import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

import { Table } from '_shared/components/CommonTable';
import { TableProps } from '_shared/components/CommonTable/Table';
import { useIntl } from 'react-intl';
import { TableCell } from '_shared/components/Table/BorderTable';
import Button from '_shared/components/Buttons/Button';

export const StyledTableCell = styled(TableCell)`
  input {
    border: none;
    :hover {
      border: none;
    }
    :focus {
      border: none;
    }
  }
  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
`;

export const AccountStyledTableCell = styled(StyledTableCell)`
  font-weight: 700;
  &.emptyRow {
    font-weight: 400;
    font-style: italic;
    color: ${({ theme }) => theme.palette.grey[500]};
  }
`;

export const AmountTableCell = styled(StyledTableCell)`
  &.active {
    input {
      border: 1px solid ${({ theme }) => theme.palette.grey[300]};
      :hover {
        border: 1px solid ${({ theme }) => theme.palette.grey[400]};
      }
      :focus {
        border: 1px solid ${({ theme }) => theme.palette.primary.main};
      }
      padding: ${({ theme }) => theme.spacing(1.6)}px;
    }
  }
`;

const StyledTable = styled(Table)`
  box-shadow: none;
  border: none;
  padding: 0 8px;
  border-radius: 0;
  background-color: initial;

  .MuiTableBody-root {
    .MuiTableRow-root {
      border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
    }
  }

  .MuiTableCell-root {
    padding: 4px;
    border-bottom: 0;

    svg {
      color: ${({ theme }) => theme.palette.darkBlue};
      font-size: 25px;
    }

    ${({ canDeleteRows }) =>
      canDeleteRows
        ? `&:last-of-type {
            text-align: center;
          }`
        : ''}
  }

  .MuiTableCell-head {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }
`;

const EmptyAccountsInfo = styled(Typography)`
  font-weight: 400;
  font-style: italic;
  padding: 8px;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.grey[400]};
`;

const AddNewRowButton = styled.div`
  margin: 8px;
`;

const RenovationTable = (props: TableProps) => {
  const { canAddRows, service, tableId, rows, renderRow } = props;
  const { formatMessage } = useIntl();

  const isEmptyTableValues = useMemo(() => {
    const empty = [...rows].find((row) => {
      if (
        'cells' in row &&
        row.cells &&
        'accountName' in row.cells &&
        'amount' in row.cells &&
        'account' in row.cells &&
        'value' in row.cells.accountName &&
        'value' in row.cells.amount &&
        'value' in row.cells.account
      ) {
        return (
          !row.cells.accountName.value &&
          !row.cells.amount.value &&
          row.cells.account.value
        );
      }
    });
    return empty;
  }, [rows]);

  return (
    <>
      <StyledTable {...props} canAddRows={false} renderRow={renderRow} />
      {isEmptyTableValues && (
        <EmptyAccountsInfo>
          {formatMessage({
            id: `tax.declaration.form.helper.information`,
          })}
        </EmptyAccountsInfo>
      )}
      {canAddRows && (
        <AddNewRowButton>
          <Button
            label="+ Lägg till rad"
            onClick={() => service?.addRow(tableId)}
            variant="outlined"
            size="medium"
          />
        </AddNewRowButton>
      )}
    </>
  );
};

export default RenovationTable;
