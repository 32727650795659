// Type that will allow recursive deep comparison
type Change<T> = T extends object
  ? { [K in keyof T]: Change<T[K]> }
  : { old: T; new: T };

// Function to perform deep comparison between two objects
export const deepCompare = <T>(prev: T, current: T): Change<T> => {
  const changes: Record<string, any> = {}; // Using Record<string, any> for flexibility

  // Iterate over all keys in the object
  for (const key in current) {
    // Check if current[key] and prev[key] are defined
    if (current[key] !== undefined && prev[key] !== undefined) {
      // If the value is an object, perform a recursive comparison
      if (typeof current[key] === 'object' && current[key] !== null) {
        const nestedChanges = deepCompare(prev[key], current[key]);
        if (Object.keys(nestedChanges).length > 0) {
          changes[key] = nestedChanges;
        }
      } else if (current[key] !== prev[key]) {
        // If the value is different, record the change
        changes[key] = { old: prev[key], new: current[key] };
      }
    }
  }

  return changes as Change<T>;
};
