import { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiReturnType,
  asResultClass,
  isApiErrorType,
  useApiSdk,
} from 'api-sdk';
import { NotificationContext } from '_shared/services/Notifications/NotificationsContext';
import { useIsNewSpecifications } from '_shared/HOC/withNewSpecificationsFeatureSwitchContext';
import { last } from 'lodash';
import { useDispatch } from 'react-redux';
import { addGlobalErrorMessage } from 'redux/actions';
import { HiddenAccountRow } from '../RowContext/types';

type UserInput = ApiReturnType<'getPeriodUserInput'>['accounts'][string];
type ActualBalance = ApiReturnType<'getActualBalances'>['accounts'][string];
type LegacySpecifications =
  ApiReturnType<'getLegacySpecifications'>['accounts'][string];

const DEFAULT_ACTUAL_BALANCE = {};
const DEFAULT_SPECIFICATIONS = [];
const DEFAULT_USER_INPUT = {
  comment: '',
  printComment: true,
  visited: false,
};

const stateKey = (periodId: number, accountNumber: number): string =>
  `${periodId}_${accountNumber}`;

/**
 * Hook for fetching and subscribing to actual balances and user input
 *
 * The two sets of information needs to be in sync, so if the period changes
 * we don't reuse data from the previous period with data from the new period.
 *
 * @param row
 * @returns
 */
const useUserDataWithSubscription = (
  row: HiddenAccountRow
):
  | {
      userInput: UserInput;
      actualBalance: ActualBalance;
      legacySpecifications: LegacySpecifications;
    }
  | undefined => {
  const sdk = useApiSdk();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const isNewSpecifications = useIsNewSpecifications();

  const notificationService = useContext(NotificationContext);
  const accountNumber = parseInt(row.accountNumber, 10);
  const periodId = last(row.period.periods)?.id;

  // Keeping a state with period and account as key
  const [state, setState] = useState<
    Record<
      string,
      {
        userInput?: UserInput;
        actualBalance?: ActualBalance;
        legacySpecifications?: LegacySpecifications;
      }
    >
  >({});

  const requestUserInput = useCallback(async () => {
    if (!periodId) {
      return;
    }
    if (periodId) {
      const result = await asResultClass(
        sdk.getPeriodUserInput({
          clientid: row.period.clientId,
          accountNumbers: [accountNumber],
          periodId,
        })
      );
      if (result.err) {
        if (!isApiErrorType(result.val) || !result.val.handled) {
          dispatch(addGlobalErrorMessage('error'));
        }
        return;
      }
      const userInput =
        result.val.accounts[accountNumber] ?? DEFAULT_USER_INPUT;
      const key = stateKey(periodId, accountNumber);
      setState((current) => ({
        ...current,
        [key]: { ...current[key], userInput },
      }));
    }
  }, [periodId, sdk, row.period.clientId, accountNumber, dispatch]);

  const requestActualBalance = useCallback(async () => {
    if (!periodId) {
      return;
    }
    if (periodId) {
      const result = await asResultClass(
        sdk.getActualBalances({
          clientid: row.period.clientId,
          accounts: [accountNumber],
          periodId,
        })
      );
      if (result.err) {
        if (!isApiErrorType(result.val) || !result.val.handled) {
          dispatch(addGlobalErrorMessage('error'));
        }
        return;
      }
      const actualBalance =
        result.val.accounts[accountNumber] ?? DEFAULT_ACTUAL_BALANCE;
      const key = stateKey(periodId, accountNumber);
      setState((current) => ({
        ...current,
        [key]: { ...current[key], actualBalance },
      }));
    }
  }, [periodId, sdk, row.period.clientId, accountNumber, dispatch]);

  const requestSpecifications = useCallback(async () => {
    if (!periodId) {
      return;
    }
    if (isNewSpecifications === true) {
      const key = stateKey(periodId, accountNumber);
      setState((current) => ({
        ...current,
        [key]: { ...current[key], legacySpecifications: [] },
      }));
      return;
    }
    if (periodId) {
      const result = await asResultClass(
        sdk.getLegacySpecifications({
          clientid: row.period.clientId,
          accountNumbers: [accountNumber],
          periodId,
        })
      );
      if (result.err) {
        if (
          isApiErrorType(result.val) &&
          result.val.body.message === 'Migration in progress'
        ) {
          dispatch(
            addGlobalErrorMessage(
              formatMessage({ id: 'hidden.specification.migration.inProgress' })
            )
          );
          return;
        }
        if (!isApiErrorType(result.val) || !result.val.handled) {
          dispatch(addGlobalErrorMessage('error'));
        }
        return;
      }
      const specifications =
        result.val.accounts[accountNumber] ?? DEFAULT_SPECIFICATIONS;
      const key = stateKey(periodId, accountNumber);
      setState((current) => ({
        ...current,
        [key]: { ...current[key], legacySpecifications: specifications },
      }));
    }
  }, [
    periodId,
    isNewSpecifications,
    sdk,
    row.period.clientId,
    accountNumber,
    dispatch,
    formatMessage,
  ]);

  useEffect(() => {
    const sub = notificationService?.subscribe(
      {
        clientId: row.period.clientId,
        topic: 'user-input-changed',
      },
      (msg) => {
        if (!msg.ok) {
          console.error('HiddenRow error in notification', msg.val);
          return;
        }

        if (msg.val.topic !== 'user-input-changed') {
          return;
        }

        if (!msg.val.accounts.includes(accountNumber)) {
          return;
        }

        if (msg.val.information.includes('actualBalance')) {
          requestActualBalance();
        }
        if (msg.val.information.includes('userInput')) {
          requestUserInput();
        }
        if (
          msg.val.information.includes('specification') &&
          !isNewSpecifications
        ) {
          requestSpecifications();
        }
      }
    );
    return () => {
      sub?.unsubscribe();
    };
  }, [
    requestUserInput,
    requestActualBalance,
    requestSpecifications,
    notificationService,
    row.period,
    accountNumber,
    isNewSpecifications,
  ]);

  useEffect(() => {
    requestUserInput();
    requestActualBalance();
    requestSpecifications();
  }, [requestUserInput, requestActualBalance, requestSpecifications]);

  // Pick the state for the current account and period
  const data = periodId ? state[stateKey(periodId, accountNumber)] : undefined;
  if (
    data &&
    data.actualBalance &&
    data.userInput &&
    data.legacySpecifications
  ) {
    return {
      userInput: data.userInput,
      actualBalance: data.actualBalance,
      legacySpecifications: data.legacySpecifications,
    };
  }
  return undefined;
};

export default useUserDataWithSubscription;
