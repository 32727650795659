import React, { useState, useContext } from 'react';
import { IconButton } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useSelector } from 'redux/reducers';
import { MovedAccountsByPeriods } from '_reconciliation/components/ReconciliationView/RowContext/types';
import Document from '_shared/components/Document';
import { TaxSpecificationsAccountGroup } from '@agoy/api-sdk-core';
import {
  BalanceHistoryItemType,
  CheckedHistoryItemType,
  EnableForPrint,
} from '_shared/components/GroupsSpecificationInfo/types';

import { PeriodDocument } from 'utils/usePeriodDocuments/Provider';
import { ActualBalances } from '_reconciliation/components/ReconciliationView/types';
import { printDocument } from '_shared/components/PrintedDocument/utils';
import Typography from '_shared/components/Typography/Typography';
import usePrintState from '_tax/hooks/usePrintState';
import { addGlobalErrorMessage } from 'redux/actions';
import { GetSpecificationType } from '_clients/types/types';
import LoadingLogo from '_shared/components/LoadingLogo';
import { Comment } from '_shared/types';

import Checklists from './Checklists';
import TaxesDataContext from '../../../service/TaxesDataContext';
import GroupsTab, { getGroupDocuments } from './GroupsTab';
import AccountsTab, { getAccountsDocuments } from './AccountsTab';
import { ExternCommentsRecord, DocumentType } from './types';

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)}px;
`;

const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[500]};
  padding-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

const PrintButton = styled(IconButton)`
  &:hover {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const PrintWrapper = styled.div`
  display: flex;
  @media print {
    display: none;
  }
`;

const DocumentContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  padding: 8px;
  @media print {
    padding: 0;
    margin: 0;
    page-break-inside: avoid;
    max-width: 1000px;
  }
`;

const DocumentTitleWrapper = styled.div`
  @media print {
    page-break-before: always;
    break-before: always;
  }
`;

const DocumentWrapper = styled.div`
  padding-top: 8px;

  @media print {
    display: flex;
    padding: 0;
  }
`;

type TaxSpecificationsContentTabProps = {
  tab: string;
  part?: string;
  groups: TaxSpecificationsAccountGroup[];
  accounts: string[];
  periodDocuments: DocumentType[];
  accountDocuments: PeriodDocument[];
  balanceChangeHistory: BalanceHistoryItemType[];
  checkedChangeHistory: CheckedHistoryItemType[];
  actualBalances: ActualBalances;
  groupsExternComments: ExternCommentsRecord;
  internalComments: Comment[];
  enableForPrint: EnableForPrint;
  movedAccountsByPeriods: MovedAccountsByPeriods;
  specifications: Record<string, GetSpecificationType>;
  isNewSpecifications: boolean;
  isPrint?: boolean;
  onSwitchVisibleForPrint: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const TaxSpecificationsContentTab = ({
  tab,
  part,
  groups,
  accounts,
  periodDocuments,
  accountDocuments,
  balanceChangeHistory,
  checkedChangeHistory,
  actualBalances,
  groupsExternComments,
  internalComments,
  enableForPrint,
  movedAccountsByPeriods,
  specifications,
  isNewSpecifications,
  isPrint = false,
  onSwitchVisibleForPrint,
}: TaxSpecificationsContentTabProps): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const printState = usePrintState();

  const { clientId, financialYear, taxYearData, periodObj } =
    useContext(TaxesDataContext);

  const [printLoading, setPrintLoading] = useState(false);

  const { orgNumber, name } = useSelector((state) => state.customers[clientId]);

  const checklist = useSelector((state) => state.checklist);

  const handlePrint = async () => {
    try {
      setPrintLoading(true);

      let attachments: string[] = [];

      if (tab === 'group') {
        attachments = groups.reduce<string[]>((acc, group) => {
          if (!(enableForPrint.documents[`group${group.id}`] ?? true)) {
            return acc;
          }
          const documents = getGroupDocuments(
            group,
            periodObj.start,
            accountDocuments,
            movedAccountsByPeriods
          );

          return [...acc, ...documents.map((document) => document.url)];
        }, []);
      } else {
        attachments = accounts.reduce<string[]>((acc, account) => {
          if (!(enableForPrint.documents[`account${account}`] ?? true)) {
            return acc;
          }
          const documents = getAccountsDocuments(account, accountDocuments);
          return [...acc, ...documents.map((document) => document.url)];
        }, []);
      }

      attachments.push(...periodDocuments.map((doc) => doc.url));

      await printDocument(
        clientId,
        financialYear,
        'taxes',
        formatMessage({
          id:
            part === 'externspecifications'
              ? 'tax.specifications.extern'
              : 'tax.specifications.intern',
        }),
        ['specifications'],
        {
          ...printState,
          checklist,
          taxSpecificationsData: {
            tab,
            part,
            accounts,
            groups,
            periodDocuments,
            accountDocuments,
            balanceChangeHistory,
            checkedChangeHistory,
            actualBalances,
            groupsExternComments,
            enableForPrint,
            movedAccountsByPeriods,
            internalComments,
            specifications,
            isNewSpecifications,
          },
        },
        attachments
      );
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    } finally {
      setPrintLoading(false);
    }
  };

  const taxDocumentsItems = taxYearData.state?.taxDocuments || [];

  return (
    <>
      <TitleWrapper>
        <Typography variant={isPrint ? 'h1' : 'h2'} margin="none">
          {formatMessage({
            id:
              part === 'externspecifications'
                ? 'tax.specifications.extern'
                : 'tax.specifications.intern',
          })}
        </Typography>
        {!isPrint && (
          <PrintWrapper>
            <PrintButton onClick={handlePrint} disabled={printLoading}>
              {printLoading ? <LoadingLogo size="tiny" /> : <PrintIcon />}
            </PrintButton>
          </PrintWrapper>
        )}
      </TitleWrapper>

      <SubTitleWrapper>
        <Typography margin="none">{name}</Typography>
        <Typography margin="none">{orgNumber}</Typography>
      </SubTitleWrapper>

      {tab === 'group' ? (
        <GroupsTab
          isPrint={isPrint}
          part={part}
          groups={groups}
          accountDocuments={accountDocuments}
          balanceChangeHistory={balanceChangeHistory}
          checkedChangeHistory={checkedChangeHistory}
          actualBalances={actualBalances}
          externComments={groupsExternComments}
          internalComments={internalComments}
          enableForPrint={enableForPrint}
          movedAccountsByPeriods={movedAccountsByPeriods}
          onSwitchVisibleForPrint={onSwitchVisibleForPrint}
        />
      ) : (
        <AccountsTab
          isPrint={isPrint}
          part={part}
          accounts={accounts}
          accountDocuments={accountDocuments}
          balanceChangeHistory={balanceChangeHistory}
          checkedChangeHistory={checkedChangeHistory}
          actualBalances={actualBalances}
          internalComments={internalComments}
          enableForPrint={enableForPrint}
          specifications={specifications}
          isNewSpecifications={isNewSpecifications}
          onSwitchVisibleForPrint={onSwitchVisibleForPrint}
        />
      )}

      {!!periodDocuments.length && (
        <>
          <DocumentTitleWrapper>
            <Typography variant="h2">
              {formatMessage({
                id: 'tax.documents',
              })}
            </Typography>
          </DocumentTitleWrapper>

          {periodDocuments.map((document, index) => (
            <DocumentContainer key={document.id}>
              <Typography variant="h4">
                {
                  taxDocumentsItems.find(
                    (item) => item.value === document.category
                  )?.label
                }
              </Typography>
              <DocumentWrapper>
                <Document
                  name={document.name}
                  url={document.url}
                  isPrint={isPrint}
                  disableBreakAfter={index === periodDocuments.length - 1}
                />
              </DocumentWrapper>
            </DocumentContainer>
          ))}
        </>
      )}

      {part === 'internspecifications' && (
        <Checklists isGroup={tab === 'group'} />
      )}
    </>
  );
};

export default TaxSpecificationsContentTab;
