import { useRouteMatch } from 'react-router-dom';
import { FortnoxRouteParams } from '../types';
import { FORTNOX_ROUTE_PREFIX } from '../../contants';

const isValidFid = (fid) => fid !== undefined && fid.length === 32;

const useFid = () => {
  const routeMatch = useRouteMatch<FortnoxRouteParams>(FORTNOX_ROUTE_PREFIX);
  const fid = routeMatch?.params.fid;
  return isValidFid(fid) ? fid : undefined;
};

export default useFid;
