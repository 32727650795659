import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

import { FinancialReport } from '@agoy/financial-report-document';
import { stringValue } from '@agoy/document';
import Document from '_shared/components/Document';
import { Document as DocumentType } from '_financial-report/redux/reducer/types';

import { ReportColors } from '../../../../utils';

type ReportColorsProps = {
  customColor: string | undefined;
};

const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SmallTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ReportColorsProps>`
  font-size: 20px;
  line-height: 22px;
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
  color: ${(props) => props.customColor || props.theme.palette.text.primary};
`;

interface DocumentsViewProps {
  documentsData: FinancialReport['documents'];
  documents: DocumentType[];
  reportColors: ReportColors;
}

const DocumentsViewPreview = ({
  documentsData,
  documents,
  reportColors,
}: DocumentsViewProps): JSX.Element => {
  return (
    <DocumentsContainer>
      <SmallTitle customColor={reportColors.textMain}>
        {stringValue(documentsData.section.title)}
      </SmallTitle>
      {documents.map((attachment, index) => (
        <Document
          name={attachment.name}
          url={attachment.url}
          key={attachment.id}
          disableBreakAfter={index === documents.length - 1}
          isPrint
        />
      ))}
    </DocumentsContainer>
  );
};

export default DocumentsViewPreview;
