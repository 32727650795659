import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import styled from '@emotion/styled';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { groupBy, isEqual } from 'lodash';
import { parseISO, format } from 'date-fns';
import { useDispatch } from 'react-redux';

import { parseFormat } from '@agoy/dates';
import { Period } from '@agoy/api-sdk-core';
import { useSelector } from 'redux/reducers';
import { formatPeriodToMonthAndYear } from 'utils';
import { transactionMangling } from 'utils/SieParser/transactionUtils';
import { Transaction } from '_shared/types';
import { InputData } from '_reconciliation/types';
import PeriodSummary from '_shared/components/PeriodSummary';
import { addGlobalErrorMessage } from 'redux/actions';
import { isDefined } from '@agoy/common';
import { asResultClass, useApiSdk } from 'api-sdk';

import TransactionTable from './TransactionTable';
import PeriodPicker from '../PeriodPicker';
import VerFilter from './VerFilter';
import PeriodDataContext from '../PeriodDataContext';
import { NUMBER_OF_TRANSACTION_ROWS, TransactionFilter } from './types';
import useTransactionsTransferring from './useTransactionsTransferring';

const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  display: flex;
  gap: ${(props) => props.theme.spacing(3)}px;
`;

const TransactionView = styled.div`
  width: 100%;
  overflow: auto;
  padding-bottom: 16px;
`;

type TransactionAnalysisProps = {
  userData: InputData;
  account: string;
};

const TransactionAnalysisView = ({
  userData,
  account,
}: TransactionAnalysisProps): JSX.Element => {
  const sdk = useApiSdk();
  const dispatch = useDispatch();

  const {
    isTransactionTransferred,
    transferAllTransactions,
    transferTransaction,
  } = useTransactionsTransferring(account);

  const { clientId, period, groupedPeriods } = useContext(PeriodDataContext);

  const initiallyIncludePreliminary = !!groupedPeriods.find(
    (p) => p.type === 'year_end'
  );
  const [includePreliminary, setIncludePreliminary] = useState(
    initiallyIncludePreliminary
  );

  // state handling of pagination and results
  const [paginatedTransactions, setPaginatedTransactions] = useState<
    Transaction[]
  >([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);

  // default filter
  const [filterBy, setFilterBy] = useState<TransactionFilter>({
    account,
    periods: {},
    voucherId: null,
    verification: null,
  });

  const rawFinancialYears = useSelector((state) =>
    clientId ? state.customers?.[clientId].rawFinancialYears : []
  );

  const currentFinancialYear = useMemo(
    () =>
      rawFinancialYears.find((year) =>
        year.periods?.find((p) => p.id === period.id)
      ),
    [period.id, rawFinancialYears]
  );

  const { ub, ib, psaldo, saldo: userDataSaldo, yearIB } = userData || {};
  const saldo = userDataSaldo ? parseInt(userDataSaldo, 10) : 0;

  // EFFECTS
  const periodsWithIds: Record<string, Period[]> = useMemo(() => {
    return rawFinancialYears?.reduce((prevYear, currYear) => {
      if (currYear.periods) {
        const ps = groupBy(currYear.periods, (p) =>
          parseFormat(p.start, 'yyyyMM')
        );

        return { ...prevYear, ...ps };
      }
      return { ...prevYear };
    }, {});
  }, [rawFinancialYears]);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (
        !filterBy.account ||
        !filterBy.periods ||
        !periodsWithIds ||
        !(Object.keys(periodsWithIds).length > 0) ||
        !(Object.keys(filterBy.periods).length > 0)
      ) {
        setPaginatedTransactions([]);
        return;
      }

      const periodIdsToFetch = Object.keys(filterBy.periods)
        .flatMap((key) =>
          periodsWithIds[format(parseISO(key), 'yyyyMM')]?.filter(
            (p) => includePreliminary || p.type === 'month'
          )
        )
        .filter(isDefined);

      try {
        const res = await asResultClass(
          sdk.getTransactions({
            clientid: clientId,
            accountNumber: filterBy.voucherId ? undefined : filterBy.account,
            periodId: filterBy.voucherId
              ? undefined
              : periodIdsToFetch.map((p) => p.id),
            offset: (selectedPage - 1) * NUMBER_OF_TRANSACTION_ROWS,
            limit: NUMBER_OF_TRANSACTION_ROWS,
            voucherId: filterBy.voucherId || undefined,
          })
        );
        if (res.ok) {
          const { transactions, pagination } = res.val;
          if (transactions) {
            setPaginatedTransactions(transactionMangling(transactions));
          }

          if (pagination && pagination.totalPages) {
            setTotalNumberOfPages(pagination.totalPages);
          }
        } else {
          dispatch(addGlobalErrorMessage('error'));
        }
      } catch {
        dispatch(addGlobalErrorMessage('error'));
      }
    };

    fetchTransactions();
  }, [
    clientId,
    dispatch,
    filterBy,
    periodsWithIds,
    sdk,
    selectedPage,
    includePreliminary,
  ]);

  // HANDLERS
  const handlePeriodSelect = useCallback((periods) => {
    // reset selected page otherwise index is based on previous result
    setSelectedPage(1);

    // update filter to cover selected periods
    setFilterBy((f) => (isEqual(f.periods, periods) ? f : { ...f, periods }));
  }, []);

  const handleVerSelect = useCallback((verification, voucherId) => {
    setSelectedPage(1);
    setFilterBy((f) => ({ ...f, verification, voucherId }));
  }, []);

  const handleVerDeselect = useCallback(() => {
    setSelectedPage(1);
    setFilterBy((f) => ({ ...f, verification: null, voucherId: null }));
  }, []);

  const handleTransferAllTransactions = useCallback(async () => {
    await transferAllTransactions(
      filterBy,
      includePreliminary,
      totalNumberOfPages
    );
  }, [
    filterBy,
    includePreliminary,
    totalNumberOfPages,
    transferAllTransactions,
  ]);

  return (
    <Wrapper>
      <PeriodSummary
        date={formatPeriodToMonthAndYear(period)}
        periodIb={ib}
        periodSaldo={psaldo}
        periodUb={ub}
        saldo={saldo}
        yearIB={yearIB}
      />

      <div>
        <PeriodPicker
          clientId={clientId}
          periods={groupedPeriods}
          onPeriodChange={handlePeriodSelect}
        />
        {initiallyIncludePreliminary && (
          <FormControlLabel
            control={<Checkbox checked={includePreliminary} />}
            label="Preliminära verifikat"
            onChange={(event, checked) => setIncludePreliminary(checked)}
          />
        )}
      </div>
      <TransactionView>
        <VerFilter
          filterByVerificationNumber={filterBy.verification}
          handleVerFilterDeselect={handleVerDeselect}
        />
        <TransactionTable
          clientId={clientId}
          financialYear={currentFinancialYear}
          rawFinancialYears={rawFinancialYears}
          transactions={paginatedTransactions}
          selectedPage={selectedPage}
          totalNumberOfPages={totalNumberOfPages}
          onTransferAllTransactions={handleTransferAllTransactions}
          onTransferTransaction={transferTransaction}
          isTransactionTransferred={isTransactionTransferred}
          onVerSelect={handleVerSelect}
          onPageChange={setSelectedPage}
        />
      </TransactionView>
    </Wrapper>
  );
};

const TransactionAnalysis = ({
  account,
  userData,
}: TransactionAnalysisProps) => {
  const { period } = useContext(PeriodDataContext);

  // Forcing React to render a new component for each period to
  // prevent it from using state from the previous period.
  return (
    <>
      <TransactionAnalysisView
        account={account}
        userData={userData}
        key={period.id}
      />
    </>
  );
};
export default TransactionAnalysis;
