export interface TransactionFilter {
  account: string;
  periods: { [period: string]: boolean };
  voucherId: number | null;
  verification: string | null;
}

export type ColumnsIds = {
  account: number;
  amount: number;
  reference: number;
  description: number;
  transactionInfo: number;
};

export type TransactionInfo = {
  account: string;
  amount: number;
  series: string;
  verificationNumber?: string;
  verificationText?: string;
  transactionDate?: string;
  transactionText?: string;
};

export const NUMBER_OF_TRANSACTION_ROWS = 10;
