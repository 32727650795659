/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6810
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  previousYear,
  year,
  sumAccountsIBs,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2036K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2036K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Goodwill'),
  data: {
    active: true,
    GoodwillAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Goodwill'),
      table: table<IxbrlCell>(
        'notes.note36.data.GoodwillAbstract.table',
        {
          id: 'GoodwillAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'GoodwillAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'GoodwillAnskaffningsvardenIngaende',
                'sum_accounts',
                noteLabel('Ingående anskaffningsvärden'),
                ixbrlCell(ref(sumAccountsIBs('1070:1077', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:GoodwillAnskaffningsvarden',
                  standardRubrik: 'Anskaffningsvärden goodwill',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsIBs('1070:1077', false, 'previousYear')),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:GoodwillAnskaffningsvarden',
                    standardRubrik: 'Anskaffningsvärden goodwill',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows
                .addRowWithType(
                  'GoodwillForandringAnskaffningsvardenAbstract',
                  'header',
                  noteLabel('Förändringar av anskaffningsvärden'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'GoodwillForandringAnskaffningsvardenRorelseforvarv',
                    'row',
                    noteLabel('Rörelseförvärv'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAnskaffningsvardenRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för goodwill vid rörelseförvärv',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAnskaffningsvardenRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för goodwill vid rörelseförvärv',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'GoodwillForandringAnskaffningsvardenFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för goodwill genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för goodwill genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'GoodwillForandringAnskaffningsvardenForsaljningarUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAnskaffningsvardenForsaljningarUtrangeringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av goodwill',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAnskaffningsvardenForsaljningarUtrangeringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av goodwill',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'GoodwillForandringAnskaffningsvardenOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av goodwill',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av goodwill',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'GoodwillForandringAnskaffningsvardenOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av goodwill',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av goodwill',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'GoodwillAnskaffningsvardenUtgaende',
                'sum',
                noteLabel('Utgående anskaffningsvärden'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note36.data.GoodwillAbstract.table.GoodwillAbstract.GoodwillAnskaffningsvardenIngaende.year'
                      ),
                      id(
                        'notes.note36.data.GoodwillAbstract.table.GoodwillAbstract.GoodwillForandringAnskaffningsvardenAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:GoodwillAnskaffningsvarden',
                    standardRubrik: 'Anskaffningsvärden goodwill',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note36.data.GoodwillAbstract.table.GoodwillAbstract.GoodwillAnskaffningsvardenIngaende.previousYear'
                      ),
                      id(
                        'notes.note36.data.GoodwillAbstract.table.GoodwillAbstract.GoodwillForandringAnskaffningsvardenAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:GoodwillAnskaffningsvarden',
                    standardRubrik: 'Anskaffningsvärden goodwill',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'GoodwillAvskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående avskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1079', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:GoodwillAvskrivningar',
                  standardRubrik: 'Ackumulerade avskrivningar goodwill',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(ref(sumAccountsIBs('1079', false, 'previousYear')), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:GoodwillAvskrivningar',
                  standardRubrik: 'Ackumulerade avskrivningar goodwill',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows
                .addRowWithType(
                  'GoodwillForandringAvskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av avskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'GoodwillForandringAvskrivningarAterfordaAvskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAvskrivningarAterfordaAvskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av avskrivningar av goodwill genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAvskrivningarAterfordaAvskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av avskrivningar av goodwill genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'GoodwillForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda avskrivningar i samband med försäljningar eller utrangeringar av goodwill',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda avskrivningar i samband med försäljningar eller utrangeringar av goodwill',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'GoodwillForandringAvskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAvskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omklassificeringar av goodwill',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAvskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omklassificeringar av goodwill',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'GoodwillForandringAvskrivningarAretsAvskrivningar',
                    'row',
                    noteLabel('Årets avskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAvskrivningarAretsAvskrivningar',
                      standardRubrik: 'Periodens avskrivningar goodwill',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAvskrivningarAretsAvskrivningar',
                      standardRubrik: 'Periodens avskrivningar goodwill',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'GoodwillForandringAvskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAvskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av goodwill',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringAvskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av goodwill',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'GoodwillAvskrivningarUtgaende',
                'sum',
                noteLabel('Utgående avskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note36.data.GoodwillAbstract.table.GoodwillAbstract.GoodwillAvskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note36.data.GoodwillAbstract.table.GoodwillAbstract.GoodwillForandringAvskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:GoodwillAvskrivningar',
                    standardRubrik: 'Ackumulerade avskrivningar goodwill',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note36.data.GoodwillAbstract.table.GoodwillAbstract.GoodwillAvskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note36.data.GoodwillAbstract.table.GoodwillAbstract.GoodwillForandringAvskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:GoodwillAvskrivningar',
                    standardRubrik: 'Ackumulerade avskrivningar goodwill',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              rows.addRowWithType(
                'GoodwillNedskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående nedskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1078', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:GoodwillNedskrivningar',
                  standardRubrik: 'Ackumulerade nedskrivningar goodwill',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(ref(sumAccountsIBs('1078', false, 'previousYear')), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:GoodwillNedskrivningar',
                  standardRubrik: 'Ackumulerade nedskrivningar goodwill',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows
                .addRowWithType(
                  'GoodwillForandringNedskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av nedskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'GoodwillForandringNedskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar av goodwill genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar av goodwill genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'GoodwillForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av goodwill',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av goodwill',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'GoodwillForandringNedskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av goodwill',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av goodwill',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'GoodwillForandringNedskrivningarAterfordaNedskrivningar',
                    'row',
                    noteLabel('Återförda nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av goodwill',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av goodwill',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'GoodwillForandringNedskrivningarAretsNedskrivningar',
                    'row',
                    noteLabel('Årets nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik: 'Periodens nedskrivningar av goodwill',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik: 'Periodens nedskrivningar av goodwill',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'GoodwillForandringNedskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av goodwill',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:GoodwillForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av goodwill',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'GoodwillNedskrivningarUtgaende',
                'sum',
                noteLabel('Utgående nedskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note36.data.GoodwillAbstract.table.GoodwillAbstract.GoodwillNedskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note36.data.GoodwillAbstract.table.GoodwillAbstract.GoodwillForandringNedskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:GoodwillNedskrivningar',
                    standardRubrik: 'Ackumulerade nedskrivningar goodwill',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note36.data.GoodwillAbstract.table.GoodwillAbstract.GoodwillNedskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note36.data.GoodwillAbstract.table.GoodwillAbstract.GoodwillForandringNedskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:GoodwillNedskrivningar',
                    standardRubrik: 'Ackumulerade nedskrivningar goodwill',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'Goodwill',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note36.data.GoodwillAbstract.table.GoodwillAbstract.GoodwillAnskaffningsvardenUtgaende.year'
                  ),
                  id(
                    'notes.note36.data.GoodwillAbstract.table.GoodwillAbstract.GoodwillAvskrivningarUtgaende.year'
                  ),
                  id(
                    'notes.note36.data.GoodwillAbstract.table.GoodwillAbstract.GoodwillNedskrivningarUtgaende.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:Goodwill',
                standardRubrik: 'Goodwill',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note36.data.GoodwillAbstract.table.GoodwillAbstract.GoodwillAnskaffningsvardenUtgaende.previousYear'
                  ),
                  id(
                    'notes.note36.data.GoodwillAbstract.table.GoodwillAbstract.GoodwillAvskrivningarUtgaende.previousYear'
                  ),
                  id(
                    'notes.note36.data.GoodwillAbstract.table.GoodwillAbstract.GoodwillNedskrivningarUtgaende.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:Goodwill',
                standardRubrik: 'Goodwill',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NotGoodwillKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field('Kommentar till specifikation av goodwill'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotGoodwillKommentar',
          standardRubrik: 'Kommentar till specifikation av goodwill',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2036K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        GoodwillAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotGoodwillKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
