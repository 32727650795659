/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6788
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  previousYear,
  year,
  sumAccountsUBs,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2063K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2063K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Pågående arbete för annans räkning'),
  data: {
    active: true,
    PagaendeArbetenAnnansRakningOmsattningstillgangarAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Pågående arbete för annans räkning, tillgång'),
      table: table<IxbrlCell>(
        'notes.note63.data.PagaendeArbetenAnnansRakningOmsattningstillgangarAbstract.table',
        {
          id: 'PagaendeArbetenAnnansRakningOmsattningstillgangarAbstract',
          label: 'Pågående arbete för annans räkning (Fordran)',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'PagaendeArbetenAnnansRakningOmsattningstillgangarAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'PagaendeArbetenAnnansRakningOmsattningstillgangarAktiveradeUtgifter',
                'sum_accounts',
                noteLabel('Aktiverade nedlagda utgifter'),
                ixbrlCell(ref(sumAccountsUBs('1471', false)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangarAktiveradeUtgifter',
                  standardRubrik:
                    'Aktiverade nedlagda utgifter avseende pågående arbete för annans räkning, tillgång',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(ref(sumAccountsUBs('1471', false, 'previousYear')), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangarAktiveradeUtgifter',
                  standardRubrik:
                    'Aktiverade nedlagda utgifter avseende pågående arbete för annans räkning, tillgång',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'PagaendeArbetenAnnansRakningOmsattningstillgangarFaktureradeDelbelopp',
                'sum_accounts',
                noteLabel('Fakturerat belopp'),
                ixbrlCell(ref(sumAccountsUBs('1478', false)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangarFaktureradeDelbelopp',
                  standardRubrik:
                    'Fakturerat belopp avseende pågående arbete för annans räkning, tillgång',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(ref(sumAccountsUBs('1478', false, 'previousYear')), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangarFaktureradeDelbelopp',
                  standardRubrik:
                    'Fakturerat belopp avseende pågående arbete för annans räkning, tillgång',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'PagaendeArbetenAnnansRakningOmsattningstillgangar',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note63.data.PagaendeArbetenAnnansRakningOmsattningstillgangarAbstract.table.PagaendeArbetenAnnansRakningOmsattningstillgangarAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangar',
                standardRubrik: 'Pågående arbete för annans räkning, tillgång',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note63.data.PagaendeArbetenAnnansRakningOmsattningstillgangarAbstract.table.PagaendeArbetenAnnansRakningOmsattningstillgangarAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangar',
                standardRubrik: 'Pågående arbete för annans räkning, tillgång',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
    },
    PagaendeArbetenAnnansRakningOmsattningstillgangarBestallarenInnehalletBelopp:
      {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Av beställaren innehållet belopp avseende pågående arbete för annans räkning, fordran'
        ),
        table: table<IxbrlCell>(
          'notes.note63.data.PagaendeArbetenAnnansRakningOmsattningstillgangarBestallarenInnehalletBelopp.table',
          {
            id: 'PagaendeArbetenAnnansRakningOmsattningstillgangarBestallarenInnehalletBelopp',
            label: 'Av beställaren innehållet belopp',
            dataType: 'text',
            active: true,
          },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) ?? '',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) ?? '',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows.addRowWithType(
              'PagaendeArbetenAnnansRakningOmsattningstillgangarBestallarenInnehalletBelopp',
              'row',
              noteLabel('Av beställaren innehållet belopp'),
              ixbrlCell(value(undefined), {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangarBestallarenInnehalletBelopp',
                standardRubrik:
                  'Av beställaren innehållet belopp avseende pågående arbete för annans räkning, fordran',
                negateValue: false,
              }),
              ixbrlCell(value(undefined), {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangarBestallarenInnehalletBelopp',
                standardRubrik:
                  'Av beställaren innehållet belopp avseende pågående arbete för annans räkning, fordran',
                negateValue: false,
              })
            );
            return rows.build();
          })
          .build(),
      },
    PagaendeArbetenAnnansRakningKortfristigaSkulderAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Pågående arbete för annans räkning, skuld'),
      table: table<IxbrlCell>(
        'notes.note63.data.PagaendeArbetenAnnansRakningKortfristigaSkulderAbstract.table',
        {
          id: 'PagaendeArbetenAnnansRakningKortfristigaSkulderAbstract',
          label: 'Pågående arbete för annans räkning (Skuld)',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'PagaendeArbetenAnnansRakningKortfristigaSkulderAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'PagaendeArbetenAnnansRakningKortfristigaSkulderFaktureradeDelbelopp',
                'sum_accounts',
                noteLabel('Fakturerat belopp'),
                ixbrlCell(ref(sumAccountsUBs('2431', true)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulderFaktureradeDelbelopp',
                  standardRubrik:
                    'Fakturerat belopp avseende pågående arbete för annans räkning, skuld',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(ref(sumAccountsUBs('2431', true, 'previousYear')), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulderFaktureradeDelbelopp',
                  standardRubrik:
                    'Fakturerat belopp avseende pågående arbete för annans räkning, skuld',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows.addRowWithType(
                'PagaendeArbetenAnnansRakningKortfristigaSkulderAktiveradeUtgifter',
                'sum_accounts',
                noteLabel('Aktiverade nedlagda utgifter'),
                ixbrlCell(ref(sumAccountsUBs('2438', true)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulderAktiveradeUtgifter',
                  standardRubrik:
                    'Aktiverade nedlagda utgifter avseende pågående arbete för annans räkning, skuld',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(ref(sumAccountsUBs('2438', true, 'previousYear')), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulderAktiveradeUtgifter',
                  standardRubrik:
                    'Aktiverade nedlagda utgifter avseende pågående arbete för annans räkning, skuld',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'PagaendeArbetenAnnansRakningKortfristigaSkulder',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note63.data.PagaendeArbetenAnnansRakningKortfristigaSkulderAbstract.table.PagaendeArbetenAnnansRakningKortfristigaSkulderAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulder',
                standardRubrik: 'Pågående arbete för annans räkning, skuld',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note63.data.PagaendeArbetenAnnansRakningKortfristigaSkulderAbstract.table.PagaendeArbetenAnnansRakningKortfristigaSkulderAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulder',
                standardRubrik: 'Pågående arbete för annans räkning, skuld',
                saldo: 'credit',
                negateValue: true,
              }
            )
          );
          return rows.build();
        })
        .build(),
    },
    PagaendeArbetenAnnansRakningKortfristigaSkulderBestallarenInnehalletBelopp:
      {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Av beställaren innehållet belopp avseende pågående arbete för annans räkning, skuld'
        ),
        table: table<IxbrlCell>(
          'notes.note63.data.PagaendeArbetenAnnansRakningKortfristigaSkulderBestallarenInnehalletBelopp.table',
          {
            id: 'PagaendeArbetenAnnansRakningKortfristigaSkulderBestallarenInnehalletBelopp',
            label: 'Av beställaren innehållet belopp',
            dataType: 'text',
            active: true,
          },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) ?? '',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) ?? '',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows.addRowWithType(
              'PagaendeArbetenAnnansRakningKortfristigaSkulderBestallarenInnehalletBelopp',
              'row',
              noteLabel('Av beställaren innehållet belopp'),
              ixbrlCell(value(undefined), {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulderBestallarenInnehalletBelopp',
                standardRubrik:
                  'Av beställaren innehållet belopp avseende pågående arbete för annans räkning, skuld',
                negateValue: false,
              }),
              ixbrlCell(value(undefined), {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulderBestallarenInnehalletBelopp',
                standardRubrik:
                  'Av beställaren innehållet belopp avseende pågående arbete för annans räkning, skuld',
                negateValue: false,
              })
            );
            return rows.build();
          })
          .build(),
      },
    NotPagaendeArbetenAnnansRakningKommentar: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Kommentar till specifikation av pågående arbete för annans räkning'
      ),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:NotPagaendeArbetenAnnansRakningKommentar',
        standardRubrik:
          'Kommentar till specifikation av pågående arbete för annans räkning',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2063K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        PagaendeArbetenAnnansRakningOmsattningstillgangarAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        PagaendeArbetenAnnansRakningOmsattningstillgangarBestallarenInnehalletBelopp:
          {
            type: 'part' as const,
            children: {
              type: type,
              level: number,
              active: boolean,

              title: cell,

              table: { type: 'table' as const },
            },
          },

        PagaendeArbetenAnnansRakningKortfristigaSkulderAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        PagaendeArbetenAnnansRakningKortfristigaSkulderBestallarenInnehalletBelopp:
          {
            type: 'part' as const,
            children: {
              type: type,
              level: number,
              active: boolean,

              title: cell,

              table: { type: 'table' as const },
            },
          },

        NotPagaendeArbetenAnnansRakningKommentar: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            value: cell,
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
