import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { asResultClass, useApiSdk } from 'api-sdk';
import { addGlobalErrorMessage } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { Period } from '@agoy/api-sdk-core';
import Button from '_shared/components/Buttons/Button';
import { onUserInputCallbackFunction } from '../types';
import PeriodDataContext from '../PeriodDataContext';
import { trackCustomEvent } from '@fnox/web-analytics-script';
import { getAdditionalReconciliationPeriodData } from 'utils/piwik';

const Container = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  gap: ${({ theme }) => theme.spacing(1)}px;
  flex-wrap: wrap;
`;

interface TransferCommentProps {
  comment: string | undefined;
  period: Period;
  accountNumber: string;
  userInputCallback: onUserInputCallbackFunction;
  isGroupedAccounts?: boolean;
}

export const TransferComment = memo(
  ({
    comment,
    accountNumber,
    period,
    isGroupedAccounts,
    userInputCallback,
  }: TransferCommentProps) => {
    const { formatMessage } = useIntl();

    const [previousPeriodComment, setPreviousPeriodComment] = useState('');

    const sdk = useApiSdk();
    const dispatch = useDispatch();

    const {
      nextPeriod,
      nextPeriodFinancialYear,
      nextPeriodLocked,
      periodLocked,
      previousPeriod,
      lastPeriodLocked,
      yearEndPeriod,
      clientId,
    } = useContext(PeriodDataContext);

    const requestUserInput = useCallback(async () => {
      if (!previousPeriod) return;
      const requestBody = isGroupedAccounts
        ? {
            clientid: clientId,
            accountGroups: [accountNumber],
            periodId: previousPeriod.id,
          }
        : {
            clientid: clientId,
            periodId: previousPeriod.id,
            accountNumbers: [Number(accountNumber)],
          };

      const result = await asResultClass(sdk.getPeriodUserInput(requestBody));
      if (result.err) {
        dispatch(addGlobalErrorMessage('error'));
        return;
      }
      const userComments = result.val.accounts[accountNumber]?.comment || '';
      setPreviousPeriodComment(userComments);
    }, [
      previousPeriod,
      clientId,
      isGroupedAccounts,
      accountNumber,
      sdk,
      dispatch,
    ]);

    const showTransferToYearEndPeriod =
      yearEndPeriod.id !== period.id && comment;
    const showTransferToNextPeriod = nextPeriod && comment;

    useEffect(() => {
      requestUserInput();
    }, [requestUserInput]);

    const transferCommentNextPeriod = () => {
      if (nextPeriod && nextPeriodFinancialYear) {
        userInputCallback(
          { comment },
          accountNumber,
          nextPeriod,
          nextPeriodFinancialYear
        );
        track('copy_external_comment');
      }
    };

    const transferCommentFromPreviousPeriod = () => {
      userInputCallback(
        { comment: previousPeriodComment },
        accountNumber,
        period
      );
      track('fetch_external_comment');
    };

    const transferCommentToEndPeriod = () => {
      userInputCallback({ comment }, accountNumber, yearEndPeriod);
      track('copy_external_comment_annual_account');
    };

    const track = (action: string) => {
      trackCustomEvent({
        eventCategory: 'reconciliations',
        eventAction: 'reconciliation_row:select',
        eventName: `underlag:${action}`,
        data: getAdditionalReconciliationPeriodData(period),
      });
    };

    return (
      <Container>
        {showTransferToNextPeriod && (
          <Button
            label={formatMessage({
              id: 'hidden.transactions.transferComment',
            })}
            onClick={transferCommentNextPeriod}
            size="small"
            variant="text"
            disabled={nextPeriodLocked ?? false}
          />
        )}

        {previousPeriodComment && previousPeriod && (
          <Button
            label={formatMessage({ id: 'hidden.transactions.fetchComment' })}
            onClick={transferCommentFromPreviousPeriod}
            size="small"
            variant="text"
            disabled={periodLocked}
          />
        )}
        {showTransferToYearEndPeriod && (
          <Button
            label={formatMessage(
              { id: 'hidden.transactions.commentToEnd' },
              { target: formatMessage({ id: 'periodType.yearEndPeriod' }) }
            )}
            onClick={transferCommentToEndPeriod}
            size="small"
            variant="text"
            disabled={lastPeriodLocked ?? false}
          />
        )}
      </Container>
    );
  }
);

export default TransferComment;
