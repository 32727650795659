import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { getClasses } from '@agoy/common';
import { useIntl } from 'react-intl';
import DoneIcon from '@material-ui/icons/Done';
import { ReactComponent as Expand } from 'assets/expand-bold.svg';
import { ClickAwayListener, Popper } from '@material-ui/core';

import When from '_shared/components/When/When';
import Typography from '_shared/components/Typography/Typography';
import Checkbox from '_shared/components/Controls/Checkbox';
import useEscapeKeyHandler from '_shared/hooks/useEscapeKeyHandler';
import { Group, Item } from './types';
import useDropdownDimensions from './useDropdownDimension';
import {
  Input,
  InputContainerStyle as BaseInputContainer,
} from '../Inputs/v2/Input';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media print {
    div {
      background: none;
    }
  }
`;

const InputContainerStyle = styled(BaseInputContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  user-select: none;

  svg {
    transition: transform 0.1s ease-in-out;
  }

  &.open svg {
    transform: rotate(-180deg);
  }

  &.compact {
    padding: 5px 8px 6px 4px;
  }
`;

const DropdownInput = styled(Input)`
  cursor: pointer;
  user-select: none;
  ::selection {
    background: transparent;
  }
`;

const StyledPopper = styled(Popper)`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  font-family: 'Exo 2';
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 1px;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0px 0px ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(1)}px;
`;

const GroupTitle = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0.5)}px
    ${({ theme }) => theme.spacing(1.5)}px;
`;

const DividerContainer = styled.div`
  display: flex;
  flex: 1 0 0;
  height: ${({ theme }) => theme.spacing(1)}px;
  padding: 0 ${({ theme }) => theme.spacing(1.5)}px;
  align-items: center;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.palette.border.light};
`;

const GroupDivider = () => (
  <DividerContainer>
    <Line />
  </DividerContainer>
);

const ITEM_HEIGHT = { regular: 40, compact: 32 };

const ItemButton = styled.div`
  cursor: pointer;
  display: flex;
  width: 100%;
  height: ${ITEM_HEIGHT.regular}px;
  gap: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(1.5)}px`};
  align-items: center;
  border: 0;
  background: none;
  white-space: nowrap;

  &.hasSelection span {
    width: ${({ theme }) => theme.spacing(3)}px;
  }

  &:hover,
  &:focus-visible {
    background: rgba(0, 0, 0, 0.05);
  }

  &.filter {
    cursor: default;
    border-bottom: 1px solid ${({ theme }) => theme.palette.border.light};
  }

  &.compact {
    height: ${ITEM_HEIGHT.compact}px;
    padding: ${({ theme }) =>
      `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`};
  }
`;

type DropDownWithSelectProps = {
  items: Group[] | null;
  value?: Item['value'];
  selection?: Item['value'][];
  label?: string;
  placeholder?: string;
  onValueChange: (item: Item) => void;
  multiple?: boolean;
  compact?: boolean;
};

const DropdownWithSelect = ({
  items,
  value,
  selection = [],
  multiple = false,
  onValueChange,
  placeholder,
  label,
  compact = false,
}: DropDownWithSelectProps) => {
  const { formatMessage } = useIntl();
  const anchorRef = useRef<HTMLDivElement>(null);
  const filterInputRef = useRef<HTMLInputElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [filterInput, setFilterInput] = useState('');

  // Close dropdown on escape key
  useEscapeKeyHandler(() => setIsOpen(false));

  useEffect(() => {
    if (!isOpen) {
      setFilterInput('');
    }
  }, [isOpen]);

  const flatItems = items?.flatMap((item) => item.items);
  const selectedItem = flatItems?.find((item) => item.value === value);

  const renderItems = useMemo(() => {
    if (!filterInput) {
      return items;
    }

    const lowerCaseFilterInput = filterInput.toLocaleLowerCase();
    return items
      ?.map((group) => ({
        ...group,
        items: group.items.filter((item) =>
          item.label.toLocaleLowerCase().includes(lowerCaseFilterInput)
        ),
      }))
      .filter((group) => group.items.length > 0);
  }, [items, filterInput]);

  const itemHeight = ITEM_HEIGHT[compact ? 'compact' : 'regular'];

  const { parentWidth, maxHeight } = useDropdownDimensions(
    anchorRef,
    itemHeight,
    isOpen,
    selection
  );

  const dynamicHeight = useMemo(() => {
    const numberOfItems = flatItems?.length ?? 0;
    const totalItemsHeight = numberOfItems * itemHeight;
    const totalContentHeight = totalItemsHeight + itemHeight;

    const minHeight = itemHeight * 2;

    return Math.floor(
      Math.min(Math.max(totalContentHeight, minHeight), maxHeight)
    );
  }, [flatItems, maxHeight]);

  const displayValue = useMemo(() => {
    if (isOpen && !multiple) {
      return filterInput;
    }

    if (multiple) {
      return `${placeholder} (${formatMessage({ id: 'select.itemsSelected' }, { count: selection.length })})`;
    }
    return selectedItem?.label;
  }, [isOpen, filterInput, placeholder, selection, multiple]);

  const onChange = (item: Item) => {
    if (!multiple) {
      setIsOpen(false);
    }
    onValueChange(item);
  };

  const onInput = (e) => {
    const value = e.target.value;
    setFilterInput(value);
  };

  return (
    <>
      <InputContainer>
        <When isTrue={!!label}>
          <Typography variant="body2" as="label" margin="none">
            {label}
          </Typography>
        </When>
        <InputContainerStyle
          className={getClasses({
            editing: true,
            open: isOpen,
            compact,
          })}
          ref={anchorRef}
          onClick={(e) => {
            e.preventDefault();
            setIsOpen((prev) => !prev);
            requestAnimationFrame(() => {
              filterInputRef.current?.focus();
            });
          }}
        >
          <DropdownInput
            ref={filterInputRef}
            placeholder={
              multiple
                ? `${placeholder} (${formatMessage({ id: 'select.noSelected' })})`
                : placeholder
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                setIsOpen((prev) => !prev);
                requestAnimationFrame(() => {
                  filterInputRef.current?.focus();
                });
              }
            }}
            value={displayValue}
            onChange={onInput}
            readOnly={multiple}
          />

          <Expand />
        </InputContainerStyle>
      </InputContainer>

      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <StyledPopper
            open={isOpen}
            placement="bottom"
            anchorEl={anchorRef.current}
            style={{
              maxHeight: `${dynamicHeight}px`,
              width: `${parentWidth}px`,
            }}
          >
            <InnerWrapper>
              <When isTrue={multiple}>
                <ItemButton className={getClasses({ compact, filter: true })}>
                  <Input
                    value={filterInput}
                    placeholder={formatMessage({ id: 'select.search' })}
                    onChange={onInput}
                  />
                </ItemButton>
              </When>
              <When
                isTrue={!!renderItems && renderItems.length > 0}
                fallback={
                  <ItemButton className={getClasses({ compact })}>
                    {formatMessage({ id: 'dropdown.filter.noresult' })}
                  </ItemButton>
                }
              >
                {renderItems?.map((group, index) => (
                  <div key={group.name}>
                    <When isTrue={group.showName}>
                      <GroupTitle>
                        <Typography
                          variant="h4"
                          margin="none"
                          color="readOnlyOnLight"
                        >
                          {group.name}
                        </Typography>
                      </GroupTitle>
                    </When>
                    <InnerWrapper>
                      {group.items.map((item) => (
                        <When
                          key={item.label}
                          isTrue={multiple}
                          fallback={
                            <ItemButton
                              tabIndex={1}
                              onClick={() => onChange(item)}
                              className={getClasses({
                                hasSelection: !!value,
                                compact,
                              })}
                            >
                              {value === item.value ? <DoneIcon /> : <span />}
                              <Typography margin="none">
                                {item.label}
                              </Typography>
                            </ItemButton>
                          }
                        >
                          <ItemButton className={getClasses({ compact })}>
                            <Checkbox
                              checked={selection.some(
                                (sel) => sel === item.value
                              )}
                              onChange={() => onChange(item)}
                              label={item.label}
                            />
                          </ItemButton>
                        </When>
                      ))}
                      <When isTrue={index < renderItems.length - 1}>
                        <GroupDivider />
                      </When>
                    </InnerWrapper>
                  </div>
                ))}
              </When>
            </InnerWrapper>
          </StyledPopper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default DropdownWithSelect;
