import React from 'react';
import styled from '@emotion/styled';
import { Input, TableCell, TableRow, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { ccyFormat } from '@agoy/common';
import DeleteRowButtonBase from '_tax/components/Taxes/DeleteRowButton';
import EditField from '_shared/components/Inputs/EditField';
import CurrencyField from '_shared/components/Inputs/CurrencyField';
import {
  AccountPlanList,
  VoucherTransaction,
  VoucherTransactionType,
} from 'types/Voucher';

const StyledInput = styled(Input)`
  min-width: 250px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.grey[500]};
  padding: 0 6px;
  color: ${(props) => props.theme.palette.common.black};
`;

const StyledTableRow = styled(TableRow)`
  &:first-of-type {
    .MuiTableCell-root {
      padding: 12px 4px 6px 4px;
    }
  }

  .MuiTableCell-root {
    border: none;
    padding: 6px 4px 6px 4px;

    &:first-of-type {
      padding-left: 0;
      min-width: 50px;
    }
  }

  .autocomplete {
    .MuiOutlinedInput-notchedOutline {
      border-radius: 8px;
      border-color: ${({ theme }) => theme.palette.grey[500]};
    }
  }
`;

const PrintTableRow = styled(TableRow)`
  td:first-of-type {
    font-weight: 700;
  }

  .MuiTableCell-root {
    padding: 12px 4px;
  }
`;

const EditValueFieldInputProps = {
  InputProps: { disableUnderline: true },
};

const EditValueField = styled(CurrencyField)`
  border: 1px solid ${({ theme }) => theme.palette.grey[500]};
  border-radius: 8px;
  height: 40px;
  width: 100%;

  .MuiInputBase-root {
    min-width: 150px;
    padding-right: 8px;
    height: 100%;
  }

  .MuiInputBase-input {
    text-align: right;
  }
`;

const DeleteRowButton = styled(DeleteRowButtonBase)`
  border: none;
  margin-left: 0;
`;

const StyledTextField = styled(TextField)`
  min-width: 200px;
`;

interface VoucherTransactionRow {
  row: VoucherTransaction;
  accounts: AccountPlanList[];
  disabled?: boolean;
  onUpdateTransaction?: (
    transaction: VoucherTransaction,
    changedField: string
  ) => void;
  onRemoveTransaction?: (removeId: number | string) => void;
  editMode: boolean;
  roundDown?: boolean;
}

const TransactionRow = ({
  row,
  accounts,
  editMode,
  disabled = false,
  roundDown = false,
  onUpdateTransaction = () => {},
  onRemoveTransaction = () => {},
}: VoucherTransactionRow) => {
  const handleDebitCredit = (
    fieldType: VoucherTransactionType,
    value: number
  ) => {
    const newTransaction = {
      ...row,
      typeAmount: fieldType,
      debit: fieldType === 'debit' ? value : 0,
      credit: fieldType === 'credit' ? value : 0,
      amount: value,
    };
    onUpdateTransaction(newTransaction, fieldType);
  };

  const handleDescriptionChange = (value: string) => {
    const newTransaction = {
      ...row,
      sourceInfo: value,
    };
    onUpdateTransaction(newTransaction, 'sourceInfo');
  };

  const handleAccountChange = (value: number | null) => {
    if (value) {
      const newTransaction = {
        ...row,
        account: value,
      };
      onUpdateTransaction(newTransaction, 'account');
    }
  };

  const formatter = (value: number | undefined) => {
    if (value === undefined) {
      return '';
    }

    const valueToShow = roundDown && !editMode ? Math.floor(value) : value;
    return ccyFormat(valueToShow, 2);
  };

  if (editMode) {
    return (
      <StyledTableRow key={row.id}>
        <TableCell>
          <Autocomplete
            className="autocomplete"
            options={accounts}
            id={`accounts-${row.id}`}
            value={accounts.find((item) => item.value === row.account) || null}
            getOptionSelected={(option) => option.value === row.account}
            getOptionLabel={(option) =>
              `${option.value ? `${option.value} - ` : ''} ${option.label}`
            }
            getOptionDisabled={(option) => option.noActive}
            onChange={(e, value) =>
              handleAccountChange(value ? value.value : null)
            }
            renderInput={(params) => (
              <StyledTextField {...params} variant="outlined" />
            )}
            size="small"
            disabled={disabled}
          />
        </TableCell>
        <TableCell align="left">
          <EditField
            component={StyledInput}
            value={row.sourceInfo}
            onChange={handleDescriptionChange}
            disabled={disabled}
            InputProps={{
              disableUnderline: true,
              fullWidth: true,
            }}
          />
        </TableCell>
        <TableCell align="right">
          <EditValueField
            value={
              row.amount !== undefined &&
              row.typeAmount === 'debit' &&
              row.amount > 0
                ? row.amount
                : undefined
            }
            formatter={formatter}
            displayDecimals={2}
            InputProps={{ ...EditValueFieldInputProps, disabled }}
            onValueChange={(newValue) =>
              handleDebitCredit('debit', newValue || 0)
            }
          />
        </TableCell>
        <TableCell align="right">
          <EditValueField
            value={
              row.amount !== undefined &&
              row.typeAmount === 'credit' &&
              row.amount > 0
                ? row.amount
                : undefined
            }
            formatter={formatter}
            displayDecimals={2}
            InputProps={{ ...EditValueFieldInputProps, disabled }}
            onValueChange={(newValue) =>
              handleDebitCredit('credit', newValue || 0)
            }
          />
        </TableCell>
        {editMode && (
          <TableCell align="center">
            <DeleteRowButton
              onClick={() => onRemoveTransaction(row.id)}
              disabled={disabled}
            />
          </TableCell>
        )}
      </StyledTableRow>
    );
  }

  const roundFunction = roundDown ? Math.floor : (value) => value;

  return (
    <PrintTableRow key={row.id}>
      <TableCell>{row.account || ''}</TableCell>
      <TableCell>
        {accounts.find((item) => item.value === row.account)?.label}
      </TableCell>
      <TableCell>{row.sourceInfo}</TableCell>
      <TableCell align="right">
        {roundFunction(row.debit) !== 0 ? formatter(row.debit) : ''}
      </TableCell>
      <TableCell align="right">
        {roundFunction(row.credit) !== 0 ? formatter(row.credit) : ''}
      </TableCell>
    </PrintTableRow>
  );
};

export default TransactionRow;
