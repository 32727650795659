/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: nan
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  previousYear,
  year,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2018K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2018K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel(
    'Resultat från andelar i intresseföretag och gemensamt styrda företag inkl. nedskrivningar'
  ),
  data: {
    active: true,
    ResultatAndelarIntresseforetagGemensamtStyrdaForetagInklNedskrivningAbstract:
      {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Resultat från andelar i intresseföretag och gemensamt styrda företag inklusive nedskrivningar'
        ),
        table: table<IxbrlCell>(
          'notes.note18.data.ResultatAndelarIntresseforetagGemensamtStyrdaForetagInklNedskrivningAbstract.table',
          {
            id: 'ResultatAndelarIntresseforetagGemensamtStyrdaForetagInklNedskrivningAbstract',
            label:
              'Resultat från andelar i intresseföretag och gemensamt styrda företag',
            dataType: 'text',
            active: true,
          },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) ?? '',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) ?? '',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'ResultatAndelarIntresseforetagGemensamtStyrdaForetagInklNedskrivningAbstract',
                'hidden',
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  'ResultatAndelarIntresseforetagGemensamtStyrdaForetagUtdelning',
                  'row',
                  noteLabel('Utdelning'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaForetagUtdelning',
                    standardRubrik:
                      'Utdelning från intresseföretag och gemensamt styrda företag',
                    saldo: 'credit',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaForetagUtdelning',
                    standardRubrik:
                      'Utdelning från intresseföretag och gemensamt styrda företag',
                    saldo: 'credit',
                    negateValue: false,
                  })
                );
                rows.addRowWithType(
                  'ResultatAndelarIntresseforetagGemensamtStyrdaForetagEmissionsinsats',
                  'row',
                  noteLabel('Emissionsinsats'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaForetagEmissionsinsats',
                    standardRubrik:
                      'Emissionsinsats från intresseföretag och gemensamt styrda företag',
                    saldo: 'credit',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaForetagEmissionsinsats',
                    standardRubrik:
                      'Emissionsinsats från intresseföretag och gemensamt styrda företag',
                    saldo: 'credit',
                    negateValue: false,
                  })
                );
                rows.addRowWithType(
                  'ResultatAndelarIntresseforetagGemensamtStyrdaForetagResultatForsaljning',
                  'row',
                  noteLabel('Realisationsresultat vid försäljning'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaForetagResultatForsaljning',
                    standardRubrik:
                      'Realisationsresultat vid försäljning av andelar i intresseföretag och gemensamt styrda företag',
                    saldo: 'credit',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaForetagResultatForsaljning',
                    standardRubrik:
                      'Realisationsresultat vid försäljning av andelar i intresseföretag och gemensamt styrda företag',
                    saldo: 'credit',
                    negateValue: false,
                  })
                );
                rows.addRowWithType(
                  'ResultatAndelarIntresseforetagGemensamtStyrdaForetagResultatandelar',
                  'row',
                  noteLabel('Resultatandelar'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaForetagResultatandelar',
                    standardRubrik:
                      'Resultatandelar från intresseföretag och gemensamt styrda företag',
                    saldo: 'credit',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaForetagResultatandelar',
                    standardRubrik:
                      'Resultatandelar från intresseföretag och gemensamt styrda företag',
                    saldo: 'credit',
                    negateValue: false,
                  })
                );
                rows.addRowWithType(
                  'ResultatAndelarIntresseforetagGemensamtStyrdaForetagNedskrivning',
                  'row',
                  noteLabel('Nedskrivningar'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaForetagNedskrivning',
                    standardRubrik:
                      'Nedskrivningar av andelar i intresseföretag och gemensamt styrda företag',
                    saldo: 'debit',
                    negateValue: true,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaForetagNedskrivning',
                    standardRubrik:
                      'Nedskrivningar av andelar i intresseföretag och gemensamt styrda företag',
                    saldo: 'debit',
                    negateValue: true,
                  })
                );
                rows.addRowWithType(
                  'ResultatAndelarIntresseforetagGemensamtStyrdaForetagAterforingNedskrivning',
                  'row',
                  noteLabel('Återföring av nedskrivningar'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaForetagAterforingNedskrivning',
                    standardRubrik:
                      'Återföring av nedskrivningar av andelar i intresseföretag och gemensamt styrda företag',
                    saldo: 'credit',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaForetagAterforingNedskrivning',
                    standardRubrik:
                      'Återföring av nedskrivningar av andelar i intresseföretag och gemensamt styrda företag',
                    saldo: 'credit',
                    negateValue: false,
                  })
                );
                return rows.build();
              });
            rows.addRowWithType(
              'ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning',
              'sum',
              noteLabel('Summa'),
              ixbrlCell(
                ref(
                  sumAllowNull(
                    id(
                      'notes.note18.data.ResultatAndelarIntresseforetagGemensamtStyrdaForetagInklNedskrivningAbstract.table.ResultatAndelarIntresseforetagGemensamtStyrdaForetagInklNedskrivningAbstract.*.year'
                    )
                  )
                ),
                {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning',
                  standardRubrik:
                    'Resultat från andelar i intresseföretag och gemensamt styrda företag inklusive nedskrivningar',
                  saldo: 'credit',
                  negateValue: false,
                }
              ),
              ixbrlCell(
                ref(
                  sumAllowNull(
                    id(
                      'notes.note18.data.ResultatAndelarIntresseforetagGemensamtStyrdaForetagInklNedskrivningAbstract.table.ResultatAndelarIntresseforetagGemensamtStyrdaForetagInklNedskrivningAbstract.*.previousYear'
                    )
                  )
                ),
                {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning',
                  standardRubrik:
                    'Resultat från andelar i intresseföretag och gemensamt styrda företag inklusive nedskrivningar',
                  saldo: 'credit',
                  negateValue: false,
                }
              )
            );
            return rows.build();
          })
          .build(),
        NotResultatAndelarIntresseforetagGemensamtStyrdaForetagInklNedskrivningKommentar:
          {
            type: 'group',
            active: true,
            level: 0,
            title: field(
              'Kommentar till specifikation av resultat från andelar i intresseföretag och gemensamt styrda företag inklusive nedskrivningar'
            ),
            value: ixbrlField(field(''), {
              contextRef: 'period0',
              type: 'stringItemType',
              name: 'se-gen-base:NotResultatAndelarIntresseforetagGemensamtStyrdaForetagInklNedskrivningKommentar',
              standardRubrik:
                'Kommentar till specifikation av resultat från andelar i intresseföretag och gemensamt styrda företag inklusive nedskrivningar',
            }),
          },
      },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2018K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        ResultatAndelarIntresseforetagGemensamtStyrdaForetagInklNedskrivningAbstract:
          {
            type: 'part' as const,
            children: {
              type: type,
              level: number,
              active: boolean,

              title: cell,

              table: { type: 'table' as const },

              NotResultatAndelarIntresseforetagGemensamtStyrdaForetagInklNedskrivningKommentar:
                {
                  type: 'part' as const,
                  children: {
                    type: type,
                    level: number,
                    active: boolean,

                    title: cell,

                    value: cell,
                  },
                },
            },
          },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
