import React, { createContext, useMemo, useState } from 'react';
import {
  FinancialYear,
  Period,
  TaxSpecificationsAccountGroup,
} from '@agoy/api-sdk-core';
import {
  ActualBalances,
  BalanceAndInput,
} from '_reconciliation/components/ReconciliationView/types';
import {
  AgoyDocumentStructure,
  AgoyTable,
  AgoyTableRow,
  ResolvedReference,
} from '@agoy/document';
import { TaxDeclarationFormPartData } from '@agoy/tax-declaration-document';
import { PeriodDataContextType } from '_reconciliation/components/ReconciliationView/HiddenRow/HiddenGroupRow/utils/periodUtils';
import { InventoryObsolescenceDataType } from '_reconciliation/components/ReconciliationView/HiddenRow/Rows/LagerjusteringView/types';
import { MovedAccountsByPeriods } from '_reconciliation/components/ReconciliationView/RowContext/types';
import { SignaturePerson, UserInput } from '_reconciliation/types';
import { TaxData } from '_tax/service/TaxesDataContext';
import { RootState } from 'redux/reducers';
import { AccountingBalances } from 'types/Accounting';
import { VoucherPrintData } from 'types/Voucher';
import { PeriodDocument } from 'utils/usePeriodDocuments/Provider';
import {
  ExternCommentsRecord,
  DocumentType,
} from '_tax/components/Taxes/TaxSpecifications/types';
import { Comment } from '_shared/types';
import {
  BalanceHistoryItemType,
  CheckedHistoryItemType,
  EnableForPrint,
} from '../GroupsSpecificationInfo/types';
import {
  GetSpecificationType,
  SpecificationColumnType,
  SpecificationRowType,
} from '_clients/types/types';
import { SpecificationInputData } from '../SpecificationInfo';

export type PrintState = Partial<RootState> & {
  /**
   * Content for the PeriodDataContext in the print view
   */
  periodData?: Partial<PeriodDataContextType> &
    Pick<
      PeriodDataContextType,
      'financialYear' | 'period' | 'lastPeriod' | 'periodType' | 'yearEndPeriod'
    >;

  /**
   * Content for the TaxesDataContext in the print view
   */
  taxData?: Omit<TaxData, 'onToggleDetailedSpecification'>;

  /**
   * Content for the TaxesDataContext in the print view
   */
  propertyTaxData?: {
    total: number;
    currentYearRows: AgoyTableRow[];
    currentYearColumns: {
      id: string;
      align: string;
    }[];
    mainFhsValue: number;
    alternativeFhsValue: number;
  };

  /**
   * Additional parameters required for printing state
   */
  parameters?: Record<string, unknown>;
  additionalPages?: {
    orgName?: string;
    postalCode?: string;
    city?: string;
    definition: AgoyDocumentStructure;
    document: Record<string, TaxDeclarationFormPartData>;
  };

  voucherData?: VoucherPrintData;
  adjustmentsData?: Record<string, VoucherPrintData>;
  specificationsData?: {
    clientId: string;
    accounts: string[];
    documentsByAccount: Record<string, PeriodDocument[]>;
    period: Period;
    financialYear: FinancialYear;
    accountingBalances: AccountingBalances;
    userInputData: UserInput;
    actualFinancialYear: string;
    balanceAndInput: BalanceAndInput[];
    specifications: Record<string, GetSpecificationType>;
    isNewSpecifications: boolean;
  };
  taxSpecificationsData?: {
    tab: string;
    part?: string;
    groups: TaxSpecificationsAccountGroup[];
    accounts: string[];
    periodDocuments: DocumentType[];
    accountDocuments: PeriodDocument[];
    balanceChangeHistory: BalanceHistoryItemType[];
    checkedChangeHistory: CheckedHistoryItemType[];
    actualBalances: ActualBalances;
    groupsExternComments: ExternCommentsRecord;
    internalComments: Comment[];
    enableForPrint: EnableForPrint;
    movedAccountsByPeriods: MovedAccountsByPeriods;
    specifications: Record<string, GetSpecificationType>;
    isNewSpecifications: boolean;
  };
  cashViewData?: {
    accountNumber: string;
    signatures: SignaturePerson[];
    richText: string;
    location: string;
  };
  inventoryViewData?: {
    accountNumber: string;
    period: Period;
    signatures: SignaturePerson[];
    richText: string;
    location: string;
  };
  inventoryObsolescenceData?: {
    data: InventoryObsolescenceDataType;
    ub: number | undefined;
  };
  reconciliationSpecificationsData?: {
    legacySpecifications?: Client.LegacySpecification[];
    specifications?: {
      columns: SpecificationColumnType[];
      rows: SpecificationRowType[];
    };
    account: string;
    accountName: string;
    userData: SpecificationInputData & { yearIb: number };
  };
  taxFormHelpData?: {
    table: AgoyTable;
    title: string;
    propertyCode: ResolvedReference;
  };
};

type PrintStateContextType = {
  setState(state: PrintState);
  state: PrintState;
};

const PrintStateContext = createContext<PrintStateContextType>(
  {} as unknown as PrintStateContextType
);

export const withPrintState =
  (Component: React.FC) =>
  (props: Record<string, unknown>): JSX.Element => {
    const [state, setState] = useState({});

    const value = useMemo(() => ({ state, setState }), [state, setState]);

    return (
      <PrintStateContext.Provider value={value}>
        <Component {...props} />
      </PrintStateContext.Provider>
    );
  };
export default PrintStateContext;
