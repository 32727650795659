import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { formatPeriodToMonthAndYear } from 'utils';
import { InputData } from '_reconciliation/types';
import PeriodSummary from '_shared/components/PeriodSummary';

import PeriodDataContext from '../PeriodDataContext';
import SpecificationTable from './SpecificationTable';

const Container = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: max-content 1fr;
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

const PeriodSummaryWrapper = styled.div`
  margin-right: ${(props) => props.theme.spacing(4)}px;
`;

interface SpecificationViewProps {
  userData: InputData;
  accountNumber: string;
  accountName: string;
}

const SpecificationView = ({
  userData,
  accountNumber,
  accountName,
}: SpecificationViewProps) => {
  const { ib, ub, psaldo, saldo: userDataSaldo, yearIB } = userData || {};
  const saldo = userDataSaldo ? parseFloat(userDataSaldo) : 0;

  const { period } = useContext(PeriodDataContext);

  return (
    <Container>
      <PeriodSummaryWrapper>
        <PeriodSummary
          date={formatPeriodToMonthAndYear(period)}
          periodIb={ib}
          periodSaldo={psaldo}
          periodUb={ub}
          saldo={saldo}
          yearIB={yearIB}
        />
      </PeriodSummaryWrapper>

      <SpecificationTable
        accountNumber={accountNumber}
        accountName={accountName}
        periodIB={ib ?? 0}
        periodUB={ub ?? 0}
        yearIB={yearIB ?? 0}
      />
    </Container>
  );
};

export default SpecificationView;
