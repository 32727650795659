/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: nan
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  previousYear,
  year,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2020K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2020K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel(
    'Resultat från övriga företag som det finns ett ägarintresse i inkl. nedskrivningar'
  ),
  data: {
    active: true,
    ResultatOvrigaforetagAgarintresseInklNedskrivningAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Resultat från övriga företag som det finns ett ägarintresse i inklusive nedskrivningar'
      ),
      table: table<IxbrlCell>(
        'notes.note20.data.ResultatOvrigaforetagAgarintresseInklNedskrivningAbstract.table',
        {
          id: 'ResultatOvrigaforetagAgarintresseInklNedskrivningAbstract',
          label:
            'Resultat från övriga företag som det finns ett ägarintresse i',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'ResultatOvrigaforetagAgarintresseInklNedskrivningAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'ResultatOvrigaforetagAgarintresseUtdelning',
                'row',
                noteLabel('Utdelning'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaforetagAgarintresseUtdelning',
                  standardRubrik:
                    'Utdelning från övriga företag som det finns ett ägarintresse i',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaforetagAgarintresseUtdelning',
                  standardRubrik:
                    'Utdelning från övriga företag som det finns ett ägarintresse i',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ResultatOvrigaforetagAgarintresseEmissionsinsats',
                'row',
                noteLabel('Emissionsinsats'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaforetagAgarintresseEmissionsinsats',
                  standardRubrik:
                    'Emissionsinsats från övriga företag som det finns ett ägarintresse i',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaforetagAgarintresseEmissionsinsats',
                  standardRubrik:
                    'Emissionsinsats från övriga företag som det finns ett ägarintresse i',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ResultatOvrigaforetagAgarintresseResultatForsaljning',
                'row',
                noteLabel('Realisationsresultat vid försäljning'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaforetagAgarintresseResultatForsaljning',
                  standardRubrik:
                    'Realisationsresultat vid försäljning av andelar i övriga företag som det finns ett ägarintresse i',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaforetagAgarintresseResultatForsaljning',
                  standardRubrik:
                    'Realisationsresultat vid försäljning av andelar i övriga företag som det finns ett ägarintresse i',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ResultatOvrigaforetagAgarintresseResultatandelar',
                'row',
                noteLabel('Resultatandelar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaforetagAgarintresseResultatandelar',
                  standardRubrik:
                    'Resultatandelar från övriga företag som det finns ett ägarintresse i',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaforetagAgarintresseResultatandelar',
                  standardRubrik:
                    'Resultatandelar från övriga företag som det finns ett ägarintresse i',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ResultatOvrigaforetagAgarintresseNedskrivning',
                'row',
                noteLabel('Nedskrivningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaforetagAgarintresseNedskrivning',
                  standardRubrik:
                    'Nedskrivningar av andelar i övriga företag som det finns ett ägarintresse i',
                  saldo: 'debit',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaforetagAgarintresseNedskrivning',
                  standardRubrik:
                    'Nedskrivningar av andelar i övriga företag som det finns ett ägarintresse i',
                  saldo: 'debit',
                  negateValue: true,
                })
              );
              rows.addRowWithType(
                'ResultatOvrigaforetagAgarintresseAterforingNedskrivning',
                'row',
                noteLabel('Återföringar av nedskrivningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaforetagAgarintresseAterforingNedskrivning',
                  standardRubrik:
                    'Återföring av nedskrivningar av andelar i övriga företag som det finns ett ägarintresse i',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaforetagAgarintresseAterforingNedskrivning',
                  standardRubrik:
                    'Återföring av nedskrivningar av andelar i övriga företag som det finns ett ägarintresse i',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'ResultatOvrigaforetagAgarintresseInklNedskrivning',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note20.data.ResultatOvrigaforetagAgarintresseInklNedskrivningAbstract.table.ResultatOvrigaforetagAgarintresseInklNedskrivningAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'period0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ResultatOvrigaforetagAgarintresseInklNedskrivning',
                standardRubrik:
                  'Resultat från övriga företag som det finns ett ägarintresse i inklusive nedskrivningar',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note20.data.ResultatOvrigaforetagAgarintresseInklNedskrivningAbstract.table.ResultatOvrigaforetagAgarintresseInklNedskrivningAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'period1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ResultatOvrigaforetagAgarintresseInklNedskrivning',
                standardRubrik:
                  'Resultat från övriga företag som det finns ett ägarintresse i inklusive nedskrivningar',
                saldo: 'credit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NotResultatOvrigaforetagAgarintresseInklNedskrivningKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till specifikation resultat från övriga företag som det finns ett ägarintresse i'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotResultatOvrigaforetagAgarintresseInklNedskrivningKommentar',
          standardRubrik:
            'Kommentar till specifikation resultat från övriga företag som det finns ett ägarintresse i',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2020K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        ResultatOvrigaforetagAgarintresseInklNedskrivningAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotResultatOvrigaforetagAgarintresseInklNedskrivningKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
