import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useApiSdk } from '../../api-sdk';
import { useSelector } from '../../redux/reducers';
import { addGlobalErrorMessage } from '../../_messages/redux/actions';
import { useDispatch } from 'react-redux';
import { FEATURE_IDS } from '@agoy/common';
import { useHasFeature } from '../../_access-rights/hooks/useHasFeature';
import { useRouteMatch } from 'react-router-dom';
import { ClientsRouteParams } from 'routes/types';
import { format, parseFinancialYear } from '@agoy/dates';

export const NewSpecificationsFeatureSwitchContext = createContext<
  boolean | null
>(null);

export const useIsNewSpecifications = () => {
  return useContext(NewSpecificationsFeatureSwitchContext);
};

export const withNewSpecificationsFeatureSwitchContext =
  (Component) => (props) => {
    const route = useRouteMatch<ClientsRouteParams>();
    const dispatch = useDispatch();
    const sdk = useApiSdk();

    const hasNewSpecificationsFeature = useHasFeature(
      FEATURE_IDS.NEW_SPECIFICATIONS_FEATURE
    );

    const { clientId, financialYear } = route.params;

    const customer = useSelector((state) => state.customers[clientId]);

    const finYear = useMemo(() => {
      if (!customer?.rawFinancialYears) {
        return undefined;
      }

      const { start } = parseFinancialYear(financialYear);
      return customer.rawFinancialYears.find(
        (y) => y.start === format(start, 'yyyy-MM-dd')
      );
    }, [customer, financialYear]);

    const [isNewSpecifications, setIsNewSpecifications] = useState<
      boolean | null
    >(null);

    const fetchSpecificationsVersion = useCallback(async () => {
      if (!hasNewSpecificationsFeature) {
        setIsNewSpecifications(false);
        return;
      }
      if (clientId && finYear) {
        try {
          const specificationsVersion =
            await sdk.getFinancialYearSpecificationsVersion({
              clientid: clientId,
              financialYearId: finYear.id,
            });
          setIsNewSpecifications(specificationsVersion === 'new');
        } catch {
          dispatch(
            addGlobalErrorMessage(
              'reconciliation.hidden.specifications.versionError'
            )
          );
        }
      }
    }, [clientId, finYear, dispatch, hasNewSpecificationsFeature, sdk]);

    useEffect(() => {
      fetchSpecificationsVersion();
    }, [fetchSpecificationsVersion]);

    return (
      <NewSpecificationsFeatureSwitchContext.Provider
        value={isNewSpecifications}
      >
        <Component {...props} />
      </NewSpecificationsFeatureSwitchContext.Provider>
    );
  };
