import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Typography, Checkbox, Tooltip } from '@material-ui/core';
import isPropValid from '@emotion/is-prop-valid';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { last } from 'lodash';

import { useApiSdk, asResultClass } from 'api-sdk';
import isInputCorrect from 'utils/isInputCorrect';
import { reformatDate } from '@agoy/common';
import { addGlobalErrorMessage } from 'redux/actions';
import { ReconciliationBalanceAccountRow } from '@agoy/api-sdk-core';
import CurrencyField from '_shared/components/Inputs/CurrencyField';
import SaldoCheckbox from '_shared/components/SaldoCheckbox';
import { AccountingViewClient } from '_reconciliation/redux/accounting-view/reducer';
import { ReconciliationPeriod, createEmptyAccount } from '@agoy/reconciliation';
import { TimePeriod } from '@agoy/document';

import { HiddenGroupRow as HiddenGroupRowType } from '../../RowContext/types';
import { isPercentageRow, formatValue, getAccountColor } from '../../utils';
import { AccountNamesContext } from '../../AccountNamesContext';
import {
  LegacySpecifications,
  AccountBalance,
  ActualBalances,
  UserInput,
} from '../../types';

import SelectAccountDocuments from './SelectAccountDocuments';
import { useIsNewSpecifications } from '_shared/HOC/withNewSpecificationsFeatureSwitchContext';

const Table = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  overflow: scroll;

  > div {
    :nth-of-type(2) {
      > div:not(:nth-last-of-type(-n + 2)) {
        border-top: 2px solid #eeeeee;
      }

      > div:nth-of-type(1) {
        border-top-left-radius: 4px;
      }

      > div:nth-last-of-type(3) {
        border-top-right-radius: 4px;
      }
    }

    :last-of-type {
      > div:nth-of-type(1) {
        border-bottom-left-radius: 4px;
      }

      > div:nth-last-of-type(3) {
        border-bottom-right-radius: 4px;
      }
    }
  }
`;

const Row = styled.div<{ periodType: string }>`
  display: grid;
  grid-template-columns: ${({ periodType }) =>
    periodType === 'financialYear'
      ? 'minmax(300px, 3fr) repeat(2, 1fr) 1.6fr 1.2fr 3fr'
      : 'minmax(300px, 3fr) repeat(3, 1fr) 1.6fr 1.2fr 3fr'};
  min-width: 1200px;
`;

const HeaderRow = styled(Row)`
  height: max-content;
`;

const HeaderCell = styled.div<{ alignText?: 'start' | 'end' }>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-${({ alignText = 'start' }) => alignText};
  padding: 4px 8px;

  :nth-last-of-type(3) {
    margin-right: 16px;
  }
`;

const BodyCell = styled.div`
  min-height: 34px;

  :not(:nth-last-of-type(-n + 2)) {
    border-bottom: 2px solid #eeeeee;
    border-left: 2px solid #eeeeee;
  }
  :nth-last-of-type(3) {
    border-right: 2px solid #eeeeee;
    margin-right: 16px;
  }
  :nth-last-of-type(-n + 2) {
    padding: 1px;
  }
`;

const NameCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 5px;
`;

const AccountName = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const AccountNumber = styled(Typography)`
  font-weight: 600;
  margin-right: 8px;
`;

const SumName = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
`;

const BalanceCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  padding: 0 5px;
`;

const YearIngoingBalance = styled(BalanceCell)`
  background-color: #e3ecf0;
`;

const YearOutgoingBalance = styled(BalanceCell)`
  background-color: #eef3f6;
`;

const OutgoingBalance = styled(BalanceCell, {
  shouldForwardProp: isPropValid,
})<{
  correct: boolean | null;
  visited: boolean;
}>`
  background-color: ${({ visited, correct }) =>
    getAccountColor(visited, correct)};
`;

const SumBalanceCell = styled(BalanceCell)<{ textAlign?: 'start' | 'end' }>`
  font-weight: 600;
  justify-content: flex-${({ textAlign = 'end' }) => textAlign};
`;

const StyledCurrencyField = styled(CurrencyField)`
  .MuiOutlinedInput-root {
    height: 32px;
  }
  margin-right: 16px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  margin-left: 2px;
`;

const CorrectCheckbox = styled(Checkbox)`
  padding: 2px;

  color: ${({ theme, checked }) =>
    checked ? '#007031' : theme.palette.grey[500]};

  :hover {
    color: ${({ theme, checked }) =>
      checked ? '#003621' : theme.palette.grey[900]};
  }
`;

export const getUserInputByPeriodAndAccount = (
  state: AccountingViewClient,
  period: ReconciliationPeriod,
  accountNumber?: string
) => {
  const lastFinancialYear = last(period.financialYears);
  if (!lastFinancialYear) {
    return undefined;
  }
  const financialYear = TimePeriod.fromISODates(
    lastFinancialYear.start,
    lastFinancialYear.end
  ).value;
  const lastPeriod = last(period.periods);
  if (!lastPeriod) {
    return undefined;
  }

  return state.years[financialYear]?.userInput?.[`account${accountNumber}`]?.[
    reformatDate(lastPeriod.start, 'yyyy-MM-dd', 'yyyy-MM')
  ];
};

type NumberFieldProps = {
  defaultValue: number | null | undefined;
  disabled?: boolean;
  onValueChange: (value: number | undefined) => void;
};

const getDefaultBalance = (number: number) => ({
  hasComment: false,
  hasDocuments: false,
  hasInternalComments: false,
  hasSpecifications: false,
  ib: 0,
  number,
  state: 'not_started',
  type: 'account',
  ub: 0,
});

const NumberField = ({
  defaultValue,
  disabled = false,
  onValueChange,
}: NumberFieldProps) => {
  const [value, setValue] = useState(defaultValue ?? undefined);

  useEffect(() => {
    setValue(defaultValue ?? undefined);
  }, [defaultValue]);

  const handleValueChange = (newValue: number | undefined) => {
    onValueChange(newValue);
    setValue(newValue);
  };

  return (
    <StyledCurrencyField
      value={value}
      onValueChange={handleValueChange}
      disabled={disabled}
      InputProps={{
        variant: 'outlined',
      }}
    />
  );
};

type GroupedAccountsTableProps = {
  row: HiddenGroupRowType;
  clientId: string;
  periodBalances: AccountBalance;
  yearBalances: AccountBalance;
  actualBalances: ActualBalances;
  userInput: UserInput;
  specifications: LegacySpecifications;
  financialYearId: number;
};

const GroupedAccountsTable = ({
  row,
  clientId,
  periodBalances,
  yearBalances,
  actualBalances,
  userInput,
  specifications,
  financialYearId,
}: GroupedAccountsTableProps) => {
  const isNewSpecifications = useIsNewSpecifications();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const sdk = useApiSdk();
  const accountNames = useContext(AccountNamesContext);

  const { period, rows } = row;

  const financialYear = period.financialYears[0];

  const groupId = row.balance.id.split('.')[0];
  const lastPeriod = last(period.periods);
  const isDeadPeriod = period.type === 'dead';
  const yearAccountNames = accountNames[financialYearId] || {};

  const sumYearBalance = useMemo(() => {
    return rows.reduce(
      (sum, accountRow) => ({
        ib: sum.ib + (yearBalances[accountRow.number]?.ib || 0),
        ub: sum.ub + (yearBalances[accountRow.number]?.ub || 0),
      }),
      { ib: 0, ub: 0 }
    );
  }, [rows, yearBalances]);

  const actualBalanceSum = useMemo(() => {
    return Object.values(actualBalances).reduce((sum, balance) => {
      const actualBalance = balance?.final?.balance;
      return actualBalance ? sum + actualBalance : sum;
    }, 0);
  }, [actualBalances]);

  const isActualBalancesGroupConfirmed = useMemo(() => {
    return rows.every(({ number }) => {
      const actualBalance = actualBalances[number]?.final?.balance;
      const ub = periodBalances[number]?.ub || 0;

      return isInputCorrect(actualBalance, ub);
    });
  }, [rows, actualBalances, periodBalances]);

  const isEnabledGroupCheckbox = useMemo(() => {
    return rows.every(({ number }) => {
      const actualBalance = actualBalances[number]?.final?.balance;
      const ub = periodBalances[number]?.ub || 0;

      return specifications[number]?.length
        ? isInputCorrect(actualBalance, ub)
        : true;
    });
  }, [actualBalances, periodBalances, rows, specifications]);

  const onPutActualBalance = useCallback(
    async (accountNumbers: number[], value: number | null | 'current') => {
      if (!lastPeriod) {
        return;
      }

      const result = await asResultClass(
        sdk.putActualBalance({
          clientid: clientId,
          periodId: lastPeriod.id,
          account: accountNumbers,
          preliminary: lastPeriod.preliminary ?? false,
          requestBody: {
            balance: value,
            checked: false,
          },
        })
      );

      if (result.err) {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [clientId, dispatch, lastPeriod, sdk]
  );

  const onPutGroupActualBalance = useCallback(async () => {
    const accountsToUpdate = rows
      .map((account) => account.number)
      .filter((account) => !specifications[account]?.length);

    await onPutActualBalance(
      accountsToUpdate,
      isActualBalancesGroupConfirmed ? null : 'current'
    );
  }, [
    rows,
    onPutActualBalance,
    isActualBalancesGroupConfirmed,
    specifications,
  ]);

  const getFormattedPeriod = () => {
    let format = 'yy';

    switch (period.type) {
      case 'financialYear':
        format = 'yy';
        break;
      case 'quarter':
        format = 'QQQ';
        break;
      case 'month':
        format = 'MMM';
        break;
      default:
        format = 'yy';
    }
    return reformatDate(period.end, 'yyyy-MM-dd', format).replace('.', '');
  };

  return (
    <Table>
      <HeaderRow periodType={period.type}>
        <HeaderCell>
          {formatMessage({
            id: `reconciliation.row.${groupId}`,
          })}
        </HeaderCell>
        <HeaderCell alignText="end">
          {formatMessage(
            {
              id: 'reconciliation.hidden.group.yearIb',
            },
            {
              year: reformatDate(financialYear.end, 'yyyy-MM-dd', 'yy'),
            }
          )}
        </HeaderCell>
        <HeaderCell alignText="end">
          {formatMessage(
            {
              id: 'reconciliation.hidden.group.yearUb',
            },
            {
              year: reformatDate(financialYear.end, 'yyyy-MM-dd', 'yy'),
            }
          )}
        </HeaderCell>
        {period.type !== 'financialYear' && (
          <HeaderCell alignText="end">
            {formatMessage(
              {
                id: 'reconciliation.hidden.group.periodIb',
              },
              {
                period: getFormattedPeriod(),
              }
            )}
          </HeaderCell>
        )}
        <HeaderCell alignText="end">
          {formatMessage(
            {
              id: 'reconciliation.hidden.group.periodUb',
            },
            {
              period: getFormattedPeriod(),
            }
          )}
        </HeaderCell>
        <HeaderCell>
          {formatMessage({
            id: 'reconciliation.hidden.group.saldo',
          })}
        </HeaderCell>
        <HeaderCell>
          {formatMessage({
            id: 'reconciliation.hidden.group.underlag',
          })}
        </HeaderCell>
      </HeaderRow>

      {rows.map(({ number }) => {
        const yearBalance = (yearBalances[number] ||
          createEmptyAccount(number)) as ReconciliationBalanceAccountRow;

        if (!yearBalance) {
          return null;
        }

        const periodBalance = (periodBalances[number] ||
          getDefaultBalance(number)) as ReconciliationBalanceAccountRow;
        const balance = actualBalances[number]?.final?.balance;
        const isVisited = !!userInput[number]?.visited;
        const isCorrect =
          typeof balance === 'number' && period.type !== 'dead'
            ? isInputCorrect(balance, periodBalance.ub)
            : null;
        const hasSpecifications = isNewSpecifications
          ? periodBalance.hasSpecifications
          : periodBalance.hasLegacySpecifications;
        return (
          <Row key={number} periodType={period.type}>
            {yearAccountNames[number] && (
              <BodyCell>
                <NameCell>
                  <AccountNumber>{number}</AccountNumber>
                  <AccountName>{yearAccountNames[number].name}</AccountName>
                </NameCell>
              </BodyCell>
            )}
            <BodyCell>
              <YearIngoingBalance>
                {formatValue(yearBalance.ib)}
              </YearIngoingBalance>
            </BodyCell>
            <BodyCell>
              <YearOutgoingBalance>
                {formatValue(yearBalance.ub - yearBalance.ib)}
              </YearOutgoingBalance>
            </BodyCell>
            {period.type !== 'financialYear' && (
              <BodyCell>
                <BalanceCell>
                  {formatValue(periodBalance.ub - periodBalance.ib)}
                </BalanceCell>
              </BodyCell>
            )}
            <BodyCell>
              <OutgoingBalance correct={isCorrect} visited={isVisited}>
                {formatValue(periodBalance.ub)}
                {!isDeadPeriod && (
                  <SaldoCheckbox
                    state={periodBalance.state}
                    hasSpecifications={hasSpecifications}
                    visible
                    onClick={() => {
                      onPutActualBalance(
                        [number],
                        isCorrect ? null : periodBalance.ub
                      );
                    }}
                  />
                )}
              </OutgoingBalance>
            </BodyCell>
            <BodyCell>
              <NumberField
                defaultValue={balance}
                disabled={hasSpecifications || isDeadPeriod}
                onValueChange={(value) =>
                  onPutActualBalance([number], value ?? null)
                }
              />
            </BodyCell>
            <BodyCell>
              {lastPeriod && (
                <SelectAccountDocuments
                  clientId={clientId}
                  lastPeriod={lastPeriod}
                  accountGroup={groupId}
                  accountNumber={`${number}`}
                />
              )}
            </BodyCell>
          </Row>
        );
      })}

      <Row periodType={period.type}>
        <BodyCell>
          <NameCell>
            <SumName>
              {formatMessage({
                id: `reconciliation.row.${row.balance.id}`,
              })}
            </SumName>
          </NameCell>
        </BodyCell>
        <BodyCell>
          <SumBalanceCell>{formatValue(sumYearBalance.ib)}</SumBalanceCell>
        </BodyCell>
        <BodyCell>
          <SumBalanceCell>
            {formatValue(sumYearBalance.ub - sumYearBalance.ib)}
          </SumBalanceCell>
        </BodyCell>
        {period.type !== 'financialYear' && (
          <BodyCell>
            <SumBalanceCell>
              {row.balance.ub !== null && row.balance.ib !== null
                ? formatValue(
                    row.balance.ub - row.balance.ib,
                    isPercentageRow(row.balance.id)
                  )
                : ''}
            </SumBalanceCell>
          </BodyCell>
        )}

        <BodyCell>
          <SumBalanceCell>
            {row.balance.ub !== null ? formatValue(row.balance.ub) : ''}
            {!isDeadPeriod && (
              <Tooltip
                title={formatMessage({
                  id: isEnabledGroupCheckbox
                    ? 'reconciliation.hidden.group.checkAccounts'
                    : 'reconciliation.hidden.group.checkAccounts.disabled',
                })}
              >
                <IconWrapper>
                  <CorrectCheckbox
                    checked={isActualBalancesGroupConfirmed}
                    disabled={!isEnabledGroupCheckbox}
                    onClick={onPutGroupActualBalance}
                    color="default"
                    size="small"
                  />
                </IconWrapper>
              </Tooltip>
            )}
          </SumBalanceCell>
        </BodyCell>
        <BodyCell>
          <SumBalanceCell textAlign="start">
            {!!Object.keys(actualBalances).length &&
              formatValue(actualBalanceSum)}
          </SumBalanceCell>
        </BodyCell>
        <BodyCell />
      </Row>
    </Table>
  );
};

export default GroupedAccountsTable;
