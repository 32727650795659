import React from 'react';
import styled from '@emotion/styled';
import Typography from '../../../../UI/Typography/Typography';
import Spacer from '../../../../UI/helpers/Spacer';
import { formatRolesByComma } from '../../../../utils/formatRoles';
import { IXElement } from '../../../../ARKeyToIX';
import { AnnualReportVersion, IxbrlCell } from '@agoy/annual-report-document';
import { Tuple } from '../../../../xml/IX';
import { BoardMember } from './BoardMembersPreview';
import { format } from '@agoy/dates';
import { stringValue } from '@agoy/document';
import { useIntl } from 'react-intl';

const GridItem = styled.div`
  min-width: 300px;
  display: inline-block;
  padding-left: 0px;
  margin: 0 ${({ theme }) => theme.spacing(0.5)}px;

  p {
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`;

const ItalicContent = styled.div`
  display: block;
`;

type BoardMembersRowPreviewProps = {
  boardMember: BoardMember;
  isDigitalSubmission: boolean;
  isNewDateStructure: boolean;
  index: number;
  isDigitalSigningWithFortnox: boolean;
  isManualSigning: boolean;
  isDateSelected: boolean;
  version: AnnualReportVersion;
};

const returnTuple = (arPart, element: string, id: number): IxbrlCell => {
  let item;
  switch (element) {
    case 'givenName':
      item = '1.0';
      break;
    case 'surName':
      item = '2.0';
      break;
    case 'role':
      item = '3.0';
      break;
    case 'date':
      item = '5.0';
      break;
    default:
      console.log('error in item for returnTuple: ', element);
  }

  return {
    ...arPart,
    value: `X: ${arPart.value}`,
    ixbrl: {
      ...arPart.ixbrl,
      tupleRef: {
        tupleRef: `UnderskriftArsredovisningForetradareTuple${id + 1}`,
        order: item,
      },
    },
  };
};

const BoardMemberRowPreview = ({
  boardMember,
  isDigitalSubmission,
  isNewDateStructure,
  index,
  isDigitalSigningWithFortnox,
  isManualSigning,
  isDateSelected,
  version,
}: BoardMembersRowPreviewProps) => {
  const { formatMessage } = useIntl();
  const getDate = () => {
    if (version === '1') {
      return !boardMember.date.startsWith('$') ? boardMember.date : '';
    }

    // always need to show default date for member
    // because BV validation doesn't work without it (v2)
    if (version === '2') {
      return isDateSelected
        ? boardMember.date || format(new Date(), 'yyyy-MM-dd')
        : '';
    }
  };

  const renderDigitalSubmission = () => {
    return (
      <GridItem key={index}>
        {!isDigitalSigningWithFortnox &&
          isNewDateStructure &&
          !isManualSigning && (
            <ItalicContent>
              <Typography textStyle="italic" margin="none">
                {`${boardMember.givenName} ${boardMember.surName}`}
              </Typography>
            </ItalicContent>
          )}
        <Tuple
          name="se-gaap-ext:UnderskriftArsredovisningForetradareTuple"
          tupleID={`UnderskriftArsredovisningForetradareTuple${index + 1}`}
        />
        <Typography margin="none">
          <IXElement
            arPart={returnTuple(
              boardMember.cells?.givenName,
              'givenName',
              index
            )}
          >
            {boardMember.givenName}
          </IXElement>{' '}
          <IXElement
            arPart={returnTuple(boardMember.cells?.surName, 'surName', index)}
          >
            {boardMember.surName}
          </IXElement>
        </Typography>
        <Typography variant="body1" margin="none">
          {isDigitalSigningWithFortnox &&
          !stringValue(boardMember.cells?.date) ? (
            formatMessage({
              id: 'annualReport.signatures.section.dateFromSignature.label',
            })
          ) : (
            <IXElement
              arPart={returnTuple(boardMember.cells?.date, 'date', index)}
            >
              {getDate()}
            </IXElement>
          )}
        </Typography>

        <Typography variant="body2" textStyle="bold" margin="none">
          <IXElement
            arPart={returnTuple(boardMember.cells?.role, 'role', index)}
          >
            {formatRolesByComma(boardMember.roles)}
          </IXElement>
        </Typography>
      </GridItem>
    );
  };
  const renderManualSubmission = () => {
    return (
      <GridItem key={index}>
        <Spacer size={4} />
        <Typography>{`${boardMember.givenName} ${boardMember.surName}`}</Typography>

        {isNewDateStructure && version === '1' && (
          <Typography variant="body1">
            <IXElement arPart={returnTuple(boardMember.date, 'date', index)}>
              {boardMember.date.startsWith('$') ? '' : boardMember.date}
            </IXElement>
          </Typography>
        )}

        {isNewDateStructure && version === '2' && (
          <Typography variant="body1">
            {isDigitalSigningWithFortnox && !boardMember.date ? (
              formatMessage({
                id: 'annualReport.signatures.section.dateFromSignature.label',
              })
            ) : (
              <IXElement arPart={returnTuple(boardMember.date, 'date', index)}>
                {!isDateSelected || boardMember.date.startsWith('$')
                  ? ''
                  : boardMember.date}
              </IXElement>
            )}
          </Typography>
        )}

        <Typography variant="body2" textStyle="bold">
          {formatRolesByComma(boardMember.roles)}
        </Typography>
      </GridItem>
    );
  };

  return (
    <>
      {isDigitalSubmission
        ? renderDigitalSubmission()
        : renderManualSubmission()}
    </>
  );
};

export default BoardMemberRowPreview;
