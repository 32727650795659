import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import {
  FormControlLabel,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Checkbox,
  Dialog,
  TextField,
  Switch,
  Tooltip,
  TooltipProps,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  CheckBoxOutlined,
  Close,
  FileCopyOutlined as FileCopy,
  Forward,
} from '@material-ui/icons';
import { orderBy, last } from 'lodash';

import { AccountNamesContext } from '_reconciliation/components/ReconciliationView/AccountNamesContext';
import { useApiSdk } from 'api-sdk';
import { Period } from '@agoy/api-sdk-core';
import { useSelector } from 'redux/reducers';
import {
  currentClientForNewReconciliation,
  clientYear,
} from '_reconciliation/redux/accounting-view/selectors';
import { addGlobalMessage } from 'redux/actions';
import { InputData } from '_reconciliation/types';
import { parseFormat } from '@agoy/dates';
import { getQuarters } from '@agoy/reconciliation';
import Button from '_shared/components/Buttons/Button';

import useDocumentsUtils from './utils';
import PeriodDataContext from '../PeriodDataContext';
import { SaldoHistoryContext } from '../../HiddenRowHistoryProvider';
import { onUserInputRoutineCallbackFunction } from '../types';
import { trackCustomEvent } from '@fnox/web-analytics-script';
import { getAdditionalReconciliationPeriodData } from 'utils/piwik';

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacing(4)}px;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SelectsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const LabelsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(1)}px;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

const SwitchRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ButtonsColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  > div {
    margin-top: ${({ theme }) => theme.spacing(1)}px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  margin-bottom: -${({ theme }) => theme.spacing(8)}px;
`;

const Label = styled(Typography)`
  font-weight: bold;
  font-size: 20px;
`;

const StyledDialogTitle = styled(DialogTitle)`
  padding: 0 0 0 24px;
`;

const SubTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const StyledTextField = styled(TextField)`
  min-width: 200px;
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 4px 9px;
`;

const WhiteClose = styled(Close)`
  color: white;
`;

const StyledTooltip = styled(
  ({ className, children, ...other }: TooltipProps) => (
    <Tooltip classes={{ tooltip: className }} {...other}>
      {children}
    </Tooltip>
  )
)`
  padding-top: 10px;
`;

// 'specification' checkbox is removed/hidden until we implement it for new specifications
const checkboxes = ['comment', 'routine', 'documents'];

const defaultUserInputValues = {
  specification: [],
  comment: '',
  routine: '',
};

const validateFields = (formData) => {
  const requiredFields = ['fromAccount', 'fromPeriod', 'toAccount', 'toPeriod'];

  let validated = true;

  if (
    formData.fromAccount === formData.toAccount &&
    formData.fromPeriod === formData.toPeriod
  ) {
    return false;
  }

  requiredFields.forEach((field) => {
    if (!formData[field]) {
      validated = false;
    }
  });

  return validated;
};

type Account = {
  number: string;
  name: string;
};

type CopyDialogProps = {
  open: boolean;
  period: Period;
  accountNumber: string;
  currentTabId: string;
  onUserInputRoutineCallback: onUserInputRoutineCallbackFunction;
  onClose: () => void;
};

const CopyDialog = ({
  open,
  period,
  accountNumber,
  currentTabId,
  onUserInputRoutineCallback,
  onClose,
}: CopyDialogProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { copyAllDocuments } = useDocumentsUtils();
  const sdk = useApiSdk();

  const accountsNames = useContext(AccountNamesContext);
  const { onUserInputChange } = useContext(SaldoHistoryContext);
  const { financialYear, clientId, periodType, yearEndPeriod } =
    useContext(PeriodDataContext);

  const userInput = useSelector(
    clientYear(clientId, financialYear, (state) => state.userInput)
  );
  const { accounts } =
    useSelector(
      clientYear(clientId, financialYear, (state) => state.accountingBalances)
    ) || {};

  const periodFromState = useSelector(
    (state) => state.customers[clientId]
  ).closingPeriod;

  const routines = useSelector(
    currentClientForNewReconciliation(clientId, (state) => state.routines)
  );
  const rawFinancialYears = useSelector(
    (state) => state.customers[clientId].rawFinancialYears
  );

  const filteredAccounts = useMemo(() => {
    const resultAccounts: Account[] = [];
    const financialYearAccounts = accountsNames[financialYear.id];

    Object.keys(financialYearAccounts).forEach((accountKey) => {
      if (financialYearAccounts[accountKey].active) {
        resultAccounts.push({
          number: accountKey,
          name: financialYearAccounts[accountKey].name,
        });
      }
    });

    return resultAccounts;
  }, [periodType, accountsNames, financialYear.id, accounts]);

  const filteredPeriods = useMemo(() => {
    const resultPeriods: Period[] = [];
    const periodsMonths = financialYear.periods.filter(
      (item) => item.type === 'month'
    );

    if (periodFromState === 'quarter') {
      const financialYears = orderBy(rawFinancialYears, 'start');
      const quarters = getQuarters(clientId, financialYears, financialYear);

      quarters.forEach((item) => {
        const lastPeriod = last(item.periods);
        if (lastPeriod) {
          resultPeriods.push(lastPeriod);
        }
      });
    }

    if (periodFromState === 'year') {
      const lastPeriod = last(periodsMonths);
      if (lastPeriod) {
        resultPeriods.push(lastPeriod);
      }
    }

    if (periodFromState === 'month') {
      periodsMonths.forEach((item) => {
        resultPeriods.push(item);
      });
    }

    resultPeriods.push(yearEndPeriod);

    return resultPeriods;
  }, [
    clientId,
    financialYear,
    periodFromState,
    rawFinancialYears,
    yearEndPeriod,
  ]);

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [overwriteWarning, setOverwriteWarning] = useState(false);
  const [formData, setFormData] = useState<{
    fromAccount: Account | null;
    fromPeriod: Period | null;
    toAccount: Account | null;
    toPeriod: Period | null;
    comment: boolean;
    specification: boolean;
    routine: boolean;
    documents: boolean;
    overwrite: boolean;
    keepOpen: boolean;
  }>({
    fromAccount: null,
    fromPeriod: null,
    toAccount: null,
    toPeriod: null,
    comment: true,
    routine: true,
    specification: false,
    documents: false,
    overwrite: false,
    keepOpen: false,
  });

  useEffect(() => {
    const currentAccount = filteredAccounts.find(
      (item) => String(item.number) === accountNumber
    );
    const currentPeriodIndex = filteredPeriods.findIndex(
      (item) => item.id === period.id
    );

    const currentPeriod = filteredPeriods.find((item) => item.id === period.id);

    setFormData((currentValue) => ({
      ...currentValue,
      fromAccount: currentAccount || null,
      fromPeriod: currentPeriod || null,
      toAccount: currentAccount || null,
      toPeriod:
        filteredPeriods[
          currentPeriodIndex === filteredPeriods.length - 1
            ? currentPeriodIndex
            : currentPeriodIndex + 1
        ],
      comment: currentTabId === 'event-view',
      routine: currentTabId === 'event-view',
      documents: currentTabId === 'event-view',
      // unchecked until implemented for new specifications
      // currentTabId === 'specification', <- (add this again when implemented)
      specification: false,
    }));
  }, [period, accountNumber, filteredPeriods, currentTabId, filteredAccounts]);

  useEffect(() => {
    setDisabled(!validateFields(formData));
  }, [formData]);

  const getFullDate = (periodToFormat: Period) => {
    let format = '';

    switch (periodFromState) {
      case 'quarter':
        format = 'QQQ yyyy';
        break;
      case 'year':
        format = '-yy';
        break;
      default:
        format = 'MMMM yyyy';
    }
    if (periodToFormat.type === 'year_end') {
      return formatMessage({ id: 'closingperiod' });
    }
    const formatted = parseFormat(periodToFormat.start, format);
    return formatted.charAt(0).toLocaleUpperCase() + formatted.slice(1);
  };

  const handleCheckboxField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((currentFormData) => ({
      ...currentFormData,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleSelectField = (
    field: string,
    item: Period | { number: number; name: string } | null | Account
  ) => {
    setFormData((currentFormData) => ({
      ...currentFormData,
      [field]: item,
    }));
  };

  const closeOverwriteTooltip = () => {
    setOverwriteWarning((currentValue) => !currentValue);
  };

  const handleChangeOverwrite = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setOverwriteWarning(true);
    }
    handleCheckboxField(event);
  };

  const copyUserData = async (move = false) => {
    try {
      setLoading(true);

      const {
        fromAccount,
        fromPeriod,
        toAccount,
        toPeriod,
        documents,
        routine,
        overwrite,
        keepOpen,
        ...rest
      } = formData;

      if (
        !financialYear ||
        !fromPeriod ||
        !fromAccount ||
        !toAccount ||
        !toPeriod
      ) {
        return;
      }

      const fromData =
        userInput?.[`account${fromAccount.number}`]?.[
          parseFormat(fromPeriod.start, 'yyyy-MM')
        ] || {};
      const fromRoutine: string =
        routines?.[`account${fromAccount.number}`]?.[
          parseFormat(fromPeriod.start, 'yyyy-MM')
        ] || '';

      const toData =
        userInput?.[`account${toAccount.number}`]?.[
          parseFormat(toPeriod.start, 'yyyy-MM')
        ] || {};
      const toRoutine: string =
        routines?.[`account${toAccount.number}`]?.[
          parseFormat(toPeriod.start, 'yyyy-MM')
        ] || '';

      if (fromData) {
        const updatedFromData: Partial<InputData> = {};
        const updatedToData: Partial<InputData> = {};

        // New reconciliation
        const information: ('specification' | 'comment')[] = [];
        if (rest.comment) information.push('comment');
        if (rest.specification) information.push('specification');

        if (information.length > 0) {
          await sdk.copyUserInput({
            clientid: clientId,
            requestBody: {
              information,
              operation: move ? 'move' : 'copy',
              overwrite,
              sourceAccount: parseInt(fromAccount.number, 10),
              sourcePeriodId: fromPeriod.id,
              targetAccount: parseInt(toAccount.number, 10),
              targetPeriodId: toPeriod.id,
            },
          });
        }

        if (routine) {
          let updatedRoutine = '';

          if (overwrite) {
            updatedRoutine = fromRoutine;
          } else {
            updatedRoutine = toRoutine.length
              ? toRoutine.concat(`\n${fromRoutine}`)
              : toRoutine.concat(fromRoutine);
          }

          onUserInputRoutineCallback(
            {
              routine: updatedRoutine,
            },
            String(toAccount.number),
            toPeriod
          );
        }

        if (documents) {
          await copyAllDocuments({
            toAccount: String(toAccount.number),
            toPeriod,
            toFinancialYear: financialYear,
            fromAccount: String(fromAccount.number),
            fromPeriod,
            fromFinancialYear: financialYear,
            move,
            overwrite,
          });
        }

        if (move) {
          onUserInputChange(
            updatedFromData,
            String(fromAccount.number),
            fromPeriod
          );
          dispatch(addGlobalMessage('success', 'moved'));
        } else {
          dispatch(addGlobalMessage('success', 'copied'));
        }

        const action = move ? 'move' : 'copy';
        trackCustomEvent({
          eventCategory: 'reconciliations',
          eventAction: 'reconciliation_row:select',
          eventName: `dialog:${action}`,
          data: getAdditionalReconciliationPeriodData(period),
        });

        if (!keepOpen) {
          onClose();
        }
      }
    } catch (e) {
      dispatch(addGlobalMessage('error', 'error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <StyledDialogTitle>
        <HeaderRow>
          <Label>{formatMessage({ id: 'hidden.copy.title' })}</Label>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </HeaderRow>
      </StyledDialogTitle>
      <DialogContent>
        <SubTitle>{formatMessage({ id: 'hidden.copy.subTitle' })}</SubTitle>
        <LabelsRow>
          <Label>{formatMessage({ id: 'hidden.copy.from' })}</Label>
          <Label>{formatMessage({ id: 'hidden.copy.to' })}</Label>
        </LabelsRow>
        <Row>
          <SelectsRow>
            <Autocomplete
              options={filteredPeriods}
              value={formData.fromPeriod}
              onChange={(e, value) => handleSelectField('fromPeriod', value)}
              getOptionLabel={(option) => getFullDate(option)}
              renderInput={(params) => (
                <StyledTextField {...params} variant="outlined" />
              )}
              disabled={loading}
              size="small"
              fullWidth
            />
            <Autocomplete
              options={filteredAccounts}
              value={formData.fromAccount}
              onChange={(e, value) => handleSelectField('fromAccount', value)}
              getOptionLabel={(option) => `${option.number} ${option.name}`}
              renderInput={(params) => (
                <StyledTextField {...params} variant="outlined" />
              )}
              disabled={loading}
              size="small"
              fullWidth
            />
          </SelectsRow>
          <SelectsRow>
            <Autocomplete
              options={filteredPeriods}
              value={formData.toPeriod}
              getOptionLabel={(option) => getFullDate(option)}
              onChange={(e, value) => handleSelectField('toPeriod', value)}
              renderInput={(params) => (
                <StyledTextField {...params} variant="outlined" />
              )}
              disabled={loading}
              size="small"
              fullWidth
            />
            <Autocomplete
              options={filteredAccounts}
              value={formData.toAccount}
              getOptionLabel={(option) => `${option.number} ${option.name}`}
              onChange={(e, value) => handleSelectField('toAccount', value)}
              renderInput={(params) => (
                <StyledTextField {...params} variant="outlined" />
              )}
              disabled={loading}
              size="small"
              fullWidth
            />
          </SelectsRow>
        </Row>
        <Column>
          <Label>{formatMessage({ id: 'hidden.checkbox.title' })}</Label>

          {checkboxes.map((item) => (
            <FormControlLabel
              key={item}
              label={formatMessage({ id: `hidden.checkbox.${item}` })}
              labelPlacement="end"
              control={
                <StyledCheckbox
                  color="primary"
                  checkedIcon={<CheckBoxOutlined />}
                  checked={formData[item]}
                  name={item}
                  onChange={handleCheckboxField}
                  disabled={loading}
                />
              }
            />
          ))}
        </Column>

        <SwitchRow>
          <ButtonsColumn>
            <StyledTooltip
              title={
                <>
                  <HeaderRow>
                    <Typography>
                      {formatMessage({
                        id: 'hidden.switch.overwrite',
                      })}
                    </Typography>
                    <IconButton size="small" onClick={closeOverwriteTooltip}>
                      <WhiteClose />
                    </IconButton>
                  </HeaderRow>
                  <Typography color="inherit">
                    {formatMessage({
                      id: 'hidden.switch.overwrite.warning',
                    })}
                  </Typography>
                </>
              }
              open={overwriteWarning}
              placement="top-end"
              interactive
              arrow
            >
              <FormControlLabel
                control={
                  <Switch
                    name="overwrite"
                    checked={formData.overwrite}
                    onChange={handleChangeOverwrite}
                    disabled={loading}
                  />
                }
                label={
                  <Typography variant="body1" color="textSecondary">
                    {formatMessage({ id: 'hidden.switch.overwrite' })}
                  </Typography>
                }
                labelPlacement="start"
              />
            </StyledTooltip>
            <Tooltip
              title={formatMessage({
                id: 'hidden.switch.keepOpen.tooltip',
              })}
            >
              <FormControlLabel
                control={
                  <Switch
                    name="keepOpen"
                    checked={formData.keepOpen}
                    onChange={handleCheckboxField}
                    disabled={loading}
                  />
                }
                label={
                  <Typography variant="body1" color="textSecondary">
                    {formatMessage({ id: 'hidden.switch.keepOpen' })}
                  </Typography>
                }
                labelPlacement="start"
              />
            </Tooltip>
          </ButtonsColumn>
        </SwitchRow>
        <ButtonsRow>
          <Button
            label={formatMessage({ id: 'hidden.button.cancel' })}
            onClick={onClose}
            disabled={loading}
            variant="text"
          />
          <Button
            label={formatMessage({ id: 'hidden.button.copy' })}
            onClick={() => copyUserData()}
            disabled={loading || disabled}
            startIcon={<FileCopy />}
          />
          <Button
            label={formatMessage({ id: 'hidden.button.move' })}
            onClick={() => copyUserData(true)}
            disabled={loading || disabled}
            startIcon={<Forward />}
          />
        </ButtonsRow>
      </DialogContent>
    </Dialog>
  );
};

export default CopyDialog;
