import React, { useMemo, useContext, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip, TableCell, IconButton } from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/SubdirectoryArrowLeft';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MaterialPaginator from '@material-ui/lab/Pagination';
import styled from '@emotion/styled';
import { find } from 'lodash';

import { useSelector } from 'redux/reducers';
import { ccyFormat } from '@agoy/common';
import { Table } from '_shared/components/CommonTable';
import { TableProps } from '_shared/components/CommonTable/Table';
import {
  AgoyTableRow,
  Cell,
  numberValue,
  StringCell,
  stringValue,
  TimePeriod,
  value,
} from '@agoy/document';
import { Transaction } from '_shared/types';
import { PreviewFortnoxFileType } from 'types/PreviewFortnoxFile/types';
import PreviewFortnoxFile from '_shared/components/PreviewFortnoxFile/PreviewFortnoxFile';
import Button from '_shared/components/Buttons/Button';
import { parseFormat, reformat } from '@agoy/dates';
import { ClientCompanyType } from '_clients/types/types';

import AttachmentPreviewButton from './AttachmentPreviewButton';
import PeriodDataContext from '../PeriodDataContext';

const PaginatorWrapper = styled.div`
  margin-left: 250px;
  margin-top: 25px;
`;

const MultiDimensionTextWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${(props) =>
    props.theme.palette.transactionsanalysView.backgroundcolor};
  border-radius: 5px;
  width: 120px;
  display: inline-block;
  margin-left: 5px;
  padding-left: 5px;
  text-align: left;
`;

const StyledTable = styled(Table)`
  overflow-x: auto;
  min-width: 1000px;
`;

const StyledCell = styled(TableCell)`
  text-align: right;
`;

const ButtonTableCell = styled(StyledCell)`
  cursor: pointer;
`;

const CellText = styled.span`
  width: 100%;
`;

const CellTextLink = styled(CellText)`
  text-decoration: underline;
  color: ${(props) => props.theme.palette.secondary.main};
`;

const DimTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  position: absolute;
  top: 14px;
  left: 18px;
  height: 12px;
  width: 12px;
  border: 1px solid #fff;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 8px #fff;
  fill: ${(props) => props.theme.palette.primary.main};
`;

const StyledArrowIcon = styled(ArrowIcon)`
  fill: ${(props) => props.theme.palette.primary.main};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: ${(props) => props.theme.spacing(1)}px;
  padding-bottom: ${(props) => props.theme.spacing(4)}px;

  svg {
    margin-right: ${(props) => props.theme.spacing(1)}px;
  }
`;
interface DimensionTextProps {
  multi: boolean;
  text: string;
}

const DimensionTextColumn = ({ multi, text }: DimensionTextProps) => {
  return multi ? (
    <MultiDimensionTextWrapper key={text}>{text}</MultiDimensionTextWrapper>
  ) : (
    <CellText>{text}</CellText>
  );
};

const columns = [
  { id: 'arrow', label: '' },
  { id: 'attachment', label: '' },
  { id: 'ver' },
  { id: 'date' },
  { id: 'verificationText' },
  { id: 'transactionText' },
  { id: 'account' },
  { id: 'amount' },
  { id: 'saldo' },
  { id: 'dimText' },
  { id: 'comment' },
];

type TransactionTableProps = {
  clientId: string;
  financialYear?: ClientCompanyType['rawFinancialYears'][number];
  rawFinancialYears?: ClientCompanyType['rawFinancialYears'];
  transactions: Transaction[];
  selectedPage: number;
  totalNumberOfPages: number;
  isTransactionTransferred: (
    account: string,
    amount: string,
    ver: string
  ) => boolean;
  onTransferAllTransactions: () => Promise<void>;
  onTransferTransaction: (transaction: Transaction) => Promise<void>;
  onVerSelect: (ver: string, voucherId: number | null) => void;
  onPageChange: (page: number) => void;
};

const TransactionTable = ({
  clientId,
  financialYear,
  rawFinancialYears,
  transactions,
  selectedPage = 1,
  totalNumberOfPages,
  isTransactionTransferred,
  onTransferAllTransactions,
  onTransferTransaction,
  onVerSelect,
  onPageChange,
}: TransactionTableProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { periodLocked } = useContext(PeriodDataContext);

  const [attachmentPreviewVisible, setAttachmentPreviewVisible] =
    useState(false);
  const [attachments, setAttachmentFiles] = useState<PreviewFortnoxFileType[]>(
    []
  );
  const [attachmentState, setAttachmentState] = useState<
    Record<string, boolean>
  >({});
  const [isAllTransactionsLoading, setIsAllTransactionsLoading] =
    useState(false);
  const [transactionLoading, setTransactionLoading] = useState<number | null>(
    null
  );

  const withFortnoxIntegration = useSelector(
    (state) => !!state.customers[clientId]?.integrations?.fortnox
  );

  const voucherFinancialYear = useCallback(
    (voucherDate?: StringCell) => {
      if (!rawFinancialYears || !voucherDate) return '';
      // In this function we get the period ID through the voucher date.
      // In order to determine which financial year the voucher belongs to.

      const allPeriods = rawFinancialYears?.map((item) => item.periods).flat();
      const periodOfVoucher = allPeriods.filter(
        (item) =>
          parseFormat(item?.start, 'yyyy-MM') ===
            parseFormat(voucherDate.value, 'yyyy-MM') && item?.type === 'month'
      )[0]?.id;

      const financialYearOfVoucher = rawFinancialYears.filter((item) => {
        return find(item.periods, (i) => i.id === periodOfVoucher);
      });

      return TimePeriod.fromISODates(financialYearOfVoucher[0]).value;
    },
    [rawFinancialYears]
  );

  const handleShowAttachmentPreview = (
    transactionId: string,
    files: PreviewFortnoxFileType[]
  ) => {
    const newAttachmentState = { ...attachmentState };
    newAttachmentState[transactionId] = files.length === 0;
    setAttachmentState(newAttachmentState);
    setAttachmentPreviewVisible(true);
    setAttachmentFiles(files);
  };

  const handlePaginationChange = (e, newPageNumber) => {
    onPageChange(newPageNumber);
  };

  const rows: AgoyTableRow[] = useMemo(() => {
    return transactions.map((item, index) => {
      const {
        series,
        verificationNumber,
        dimText,
        verificationDate,
        transactionDate,
        voucherId,
      } = item;
      const cells: Record<string, Cell> = {};

      columns.forEach((col) => {
        cells[col.id] = {
          type: 'string',
          value: '',
        };
        if (col.id === 'ver') {
          cells[col.id] = value(`${series}-${verificationNumber}`);
          cells.voucherId = value(voucherId ?? undefined);
        } else if (col.id === 'dimText') {
          cells[col.id] = value(dimText.join(';'));
        } else if (col.id === 'date') {
          cells[col.id] = value(
            `${reformat(
              transactionDate || verificationDate,
              'yyyyMMdd',
              'yyyy-MM-dd'
            )}`
          );
        } else if (item[col.id]) {
          cells[col.id] = value(item[col.id]);
        }
      });

      return {
        id: `${index}`,
        active: true,
        cells,
      };
    });
  }, [transactions]);

  const alreadyTransferred = (cell: Record<string, Cell>) => {
    const reference = `ver ${stringValue(cell.ver)}`;
    const account = stringValue(cell.account);
    const amount = numberValue(cell.amount)?.toFixed(2);

    if (!account || !amount) {
      return false;
    }

    // Check if there is a user input with a reference that matches the cell's ver and account values
    return isTransactionTransferred(account, amount, reference);
  };

  const handleTransferAllTransactions = async () => {
    setIsAllTransactionsLoading(true);
    await onTransferAllTransactions();
    setIsAllTransactionsLoading(false);
  };

  const handleTransferTransaction = async (index: number) => {
    setTransactionLoading(index);
    await onTransferTransaction(transactions[index]);
    setTransactionLoading(null);
  };

  const renderCell: TableProps['renderCell'] = ({ cell, column, row }) => {
    if (column.id === 'arrow') {
      const isAlreadyTransferred = row.cells
        ? alreadyTransferred(row.cells)
        : false;

      const title = formatMessage({
        id: isAlreadyTransferred
          ? 'hidden.transactions.transferIconAlreadyTransfered'
          : 'hidden.transactions.transferIcon',
      });
      const index = Number.parseInt(row.id, 10);

      return (
        <TableCell key={column.id}>
          <Tooltip title={title} placement="top">
            <span>
              <IconButton
                disabled={transactionLoading === index || periodLocked}
                onClick={() => handleTransferTransaction(index)}
                size="small"
                color="secondary"
              >
                {isAlreadyTransferred ? (
                  <>
                    <StyledArrowIcon />
                    <StyledCheckCircleIcon />
                  </>
                ) : (
                  <ArrowIcon />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </TableCell>
      );
    }
    if (column.id === 'attachment') {
      return (
        <TableCell key={column.id}>
          {withFortnoxIntegration && financialYear && (
            <AttachmentPreviewButton
              clientId={clientId}
              voucher={stringValue(row.cells?.ver) ?? ''}
              isBlock={
                attachmentState[stringValue(row.cells?.ver) ?? ''] || false
              }
              voucherYear={voucherFinancialYear(row.cells?.date as StringCell)}
              onFetchDone={handleShowAttachmentPreview}
            />
          )}
        </TableCell>
      );
    }
    if (column.id === 'ver') {
      return (
        <ButtonTableCell
          key={column.id}
          onClick={() =>
            onVerSelect(
              stringValue(cell) ?? '',
              numberValue(row.cells?.voucherId) ?? -1
            )
          }
        >
          <CellTextLink>{stringValue(cell)}</CellTextLink>
        </ButtonTableCell>
      );
    }
    if (column.id === 'amount') {
      return (
        <StyledCell key={column.id}>
          <CellText>{ccyFormat(numberValue(cell))}</CellText>
        </StyledCell>
      );
    }
    if (column.id === 'dimText') {
      const values = stringValue(cell)?.split(';');
      return (
        <StyledCell key={column.id}>
          <DimTextWrapper>
            {values?.map((item: string) => (
              <DimensionTextColumn
                key={item}
                multi={values.length > 1}
                text={item}
              />
            ))}
          </DimTextWrapper>
        </StyledCell>
      );
    }
    return (
      <StyledCell key={column.id}>
        <CellText>
          {cell.type === 'number' ? ccyFormat(cell.value) : stringValue(cell)}
        </CellText>
      </StyledCell>
    );
  };

  return (
    <>
      {attachmentPreviewVisible && (
        <PreviewFortnoxFile
          clientId={clientId || ''}
          fileList={attachments}
          showModal={attachmentPreviewVisible}
          uploadFile
        />
      )}

      <ButtonWrapper>
        <Tooltip
          title={formatMessage({
            id: 'hidden.transactions.sendAllTransactionsButton.tooltip',
          })}
        >
          <Button
            label={formatMessage({
              id: 'hidden.transactions.sendAllTransactionsButton',
            })}
            onClick={handleTransferAllTransactions}
            disabled={!transactions.length || periodLocked}
            loading={isAllTransactionsLoading}
            startIcon={<ArrowIcon fontSize="small" />}
          />
        </Tooltip>
      </ButtonWrapper>
      <StyledTable
        baseId="hidden"
        tableId="transactions"
        rows={rows}
        columns={columns}
        renderCell={renderCell}
      />

      {transactions.length > 0 && (
        <PaginatorWrapper>
          <MaterialPaginator
            count={totalNumberOfPages}
            page={selectedPage}
            onChange={handlePaginationChange}
            disabled={false}
          />
        </PaginatorWrapper>
      )}
    </>
  );
};

export default TransactionTable;
