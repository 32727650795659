import { ReferenceAccountInformation, TimePeriod } from '@agoy/document';
import {
  signaturesConfig,
  manualSubmissionConfig,
  confirmationCertificateConfig,
  AnnualReportCustomerType,
} from '../common';
import { DocumentConfiguration } from '../document';
import {
  limitedCompanyBalanceSheetNotes,
  limitedCompanyIncomeStatementNotes,
  LimitedCompaniesAnnualReport,
  limitedCompanyOtherNotes,
} from './document';
import {
  balanceStatementK2v2Config,
  balanceStatementK2v2References,
} from './balance-sheet/balancestatementk2v2';
import {
  cashFlowStatementK2v2Config,
  cashFlowStatementK2v2References,
} from './cash-flow-statement/cashflowstatementk2v2';
import { notesConfigLimitedConfig } from './notes/notes';
import {
  costDividedIncomeStatementK2v2Config,
  costDividedIncomeStatementK2v2References,
} from './income-statement/costdividedincomestatementk2v2';
import { managementReportConfig } from './management-report/limitedCompaniesManagementReport';
import { settingsConfig } from './settings/limitedCompaniesSettings';

/**
 * Creates a new configuration of a annual report for economic association
 * @param customer
 * @param period  The time period for the financial year's start to
 *                the end of the current month
 * @param previousPeriod  The time period for the previous year's start
 *                        to the end of the current month minus one year
 * @param documentConfiguration Settings that affect the content of the report.
 */

export const limitedCompaniesNotes = {
  incomeStatementNotes: limitedCompanyIncomeStatementNotes,
  balanceSheetNotes: limitedCompanyBalanceSheetNotes,
  otherNotes: limitedCompanyOtherNotes,
};

export const limitedCompaniesConfig = (
  customer: AnnualReportCustomerType,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  accounts: Record<string, ReferenceAccountInformation>,
  documentConfiguration: DocumentConfiguration,
  isSinglePeriod: boolean
): LimitedCompaniesAnnualReport => {
  const isFirstFinancialYear =
    documentConfiguration.financialYears?.length === 1;

  const currentYear = documentConfiguration.financialYears[0];
  const financialYear = TimePeriod.fromISODates(
    currentYear.start,
    currentYear.end
  ).value;

  return {
    documentType: 'limited_companies',
    version: '2',
    settings: settingsConfig(
      customer,
      financialYear,
      period,
      previousPeriod,
      isFirstFinancialYear,
      isSinglePeriod
    ),
    confirmationCertificate: confirmationCertificateConfig(),
    managementReport: managementReportConfig(
      documentConfiguration.reportType,
      documentConfiguration.reportTransitionK3toK2,
      documentConfiguration.financialYears || []
    ),
    incomeStatement: costDividedIncomeStatementK2v2Config(
      accounts,
      period,
      previousPeriod,
      documentConfiguration.reportType,
      false
    ),
    balanceSheet: balanceStatementK2v2Config(
      accounts,
      period,
      previousPeriod,
      documentConfiguration.reportType,
      false
    ),
    cashFlowStatement: cashFlowStatementK2v2Config(
      accounts,
      period,
      previousPeriod,
      documentConfiguration.reportType,
      false
    ),
    notes: notesConfigLimitedConfig(
      accounts,
      customer.financialYears || [],
      financialYear,
      period.end,
      false
    ),
    signatures: signaturesConfig(),
    manualSubmission: manualSubmissionConfig(),
  };
};

/**
 * Creates all references that the annual report refers to
 */
export const references = (_: DocumentConfiguration) => {
  const sharedReferences = {};

  const partReferences = [
    sharedReferences,
    balanceStatementK2v2References(),
    costDividedIncomeStatementK2v2References(),
    cashFlowStatementK2v2References(),
  ];

  // Verify that there is no name conflict
  let references = {};
  partReferences.forEach((refs) => {
    const keysSoFar = Object.keys(references);
    const conflict = Object.keys(refs).find((ref) => keysSoFar.includes(ref));
    if (conflict) {
      throw new Error(`Conflicting keys in references (${conflict})`);
    }
    references = { ...references, ...refs };
  });

  return references;
};
