import React, { useState } from 'react';
import { SimplifiedDatePicker } from '_shared/components/Inputs';
import styled from '@emotion/styled';
import { format } from 'date-fns';

const Container = styled.div`
  display: flex;
`;

const Divider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
`;

const StyledSimplifiedDatePicker = styled(SimplifiedDatePicker)`
  .MuiInputBase-root {
    padding: 0;
    input {
      padding: 5px;
    }
  }
  .MuiIconButton-label {
    svg {
      width: 0.8em;
      height: 0.8em;
    }
  }
  .MuiOutlinedInput-root {
    background: unset;
  }
`;

type DateRangePickerProps = {
  onDateRangeUpdate: (start: string | null, end: string | null) => void;
  dateRange: { start: string | null; end: string | null };
  onKeyDown: (event: React.KeyboardEvent) => void;
};

const DateRangePicker = ({
  onDateRangeUpdate,
  dateRange,
  onKeyDown,
}: DateRangePickerProps) => {
  const [startDate, setStartDate] = useState<string | null>(dateRange.start);
  const [endDate, setEndDate] = useState<string | null>(dateRange.end);

  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);

  const handleOnAcceptDate = (date: Date | null, isStart: boolean) => {
    if (!date) return;

    const formattedDate = format(date, 'yyyy-MM-dd');

    if (isStart) {
      setStartDate(formattedDate);
      !endDate && setOpenEnd(true);
      if (formattedDate && endDate) {
        onDateRangeUpdate(formattedDate, endDate);
      }
    } else {
      setEndDate(formattedDate);
      !startDate && setOpenStart(true);
      if (formattedDate && startDate) {
        onDateRangeUpdate(startDate, formattedDate);
      }
    }
  };

  const handleOnBlur = () => {
    onDateRangeUpdate(startDate, endDate);
  };

  return (
    <Container>
      <StyledSimplifiedDatePicker
        editing
        value={startDate}
        onChange={(value) => setStartDate(value)}
        onAccept={(date) => handleOnAcceptDate(date, true)}
        onClose={() => setOpenStart(false)}
        onOpen={() => setOpenStart(true)}
        onBlur={() => handleOnBlur()}
        onKeyDown={onKeyDown}
        open={openStart}
        error={false}
        helperText=""
        width={140}
      />
      <Divider>
        <div>—</div>
      </Divider>
      <StyledSimplifiedDatePicker
        editing
        value={endDate}
        onChange={(value) => setEndDate(value)}
        onAccept={(date) => handleOnAcceptDate(date, false)}
        onClose={() => setOpenEnd(false)}
        onOpen={() => setOpenEnd(true)}
        onBlur={() => handleOnBlur()}
        onKeyDown={onKeyDown}
        open={openEnd}
        error={false}
        helperText=""
        width={140}
      />
    </Container>
  );
};

export default DateRangePicker;
