import React, { useMemo } from 'react';
import styled from '@emotion/styled';

import {
  SpecificationColumnType,
  SpecificationRowType,
} from '_clients/types/types';
import Typography from '_shared/components/Typography/Typography';
import { ccyFormat } from '@agoy/common';
import { getColumnsSum } from '../utils';

const StyledTable = styled.table`
  border-spacing: 0;
  table-layout: fixed;
  min-width: 100%;

  th,
  td {
    text-align: start;
    padding: 8px;
    height: 40px;
  }

  td {
    border-bottom: 1px solid #ccc;
  }

  th {
    border-bottom: 1px solid black;
  }

  tr:last-of-type {
    td {
      border-bottom: none;
    }
  }
`;

type PrintSpecificationsTableProps = {
  columns: SpecificationColumnType[];
  rows: SpecificationRowType[];
};

const PrintSpecificationsTable = ({
  columns,
  rows,
}: PrintSpecificationsTableProps) => {
  const columnsSum = useMemo(
    () => getColumnsSum(rows, columns),
    [columns, rows]
  );

  const getFormattedValue = (value, contentType) => {
    // Return empty string if it's undefined or null
    if (value == null) return '';

    switch (contentType) {
      case 'DATE_RANGE':
        return typeof value === 'string' ? value.replace(',', ' — ') : value;

      case 'PERCENTAGE':
        return value || value === 0
          ? `${value.toString().replaceAll('.', ',')}%`
          : value;

      case 'AMOUNT':
        return value || value === 0
          ? value.toString().replaceAll('.', ',')
          : value;

      default:
        return value;
    }
  };

  return (
    <StyledTable>
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.id}>
              <Typography textStyle="bold" margin="none">
                {column.name}
              </Typography>
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {rows.map((row) => (
          <tr key={row.id}>
            {columns.map((col) => (
              <td key={`${row.id}-${col.id}`}>
                <Typography margin="none">
                  {getFormattedValue(
                    row.cells.find((c) => c.columnId === col.id)?.value,
                    col.contentType
                  )}
                </Typography>
              </td>
            ))}
          </tr>
        ))}

        {/* Table footer */}
        <tr>
          {columns.map((col) => (
            <td key={col.id}>
              <Typography margin="none" textStyle="bold">
                {typeof columnsSum[col.id] === 'number'
                  ? `= ${ccyFormat(columnsSum[col.id], 2)}`
                  : ''}
              </Typography>
            </td>
          ))}
        </tr>
      </tbody>
    </StyledTable>
  );
};

export default PrintSpecificationsTable;
