import { parseFormat } from '@agoy/dates';

/**
 * A number format type is used to specify how a number should be formatted.
 *
 * - standard is the default format
 * - percentage update precision to change the number of decimals
 * - plain-integer is not affected by displayInThousands and does not format the number with spaces
 * - plain-integer-formatted is not affected by displayInThousands but format the number with spaces
 */
export type NumberFormatTypeVariants =
  | 'standard'
  | 'percentage'
  | 'plain-integer'
  | 'plain-integer-formatted';

export type NumberFormatType = {
  type: NumberFormatTypeVariants;
  precision: number;
  minValue?: number;
  maxValue?: number;
};

export const StandardNumberFormatType: NumberFormatType = {
  type: 'standard',
  precision: 0,
};

export const PercentageNumberFormatType: NumberFormatType = {
  type: 'percentage',
  precision: 2,
};

export const PercentageNumberFormatTypeWithPrecision = (
  precision: number
): NumberFormatType => ({
  type: 'percentage',
  precision: precision,
});

export const PlainIntegerNumberFormatType: NumberFormatType = {
  type: 'plain-integer',
  precision: 0,
};

export const PlainIntegerFormattedNumberFormatType: NumberFormatType = {
  type: 'plain-integer-formatted',
  precision: 0,
};

const customToFixed = (value: number, decimals: number) => {
  let factor = Math.pow(10, decimals);

  return (
    (Math.sign(value) * Math.round(Math.abs(value) * factor + Number.EPSILON)) /
    factor
  ).toFixed(decimals);
};

/**
 * It processes a number in US standard and displays it formatted in Swedish standard
 * Also makes big numbers easier to read Ex for 100k: 100 000
 * @param num: number or string input.
 * @param fractionDigits: Optional. To specify the number of decimals to display.
 */
export const ccyFormat = (
  num: string | number | undefined | null,
  fractionDigits = 0
): string => {
  if (num === 0) {
    return '0';
  }
  if (num === undefined || num === '' || num === null) {
    return '';
  }
  const value = typeof num === 'number' ? num : parseFloat(num);

  // Using toFixed instead of toLocaleString since it's way much faster
  const temp = customToFixed(value, fractionDigits).replace('.', ',');
  if (temp === '-0') {
    return '0';
  }

  let result = temp[0] === '-' ? '-' : '';
  // Insert thousand markers without the decimals
  const len = temp.length - (fractionDigits ? fractionDigits + 1 : 0);
  // Start after the minus sign
  const start = temp[0] === '-' ? 1 : 0;
  // eslint-disable-next-line no-plusplus
  for (let i = start; i < len; i++) {
    if (i > start && (len - i) % 3 === 0) {
      // Insert no-breaking-space as thousand markers
      result += '\u00a0';
    }
    result += temp.charAt(i);
  }
  // Add the decimals and return
  return result + temp.substring(len);
};

/**
 * Stripe only returns integers for their prices. The last two digits of the integer
 * are always decimals.
 * @param num The number to format
 */
export const formatStripefee = (num: string | number): string => {
  let stringified = `${num}`;
  // This is probably uneccesary as zero is represented as 000. Im just staying on the safe side
  if (stringified.length > 2) {
    stringified = [stringified.slice(0, 2), '.', stringified.slice(2)].join('');
  }

  return ccyFormat(parseFloat(stringified));
};

/**
 * Parses string with number in Swedish formatting, i.e. space as thousands
 * separator and comma as decimal separator, to a number.
 */
export const ccyParse = (
  value: string | number | undefined
): number | undefined => {
  if (typeof value === 'string') {
    const parsedValue = parseFloat(
      value.replace(/[\s.]+/g, '').replace(',', '.')
    );
    if (Number.isNaN(parsedValue)) {
      return undefined;
    }
    return parsedValue;
  }
  return value;
};

export const formatPercentage = (value: number | undefined, decimals = 2) => {
  if (value === undefined) return '';

  const percentage = value * 100.0;

  if (percentage === 0) return '0%';

  const formatted = ccyFormat(percentage, decimals);

  const [integerPart, decimalPart] = formatted.split(',');

  const hasTrimmedDecimals = decimalPart?.replace(/0+$/, '');
  return hasTrimmedDecimals
    ? `${integerPart},${hasTrimmedDecimals}%`
    : `${integerPart}%`;
};

export const parsePercentage = (value: string): number | undefined => {
  const trimmedValue = value.trim();
  const result = trimmedValue.endsWith('%')
    ? ccyParse(trimmedValue.substring(0, trimmedValue.length - 1))
    : ccyParse(trimmedValue);

  return result !== undefined ? result / 100 : undefined;
};

export const capitalize = (str: string): string =>
  str.length < 2
    ? str.toUpperCase()
    : `${str.substring(0, 1).toUpperCase()}${str.substring(1)}`;

/**
 * A subset of letters, since JS regexp doesn't support unicode
 */
const LETTERS = '[A-Za-z\u00AA\u00B5\u00BA\u00C0-\u00D6\u00D8-\u00F6]';

/**
 * Capitalizes each word in the string
 *
 * @param str
 * @returns
 */
export const capitalizeWords = (str: string): string =>
  str.replace(new RegExp(`${LETTERS}+`, 'g'), (w) =>
    capitalize(w.toLowerCase())
  );

export const parseDateStringToMonth = (dateString: string) => {
  const isoFormatted = `${dateString.slice(0, 4)}-${dateString.slice(4, 6)}`;
  return capitalize(parseFormat(isoFormatted, 'MMMM'));
};

export const parseDateStringToMonthAndYear = (dateString: string): string => {
  const isoFormatted = `${dateString.slice(0, 4)}-${dateString.slice(4, 6)}`;
  return ` ${capitalize(parseFormat(isoFormatted, 'MMMM'))} ${parseFormat(
    isoFormatted,
    'yyyy'
  )}`;
};

export const parseDateWithAbbreviatedMonth = (dateString: string): string => {
  const isoFormatted =
    dateString.charAt(4) === '-'
      ? dateString.substring(0, 7)
      : `${dateString.slice(0, 4)}-${dateString.slice(4, 6)}`;
  return ` ${capitalize(parseFormat(isoFormatted, 'MMM'))} ${parseFormat(
    isoFormatted,
    'yyyy'
  )}`;
};

const formatPositiveInteger = (n: number): string => {
  const str = n.toFixed(0);

  if (str.length <= 3) {
    return str;
  }

  const firstIndex = str.length % 3;
  const firstPart = firstIndex > 0 ? `${str.substring(0, firstIndex)} ` : '';

  return `${firstPart}${str
    .substring(firstIndex)
    .replace(/(\d{3})/g, ' $1')
    .trim()}`;
};

export const formatInteger = (n: number): string =>
  n <= -0.5
    ? `-${formatPositiveInteger(Math.abs(n))}`
    : formatPositiveInteger(Math.abs(n));

const formatPlainInteger = (value: number | undefined, _decimals?: number) =>
  String(value ?? 0);

/**
 * Returns a correct number formatter based on type
 */
export const buildNumberFormatter = (variant: NumberFormatTypeVariants) => {
  switch (variant) {
    case 'percentage':
      return formatPercentage;
    case 'standard':
      return ccyFormat;
    case 'plain-integer':
      return formatPlainInteger;
    default:
      return ccyFormat;
  }
};

/**
 * Returns formatType and formatPrecision based on the ixbrl tagging data
 * for people and percentage.
 * If there's no match it returns the standard number format.
 */
export const getNumericValueFormat = (
  unitRef: string | undefined,
  ixbrlType?: string
) => {
  // percentage in the ixbrl tagging
  if (unitRef === 'procent') {
    return PercentageNumberFormatType;
  }
  // Amount of people in the ixbrl tagging
  if (unitRef === 'antal-personer') {
    return PlainIntegerNumberFormatType;
  }

  if (ixbrlType === 'decimalItemType') {
    return {
      type: 'standard',
      precision: 2,
    } as NumberFormatType;
  }

  return StandardNumberFormatType;
};

/**
 * This function is used to get the display value based on the ixbrlType.
 * If the ixbrlType is 'monetaryItemType', it formats the number using the provided formatNumber function.
 * Otherwise, it returns the value as is.
 *
 * @param {string | undefined} ixbrlType - The type of the ixbrl item.
 * @param {number | undefined} value - The value to be formatted.
 * @param {string} type - The type of formatting to be applied.
 * @param {(value: number | undefined, type: string) => number | undefined} formatNumber - The function to format the number.
 * @returns {number | undefined} - The formatted value or the original value if no formatting is applied.
 */
export const getDisplayValue = (
  ixbrlType: string | undefined,
  value: number | undefined,
  type: NumberFormatTypeVariants,
  formatNumber: (value: number | undefined, type: string) => number | undefined
): number | undefined => {
  return ixbrlType === 'monetaryItemType' || ixbrlType === undefined
    ? formatNumber(value, type)
    : value;
};
