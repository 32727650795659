import React, { useContext } from 'react';
import styled from '@emotion/styled';

import SpecificationInfo, {
  SpecificationInputData,
} from '_shared/components/SpecificationInfo';
import {
  SpecificationColumnType,
  SpecificationRowType,
} from '_clients/types/types';

import LegacySpecificationTable from './SpecificationTable';
import PeriodDataContext from '../PeriodDataContext';
import PrintSpecificationsTables from '../SpecificationView/PrintSpecification/PrintSpecificationsTables';

const Content = styled.div`
  width: 100%;
  min-width: 800px;
`;

const TableWrapper = styled.div`
  margin-top: 20px;
`;

type SpecificationPrintModalProps = {
  specifications?: {
    columns: SpecificationColumnType[];
    rows: SpecificationRowType[];
  };
  legacySpecifications?: Client.LegacySpecification[];
  account: string;
  accountName: string;
  userData: SpecificationInputData;
};

const SpecificationPrint = ({
  specifications,
  legacySpecifications,
  account,
  accountName,
  userData,
}: SpecificationPrintModalProps): JSX.Element => {
  const { period, financialYear } = useContext(PeriodDataContext);

  return (
    <Content>
      <TableWrapper>
        <SpecificationInfo
          period={period}
          financialYear={financialYear}
          inputData={userData}
          accountName={accountName}
          accountNumber={account}
          isNewSpecifications={!!specifications}
        />

        {legacySpecifications && (
          <LegacySpecificationTable
            specification={legacySpecifications}
            accountNumber={account}
            print
          />
        )}

        {specifications && (
          <PrintSpecificationsTables
            rows={specifications.rows}
            columns={specifications.columns}
          />
        )}
      </TableWrapper>
    </Content>
  );
};

export default SpecificationPrint;
