/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6882 (Former Note 99)
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
  SelectFormat,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
  previousYear,
  year,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

const SkulderAvsattningarList: SelectFormat = {
  type: 'select',
  options: [
    {
      value: 'se-mem-base:SkulderAvsattningarObligationslanMember',
      label: 'Obligationslån',
    },
    {
      value: 'se-mem-base:SkulderAvsattningarCheckrakningskreditMember',
      label: 'Checkräkningskredit',
    },
    {
      value: 'se-mem-base:SkulderAvsattningarOvrigaSkulderKreditinstitutMember',
      label: 'Övriga skulder till kreditinstitut',
    },
    {
      value: 'se-mem-base:SkulderAvsattningarSkuldKoncernforetagMember',
      label: 'Skulder till koncernföretag',
    },
    {
      value:
        'se-mem-base:SkulderAvsattningarIntresseGemensamtStyrdaForetagMember',
      label: 'Skulder till intresseföretag och gemensamt styrda företag',
    },
    {
      value: 'se-mem-base:SkulderAvsattningarOvrigaForetagAgarintresseMember',
      label: 'Skulder till övriga företag som det finns ett ägarintresse i',
    },
    {
      value: 'se-mem-base:SkulderAvsattningarOvrigaSkulderMember',
      label: 'Övriga skulder',
    },
    {
      value: 'se-mem-base:SkulderAvsattningarAvsattningPensionerMember',
      label: 'Avsättning för pensioner',
    },
    {
      value: 'se-mem-base:SkulderAvsattningarOvrigaAvsattningarMember',
      label: 'Övriga avsättningar',
    },
  ],
};

const StalldaSakerheterList: SelectFormat = {
  type: 'select',
  options: [
    {
      value: 'se-mem-base:StalldaSakerheterForetagsinteckningarMember',
      label: 'Företagsinteckningar',
    },
    {
      value: 'se-mem-base:StalldaSakerheterFastighetsinteckningarMember',
      label: 'Fastighetsinteckningar',
    },
    {
      value: 'se-mem-base:StalldaSakerheterTomtrattsinteckningarMember',
      label: 'Tomträttsinteckningar',
    },
    {
      value: 'se-mem-base:StalldaSakerheterSkeppsinteckningarMember',
      label: 'Skeppsinteckningar',
    },
    {
      value: 'se-mem-base:StalldaSakerheterLuftfartygsinteckningarMember',
      label: 'Luftfartygsinteckningar',
    },
    {
      value: 'se-mem-base:StalldaSakerheterAndraInteckningarMember',
      label: 'Andra inteckningar',
    },
    {
      value:
        'se-mem-base:StalldaSakerheterTransportfordonAganderattsforbehallMember',
      label: 'Transportfordon med äganderättsförbehåll',
    },
    {
      value:
        'se-mem-base:StalldaSakerheterMaskinerInventarierAganderattsforbehallMember',
      label: 'Maskiner och inventarier med äganderättsförbehåll',
    },
    {
      value:
        'se-mem-base:StalldaSakerheterAktierAndraTillgangarAganderattsforbehallMember',
      label: 'Andra tillgångar med äganderättsförbehåll',
    },
    {
      value: 'se-mem-base:StalldaSakerheterAktierDotterbolagMember',
      label: 'Aktier i dotterföretag',
    },
    {
      value: 'se-mem-base:StalldaSakerheterAktierAnnatAnDotterbolagMember',
      label: 'Aktier i annat än dotterföretag',
    },
    {
      value: 'se-mem-base:StalldaSakerheterObligationerMember',
      label: 'Obligationer',
    },
    {
      value:
        'se-mem-base:StalldaSakerheterAndraVardepapperAnAktierObligationerMember',
      label: 'Andra värdepapper',
    },
    {
      value: 'se-mem-base:StalldaSakerheterBelanadeFordringarMember',
      label: 'Belånade fordringar',
    },
    {
      value: 'se-mem-base:StalldaSakerheterKundfordringarMember',
      label: 'Kundfordringar',
    },
    {
      value: 'se-mem-base:StalldaSakerheterSparradeBankmedelMember',
      label: 'Spärrade bankmedel',
    },
    {
      value: 'se-mem-base:StalldaSakerheterBankrakningarMember',
      label: 'Bankräkningar',
    },
    {
      value: 'se-mem-base:StalldaSakerheterVarulagerMember',
      label: 'Varulager',
    },
    {
      value: 'se-mem-base:StalldaSakerheterAndraStalldaSakerheterMember',
      label: 'Andra ställda säkerheter',
    },
  ],
};

export const getNote2082K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2082K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Ställda säkerheter'),
  data: {
    active: true,
    StalldaSakerheterFormanForetagetAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Ställda säkerheter för egen räkning'),
      StalldaSakerheterEgnaSkulderAvsattningarTuple: {
        type: 'group',
        active: true,
        level: 0,
        title: field('Ställda säkerheter för egna skulder och avsättningar'),
        table: table<IxbrlCell>(
          'notes.note82.data.StalldaSakerheterFormanForetagetAbstract.StalldaSakerheterEgnaSkulderAvsattningarTuple.table',
          {
            id: 'SkulderAvsattningarList',
            label: 'Typ av skuld eller avsättning',
            dataType: 'text',
            active: true,
          },
          {
            id: 'StalldaSakerheterList',
            label: 'Typ av säkerhet',
            dataType: 'text',
            active: true,
          },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) ?? '',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) ?? '',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'StalldaSakerheterEgnaSkulderAvsattningarTuple',
                'hidden',
                undefined,
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  '1',
                  'row',
                  ixbrlCell(
                    {
                      type: 'string',
                      value: SkulderAvsattningarList.options[0].value,
                      format: SkulderAvsattningarList,
                    },
                    {
                      type: 'enumerationItemType',
                      name: 'se-gen-base:SkulderAvsattningarList',
                      contextRef: 'balans0',
                      standardRubrik: 'Typ av skulder och avsättningar',
                      negateValue: false,
                      tuple: {
                        name: 'se-gaap-ext:StalldaSakerheterEgnaSkulderAvsattningarTuple',
                        id: `${rows.getBaseId()}.1.tuple`,
                        tupleID: `${rows.getBaseId()}.1.tuple`,
                      },
                      tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                      previousYearTuple: true,
                    }
                  ),
                  ixbrlCell(
                    {
                      type: 'string',
                      value: StalldaSakerheterList.options[0].value,
                      format: StalldaSakerheterList,
                    },
                    {
                      type: 'enumerationItemType',
                      name: 'se-gen-base:StalldaSakerheterList',
                      contextRef: 'balans0',
                      standardRubrik: 'Typ av ställd säkerhet',
                      negateValue: false,
                      tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                      previousYearTuple: true,
                    }
                  ),
                  ixbrlCell(value(undefined), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:StalldaSakerheterEgnaSkulderAvsattningar',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Belopp per ställd säkerhet för egna skulder och avsättningar',
                    negateValue: false,
                    tupleRef: tupleRef(
                      3,
                      `${rows.getBaseId()}.1.tuple_balans0`
                    ),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(undefined), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:StalldaSakerheterEgnaSkulderAvsattningar',
                    contextRef: 'balans1',
                    standardRubrik:
                      'Belopp per ställd säkerhet för egna skulder och avsättningar',
                    negateValue: false,
                    tupleRef: tupleRef(
                      4,
                      `${rows.getBaseId()}.1.tuple_balans1`
                    ),
                    previousYearTuple: false,
                  })
                );
                return rows.build();
              });
            rows.newRowTemplateGenerator((_id, baseId) => ({
              id: _id,
              active: true,
              type: 'row',
              cells: {
                SkulderAvsattningarList: ixbrlCell(
                  {
                    type: 'string',
                    value: SkulderAvsattningarList.options[0].value,
                    format: SkulderAvsattningarList,
                  },
                  {
                    type: 'enumerationItemType',
                    name: 'se-gen-base:SkulderAvsattningarList',
                    contextRef: 'balans0',
                    standardRubrik: 'Typ av skulder och avsättningar',
                    negateValue: false,
                    tuple: {
                      name: 'se-gaap-ext:StalldaSakerheterEgnaSkulderAvsattningarTuple',
                      id: `${baseId}.${_id}.tuple`,
                      tupleID: `${baseId}.${_id}.tuple`,
                    },
                    tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                    previousYearTuple: true,
                  }
                ),
                StalldaSakerheterList: ixbrlCell(
                  {
                    type: 'string',
                    value: StalldaSakerheterList.options[0].value,
                    format: StalldaSakerheterList,
                  },
                  {
                    type: 'enumerationItemType',
                    name: 'se-gen-base:StalldaSakerheterList',
                    contextRef: 'balans0',
                    standardRubrik: 'Typ av ställd säkerhet',
                    negateValue: false,
                    tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                    previousYearTuple: true,
                  }
                ),
                year: ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterEgnaSkulderAvsattningar',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Belopp per ställd säkerhet för egna skulder och avsättningar',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${baseId}.${_id}.tuple_balans0`),
                  previousYearTuple: false,
                }),
                previousYear: ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterEgnaSkulderAvsattningar',
                  contextRef: 'balans1',
                  standardRubrik:
                    'Belopp per ställd säkerhet för egna skulder och avsättningar',
                  negateValue: false,
                  tupleRef: tupleRef(4, `${baseId}.${_id}.tuple_balans1`),
                  previousYearTuple: false,
                }),
              },
            }));
            return rows.build();
          })
          .build(),
        StalldaSakerheterEgnaSkulderAvsattningarKommentar: {
          type: 'group',
          active: true,
          level: 0,
          visible: ref(
            id(
              'notes.note82.data.StalldaSakerheterFormanForetagetAbstract.StalldaSakerheterEgnaSkulderAvsattningarTuple.active'
            )
          ),
          title: field(
            'Kommentar till ställda säkerheter för egna skulder och avsättningar'
          ),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:StalldaSakerheterEgnaSkulderAvsattningarKommentar',
            standardRubrik:
              'Kommentar till ställda säkerheter för egna skulder och avsättningar',
          }),
        },
      },
      OvrigaStalldaSakerheterEgnaSkulderAvsattningarTuple: {
        type: 'group',
        active: true,
        level: 0,
        title: field('Övriga ställda säkerheter till förmån för företaget'),
        table: table<IxbrlCell>(
          'notes.note82.data.StalldaSakerheterFormanForetagetAbstract.OvrigaStalldaSakerheterEgnaSkulderAvsattningarTuple.table',
          {
            id: 'StalldaSakerheterList',
            label: 'Typ av säkerhet',
            dataType: 'text',
            active: true,
          },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) ?? '',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) ?? '',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'OvrigaStalldaSakerheterEgnaSkulderAvsattningarTuple',
                'hidden',
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  '1',
                  'row',
                  ixbrlCell(
                    {
                      type: 'string',
                      value: StalldaSakerheterList.options[0].value,
                      format: StalldaSakerheterList,
                    },
                    {
                      type: 'enumerationItemType',
                      name: 'se-gen-base:StalldaSakerheterList',
                      contextRef: 'balans0',
                      standardRubrik: 'Typ av ställd säkerhet',
                      negateValue: false,
                      tuple: {
                        name: 'se-gaap-ext:OvrigaStalldaSakerheterEgnaSkulderAvsattningarTuple',
                        id: `${rows.getBaseId()}.1.tuple`,
                        tupleID: `${rows.getBaseId()}.1.tuple`,
                      },
                      tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                      previousYearTuple: true,
                    }
                  ),
                  ixbrlCell(value(undefined), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:OvrigaStalldaSakerheterEgnaSkulderAvsattningar',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Belopp per övrig ställd säkerhet till förmån för företaget',
                    negateValue: false,
                    tupleRef: tupleRef(
                      2,
                      `${rows.getBaseId()}.1.tuple_balans0`
                    ),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(undefined), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:OvrigaStalldaSakerheterEgnaSkulderAvsattningar',
                    contextRef: 'balans1',
                    standardRubrik:
                      'Belopp per övrig ställd säkerhet till förmån för företaget',
                    negateValue: false,
                    tupleRef: tupleRef(
                      3,
                      `${rows.getBaseId()}.1.tuple_balans1`
                    ),
                    previousYearTuple: false,
                  })
                );
                return rows.build();
              });
            rows.newRowTemplateGenerator((_id, baseId) => ({
              id: _id,
              active: true,
              type: 'row',
              cells: {
                StalldaSakerheterList: ixbrlCell(
                  {
                    type: 'string',
                    value: StalldaSakerheterList.options[0].value,
                    format: StalldaSakerheterList,
                  },
                  {
                    type: 'enumerationItemType',
                    name: 'se-gen-base:StalldaSakerheterList',
                    contextRef: 'balans0',
                    standardRubrik: 'Typ av ställd säkerhet',
                    negateValue: false,
                    tuple: {
                      name: 'se-gaap-ext:OvrigaStalldaSakerheterEgnaSkulderAvsattningarTuple',
                      id: `${baseId}.${_id}.tuple`,
                      tupleID: `${baseId}.${_id}.tuple`,
                    },
                    tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                    previousYearTuple: true,
                  }
                ),
                year: ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaStalldaSakerheterEgnaSkulderAvsattningar',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Belopp per övrig ställd säkerhet till förmån för företaget',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${baseId}.${_id}.tuple_balans0`),
                  previousYearTuple: false,
                }),
                previousYear: ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaStalldaSakerheterEgnaSkulderAvsattningar',
                  contextRef: 'balans1',
                  standardRubrik:
                    'Belopp per övrig ställd säkerhet till förmån för företaget',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${baseId}.${_id}.tuple_balans1`),
                  previousYearTuple: false,
                }),
              },
            }));
            return rows.build();
          })
          .build(),
        OvrigaStalldaSakerheterEgnaSkulderAvsattningarKommentar: {
          type: 'group',
          active: true,
          level: 0,
          visible: ref(
            id(
              'notes.note82.data.StalldaSakerheterFormanForetagetAbstract.OvrigaStalldaSakerheterEgnaSkulderAvsattningarTuple.active'
            )
          ),
          title: field(
            'Kommentar till övriga ställda säkerheter till förmån för företaget'
          ),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:OvrigaStalldaSakerheterEgnaSkulderAvsattningarKommentar',
            standardRubrik:
              'Kommentar till övriga ställda säkerheter till förmån för företaget',
          }),
        },
      },
      StalldaSakerheterFormanKoncernForetagMedFleraAbstract: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Ställda säkerheter till förmån för koncernföretag, intresseföretag och gemensamt styrda företag'
        ),
        StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetagTuple: {
          type: 'group',
          active: true,
          level: 0,
          title: field(
            'Ställda säkerheter till förmån för koncernföretag, intresseföretag och gemensamt styrda företag'
          ),
          table: table<IxbrlCell>(
            'notes.note82.data.StalldaSakerheterFormanForetagetAbstract.StalldaSakerheterFormanKoncernForetagMedFleraAbstract.StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetagTuple.table',
            {
              id: 'StalldaSakerheterList',
              label: 'Typ av säkerhet',
              dataType: 'text',
              active: true,
            },
            {
              id: 'year',
              label: year(financialYear, financialYears, period) ?? '',
              dataType: 'numeric',
              active: true,
            },
            {
              id: 'previousYear',
              label: previousYear(financialYear, financialYears) ?? '',
              dataType: 'numeric',
              active: true,
            }
          )
            .addRows((rows) => {
              rows
                .addRowWithType(
                  'StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetagTuple',
                  'hidden',
                  undefined,
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    '1',
                    'row',
                    ixbrlCell(
                      {
                        type: 'string',
                        value: StalldaSakerheterList.options[0].value,
                        format: StalldaSakerheterList,
                      },
                      {
                        type: 'enumerationItemType',
                        name: 'se-gen-base:StalldaSakerheterList',
                        contextRef: 'balans0',
                        standardRubrik: 'Typ av ställd säkerhet',
                        negateValue: false,
                        tuple: {
                          name: 'se-gaap-ext:StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetagTuple',
                          id: `${rows.getBaseId()}.1.tuple`,
                          tupleID: `${rows.getBaseId()}.1.tuple`,
                        },
                        tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                        previousYearTuple: true,
                      }
                    ),
                    ixbrlCell(value(undefined), {
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetag',
                      contextRef: 'balans0',
                      standardRubrik:
                        'Belopp per ställd säkerhet till förmån för koncernföretag, intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                      tupleRef: tupleRef(
                        2,
                        `${rows.getBaseId()}.1.tuple_balans0`
                      ),
                      previousYearTuple: false,
                    }),
                    ixbrlCell(value(undefined), {
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetag',
                      contextRef: 'balans1',
                      standardRubrik:
                        'Belopp per ställd säkerhet till förmån för koncernföretag, intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                      tupleRef: tupleRef(
                        3,
                        `${rows.getBaseId()}.1.tuple_balans1`
                      ),
                      previousYearTuple: false,
                    })
                  );
                  return rows.build();
                });
              rows.newRowTemplateGenerator((_id, baseId) => ({
                id: _id,
                active: true,
                type: 'row',
                cells: {
                  StalldaSakerheterList: ixbrlCell(
                    {
                      type: 'string',
                      value: StalldaSakerheterList.options[0].value,
                      format: StalldaSakerheterList,
                    },
                    {
                      type: 'enumerationItemType',
                      name: 'se-gen-base:StalldaSakerheterList',
                      contextRef: 'balans0',
                      standardRubrik: 'Typ av ställd säkerhet',
                      negateValue: false,
                      tuple: {
                        name: 'se-gaap-ext:StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetagTuple',
                        id: `${baseId}.${_id}.tuple`,
                        tupleID: `${baseId}.${_id}.tuple`,
                      },
                      tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                      previousYearTuple: true,
                    }
                  ),
                  year: ixbrlCell(value(undefined), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetag',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Belopp per ställd säkerhet till förmån för koncernföretag, intresseföretag och gemensamt styrda företag',
                    negateValue: false,
                    tupleRef: tupleRef(2, `${baseId}.${_id}.tuple_balans0`),
                    previousYearTuple: false,
                  }),
                  previousYear: ixbrlCell(value(undefined), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetag',
                    contextRef: 'balans1',
                    standardRubrik:
                      'Belopp per ställd säkerhet till förmån för koncernföretag, intresseföretag och gemensamt styrda företag',
                    negateValue: false,
                    tupleRef: tupleRef(3, `${baseId}.${_id}.tuple_balans1`),
                    previousYearTuple: false,
                  }),
                },
              }));
              return rows.build();
            })
            .build(),
          StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetagKommentar:
            {
              type: 'group',
              active: true,
              level: 0,
              visible: ref(
                id(
                  'notes.note82.data.StalldaSakerheterFormanForetagetAbstract.StalldaSakerheterFormanKoncernForetagMedFleraAbstract.StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetagTuple.active'
                )
              ),
              title: field(
                'Kommentar till ställda säkerheter till förmån för koncernföretag, intresseföretag och gemensamt styrda företag'
              ),
              value: ixbrlField(field(''), {
                contextRef: 'period0',
                type: 'stringItemType',
                name: 'se-gen-base:StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetagKommentar',
                standardRubrik:
                  'Kommentar till ställda säkerheter till förmån för koncernföretag, intresseföretag och gemensamt styrda företag',
              }),
            },
        },
        StalldaSakerheterFormanAndraAbstract: {
          type: 'group',
          active: true,
          level: 0,
          title: field('Ställda säkerheter till förmån för andra'),
          StalldaSakerheterFormanAndraTuple: {
            type: 'group',
            active: true,
            level: 0,
            title: field('Ställda säkerheter till förmån för andra'),
            table: table<IxbrlCell>(
              'notes.note82.data.StalldaSakerheterFormanForetagetAbstract.StalldaSakerheterFormanKoncernForetagMedFleraAbstract.StalldaSakerheterFormanAndraAbstract.StalldaSakerheterFormanAndraTuple.table',
              {
                id: 'StalldaSakerheterList',
                label: 'Typ av säkerhet',
                dataType: 'text',
                active: true,
              },
              {
                id: 'year',
                label: year(financialYear, financialYears, period) ?? '',
                dataType: 'numeric',
                active: true,
              },
              {
                id: 'previousYear',
                label: previousYear(financialYear, financialYears) ?? '',
                dataType: 'numeric',
                active: true,
              }
            )
              .addRows((rows) => {
                rows
                  .addRowWithType(
                    'StalldaSakerheterFormanAndraTuple',
                    'hidden',
                    undefined,
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows.addRowWithType(
                      '1',
                      'row',
                      ixbrlCell(
                        {
                          type: 'string',
                          value: StalldaSakerheterList.options[0].value,
                          format: StalldaSakerheterList,
                        },
                        {
                          type: 'enumerationItemType',
                          name: 'se-gen-base:StalldaSakerheterList',
                          contextRef: 'balans0',
                          standardRubrik: 'Typ av ställd säkerhet',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:StalldaSakerheterFormanAndraTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: true,
                        }
                      ),
                      ixbrlCell(value(undefined), {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:StalldaSakerheterFormanAndra',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Belopp per ställd säkerhet till förmån för andra',
                        negateValue: false,
                        tupleRef: tupleRef(
                          2,
                          `${rows.getBaseId()}.1.tuple_balans0`
                        ),
                        previousYearTuple: false,
                      }),
                      ixbrlCell(value(undefined), {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:StalldaSakerheterFormanAndra',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Belopp per ställd säkerhet till förmån för andra',
                        negateValue: false,
                        tupleRef: tupleRef(
                          3,
                          `${rows.getBaseId()}.1.tuple_balans1`
                        ),
                        previousYearTuple: false,
                      })
                    );
                    return rows.build();
                  });
                rows.newRowTemplateGenerator((_id, baseId) => ({
                  id: _id,
                  active: true,
                  type: 'row',
                  cells: {
                    StalldaSakerheterList: ixbrlCell(
                      {
                        type: 'string',
                        value: StalldaSakerheterList.options[0].value,
                        format: StalldaSakerheterList,
                      },
                      {
                        type: 'enumerationItemType',
                        name: 'se-gen-base:StalldaSakerheterList',
                        contextRef: 'balans0',
                        standardRubrik: 'Typ av ställd säkerhet',
                        negateValue: false,
                        tuple: {
                          name: 'se-gaap-ext:StalldaSakerheterFormanAndraTuple',
                          id: `${baseId}.${_id}.tuple`,
                          tupleID: `${baseId}.${_id}.tuple`,
                        },
                        tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                        previousYearTuple: true,
                      }
                    ),
                    year: ixbrlCell(value(undefined), {
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:StalldaSakerheterFormanAndra',
                      contextRef: 'balans0',
                      standardRubrik:
                        'Belopp per ställd säkerhet till förmån för andra',
                      negateValue: false,
                      tupleRef: tupleRef(2, `${baseId}.${_id}.tuple_balans0`),
                      previousYearTuple: false,
                    }),
                    previousYear: ixbrlCell(value(undefined), {
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:StalldaSakerheterFormanAndra',
                      contextRef: 'balans1',
                      standardRubrik:
                        'Belopp per ställd säkerhet till förmån för andra',
                      negateValue: false,
                      tupleRef: tupleRef(3, `${baseId}.${_id}.tuple_balans1`),
                      previousYearTuple: false,
                    }),
                  },
                }));
                return rows.build();
              })
              .build(),
            StalldaSakerheterFormanAndraKommentar: {
              type: 'group',
              active: true,
              level: 0,
              visible: ref(
                id(
                  'notes.note82.data.StalldaSakerheterFormanForetagetAbstract.StalldaSakerheterFormanKoncernForetagMedFleraAbstract.StalldaSakerheterFormanAndraAbstract.StalldaSakerheterFormanAndraTuple.active'
                )
              ),
              title: field(
                'Kommentar till ställda säkerheter till förmån för andra'
              ),
              value: ixbrlField(field(''), {
                contextRef: 'period0',
                type: 'stringItemType',
                name: 'se-gen-base:StalldaSakerheterFormanAndraKommentar',
                standardRubrik:
                  'Kommentar till ställda säkerheter till förmån för andra',
              }),
            },
          },
          StalldaSakerheter: {
            type: 'group',
            active: true,
            level: 0,
            title: field('Ställda säkerheter'),
            table: table<IxbrlCell>(
              'notes.note82.data.StalldaSakerheterFormanForetagetAbstract.StalldaSakerheterFormanKoncernForetagMedFleraAbstract.StalldaSakerheterFormanAndraAbstract.StalldaSakerheter.table',
              {
                id: 'StalldaSakerheter',
                label: 'Summa ställda säkerheter',
                dataType: 'text',
                active: true,
              },
              {
                id: 'year',
                label: year(financialYear, financialYears, period) ?? '',
                dataType: 'numeric',
                active: true,
              },
              {
                id: 'previousYear',
                label: previousYear(financialYear, financialYears) ?? '',
                dataType: 'numeric',
                active: true,
              }
            )
              .addRows((rows) => {
                rows.addRowWithType(
                  'StalldaSakerheter',
                  'sum',
                  noteLabel('Summa ställda säkerheter'),
                  ixbrlCell(
                    ref(
                      sumAllowNull(
                        id(
                          'notes.note82.data.StalldaSakerheterFormanForetagetAbstract.StalldaSakerheterEgnaSkulderAvsattningarTuple.table.*.year'
                        ),
                        id(
                          'notes.note82.data.StalldaSakerheterFormanForetagetAbstract.OvrigaStalldaSakerheterEgnaSkulderAvsattningarTuple.table.*.year'
                        ),
                        id(
                          'notes.note82.data.StalldaSakerheterFormanForetagetAbstract.StalldaSakerheterFormanKoncernForetagMedFleraAbstract.StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetagTuple.table.*.year'
                        ),
                        id(
                          'notes.note82.data.StalldaSakerheterFormanForetagetAbstract.StalldaSakerheterFormanKoncernForetagMedFleraAbstract.StalldaSakerheterFormanAndraAbstract.StalldaSakerheterFormanAndraTuple.table.*.year'
                        )
                      )
                    ),
                    {
                      contextRef: 'balans0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:StalldaSakerheter',
                      standardRubrik: 'Ställda säkerheter',
                      negateValue: false,
                    }
                  ),
                  ixbrlCell(
                    ref(
                      sumAllowNull(
                        id(
                          'notes.note82.data.StalldaSakerheterFormanForetagetAbstract.StalldaSakerheterEgnaSkulderAvsattningarTuple.table.*.previousYear'
                        ),
                        id(
                          'notes.note82.data.StalldaSakerheterFormanForetagetAbstract.OvrigaStalldaSakerheterEgnaSkulderAvsattningarTuple.table.*.previousYear'
                        ),
                        id(
                          'notes.note82.data.StalldaSakerheterFormanForetagetAbstract.StalldaSakerheterFormanKoncernForetagMedFleraAbstract.StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetagTuple.table.*.previousYear'
                        ),
                        id(
                          'notes.note82.data.StalldaSakerheterFormanForetagetAbstract.StalldaSakerheterFormanKoncernForetagMedFleraAbstract.StalldaSakerheterFormanAndraAbstract.StalldaSakerheterFormanAndraTuple.table.*.previousYear'
                        )
                      )
                    ),
                    {
                      contextRef: 'balans1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:StalldaSakerheter',
                      standardRubrik: 'Ställda säkerheter',
                      negateValue: false,
                    }
                  )
                );
                return rows.build();
              })
              .build(),
          },
          NotStalldaSakerheterKommentarer: {
            type: 'group',
            active: true,
            level: 0,
            title: field('Kommentar till specifikation av ställda säkerheter'),
            value: ixbrlField(field(''), {
              contextRef: 'balans0',
              type: 'stringItemType',
              name: 'se-gen-base:NotStalldaSakerheterKommentarer',
              standardRubrik:
                'Kommentar till specifikation av ställda säkerheter',
            }),
          },
        },
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2082K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        StalldaSakerheterFormanForetagetAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            StalldaSakerheterEgnaSkulderAvsattningarTuple: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                table: { type: 'table' as const },

                StalldaSakerheterEgnaSkulderAvsattningarKommentar: {
                  type: 'part' as const,
                  children: {
                    type: type,
                    level: number,
                    active: boolean,
                    visible: cell,

                    title: cell,

                    value: cell,
                  },
                },
              },
            },

            OvrigaStalldaSakerheterEgnaSkulderAvsattningarTuple: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                table: { type: 'table' as const },

                OvrigaStalldaSakerheterEgnaSkulderAvsattningarKommentar: {
                  type: 'part' as const,
                  children: {
                    type: type,
                    level: number,
                    active: boolean,
                    visible: cell,

                    title: cell,

                    value: cell,
                  },
                },
              },
            },

            StalldaSakerheterFormanKoncernForetagMedFleraAbstract: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetagTuple:
                  {
                    type: 'part' as const,
                    children: {
                      type: type,
                      level: number,
                      active: boolean,

                      title: cell,

                      table: { type: 'table' as const },

                      StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetagKommentar:
                        {
                          type: 'part' as const,
                          children: {
                            type: type,
                            level: number,
                            active: boolean,
                            visible: cell,

                            title: cell,

                            value: cell,
                          },
                        },
                    },
                  },

                StalldaSakerheterFormanAndraAbstract: {
                  type: 'part' as const,
                  children: {
                    type: type,
                    level: number,
                    active: boolean,

                    title: cell,

                    StalldaSakerheterFormanAndraTuple: {
                      type: 'part' as const,
                      children: {
                        type: type,
                        level: number,
                        active: boolean,

                        title: cell,

                        table: { type: 'table' as const },

                        StalldaSakerheterFormanAndraKommentar: {
                          type: 'part' as const,
                          children: {
                            type: type,
                            level: number,
                            active: boolean,
                            visible: cell,

                            title: cell,

                            value: cell,
                          },
                        },
                      },
                    },

                    StalldaSakerheter: {
                      type: 'part' as const,
                      children: {
                        type: type,
                        level: number,
                        active: boolean,

                        title: cell,

                        table: { type: 'table' as const },
                      },
                    },

                    NotStalldaSakerheterKommentarer: {
                      type: 'part' as const,
                      children: {
                        type: type,
                        level: number,
                        active: boolean,

                        title: cell,

                        value: cell,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
