import React from 'react';
import { AgoyTableRow, Cell, stringValue } from '@agoy/document';
import styled from '@emotion/styled';
import {
  AnnualReportVersion,
  IxbrlCell,
  defaultRolesForSignatureTable,
} from '@agoy/annual-report-document';
import BoardMemberRowPreview from './BoardMemberRowPreview';
import { useIntl } from 'react-intl';
import { isAuditor } from '../../../../utils/isAuditor';

const Grid = styled.div`
  margin: ${({ theme }) => theme.spacing(1)}px 0;
  display: inline-block;
`;

type BoardMembersPreviewProps = {
  rows: AgoyTableRow[];
  isDigitalSubmission: boolean;
  isNewDateStructure: boolean;
  isDigitalSigningWithFortnox: boolean;
  isManualSigning: boolean;
  isDateSelected: boolean;
  version: AnnualReportVersion;
};
export interface BoardMember {
  cells: Record<string, IxbrlCell> | undefined;
  givenName: string;
  surName: string;
  roles: string;
  date: string;
}

const BoardMembersPreview = ({
  rows,
  isDigitalSubmission,
  isNewDateStructure,
  isDigitalSigningWithFortnox,
  isManualSigning,
  isDateSelected,
  version,
}: BoardMembersPreviewProps) => {
  const { formatMessage } = useIntl();

  let boardMembers: BoardMember[] = [];
  if (!rows || rows.length === 0) return null;

  const translateRole = (role: Cell | undefined): string => {
    const roleValue = stringValue(role)?.split('|') || [];

    const allRoles = Object.values(defaultRolesForSignatureTable).flat();

    return roleValue
      .map((role) =>
        allRoles.includes(role)
          ? formatMessage({ id: `clientInformation.${role}` })
          : role || ''
      )
      .join(', ');
  };

  boardMembers = rows
    .filter((row) => {
      // Exclude auditors and ensure givenName or surName is non-empty
      const givenName = (stringValue(row?.cells?.givenName) || '').trim();
      const surName = (stringValue(row?.cells?.surName) || '').trim();
      return !isAuditor(row, version) && (givenName !== '' || surName !== '');
    })
    .map((row) => {
      return {
        cells: row.cells,
        givenName: stringValue(row?.cells?.givenName) || '',
        surName: stringValue(row?.cells?.surName) || '',
        roles: translateRole(row?.cells?.role) || '',
        date: stringValue(row?.cells?.date) || '',
      };
    });

  return (
    <Grid>
      {boardMembers.map((boardMember, index) => (
        <BoardMemberRowPreview
          index={index}
          key={index}
          boardMember={boardMember}
          isDigitalSubmission={isDigitalSubmission}
          isNewDateStructure={isNewDateStructure}
          isDigitalSigningWithFortnox={isDigitalSigningWithFortnox}
          isManualSigning={isManualSigning}
          isDateSelected={isDateSelected || !!boardMember.date}
          version={version}
        />
      ))}
    </Grid>
  );
};

export default BoardMembersPreview;
