//
// NOTE: This is a generated file, do not edit manually.
// Run 'npm start individualTaxTables' from 'taxFetcher' repository to generate.
//

import {
  Cell,
  id,
  multiply,
  ref,
  sumAllowEmpty,
  table,
  value,
  min,
  max,
  limitMin,
  limitMax,
  ifOrElse,
  isSmallerThan,
  isGreaterThan,
  isGreaterThanOrEqualTo,
  roundToBase,
  floor,
} from '@agoy/document';
import { ConfigType, IndividualTaxType } from '@agoy/common';

export default function (
  annualConfig: ConfigType,
  individualTaxConfig: IndividualTaxType
) {
  return {
    SammanstallningForvarvsinkomst: {
      table: table<Cell>(
        'TS.fields.SammanstallningForvarvsinkomst.table',
        {
          id: 'label',
          label: 'Sammanställning förvärvsinkomst',
        },
        { id: 'sign' },
        { id: 'value' }
      )
        .addRows((rows) => {
          rows.addRowWithType('A', 'hidden').addSubRows((rows) => {
            rows.addRowWithType(
              '1',
              'row',
              value('Inkomst av tjänst efter avdrag'),
              value(1),
              ref(
                sumAllowEmpty(
                  id('INK1.INK1.fields.1_1'),
                  id('INK1.INK1.fields.1_2'),
                  id('INK1.INK1.fields.1_3'),
                  id('INK1.INK1.fields.1_4'),
                  id('INK1.INK1.fields.1_5'),
                  id('INK1.INK1.fields.1_6'),
                  id('INK1.INK1.fields.1_7'),
                  multiply(
                    -1,
                    sumAllowEmpty(
                      id('INK1.INK1.fields.2_1'),
                      id('INK1.INK1.fields.2_2'),
                      id('INK1.INK1.fields.2_3'),
                      id('INK1.INK1.fields.2_4')
                    )
                  )
                )
              )
            );
            rows.addRowWithType(
              '2',
              'row',
              value('Inkomst av näringsverksamhet'),
              value(1),
              ref(
                sumAllowEmpty(
                  id('INK1.INK1.fields.10_1_NE'),
                  id('INK1.INK1.fields.10_1_N3A'),
                  id('INK1.INK1.fields.10_3_NE'),
                  id('INK1.INK1.fields.10_3_N3A')
                )
              )
            );
            rows.addRowWithType(
              '3',
              'row',
              value('Allmänna avdrag'),
              value(-1),
              ref(
                sumAllowEmpty(
                  id('INK1.INK1.fields.3_1'),
                  id('INK1.INK1.fields.14_1')
                )
              )
            );
            rows.addRowWithType(
              'earnedIncome',
              'sum',
              value('Fastställd förvärvsinkomst'),
              value(undefined),
              ref(
                roundToBase(
                  sumAllowEmpty(
                    multiply(
                      id(
                        'TS.fields.SammanstallningForvarvsinkomst.table.A.1.sign'
                      ),
                      id(
                        'TS.fields.SammanstallningForvarvsinkomst.table.A.1.value'
                      )
                    ),
                    multiply(
                      id(
                        'TS.fields.SammanstallningForvarvsinkomst.table.A.2.sign'
                      ),
                      id(
                        'TS.fields.SammanstallningForvarvsinkomst.table.A.2.value'
                      )
                    ),
                    multiply(
                      id(
                        'TS.fields.SammanstallningForvarvsinkomst.table.A.3.sign'
                      ),
                      id(
                        'TS.fields.SammanstallningForvarvsinkomst.table.A.3.value'
                      )
                    )
                  ),
                  '100'
                )
              )
            );
            rows.addRowWithType(
              '4',
              'row',
              value('Grundavdrag'),
              value(-1),
              ref(id('TS.fields.taxFreeAllowance'))
            );
            rows.addRowWithType(
              '5',
              'row',
              value('Sjöinkomstavdrag'),
              value(-1),
              value(undefined)
            );
            rows.addRowWithType(
              'taxableIncome',
              'sum',
              value('Beskattningsbar förvärvsinkomst'),
              value(undefined),
              ref(
                sumAllowEmpty(
                  id(
                    'TS.fields.SammanstallningForvarvsinkomst.table.A.earnedIncome.value'
                  ),
                  multiply(
                    id(
                      'TS.fields.SammanstallningForvarvsinkomst.table.A.4.sign'
                    ),
                    id(
                      'TS.fields.SammanstallningForvarvsinkomst.table.A.4.value'
                    )
                  ),
                  multiply(
                    id(
                      'TS.fields.SammanstallningForvarvsinkomst.table.A.5.sign'
                    ),
                    id(
                      'TS.fields.SammanstallningForvarvsinkomst.table.A.5.value'
                    )
                  )
                )
              )
            );
            return rows.build();
          });
          return rows.build();
        })
        .build(),
    },
    SammanstallningKapitalinkomst: {
      table: table<Cell>(
        'TS.fields.SammanstallningKapitalinkomst.table',
        {
          id: 'label',
          label: 'Sammanställning kapitalinkomst',
        },
        { id: 'sign' },
        { id: 'value' }
      )
        .addRows((rows) => {
          rows.addRowWithType('B', 'hidden').addSubRows((rows) => {
            rows.addRowWithType(
              '1',
              'row',
              value('Ränteinkomster utdelning m.m.'),
              value(1),
              ref(
                sumAllowEmpty(
                  id('INK1.INK1.fields.7_1'),
                  id('INK1.INK1.fields.7_2'),
                  id('INK1.INK1.fields.7_3'),
                  id('INK1.INK1.fields.7_4'),
                  id('INK1.INK1.fields.7_5'),
                  id('INK1.INK1.fields.7_6'),
                  id('INK1.INK1.fields.7_7')
                )
              )
            );
            rows.addRowWithType(
              '2',
              'row',
              value('Ränteutgifter avdrag m.m.'),
              value(-1),
              ref(
                sumAllowEmpty(
                  id('INK1.INK1.fields.8_1'),
                  id('INK1.INK1.fields.8_2'),
                  id('INK1.INK1.fields.8_3'),
                  id('INK1.INK1.fields.8_4'),
                  id('INK1.INK1.fields.8_5'),
                  id('INK1.INK1.fields.8_6'),
                  id('INK1.INK1.fields.8_7')
                )
              )
            );
            rows.addRowWithType(
              '3',
              'row',
              value('Positiv räntefördelning'),
              value(1),
              ref(id('INK1.INK1.fields.11_1'))
            );
            rows.addRowWithType(
              '4',
              'row',
              value('Negativ räntefördelning'),
              value(-1),
              ref(multiply(0.0362, id('INK1.INK1.fields.11_2')))
            );
            rows.addRowWithType(
              '5',
              'row',
              value('Avkastningsskatt för utländsk kapitalförsäkring'),
              value(1),
              ref(roundToBase(id('INK1.INK1.fields.9_1'), '100'))
            );
            rows.addRowWithType(
              '6',
              'row',
              value('Avkastningsskatt för pensionsförsäkring'),
              value(1),
              ref(roundToBase(id('INK1.INK1.fields.9_2'), '100'))
            );
            rows
              .addRowWithType(
                'capitalSurplus',
                'sum',
                value('Överskott av kapital'),
                value(undefined),
                ref(
                  sumAllowEmpty(
                    multiply(
                      id(
                        'TS.fields.SammanstallningKapitalinkomst.table.B.1.sign'
                      ),
                      id(
                        'TS.fields.SammanstallningKapitalinkomst.table.B.1.value'
                      )
                    ),
                    multiply(
                      id(
                        'TS.fields.SammanstallningKapitalinkomst.table.B.2.sign'
                      ),
                      id(
                        'TS.fields.SammanstallningKapitalinkomst.table.B.2.value'
                      )
                    ),
                    multiply(
                      id(
                        'TS.fields.SammanstallningKapitalinkomst.table.B.3.sign'
                      ),
                      id(
                        'TS.fields.SammanstallningKapitalinkomst.table.B.3.value'
                      )
                    ),
                    multiply(
                      id(
                        'TS.fields.SammanstallningKapitalinkomst.table.B.4.sign'
                      ),
                      id(
                        'TS.fields.SammanstallningKapitalinkomst.table.B.4.value'
                      )
                    ),
                    multiply(
                      id(
                        'TS.fields.SammanstallningKapitalinkomst.table.B.5.sign'
                      ),
                      id(
                        'TS.fields.SammanstallningKapitalinkomst.table.B.5.value'
                      )
                    ),
                    multiply(
                      id(
                        'TS.fields.SammanstallningKapitalinkomst.table.B.6.sign'
                      ),
                      id(
                        'TS.fields.SammanstallningKapitalinkomst.table.B.6.value'
                      )
                    )
                  )
                )
              )
              .setRowActive(
                ref(
                  isGreaterThanOrEqualTo(
                    id(`${rows.getBaseId()}.capitalSurplus.value`),
                    '0'
                  )
                )
              );
            rows
              .addRowWithType(
                'capitalDeficit',
                'sum',
                value('Underskott av kapital'),
                value(undefined),
                ref(
                  max(
                    multiply(
                      -1,
                      sumAllowEmpty(
                        multiply(
                          id(
                            'TS.fields.SammanstallningKapitalinkomst.table.B.1.sign'
                          ),
                          id(
                            'TS.fields.SammanstallningKapitalinkomst.table.B.1.value'
                          )
                        ),
                        multiply(
                          id(
                            'TS.fields.SammanstallningKapitalinkomst.table.B.2.sign'
                          ),
                          id(
                            'TS.fields.SammanstallningKapitalinkomst.table.B.2.value'
                          )
                        ),
                        multiply(
                          id(
                            'TS.fields.SammanstallningKapitalinkomst.table.B.3.sign'
                          ),
                          id(
                            'TS.fields.SammanstallningKapitalinkomst.table.B.3.value'
                          )
                        ),
                        multiply(
                          id(
                            'TS.fields.SammanstallningKapitalinkomst.table.B.4.sign'
                          ),
                          id(
                            'TS.fields.SammanstallningKapitalinkomst.table.B.4.value'
                          )
                        ),
                        multiply(
                          id(
                            'TS.fields.SammanstallningKapitalinkomst.table.B.5.sign'
                          ),
                          id(
                            'TS.fields.SammanstallningKapitalinkomst.table.B.5.value'
                          )
                        ),
                        multiply(
                          id(
                            'TS.fields.SammanstallningKapitalinkomst.table.B.6.sign'
                          ),
                          id(
                            'TS.fields.SammanstallningKapitalinkomst.table.B.6.value'
                          )
                        )
                      )
                    ),
                    0
                  )
                )
              )
              .setRowActive(
                ref(
                  isGreaterThan(
                    id(`${rows.getBaseId()}.capitalDeficit.value`),
                    '0'
                  )
                )
              );
            return rows.build();
          });
          return rows.build();
        })
        .build(),
    },
    SkatterOchAvgifter: {
      table: table<Cell>(
        'TS.fields.SkatterOchAvgifter.table',
        {
          id: 'label',
          label: 'Dina skatter och avgifter',
        },
        { id: 'sign' },
        { id: 'value' }
      )
        .addRows((rows) => {
          rows.addRowWithType('C', 'hidden').addSubRows((rows) => {
            rows.addRowWithType(
              '1',
              'row',
              value('Kommunal inkomstskatt'),
              value(1),
              ref(
                multiply(
                  id('TS.fields.municipalTaxRate'),
                  id(
                    'TS.fields.SammanstallningForvarvsinkomst.table.A.taxableIncome.value'
                  )
                )
              )
            );
            rows.addRowWithType(
              '2',
              'row',
              value('Statlig inkomstskatt på förvärvsinkomst'),
              value(1),
              ref(
                multiply(
                  0.2,
                  max(
                    sumAllowEmpty(
                      id(
                        'TS.fields.SammanstallningForvarvsinkomst.table.A.taxableIncome.value'
                      ),
                      multiply(-1, individualTaxConfig.incomeThresholdAmount)
                    ),
                    0
                  )
                )
              )
            );
            rows
              .addRowWithType(
                '3',
                'row',
                value('Statlig inkomstskatt på kapitalinkomst'),
                value(1),
                ref(
                  max(
                    multiply(
                      0.3,
                      sumAllowEmpty(
                        id(
                          'TS.fields.SammanstallningKapitalinkomst.table.B.capitalSurplus.value'
                        ),
                        multiply(
                          -0.5,
                          id(
                            'TS.fields.SammanstallningKapitalinkomst.table.B.6.value'
                          )
                        )
                      )
                    ),
                    0
                  )
                )
              )
              .setRowActive(
                ref(
                  isGreaterThan(
                    sumAllowEmpty(
                      multiply(
                        id(
                          'TS.fields.SammanstallningKapitalinkomst.table.B.1.sign'
                        ),
                        id(
                          'TS.fields.SammanstallningKapitalinkomst.table.B.1.value'
                        )
                      ),
                      multiply(
                        id(
                          'TS.fields.SammanstallningKapitalinkomst.table.B.2.sign'
                        ),
                        id(
                          'TS.fields.SammanstallningKapitalinkomst.table.B.2.value'
                        )
                      )
                    ),
                    '0'
                  )
                )
              );
            rows.addRowWithType(
              '4',
              'row',
              value('Fastighetsavgift'),
              value(1),
              value(undefined)
            );
            rows.addRowWithType(
              '5',
              'row',
              value('Fastighetsskatt'),
              value(1),
              value(undefined)
            );
            rows.addRowWithType(
              '6',
              'row',
              value('Allmän pensionsavgift'),
              value(1),
              ref(
                roundToBase(
                  multiply(
                    0.07,
                    min(
                      id(
                        'TS.fields.SammanstallningForvarvsinkomst.table.A.earnedIncome.value'
                      ),
                      multiply(8.07, annualConfig.incomeBaseAmount)
                    )
                  ),
                  '100'
                )
              )
            );
            rows
              .addRowWithType(
                '7',
                'row',
                value('Avgift till trossamfund'),
                value(1),
                ref(
                  multiply(
                    id('TS.fields.religiousCommunitiesFee'),
                    id(
                      'TS.fields.SammanstallningForvarvsinkomst.table.A.taxableIncome.value'
                    )
                  )
                )
              )
              .setRowActive(ref(id('TS.fields.religiousCommunitiesFee')));
            rows.addRowWithType(
              '8',
              'row',
              value('Begravningsavgift'),
              value(1),
              ref(
                multiply(
                  id('TS.fields.burialFeeRate'),
                  id(
                    'TS.fields.SammanstallningForvarvsinkomst.table.A.taxableIncome.value'
                  )
                )
              )
            );
            rows.addRowWithType(
              '9',
              'row',
              value('Public serviceavgift'),
              value(1),
              ref(
                min(
                  individualTaxConfig.publicServiceThresholdTax,
                  multiply(
                    0.01,
                    id(
                      'TS.fields.SammanstallningForvarvsinkomst.table.A.taxableIncome.value'
                    )
                  )
                )
              )
            );
            rows.addRowWithType(
              '10',
              'row',
              value('Andra skatter och avgifter'),
              value(1),
              value(undefined)
            );
            rows.addRowWithType(
              'individualTax',
              'sum',
              value('Summa skatter och avgifter'),
              value(undefined),
              ref(
                sumAllowEmpty(
                  multiply(
                    id('TS.fields.SkatterOchAvgifter.table.C.1.sign'),
                    id('TS.fields.SkatterOchAvgifter.table.C.1.value')
                  ),
                  multiply(
                    id('TS.fields.SkatterOchAvgifter.table.C.2.sign'),
                    id('TS.fields.SkatterOchAvgifter.table.C.2.value')
                  ),
                  multiply(
                    id('TS.fields.SkatterOchAvgifter.table.C.3.sign'),
                    id('TS.fields.SkatterOchAvgifter.table.C.3.value')
                  ),
                  multiply(
                    id('TS.fields.SkatterOchAvgifter.table.C.4.sign'),
                    id('TS.fields.SkatterOchAvgifter.table.C.4.value')
                  ),
                  multiply(
                    id('TS.fields.SkatterOchAvgifter.table.C.5.sign'),
                    id('TS.fields.SkatterOchAvgifter.table.C.5.value')
                  ),
                  multiply(
                    id('TS.fields.SkatterOchAvgifter.table.C.6.sign'),
                    id('TS.fields.SkatterOchAvgifter.table.C.6.value')
                  ),
                  multiply(
                    id('TS.fields.SkatterOchAvgifter.table.C.7.sign'),
                    id('TS.fields.SkatterOchAvgifter.table.C.7.value')
                  ),
                  multiply(
                    id('TS.fields.SkatterOchAvgifter.table.C.8.sign'),
                    id('TS.fields.SkatterOchAvgifter.table.C.8.value')
                  ),
                  multiply(
                    id('TS.fields.SkatterOchAvgifter.table.C.9.sign'),
                    id('TS.fields.SkatterOchAvgifter.table.C.9.value')
                  ),
                  multiply(
                    id('TS.fields.SkatterOchAvgifter.table.C.10.sign'),
                    id('TS.fields.SkatterOchAvgifter.table.C.10.value')
                  )
                )
              )
            );
            return rows.build();
          });
          return rows.build();
        })
        .build(),
    },
    EnskildNaringsverksamhet: {
      table: table<Cell>(
        'TS.fields.EnskildNaringsverksamhet.table',
        {
          id: 'label',
          label: 'Skatter och avgifter för enskild näringsverksamhet',
        },
        { id: 'sign' },
        { id: 'value' }
      )
        .addRows((rows) => {
          rows.addRowWithType('D', 'hidden').addSubRows((rows) => {
            rows.addRowWithType(
              '1',
              'row',
              value('Expansionsfondsskatt'),
              value(1),
              ref(multiply(0.206, id('INK1.INK1.fields.12_1')))
            );
            rows.addRowWithType(
              '2',
              'row',
              value('Tillgodoräknad expansionsfondsskatt'),
              value(-1),
              ref(multiply(0.206, id('INK1.INK1.fields.12_2')))
            );
            rows.addRowWithType(
              '3',
              'row',
              value('Särskild löneskatt på pensionskostnader för anställda'),
              value(1),
              ref(multiply(0.2426, id('INK1.INK1.fields.10_6_anstalldas')))
            );
            rows.addRowWithType(
              '4',
              'row',
              value('Särskild löneskatt på egen pensionsförsäkringspremie'),
              value(1),
              ref(multiply(0.2426, id('INK1.INK1.fields.10_6_eget')))
            );
            rows.addRowWithType(
              '5',
              'row',
              value('Avkastningsskatt för avsatta pensioner'),
              value(1),
              ref(
                roundToBase(multiply(0.15, id('INK1.INK1.fields.10_7')), '100')
              )
            );
            rows.addRowWithType(
              'soleProprietorTax',
              'sum',
              value('Summa skatter och avgifter för enskild näringsverksamhet'),
              value(undefined),
              ref(
                sumAllowEmpty(
                  multiply(
                    id('TS.fields.EnskildNaringsverksamhet.table.D.1.sign'),
                    id('TS.fields.EnskildNaringsverksamhet.table.D.1.value')
                  ),
                  multiply(
                    id('TS.fields.EnskildNaringsverksamhet.table.D.2.sign'),
                    id('TS.fields.EnskildNaringsverksamhet.table.D.2.value')
                  ),
                  multiply(
                    id('TS.fields.EnskildNaringsverksamhet.table.D.3.sign'),
                    id('TS.fields.EnskildNaringsverksamhet.table.D.3.value')
                  ),
                  multiply(
                    id('TS.fields.EnskildNaringsverksamhet.table.D.4.sign'),
                    id('TS.fields.EnskildNaringsverksamhet.table.D.4.value')
                  ),
                  multiply(
                    id('TS.fields.EnskildNaringsverksamhet.table.D.5.sign'),
                    id('TS.fields.EnskildNaringsverksamhet.table.D.5.value')
                  )
                )
              )
            );
            return rows.build();
          });
          return rows.build();
        })
        .build(),
    },
    EgenavgifterOchLoneavgift: {
      table: table<Cell>(
        'TS.fields.EgenavgifterOchLoneavgift.table',
        {
          id: 'label',
          label:
            'Egenavgifter och allmän löneavgift för enskild näringsverksamhet',
        },
        { id: 'sign' },
        { id: 'value' }
      )
        .addRows((rows) => {
          rows.addRowWithType('E', 'hidden').addSubRows((rows) => {
            rows.addRowWithType(
              '1',
              'row',
              value('Sjukförsäkringsavgift'),
              value(1),
              ref(
                multiply(
                  individualTaxConfig.healthInsuranceFee,
                  sumAllowEmpty(
                    id('INK1.INK1.fields.10_1_NE'),
                    id('INK1.INK1.fields.10_1_N3A')
                  )
                )
              )
            );
            rows.addRowWithType(
              '2',
              'row',
              value('Regional nedsättning'),
              value(1),
              value(undefined)
            );
            rows.addRowWithType(
              '3',
              'row',
              value('Föräldraförsäkringsavgift'),
              value(1),
              ref(
                multiply(
                  individualTaxConfig.parentalInsuranceFee,
                  sumAllowEmpty(
                    id('INK1.INK1.fields.10_1_NE'),
                    id('INK1.INK1.fields.10_1_N3A')
                  )
                )
              )
            );
            rows.addRowWithType(
              '4',
              'row',
              value('Ålderspensionsavgift'),
              value(1),
              ref(
                multiply(
                  individualTaxConfig.retirementPensionFee,
                  sumAllowEmpty(
                    id('INK1.INK1.fields.10_1_NE'),
                    id('INK1.INK1.fields.10_1_N3A')
                  )
                )
              )
            );
            rows.addRowWithType(
              '5',
              'row',
              value('Efterlevnadspensionsavgift'),
              value(1),
              ref(
                multiply(
                  individualTaxConfig.survivingDependentPensionFee,
                  sumAllowEmpty(
                    id('INK1.INK1.fields.10_1_NE'),
                    id('INK1.INK1.fields.10_1_N3A')
                  )
                )
              )
            );
            rows.addRowWithType(
              '6',
              'row',
              value('Arbetsmarknadsavgifter'),
              value(1),
              ref(
                multiply(
                  individualTaxConfig.laborMarketFees,
                  sumAllowEmpty(
                    id('INK1.INK1.fields.10_1_NE'),
                    id('INK1.INK1.fields.10_1_N3A')
                  )
                )
              )
            );
            rows.addRowWithType(
              '7',
              'row',
              value('Arbetsskadeavgift'),
              value(1),
              ref(
                multiply(
                  individualTaxConfig.laborInjuryFee,
                  sumAllowEmpty(
                    id('INK1.INK1.fields.10_1_NE'),
                    id('INK1.INK1.fields.10_1_N3A')
                  )
                )
              )
            );
            rows.addRowWithType(
              '8',
              'row',
              value('Allmän löneavgift'),
              value(1),
              ref(
                multiply(
                  individualTaxConfig.generalPayrollFee,
                  sumAllowEmpty(
                    id('INK1.INK1.fields.10_1_NE'),
                    id('INK1.INK1.fields.10_1_N3A')
                  )
                )
              )
            );
            rows.addRowWithType(
              '9',
              'row',
              value('Särskild löneskatt på passiv näringsverksamhet'),
              value(1),
              ref(
                multiply(
                  annualConfig.passiveFee,
                  sumAllowEmpty(
                    id('INK1.INK1.fields.10_3_NE'),
                    id('INK1.INK1.fields.10_3_N3A')
                  )
                )
              )
            );
            rows.addRowWithType(
              '10',
              'row',
              value('Nedsättning av egenavgifter'),
              value(-1),
              ref(
                limitMax(
                  '15000',
                  limitMin(
                    '0',
                    multiply(
                      0.075,
                      ifOrElse(
                        isSmallerThan(
                          sumAllowEmpty(
                            id('INK1.INK1.fields.10_1_NE'),
                            id('INK1.INK1.fields.10_1_N3A'),
                            id('INK1.INK1.fields.10_3_NE'),
                            id('INK1.INK1.fields.10_3_N3A')
                          ),
                          '40000'
                        ),
                        '0',
                        sumAllowEmpty(
                          id('INK1.INK1.fields.10_1_NE'),
                          id('INK1.INK1.fields.10_1_N3A'),
                          id('INK1.INK1.fields.10_3_NE'),
                          id('INK1.INK1.fields.10_3_N3A')
                        )
                      )
                    )
                  )
                )
              )
            );
            rows.addRowWithType(
              'soleProprietorSalaryTax',
              'sum',
              value(
                'Summa egenavgifter och allmän löneavgift för enskild näringsverksamhet'
              ),
              value(undefined),
              ref(
                sumAllowEmpty(
                  multiply(
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.1.sign'),
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.1.value')
                  ),
                  multiply(
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.2.sign'),
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.2.value')
                  ),
                  multiply(
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.3.sign'),
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.3.value')
                  ),
                  multiply(
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.4.sign'),
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.4.value')
                  ),
                  multiply(
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.5.sign'),
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.5.value')
                  ),
                  multiply(
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.6.sign'),
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.6.value')
                  ),
                  multiply(
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.7.sign'),
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.7.value')
                  ),
                  multiply(
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.8.sign'),
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.8.value')
                  ),
                  multiply(
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.9.sign'),
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.9.value')
                  ),
                  multiply(
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.10.sign'),
                    id('TS.fields.EgenavgifterOchLoneavgift.table.E.10.value')
                  )
                )
              )
            );
            return rows.build();
          });
          return rows.build();
        })
        .build(),
    },
    Skattereduktioner: {
      table: table<Cell>(
        'TS.fields.Skattereduktioner.table',
        {
          id: 'label',
          label: 'Skattereduktioner och annat som minskar din skatt',
        },
        { id: 'sign' },
        { id: 'value' }
      )
        .addRows((rows) => {
          rows.addRowWithType('F', 'hidden').addSubRows((rows) => {
            rows.addRowWithType(
              '1',
              'row',
              value('Skattereduktion för allmän pensionsavgift'),
              value(-1),
              ref(id('TS.fields.SkatterOchAvgifter.table.C.6.value'))
            );
            rows.addRowWithType(
              '2',
              'row',
              value('Skattereduktion för fastighetsskatt'),
              value(-1),
              value(undefined)
            );
            rows.addRowWithType(
              '9',
              'row',
              value('Skattereduktion för sjöinkomst'),
              value(-1),
              value(undefined)
            );
            rows.addRowWithType(
              '4',
              'row',
              value('Skattereduktion för arbetsinkomster (jobbskatteavdrag)'),
              value(-1),
              value(undefined)
            );
            rows.addRowWithType(
              '5',
              'row',
              value('Skattereduktion för förvärvsinkomst'),
              value(-1),
              ref(
                min(
                  max(
                    multiply(
                      0.0075,
                      sumAllowEmpty(
                        id(
                          'TS.fields.SammanstallningForvarvsinkomst.table.A.taxableIncome.value'
                        ),
                        multiply(-1, 40000)
                      )
                    ),
                    0
                  ),
                  1500
                )
              )
            );
            rows.addRowWithType(
              '6',
              'row',
              value('Skattereduktion för sjuk- och aktivitetsersättning'),
              value(-1),
              value(undefined)
            );
            rows.addRowWithType(
              '7',
              'row',
              value('Skattereduktion för avgift till A-kassa'),
              value(-1),
              value(undefined)
            );
            rows.addRowWithType(
              '8',
              'row',
              value('Skattereduktion för rot- och rutarbete'),
              value(-1),
              value(undefined)
            );
            rows
              .addRowWithType(
                '3',
                'row',
                value('Skattereduktion för underskott av kapital'),
                value(-1),
                ref(
                  sumAllowEmpty(
                    multiply(
                      0.3,
                      min(
                        id(
                          'TS.fields.SammanstallningKapitalinkomst.table.B.capitalDeficit.value'
                        ),
                        100000
                      )
                    ),
                    multiply(
                      0.21,
                      max(
                        sumAllowEmpty(
                          id(
                            'TS.fields.SammanstallningKapitalinkomst.table.B.capitalDeficit.value'
                          ),
                          multiply(-1, 100000)
                        ),
                        0
                      )
                    )
                  )
                )
              )
              .setRowActive(
                ref(
                  isGreaterThan(
                    id(
                      'TS.fields.SammanstallningKapitalinkomst.table.B.capitalDeficit.value'
                    ),
                    '0'
                  )
                )
              );
            rows.addRowWithType(
              '10',
              'row',
              value('Skattereduktion för installation av grön teknik'),
              value(-1),
              value(undefined)
            );
            rows.addRowWithType(
              '11',
              'row',
              value('Skattereduktion för gåvor'),
              value(-1),
              value(undefined)
            );
            rows.addRowWithType(
              '12',
              'row',
              value('Skattereduktion för förnybar el'),
              value(-1),
              value(undefined)
            );
            rows.addRowWithType(
              '13',
              'row',
              value('Skattereduktion för investeringar i inventarier'),
              value(-1),
              value(undefined)
            );
            rows.addRowWithType(
              '14',
              'row',
              value('Andra skattereduktioner och justeringar'),
              value(-1),
              value(undefined)
            );
            rows.addRowWithType(
              'taxReductions',
              'sum',
              value('Summa skattereduktion och annat som minskar din skatt'),
              value(undefined),
              ref(
                sumAllowEmpty(
                  multiply(
                    id('TS.fields.Skattereduktioner.table.F.1.sign'),
                    id('TS.fields.Skattereduktioner.table.F.1.value')
                  ),
                  multiply(
                    id('TS.fields.Skattereduktioner.table.F.2.sign'),
                    id('TS.fields.Skattereduktioner.table.F.2.value')
                  ),
                  multiply(
                    id('TS.fields.Skattereduktioner.table.F.3.sign'),
                    id('TS.fields.Skattereduktioner.table.F.3.value')
                  ),
                  multiply(
                    id('TS.fields.Skattereduktioner.table.F.4.sign'),
                    id('TS.fields.Skattereduktioner.table.F.4.value')
                  ),
                  multiply(
                    id('TS.fields.Skattereduktioner.table.F.5.sign'),
                    id('TS.fields.Skattereduktioner.table.F.5.value')
                  ),
                  multiply(
                    id('TS.fields.Skattereduktioner.table.F.6.sign'),
                    id('TS.fields.Skattereduktioner.table.F.6.value')
                  ),
                  multiply(
                    id('TS.fields.Skattereduktioner.table.F.7.sign'),
                    id('TS.fields.Skattereduktioner.table.F.7.value')
                  ),
                  multiply(
                    id('TS.fields.Skattereduktioner.table.F.8.sign'),
                    id('TS.fields.Skattereduktioner.table.F.8.value')
                  ),
                  multiply(
                    id('TS.fields.Skattereduktioner.table.F.9.sign'),
                    id('TS.fields.Skattereduktioner.table.F.9.value')
                  ),
                  multiply(
                    id('TS.fields.Skattereduktioner.table.F.10.sign'),
                    id('TS.fields.Skattereduktioner.table.F.10.value')
                  ),
                  multiply(
                    id('TS.fields.Skattereduktioner.table.F.11.sign'),
                    id('TS.fields.Skattereduktioner.table.F.11.value')
                  ),
                  multiply(
                    id('TS.fields.Skattereduktioner.table.F.12.sign'),
                    id('TS.fields.Skattereduktioner.table.F.12.value')
                  ),
                  multiply(
                    id('TS.fields.Skattereduktioner.table.F.13.sign'),
                    id('TS.fields.Skattereduktioner.table.F.13.value')
                  ),
                  multiply(
                    id('TS.fields.Skattereduktioner.table.F.14.sign'),
                    id('TS.fields.Skattereduktioner.table.F.14.value')
                  )
                )
              )
            );
            return rows.build();
          });
          return rows.build();
        })
        .build(),
    },
    SammanstalldSkatt: {
      table: table<Cell>(
        'TS.fields.SammanstalldSkatt.table',
        {
          id: 'label',
          label: 'Sammanställd skatt för inkomståret',
        },
        { id: 'sign' },
        { id: 'value' }
      )
        .addRows((rows) => {
          rows.addRowWithType('G', 'hidden').addSubRows((rows) => {
            rows.addRowWithType(
              '1',
              'row',
              value('Summa skatter och avgifter'),
              value(1),
              ref(
                id('TS.fields.SkatterOchAvgifter.table.C.individualTax.value')
              )
            );
            rows.addRowWithType(
              '2',
              'row',
              value('Summa skatter och avgifter för enskild näringsverksamhet'),
              value(1),
              ref(
                id(
                  'TS.fields.EnskildNaringsverksamhet.table.D.soleProprietorTax.value'
                )
              )
            );
            rows.addRowWithType(
              '3',
              'row',
              value(
                'Summa egenavgifter och allmän löneavgift för enskild näringsverksamhet'
              ),
              value(1),
              ref(
                id(
                  'TS.fields.EgenavgifterOchLoneavgift.table.E.soleProprietorSalaryTax.value'
                )
              )
            );
            rows.addRowWithType(
              '4',
              'row',
              value('Summa skattereduktioner och annat som minskar din skatt'),
              value(-1),
              ref(
                multiply(
                  -1,
                  id('TS.fields.Skattereduktioner.table.F.taxReductions.value')
                )
              )
            );
            rows.addRowWithType(
              'totalTax',
              'sum',
              value('Beräknad skatt'),
              value(undefined),
              ref(
                floor(
                  limitMin(
                    '0',
                    sumAllowEmpty(
                      multiply(
                        id('TS.fields.SammanstalldSkatt.table.G.1.sign'),
                        id('TS.fields.SammanstalldSkatt.table.G.1.value')
                      ),
                      multiply(
                        id('TS.fields.SammanstalldSkatt.table.G.2.sign'),
                        id('TS.fields.SammanstalldSkatt.table.G.2.value')
                      ),
                      multiply(
                        id('TS.fields.SammanstalldSkatt.table.G.3.sign'),
                        id('TS.fields.SammanstalldSkatt.table.G.3.value')
                      ),
                      multiply(
                        id('TS.fields.SammanstalldSkatt.table.G.4.sign'),
                        id('TS.fields.SammanstalldSkatt.table.G.4.value')
                      )
                    )
                  )
                )
              )
            );
            return rows.build();
          });
          return rows.build();
        })
        .build(),
    },
    SammanstallningBerakningar: {
      table: table<Cell>(
        'TS.fields.SammanstallningBerakningar.table',
        {
          id: 'label',
          label: 'Beräkning av beloppet du ska betala/få tillbaka',
        },
        { id: 'sign' },
        { id: 'value' }
      )
        .addRows((rows) => {
          rows.addRowWithType('H', 'hidden').addSubRows((rows) => {
            rows.addRowWithType(
              '1',
              'row',
              value('Beräknad skatt'),
              value(1),
              ref(id('TS.fields.SammanstalldSkatt.table.G.totalTax.value'))
            );
            rows.addRowWithType(
              '2',
              'row',
              value(
                'Redan avdragen skatt enligt kontrolluppgifter och förtrycka uppgifter'
              ),
              value(-1),
              value(undefined)
            );
            rows.addRowWithType(
              '3',
              'row',
              value(
                'Inbetald preliminärskatt som avser enskild näringsverksamhet'
              ),
              value(-1),
              value(undefined)
            );
            rows.addRowWithType(
              '4',
              'row',
              value('Övrig inbetalning till privata skattekontot'),
              value(-1),
              value(undefined)
            );
            rows.addRowWithType(
              '5',
              'row',
              value(
                'Redan erhållen skattereduktion för rot-/rutarbete och installation av grön teknik'
              ),
              value(1),
              value(undefined)
            );
            rows
              .addRowWithType(
                'taxToPay',
                'sum',
                value('Beräknat belopp att betala'),
                value(undefined),
                ref(
                  sumAllowEmpty(
                    multiply(
                      id('TS.fields.SammanstallningBerakningar.table.H.1.sign'),
                      id('TS.fields.SammanstallningBerakningar.table.H.1.value')
                    ),
                    multiply(
                      id('TS.fields.SammanstallningBerakningar.table.H.2.sign'),
                      id('TS.fields.SammanstallningBerakningar.table.H.2.value')
                    ),
                    multiply(
                      id('TS.fields.SammanstallningBerakningar.table.H.3.sign'),
                      id('TS.fields.SammanstallningBerakningar.table.H.3.value')
                    ),
                    multiply(
                      id('TS.fields.SammanstallningBerakningar.table.H.4.sign'),
                      id('TS.fields.SammanstallningBerakningar.table.H.4.value')
                    ),
                    multiply(
                      id('TS.fields.SammanstallningBerakningar.table.H.5.sign'),
                      id('TS.fields.SammanstallningBerakningar.table.H.5.value')
                    )
                  )
                )
              )
              .setRowActive(
                ref(
                  isGreaterThanOrEqualTo(
                    id(`${rows.getBaseId()}.taxToPay.value`),
                    '0'
                  )
                )
              );
            rows
              .addRowWithType(
                'taxRefund',
                'sum',
                value('Beräknat belopp att få tillbaka'),
                value(undefined),
                ref(
                  multiply(
                    -1,
                    sumAllowEmpty(
                      multiply(
                        id(
                          'TS.fields.SammanstallningBerakningar.table.H.1.sign'
                        ),
                        id(
                          'TS.fields.SammanstallningBerakningar.table.H.1.value'
                        )
                      ),
                      multiply(
                        id(
                          'TS.fields.SammanstallningBerakningar.table.H.2.sign'
                        ),
                        floor(
                          id(
                            'TS.fields.SammanstallningBerakningar.table.H.2.value'
                          )
                        )
                      ),
                      multiply(
                        id(
                          'TS.fields.SammanstallningBerakningar.table.H.3.sign'
                        ),
                        floor(
                          id(
                            'TS.fields.SammanstallningBerakningar.table.H.3.value'
                          )
                        )
                      ),
                      multiply(
                        id(
                          'TS.fields.SammanstallningBerakningar.table.H.4.sign'
                        ),
                        floor(
                          id(
                            'TS.fields.SammanstallningBerakningar.table.H.4.value'
                          )
                        )
                      ),
                      multiply(
                        id(
                          'TS.fields.SammanstallningBerakningar.table.H.5.sign'
                        ),
                        floor(
                          id(
                            'TS.fields.SammanstallningBerakningar.table.H.5.value'
                          )
                        )
                      )
                    )
                  )
                )
              )
              .setRowActive(
                ref(
                  isGreaterThan(id(`${rows.getBaseId()}.taxRefund.value`), '0')
                )
              );
            return rows.build();
          });
          return rows.build();
        })
        .build(),
    },
  };
}
