export * from './financialYear';
export * from './utils';
export * from './organisationNumber';
export * from './personalNumber';
export * from './utils/validate';
export * from './translations/service';
export * from './annualConfig';
export * from './feature-switch/featureSwitchIds';

import sv from './translations/sv.json';
import K4 from './translations/forms/k4.json';
import K5 from './translations/forms/k5.json';
import K6 from './translations/forms/k6.json';
import K10 from './translations/forms/k10.json';
import N3B from './translations/forms/n3b.json';
import NE from './translations/forms/ne.json';
import NEA from './translations/forms/nea.json';
import INK2 from './translations/forms/ink2.json';
import TS from './translations/forms/ts.json';
import INK1 from './translations/forms/ink1.json';
import NE_PERSON_HELPER from './translations/forms/ne_person_helper.json';

const flattenImpl = (
  messages: Record<string, any>,
  prefix = ''
): [string, string][] => {
  return Object.entries(messages).flatMap(([key, entry]) =>
    typeof entry === 'string'
      ? [[`${prefix}${key}`, entry]]
      : flattenImpl(entry, `${prefix}${key}.`)
  );
};

const flatten = (messages: Record<string, any>): Record<string, string> =>
  flattenImpl(messages).reduce(
    (flat, [key, value]) => ({
      ...flat,
      [key]: value,
    }),
    {}
  );

/* Export one big object with all translations for locale SV
   The translations for forms contain field ids which are calculated (containing refs)
   since they might be reset by the user.
*/
export const messages = {
  sv: {
    ...flatten(sv),
    ...flatten(K4),
    ...flatten(K5),
    ...flatten(K6),
    ...flatten(K10),
    ...flatten(N3B),
    ...flatten(NE),
    ...flatten(NEA),
    ...flatten(INK2),
    ...flatten(INK1),
    ...flatten(NE_PERSON_HELPER),
    ...flatten(TS),
  },
};
