/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: Former Note91
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  previousYear,
  year,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2007K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2007K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Operationella leasingavtal - leasetagare'),
  data: {
    active: true,
    OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifterAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Framtida minimileaseavgifter avseende operationella leasingavtal, för leasetagare'
      ),
      table: table<IxbrlCell>(
        'notes.note7.data.OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifterAbstract.table',
        {
          id: 'OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifterAbstract',
          label: 'Framtida minimileaseavgifter',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifterAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifterInomEttAr',
                'row',
                noteLabel('Inom ett år'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifterInomEttAr',
                  standardRubrik:
                    'Framtida minimileaseavgifter avseende operationella leasingavtal som förfaller till betalning inom ett år, för leasetagare',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifterInomEttAr',
                  standardRubrik:
                    'Framtida minimileaseavgifter avseende operationella leasingavtal som förfaller till betalning inom ett år, för leasetagare',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifterMellanEttOchFemAr',
                'row',
                noteLabel('Senare än ett år men inom fem år'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifterMellanEttOchFemAr',
                  standardRubrik:
                    'Framtida minimileaseavgifter avseende operationella leasingavtal som förfaller till betalning senare än ett men inom fem år, för leasetagare',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifterMellanEttOchFemAr',
                  standardRubrik:
                    'Framtida minimileaseavgifter avseende operationella leasingavtal som förfaller till betalning senare än ett men inom fem år, för leasetagare',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifterSenareFemAr',
                'row',
                noteLabel('Senare än fem år'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifterSenareFemAr',
                  standardRubrik:
                    'Framtida minimileaseavgifter avseende operationella leasingavtal som förfaller till betalning senare än fem år, för leasetagare',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifterSenareFemAr',
                  standardRubrik:
                    'Framtida minimileaseavgifter avseende operationella leasingavtal som förfaller till betalning senare än fem år, för leasetagare',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifter',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note7.data.OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifterAbstract.table.OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifterAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'period0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifter',
                standardRubrik:
                  'Framtida minimileaseavgifter avseende operationella leasingavtal, för leasetagare',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note7.data.OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifterAbstract.table.OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifterAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'period1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifter',
                standardRubrik:
                  'Framtida minimileaseavgifter avseende operationella leasingavtal, för leasetagare',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
    },
    OperationellaLeasingavtalLeasetagareKostnadsfordaLeasingavgifterAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Kostnadsförda leasingavgifter avseende operationella leasingavtal, för leasetagare'
      ),
      table: table<IxbrlCell>(
        'notes.note7.data.OperationellaLeasingavtalLeasetagareKostnadsfordaLeasingavgifterAbstract.table',
        {
          id: 'OperationellaLeasingavtalLeasetagareKostnadsfordaLeasingavgifterAbstract',
          label: 'Räkenskapsårets kostnadsförda leasingavgifter',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'OperationellaLeasingavtalLeasetagareKostnadsfordaLeasingavgifterAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'OperationellaLeasingavtalLeasetagareKostnadsfordaLeasingavgifter',
                'row',
                noteLabel('Räkenskapsårets kostnadsförda leasingavgifter'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OperationellaLeasingavtalLeasetagareKostnadsfordaLeasingavgifter',
                  standardRubrik:
                    'Kostnadsförda leasingavgifter avseende operationella leasingavtal, för leasetagare',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OperationellaLeasingavtalLeasetagareKostnadsfordaLeasingavgifter',
                  standardRubrik:
                    'Kostnadsförda leasingavgifter avseende operationella leasingavtal, för leasetagare',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          return rows.build();
        })
        .build(),
      NotOperationellaLeasingavtalLeasetagareKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till upplysning om operationella leasingavtal, leasetagare'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotOperationellaLeasingavtalLeasetagareKommentar',
          standardRubrik:
            'Kommentar till upplysning om operationella leasingavtal, leasetagare',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2007K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        OperationellaLeasingavtalLeasetagareFramtidaMinimileaseavgifterAbstract:
          {
            type: 'part' as const,
            children: {
              type: type,
              level: number,
              active: boolean,

              title: cell,

              table: { type: 'table' as const },
            },
          },

        OperationellaLeasingavtalLeasetagareKostnadsfordaLeasingavgifterAbstract:
          {
            type: 'part' as const,
            children: {
              type: type,
              level: number,
              active: boolean,

              title: cell,

              table: { type: 'table' as const },

              NotOperationellaLeasingavtalLeasetagareKommentar: {
                type: 'part' as const,
                children: {
                  type: type,
                  level: number,
                  active: boolean,

                  title: cell,

                  value: cell,
                },
              },
            },
          },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
