import React, { useMemo } from 'react';
import {
  SpecificationColumnType,
  SpecificationRowType,
} from '_clients/types/types';
import SpecificationsTable from './PrintSpecificationsTable';

const SPLIT_COLUMNS_COUNT = 5;

export type PrintSpecificationsTablesProps = {
  columns: SpecificationColumnType[];
  rows: SpecificationRowType[];
};

const filterEmptyColumns = (
  columns: SpecificationColumnType[],
  rows: SpecificationRowType[]
) => {
  return columns.filter(
    (col) =>
      !rows.every((row) => {
        const cell = row.cells.find((c) => c.columnId === col.id);
        return !cell || (typeof cell.value !== 'number' && !cell.value);
      })
  );
};

const filterEmptyRows = (
  columns: SpecificationColumnType[],
  rows: SpecificationRowType[]
) => {
  return rows.filter(
    (row) =>
      !row.cells.every((cell) => {
        if (!columns.find((item) => item.id === cell.columnId)) {
          return true;
        }

        return !cell || (typeof cell.value !== 'number' && !cell.value);
      })
  );
};

const PrintSpecificationsTables = ({
  columns,
  rows,
}: PrintSpecificationsTablesProps) => {
  const filteredColumns = useMemo(
    () => filterEmptyColumns(columns, rows),
    [columns, rows]
  );

  const filteredRows = useMemo(
    () => filterEmptyRows(filteredColumns, rows),
    [filteredColumns, rows]
  );

  const splittedColumns = useMemo(() => {
    if (!filteredColumns.length) {
      return [];
    }
    // without first column, we will add to each table
    const columnsCount = filteredColumns.length - 1;

    if (columnsCount < SPLIT_COLUMNS_COUNT) {
      return [filteredColumns];
    }
    const result: SpecificationColumnType[][] = [];
    const tablesCount = Math.ceil(columnsCount / SPLIT_COLUMNS_COUNT);
    const baseColumns = Math.floor(columnsCount / tablesCount);
    const extraColumns = columnsCount % tablesCount;

    let start = 1;

    for (let table = 0; table < tablesCount; table += 1) {
      const columnsInTable = baseColumns + (table < extraColumns ? 1 : 0);

      result.push([]);

      for (let i = start; i < start + columnsInTable; i += 1) {
        result[table].push(filteredColumns[i]);
      }

      start += columnsInTable;
    }

    result.forEach((item) => {
      item.unshift(filteredColumns[0]);
    });

    return result;
  }, [filteredColumns]);

  return (
    <>
      {splittedColumns.map((tableColumns) => (
        <SpecificationsTable
          rows={filteredRows}
          columns={tableColumns}
          key={tableColumns[tableColumns.length - 1].id}
        />
      ))}
    </>
  );
};

export default PrintSpecificationsTables;
