import { ReferenceAccountInformation, label, value } from '@agoy/document';
import { active, field } from '@agoy/annual-report-document';
import { GeneratedLimitedNotes } from './types';
import { getSharesCompanyCustomNote } from '../../shared/customNotes';
import { getNote2001K2v2 } from './notes/note2001k2v2';
import { getNote2004K2v2 } from './notes/note2004k2v2';
import { getNote2005K2v2 } from './notes/note2005k2v2';

export const notesConfigLimitedConfig = (
  accounts: Record<string, ReferenceAccountInformation>,
  financialYears: string[],
  financialYear: string,
  period: string | null,
  isDigitalSubmission: boolean
): GeneratedLimitedNotes => {
  const noteLabel = (name: string) =>
    isDigitalSubmission ? label(name) : value(name);

  return {
    active: undefined,
    headings: {
      active: undefined,
      number: value(),
      name: value(),
      notesIncomeStatementTitle: active(field('Noter till resultaträkning')),
      notesBalanceSheetTitle: active(field('Noter till balansräkning')),
      notesOtherTitle: active(field('Övriga noter')),
      group: value(),
      section: value(),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note1: getNote2001K2v2(noteLabel, financialYear, financialYears, period),
    note4: getNote2004K2v2(noteLabel, financialYear, financialYears, period),
    note: getNote2005K2v2(noteLabel, financialYear, financialYears, period),
    custom: getSharesCompanyCustomNote(financialYear, financialYears, period),
  };
};
