import React, { useState } from 'react';
import { ccyFormat } from '@agoy/common';
import styled from '@emotion/styled';
import { Tooltip, Typography } from '@material-ui/core';
import { SkatteverketTransaction } from '_clients/redux/customer-view/types';
import { orange } from 'theme/colors';
import ArrowIcon from '@material-ui/icons/SubdirectoryArrowLeft';
import { useIntl } from 'react-intl';
import { reformat } from '@agoy/dates';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid ${orange};
`;

type ColumnProps = {
  longCol?: boolean;
  children: React.ReactNode;
};

const Column = ({ children, longCol }: ColumnProps): JSX.Element => (
  <div style={{ flex: longCol ? 3 : 1 }}>
    <Typography variant="body1">{children}</Typography>
  </div>
);

const RowIconButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  width: 100px;
  cursor: pointer;
`;

type TableRowProps = {
  transaction: SkatteverketTransaction;
  showActionButton: boolean;
  isSaldoInformation?: boolean;
  onActionClicked?: (transaction: SkatteverketTransaction) => Promise<void>;
};

const TableRow = ({
  transaction,
  showActionButton,
  isSaldoInformation,
  onActionClicked,
}: TableRowProps) => {
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);

  const getFormattedDate = (date: string) => {
    if (date.length === 0 || isSaldoInformation) {
      return '';
    }

    const transactionDate = transaction?.transactionDate;
    return reformat(transactionDate, 'yyyyMMdd', 'yyyy-MM-dd');
  };

  const handleClick = async () => {
    if (!onActionClicked) return;

    setLoading(true);
    await onActionClicked(transaction);
    setLoading(false);
  };

  const TooltipText = React.memo(() => (
    <Typography style={{ textAlign: 'center' }}>
      {formatMessage({ id: 'hidden.transactions.transferIcon' })}
    </Typography>
  ));

  const ActionButtonWithTooltip = React.memo(() => {
    if (isSaldoInformation) {
      return <RowIconButton />;
    }

    return (
      <Tooltip placement="top" title={<TooltipText />}>
        <div>
          <RowIconButton disabled={loading} onClick={handleClick}>
            <ArrowIcon />
          </RowIconButton>
        </div>
      </Tooltip>
    );
  });

  return (
    <Row>
      {showActionButton && <ActionButtonWithTooltip />}
      <Column>{getFormattedDate(transaction?.transactionDate)}</Column>
      <Column longCol>{transaction?.specification}</Column>
      <Column>
        {isSaldoInformation ? '' : ccyFormat(transaction?.amount)}
      </Column>
      <Column>{ccyFormat(transaction?.saldo)}</Column>
    </Row>
  );
};

export default TableRow;
