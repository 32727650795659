import styled from '@emotion/styled';
import React, { ChangeEvent, useState } from 'react';
import { Input } from '_shared/components/Inputs/v2/Input';
import { SimplifiedDatePicker } from '_shared/components/Inputs';
import DateRangePicker from './DateRangePicker';
import { format } from 'date-fns';
import { ccyFormat } from '@agoy/common';
import { SpecificationColumnContentType } from '_clients/types/types';

const InputWrapper = styled.div`
  display: flex;
`;

const StyledSimplifiedDatePicker = styled(SimplifiedDatePicker)`
  .MuiInputBase-root {
    padding: 0;
    input {
      padding: 0;
    }
    fieldset {
      border: 0;
    }
  }
  .MuiOutlinedInput-root {
    background: unset;
  }
`;

type ValueType = string | number | null | undefined;

type CellProps = {
  contentType: SpecificationColumnContentType;
  value: ValueType;
  periodLocked?: boolean;
  onUpdate: (value: string | number | null) => void;
  onKeyDown: (event: React.KeyboardEvent) => void;
};

const getFormattedValue = (
  value: ValueType,
  contentType: SpecificationColumnContentType
) => {
  if (value === null || value === undefined) {
    return '';
  }

  if (['AMOUNT', 'PERCENTAGE'].includes(contentType)) {
    return value?.toString().replaceAll('.', ',');
  }

  return value;
};

const Cell = ({
  contentType,
  value,
  periodLocked = false,
  onUpdate,
  onKeyDown,
}: CellProps) => {
  const [localValue, setLocalValue] = useState(
    getFormattedValue(value, contentType)
  );

  const isValidDate = (date: string | null): boolean => {
    return date ? /^\d{4}-\d{2}-\d{2}$/.test(date) : false;
  };

  const handleInputUpdate = () => {
    // Need to do this to make sure we send null on empty value
    onUpdate(localValue || null);
  };

  const handleDateUpdate = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const dateValue = e.target.value;
    onUpdate(/^\d{4}-\d{2}-\d{2}$/.test(dateValue) ? dateValue : null);
  };

  const handleDateAccept = (date: Date | null) => {
    onUpdate(date ? format(date, 'yyyy-MM-dd') : null);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const newValue = e.target.value;

    if (contentType === 'TEXT' || /^-?\d*([,.]\d*)?$/.test(newValue)) {
      setLocalValue(getFormattedValue(newValue, contentType));
    }
  };

  const handleDateRangeUpdate = (start: string | null, end: string | null) => {
    if (start === null && end === null) {
      onUpdate(null);
      return;
    }

    if (isValidDate(start) && isValidDate(end)) {
      const dateRange = `${start},${end}`;
      onUpdate(dateRange);
    }
  };

  const splitDateRange = (dateRange: ValueType) => {
    if (!dateRange || typeof dateRange !== 'string') {
      return { start: null, end: null };
    }

    const [start, end] = dateRange.split(',');
    return {
      start: start || null,
      end: end || null,
    };
  };

  if (periodLocked) {
    return (
      <td>
        {contentType === 'AMOUNT' ? ccyFormat(value) : value}
        {contentType === 'PERCENTAGE' && value && '%'}
      </td>
    );
  }

  if (contentType === 'DATE') {
    return (
      <td>
        <StyledSimplifiedDatePicker
          editing
          value={localValue}
          onAccept={(date) => handleDateAccept(date)}
          onChange={(dateValue) => setLocalValue(dateValue)}
          onBlur={(e) => handleDateUpdate(e)}
          onKeyDown={onKeyDown}
          error={false}
          helperText=""
        />
      </td>
    );
  }

  if (contentType === 'DATE_RANGE') {
    return (
      <td>
        <DateRangePicker
          dateRange={splitDateRange(localValue)}
          onDateRangeUpdate={handleDateRangeUpdate}
          onKeyDown={onKeyDown}
        />
      </td>
    );
  }

  return (
    <td>
      <InputWrapper>
        <Input
          type="text"
          value={localValue}
          onKeyDown={onKeyDown}
          onBlur={handleInputUpdate}
          onChange={handleInputChange}
        />
        {contentType === 'PERCENTAGE' &&
          (localValue || localValue === 0) &&
          '%'}
      </InputWrapper>
    </td>
  );
};

export default Cell;
