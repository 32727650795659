import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { last, omit } from 'lodash';

import { useSelector } from 'redux/reducers';
import {
  setUserInputData,
  setUserInputDocuments,
} from '_reconciliation/redux/accounting-view/actions';
import { clientYear } from '_reconciliation/redux/accounting-view/selectors';
import { mapUserInputData } from '_clients/services/mappings';
import { InputData } from '_reconciliation/types';
import { asResultClass, useApiSdk } from 'api-sdk';
import { ClientFinancialYears, ClosingPeriod } from '_clients/types/types';
import { FinancialYear, Period } from '@agoy/api-sdk-core';
import { ISODateInterval } from '@agoy/common';
import {
  addDays,
  addMonths,
  addQuarters,
  endOfMonth,
  endOfQuarter,
  format,
  parse,
  parseISO,
  startOfMonth,
  startOfQuarter,
} from 'date-fns';

export type FinancialYearWithPeriods = FinancialYear & { periods: Period[] };

export type PeriodDataContextType = {
  getPeriodUserInput: (
    period: Period,
    financialYear: FinancialYear,
    accountNumber: string
  ) => Promise<InputData | null>;
  nextPeriod: Period | null;
  nextPeriodFinancialYear: FinancialYear | null;
  nextPeriodLocked: boolean | null;
  nextGroupedPeriods: Period[] | null;

  previousPeriod: Period | null;
  previousPeriodFinancialYear: FinancialYear | null;
  previousPeriodLocked: boolean | null;
  previousYearEndLocked: boolean | null;
  previousGroupedPeriods: Period[] | null;

  periodLocked: boolean;

  lastPeriodLocked: boolean;
  lastPeriod: Period;

  clientId: string;

  /**
   * "Database Period" that is last in the current time period.
   * For quarters the usually equals the last month.
   *
   */
  period: Period;

  /**
   * "Database Periods" that make up the current time period in
   * quarterly and yearly reconciliation.
   */
  groupedPeriods: Period[];

  /**
   * 'month', 'quarter' and 'financialYear' are the new reconciliation
   * views. The 'dead' period is a month expanded in a quarter or year.
   * The 'yearEnd' is the Bokslutsperiod.
   * The 'period' indicates the old monthly view.
   */
  periodType:
    | 'month'
    | 'quarter'
    | 'financialYear'
    | 'period'
    | 'dead'
    | 'yearEnd';

  parentPeriodType: 'quarter' | 'financialYear' | undefined;

  /**
   * The current financial year in this context
   */
  financialYear: FinancialYearWithPeriods;

  yearEndPeriod: Period;
};

/**
 * Return the date interval for a month or a quarter.
 *
 * @param periodType 'month' or 'quarter' (or 'period' for the old reconciliation)
 * @param period
 * @param step Offset of months/quarters to the given period.
 * @returns start and end date of the time interval
 */
const getNextPeriod = (
  periodType: 'month' | 'quarter',
  period: Period,
  step: number
): ISODateInterval => {
  const startOf = periodType === 'month' ? startOfMonth : startOfQuarter;
  const endOf = periodType === 'month' ? endOfMonth : endOfQuarter;
  const add = periodType === 'month' ? addMonths : addQuarters;

  let start = '';
  let end = '';

  if (period.type === 'year_end' && step === -1) {
    start = format(startOf(parseISO(period.start)), 'yyyy-MM-dd');
    end = period.end;
  } else {
    start = format(add(startOf(parseISO(period.start)), step), 'yyyy-MM-dd');
    end = format(add(endOf(parseISO(period.end)), step), 'yyyy-MM-dd');
  }

  return { start, end };
};

const getGroupPeriods = (
  periodType: 'month' | 'quarter',
  period: Period,
  allPeriods: (Period & { financialYearId: number })[],
  step: number
) => {
  const periodIndex = allPeriods.findIndex((item) => period.id === item.id);
  const nextMonth = allPeriods[periodIndex + step];

  if (nextMonth?.type === 'year_end') {
    return [nextMonth];
  }

  const { start, end } = getNextPeriod(periodType, period, step);

  // Filter out all periods that belong to the next or previous time period
  return allPeriods
    .filter((p) => p.start >= start && p.start <= end)
    .filter((p) => p.type === 'month');
};

/**
 * Get the information about the next or previous month, quarter or financial year.
 *
 * @param financialYears All financial years
 * @param currentFinancialYear
 * @param currentPeriod
 * @param periodType
 * @param step 1 for next time period or -1 for the previous
 * @returns
 */
export const getPeriodByStep = (
  financialYears: ClientFinancialYears,
  currentFinancialYear: FinancialYear,
  currentPeriod: Period,
  periodType: 'month' | 'quarter' | 'financialYear' | 'yearEnd',
  closingPeriod: ClosingPeriod,
  step: -1 | 1
): {
  period: Period | null;
  financialYear: FinancialYear | null;
  groupedPeriods: Period[] | null;
} => {
  // Map all periods into a single array with financial year reference.
  const allPeriods = financialYears.flatMap((fy) =>
    fy.periods ? fy.periods.map((p) => ({ ...p, financialYearId: fy.id })) : []
  );

  // Handle financialYear
  if (periodType === 'financialYear') {
    let financialYear: ClientFinancialYears[number] | undefined;
    let yearEndPeriod: Period | undefined;

    // Next period for financial year is yearEnd of current fin year.
    if (step === 1) {
      financialYear = financialYears.find(
        (fy) => fy.id === currentFinancialYear.id
      );
      yearEndPeriod = last(financialYear?.periods);
    } else {
      // Previous period for financial year is yearEnd of previous fin year.
      const end = format(
        addDays(parseISO(currentFinancialYear.start), -1),
        'yyyy-MM-dd'
      );
      financialYear = financialYears.find((fy) => fy.end === end);
      yearEndPeriod = last(financialYear?.periods);
    }

    return {
      period: yearEndPeriod ?? null,
      financialYear: financialYear ?? null,
      groupedPeriods: yearEndPeriod ? [yearEndPeriod] : [],
    };
  }

  // Handle yearEnd for year type
  if (periodType === 'yearEnd' && closingPeriod === 'year') {
    let financialYear: ClientFinancialYears[number] | undefined;

    if (step === 1) {
      // Next financial year, starts day after the current's end.
      const start = format(
        addDays(parseISO(currentFinancialYear.end), 1),
        'yyyy-MM-dd'
      );
      financialYear = financialYears.find((fy) => fy.start === start);
    } else {
      // Previous financial year for yearEnd is current financial year.
      financialYear = financialYears.find(
        (fy) => fy.id === currentFinancialYear.id
      );
    }

    const months =
      financialYear?.periods?.filter((p) => p.type === 'month') ?? null;
    return {
      period: last(months) ?? null,
      financialYear: financialYear ?? null,
      groupedPeriods: months,
    };
  }

  let periods: (Period & { financialYearId: number })[] = [];

  // Handle months and quarters
  if (periodType === 'yearEnd') {
    periods = getGroupPeriods(
      closingPeriod as 'month' | 'quarter',
      currentPeriod,
      allPeriods,
      step
    );
  } else {
    periods = getGroupPeriods(periodType, currentPeriod, allPeriods, step);
  }

  // The period is always the last month of the quarter (and month)
  const period = periods[periods.length - 1] ?? null;

  return {
    period: period ? omit(period, 'financialYearId') : null,
    financialYear:
      (period &&
        financialYears.find((fy) => fy.id === period.financialYearId)) ??
      null,
    groupedPeriods: period
      ? periods.map((p) => omit(p, 'financialYearId'))
      : null,
  };
};

/**
 * Maps periodIds to locked status.
 *
 * The periodStatus must have statuses per periods with the latest
 * first in the array. This is true for reconciliation reducer.
 *
 * @param periodStatus
 * @param periodIds
 * @returns
 */
const getLocked = (
  periodStatus: Record<number, { status: string }> | null | undefined,
  ...periodIds: Array<number | undefined>
): Array<boolean | null> => {
  if (!periodStatus) {
    return periodIds.map(() => null);
  }
  return periodIds.map((periodId) => {
    return periodId ? periodStatus[periodId]?.status === 'LOCKED' : null;
  });
};

export const usePeriodData = (
  clientId: string,
  currentPeriod: Period,
  currentPeriods: Period[],
  periodType:
    | 'month'
    | 'quarter'
    | 'financialYear'
    | 'period'
    | 'dead'
    | 'yearEnd',
  parentPeriodType?: 'quarter' | 'financialYear',
  optionalLastPeriod?: Period
): PeriodDataContextType => {
  // If the given period type is 'period', it's the old reconciliation which is done
  // monthly. We use 'month' for getting the data, but keep 'period' so the views can
  // distinguish where it is rendered.
  const dataPeriodType =
    periodType === 'period' || periodType === 'dead' ? 'month' : periodType;

  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const client = useSelector((state) => state.customers[clientId]);
  const financialYears = client.rawFinancialYears;
  const closingPeriod = client.closingPeriod || 'month';

  const currentFinancialYear = useMemo((): FinancialYearWithPeriods => {
    const y = financialYears.find((year) =>
      year.periods?.find((p) => p.id === currentPeriod.id)
    );
    if (y?.periods === undefined) {
      // There should never be a case where a period is not in the client's
      // financial years.
      throw new Error('Period must exist in financial year');
    }
    // Not sure why, even though I checked periods exist in the object,
    // TS still say that it might be undefined.
    return y as FinancialYearWithPeriods;
  }, [currentPeriod.id, financialYears]);

  const allPeriods = useMemo(
    () => currentFinancialYear?.periods ?? [],
    [currentFinancialYear?.periods]
  );

  const periods = useMemo(
    () =>
      allPeriods ? allPeriods.filter((period) => period.type === 'month') : [],
    [allPeriods]
  );

  const yearEndPeriods = useMemo(
    () =>
      allPeriods
        ? allPeriods.filter((period) => period.type === 'year_end')
        : [],
    [allPeriods]
  );

  const userInputData = useSelector(
    clientYear(clientId, currentFinancialYear, (state) => state.userInput)
  );

  const {
    period: nextPeriod,
    financialYear: nextPeriodFinancialYear,
    groupedPeriods: nextGroupedPeriods,
  } = useMemo(() => {
    if (currentFinancialYear && financialYears) {
      return getPeriodByStep(
        financialYears,
        currentFinancialYear,
        currentPeriod,
        dataPeriodType,
        closingPeriod,
        1
      );
    }
    return {
      period: null,
      financialYear: null,
      groupedPeriods: null,
    };
  }, [
    currentFinancialYear,
    financialYears,
    currentPeriod,
    dataPeriodType,
    closingPeriod,
  ]);

  const {
    period: previousPeriod,
    financialYear: previousPeriodFinancialYear,
    groupedPeriods: previousGroupedPeriods,
  } = useMemo(() => {
    if (currentFinancialYear && financialYears) {
      return getPeriodByStep(
        financialYears,
        currentFinancialYear,
        currentPeriod,
        dataPeriodType,
        closingPeriod,
        -1
      );
    }
    return {
      period: null,
      financialYear: null,
      groupedPeriods: null,
    };
  }, [
    currentFinancialYear,
    financialYears,
    currentPeriod,
    dataPeriodType,
    closingPeriod,
  ]);

  const getPeriodUserInput = useCallback(
    async (
      period: Period,
      financialYear: FinancialYear,
      accountNumber: string
    ): Promise<InputData | null> => {
      if (
        currentFinancialYear &&
        financialYear.id !== currentFinancialYear.id
      ) {
        const userInputResult = await asResultClass(
          sdk.getUserInput({
            clientId,
            accountingYear: financialYear.start.substring(0, 4),
            financialYearId: financialYear.id,
          })
        );

        if (userInputResult.ok) {
          const parsed = JSON.parse(userInputResult.val.inputData);
          const { userInput, userInputDocuments } = mapUserInputData(parsed);

          await dispatch(setUserInputData(clientId, financialYear, userInput));
          await dispatch(
            setUserInputDocuments(clientId, financialYear, userInputDocuments)
          );

          return userInput?.[`account${accountNumber}`]?.[period.id];
        }
        return null;
      }

      return userInputData?.[`account${accountNumber}`]?.[period.id] || null;
    },
    [clientId, currentFinancialYear, dispatch, sdk, userInputData]
  );
  const lastPeriod = optionalLastPeriod ?? periods[periods.length - 1];

  const yearEndPeriod =
    periodType === 'period'
      ? lastPeriod
      : yearEndPeriods[yearEndPeriods.length - 1];

  const previousFinancialYearEnd = format(
    addDays(parse(currentFinancialYear.start, 'yyyy-MM-dd', Date.now()), -1),
    'yyyy-MM-dd'
  );
  const previousFinancialYear = financialYears.find(
    (y) => y.end === previousFinancialYearEnd
  );
  const previousYearEndPeriod = previousFinancialYear?.periods?.find(
    (p) => p.type === 'year_end'
  );

  const periodStatus = useSelector(
    (state) => state.accountingView.clients[clientId]?.periodStatus
  );

  const [
    previousPeriodLocked,
    periodLocked,
    nextPeriodLocked,
    lastPeriodLocked,
    previousYearEndLocked,
  ] = useMemo(
    () =>
      getLocked(
        periodStatus,
        previousPeriod?.id,
        currentPeriod.id,
        nextPeriod?.id,
        lastPeriod?.id,
        previousYearEndPeriod?.id
      ),
    [
      currentPeriod.id,
      nextPeriod?.id,
      periodStatus,
      previousPeriod?.id,
      previousYearEndPeriod?.id,
      lastPeriod?.id,
    ]
  );

  return {
    nextPeriod,
    nextPeriodFinancialYear,
    nextGroupedPeriods,
    nextPeriodLocked: periodType === 'dead' ? true : nextPeriodLocked,
    previousPeriod,
    previousPeriodFinancialYear,
    previousPeriodLocked,
    previousGroupedPeriods,
    previousYearEndLocked,
    periodLocked: periodType === 'dead' ? true : periodLocked === true,
    lastPeriodLocked: lastPeriodLocked === true,
    lastPeriod,
    getPeriodUserInput,
    groupedPeriods: currentPeriods,
    period: currentPeriod,
    financialYear: currentFinancialYear,
    periodType,
    parentPeriodType,
    clientId,
    yearEndPeriod,
  };
};
