import React from 'react';
import {
  Backdrop as MuiBackdrop,
  Fade,
  Modal as MuiModal,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from '@emotion/styled';
import Typography from '_shared/components/Typography/Typography';

const Backdrop = styled(MuiBackdrop)`
  background-color: #0000001a;
`;

const ModalBase = styled(MuiModal)`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  background: ${({ theme }) => theme.palette.taxDeclarationView.background};

  &.huge {
    width: 1140px;
  }

  &.large {
    width: 756px;
  }

  &.medium {
    width: 554px;
  }

  &.small {
    width: 414px;
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;

  > *:first-of-type {
    padding-top: ${({ theme }) => theme.spacing(4)}px;
    padding-left: ${({ theme }) => theme.spacing(4)}px;
  }

  > *:last-of-type {
    padding-top: ${({ theme }) => theme.spacing(3)}px;
    padding-right: ${({ theme }) => theme.spacing(3)}px;
  }
`;

const CloseButton = styled(IconButton)`
  padding: ${({ theme }) => theme.spacing(1)}px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 600px;
  overflow-y: auto;
  padding: 0 ${({ theme }) => theme.spacing(3)}px;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const Footer = styled.div`
  display: flex;
  flex: 1;
  align-self: flex-end;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(4)}px;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

interface ModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  size?: 'huge' | 'large' | 'medium' | 'small';
}

const Modal = ({
  open,
  onClose,
  title,
  children,
  footer,
  size = 'large',
}: ModalProps) => {
  return (
    <ModalBase
      keepMounted={false}
      aria-labelledby="transition-modal-select-form-modal"
      aria-describedby="transition-modal-select-form-modal-option"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div style={{ display: 'contents' }}>
          <ModalWrapper className={`${size}`}>
            <Header>
              <Typography variant="h2" margin="none">
                {title}
              </Typography>

              <div>
                <CloseButton onClick={onClose}>
                  <CloseIcon />
                </CloseButton>
              </div>
            </Header>
            <Content>{children}</Content>
            <Footer>{footer}</Footer>
          </ModalWrapper>
        </div>
      </Fade>
    </ModalBase>
  );
};

export default Modal;
