import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addGlobalErrorMessage } from 'redux/actions';
import CommonPreviewModal from '_shared/components/CommonPreviewModal';
import { printDocument } from '_shared/components/PrintedDocument/utils';

import SpecificationInfo from '_shared/components/SpecificationInfo';
import { FinancialYear, Period } from '@agoy/api-sdk-core';
import {
  SpecificationColumnType,
  SpecificationRowType,
} from '_clients/types/types';
import { useSelector } from 'redux/reducers';
import { TimePeriod } from '@agoy/document';

import { usePrintState } from '../../../utils';
import PrintSpecificationsTables from './PrintSpecificationsTables';

interface PrintSpecificationsModalProps {
  open: boolean;
  clientId: string;
  accountNumber: string;
  accountName: string;
  financialYear: FinancialYear;
  period: Period;
  inputData: {
    ib: number;
    ub: number;
    yearIB: number;
    specificationAmount: number;
  };
  columns: SpecificationColumnType[];
  rows: SpecificationRowType[];
  onClose: () => void;
}

const PrintSpecificationsModal = ({
  open,
  clientId,
  accountNumber,
  accountName,
  financialYear,
  period,
  inputData,
  columns,
  rows,
  onClose,
}: PrintSpecificationsModalProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const printState = usePrintState();

  const [loading, setLoading] = useState(false);

  const { name } = useSelector((state) => state.customers[clientId]);

  const handlePrint = async () => {
    setLoading(true);
    try {
      const finYearInterval = TimePeriod.fromISODates(financialYear).value;

      await printDocument(
        clientId,
        finYearInterval,
        'accounting',
        `${formatMessage({
          id: 'hidden.specification.view',
        })} ${name} ${period.end}.pdf`,
        ['specificationTable'],
        {
          ...printState,
          reconciliationSpecificationsData: {
            specifications: { columns, rows },
            account: accountNumber,
            accountName,
            userData: inputData,
          },
        }
      );
    } catch {
      dispatch(addGlobalErrorMessage('error'));
    }

    setLoading(false);
  };

  return (
    <CommonPreviewModal
      isOpen={open}
      loading={loading}
      handleShow={onClose}
      onPrint={handlePrint}
    >
      <SpecificationInfo
        accountNumber={accountNumber}
        accountName={accountName}
        period={period}
        financialYear={financialYear}
        inputData={inputData}
        isNewSpecifications
      />
      <PrintSpecificationsTables rows={rows} columns={columns} />
    </CommonPreviewModal>
  );
};

export default PrintSpecificationsModal;
