/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-arsredovisning-2021-10-31-rev20230131_sv - Noter.csv
 * - Comments: nan
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
  previousYear,
  year,
  sumAccountsUBs,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2081K2v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2081K2v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Upplupna kostnader och förutbetalda intäkter'),
  data: {
    active: true,
    UpplupnaKostnaderForutbetaldaIntakterAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Upplupna kostnader och förutbetalda intäkter'),
      table: table<IxbrlCell>(
        'notes.note81.data.UpplupnaKostnaderForutbetaldaIntakterAbstract.table',
        {
          id: 'OvrigaUpplupnaKostnaderForutbetaldaIntakterInterimspost',
          label: 'Typ av interimspost',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'UpplupnaKostnaderForutbetaldaIntakterAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'UpplupnaKostnaderForutbetaldaIntakterUpplupnaLoner',
                'sum_accounts',
                noteLabel('Upplupna löner'),
                ixbrlCell(ref(sumAccountsUBs('2910', true)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaLoner',
                  standardRubrik:
                    'Upplupna löner i upplupna kostnader och förutbetalda intäkter',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(ref(sumAccountsUBs('2910', true, 'previousYear')), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaLoner',
                  standardRubrik:
                    'Upplupna löner i upplupna kostnader och förutbetalda intäkter',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'UpplupnaKostnaderForutbetaldaIntakterUpplupnaSemesterloner',
                'sum_accounts',
                noteLabel('Upplupna semesterlöner'),
                ixbrlCell(ref(sumAccountsUBs('2920', true)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaSemesterloner',
                  standardRubrik:
                    'Upplupna semesterlöner i upplupna kostnader och förutbetalda intäkter',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(ref(sumAccountsUBs('2920', true, 'previousYear')), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaSemesterloner',
                  standardRubrik:
                    'Upplupna semesterlöner i upplupna kostnader och förutbetalda intäkter',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'UpplupnaKostnaderForutbetaldaIntakterUpplupnaSocialaAvgifter',
                'sum_accounts',
                noteLabel('Upplupna sociala avgifter'),
                ixbrlCell(ref(sumAccountsUBs('2940:2941+2950', true)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaSocialaAvgifter',
                  standardRubrik:
                    'Upplupna sociala avgifter i upplupna kostnader och förutbetalda intäkter',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsUBs('2940:2941+2950', true, 'previousYear')),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaSocialaAvgifter',
                    standardRubrik:
                      'Upplupna sociala avgifter i upplupna kostnader och förutbetalda intäkter',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'UpplupnaKostnaderForutbetaldaIntakterUpplupnaRantekostnader',
                'sum_accounts',
                noteLabel('Upplupna räntekostnader'),
                ixbrlCell(ref(sumAccountsUBs('2960', true)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaRantekostnader',
                  standardRubrik:
                    'Upplupna räntekostnader i upplupna kostnader och förutbetalda intäkter',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(ref(sumAccountsUBs('2960', true, 'previousYear')), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaRantekostnader',
                  standardRubrik:
                    'Upplupna räntekostnader i upplupna kostnader och förutbetalda intäkter',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'UpplupnaKostnaderForutbetaldaIntakterForutbetaldaIntakter',
                'sum_accounts',
                noteLabel('Förutbetalda intäkter'),
                ixbrlCell(ref(sumAccountsUBs('2970', true)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterForutbetaldaIntakter',
                  standardRubrik:
                    'Förutbetalda intäkter i upplupna kostnader och förutbetalda intäkter',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(ref(sumAccountsUBs('2970', true, 'previousYear')), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterForutbetaldaIntakter',
                  standardRubrik:
                    'Förutbetalda intäkter i upplupna kostnader och förutbetalda intäkter',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'UpplupnaKostnaderForutbetaldaIntakterForutbetaldaHyresintakter',
                'sum_accounts',
                noteLabel('Förutbetalda hyresintäkter'),
                ixbrlCell(ref(sumAccountsUBs('2971', true)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterForutbetaldaHyresintakter',
                  standardRubrik:
                    'Förutbetalda hyresintäkter i upplupna kostnader och förutbetalda intäkter',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(ref(sumAccountsUBs('2971', true, 'previousYear')), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterForutbetaldaHyresintakter',
                  standardRubrik:
                    'Förutbetalda hyresintäkter i upplupna kostnader och förutbetalda intäkter',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Typ av interimspost'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:OvrigaUpplupnaKostnaderForutbetaldaIntakterInterimspost',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Typ av övrig upplupen kostnad eller förutbetald intäkt',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:OvrigaUpplupnaKostnaderForutbetaldaIntakterTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: true,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaUpplupnaKostnaderForutbetaldaIntakter',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Övrig upplupen kostnad eller förutbetald intäkt per typ av interimspost',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple_balans0`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaUpplupnaKostnaderForutbetaldaIntakter',
                  contextRef: 'balans1',
                  standardRubrik:
                    'Övrig upplupen kostnad eller förutbetald intäkt per typ av interimspost',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple_balans1`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              OvrigaUpplupnaKostnaderForutbetaldaIntakterInterimspost:
                ixbrlCell(value('Typ av interimspost'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:OvrigaUpplupnaKostnaderForutbetaldaIntakterInterimspost',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Typ av övrig upplupen kostnad eller förutbetald intäkt',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:OvrigaUpplupnaKostnaderForutbetaldaIntakterTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: true,
                }),
              year: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:OvrigaUpplupnaKostnaderForutbetaldaIntakter',
                contextRef: 'balans0',
                standardRubrik:
                  'Övrig upplupen kostnad eller förutbetald intäkt per typ av interimspost',
                saldo: 'credit',
                negateValue: false,
                tupleRef: tupleRef(2, `${baseId}.${_id}.tuple_balans0`),
                previousYearTuple: false,
              }),
              previousYear: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:OvrigaUpplupnaKostnaderForutbetaldaIntakter',
                contextRef: 'balans1',
                standardRubrik:
                  'Övrig upplupen kostnad eller förutbetald intäkt per typ av interimspost',
                saldo: 'credit',
                negateValue: false,
                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple_balans1`),
                previousYearTuple: false,
              }),
            },
          }));
          rows.addRowWithType(
            'UpplupnaKostnaderForutbetaldaIntakter',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note81.data.UpplupnaKostnaderForutbetaldaIntakterAbstract.table.UpplupnaKostnaderForutbetaldaIntakterAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakter',
                standardRubrik: 'Upplupna kostnader och förutbetalda intäkter',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note81.data.UpplupnaKostnaderForutbetaldaIntakterAbstract.table.UpplupnaKostnaderForutbetaldaIntakterAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakter',
                standardRubrik: 'Upplupna kostnader och förutbetalda intäkter',
                saldo: 'credit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NotUpplupnaKostnaderForutbetaldaIntakterKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till specifikation av upplupna kostnader och förutbetalda intäkter'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotUpplupnaKostnaderForutbetaldaIntakterKommentar',
          standardRubrik:
            'Kommentar till specifikation av upplupna kostnader och förutbetalda intäkter',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2081K2v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        UpplupnaKostnaderForutbetaldaIntakterAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotUpplupnaKostnaderForutbetaldaIntakterKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
