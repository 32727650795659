import React, { createContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import config from '_shared/services/config';

import { ContextType } from './withAuth/types';
import cognitoAuth from './withAuth/cognitoAuth';
import jwtAuth from './withAuth/jwtAuth';
import fortnoxAuth from './withAuth/fortnoxAuth';

export const authContext = createContext({} as ContextType);

/**
 * HOC providing the Authentication context.
 * Autentication operation are to be preformed through the provided context.
 */
const withAuth = (Component) => (props) => {
  const dispatch = useDispatch();

  // FIXME: for now, stick with redux state. Add auth refactoring story to only rely on the auth hook's state
  const [user, setStateUser] = useState(null);
  const [isAuthenticated, setStateIsAuthenticated] = useState(false);
  const [isLoading, setStateIsLoading] = useState(true);
  let funcs;
  if (config.isFortnoxCloud) {
    funcs = fortnoxAuth(
      dispatch,
      setStateUser,
      setStateIsAuthenticated,
      setStateIsLoading
    );
  } else {
    funcs =
      config.whiteLabel === 'fortnox'
        ? jwtAuth(
            dispatch,
            setStateUser,
            setStateIsAuthenticated,
            setStateIsLoading
          )
        : cognitoAuth(
            dispatch,
            setStateUser,
            setStateIsAuthenticated,
            setStateIsLoading
          );
  }
  const auth = {
    isLoading,
    isAuthenticated,
    user,
    ...funcs,
  };

  return (
    <authContext.Provider value={auth}>
      <Component {...props} />
    </authContext.Provider>
  );
};

export default withAuth;
