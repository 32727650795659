import * as t from 'io-ts';
const sectionChanges = t.union([t.undefined, t.record(t.string, t.unknown)]);

export const ifUndefined = <T extends string>(def: T) =>
  new t.Type<T, string, unknown>(
    'default',
    (i): i is T => i === def,
    (input, context) =>
      input === undefined
        ? t.success(def)
        : t.failure(input, context, 'not undefined'),
    (a) => a
  );

export const incomeStatementCodec = <T extends 'cost' | 'functional'>(
  def: T
) => {
  // Define a type guard function
  const isT = (i: unknown): i is T => i === 'cost' || i === 'functional';

  // Define a validation function
  const validate = (input: unknown, context: t.Context) => {
    if (input === undefined) {
      return t.success(def);
    }

    if (incomeStatementCodec(def).is(input)) {
      return t.success(input as T);
    }

    return t.failure(input, context, 'must be either "cost" or "functional"');
  };

  // Return a new type
  return new t.Type<T, T, unknown>(
    'incomeStatement',
    isT,
    validate,
    t.identity
  ) as t.Type<T, T, unknown>;
};

export const documentConfigurationCodec = t.intersection([
  t.type({
    reportType: t.union([t.literal('k1'), t.literal('k2'), t.literal('k3')]),
    reportTransitionK3toK2: t.boolean,
    incomeStatement: incomeStatementCodec('cost'),
    isDigitalSubmission: t.union([t.boolean, t.null]),
    financialYears: t.array(
      t.type({
        start: t.string,
        end: t.string,
        label: t.string,
      })
    ),
  }),

  t.union([
    t.type({
      documentType: ifUndefined('shares'),
      version: ifUndefined('1'),
    }),
    t.type({
      documentType: t.union([
        t.literal('shares'),
        t.literal('individual'),
        t.literal('economic_association'),
        t.literal('non_profit_association'),
        t.literal('foundation'),
      ]),
      version: ifUndefined('1'),
    }),
    t.type({
      documentType: t.literal('shares'),
      version: t.union([t.literal('1'), t.literal('2')]),
    }),
    t.type({
      documentType: t.literal('individual'),
      version: t.literal('1'),
    }),
    t.type({
      documentType: t.literal('economic_association'),
      version: t.literal('1'),
    }),
    t.type({
      documentType: t.literal('limited_companies'),
      version: t.literal('2'),
    }),
    t.type({
      documentType: t.literal('non_profit_association'),
      version: t.literal('1'),
    }),
    t.type({
      documentType: t.literal('foundation'),
      version: t.literal('1'),
    }),
  ]),
]);

/**
 * Definition of the annual report changes including the
 * contentDefinition.
 */
export const annualReportChangesCodec = t.intersection([
  t.type({
    documentConfiguration: documentConfigurationCodec,
  }),
  t.partial({
    confirmationCertificate: sectionChanges,
    settings: sectionChanges,
    managementReport: sectionChanges,
    incomeStatement: sectionChanges,
    balanceSheet: sectionChanges,
    cashFlowStatement: sectionChanges,
    notes: sectionChanges,
    signatures: sectionChanges,
    annualGeneralMeeting: sectionChanges,
    digitalSubmission: sectionChanges,
  }),
]);

export const annualReportSignaturesChangesCodec = t.partial({
  signatures: sectionChanges,
});

export const annualGeneralMeetingCodec = t.strict({
  annualGeneralMeeting: sectionChanges,
});
