/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6789
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  previousYear,
  year,
  sumAccountsUBs,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2064K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2064K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Upparbetad men ej fakturerad intäkt'),
  data: {
    active: true,
    UpparbetadEjFaktureradIntaktAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Upparbetad men ej fakturerad intäkt'),
      table: table<IxbrlCell>(
        'notes.note64.data.UpparbetadEjFaktureradIntaktAbstract.table',
        {
          id: 'UpparbetadEjFaktureradIntaktAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'UpparbetadEjFaktureradIntaktAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'UpparbetadEjFaktureradIntaktUpparbetat',
                'sum_accounts',
                noteLabel('Upparbetad intäkt'),
                ixbrlCell(ref(sumAccountsUBs('1620', false)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UpparbetadEjFaktureradIntaktUpparbetat',
                  standardRubrik:
                    'Total upparbetad intäkt från uppdrag på löpande räkning och uppdrag på fastpris enligt huvudregeln',
                  negateValue: false,
                }),
                ixbrlCell(ref(sumAccountsUBs('1620', false, 'previousYear')), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UpparbetadEjFaktureradIntaktUpparbetat',
                  standardRubrik:
                    'Total upparbetad intäkt från uppdrag på löpande räkning och uppdrag på fastpris enligt huvudregeln',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'UpparbetadEjFaktureradIntaktFakturerat',
                'row',
                noteLabel('Fakturerat belopp'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UpparbetadEjFaktureradIntaktFakturerat',
                  standardRubrik:
                    'Fakturerat belopp avseende uppdrag på löpande räkning och uppdrag på fastpris enligt huvudregeln',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UpparbetadEjFaktureradIntaktFakturerat',
                  standardRubrik:
                    'Fakturerat belopp avseende uppdrag på löpande räkning och uppdrag på fastpris enligt huvudregeln',
                  negateValue: true,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'UpparbetadEjFaktureradIntakt',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note64.data.UpparbetadEjFaktureradIntaktAbstract.table.UpparbetadEjFaktureradIntaktAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:UpparbetadEjFaktureradIntakt',
                standardRubrik: 'Upparbetad men ej fakturerad intäkt',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note64.data.UpparbetadEjFaktureradIntaktAbstract.table.UpparbetadEjFaktureradIntaktAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:UpparbetadEjFaktureradIntakt',
                standardRubrik: 'Upparbetad men ej fakturerad intäkt',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      UpparbetadEjFaktureradIntaktBestallarenInnehalletBelopp: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Av beställaren innehållet belopp avseende upparbetad men ej fakturerad intäkt'
        ),
        table: table<IxbrlCell>(
          'notes.note64.data.UpparbetadEjFaktureradIntaktAbstract.UpparbetadEjFaktureradIntaktBestallarenInnehalletBelopp.table',
          {
            id: 'UpparbetadEjFaktureradIntaktBestallarenInnehalletBelopp',
            label: 'Av beställaren innehållet belopp',
            dataType: 'text',
            active: true,
          },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) ?? '',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) ?? '',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows.addRowWithType(
              'UpparbetadEjFaktureradIntaktBestallarenInnehalletBelopp',
              'row',
              noteLabel('Av beställaren innehållet belopp'),
              ixbrlCell(value(undefined), {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:UpparbetadEjFaktureradIntaktBestallarenInnehalletBelopp',
                standardRubrik:
                  'Av beställaren innehållet belopp avseende upparbetad men ej fakturerad intäkt',
                negateValue: false,
              }),
              ixbrlCell(value(undefined), {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:UpparbetadEjFaktureradIntaktBestallarenInnehalletBelopp',
                standardRubrik:
                  'Av beställaren innehållet belopp avseende upparbetad men ej fakturerad intäkt',
                negateValue: false,
              })
            );
            return rows.build();
          })
          .build(),
      },
      NotUpparbetadEjFaktureradIntaktKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till specifikation av upparbetad men ej fakturerad intäkt'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotUpparbetadEjFaktureradIntaktKommentar',
          standardRubrik:
            'Kommentar till specifikation av upparbetad men ej fakturerad intäkt',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2064K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        UpparbetadEjFaktureradIntaktAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            UpparbetadEjFaktureradIntaktBestallarenInnehalletBelopp: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                table: { type: 'table' as const },
              },
            },

            NotUpparbetadEjFaktureradIntaktKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
