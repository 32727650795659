import React from 'react';
import { FinancialYear, Period } from '@agoy/api-sdk-core';
import { UserInput } from '_reconciliation/types';
import { AccountingBalances } from 'types/Accounting';
import { PeriodDocument } from 'utils/usePeriodDocuments/Provider';
import { GetSpecificationType } from '_clients/types/types';

import Content from './AccountContent';
import Header from './Header';
import { BalanceAndInput } from '../types';
import {
  createRowData,
  createUserInputData,
} from '../HiddenRow/HiddenGroupRow/utils';

type PrintSpecificationsModalProps = {
  clientId: string;
  accounts: string[];
  documentsByAccount: Record<string, PeriodDocument[]>;
  period: Period;
  financialYear: FinancialYear;
  accountingBalances: AccountingBalances;
  userInputData: UserInput;
  actualFinancialYear: string;
  balanceAndInput: BalanceAndInput[];
  specifications: Record<string, GetSpecificationType>;
  isNewSpecifications: boolean;
  isPrint?: boolean;
  handleDocumentLoaded: () => void;
};

const PrintSpecificationsContent = ({
  clientId,
  accounts,
  documentsByAccount,
  period,
  actualFinancialYear,
  financialYear,
  accountingBalances,
  userInputData,
  balanceAndInput,
  specifications,
  isNewSpecifications,
  isPrint,
  handleDocumentLoaded,
}: PrintSpecificationsModalProps): JSX.Element | null => {
  return (
    <>
      <Header
        financialYear={actualFinancialYear}
        period={period}
        clientId={clientId}
      />

      {accounts.map((account, index) => {
        const userData = userInputData[`account${account}`];
        const accountInformation = accountingBalances?.accounts.find(
          (a) => a.number === account
        );
        if (!accountInformation) {
          return null;
        }

        const yearBalance = accountInformation.balances[financialYear.id];

        const rowData = createRowData(
          accountingBalances.periods,
          yearBalance,
          accountInformation.periods
        );

        const data = createUserInputData(rowData, userData);

        return (
          <Content
            key={account}
            financialYear={financialYear}
            period={period}
            userData={data[period.id]}
            accountInformation={accountInformation}
            balanceAndInput={balanceAndInput.find(
              (bi) => bi.number === Number(account)
            )}
            documents={documentsByAccount[account] || []}
            specifications={specifications[account]}
            isPrint={isPrint}
            isLastAccount={index === accounts.length - 1}
            isNewSpecifications={!!isNewSpecifications}
            onDocumentLoaded={handleDocumentLoaded}
          />
        );
      })}
    </>
  );
};

export default PrintSpecificationsContent;
