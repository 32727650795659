import { GlobalActions } from '_shared/redux/actions';
import { FeatureSwitchType } from '../types';
import { SET_FEATURE_SWITCH } from './action-types';
import { SetFeatureSwitchAction } from './featureSwitchActions';

export default (
  state: FeatureSwitchType = [],
  action: SetFeatureSwitchAction | GlobalActions
): FeatureSwitchType => {
  switch (action.type) {
    case SET_FEATURE_SWITCH:
      return action.featureSwitch;
    default:
      return state;
  }
};
