import React from 'react';

import { useApiSdk } from 'api-sdk';
import { useAsync } from 'utils/hooks';
import { LicenseType } from '_payment/types';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import config from '_shared/services/config';
import { useSelector } from '../../redux/reducers';

const USER_LICENSE_KEY = 'userLicense';

/**
 * Helper method to load user license from session storage
 */
const getUserLicense = () =>
  window.sessionStorage.getItem(USER_LICENSE_KEY) as LicenseType | null;
/**
 * Helper method to store user license to session storage
 */
export const setUserLicense = (license: LicenseType) =>
  window.sessionStorage.setItem(USER_LICENSE_KEY, license);

/**
 * Fetch and injects the license status into session storage.
 * Session storage must be used instead of a context, due to obscure rendering hierarchy of the program ui.
 */
const withLicenseStatusFetcher = (Component) => (props) => {
  const sdk = useApiSdk();

  const { status, run } = useAsync({
    license: 'none',
  } as { license: LicenseType });

  React.useEffect(() => {
    run(sdk.getUserLicense())
      .then((res) => {
        window.sessionStorage.setItem(USER_LICENSE_KEY, res.license);
      })
      .catch(console.error);
  }, []);

  if (status === 'pending' || status === 'idle') {
    return <LoadingPlaceholder />;
  }

  if (status === 'rejected') {
    return <>error loading user license status</>;
  }

  if (status === 'resolved') {
    return <Component {...props} />;
  }

  throw new Error(`Unhandled status: ${status}`);
};

/**
 * Exposes the license status server-state, relative to the current organisation.
 */
function useLicenseStatus(): { license: LicenseType } {
  const agoyPackageName = useSelector((state) => state.user.agoyPackageName);
  if (config.isFortnoxCloud && agoyPackageName) {
    return { license: agoyPackageName };
  }
  const license = getUserLicense();

  if (license === null) return { license: 'none' };

  return { license };
}

export { withLicenseStatusFetcher, useLicenseStatus };
