import { Dispatch } from 'redux';
import { ActivityLog } from '@agoy/activity-log';
import {
  insertUserAttributes,
  setUserLoggedIn,
  setUserLoggedOut,
  userIsAuthenticating,
  userNotAuthenticating,
} from '_users/redux/actions';
import sendActivityEvent from 'Api/Activity/authorizationActivityEventHandler';
import { ContextFunctionsType } from './types';
import { getApiSdk } from '../../../api-sdk';
import { createFortnoxContext } from '../../../utils/AgoyAppClient/AgoyAppClientContext';
import { ApiError } from '@agoy/api-sdk-core';

/**
 * Factory for managing the authentication using Fortnox Session.
 */
const fortnoxAuth = (
  dispatch: Dispatch<any>,
  setStateUser: (user: any) => void,
  setStateIsAuthenticated: (value: boolean) => void,
  setStateIsLoading: (value: boolean) => void
): ContextFunctionsType => {
  const setUser = (user) => {
    setStateUser(user);
    dispatch(insertUserAttributes(user));
  };

  const setIsAuthenticated = (value: boolean) => {
    setStateIsAuthenticated(value);
    if (value) {
      dispatch(setUserLoggedIn());
    } else {
      dispatch(setUserLoggedOut());
    }
  };

  const setIsLoading = (value: boolean) => {
    setStateIsLoading(value);
    if (value) {
      dispatch(userIsAuthenticating());
    } else {
      dispatch(userNotAuthenticating());
    }
  };

  const withLoading = (fn) => async (args?) => {
    try {
      setIsLoading(true);
      await fn(args);
    } finally {
      setIsLoading(false);
    }
  };

  const getCurrentSessionAWS = withLoading(async (fid) => {
    const sdk = getApiSdk(createFortnoxContext(fid));
    try {
      const userAttributes = await sdk.getCurrentUserAttributes();
      if (userAttributes) {
        setUser(userAttributes);
        setIsAuthenticated(true);
        return;
      }
    } catch (error) {
      if (error instanceof ApiError && error.status === 403) {
        setUser({
          agoyPackageName: 'none',
        });
        setIsAuthenticated(true);
        return;
      }
    }
    setUser(null);
    setIsAuthenticated(false);
  });

  const logInAWS = withLoading(async () => {
    throw new Error('Not supported');
  });

  const logOutAWS = withLoading(async () => {
    await sendActivityEvent(
      ActivityLog.createActivityLogEvent({
        program: 'SYSTEM',
        section: 'GENERAL',
        resource: 'USER',
        operation: 'LOGOUT',
        arguments: [],
      })
    );
    setUser(null);
    setIsAuthenticated(false);
  });

  const signUpAWS = withLoading(async () => {
    throw new Error('Not supported');
  });

  const confirmAccountAWS = withLoading(async () => {
    throw new Error('Not supported');
  });

  const resendConfirmationAWS = withLoading(async () => {
    throw new Error('Not supported');
  });

  const forgotPasswordAWS = withLoading(async () => {
    throw new Error('Not supported');
  });

  const changePasswordAWS = withLoading(async () => {
    throw new Error('Not supported');
  });

  const confirmNewPasswordAWS = withLoading(async () => {
    throw new Error('Not supported');
  });

  return {
    getCurrentSessionAWS,
    logInAWS,
    logOutAWS,
    signUpAWS,
    confirmAccountAWS,
    resendConfirmationAWS,
    forgotPasswordAWS,
    changePasswordAWS,
    confirmNewPasswordAWS,
    refreshSessionAWS: getCurrentSessionAWS,
  };
};

export default fortnoxAuth;
