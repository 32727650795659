/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: Former Note 75
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  previousYear,
  year,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2012K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2012K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel(
    'Av- och nedskrivningar av materiella och immateriella anläggningstillgångar'
  ),
  data: {
    active: true,
    AvskrivningarEnligtPlanTillgangAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Periodens avskrivningar enligt plan fördelade per tillgång'
      ),
      table: table<IxbrlCell>(
        'notes.note12.data.AvskrivningarEnligtPlanTillgangAbstract.table',
        {
          id: 'AvskrivningarEnligtPlanTillgangAbstract',
          label: 'Avskrivningar enligt plan fördelade per tillgång',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'AvskrivningarEnligtPlanTillgangAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'AvskrivningarBalanseradeutgifterUtvecklingsarbetenLiknandeArbetenInklusiveAvskrivningarUppskrivningar',
                'row',
                noteLabel(
                  'Balanserade utgifter för utvecklingsarbeten och liknande arbeten'
                ),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarBalanseradeutgifterUtvecklingsarbetenLiknandeArbetenInklusiveAvskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens avskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten, inklusive avskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarBalanseradeutgifterUtvecklingsarbetenLiknandeArbetenInklusiveAvskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens avskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten, inklusive avskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'AvskrivningarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterInklusiveAvskrivningarUppskrivningar',
                'row',
                noteLabel('Koncessioner, patent, licenser och varumärken'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterInklusiveAvskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens avskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter, inklusive avskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterInklusiveAvskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens avskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter, inklusive avskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'AvskrivningarHyresratterLiknandeRattigheterInklusiveAvskrivningarUppskrivningar',
                'row',
                noteLabel('Hyresrätter och liknande rättigheter'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarHyresratterLiknandeRattigheterInklusiveAvskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens avskrivningar hyresrätter och liknande rättigheter, inklusive avskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarHyresratterLiknandeRattigheterInklusiveAvskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens avskrivningar hyresrätter och liknande rättigheter, inklusive avskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'GoodwillForandringAvskrivningarAretsAvskrivningar',
                'row',
                noteLabel('Goodwill'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:GoodwillForandringAvskrivningarAretsAvskrivningar',
                  standardRubrik: 'Periodens avskrivningar goodwill',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:GoodwillForandringAvskrivningarAretsAvskrivningar',
                  standardRubrik: 'Periodens avskrivningar goodwill',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'AvskrivningarByggnaderMarkInklusiveAvskrivningarUppskrivningar',
                'row',
                noteLabel('Byggnader och mark'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarByggnaderMarkInklusiveAvskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens avskrivningar byggnader och mark, inklusive avskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarByggnaderMarkInklusiveAvskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens avskrivningar byggnader och mark, inklusive avskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'AvskrivningarMaskinerAndraTekniskaAnlaggningarInklusiveAvskrivningarUppskrivningar',
                'row',
                noteLabel('Maskiner och andra tekniska anläggningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarMaskinerAndraTekniskaAnlaggningarInklusiveAvskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens avskrivningar maskiner och andra tekniska anläggningar, inklusive avskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarMaskinerAndraTekniskaAnlaggningarInklusiveAvskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens avskrivningar maskiner och andra tekniska anläggningar, inklusive avskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'AvskrivningarInventarierVerktygInstallationerInklusiveAvskrivningarUppskrivningar',
                'row',
                noteLabel('Inventarier, verktyg och installationer'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarInventarierVerktygInstallationerInklusiveAvskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens avskrivningar inventarier, verktyg och installationer, inklusive avskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarInventarierVerktygInstallationerInklusiveAvskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens avskrivningar inventarier, verktyg och installationer, inklusive avskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'AvskrivningarForbattringsutgifterAnnansFastighetInklusiveAvskrivningarUppskrivningar',
                'row',
                noteLabel('Förbättringsutgifter på annans fastighet'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarForbattringsutgifterAnnansFastighetInklusiveAvskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens avskrivningar förbättringsutgifter på annans fastighet, inklusive avskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarForbattringsutgifterAnnansFastighetInklusiveAvskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens avskrivningar förbättringsutgifter på annans fastighet, inklusive avskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'AvskrivningarOvrigaMateriellaAnlaggningstillgangarInklusiveAvskrivningarUppskrivningar',
                'row',
                noteLabel('Övriga materiella anläggningstillgångar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarOvrigaMateriellaAnlaggningstillgangarInklusiveAvskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens avskrivningar övriga materiella anläggningstillgångar, inklusive avskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarOvrigaMateriellaAnlaggningstillgangarInklusiveAvskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens avskrivningar övriga materiella anläggningstillgångar, inklusive avskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'AvskrivningarEnligtPlanPerTillgang',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note12.data.AvskrivningarEnligtPlanTillgangAbstract.table.AvskrivningarEnligtPlanTillgangAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'period0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AvskrivningarEnligtPlan',
                standardRubrik:
                  'Periodens avskrivningar enligt plan av materiella och immateriella anläggningstillgångar',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note12.data.AvskrivningarEnligtPlanTillgangAbstract.table.AvskrivningarEnligtPlanTillgangAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'period1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AvskrivningarEnligtPlan',
                standardRubrik:
                  'Periodens avskrivningar enligt plan av materiella och immateriella anläggningstillgångar',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      AvskrivningarEnligtPlanTillgangKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id('notes.note12.data.AvskrivningarEnligtPlanTillgangAbstract.active')
        ),
        title: field(
          'Kommentar till periodens avskrivningar enligt plan fördelade per tillgång'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:AvskrivningarEnligtPlanTillgangKommentar',
          standardRubrik:
            'Kommentar till periodens avskrivningar enligt plan fördelade per tillgång',
        }),
      },
    },
    AvskrivningarEnligtPlanFordeladFunktionAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Periodens avskrivningar enligt plan fördelade per funktion'
      ),
      table: table<IxbrlCell>(
        'notes.note12.data.AvskrivningarEnligtPlanFordeladFunktionAbstract.table',
        {
          id: 'AvskrivningarEnligtPlanFordeladFunktionAbstract',
          label: 'Avskrivningar enligt plan fördelade per funktion',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'AvskrivningarEnligtPlanFordeladFunktionAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'AvskrivningarEnligtPlanFordeladFunktionKostnadSaldaVaror',
                'row',
                noteLabel('Kostnad för sålda varor'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionKostnadSaldaVaror',
                  standardRubrik:
                    'Periodens avskrivningar i funktionen kostnad för sålda varor',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionKostnadSaldaVaror',
                  standardRubrik:
                    'Periodens avskrivningar i funktionen kostnad för sålda varor',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'AvskrivningarEnligtPlanFordeladFunktionForsaljningskostnader',
                'row',
                noteLabel('Försäljningskostnader'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionForsaljningskostnader',
                  standardRubrik:
                    'Periodens avskrivningar i funktionen försäljningskostnader',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionForsaljningskostnader',
                  standardRubrik:
                    'Periodens avskrivningar i funktionen försäljningskostnader',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'AvskrivningarEnligtPlanFordeladFunktionAdministrationskostnader',
                'row',
                noteLabel('Administrationskostnader'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionAdministrationskostnader',
                  standardRubrik:
                    'Periodens avskrivningar i funktionen administrationskostnader',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionAdministrationskostnader',
                  standardRubrik:
                    'Periodens avskrivningar i funktionen administrationskostnader',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'AvskrivningarEnligtPlanFordeladFunktionForskningUtvecklingskostnader',
                'row',
                noteLabel('Forsknings- och utvecklingskostnader'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionForskningUtvecklingskostnader',
                  standardRubrik:
                    'Periodens avskrivningar i funktionen forsknings- och utvecklingskostnader',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionForskningUtvecklingskostnader',
                  standardRubrik:
                    'Periodens avskrivningar i funktionen forsknings- och utvecklingskostnader',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'AvskrivningarEnligtPlanFordeladFunktionOvrigaRorelsekostnader',
                'row',
                noteLabel('Övriga rörelsekostnader'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionOvrigaRorelsekostnader',
                  standardRubrik:
                    'Periodens avskrivningar i övriga rörelsekostnader i funktionsindelad resultaträkning',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionOvrigaRorelsekostnader',
                  standardRubrik:
                    'Periodens avskrivningar i övriga rörelsekostnader i funktionsindelad resultaträkning',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'AvskrivningarEnligtPlanPerFunktion',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note12.data.AvskrivningarEnligtPlanFordeladFunktionAbstract.table.AvskrivningarEnligtPlanFordeladFunktionAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'period0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AvskrivningarEnligtPlan',
                standardRubrik:
                  'Periodens avskrivningar enligt plan av materiella och immateriella anläggningstillgångar',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note12.data.AvskrivningarEnligtPlanFordeladFunktionAbstract.table.AvskrivningarEnligtPlanFordeladFunktionAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'period1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AvskrivningarEnligtPlan',
                standardRubrik:
                  'Periodens avskrivningar enligt plan av materiella och immateriella anläggningstillgångar',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      AvskrivningarEnligtPlanFordeladFunktionKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id(
            'notes.note12.data.AvskrivningarEnligtPlanFordeladFunktionAbstract.active'
          )
        ),
        title: field(
          'Kommentar till periodens avskrivningar fördelade per funktion'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionKommentar',
          standardRubrik:
            'Kommentar till periodens avskrivningar fördelade per funktion',
        }),
      },
    },
    NedskrivningarFordeladTillgangAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Periodens nedskrivningar fördelade per tillgång'),
      table: table<IxbrlCell>(
        'notes.note12.data.NedskrivningarFordeladTillgangAbstract.table',
        {
          id: 'NedskrivningarFordeladTillgangAbstract',
          label: 'Nedskrivningar fördelade per tillgång',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'NedskrivningarFordeladTillgangAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'NedskrivningarBalanseradeUtgifterUtvecklingsarbetenLiknandeArbetenInklusiveNedskrivningarUppskrivningar',
                'row',
                noteLabel(
                  'Balanserade utgifter för utvecklingsarbeten och liknande arbeten'
                ),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarBalanseradeUtgifterUtvecklingsarbetenLiknandeArbetenInklusiveNedskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens nedskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten, inklusive nedskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarBalanseradeUtgifterUtvecklingsarbetenLiknandeArbetenInklusiveNedskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens nedskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten, inklusive nedskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'NedskrivningarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterInklusiveNedskrivningarUppskrivningar',
                'row',
                noteLabel('Koncessioner, patent, licenser och varumärken'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterInklusiveNedskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens nedskrivningar av koncessioner, patent, licenser, varumärken samt liknande rättigheter, inklusive nedskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterInklusiveNedskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens nedskrivningar av koncessioner, patent, licenser, varumärken samt liknande rättigheter, inklusive nedskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'NedskrivningarHyresratterLiknandeRattigheterInklusiveNedskrivningarUppskrivningar',
                'row',
                noteLabel('Hyresrätter och liknande rättigheter'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarHyresratterLiknandeRattigheterInklusiveNedskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens nedskrivningar av hyresrätter och liknande rättigheter, inklusive nedskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarHyresratterLiknandeRattigheterInklusiveNedskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens nedskrivningar av hyresrätter och liknande rättigheter, inklusive nedskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'GoodwillForandringNedskrivningarAretsNedskrivningar',
                'row',
                noteLabel('Goodwill'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:GoodwillForandringNedskrivningarAretsNedskrivningar',
                  standardRubrik: 'Periodens nedskrivningar av goodwill',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:GoodwillForandringNedskrivningarAretsNedskrivningar',
                  standardRubrik: 'Periodens nedskrivningar av goodwill',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'NedskrivningarByggnaderMarkInklusiveNedskrivningarUppskrivningar',
                'row',
                noteLabel('Byggnader och mark'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarByggnaderMarkInklusiveNedskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens nedskrivningar av byggnader och mark, inklusive nedskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarByggnaderMarkInklusiveNedskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens nedskrivningar av byggnader och mark, inklusive nedskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'NedskrivningarMaskinerAndraTekniskaAnlaggningarInklusiveNedskrivningarUppskrivningar',
                'row',
                noteLabel('Maskiner och andra tekniska anläggningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarMaskinerAndraTekniskaAnlaggningarInklusiveNedskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens nedskrivningar av maskiner och andra tekniska anläggningar, inklusive nedskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarMaskinerAndraTekniskaAnlaggningarInklusiveNedskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens nedskrivningar av maskiner och andra tekniska anläggningar, inklusive nedskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'NedskrivningarInventarierVerktygInstallationerInklusiveNedskrivningarUppskrivningar',
                'row',
                noteLabel('Inventarier, verktyg och installationer'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarInventarierVerktygInstallationerInklusiveNedskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens nedskrivningar av inventarier, verktyg och installationer, inklusive nedskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarInventarierVerktygInstallationerInklusiveNedskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens nedskrivningar av inventarier, verktyg och installationer, inklusive nedskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'NedskrivningarForbattringsutgifterAnnansFastighetInklusiveNedskrivningarUppskrivningar',
                'row',
                noteLabel('Förbättringsutgifter på annans fastighet'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarForbattringsutgifterAnnansFastighetInklusiveNedskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens nedskrivningar av förbättringsutgifter på annans fastighet, inklusive nedskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarForbattringsutgifterAnnansFastighetInklusiveNedskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens nedskrivningar av förbättringsutgifter på annans fastighet, inklusive nedskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'NedskrivningarOvrigaMateriellaAnlaggningstillgAngarInklusiveNedskrivningarUppskrivningar',
                'row',
                noteLabel('Övriga materiella anläggningstillgångar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarOvrigaMateriellaAnlaggningstillgAngarInklusiveNedskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens nedskrivningar av övriga materiella anläggningstillgångar, inklusive nedskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarOvrigaMateriellaAnlaggningstillgAngarInklusiveNedskrivningarUppskrivningar',
                  standardRubrik:
                    'Periodens nedskrivningar av övriga materiella anläggningstillgångar, inklusive nedskrivningar på uppskrivningar',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'NedskrivningarImmateriellaMateriellaAnlaggningstillgangarPerTillgang',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note12.data.NedskrivningarFordeladTillgangAbstract.table.NedskrivningarFordeladTillgangAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'period0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:NedskrivningarImmateriellaMateriellaAnlaggningstillgangar',
                standardRubrik:
                  'Periodens nedskrivningar av immateriella och materiella anläggningstillgångar',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note12.data.NedskrivningarFordeladTillgangAbstract.table.NedskrivningarFordeladTillgangAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'period1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:NedskrivningarImmateriellaMateriellaAnlaggningstillgangar',
                standardRubrik:
                  'Periodens nedskrivningar av immateriella och materiella anläggningstillgångar',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NedskrivningarFordeladTillgangKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id('notes.note12.data.NedskrivningarFordeladTillgangAbstract.active')
        ),
        title: field(
          'Kommentar till periodens nedskrivningar fördelade per tillgång'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NedskrivningarFordeladTillgangKommentar',
          standardRubrik:
            'Kommentar till periodens nedskrivningar fördelade per tillgång',
        }),
      },
    },
    NedskrivningarFordeladFunktionAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Periodens nedskrivningar fördelade per funktion'),
      table: table<IxbrlCell>(
        'notes.note12.data.NedskrivningarFordeladFunktionAbstract.table',
        {
          id: 'NedskrivningarFordeladFunktionAbstract',
          label: 'Nedskrivningar fördelade per funktion',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'NedskrivningarFordeladFunktionAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'NedskrivningarFordeladFunktionKostnadSaldaVaror',
                'row',
                noteLabel('Kostnad för sålda varor'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarFordeladFunktionKostnadSaldaVaror',
                  standardRubrik:
                    'Periodens nedskrivningar i funktionen kostnad för sålda varor',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarFordeladFunktionKostnadSaldaVaror',
                  standardRubrik:
                    'Periodens nedskrivningar i funktionen kostnad för sålda varor',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'NedskrivningarFordeladFunktionForsaljningskostnader',
                'row',
                noteLabel('Försäljningskostnader'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarFordeladFunktionForsaljningskostnader',
                  standardRubrik:
                    'Periodens nedskrivningar i funktionen försäljningskostnader',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarFordeladFunktionForsaljningskostnader',
                  standardRubrik:
                    'Periodens nedskrivningar i funktionen försäljningskostnader',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'NedskrivningarFordeladFunktionAdministrationskostnader',
                'row',
                noteLabel('Administrationskostnader'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarFordeladFunktionAdministrationskostnader',
                  standardRubrik:
                    'Periodens nedskrivningar i funktionen administrationskostnader',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarFordeladFunktionAdministrationskostnader',
                  standardRubrik:
                    'Periodens nedskrivningar i funktionen administrationskostnader',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'NedskrivningarFordeladFunktionForskningUtvecklingskostnader',
                'row',
                noteLabel('Forsknings- och utvecklingskostnader'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarFordeladFunktionForskningUtvecklingskostnader',
                  standardRubrik:
                    'Periodens nedskrivningar i funktionen forsknings- och utvecklingskostnader',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarFordeladFunktionForskningUtvecklingskostnader',
                  standardRubrik:
                    'Periodens nedskrivningar i funktionen forsknings- och utvecklingskostnader',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'NedskrivningarFordeladFunktionOvrigaRorelsekostnader',
                'row',
                noteLabel('Övriga rörelsekostnader'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarFordeladFunktionOvrigaRorelsekostnader',
                  standardRubrik:
                    'Periodens nedskrivningar i övriga rörelsekostnader i funktionsindelad resultaträkning',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NedskrivningarFordeladFunktionOvrigaRorelsekostnader',
                  standardRubrik:
                    'Periodens nedskrivningar i övriga rörelsekostnader i funktionsindelad resultaträkning',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'NedskrivningarImmateriellaMateriellaAnlaggningstillgangarPerFunktion',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note12.data.NedskrivningarFordeladFunktionAbstract.table.NedskrivningarFordeladFunktionAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'period0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:NedskrivningarImmateriellaMateriellaAnlaggningstillgangar',
                standardRubrik:
                  'Periodens nedskrivningar av immateriella och materiella anläggningstillgångar',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note12.data.NedskrivningarFordeladFunktionAbstract.table.NedskrivningarFordeladFunktionAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'period1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:NedskrivningarImmateriellaMateriellaAnlaggningstillgangar',
                standardRubrik:
                  'Periodens nedskrivningar av immateriella och materiella anläggningstillgångar',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NedskrivningarFordeladFunktionKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id('notes.note12.data.NedskrivningarFordeladFunktionAbstract.active')
        ),
        title: field(
          'Kommentar till periodens nedskrivningar fördelade per funktion'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NedskrivningarFordeladFunktionKommentar',
          standardRubrik:
            'Kommentar till periodens nedskrivningar fördelade per funktion',
        }),
      },
    },
    AterfordaNedskrivningarFordeladTillgangAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Periodens återförda nedskrivningar fördelade per tillgång'),
      table: table<IxbrlCell>(
        'notes.note12.data.AterfordaNedskrivningarFordeladTillgangAbstract.table',
        {
          id: 'AterfordaNedskrivningarFordeladTillgangAbstract',
          label: 'Återförda nedskrivningar fördelade per tillgång',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'AterfordaNedskrivningarFordeladTillgangAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarAterfordaNedskrivningar',
                'row',
                noteLabel(
                  'Balanserade utgifter för utvecklingsarbeten och liknande arbeten'
                ),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarAterfordaNedskrivningar',
                  standardRubrik:
                    'Periodens återförda nedskrivningar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarAterfordaNedskrivningar',
                  standardRubrik:
                    'Periodens återförda nedskrivningar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                  negateValue: true,
                })
              );
              rows.addRowWithType(
                'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
                'row',
                noteLabel('Koncessioner, patent, licenser och varumärken'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
                  standardRubrik:
                    'Periodens återförda nedskrivningar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
                  standardRubrik:
                    'Periodens återförda nedskrivningar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                  negateValue: true,
                })
              );
              rows.addRowWithType(
                'HyresratterLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
                'row',
                noteLabel('Hyresrätter och liknande rättigheter'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
                  standardRubrik:
                    'Periodens återförda nedskrivningar av hyresrätter och liknande rättigheter',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
                  standardRubrik:
                    'Periodens återförda nedskrivningar av hyresrätter och liknande rättigheter',
                  negateValue: true,
                })
              );
              rows.addRowWithType(
                'GoodwillForandringNedskrivningarAterfordaNedskrivningar',
                'row',
                noteLabel('Goodwill'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:GoodwillForandringNedskrivningarAterfordaNedskrivningar',
                  standardRubrik:
                    'Periodens återförda nedskrivningar av goodwill',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:GoodwillForandringNedskrivningarAterfordaNedskrivningar',
                  standardRubrik:
                    'Periodens återförda nedskrivningar av goodwill',
                  negateValue: true,
                })
              );
              rows.addRowWithType(
                'ByggnaderMarkForandringNedskrivningarAterfordaNedskrivningar',
                'row',
                noteLabel('Byggnader och mark'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ByggnaderMarkForandringNedskrivningarAterfordaNedskrivningar',
                  standardRubrik:
                    'Periodens återförda nedskrivningar av byggnader och mark',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ByggnaderMarkForandringNedskrivningarAterfordaNedskrivningar',
                  standardRubrik:
                    'Periodens återförda nedskrivningar av byggnader och mark',
                  negateValue: true,
                })
              );
              rows.addRowWithType(
                'MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAterfordaNedskrivningar',
                'row',
                noteLabel('Maskiner och andra tekniska anläggningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAterfordaNedskrivningar',
                  standardRubrik:
                    'Periodens återförda nedskrivningar av maskiner och andra tekniska anläggningar',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAterfordaNedskrivningar',
                  standardRubrik:
                    'Periodens återförda nedskrivningar av maskiner och andra tekniska anläggningar',
                  negateValue: true,
                })
              );
              rows.addRowWithType(
                'InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar',
                'row',
                noteLabel('Inventarier, verktyg och installationer'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar',
                  standardRubrik:
                    'Periodens återförda nedskrivningar av inventarier, verktyg och installationer',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar',
                  standardRubrik:
                    'Periodens återförda nedskrivningar av inventarier, verktyg och installationer',
                  negateValue: true,
                })
              );
              rows.addRowWithType(
                'ForbattringsutgifterAnnansFastighetForandringNedskrivningarAterfordaNedskrivningar',
                'row',
                noteLabel('Förbättringsutgifter på annans fastighet'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarAterfordaNedskrivningar',
                  standardRubrik:
                    'Periodens återförda nedskrivningar av förbättringsutgifter på annans fastighet',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarAterfordaNedskrivningar',
                  standardRubrik:
                    'Periodens återförda nedskrivningar av förbättringsutgifter på annans fastighet',
                  negateValue: true,
                })
              );
              rows.addRowWithType(
                'OvrigaMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningar',
                'row',
                noteLabel('Övriga materiella anläggningstillgångar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningar',
                  standardRubrik:
                    'Periodens återförda nedskrivningar av övriga materiella anläggningstillgångar',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningar',
                  standardRubrik:
                    'Periodens återförda nedskrivningar av övriga materiella anläggningstillgångar',
                  negateValue: true,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'AterfordaNedskrivningarImmateriellaMateriellaAnlaggningstillgangarPerTillgang',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note12.data.AterfordaNedskrivningarFordeladTillgangAbstract.table.AterfordaNedskrivningarFordeladTillgangAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'period0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AterfordaNedskrivningarImmateriellaMateriellaAnlaggningstillgangar',
                standardRubrik:
                  'Periodens återförda nedskrivningar avseende immateriella och materiella anläggningstillgångar',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note12.data.AterfordaNedskrivningarFordeladTillgangAbstract.table.AterfordaNedskrivningarFordeladTillgangAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'period1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AterfordaNedskrivningarImmateriellaMateriellaAnlaggningstillgangar',
                standardRubrik:
                  'Periodens återförda nedskrivningar avseende immateriella och materiella anläggningstillgångar',
                saldo: 'credit',
                negateValue: true,
              }
            )
          );
          return rows.build();
        })
        .build(),
      AterfordaNedskrivningarFordeladTillgangKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id(
            'notes.note12.data.AterfordaNedskrivningarFordeladTillgangAbstract.active'
          )
        ),
        title: field(
          'Kommentar till periodens återförda nedskrivningar fördelade per tillgång'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:AterfordaNedskrivningarFordeladTillgangKommentar',
          standardRubrik:
            'Kommentar till periodens återförda nedskrivningar fördelade per tillgång',
        }),
      },
    },
    AterfordaNedskrivningarFordeladFunktionAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Periodens återförda nedskrivningar fördelade per funktion'),
      table: table<IxbrlCell>(
        'notes.note12.data.AterfordaNedskrivningarFordeladFunktionAbstract.table',
        {
          id: 'AterfordaNedskrivningarFordeladFunktionAbstract',
          label: 'Återförda nedskrivningar fördelade per funktion',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'AterfordaNedskrivningarFordeladFunktionAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'AterfordaNedskrivningarFordeladFunktionKostnadSaldaVaror',
                'row',
                noteLabel('Kostnad för sålda varor'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionKostnadSaldaVaror',
                  standardRubrik:
                    'Periodens återförda nedskrivningar i funktionen kostnad för sålda varor, exklusive återföringar i samband med försäljningar och utrangeringar',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionKostnadSaldaVaror',
                  standardRubrik:
                    'Periodens återförda nedskrivningar i funktionen kostnad för sålda varor, exklusive återföringar i samband med försäljningar och utrangeringar',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows.addRowWithType(
                'AterfordaNedskrivningarFordeladFunktionForsaljningskostnader',
                'row',
                noteLabel('Försäljningskostnader'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionForsaljningskostnader',
                  standardRubrik:
                    'Periodens återförda nedskrivningar i funktionen försäljningskostnader, exklusive återföringar i samband med försäljningar och utrangeringar',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionForsaljningskostnader',
                  standardRubrik:
                    'Periodens återförda nedskrivningar i funktionen försäljningskostnader, exklusive återföringar i samband med försäljningar och utrangeringar',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows.addRowWithType(
                'AterfordaNedskrivningarFordeladFunktionAdministrationskostnader',
                'row',
                noteLabel('Administrationskostnader'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionAdministrationskostnader',
                  standardRubrik:
                    'Periodens återförda nedskrivningar i funktionen administrationskostnader, exklusive återföringar i samband med försäljningar och utrangeringar',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionAdministrationskostnader',
                  standardRubrik:
                    'Periodens återförda nedskrivningar i funktionen administrationskostnader, exklusive återföringar i samband med försäljningar och utrangeringar',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows.addRowWithType(
                'AterfordaNedskrivningarFordeladFunktionForskningUtvecklingskostnader',
                'row',
                noteLabel('Forsknings- och utvecklingskostnader'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionForskningUtvecklingskostnader',
                  standardRubrik:
                    'Periodens återförda nedskrivningar i funktionen forsknings- och utvecklingskostnader, exklusive återföringar i samband med försäljningar och utrangeringar',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionForskningUtvecklingskostnader',
                  standardRubrik:
                    'Periodens återförda nedskrivningar i funktionen forsknings- och utvecklingskostnader, exklusive återföringar i samband med försäljningar och utrangeringar',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows.addRowWithType(
                'AterfordaNedskrivningarFordeladFunktionOvrigaRorelsekostnader',
                'row',
                noteLabel('Övriga rörelsekostnader'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionOvrigaRorelsekostnader',
                  standardRubrik:
                    'Periodens återförda nedskrivningar i övriga rörelsekostnader i funktionsindelad resultaträkning, exklusive återföringar i samband med försäljningar och utrangeringar',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionOvrigaRorelsekostnader',
                  standardRubrik:
                    'Periodens återförda nedskrivningar i övriga rörelsekostnader i funktionsindelad resultaträkning, exklusive återföringar i samband med försäljningar och utrangeringar',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'AterfordaNedskrivningarImmateriellaMateriellaAnlaggningstillgangarPerFunktion',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note12.data.AterfordaNedskrivningarFordeladFunktionAbstract.table.AterfordaNedskrivningarFordeladFunktionAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'period0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AterfordaNedskrivningarImmateriellaMateriellaAnlaggningstillgangar',
                standardRubrik:
                  'Periodens återförda nedskrivningar avseende immateriella och materiella anläggningstillgångar',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note12.data.AterfordaNedskrivningarFordeladFunktionAbstract.table.AterfordaNedskrivningarFordeladFunktionAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'period1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AterfordaNedskrivningarImmateriellaMateriellaAnlaggningstillgangar',
                standardRubrik:
                  'Periodens återförda nedskrivningar avseende immateriella och materiella anläggningstillgångar',
                saldo: 'credit',
                negateValue: true,
              }
            )
          );
          return rows.build();
        })
        .build(),
      AterfordaNedskrivningarFordeladFunktionKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id(
            'notes.note12.data.AterfordaNedskrivningarFordeladFunktionAbstract.active'
          )
        ),
        title: field(
          'Kommentar till periodens återförda nedskrivningar fördelade per funktion'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionKommentar',
          standardRubrik:
            'Kommentar till periodens återförda nedskrivningar fördelade per funktion',
        }),
      },
    },
    NotAvNedskrivningarMateriellaImmateriellaAnlaggningstillgangarKommentar: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Kommentar till specifikation av avskrivningar och nedskrivningar av materiella och immateriella anläggningstillgångar'
      ),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:NotAvNedskrivningarMateriellaImmateriellaAnlaggningstillgangarKommentar',
        standardRubrik:
          'Kommentar till specifikation av avskrivningar och nedskrivningar av materiella och immateriella anläggningstillgångar',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2012K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        AvskrivningarEnligtPlanTillgangAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            AvskrivningarEnligtPlanTillgangKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        AvskrivningarEnligtPlanFordeladFunktionAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            AvskrivningarEnligtPlanFordeladFunktionKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        NedskrivningarFordeladTillgangAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NedskrivningarFordeladTillgangKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        NedskrivningarFordeladFunktionAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NedskrivningarFordeladFunktionKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        AterfordaNedskrivningarFordeladTillgangAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            AterfordaNedskrivningarFordeladTillgangKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        AterfordaNedskrivningarFordeladFunktionAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            AterfordaNedskrivningarFordeladFunktionKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        NotAvNedskrivningarMateriellaImmateriellaAnlaggningstillgangarKommentar:
          {
            type: 'part' as const,
            children: {
              type: type,
              level: number,
              active: boolean,

              title: cell,

              value: cell,
            },
          },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
