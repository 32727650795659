import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { Box, Switch, Divider } from '@material-ui/core';
import { Check, Report } from '@material-ui/icons';
import { format, parseISO } from 'date-fns';

import { Period } from '@agoy/api-sdk-core';
import { AccountingAccount } from 'types/Accounting';
import { PeriodDocument } from 'utils/usePeriodDocuments/Provider';
import { Comment } from '_shared/types';
import { InputData } from '_reconciliation/types';
import { EnableForPrint } from '_shared/components/GroupsSpecificationInfo/types';
import SpecificationInfo from '_shared/components/SpecificationInfo';
import { GetSpecificationType } from '_clients/types/types';
import PrintSpecificationsTables from '_reconciliation/components/ReconciliationView/HiddenRow/Rows/SpecificationView/PrintSpecification/PrintSpecificationsTables';
import { getColumnSum } from '_reconciliation/components/ReconciliationView/HiddenRow/Rows/SpecificationView/utils';
import Document from '_shared/components/Document';
import { ccyFormat } from '@agoy/common';

import SpecificationsTable from './SpecificationsTable';
import TaxesDataContext from '../../../service/TaxesDataContext';
import DocumentsList from '_shared/components/VoucherView/DocumentsList';
import Typography from '_shared/components/Typography/Typography';
import When from '_shared/components/When/When';

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;

  @media print {
    padding: 0;
    border: none;
    margin-bottom: ${(props) => props.theme.spacing(4)}px;
    break-inside: avoid;
  }
`;

const StyledDivider = styled(Divider)`
  background-color: ${(props) => props.theme.palette.grey[700]};
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  margin-left: ${({ theme }) => theme.spacing(0.5)}px;
  width: 2px;
  height: auto;
  @media print {
    display: none;
  }
`;

const StyledSwitchWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const StyledSwitch = styled(Switch)`
  color: ${(props) => props.theme.palette.secondary.main};
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  @media print {
    display: none;
  }
`;

const AccountSectionLabel = styled.p`
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

const StyledCheckIcon = styled(Box)`
  margin-right: ${({ theme }) => theme.spacing(3)}px;
`;

const InternalCommentBox = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

const DocumentWrapper = styled(Box)`
  padding: 8px;
  padding-left: 0;
  white-space: pre-wrap;
  p {
    margin-bottom: 4px;
  }
  span {
    padding-left: 0px;
  }
  @media print {
    display: flex;
    width: 1000px;
    white-space: none;
    padding: 0;
  }
`;

const StyledWrapper = styled(Box)`
  padding: 8px;
  padding-left: 0;
  white-space: pre-wrap;
  p {
    margin-bottom: 4px;
  }
  span {
    padding-left: 0px;
  }
`;

const SpecificationBox = styled(Box)`
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
  @media print {
    max-width: 1000px;
  }
`;

const StyledLabel = styled(Box)`
  display: flex;
  flex-direction: 'row';
  padding-left: 8px;
`;

const AuthorTypographyBlock = styled(Typography)`
  font-weight: 500;
`;

const SpecificationsTableContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  @media print {
    max-width: 1000px;
  }
`;

export interface BalanceHistoryType {
  account: number;
  saldo: number | null;
  createdAt: string;
  authorName: string;
}

type AccountSpecificationsItemProps = {
  inputData: InputData;
  accountInformation: AccountingAccount;
  period: Period;
  documents: PeriodDocument[];
  balanceHistory?: BalanceHistoryType;
  checkedHistory?: CheckedHistoryItemType;
  enableForPrint?: EnableForPrint;
  part: string | undefined;
  allInternalComments: Comment[];
  specifications?: GetSpecificationType;
  isNewSpecifications: boolean;
  isPrint?: boolean;
  onSwitch?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export interface CheckedHistoryItemType {
  checked: boolean;
  date: string;
  authorName: string;
  account: string;
}

const AccountSpecificationsItem = ({
  inputData,
  accountInformation,
  period,
  documents,
  balanceHistory,
  checkedHistory,
  enableForPrint = {
    comment: {},
    documents: {},
  },
  part,
  allInternalComments,
  specifications,
  isNewSpecifications,
  isPrint = false,
  onSwitch = () => {},
}: AccountSpecificationsItemProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const { financialYearObj: financialYear } = useContext(TaxesDataContext);

  const { legacySpecifications, comment, printComment } = inputData;

  const isBalancesEqual = useMemo(() => {
    if (balanceHistory && accountInformation.periods[period.id]) {
      return (
        ccyFormat(
          balanceHistory?.saldo != null ? balanceHistory?.saldo : undefined
        ) === ccyFormat(accountInformation.periods[period.id]?.out)
      );
    }
    return true;
  }, [accountInformation.periods, balanceHistory, period.id]);

  const specificationAmount = useMemo(() => {
    if (!specifications?.specification.actualBalanceColumnId) {
      return 0;
    }

    return (
      getColumnSum(
        specifications.specification.actualBalanceColumnId,
        specifications.rows
      ) ?? 0
    );
  }, [specifications]);

  const specificationInputData = useMemo(() => {
    return {
      ...inputData,
      yearIB: accountInformation.balances[financialYear.id]?.in ?? 0,
      specificationAmount: isNewSpecifications
        ? specificationAmount
        : undefined,
    };
  }, [
    accountInformation.balances,
    financialYear.id,
    inputData,
    isNewSpecifications,
    specificationAmount,
  ]);

  const internalComments = allInternalComments.filter(
    (c) =>
      c.periodId === period.id &&
      c.key === `account.${accountInformation.number}.internal`
  );

  const isCommentsEnabled =
    enableForPrint.comment[`account${accountInformation.number}`] ?? true;

  const isDocumentsEnabled =
    enableForPrint.documents[`account${accountInformation.number}`] ?? true;

  const showExternalComment = printComment && (!isPrint || isCommentsEnabled);

  const showDocuments = !isPrint || isDocumentsEnabled;

  return (
    <Container>
      {accountInformation && financialYear && (
        <SpecificationBox>
          <SpecificationInfo
            period={period}
            financialYear={financialYear}
            inputData={specificationInputData}
            accountName={accountInformation.name}
            accountNumber={accountInformation.number}
            isNewSpecifications={isNewSpecifications}
          />
        </SpecificationBox>
      )}

      {isNewSpecifications && !!specifications?.rows.length && (
        <PrintSpecificationsTables
          columns={specifications.columns}
          rows={specifications.rows}
        />
      )}

      {!isNewSpecifications && !!legacySpecifications?.length && (
        <SpecificationsTableContainer>
          <SpecificationsTable specifications={legacySpecifications} />
        </SpecificationsTableContainer>
      )}

      {balanceHistory && (
        <StyledWrapper>
          <StyledLabel>
            <StyledCheckIcon>
              {isBalancesEqual ? (
                <Check color="primary" />
              ) : (
                <Report color="error" />
              )}
            </StyledCheckIcon>
            <Typography margin="none">
              Faktiskt saldo fylldes i av {balanceHistory.authorName}{' '}
              {format(parseISO(balanceHistory.createdAt), 'yyyy-MM-dd')} och
              uppgick till {balanceHistory.saldo} SEK
            </Typography>
          </StyledLabel>
        </StyledWrapper>
      )}

      {checkedHistory && (
        <StyledWrapper>
          <StyledLabel>
            <StyledCheckIcon>
              <Check color="primary" />
            </StyledCheckIcon>
            <Typography margin="none">
              Faktiskt saldo kvalitetsgranskades av {checkedHistory.authorName}{' '}
              {format(parseISO(checkedHistory.date), 'yyyy-MM-dd')}
            </Typography>
          </StyledLabel>
        </StyledWrapper>
      )}

      {part === 'internspecifications' && internalComments.length > 0 && (
        <StyledWrapper paddingTop={2}>
          <div>
            <AccountSectionLabel>
              {formatMessage({
                id: 'internalComment',
              })}
            </AccountSectionLabel>
            <Box>
              {internalComments.map((internalComment) => (
                <InternalCommentBox key={internalComment.id}>
                  <StyledDivider orientation="vertical" flexItem />
                  <Box>
                    <Typography margin="bottom">
                      {internalComment.comment}
                    </Typography>
                    <AuthorTypographyBlock>
                      {internalComment.userDisplayName}{' '}
                      {format(
                        parseISO(internalComment.createdAt),
                        'yyyy-MM-dd'
                      )}
                    </AuthorTypographyBlock>
                  </Box>
                </InternalCommentBox>
              ))}
            </Box>
          </div>
        </StyledWrapper>
      )}

      {comment && showExternalComment && (
        <StyledWrapper paddingTop={2}>
          <div>
            <AccountSectionLabel>
              {formatMessage({
                id:
                  part === 'internspecifications'
                    ? 'externalComment'
                    : 'comment',
              })}
            </AccountSectionLabel>
            <StyledSwitchWrapper>
              {part !== 'internspecifications' && (
                <StyledSwitch
                  checked={isCommentsEnabled}
                  size="small"
                  name={`comment.account${accountInformation.number}`}
                  onChange={onSwitch}
                />
              )}
              <Typography>{comment}</Typography>
            </StyledSwitchWrapper>
          </div>
        </StyledWrapper>
      )}

      {documents.length > 0 && showDocuments && (
        <>
          <DocumentWrapper paddingTop={2}>
            <div>
              <AccountSectionLabel>
                {formatMessage({ id: 'hidden.event.view' })}
              </AccountSectionLabel>
              {part !== 'internspecifications' && (
                <StyledSwitch
                  checked={isDocumentsEnabled}
                  size="small"
                  name={`documents.account${accountInformation.number}`}
                  onChange={onSwitch}
                />
              )}
            </div>
          </DocumentWrapper>
          <When isTrue={isDocumentsEnabled && !isPrint}>
            <DocumentsList
              documents={documents}
              isEdit={false}
              showHeader={false}
            />
          </When>
          <When isTrue={!isDocumentsEnabled && !isPrint}>
            <Typography color="placeholder">
              {`${documents.length} ${formatMessage({
                id: 'accounts.hiddenrecords',
              })}`}
            </Typography>
          </When>
          <When isTrue={isPrint}>
            {documents.map((document, index) => (
              <DocumentWrapper key={document.id} paddingTop={2}>
                <Document
                  name={document.name}
                  url={document.url}
                  isPrint={isPrint}
                  disableBreakAfter={index === documents.length - 1}
                />
              </DocumentWrapper>
            ))}
          </When>
        </>
      )}
    </Container>
  );
};

export default AccountSpecificationsItem;
