import React, { useContext } from 'react';
import styled from '@emotion/styled';
import {
  RowType,
  isLabelCell,
  isMultiReferenceCell,
  isNumberCell,
  isReferenceCell,
  isStringCell,
  numberValue,
  stringValue,
} from '@agoy/document';
import { IXElement } from '../../../ARKeyToIX';
import { Header as IXHeader, Hidden as IXHidden } from '../../../xml/IX';
import {
  ccyFormat,
  formatPercentage,
  getNumericValueFormat,
  getDisplayValue,
} from '@agoy/common';
import { IxbrlCell } from '@agoy/annual-report-document';
import { FormattingContext } from '@agoy/formatting';
import Typography from '../../Typography/Typography';

const HiddenValue = styled.span`
  display: none;
`;

const DisplayValue = ({ children, textStyle }) => (
  <Typography variant="body1" margin="none" as="div" textStyle={textStyle}>
    {children}
  </Typography>
);

interface CellProps {
  cell: IxbrlCell;
  rowType: RowType;
  hasPreviousYear?: boolean;
}

/**
 * Renders a human-readable value while embedding compliant iXBRL data.
 * Ensures user-friendly display and iXBRL data integrity for processing.
 */
const ReadableIXBRLCell = ({
  cell,
  displayValue,
  value,
  hasPreviousYear,
}: {
  cell: IxbrlCell;
  displayValue: string;
  value: string | number;
  hasPreviousYear?: boolean;
}) => (
  <>
    {displayValue}
    <HiddenValue>
      <IXHeader>
        <IXHidden>
          <IXElement arPart={cell} hasPreviousYear={hasPreviousYear}>
            {value}
          </IXElement>
        </IXHidden>
      </IXHeader>
    </HiddenValue>
  </>
);

const Cell = ({ cell, rowType, hasPreviousYear }: CellProps) => {
  const { formatNumber } = useContext(FormattingContext);

  // return null if there is no value field or if value is undefined
  if (
    (!('value' in cell) || cell.value === undefined) &&
    (!('values' in cell) || cell.values === undefined)
  ) {
    return null;
  }

  if (isMultiReferenceCell(cell)) {
    return (
      <DisplayValue textStyle={rowType === 'sum' ? 'bold' : 'regular'}>
        {cell.values.join(', ')}
      </DisplayValue>
    );
  }

  const textStyle = ['header', 'sum', 'alwaysDisplaySum'].includes(rowType)
    ? 'bold'
    : 'regular';

  if (
    isStringCell(cell) ||
    isLabelCell(cell) ||
    (isReferenceCell(cell) && typeof cell.value === 'string')
  ) {
    const options = 'format' in cell ? cell.format?.options : undefined;
    const selectedOption = options?.find((o) => o.value === cell.value);
    const displayValue = selectedOption?.label ?? stringValue(cell);
    const value = selectedOption?.value ?? stringValue(cell);

    return (
      <DisplayValue textStyle={textStyle}>
        {selectedOption ? (
          <ReadableIXBRLCell
            cell={cell}
            displayValue={displayValue ?? ''}
            value={value ?? ''}
            hasPreviousYear={hasPreviousYear}
          />
        ) : (
          <IXElement arPart={cell} hasPreviousYear={hasPreviousYear}>
            {value}
          </IXElement>
        )}
      </DisplayValue>
    );
  }

  if (
    isNumberCell(cell) ||
    (isReferenceCell(cell) && typeof cell.value === 'number')
  ) {
    const value = numberValue(cell);

    // We rely on ixbrl tagging data here to determine the percentage and number of people format
    const { type: formatType, precision } = getNumericValueFormat(
      cell?.ixbrl?.unitRef,
      cell?.ixbrl?.type
    );

    if (formatType === 'percentage') {
      const isNegative = value !== undefined && value < 0;
      const absoluteValue = Math.abs(value ?? 0);

      return (
        <DisplayValue textStyle={textStyle}>
          <span>
            {isNegative && '-'}
            <IXElement arPart={cell}>
              {ccyFormat(absoluteValue * 100, 2)}
            </IXElement>
            %
          </span>
        </DisplayValue>
      );
    }

    const displayValue = getDisplayValue(
      cell?.ixbrl?.type,
      value,
      formatType,
      formatNumber
    );

    return (
      <DisplayValue textStyle={textStyle}>
        <span>
          {/* The IXElement handles the sign of the cell value */}
          {value && value < 0 ? '-' : ''}
          <IXElement arPart={cell}>
            {ccyFormat(Math.abs(displayValue ?? 0), precision)}
          </IXElement>
        </span>
      </DisplayValue>
    );
  }

  return null;
};

export default Cell;
