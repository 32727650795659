/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-hb-kb-arsredovisning-2024-09-12_sv - Balansräkning.csv
 * - Comments: nan
 */

import {
  id,
  account,
  sum,
  sumAllowNull,
  or,
  multiply,
  label,
  ref,
  value,
  refs,
  ReferenceAccountInformation,
  TimePeriod,
  AccountValueType,
} from '@agoy/document';

import {
  range,
  BalanceSheet,
  table,
  RowsBuilder,
  ixbrlCell,
  IxbrlCell,
  AnnualReportVersion,
  AnnualReportType,
} from '@agoy/annual-report-document';

const accountRangeRows = (
  accounts: Record<string, ReferenceAccountInformation>,
  first: number,
  last: number,
  rows: RowsBuilder,
  switchSign: boolean,
  currentPeriod: string,
  previousPeriod: string,
  modifier?: AccountValueType | undefined
): RowsBuilder => {
  range(first, last + 1).forEach((n) => {
    const accountNumber = n.toString();
    const accountInformation = accounts[accountNumber];
    if (accountInformation) {
      rows
        .addRowWithType(
          accountNumber,
          'account',
          label(`${accountInformation.accountName}`),
          undefined,
          ref(
            switchSign
              ? multiply(
                  -1,
                  or(account(accountNumber, modifier, currentPeriod), 0)
                )
              : or(account(accountNumber, modifier, currentPeriod), 0)
          ),
          ref(
            switchSign
              ? multiply(
                  -1,
                  or(account(accountNumber, modifier, previousPeriod), 0)
                )
              : or(account(accountNumber, modifier, previousPeriod), 0)
          )
        )
        .setSortKey(n);
    }
  });
  return rows;
};

export const balanceStatementK2v2Config = (
  accounts: Record<string, ReferenceAccountInformation>,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  type: AnnualReportType,
  isDigitalSubmission: boolean | null,
  documentTypeVersion: AnnualReportVersion = '2',
  accountModifier: AccountValueType = 'ub'
): BalanceSheet => ({
  active: undefined,
  section: {
    active: undefined,
    assets: table<IxbrlCell>(
      'balanceSheet.section.assets',
      { id: 'label', dataType: 'text' },
      { id: 'notes', dataType: 'numeric' },
      { id: 'year', label: `${period.endDateISO}`, dataType: 'numeric' },
      previousPeriod
        ? {
            id: 'previousYear',
            label: `${previousPeriod.endDateISO}`,
            dataType: 'numeric',
          }
        : undefined
    )
      .addRows((rows) => {
        rows
          .addRowWithType(
            'TillgangarAbstract',
            'header',
            value('Tillgångar'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows
              .addRowWithType(
                'AnlaggningstillgangarAbstract',
                'row',
                value('Anläggningstillgångar'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'stringItemType',
                    name: 'se-gen-base:AnlaggningstillgangarAbstract',
                    contextRef: 'period0',
                    standardRubrik: 'Anläggningstillgångar',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'stringItemType',
                    name: 'se-gen-base:AnlaggningstillgangarAbstract',
                    contextRef: 'period1',
                    standardRubrik: 'Anläggningstillgångar',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ImmateriellaAnlaggningstillgangarAbstract',
                'header',
                value('Immateriella anläggningstillgångar'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheter',
                    'header',
                    value(
                      'Koncessioner, patent, licenser, varumärken samt liknande rättigheter'
                    ),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'HyresratterLiknandeRattigheter',
                        'row',
                        value('Hyresrätter och liknande rättigheter'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.HyresratterLiknandeRattigheter.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:HyresratterLiknandeRattigheter',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Hyresrätter och liknande rättigheter',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.HyresratterLiknandeRattigheter.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:HyresratterLiknandeRattigheter',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Hyresrätter och liknande rättigheter',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'Goodwill',
                        'row',
                        value('Goodwill'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.Goodwill.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Goodwill',
                            contextRef: 'balans0',
                            standardRubrik: 'Goodwill',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.Goodwill.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Goodwill',
                            contextRef: 'balans1',
                            standardRubrik: 'Goodwill',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'ForskottImmateriellaAnlaggningstillgangar',
                        'row',
                        value(
                          'Förskott avseende immateriella anläggningstillgångar'
                        ),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.ForskottImmateriellaAnlaggningstillgangar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangar',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Förskott avseende immateriella anläggningstillgångar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.ForskottImmateriellaAnlaggningstillgangar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangar',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Förskott avseende immateriella anläggningstillgångar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ImmateriellaAnlaggningstillgangar',
                    'sum',
                    value('Summa immateriella anläggningstillgångar'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:ImmateriellaAnlaggningstillgangar',
                        contextRef: 'balans0',
                        standardRubrik: 'Immateriella anläggningstillgångar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:ImmateriellaAnlaggningstillgangar',
                        contextRef: 'balans1',
                        standardRubrik: 'Immateriella anläggningstillgångar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'MateriellaAnlaggningstillgangarAbstract',
                    'header',
                    value('Materiella anläggningstillgångar'),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'ByggnaderMark',
                        'row',
                        value('Byggnader och mark'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.ByggnaderMark.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ByggnaderMark',
                            contextRef: 'balans0',
                            standardRubrik: 'Byggnader och mark',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.ByggnaderMark.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ByggnaderMark',
                            contextRef: 'balans1',
                            standardRubrik: 'Byggnader och mark',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'MaskinerAndraTekniskaAnlaggningar',
                        'row',
                        value('Maskiner och andra tekniska anläggningar'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.MaskinerAndraTekniskaAnlaggningar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningar',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Maskiner och andra tekniska anläggningar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.MaskinerAndraTekniskaAnlaggningar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningar',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Maskiner och andra tekniska anläggningar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'InventarierVerktygInstallationer',
                        'row',
                        value('Inventarier, verktyg och installationer'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.InventarierVerktygInstallationer.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:InventarierVerktygInstallationer',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Inventarier, verktyg och installationer',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.InventarierVerktygInstallationer.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:InventarierVerktygInstallationer',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Inventarier, verktyg och installationer',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'ForbattringsutgifterAnnansFastighet',
                        'row',
                        value('Förbättringsutgifter på annans fastighet'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.ForbattringsutgifterAnnansFastighet.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForbattringsutgifterAnnansFastighet',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Förbättringsutgifter på annans fastighet',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.ForbattringsutgifterAnnansFastighet.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForbattringsutgifterAnnansFastighet',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Förbättringsutgifter på annans fastighet',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'OvrigaMateriellaAnlaggningstillgangar',
                        'row',
                        value('Övriga materiella anläggningstillgångar'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.OvrigaMateriellaAnlaggningstillgangar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangar',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Övriga materiella anläggningstillgångar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.OvrigaMateriellaAnlaggningstillgangar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangar',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Övriga materiella anläggningstillgångar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar',
                        'row',
                        value(
                          'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar'
                        ),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'MateriellaAnlaggningstillgangar',
                    'sum',
                    value('Summa materiella anläggningstillgångar'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:MateriellaAnlaggningstillgangar',
                        contextRef: 'balans0',
                        standardRubrik: 'Materiella anläggningstillgångar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:MateriellaAnlaggningstillgangar',
                        contextRef: 'balans1',
                        standardRubrik: 'Materiella anläggningstillgångar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'FinansiellaAnlaggningstillgangarAbstract',
                    'header',
                    value('Finansiella anläggningstillgångar'),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'AndelarKoncernforetag',
                        'row',
                        value('Andelar i koncernföretag'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndelarKoncernforetag.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndelarKoncernforetag',
                            contextRef: 'balans0',
                            standardRubrik: 'Andelar i koncernföretag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndelarKoncernforetag.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndelarKoncernforetag',
                            contextRef: 'balans1',
                            standardRubrik: 'Andelar i koncernföretag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'FordringarKoncernforetagLangfristiga',
                        'row',
                        value('Fordringar hos koncernföretag'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarKoncernforetagLangfristiga.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarKoncernforetagLangfristiga',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Långfristiga fordringar hos koncernföretag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarKoncernforetagLangfristiga.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarKoncernforetagLangfristiga',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Långfristiga fordringar hos koncernföretag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'AndelarIntresseforetagGemensamtStyrdaForetag',
                        'row',
                        value(
                          'Andelar i intresseföretag och gemensamt styrda företag'
                        ),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndelarIntresseforetagGemensamtStyrdaForetag.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetag',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Andelar i intresseföretag och gemensamt styrda företag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndelarIntresseforetagGemensamtStyrdaForetag.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetag',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Andelar i intresseföretag och gemensamt styrda företag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga',
                        'row',
                        value(
                          'Fordringar hos intresseföretag och gemensamt styrda företag'
                        ),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'AgarintressenOvrigaForetag',
                        'row',
                        value('Ägarintressen i övriga företag'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AgarintressenOvrigaForetag.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AgarintressenOvrigaForetag',
                            contextRef: 'balans0',
                            standardRubrik: 'Ägarintressen i övriga företag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AgarintressenOvrigaForetag.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AgarintressenOvrigaForetag',
                            contextRef: 'balans1',
                            standardRubrik: 'Ägarintressen i övriga företag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'FordringarOvrigaForetagAgarintresseLangfristiga',
                        'row',
                        value(
                          'Fordringar hos övriga företag som det finns ett ägarintresse i'
                        ),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarOvrigaForetagAgarintresseLangfristiga.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarOvrigaForetagAgarintresseLangfristiga',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Långfristiga fordringar hos övriga företag som det finns ägarintresse i',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarOvrigaForetagAgarintresseLangfristiga.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarOvrigaForetagAgarintresseLangfristiga',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Långfristiga fordringar hos övriga företag som det finns ägarintresse i',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'AndraLangfristigaVardepappersinnehav',
                        'row',
                        value('Andra långfristiga värdepappersinnehav'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndraLangfristigaVardepappersinnehav.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndraLangfristigaVardepappersinnehav',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Andra långfristiga värdepappersinnehav',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndraLangfristigaVardepappersinnehav.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndraLangfristigaVardepappersinnehav',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Andra långfristiga värdepappersinnehav',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'LanDelagareNarstaende',
                        'row',
                        value('Lån till delägare eller närstående'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.LanDelagareNarstaende.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:LanDelagareNarstaende',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Långfristiga lån till delägare eller närstående',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.LanDelagareNarstaende.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:LanDelagareNarstaende',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Långfristiga lån till delägare eller närstående',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'AndraLangfristigaFordringar',
                        'row',
                        value('Andra långfristiga fordringar'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndraLangfristigaFordringar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndraLangfristigaFordringar',
                            contextRef: 'balans0',
                            standardRubrik: 'Andra långfristiga fordringar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndraLangfristigaFordringar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndraLangfristigaFordringar',
                            contextRef: 'balans1',
                            standardRubrik: 'Andra långfristiga fordringar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'FinansiellaAnlaggningstillgangar',
                    'sum',
                    value('Summa finansiella anläggningstillgångar'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:FinansiellaAnlaggningstillgangar',
                        contextRef: 'balans0',
                        standardRubrik: 'Finansiella anläggningstillgångar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:FinansiellaAnlaggningstillgangar',
                        contextRef: 'balans1',
                        standardRubrik: 'Finansiella anläggningstillgångar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'Anlaggningstillgangar',
                'sum',
                value('Summa anläggningstillgångar'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Anlaggningstillgangar',
                    contextRef: 'balans0',
                    standardRubrik: 'Anläggningstillgångar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Anlaggningstillgangar',
                    contextRef: 'balans1',
                    standardRubrik: 'Anläggningstillgångar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'OmsattningstillgangarAbstract',
                'header',
                value('Omsättningstillgångar'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'VarulagerMmAbstract',
                    'header',
                    value('Varulager m.m.'),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'LagerRavarorFornodenheter',
                        'row',
                        value('Råvaror och förnödenheter'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerRavarorFornodenheter.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:LagerRavarorFornodenheter',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Lager av råvaror och förnödenheter',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerRavarorFornodenheter.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:LagerRavarorFornodenheter',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Lager av råvaror och förnödenheter',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'LagerVarorUnderTillverkning',
                        'row',
                        value('Varor under tillverkning'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerVarorUnderTillverkning.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:LagerVarorUnderTillverkning',
                            contextRef: 'balans0',
                            standardRubrik: 'Lager av varor under tillverkning',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerVarorUnderTillverkning.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:LagerVarorUnderTillverkning',
                            contextRef: 'balans1',
                            standardRubrik: 'Lager av varor under tillverkning',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'LagerFardigaVarorHandelsvaror',
                        'row',
                        value('Färdiga varor och handelsvaror'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerFardigaVarorHandelsvaror.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:LagerFardigaVarorHandelsvaror',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Lager av färdiga egentillverkade varor samt varor som har köpts för vidareförsäljning',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerFardigaVarorHandelsvaror.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:LagerFardigaVarorHandelsvaror',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Lager av färdiga egentillverkade varor samt varor som har köpts för vidareförsäljning',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'OvrigaLagertillgangar',
                        'row',
                        value('Övriga lagertillgångar'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.OvrigaLagertillgangar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:OvrigaLagertillgangar',
                            contextRef: 'balans0',
                            standardRubrik: 'Övriga lagertillgångar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.OvrigaLagertillgangar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:OvrigaLagertillgangar',
                            contextRef: 'balans1',
                            standardRubrik: 'Övriga lagertillgångar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'PagaendeArbetenAnnansRakningOmsattningstillgangar',
                        'row',
                        value('Pågående arbete för annans räkning'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.PagaendeArbetenAnnansRakningOmsattningstillgangar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangar',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Pågående arbete för annans räkning, tillgång',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.PagaendeArbetenAnnansRakningOmsattningstillgangar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangar',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Pågående arbete för annans räkning, tillgång',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'ForskottTillLeverantorer',
                        'row',
                        value('Förskott till leverantörer'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.ForskottTillLeverantorer.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForskottTillLeverantorer',
                            contextRef: 'balans0',
                            standardRubrik: 'Förskott till leverantörer',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.ForskottTillLeverantorer.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForskottTillLeverantorer',
                            contextRef: 'balans1',
                            standardRubrik: 'Förskott till leverantörer',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'VarulagerMm',
                    'sum',
                    value('Summa varulager m.m.'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:VarulagerMm',
                        contextRef: 'balans0',
                        standardRubrik: 'Varulager m.m.',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:VarulagerMm',
                        contextRef: 'balans1',
                        standardRubrik: 'Varulager m.m.',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'KortfristigaFordringarAbstract',
                    'header',
                    value('Kortfristiga fordringar'),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'Kundfordringar',
                        'row',
                        value('Kundfordringar'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.Kundfordringar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Kundfordringar',
                            contextRef: 'balans0',
                            standardRubrik: 'Kundfordringar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.Kundfordringar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Kundfordringar',
                            contextRef: 'balans1',
                            standardRubrik: 'Kundfordringar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'FordringarKoncernforetagKortfristiga',
                        'row',
                        value('Fordringar hos koncernföretag'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarKoncernforetagKortfristiga.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarKoncernforetagKortfristiga',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Kortfristiga fordringar hos koncernföretag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarKoncernforetagKortfristiga.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarKoncernforetagKortfristiga',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Kortfristiga fordringar hos koncernföretag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga',
                        'row',
                        value(
                          'Fordringar hos intresseföretag och gemensamt styrda företag'
                        ),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Kortfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Kortfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'FordringarOvrigaforetagAgarintresseKortfristiga',
                        'row',
                        value(
                          'Fordringar hos övriga företag som det finns ett ägarintresse i'
                        ),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarOvrigaforetagAgarintresseKortfristiga.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarOvrigaforetagAgarintresseKortfristiga',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Kortfristiga fordringar hos övriga företag som det finns ett ägarintresse i',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarOvrigaforetagAgarintresseKortfristiga.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarOvrigaforetagAgarintresseKortfristiga',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Kortfristiga fordringar hos övriga företag som det finns ett ägarintresse i',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'OvrigaFordringarKortfristiga',
                        'row',
                        value('Övriga fordringar'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.OvrigaFordringarKortfristiga.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:OvrigaFordringarKortfristiga',
                            contextRef: 'balans0',
                            standardRubrik: 'Övriga kortfristiga fordringar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.OvrigaFordringarKortfristiga.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:OvrigaFordringarKortfristiga',
                            contextRef: 'balans1',
                            standardRubrik: 'Övriga kortfristiga fordringar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'UpparbetadEjFaktureradIntakt',
                        'row',
                        value('Upparbetad men ej fakturerad intäkt'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.UpparbetadEjFaktureradIntakt.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:UpparbetadEjFaktureradIntakt',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Upparbetad men ej fakturerad intäkt',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.UpparbetadEjFaktureradIntakt.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:UpparbetadEjFaktureradIntakt',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Upparbetad men ej fakturerad intäkt',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'ForutbetaldaKostnaderUpplupnaIntakter',
                        'row',
                        value('Förutbetalda kostnader och upplupna intäkter'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.ForutbetaldaKostnaderUpplupnaIntakter.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakter',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Förutbetalda kostnader och upplupna intäkter',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.ForutbetaldaKostnaderUpplupnaIntakter.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakter',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Förutbetalda kostnader och upplupna intäkter',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'KortfristigaFordringar',
                    'sum',
                    value('Summa kortfristiga fordringar'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:KortfristigaFordringar',
                        contextRef: 'balans0',
                        standardRubrik: 'Kortfristiga fordringar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:KortfristigaFordringar',
                        contextRef: 'balans1',
                        standardRubrik: 'Kortfristiga fordringar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'KortfristigaPlaceringarAbstract',
                    'header',
                    value('Kortfristiga placeringar'),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'AndelarKoncernforetagKortfristiga',
                        'row',
                        value('Andelar i koncernföretag'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.AndelarKoncernforetagKortfristiga.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndelarKoncernforetagKortfristiga',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Kortfristiga andelar i koncernföretag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.AndelarKoncernforetagKortfristiga.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndelarKoncernforetagKortfristiga',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Kortfristiga andelar i koncernföretag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'OvrigaKortfristigaPlaceringar',
                        'row',
                        value('Övriga kortfristiga placeringar'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.OvrigaKortfristigaPlaceringar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:OvrigaKortfristigaPlaceringar',
                            contextRef: 'balans0',
                            standardRubrik: 'Övriga kortfristiga placeringar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.OvrigaKortfristigaPlaceringar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:OvrigaKortfristigaPlaceringar',
                            contextRef: 'balans1',
                            standardRubrik: 'Övriga kortfristiga placeringar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'KortfristigaPlaceringar',
                    'sum',
                    value('Summa kortfristiga placeringar'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:KortfristigaPlaceringar',
                        contextRef: 'balans0',
                        standardRubrik: 'Kortfristiga placeringar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:KortfristigaPlaceringar',
                        contextRef: 'balans1',
                        standardRubrik: 'Kortfristiga placeringar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'KassaBankAbstract',
                    'header',
                    value('Kassa och bank'),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'KassaBankExklRedovisningsmedel',
                        'row',
                        value('Kassa och bank'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.KassaBankExklRedovisningsmedel.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:KassaBankExklRedovisningsmedel',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Kassa och bank exklusive redovisningsmedel',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.KassaBankExklRedovisningsmedel.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:KassaBankExklRedovisningsmedel',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Kassa och bank exklusive redovisningsmedel',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'Redovisningsmedel',
                        'row',
                        value('Redovisningsmedel'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.Redovisningsmedel.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Redovisningsmedel',
                            contextRef: 'balans0',
                            standardRubrik: 'Redovisningsmedel',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.Redovisningsmedel.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Redovisningsmedel',
                            contextRef: 'balans1',
                            standardRubrik: 'Redovisningsmedel',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'KassaBank',
                    'sum',
                    value('Summa kassa och bank'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:KassaBank',
                        contextRef: 'balans0',
                        standardRubrik: 'Kassa och bank',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:KassaBank',
                        contextRef: 'balans1',
                        standardRubrik: 'Kassa och bank',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'Omsattningstillgangar',
                'sum',
                value('Summa omsättningstillgångar'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Omsattningstillgangar',
                    contextRef: 'balans0',
                    standardRubrik: 'Omsättningstillgångar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Omsattningstillgangar',
                    contextRef: 'balans1',
                    standardRubrik: 'Omsättningstillgångar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'Tillgangar',
            'sum',
            value('Summa tillgångar'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id('balanceSheet.section.assets.TillgangarAbstract.*.year')
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:Tillgangar',
                contextRef: 'balans0',
                standardRubrik: 'Tillgångar',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'balanceSheet.section.assets.TillgangarAbstract.*.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:Tillgangar',
                contextRef: 'balans1',
                standardRubrik: 'Tillgångar',
                saldo: 'debit',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        return rows.build();
      })
      .build(),
    equityAndLiabilities: table<IxbrlCell>(
      'balanceSheet.section.equityAndLiabilities',
      { id: 'label', dataType: 'text' },
      { id: 'notes', dataType: 'numeric' },
      { id: 'year', label: `${period.endDateISO}`, dataType: 'numeric' },
      previousPeriod
        ? {
            id: 'previousYear',
            label: `${previousPeriod.endDateISO}`,
            dataType: 'numeric',
          }
        : undefined
    )
      .addRows((rows) => {
        rows
          .addRowWithType(
            'EgetKapitalSkulderAbstract',
            'header',
            value('Eget kapital och skulder'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows
              .addRowWithType(
                'EgetKapitalAbstract',
                'header',
                value('Eget kapital'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'EgetKapital',
                    'row',
                    value('Eget kapital vid räkenskapsårets början'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.EgetKapital.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:EgetKapital',
                        contextRef: 'balans0',
                        standardRubrik: 'Eget kapital',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.EgetKapital.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:EgetKapital',
                        contextRef: 'balans1',
                        standardRubrik: 'Eget kapital',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForandringEgetKapitalAbstract',
                    'header',
                    value('Förändringar i eget kapital'),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'ForandringEgetKapitalInsattningarUttag',
                        'row',
                        value('Insättningar eller uttag under året'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.ForandringEgetKapitalAbstract.ForandringEgetKapitalInsattningarUttag.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForandringEgetKapitalInsattningarUttag',
                            contextRef: 'period0',
                            standardRubrik:
                              'Ökning (minskning) av eget kapital vid insättningar eller uttag',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.ForandringEgetKapitalAbstract.ForandringEgetKapitalInsattningarUttag.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForandringEgetKapitalInsattningarUttag',
                            contextRef: 'period1',
                            standardRubrik:
                              'Ökning (minskning) av eget kapital vid insättningar eller uttag',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'AretsResultatEgetKapital',
                    'sum',
                    value('Årets resultat'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.ForandringEgetKapitalAbstract.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:AretsResultatEgetKapital',
                        contextRef: 'balans0',
                        standardRubrik: 'Periodens resultat i eget kapital',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.ForandringEgetKapitalAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:AretsResultatEgetKapital',
                        contextRef: 'balans1',
                        standardRubrik: 'Periodens resultat i eget kapital',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'EgetKapital',
                'sum',
                value('Eget kapital vid räkenskapsårets slut'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:EgetKapital',
                    contextRef: 'balans0',
                    standardRubrik: 'Eget kapital',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:EgetKapital',
                    contextRef: 'balans1',
                    standardRubrik: 'Eget kapital',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ObeskattadeReserverAbstract',
                'header',
                value('Obeskattade reserver'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'AckumuleradeOveravskrivningar',
                    'row',
                    value('Ackumulerade överavskrivningar'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.AckumuleradeOveravskrivningar.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AckumuleradeOveravskrivningar',
                        contextRef: 'balans0',
                        standardRubrik: 'Ackumulerade överavskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.AckumuleradeOveravskrivningar.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AckumuleradeOveravskrivningar',
                        contextRef: 'balans1',
                        standardRubrik: 'Ackumulerade överavskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaObeskattadeReserver',
                    'row',
                    value('Övriga obeskattade reserver'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.OvrigaObeskattadeReserver.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaObeskattadeReserver',
                        contextRef: 'balans0',
                        standardRubrik: 'Övriga obeskattade reserver',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.OvrigaObeskattadeReserver.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaObeskattadeReserver',
                        contextRef: 'balans1',
                        standardRubrik: 'Övriga obeskattade reserver',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'ObeskattadeReserver',
                'sum',
                value('Summa obeskattade reserver'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:ObeskattadeReserver',
                    contextRef: 'balans0',
                    standardRubrik: 'Obeskattade reserver',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:ObeskattadeReserver',
                    contextRef: 'balans1',
                    standardRubrik: 'Obeskattade reserver',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'AvsattningarAbstract',
                'header',
                value('Avsättningar'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'AvsattningarPensionerLiknandeForpliktelserEnligtLag',
                    'row',
                    value(
                      'Avsättningar för pensioner och liknande förpliktelser enligt lagen om tryggande av pensionsutfästelse m.m.'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.AvsattningarPensionerLiknandeForpliktelserEnligtLag.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserEnligtLag',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Avsättningar för pensioner och liknande förpliktelser enligt lagen om tryggande av pensionsutfästelse m.m.',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.AvsattningarPensionerLiknandeForpliktelserEnligtLag.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserEnligtLag',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Avsättningar för pensioner och liknande förpliktelser enligt lagen om tryggande av pensionsutfästelse m.m.',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaAvsattningarPensionerLiknandeForpliktelser',
                    'row',
                    value(
                      'Övriga avsättningar för pensioner och liknande förpliktelser exklusive avsättningar enligt tryggandelagen'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.OvrigaAvsattningarPensionerLiknandeForpliktelser.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaAvsattningarPensionerLiknandeForpliktelser',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Övriga avsättningar för pensioner och liknande förpliktelser exklusive avsättningar enligt tryggandelagen',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.OvrigaAvsattningarPensionerLiknandeForpliktelser.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaAvsattningarPensionerLiknandeForpliktelser',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Övriga avsättningar för pensioner och liknande förpliktelser exklusive avsättningar enligt tryggandelagen',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaAvsattningar',
                    'row',
                    value('Övriga avsättningar'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.OvrigaAvsattningar.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaAvsattningar',
                        contextRef: 'balans0',
                        standardRubrik: 'Övriga avsättningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.OvrigaAvsattningar.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaAvsattningar',
                        contextRef: 'balans1',
                        standardRubrik: 'Övriga avsättningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'Avsattningar',
                'sum',
                value('Summa avsättningar'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Avsattningar',
                    contextRef: 'balans0',
                    standardRubrik: 'Avsättningar',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Avsattningar',
                    contextRef: 'balans1',
                    standardRubrik: 'Avsättningar',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'LangfristigaSkulderAbstract',
                'header',
                value('Långfristiga skulder'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'Obligationslan',
                    'row',
                    value('Obligationslån'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.Obligationslan.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Obligationslan',
                        contextRef: 'balans0',
                        standardRubrik: 'Obligationslån',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.Obligationslan.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Obligationslan',
                        contextRef: 'balans1',
                        standardRubrik: 'Obligationslån',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'CheckrakningskreditLangfristig',
                    'row',
                    value('Checkräkningskredit'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.CheckrakningskreditLangfristig.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:CheckrakningskreditLangfristig',
                        contextRef: 'balans0',
                        standardRubrik: 'Långfristig checkräkningskredit',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.CheckrakningskreditLangfristig.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:CheckrakningskreditLangfristig',
                        contextRef: 'balans1',
                        standardRubrik: 'Långfristig checkräkningskredit',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaLangfristigaSkulderKreditinstitut',
                    'row',
                    value('Övriga skulder till kreditinstitut'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.OvrigaLangfristigaSkulderKreditinstitut.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaLangfristigaSkulderKreditinstitut',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Övriga långfristiga skulder till kreditinstitut',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.OvrigaLangfristigaSkulderKreditinstitut.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaLangfristigaSkulderKreditinstitut',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Övriga långfristiga skulder till kreditinstitut',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'SkulderKoncernforetagLangfristiga',
                    'row',
                    value('Skulder till koncernföretag'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderKoncernforetagLangfristiga.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderKoncernforetagLangfristiga',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Långfristiga skulder till koncernföretag',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderKoncernforetagLangfristiga.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderKoncernforetagLangfristiga',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Långfristiga skulder till koncernföretag',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga',
                    'row',
                    value(
                      'Skulder till intresseföretag och gemensamt styrda företag'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Långfristiga skulder till intresseföretag och gemensamt styrda företag',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Långfristiga skulder till intresseföretag och gemensamt styrda företag',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'SkulderOvrigaForetagAgarintresseLangfristiga',
                    'row',
                    value(
                      'Skulder till övriga företag som det finns ett ägarintresse i'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderOvrigaForetagAgarintresseLangfristiga.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderOvrigaForetagAgarintresseLangfristiga',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Långfristiga skulder till övriga företag som det finns ett ägarintresse i',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderOvrigaForetagAgarintresseLangfristiga.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderOvrigaForetagAgarintresseLangfristiga',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Långfristiga skulder till övriga företag som det finns ett ägarintresse i',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaLangfristigaSkulder',
                    'row',
                    value('Övriga skulder'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.OvrigaLangfristigaSkulder.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaLangfristigaSkulder',
                        contextRef: 'balans0',
                        standardRubrik: 'Övriga långfristiga skulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.OvrigaLangfristigaSkulder.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaLangfristigaSkulder',
                        contextRef: 'balans1',
                        standardRubrik: 'Övriga långfristiga skulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'LangfristigaSkulder',
                'sum',
                value('Summa långfristiga skulder'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:LangfristigaSkulder',
                    contextRef: 'balans0',
                    standardRubrik: 'Långfristiga skulder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:LangfristigaSkulder',
                    contextRef: 'balans1',
                    standardRubrik: 'Långfristiga skulder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'KortfristigaSkulderAbstract',
                'header',
                value('Kortfristiga skulder'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'CheckrakningskreditKortfristig',
                    'row',
                    value('Checkräkningskredit'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.CheckrakningskreditKortfristig.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:CheckrakningskreditKortfristig',
                        contextRef: 'balans0',
                        standardRubrik: 'Kortfristig checkräkningskredit',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.CheckrakningskreditKortfristig.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:CheckrakningskreditKortfristig',
                        contextRef: 'balans1',
                        standardRubrik: 'Kortfristig checkräkningskredit',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaKortfristigaSkulderKreditinstitut',
                    'row',
                    value('Övriga skulder till kreditinstitut'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.OvrigaKortfristigaSkulderKreditinstitut.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaKortfristigaSkulderKreditinstitut',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Övriga kortfristiga skulder till kreditinstitut',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.OvrigaKortfristigaSkulderKreditinstitut.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaKortfristigaSkulderKreditinstitut',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Övriga kortfristiga skulder till kreditinstitut',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForskottFranKunder',
                    'row',
                    value('Förskott från kunder'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.ForskottFranKunder.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForskottFranKunder',
                        contextRef: 'balans0',
                        standardRubrik: 'Förskott från kunder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.ForskottFranKunder.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForskottFranKunder',
                        contextRef: 'balans1',
                        standardRubrik: 'Förskott från kunder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'PagaendeArbetenAnnansRakningKortfristigaSkulder',
                    'row',
                    value('Pågående arbete för annans räkning'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.PagaendeArbetenAnnansRakningKortfristigaSkulder.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulder',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Pågående arbete för annans räkning, skuld',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.PagaendeArbetenAnnansRakningKortfristigaSkulder.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulder',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Pågående arbete för annans räkning, skuld',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'FaktureradEjUpparbetadIntakt',
                    'row',
                    value('Fakturerad men ej upparbetad intäkt'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.FaktureradEjUpparbetadIntakt.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:FaktureradEjUpparbetadIntakt',
                        contextRef: 'balans0',
                        standardRubrik: 'Fakturerad men ej upparbetad intäkt',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.FaktureradEjUpparbetadIntakt.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:FaktureradEjUpparbetadIntakt',
                        contextRef: 'balans1',
                        standardRubrik: 'Fakturerad men ej upparbetad intäkt',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'Leverantorsskulder',
                    'row',
                    value('Leverantörsskulder'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Leverantorsskulder.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Leverantorsskulder',
                        contextRef: 'balans0',
                        standardRubrik: 'Leverantörsskulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Leverantorsskulder.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Leverantorsskulder',
                        contextRef: 'balans1',
                        standardRubrik: 'Leverantörsskulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'Vaxelskulder',
                    'row',
                    value('Växelskulder'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Vaxelskulder.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Vaxelskulder',
                        contextRef: 'balans0',
                        standardRubrik: 'Växelskulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Vaxelskulder.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Vaxelskulder',
                        contextRef: 'balans1',
                        standardRubrik: 'Växelskulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'SkulderKoncernforetagKortfristiga',
                    'row',
                    value('Skulder till koncernföretag'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderKoncernforetagKortfristiga.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderKoncernforetagKortfristiga',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Kortfristiga skulder till koncernföretag',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderKoncernforetagKortfristiga.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderKoncernforetagKortfristiga',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Kortfristiga skulder till koncernföretag',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga',
                    'row',
                    value(
                      'Skulder till intresseföretag och gemensamt styrda företag'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Kortfristiga skulder till intresseföretag och gemensamt styrda företag',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Kortfristiga skulder till intresseföretag och gemensamt styrda företag',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'SkulderOvrigaForetagAgarintresseKortfristiga',
                    'row',
                    value(
                      'Skulder till övriga företag som det finns ett ägarintresse i'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderOvrigaForetagAgarintresseKortfristiga.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderOvrigaForetagAgarintresseKortfristiga',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Kortfristiga skulder till övriga företag som det finns ett ägarintresse i',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderOvrigaForetagAgarintresseKortfristiga.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderOvrigaForetagAgarintresseKortfristiga',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Kortfristiga skulder till övriga företag som det finns ett ägarintresse i',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'Skatteskulder',
                    'row',
                    value('Skatteskulder'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Skatteskulder.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Skatteskulder',
                        contextRef: 'balans0',
                        standardRubrik: 'Skatteskulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Skatteskulder.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Skatteskulder',
                        contextRef: 'balans1',
                        standardRubrik: 'Skatteskulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaKortfristigaSkulder',
                    'row',
                    value('Övriga skulder'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.OvrigaKortfristigaSkulder.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaKortfristigaSkulder',
                        contextRef: 'balans0',
                        standardRubrik: 'Övriga kortfristiga skulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.OvrigaKortfristigaSkulder.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaKortfristigaSkulder',
                        contextRef: 'balans1',
                        standardRubrik: 'Övriga kortfristiga skulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'UpplupnaKostnaderForutbetaldaIntakter',
                    'row',
                    value('Upplupna kostnader och förutbetalda intäkter'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.UpplupnaKostnaderForutbetaldaIntakter.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakter',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Upplupna kostnader och förutbetalda intäkter',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.UpplupnaKostnaderForutbetaldaIntakter.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakter',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Upplupna kostnader och förutbetalda intäkter',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'KortfristigaSkulder',
                'sum',
                value('Summa kortfristiga skulder'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:KortfristigaSkulder',
                    contextRef: 'balans0',
                    standardRubrik: 'Kortfristiga skulder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:KortfristigaSkulder',
                    contextRef: 'balans1',
                    standardRubrik: 'Kortfristiga skulder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'EgetKapitalSkulder',
            'sum',
            value('Summa eget kapital och skulder'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.*.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:EgetKapitalSkulder',
                contextRef: 'balans0',
                standardRubrik: 'Eget kapital och skulder',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.*.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:EgetKapitalSkulder',
                contextRef: 'balans1',
                standardRubrik: 'Eget kapital och skulder',
                saldo: 'credit',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        return rows.build();
      })
      .build(),
  },
});
export const balanceStatementK2v2References = (): Record<string, string> => ({
  'balanceSheet.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.year':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.year'
    ),
  'balanceSheet.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.previousYear':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.previousYear'
    ),
  'balanceSheet.HyresratterLiknandeRattigheter.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.HyresratterLiknandeRattigheter.year'
  ),
  'balanceSheet.HyresratterLiknandeRattigheter.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.HyresratterLiknandeRattigheter.previousYear'
  ),
  'balanceSheet.Goodwill.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.Goodwill.year'
  ),
  'balanceSheet.Goodwill.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.Goodwill.previousYear'
  ),
  'balanceSheet.ForskottImmateriellaAnlaggningstillgangar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.ForskottImmateriellaAnlaggningstillgangar.year'
  ),
  'balanceSheet.ForskottImmateriellaAnlaggningstillgangar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.ForskottImmateriellaAnlaggningstillgangar.previousYear'
  ),
  'balanceSheet.ImmateriellaAnlaggningstillgangar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangar.year'
  ),
  'balanceSheet.ImmateriellaAnlaggningstillgangar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangar.previousYear'
  ),
  'balanceSheet.ByggnaderMark.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.ByggnaderMark.year'
  ),
  'balanceSheet.ByggnaderMark.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.ByggnaderMark.previousYear'
  ),
  'balanceSheet.MaskinerAndraTekniskaAnlaggningar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.MaskinerAndraTekniskaAnlaggningar.year'
  ),
  'balanceSheet.MaskinerAndraTekniskaAnlaggningar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.MaskinerAndraTekniskaAnlaggningar.previousYear'
  ),
  'balanceSheet.InventarierVerktygInstallationer.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.InventarierVerktygInstallationer.year'
  ),
  'balanceSheet.InventarierVerktygInstallationer.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.InventarierVerktygInstallationer.previousYear'
  ),
  'balanceSheet.ForbattringsutgifterAnnansFastighet.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.ForbattringsutgifterAnnansFastighet.year'
  ),
  'balanceSheet.ForbattringsutgifterAnnansFastighet.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.ForbattringsutgifterAnnansFastighet.previousYear'
  ),
  'balanceSheet.OvrigaMateriellaAnlaggningstillgangar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.OvrigaMateriellaAnlaggningstillgangar.year'
  ),
  'balanceSheet.OvrigaMateriellaAnlaggningstillgangar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.OvrigaMateriellaAnlaggningstillgangar.previousYear'
  ),
  'balanceSheet.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar.year':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar.year'
    ),
  'balanceSheet.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar.previousYear':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar.previousYear'
    ),
  'balanceSheet.MateriellaAnlaggningstillgangar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangar.year'
  ),
  'balanceSheet.MateriellaAnlaggningstillgangar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangar.previousYear'
  ),
  'balanceSheet.AndelarKoncernforetag.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndelarKoncernforetag.year'
  ),
  'balanceSheet.AndelarKoncernforetag.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndelarKoncernforetag.previousYear'
  ),
  'balanceSheet.FordringarKoncernforetagLangfristiga.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarKoncernforetagLangfristiga.year'
  ),
  'balanceSheet.FordringarKoncernforetagLangfristiga.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarKoncernforetagLangfristiga.previousYear'
  ),
  'balanceSheet.AndelarIntresseforetagGemensamtStyrdaForetag.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndelarIntresseforetagGemensamtStyrdaForetag.year'
  ),
  'balanceSheet.AndelarIntresseforetagGemensamtStyrdaForetag.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndelarIntresseforetagGemensamtStyrdaForetag.previousYear'
  ),
  'balanceSheet.FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga.year':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga.year'
    ),
  'balanceSheet.FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga.previousYear':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga.previousYear'
    ),
  'balanceSheet.AgarintressenOvrigaForetag.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AgarintressenOvrigaForetag.year'
  ),
  'balanceSheet.AgarintressenOvrigaForetag.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AgarintressenOvrigaForetag.previousYear'
  ),
  'balanceSheet.FordringarOvrigaForetagAgarintresseLangfristiga.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarOvrigaForetagAgarintresseLangfristiga.year'
  ),
  'balanceSheet.FordringarOvrigaForetagAgarintresseLangfristiga.previousYear':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarOvrigaForetagAgarintresseLangfristiga.previousYear'
    ),
  'balanceSheet.AndraLangfristigaVardepappersinnehav.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndraLangfristigaVardepappersinnehav.year'
  ),
  'balanceSheet.AndraLangfristigaVardepappersinnehav.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndraLangfristigaVardepappersinnehav.previousYear'
  ),
  'balanceSheet.LanDelagareNarstaende.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.LanDelagareNarstaende.year'
  ),
  'balanceSheet.LanDelagareNarstaende.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.LanDelagareNarstaende.previousYear'
  ),
  'balanceSheet.AndraLangfristigaFordringar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndraLangfristigaFordringar.year'
  ),
  'balanceSheet.AndraLangfristigaFordringar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndraLangfristigaFordringar.previousYear'
  ),
  'balanceSheet.FinansiellaAnlaggningstillgangar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangar.year'
  ),
  'balanceSheet.FinansiellaAnlaggningstillgangar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangar.previousYear'
  ),
  'balanceSheet.Anlaggningstillgangar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.Anlaggningstillgangar.year'
  ),
  'balanceSheet.Anlaggningstillgangar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.Anlaggningstillgangar.previousYear'
  ),
  'balanceSheet.LagerRavarorFornodenheter.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerRavarorFornodenheter.year'
  ),
  'balanceSheet.LagerRavarorFornodenheter.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerRavarorFornodenheter.previousYear'
  ),
  'balanceSheet.LagerVarorUnderTillverkning.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerVarorUnderTillverkning.year'
  ),
  'balanceSheet.LagerVarorUnderTillverkning.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerVarorUnderTillverkning.previousYear'
  ),
  'balanceSheet.LagerFardigaVarorHandelsvaror.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerFardigaVarorHandelsvaror.year'
  ),
  'balanceSheet.LagerFardigaVarorHandelsvaror.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerFardigaVarorHandelsvaror.previousYear'
  ),
  'balanceSheet.OvrigaLagertillgangar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.OvrigaLagertillgangar.year'
  ),
  'balanceSheet.OvrigaLagertillgangar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.OvrigaLagertillgangar.previousYear'
  ),
  'balanceSheet.PagaendeArbetenAnnansRakningOmsattningstillgangar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.PagaendeArbetenAnnansRakningOmsattningstillgangar.year'
  ),
  'balanceSheet.PagaendeArbetenAnnansRakningOmsattningstillgangar.previousYear':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.PagaendeArbetenAnnansRakningOmsattningstillgangar.previousYear'
    ),
  'balanceSheet.ForskottTillLeverantorer.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.ForskottTillLeverantorer.year'
  ),
  'balanceSheet.ForskottTillLeverantorer.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.ForskottTillLeverantorer.previousYear'
  ),
  'balanceSheet.VarulagerMm.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMm.year'
  ),
  'balanceSheet.VarulagerMm.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMm.previousYear'
  ),
  'balanceSheet.Kundfordringar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.Kundfordringar.year'
  ),
  'balanceSheet.Kundfordringar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.Kundfordringar.previousYear'
  ),
  'balanceSheet.FordringarKoncernforetagKortfristiga.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarKoncernforetagKortfristiga.year'
  ),
  'balanceSheet.FordringarKoncernforetagKortfristiga.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarKoncernforetagKortfristiga.previousYear'
  ),
  'balanceSheet.FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga.year':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga.year'
    ),
  'balanceSheet.FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga.previousYear':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga.previousYear'
    ),
  'balanceSheet.FordringarOvrigaforetagAgarintresseKortfristiga.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarOvrigaforetagAgarintresseKortfristiga.year'
  ),
  'balanceSheet.FordringarOvrigaforetagAgarintresseKortfristiga.previousYear':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarOvrigaforetagAgarintresseKortfristiga.previousYear'
    ),
  'balanceSheet.OvrigaFordringarKortfristiga.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.OvrigaFordringarKortfristiga.year'
  ),
  'balanceSheet.OvrigaFordringarKortfristiga.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.OvrigaFordringarKortfristiga.previousYear'
  ),
  'balanceSheet.UpparbetadEjFaktureradIntakt.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.UpparbetadEjFaktureradIntakt.year'
  ),
  'balanceSheet.UpparbetadEjFaktureradIntakt.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.UpparbetadEjFaktureradIntakt.previousYear'
  ),
  'balanceSheet.ForutbetaldaKostnaderUpplupnaIntakter.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.ForutbetaldaKostnaderUpplupnaIntakter.year'
  ),
  'balanceSheet.ForutbetaldaKostnaderUpplupnaIntakter.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.ForutbetaldaKostnaderUpplupnaIntakter.previousYear'
  ),
  'balanceSheet.KortfristigaFordringar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringar.year'
  ),
  'balanceSheet.KortfristigaFordringar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringar.previousYear'
  ),
  'balanceSheet.AndelarKoncernforetagKortfristiga.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.AndelarKoncernforetagKortfristiga.year'
  ),
  'balanceSheet.AndelarKoncernforetagKortfristiga.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.AndelarKoncernforetagKortfristiga.previousYear'
  ),
  'balanceSheet.OvrigaKortfristigaPlaceringar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.OvrigaKortfristigaPlaceringar.year'
  ),
  'balanceSheet.OvrigaKortfristigaPlaceringar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.OvrigaKortfristigaPlaceringar.previousYear'
  ),
  'balanceSheet.KortfristigaPlaceringar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringar.year'
  ),
  'balanceSheet.KortfristigaPlaceringar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringar.previousYear'
  ),
  'balanceSheet.KassaBankExklRedovisningsmedel.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.KassaBankExklRedovisningsmedel.year'
  ),
  'balanceSheet.KassaBankExklRedovisningsmedel.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.KassaBankExklRedovisningsmedel.previousYear'
  ),
  'balanceSheet.Redovisningsmedel.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.Redovisningsmedel.year'
  ),
  'balanceSheet.Redovisningsmedel.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.Redovisningsmedel.previousYear'
  ),
  'balanceSheet.KassaBank.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBank.year'
  ),
  'balanceSheet.KassaBank.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBank.previousYear'
  ),
  'balanceSheet.Omsattningstillgangar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.Omsattningstillgangar.year'
  ),
  'balanceSheet.Omsattningstillgangar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.Omsattningstillgangar.previousYear'
  ),
  'balanceSheet.Tillgangar.year': id(
    'balanceSheet.section.assets.Tillgangar.year'
  ),
  'balanceSheet.Tillgangar.previousYear': id(
    'balanceSheet.section.assets.Tillgangar.previousYear'
  ),
  'balanceSheet.EgetKapital.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.EgetKapital.year'
  ),
  'balanceSheet.EgetKapital.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.EgetKapital.previousYear'
  ),
  'balanceSheet.ForandringEgetKapitalInsattningarUttag.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.ForandringEgetKapitalAbstract.ForandringEgetKapitalInsattningarUttag.year'
  ),
  'balanceSheet.ForandringEgetKapitalInsattningarUttag.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.ForandringEgetKapitalAbstract.ForandringEgetKapitalInsattningarUttag.previousYear'
  ),
  'balanceSheet.AretsResultatEgetKapital.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.AretsResultatEgetKapital.year'
  ),
  'balanceSheet.AretsResultatEgetKapital.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.AretsResultatEgetKapital.previousYear'
  ),
  'balanceSheet.AckumuleradeOveravskrivningar.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.AckumuleradeOveravskrivningar.year'
  ),
  'balanceSheet.AckumuleradeOveravskrivningar.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.AckumuleradeOveravskrivningar.previousYear'
  ),
  'balanceSheet.OvrigaObeskattadeReserver.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.OvrigaObeskattadeReserver.year'
  ),
  'balanceSheet.OvrigaObeskattadeReserver.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.OvrigaObeskattadeReserver.previousYear'
  ),
  'balanceSheet.ObeskattadeReserver.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserver.year'
  ),
  'balanceSheet.ObeskattadeReserver.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserver.previousYear'
  ),
  'balanceSheet.AvsattningarPensionerLiknandeForpliktelserEnligtLag.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.AvsattningarPensionerLiknandeForpliktelserEnligtLag.year'
  ),
  'balanceSheet.AvsattningarPensionerLiknandeForpliktelserEnligtLag.previousYear':
    id(
      'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.AvsattningarPensionerLiknandeForpliktelserEnligtLag.previousYear'
    ),
  'balanceSheet.OvrigaAvsattningarPensionerLiknandeForpliktelser.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.OvrigaAvsattningarPensionerLiknandeForpliktelser.year'
  ),
  'balanceSheet.OvrigaAvsattningarPensionerLiknandeForpliktelser.previousYear':
    id(
      'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.OvrigaAvsattningarPensionerLiknandeForpliktelser.previousYear'
    ),
  'balanceSheet.OvrigaAvsattningar.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.OvrigaAvsattningar.year'
  ),
  'balanceSheet.OvrigaAvsattningar.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.OvrigaAvsattningar.previousYear'
  ),
  'balanceSheet.Avsattningar.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.Avsattningar.year'
  ),
  'balanceSheet.Avsattningar.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.Avsattningar.previousYear'
  ),
  'balanceSheet.Obligationslan.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.Obligationslan.year'
  ),
  'balanceSheet.Obligationslan.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.Obligationslan.previousYear'
  ),
  'balanceSheet.CheckrakningskreditLangfristig.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.CheckrakningskreditLangfristig.year'
  ),
  'balanceSheet.CheckrakningskreditLangfristig.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.CheckrakningskreditLangfristig.previousYear'
  ),
  'balanceSheet.OvrigaLangfristigaSkulderKreditinstitut.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.OvrigaLangfristigaSkulderKreditinstitut.year'
  ),
  'balanceSheet.OvrigaLangfristigaSkulderKreditinstitut.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.OvrigaLangfristigaSkulderKreditinstitut.previousYear'
  ),
  'balanceSheet.SkulderKoncernforetagLangfristiga.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderKoncernforetagLangfristiga.year'
  ),
  'balanceSheet.SkulderKoncernforetagLangfristiga.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderKoncernforetagLangfristiga.previousYear'
  ),
  'balanceSheet.SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga.year':
    id(
      'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga.year'
    ),
  'balanceSheet.SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga.previousYear':
    id(
      'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga.previousYear'
    ),
  'balanceSheet.SkulderOvrigaForetagAgarintresseLangfristiga.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderOvrigaForetagAgarintresseLangfristiga.year'
  ),
  'balanceSheet.SkulderOvrigaForetagAgarintresseLangfristiga.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderOvrigaForetagAgarintresseLangfristiga.previousYear'
  ),
  'balanceSheet.OvrigaLangfristigaSkulder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.OvrigaLangfristigaSkulder.year'
  ),
  'balanceSheet.OvrigaLangfristigaSkulder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.OvrigaLangfristigaSkulder.previousYear'
  ),
  'balanceSheet.LangfristigaSkulder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulder.year'
  ),
  'balanceSheet.LangfristigaSkulder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulder.previousYear'
  ),
  'balanceSheet.CheckrakningskreditKortfristig.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.CheckrakningskreditKortfristig.year'
  ),
  'balanceSheet.CheckrakningskreditKortfristig.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.CheckrakningskreditKortfristig.previousYear'
  ),
  'balanceSheet.OvrigaKortfristigaSkulderKreditinstitut.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.OvrigaKortfristigaSkulderKreditinstitut.year'
  ),
  'balanceSheet.OvrigaKortfristigaSkulderKreditinstitut.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.OvrigaKortfristigaSkulderKreditinstitut.previousYear'
  ),
  'balanceSheet.ForskottFranKunder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.ForskottFranKunder.year'
  ),
  'balanceSheet.ForskottFranKunder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.ForskottFranKunder.previousYear'
  ),
  'balanceSheet.PagaendeArbetenAnnansRakningKortfristigaSkulder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.PagaendeArbetenAnnansRakningKortfristigaSkulder.year'
  ),
  'balanceSheet.PagaendeArbetenAnnansRakningKortfristigaSkulder.previousYear':
    id(
      'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.PagaendeArbetenAnnansRakningKortfristigaSkulder.previousYear'
    ),
  'balanceSheet.FaktureradEjUpparbetadIntakt.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.FaktureradEjUpparbetadIntakt.year'
  ),
  'balanceSheet.FaktureradEjUpparbetadIntakt.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.FaktureradEjUpparbetadIntakt.previousYear'
  ),
  'balanceSheet.Leverantorsskulder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Leverantorsskulder.year'
  ),
  'balanceSheet.Leverantorsskulder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Leverantorsskulder.previousYear'
  ),
  'balanceSheet.Vaxelskulder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Vaxelskulder.year'
  ),
  'balanceSheet.Vaxelskulder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Vaxelskulder.previousYear'
  ),
  'balanceSheet.SkulderKoncernforetagKortfristiga.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderKoncernforetagKortfristiga.year'
  ),
  'balanceSheet.SkulderKoncernforetagKortfristiga.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderKoncernforetagKortfristiga.previousYear'
  ),
  'balanceSheet.SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga.year':
    id(
      'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga.year'
    ),
  'balanceSheet.SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga.previousYear':
    id(
      'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga.previousYear'
    ),
  'balanceSheet.SkulderOvrigaForetagAgarintresseKortfristiga.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderOvrigaForetagAgarintresseKortfristiga.year'
  ),
  'balanceSheet.SkulderOvrigaForetagAgarintresseKortfristiga.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderOvrigaForetagAgarintresseKortfristiga.previousYear'
  ),
  'balanceSheet.Skatteskulder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Skatteskulder.year'
  ),
  'balanceSheet.Skatteskulder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Skatteskulder.previousYear'
  ),
  'balanceSheet.OvrigaKortfristigaSkulder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.OvrigaKortfristigaSkulder.year'
  ),
  'balanceSheet.OvrigaKortfristigaSkulder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.OvrigaKortfristigaSkulder.previousYear'
  ),
  'balanceSheet.UpplupnaKostnaderForutbetaldaIntakter.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.UpplupnaKostnaderForutbetaldaIntakter.year'
  ),
  'balanceSheet.UpplupnaKostnaderForutbetaldaIntakter.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.UpplupnaKostnaderForutbetaldaIntakter.previousYear'
  ),
  'balanceSheet.KortfristigaSkulder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulder.year'
  ),
  'balanceSheet.KortfristigaSkulder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulder.previousYear'
  ),
  'balanceSheet.EgetKapitalSkulder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulder.year'
  ),
  'balanceSheet.EgetKapitalSkulder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulder.previousYear'
  ),
});
