import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import {
  FieldError,
  FieldValues,
  RegisterOptions,
  UseFormSetValue,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { isValidPersonalNumber } from '@agoy/common';

import { SimplifiedTextfield } from '_shared/components/Inputs';
import FormError from '_shared/components/Inputs/FormError';
import { PrivatePerson, PrivatePersonWithManager } from '_person/_types/person';
import countries from '_shared/countries';
import DropdownWithSelect from '_shared/components/Select/DropdownWithSelect';
import When from '_shared/components/When/When';

const CountrySelect = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &.top {
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    min-width: 180px;
    color: #000000a6 !important;
    font-size: 0.875rem !important;
    font-family: 'Exo 2';
    line-height: 1.75 !important;
  }

  p {
    margin: 0;
    font-size: 1rem;
    font-family: 'Exo 2';
    font-weight: 400;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
  }

  div {
    width: 100%;

    color: #000;
    font-size: 1rem;
    font-family: 'Exo 2';
    font-weight: 400;
    line-height: 1.1876em;
  }

  .editing {
    position: relative;
    border-radius: 4px;
    width: 100%;
    outline-color: rgba(0, 0, 0, 0.23) !important;
  }
`;
export type ValidationTypes = {
  [key: string]: RegisterOptions;
};

export const Validation: ValidationTypes = {
  PersonalNumber: {
    validate: (number) => isValidPersonalNumber(number),
    minLength: 10,
    pattern: /^(18|19|20|)?(\d{6})[-–+]?(\d{4})$/g,
  },
  Email: { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ },
  PhoneNumber: { pattern: /0([-\s]?\d){6,10}/ },
};

/**
 * NestedKeyOf will give us typed fields even for the nested keys
 * such as postaddress.city etc..
 */
export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & string]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & string];

export type InfoFieldProps = {
  fieldName: NestedKeyOf<PrivatePerson>;
  disabled?: boolean;
  fieldError?: FieldError;
  placeholder?: string;
  readOnlyValue?: string;
  isEditing?: boolean;
  labelPlacement?: 'top' | 'left';
  fullWidth?: boolean;
  value?: string;
  setValue?: UseFormSetValue<PrivatePersonWithManager>;
};

const InfoField = React.forwardRef((props: InfoFieldProps, ref) => {
  const { formatMessage } = useIntl();
  const {
    fieldName,
    isEditing = true,
    readOnlyValue,
    labelPlacement = 'top',
    fieldError,
    value,
    setValue,
    ...rest
  } = props;

  const getString = (field: string) => {
    return formatMessage({ id: `person.form.${field}` });
  };

  const getErrorMessage = () => {
    const validationMessages = {
      validate: getString(`${fieldName}.wrongFormat`),
      minLength: getString(`${fieldName}.wrongFormat`),
      pattern: getString(`${fieldName}.wrongFormat`),
      required: getString('required'),
    };
    return fieldError ? validationMessages[fieldError.type] : '';
  };

  const countriesItems = useMemo(
    () =>
      countries.map((country) => ({
        value: country.country,
        label: country.country,
      })),
    [countries]
  );

  return (
    <Grid item sm={rest.fullWidth ? 10 : 6} xs={12}>
      <When isTrue={fieldName === 'taxAddress.country'}>
        <CountrySelect className={labelPlacement}>
          <label>
            {formatMessage({ id: 'person.form.taxAddress.country' })}
          </label>
          <When isTrue={isEditing} fallback={<p>{readOnlyValue}</p>}>
            <DropdownWithSelect
              value={value}
              items={[
                { name: 'countries', showName: false, items: countriesItems },
              ]}
              onValueChange={({ value }) => {
                if (typeof value === 'string') {
                  setValue?.('taxAddress.country', value);
                }
              }}
            />
          </When>
        </CountrySelect>
      </When>
      <When isTrue={fieldName !== 'taxAddress.country'}>
        <SimplifiedTextfield
          {...rest}
          label={getString(fieldName)}
          value={!isEditing ? readOnlyValue : undefined}
          inputRef={ref}
          labelPlacement={labelPlacement}
          editing={isEditing}
        />
      </When>
      <FormError>{getErrorMessage()}</FormError>
    </Grid>
  );
});

export default InfoField;
