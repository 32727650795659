import { getUnixTime } from 'date-fns';
import {
  ReconciliationBalanceAccountGroup,
  ReconciliationBalanceAccountRow,
  ReconciliationBalanceKeyFigure,
} from '@agoy/api-sdk-core';
import { isSameOrAfter, isSameOrBefore, parse } from '@agoy/dates';
import { ReconciliationPeriod } from '@agoy/reconciliation';
import {
  RowWithoutClassName,
  Row,
  MovedRows,
  MovedAccountsBalance,
} from '../../RowContext/types';
import {
  AccountBalance,
  ReconciliationBalanceAccountRowWithMoving,
} from '../../types';
import compareRows from '../../RowContext/compareRows';

export type InitialRow =
  | ReconciliationBalanceAccountRow
  | ReconciliationBalanceAccountRowWithMoving
  | ReconciliationBalanceAccountGroup
  | ReconciliationBalanceKeyFigure;

const toAccountRow = (
  row: ReconciliationBalanceAccountRow
): RowWithoutClassName => ({
  type: 'account',
  accountNumber: `${row.number}`,
  id: `${row.number}`,
});

const toKeyFigureRow = (
  row: ReconciliationBalanceKeyFigure
): RowWithoutClassName => ({
  type: 'keyFigure',
  id: row.id,
});

const mapRow = (
  row: InitialRow,
  groupsWithHiddenRow,
  removeEmptyAccountGroups = false
): RowWithoutClassName[] => {
  if (row.type === 'account') {
    return [toAccountRow(row)];
  }
  if (row.type === 'group') {
    return toGroupRows(row, groupsWithHiddenRow, removeEmptyAccountGroups);
  }

  return [toKeyFigureRow(row)];
};

const hasAccounts = (row) => {
  if (row.type === 'group') {
    return row.rows.some((subRow) => {
      return hasAccounts(subRow);
    });
  }
  return row.type === 'account';
};

const toGroupRows = (
  row: ReconciliationBalanceAccountGroup,
  groupsWithHiddenRow: string[],
  removeEmptyAccountGroups = false
): Row[] => {
  const groupRows = row.rows
    .filter((subRow) => {
      if (subRow.type === 'group' && !removeEmptyAccountGroups) {
        return subRow.rows.some((subSubRow) => hasAccounts(subSubRow));
      }
      return true;
    })
    .flatMap((item) =>
      mapRow(item, groupsWithHiddenRow, removeEmptyAccountGroups)
    )
    .map((subRow) => ({ ...subRow, className: '' }))
    .sort(compareRows);

  const rows: Row[] = [
    {
      type: 'groupHeader',
      id: row.id,
      className: '',
    },
    ...groupRows,
  ];
  if (groupRows.length > 0) {
    rows.push({
      type: 'groupSum',
      id: row.id,
      withHiddenRow: groupsWithHiddenRow.includes(row.id),
      groupRows,
      className: '',
    });
  }
  return [
    {
      type: 'group',
      id: row.id,
      className: '',
      rows,
    },
  ];
};

export const extractRows = (
  rows: InitialRow[],
  groupsWithHiddenRow: string[],
  removeEmptyAccountGroups: boolean
): RowWithoutClassName[] =>
  rows.flatMap((row) => {
    switch (row.type) {
      case 'account':
        return toAccountRow(row);
      case 'group': {
        return toGroupRows(row, groupsWithHiddenRow, removeEmptyAccountGroups);
      }
      case 'key':
        return [
          {
            type: 'keyFigure',
            id: row.id,
          },
        ];
      default:
        return [];
    }
  });

export const createEmptyAccount = (
  number: number,
  movedTo?: string
): ReconciliationBalanceAccountRowWithMoving => ({
  type: 'account',
  number,
  hasComment: false,
  hasDocuments: false,
  hasInternalComments: false,
  hasSpecifications: false,
  hasLegacySpecifications: false,
  ib: 0,
  state: 'not_started',
  ub: 0,
  change: 0,
  isPlaceholder: !!movedTo,
  movedTo,
});

export const createEmptyGroup = (
  id: string
): ReconciliationBalanceAccountGroup => ({
  id,
  type: 'group',
  state: 'not_started',
  hasComment: false,
  hasDocuments: false,
  hasInternalComments: false,
  hasSpecifications: false,
  hasLegacySpecifications: false,
  rows: [],
  sum: {
    ib: 0,
    ub: 0,
    id: `${id}_sum`,
    type: 'key',
    change: 0,
  },
});

const getTimestampDifference = (a: string, b: string) =>
  Math.abs(getUnixTime(parse(a)) - getUnixTime(parse(b)));

export const getClosestDate = (date: string, dates: string[]) => {
  return dates.reduce((prevValue, item) => {
    if (!prevValue && isSameOrBefore(parse(item), parse(date))) {
      return item;
    }

    return getTimestampDifference(date, item) <
      getTimestampDifference(date, prevValue) &&
      isSameOrBefore(parse(item), parse(date))
      ? item
      : prevValue;
  }, '');
};

export const injectMovedRows = (
  rows: InitialRow[],
  movedAccountData: MovedAccountsBalance
): InitialRow[] => {
  if (!Object.keys(movedAccountData).length) {
    return rows;
  }
  const res = rows.reduce((prev, row) => {
    if (row.type === 'group') {
      const newAccounts: ReconciliationBalanceAccountRowWithMoving[] = [];

      const internal = injectMovedRows(row.rows, movedAccountData);

      Object.values(movedAccountData[row.id] || {}).forEach((accountRow) => {
        if (
          accountRow &&
          !internal.some(
            (item) =>
              item.type === 'account' && item.number === accountRow.number
          )
        ) {
          newAccounts.push(accountRow);
        }
      });

      return [
        ...prev,
        {
          ...row,
          rows: [...newAccounts, ...internal],
        },
      ];
    }

    if (row.type === 'account') {
      const isMovedAccount = Object.values(movedAccountData).some((item) =>
        Object.keys(item).includes(row.number.toString())
      );

      if (isMovedAccount) {
        return [...prev];
      }
    }

    return [...prev, row];
  }, [] as InitialRow[]);

  return res;
};

const getBalanceValues = (
  subRows: InitialRow[],
  parentGroupId = 'noGroup'
): Record<string, ReconciliationBalanceAccountRowWithMoving> => {
  return subRows.reduce((prev, row) => {
    if (row.type === 'group') {
      return { ...prev, ...getBalanceValues(row.rows, row.id) };
    }
    if (row.type === 'account' && parentGroupId) {
      return { ...prev, [`${parentGroupId}.${row.number}`]: row };
    }
    return prev;
  }, {});
};

const getAccountBalance = (
  balance: Record<string, ReconciliationBalanceAccountRowWithMoving>,
  accountNumber: string,
  movedTo?: string
) => {
  const accountBalance = Object.values(balance).find(
    (value) => value.number.toString() === accountNumber
  );

  if (accountBalance) {
    return { ...accountBalance, movedTo };
  }

  return createEmptyAccount(+accountNumber, movedTo);
};

export const getMovedAccountsBalance = (
  rows: InitialRow[],
  movedRows: MovedRows,
  period: ReconciliationPeriod
): MovedAccountsBalance => {
  const balance = getBalanceValues(rows);

  const result = {};

  Object.keys(movedRows).forEach((accountNumber) => {
    const periods = Object.keys(movedRows[accountNumber]).sort((a, b) =>
      isSameOrAfter(parse(a), parse(b)) ? -1 : 1
    );

    const closestPeriod = getClosestDate(
      period.type === 'yearEnd' ? period.end : period.start,
      periods
    );

    if (closestPeriod) {
      const { toGroupId } = movedRows[accountNumber][closestPeriod];

      result[toGroupId] = {
        ...result[toGroupId],
        [accountNumber]: getAccountBalance(balance, accountNumber, toGroupId),
      };
    }
  });

  return result;
};

// accounts for recalculating of keyFigures
export const getMovedAccounts = (
  movedRows: MovedRows,
  periodStart: string
): { [accountNumber: string]: string } => {
  const result = {};

  Object.keys(movedRows).forEach((accountNumber) => {
    const periods = Object.keys(movedRows[accountNumber]).sort((a, b) =>
      isSameOrAfter(parse(a), parse(b)) ? -1 : 1
    );

    const closestPeriod = getClosestDate(periodStart, periods);

    if (closestPeriod) {
      const { toGroupId } = movedRows[accountNumber][closestPeriod];

      result[accountNumber] = toGroupId;
    }
  });

  return result;
};

export const checkBalanceValue = (value: number) => {
  return Number.isFinite(value) ? value : null;
};

export const getBalance = (balanceRows: InitialRow[]) => {
  const newAccounts: AccountBalance = {};
  const newGroups: { [groupId: string]: ReconciliationBalanceAccountGroup } =
    {};
  const newKeyFigures: { [groupId: string]: ReconciliationBalanceKeyFigure } =
    {};

  const getValues = (
    subRows: InitialRow[],
    parentGroupId = 'noGroup'
  ): {
    sum: { change: number; ib: number; ub: number };
  } => {
    const resultSum = subRows.reduce(
      (prev, row) => {
        if (row.type === 'group') {
          const values = getValues(row.rows, row.id);
          const updatedSumRow = { ...row.sum, ...values.sum };

          newGroups[row.id] = {
            ...row,
            ...values,
            sum: updatedSumRow,
          };
          newAccounts[row.id] = updatedSumRow;

          return {
            ...values,
            sum: {
              change: values.sum.change + prev.sum.change,
              ib: values.sum.ib + prev.sum.ib,
              ub: values.sum.ub + prev.sum.ub,
            },
          };
        }

        if (row.type === 'account' && parentGroupId) {
          const key = `${parentGroupId}.${row.number}`;

          newAccounts[key] = row;
          return {
            sum: {
              change: row.change + prev.sum.change,
              ib: row.ib + prev.sum.ib,
              ub: row.ub + prev.sum.ub,
            },
          };
        }

        if (row.type === 'key') {
          newKeyFigures[row.id] = row;
        }

        return prev;
      },
      {
        sum: { change: 0, ib: 0, ub: 0 },
      }
    );

    return resultSum;
  };

  getValues(balanceRows);

  return {
    balances: newAccounts,
    groups: newGroups,
    keyFigures: newKeyFigures,
  };
};
