import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MenuItem, Popover } from '@material-ui/core';
import { PrintOutlined, Replay } from '@material-ui/icons';
import Button from '_shared/components/Buttons/Button';

interface OtherTasksButtonProps {
  periodLocked: boolean;
  onClickResync: () => void;
  onClickPrint: () => void;
  onClickSyncPreviousYearEnd: () => void;
  isPreviousYearEndLocked: boolean;
}

const RotatingIcon = styled(ExpandMoreIcon)`
  transition: transform 0.3s;

  &.open {
    transform: rotate(180deg);
  }

  &.closed {
    transform: rotate('0deg');
  }
`;

const MirroredReplay = styled(Replay)`
  transform: scaleX(-1);
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    border-radius: ${(props) => props.theme.spacing(1)}px;

    .MuiMenuItem-root {
      white-space: wrap;
      font-size: 14px;
    }
  }
`;

const PopoverWrapper = styled.div`
  max-width: 216px;
  padding: 8px 0;
`;

const OtherTasksButton = ({
  periodLocked,
  onClickResync,
  onClickPrint,
  onClickSyncPreviousYearEnd,
  isPreviousYearEndLocked,
}: OtherTasksButtonProps) => {
  const { formatMessage } = useIntl();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClickResync = () => {
    onClickResync();
    setAnchorEl(null);
  };

  const handleClickSyncPreviousYearEnd = () => {
    onClickSyncPreviousYearEnd();
    setAnchorEl(null);
  };

  return (
    <>
      {periodLocked ? (
        <Button
          size="small"
          variant="outlined"
          label={formatMessage({
            id: 'hidden.specification.button.otherTasks.print',
          })}
          onClick={onClickPrint}
          startIcon={<PrintOutlined />}
        />
      ) : (
        <Button
          size="small"
          variant="outlined"
          label={formatMessage({
            id: 'hidden.specification.button.otherTasks',
          })}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<RotatingIcon className={anchorEl ? 'open' : 'closed'} />}
        />
      )}

      <StyledPopover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PopoverWrapper>
          <MenuItem onClick={onClickPrint}>
            <IconWrapper>
              <PrintOutlined />
            </IconWrapper>
            {formatMessage({
              id: 'hidden.specification.button.otherTasks.print',
            })}
          </MenuItem>
          <MenuItem onClick={handleClickResync}>
            <IconWrapper>
              <MirroredReplay />
            </IconWrapper>
            {formatMessage({
              id: 'hidden.specification.button.otherTasks.resync',
            })}
          </MenuItem>
          {isPreviousYearEndLocked && (
            <MenuItem onClick={handleClickSyncPreviousYearEnd}>
              <IconWrapper>
                <MirroredReplay />
              </IconWrapper>
              {formatMessage({
                id: 'hidden.specification.button.otherTasks.syncPreviousYearEnd',
              })}
            </MenuItem>
          )}
        </PopoverWrapper>
      </StyledPopover>
    </>
  );
};

export default OtherTasksButton;
