/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: Former Note 5
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  previousYear,
  year,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2026K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2026K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Räntekostnader och liknande resultatposter'),
  data: {
    active: true,
    RantekostnaderLiknandeResultatposterAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Räntekostnader och liknande resultatposter'),
      table: table<IxbrlCell>(
        'notes.note26.data.RantekostnaderLiknandeResultatposterAbstract.table',
        {
          id: 'RantekostnaderLiknandeResultatposterAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'RantekostnaderLiknandeResultatposterAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows
                .addRowWithType(
                  'RantekostnaderLiknandeResultatposterRantekostnaderAbstract',
                  'header',
                  noteLabel('Räntekostnader'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'RantekostnaderLiknandeResultatposterSkulderKoncernforetag',
                    'row',
                    noteLabel('Räntekostnader koncernföretag'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:RantekostnaderLiknandeResultatposterSkulderKoncernforetag',
                      standardRubrik:
                        'Räntekostnader och liknande resultatposter som avser skulder till koncernföretag',
                      saldo: 'debit',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:RantekostnaderLiknandeResultatposterSkulderKoncernforetag',
                      standardRubrik:
                        'Räntekostnader och liknande resultatposter som avser skulder till koncernföretag',
                      saldo: 'debit',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'RantekostnaderLiknandeResultatposterSkulderOvrigaForetag',
                    'row',
                    noteLabel('Övriga företag'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:RantekostnaderLiknandeResultatposterSkulderOvrigaForetag',
                      standardRubrik:
                        'Räntekostnader avseende finansiella skulder till andra företag än koncernföretag',
                      saldo: 'debit',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:RantekostnaderLiknandeResultatposterSkulderOvrigaForetag',
                      standardRubrik:
                        'Räntekostnader avseende finansiella skulder till andra företag än koncernföretag',
                      saldo: 'debit',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'Rantekostnader',
                'sum',
                noteLabel('Summa'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note26.data.RantekostnaderLiknandeResultatposterAbstract.table.RantekostnaderLiknandeResultatposterAbstract.RantekostnaderLiknandeResultatposterRantekostnaderAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'period0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:Rantekostnader',
                    standardRubrik: 'Räntekostnader för finansiella skulder',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note26.data.RantekostnaderLiknandeResultatposterAbstract.table.RantekostnaderLiknandeResultatposterAbstract.RantekostnaderLiknandeResultatposterRantekostnaderAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'period1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:Rantekostnader',
                    standardRubrik: 'Räntekostnader för finansiella skulder',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'KursdifferenserFinansiellaSkulder',
                'row',
                noteLabel('Kursdifferenser'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:KursdifferenserFinansiellaSkulder',
                  standardRubrik:
                    'Kursdifferenser avseende finansiella skulder',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:KursdifferenserFinansiellaSkulder',
                  standardRubrik:
                    'Kursdifferenser avseende finansiella skulder',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'OrealiseradVardeforandringSkulder',
                'row',
                noteLabel('Orealiserad värdeförändring på skulder'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OrealiseradVardeforandringSkulder',
                  standardRubrik:
                    'Orealiserad värdeförändring på finansiella skulder',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OrealiseradVardeforandringSkulder',
                  standardRubrik:
                    'Orealiserad värdeförändring på finansiella skulder',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'RantekostnaderLiknandeResultatposter',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note26.data.RantekostnaderLiknandeResultatposterAbstract.table.RantekostnaderLiknandeResultatposterAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'period0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:RantekostnaderLiknandeResultatposter',
                standardRubrik: 'Räntekostnader och liknande resultatposter',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note26.data.RantekostnaderLiknandeResultatposterAbstract.table.RantekostnaderLiknandeResultatposterAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'period1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:RantekostnaderLiknandeResultatposter',
                standardRubrik: 'Räntekostnader och liknande resultatposter',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NotRantekostnaderLiknandeResultatposterKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till specifikation av räntekostnader och liknande resultatposter'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotRantekostnaderLiknandeResultatposterKommentar',
          standardRubrik:
            'Kommentar till specifikation av räntekostnader och liknande resultatposter',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2026K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        RantekostnaderLiknandeResultatposterAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotRantekostnaderLiknandeResultatposterKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
