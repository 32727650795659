import { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { groupBy } from 'lodash';

import { parseFormat } from '@agoy/dates';
import { asResultClass, useApiSdk } from 'api-sdk';
import { LegacySpecification } from '_clients/types/types';
import { useSelector } from 'redux/reducers';
import { Period } from '@agoy/api-sdk-core';
import { transactionMangling } from 'utils/SieParser/transactionUtils';
import { SkatteverketTransaction } from '_clients/redux/customer-view/types';
import { useIsNewSpecifications } from '_shared/HOC/withNewSpecificationsFeatureSwitchContext';

import PeriodDataContext from '../PeriodDataContext';
import { getNextLegacySpecificationId } from '../../HiddenGroupRow/utils';
import {
  NUMBER_OF_TRANSACTION_ROWS,
  TransactionFilter,
  ColumnsIds,
  TransactionInfo,
} from './types';
import {
  formatTransactionToLegacySpecification,
  getColumnsIds,
  getRowCells,
  getSkatteverketRowCells,
  getTransactionData,
} from './utils';
import SpecificationsContext from '../SpecificationView/Context/SpecificationsContext';

const useTransactionsTransferring = (accountNumber: string) => {
  const sdk = useApiSdk();
  const { formatMessage } = useIntl();
  const isNewSpecifications = useIsNewSpecifications();

  const { clientId, period } = useContext(PeriodDataContext);

  const { specification, specificationRows, initSpecification } = useContext(
    SpecificationsContext
  );

  const rawFinancialYears = useSelector(
    (state) => state.customers[clientId].rawFinancialYears
  );

  const [legacySpecifications, setLegacySpecifications] = useState<
    LegacySpecification[]
  >([]);
  const [columnsIds, setColumnsIds] = useState<ColumnsIds | null>();

  const fetchColumns = useCallback(async () => {
    const result = await asResultClass(
      sdk.getGroupedSpecificationColumns({
        clientid: clientId,
        periodId: period.id,
      })
    );

    if (result.ok) {
      setColumnsIds(getColumnsIds(result.val.groupedByCategory, formatMessage));
    }
  }, [clientId, formatMessage, period.id, sdk]);

  const fetchLegacySpecifications = useCallback(async () => {
    const result = await asResultClass(
      sdk.getLegacySpecifications({
        clientid: clientId,
        periodId: period.id,
        accountNumbers: [parseInt(accountNumber, 10)],
      })
    );

    if (result.ok) {
      const { accounts } = result.val;
      setLegacySpecifications(
        accounts[accountNumber]?.map((item) => ({
          ...item,
          amount: item.amount ? `${item.amount}` : '',
        })) ?? []
      );
    }
  }, [accountNumber, clientId, period, sdk]);

  useEffect(() => {
    if (isNewSpecifications) {
      fetchColumns();
    } else {
      fetchLegacySpecifications();
    }
  }, [fetchColumns, fetchLegacySpecifications, isNewSpecifications]);

  const updateLegacySpecifications = useCallback(
    async (updatedLegacySpecification: LegacySpecification[]) => {
      await asResultClass(
        sdk.updateLegacySpecifications({
          clientid: clientId,
          periodId: period.id,
          accountNumber: parseInt(accountNumber, 10),
          requestBody: updatedLegacySpecification.map(({ id, ...item }) => ({
            ...item,
            amount: parseFloat(item.amount) ?? null,
          })),
        })
      );
      await fetchLegacySpecifications();
    },
    [accountNumber, clientId, fetchLegacySpecifications, period.id, sdk]
  );

  const updateSpecifications = useCallback(
    async (rowCells: { columnId: number; value: string | number }[][]) => {
      let specificationId = specification.id;

      if (!specificationId) {
        const result = await initSpecification();
        specificationId = result?.id;
      }

      if (!specificationId || !columnsIds) {
        return;
      }

      await asResultClass(
        sdk.addSpecificationRows({
          clientid: clientId,
          specificationId,
          requestBody: { rowCells },
        })
      );
    },
    [clientId, columnsIds, initSpecification, sdk, specification.id]
  );

  const transferTransaction = useCallback(
    async (transaction: TransactionInfo) => {
      if (isNewSpecifications) {
        await updateSpecifications(getRowCells([transaction], columnsIds));
      } else {
        const updatedLegacySpecification = [
          ...legacySpecifications,
          formatTransactionToLegacySpecification(
            transaction,
            getNextLegacySpecificationId(legacySpecifications)
          ),
        ];
        await updateLegacySpecifications(updatedLegacySpecification);
      }
    },
    [
      columnsIds,
      isNewSpecifications,
      legacySpecifications,
      updateLegacySpecifications,
      updateSpecifications,
    ]
  );

  const transferAllTransactions = useCallback(
    async (
      filterBy: TransactionFilter,
      includePreliminary: boolean,
      totalNumberOfPages: number
    ) => {
      const allPeriods = rawFinancialYears.reduce<Record<string, Period[]>>(
        (acc, year) => {
          if (!year.periods) {
            return { ...acc };
          }
          return {
            ...acc,
            ...groupBy(year.periods, (p) => parseFormat(p.start, 'yyyyMM')),
          };
        },
        {}
      );

      const periodIdsToFetch = Object.keys(filterBy.periods).flatMap((key) =>
        allPeriods[key].filter((p) => includePreliminary || p.type === 'month')
      );

      const result = await asResultClass(
        sdk.getTransactions({
          clientid: clientId,
          accountNumber: filterBy.voucherId ? undefined : filterBy.account,
          periodId: filterBy.voucherId
            ? undefined
            : periodIdsToFetch.map((p) => p.id),
          limit: NUMBER_OF_TRANSACTION_ROWS * totalNumberOfPages,
          voucherId: filterBy.voucherId ?? undefined,
        })
      );

      if (result.err) {
        return;
      }

      const allTransactions = transactionMangling(result.val.transactions);

      if (isNewSpecifications) {
        await updateSpecifications(getRowCells(allTransactions, columnsIds));
      } else {
        const updatedLegacySpecification = [
          ...legacySpecifications,
          ...allTransactions.map((transaction) =>
            formatTransactionToLegacySpecification(
              transaction,
              getNextLegacySpecificationId(legacySpecifications)
            )
          ),
        ];
        await updateLegacySpecifications(updatedLegacySpecification);
      }
    },
    [
      clientId,
      columnsIds,
      isNewSpecifications,
      legacySpecifications,
      rawFinancialYears,
      sdk,
      updateLegacySpecifications,
      updateSpecifications,
    ]
  );

  const isTransactionTransferred = useCallback(
    (account: string, amount: string, reference: string) => {
      if (!isNewSpecifications) {
        return legacySpecifications.some((spec) => {
          const parsedAmount = parseFloat(spec.amount).toFixed(2);
          return (
            spec.reference === reference &&
            spec.account === account &&
            parsedAmount === amount
          );
        });
      }

      if (!columnsIds) return false;

      return specificationRows.some(({ cells }) => {
        const specValues = getTransactionData(cells, columnsIds);

        return (
          specValues.reference === reference &&
          specValues.account === account &&
          specValues.amount.toFixed(2) === parseFloat(amount).toFixed(2)
        );
      });
    },
    [columnsIds, isNewSpecifications, legacySpecifications, specificationRows]
  );

  const transferSVTransaction = useCallback(
    async (transaction: SkatteverketTransaction) => {
      if (isNewSpecifications) {
        await updateSpecifications([
          getSkatteverketRowCells(transaction, columnsIds, accountNumber),
        ]);
      } else {
        const updatedLegacySpecification = [
          ...legacySpecifications,
          {
            account: accountNumber,
            amount: transaction.amount.toFixed(2),
            description: transaction.specification,
            id: getNextLegacySpecificationId(legacySpecifications),
            reference: `Skatteverket`,
          },
        ];

        await updateLegacySpecifications(updatedLegacySpecification);
      }
    },
    [
      accountNumber,
      columnsIds,
      isNewSpecifications,
      legacySpecifications,
      updateLegacySpecifications,
      updateSpecifications,
    ]
  );

  const value = useMemo(
    () => ({
      transferTransaction,
      transferAllTransactions,
      isTransactionTransferred,
      transferSVTransaction,
    }),
    [
      transferTransaction,
      transferAllTransactions,
      isTransactionTransferred,
      transferSVTransaction,
    ]
  );

  return value;
};

export default useTransactionsTransferring;
