import React from 'react';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import { useSelector } from '../../redux/reducers';
import LicenseErrorPage from '../../_payment/components/pages/LicenseError';

/**
 * Checks if user has any Agoy package (effectively, any Fortnox license that
 * maps to an Agoy package)
 * */
const withAgoyPackageRouteGuard =
  (Component: React.FC) =>
  (props: Record<string, unknown>): JSX.Element => {
    const agoyPackageName = useSelector((state) => state.user.agoyPackageName);

    if (agoyPackageName === null) {
      return <LoadingPlaceholder />;
    }

    if (agoyPackageName === 'none') {
      return <LicenseErrorPage />;
    }

    return <Component {...props} />;
  };

export default withAgoyPackageRouteGuard;
