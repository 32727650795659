import { AgoyDocumentPart } from '@agoy/document';
import {
  CustomNotes,
  HeadingsSection,
  AnnualReportPart,
} from '@agoy/annual-report-document';
import { customNoteContentDefinition } from '../../shared/customNotes';
import { note2001K2v2ContentDefinition } from './notes/note2001k2v2';
import { note2004K2v2ContentDefinition } from './notes/note2004k2v2';
import { note2005K2v2ContentDefinition } from './notes/note2005k2v2';
import { note2009K2v2ContentDefinition } from './notes/note2009k2v2';
import { note2010K2v2ContentDefinition } from './notes/note2010k2v2';
import { note2014K2v2ContentDefinition } from './notes/note2014k2v2';
import { note2029K2v2ContentDefinition } from './notes/note2029k2v2';
import { note2021K2v2ContentDefinition } from './notes/note2021k2v2';
import { note2023K2v2ContentDefinition } from './notes/note2023k2v2';
import { note2026K2v2ContentDefinition } from './notes/note2026k2v2';
import { note2032K2v2ContentDefinition } from './notes/note2032k2v2';
import { note2034K2v2ContentDefinition } from './notes/note2034k2v2';
import { note2035K2v2ContentDefinition } from './notes/note2035k2v2';
import { note2036K2v2ContentDefinition } from './notes/note2036k2v2';
import { note2037K2v2ContentDefinition } from './notes/note2037k2v2';
import { note2038K2v2ContentDefinition } from './notes/note2038k2v2';
import { note2040K2v2ContentDefinition } from './notes/note2040k2v2';
import { note2041K2v2ContentDefinition } from './notes/note2041k2v2';
import { note2042K2v2ContentDefinition } from './notes/note2042k2v2';
import { note2044K2v2ContentDefinition } from './notes/note2044k2v2';
import { note2043K2v2ContentDefinition } from './notes/note2043k2v2';
import { note2046K2v2ContentDefinition } from './notes/note2046k2v2';
import { note2047K2v2ContentDefinition } from './notes/note2047k2v2';
import { note2048K2v2ContentDefinition } from './notes/note2048k2v2';
import { note2049K2v2ContentDefinition } from './notes/note2049k2v2';
import { note2050K2v2ContentDefinition } from './notes/note2050k2v2';
import { note2051K2v2ContentDefinition } from './notes/note2051k2v2';
import { note2053K2v2ContentDefinition } from './notes/note2053k2v2';
import { note2059K2v2ContentDefinition } from './notes/note2059k2v2';
import { note2061K2v2ContentDefinition } from './notes/note2061k2v2';
import { note2060K2v2ContentDefinition } from './notes/note2060k2v2';
import { note2063K2v2ContentDefinition } from './notes/note2063k2v2';
import { note2067K2v2ContentDefinition } from './notes/note2067k2v2';
import { note2075K2v2ContentDefinition } from './notes/note2075k2v2';
import { note2077K2v2ContentDefinition } from './notes/note2077k2v2';
import { note2081K2v2ContentDefinition } from './notes/note2081k2v2';
import { note2082K2v2ContentDefinition } from './notes/note2082k2v2';
import { note2083K2v2ContentDefinition } from './notes/note2083k2v2';
import { note2085K2v2ContentDefinition } from './notes/note2085k2v2';
import { note2087K2v2ContentDefinition } from './notes/note2087k2v2';
import { note2092K2v2ContentDefinition } from './notes/note2092k2v2';
import { note2095K2v2ContentDefinition } from './notes/note2095k2v2';
import { note2096K2v2ContentDefinition } from './notes/note2096k2v2';
import { note2100K2v2ContentDefinition } from './notes/note2100k2v2';
export type GeneratedSharesK2Notes = {
  headings: HeadingsSection;
  note1: AgoyDocumentPart<typeof note2001K2v2ContentDefinition>;
  note4: AgoyDocumentPart<typeof note2004K2v2ContentDefinition>;
  note5: AgoyDocumentPart<typeof note2005K2v2ContentDefinition>;
  note9: AgoyDocumentPart<typeof note2009K2v2ContentDefinition>;
  note10: AgoyDocumentPart<typeof note2010K2v2ContentDefinition>;
  note14: AgoyDocumentPart<typeof note2014K2v2ContentDefinition>;
  note29: AgoyDocumentPart<typeof note2029K2v2ContentDefinition>;
  note21: AgoyDocumentPart<typeof note2021K2v2ContentDefinition>;
  note23: AgoyDocumentPart<typeof note2023K2v2ContentDefinition>;
  note26: AgoyDocumentPart<typeof note2026K2v2ContentDefinition>;
  note32: AgoyDocumentPart<typeof note2032K2v2ContentDefinition>;
  note34: AgoyDocumentPart<typeof note2034K2v2ContentDefinition>;
  note35: AgoyDocumentPart<typeof note2035K2v2ContentDefinition>;
  note36: AgoyDocumentPart<typeof note2036K2v2ContentDefinition>;
  note37: AgoyDocumentPart<typeof note2037K2v2ContentDefinition>;
  note38: AgoyDocumentPart<typeof note2038K2v2ContentDefinition>;
  note40: AgoyDocumentPart<typeof note2040K2v2ContentDefinition>;
  note41: AgoyDocumentPart<typeof note2041K2v2ContentDefinition>;
  note42: AgoyDocumentPart<typeof note2042K2v2ContentDefinition>;
  note44: AgoyDocumentPart<typeof note2044K2v2ContentDefinition>;
  note43: AgoyDocumentPart<typeof note2043K2v2ContentDefinition>;
  note46: AgoyDocumentPart<typeof note2046K2v2ContentDefinition>;
  note47: AgoyDocumentPart<typeof note2047K2v2ContentDefinition>;
  note48: AgoyDocumentPart<typeof note2048K2v2ContentDefinition>;
  note49: AgoyDocumentPart<typeof note2049K2v2ContentDefinition>;
  note50: AgoyDocumentPart<typeof note2050K2v2ContentDefinition>;
  note51: AgoyDocumentPart<typeof note2051K2v2ContentDefinition>;
  note53: AgoyDocumentPart<typeof note2053K2v2ContentDefinition>;
  note59: AgoyDocumentPart<typeof note2059K2v2ContentDefinition>;
  note61: AgoyDocumentPart<typeof note2061K2v2ContentDefinition>;
  note60: AgoyDocumentPart<typeof note2060K2v2ContentDefinition>;
  note63: AgoyDocumentPart<typeof note2063K2v2ContentDefinition>;
  note67: AgoyDocumentPart<typeof note2067K2v2ContentDefinition>;
  note75: AgoyDocumentPart<typeof note2075K2v2ContentDefinition>;
  note77: AgoyDocumentPart<typeof note2077K2v2ContentDefinition>;
  note81: AgoyDocumentPart<typeof note2081K2v2ContentDefinition>;
  note82: AgoyDocumentPart<typeof note2082K2v2ContentDefinition>;
  note83: AgoyDocumentPart<typeof note2083K2v2ContentDefinition>;
  note85: AgoyDocumentPart<typeof note2085K2v2ContentDefinition>;
  note87: AgoyDocumentPart<typeof note2087K2v2ContentDefinition>;
  note92: AgoyDocumentPart<typeof note2092K2v2ContentDefinition>;
  note95: AgoyDocumentPart<typeof note2095K2v2ContentDefinition>;
  note96: AgoyDocumentPart<typeof note2096K2v2ContentDefinition>;
  note100: AgoyDocumentPart<typeof note2100K2v2ContentDefinition>;
  custom: CustomNotes;
} & AnnualReportPart;

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };

export const generatedSharesK2NotesType = {
  type: 'part' as const,
  children: {
    active: boolean,
    headings: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        notesIncomeStatementTitle: cell,
        notesBalanceSheetTitle: cell,
        notesOtherTitle: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note1: note2001K2v2ContentDefinition,
    note4: note2004K2v2ContentDefinition,
    note5: note2005K2v2ContentDefinition,
    note9: note2009K2v2ContentDefinition,
    note10: note2010K2v2ContentDefinition,
    note14: note2014K2v2ContentDefinition,
    note29: note2029K2v2ContentDefinition,
    note21: note2021K2v2ContentDefinition,
    note23: note2023K2v2ContentDefinition,
    note26: note2026K2v2ContentDefinition,
    note32: note2032K2v2ContentDefinition,
    note34: note2034K2v2ContentDefinition,
    note35: note2035K2v2ContentDefinition,
    note36: note2036K2v2ContentDefinition,
    note37: note2037K2v2ContentDefinition,
    note38: note2038K2v2ContentDefinition,
    note40: note2040K2v2ContentDefinition,
    note41: note2041K2v2ContentDefinition,
    note42: note2042K2v2ContentDefinition,
    note44: note2044K2v2ContentDefinition,
    note43: note2043K2v2ContentDefinition,
    note46: note2046K2v2ContentDefinition,
    note47: note2047K2v2ContentDefinition,
    note48: note2048K2v2ContentDefinition,
    note49: note2049K2v2ContentDefinition,
    note50: note2050K2v2ContentDefinition,
    note51: note2051K2v2ContentDefinition,
    note53: note2053K2v2ContentDefinition,
    note59: note2059K2v2ContentDefinition,
    note61: note2061K2v2ContentDefinition,
    note60: note2060K2v2ContentDefinition,
    note63: note2063K2v2ContentDefinition,
    note67: note2067K2v2ContentDefinition,
    note75: note2075K2v2ContentDefinition,
    note77: note2077K2v2ContentDefinition,
    note81: note2081K2v2ContentDefinition,
    note82: note2082K2v2ContentDefinition,
    note83: note2083K2v2ContentDefinition,
    note85: note2085K2v2ContentDefinition,
    note87: note2087K2v2ContentDefinition,
    note92: note2092K2v2ContentDefinition,
    note95: note2095K2v2ContentDefinition,
    note96: note2096K2v2ContentDefinition,
    note100: note2100K2v2ContentDefinition,
    custom: customNoteContentDefinition,
  },
};
