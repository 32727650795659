/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: nan
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
  previousYear,
  year,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2010K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2010K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Personal'),
  data: {
    active: true,
    MedelantaletAnstalldaAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Medelantalet anställda'),
      table: table<IxbrlCell>(
        'notes.note10.data.MedelantaletAnstalldaAbstract.table',
        {
          id: 'MedelantaletAnstalldaAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'MedelantaletAnstalldaAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'MedelantaletAnstalldaKvinnor',
                'row',
                noteLabel('Kvinnor'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'decimalItemType',
                  unitRef: 'antal-anstallda',
                  format: 'ixt:numspacecomma',
                  name: 'se-gen-base:MedelantaletAnstalldaKvinnor',
                  standardRubrik: 'Medelantalet anställda kvinnor',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'decimalItemType',
                  unitRef: 'antal-anstallda',
                  format: 'ixt:numspacecomma',
                  name: 'se-gen-base:MedelantaletAnstalldaKvinnor',
                  standardRubrik: 'Medelantalet anställda kvinnor',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'MedelantaletAnstalldaAndelKvinnor',
                'row',
                noteLabel('Kvinnor (%)'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'pureItemType',
                  unitRef: 'procent',
                  name: 'se-gen-base:MedelantaletAnstalldaAndelKvinnor',
                  standardRubrik: 'Andel anställda kvinnor',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'pureItemType',
                  unitRef: 'procent',
                  name: 'se-gen-base:MedelantaletAnstalldaAndelKvinnor',
                  standardRubrik: 'Andel anställda kvinnor',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'MedelantaletAnstalldaMan',
                'row',
                noteLabel('Män'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'decimalItemType',
                  unitRef: 'antal-anstallda',
                  format: 'ixt:numspacecomma',
                  name: 'se-gen-base:MedelantaletAnstalldaMan',
                  standardRubrik: 'Medelantalet anställda män',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'decimalItemType',
                  unitRef: 'antal-anstallda',
                  format: 'ixt:numspacecomma',
                  name: 'se-gen-base:MedelantaletAnstalldaMan',
                  standardRubrik: 'Medelantalet anställda män',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'MedelantaletAnstalldaAndelMan',
                'row',
                noteLabel('Män (%)'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'pureItemType',
                  unitRef: 'procent',
                  name: 'se-gen-base:MedelantaletAnstalldaAndelMan',
                  standardRubrik: 'Andel anställda män',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'pureItemType',
                  unitRef: 'procent',
                  name: 'se-gen-base:MedelantaletAnstalldaAndelMan',
                  standardRubrik: 'Andel anställda män',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'MedelantaletAnstallda',
            'sum',
            noteLabel('Medelantalet anställda'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note10.data.MedelantaletAnstalldaAbstract.table.MedelantaletAnstalldaAbstract.MedelantaletAnstalldaKvinnor.year'
                  ),
                  id(
                    'notes.note10.data.MedelantaletAnstalldaAbstract.table.MedelantaletAnstalldaAbstract.MedelantaletAnstalldaMan.year'
                  )
                )
              ),
              {
                contextRef: 'period0',
                type: 'decimalItemType',
                unitRef: 'antal-anstallda',
                format: 'ixt:numspacecomma',
                name: 'se-gen-base:MedelantaletAnstallda',
                standardRubrik: 'Medelantalet anställda',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note10.data.MedelantaletAnstalldaAbstract.table.MedelantaletAnstalldaAbstract.MedelantaletAnstalldaKvinnor.previousYear'
                  ),
                  id(
                    'notes.note10.data.MedelantaletAnstalldaAbstract.table.MedelantaletAnstalldaAbstract.MedelantaletAnstalldaMan.previousYear'
                  )
                )
              ),
              {
                contextRef: 'period1',
                type: 'decimalItemType',
                unitRef: 'antal-anstallda',
                format: 'ixt:numspacecomma',
                name: 'se-gen-base:MedelantaletAnstallda',
                standardRubrik: 'Medelantalet anställda',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      MedelantaletAnstalldaKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id('notes.note10.data.MedelantaletAnstalldaAbstract.active')
        ),
        title: field('Kommentar till medelantalet anställda'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:MedelantaletAnstalldaKommentar',
          standardRubrik: 'Kommentar till medelantalet anställda',
        }),
      },
    },
    LanderfordelningMedelantaletAnstalldaAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Fördelning av medelantalet anställda per land'),
      LanderfordelningMedelantaletAnstalldaTuple: {
        type: 'group',
        active: true,
        level: 0,
        title: field('Medelantalet anställda fördelade per land'),
        table: table<IxbrlCell>(
          'notes.note10.data.LanderfordelningMedelantaletAnstalldaAbstract.LanderfordelningMedelantaletAnstalldaTuple.table',
          {
            id: 'LanderfordelningMedelantaletAnstalldaLand',
            label: 'Land',
            dataType: 'text',
            active: true,
          },
          {
            id: 'LanderfordelningMedelantaletAnstalldaKvinnor',
            label: 'Kvinnor',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'LanderfordelningMedelantaletAnstalldaAndelKvinnor',
            label: 'Kvinnor (%)',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'LanderfordelningMedelantaletAnstalldaMan',
            label: 'Män',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'LanderfordelningMedelantaletAnstalldaAndelMan',
            label: 'Män (%)',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'LanderfordelningMedelantaletAnstallda',
            label: 'Medelantalet anställda',
            dataType: 'text',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'LanderfordelningMedelantaletAnstalldaTuple',
                'hidden',
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  '1',
                  'row',
                  ixbrlCell(value('Land'), {
                    type: 'stringItemType',
                    name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaLand',
                    contextRef: 'period0',
                    standardRubrik:
                      'Land där anställda utför huvudsakliga arbetsuppgifter',
                    negateValue: false,
                    tuple: {
                      name: 'se-gaap-ext:LanderfordelningMedelantaletAnstalldaTuple',
                      id: `${rows.getBaseId()}.1.tuple`,
                      tupleID: `${rows.getBaseId()}.1.tuple`,
                    },
                    tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'decimalItemType',
                    unitRef: 'antal-anstallda',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaKvinnor',
                    contextRef: 'period0',
                    standardRubrik: 'Medelantalet anställda kvinnor per land',
                    negateValue: false,
                    tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaAndelKvinnor',
                    contextRef: 'period0',
                    standardRubrik: 'Andel anställda kvinnor per land',
                    negateValue: false,
                    tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'decimalItemType',
                    unitRef: 'antal-anstallda',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaMan',
                    contextRef: 'period0',
                    standardRubrik: 'Medelantalet anställda män per land',
                    negateValue: false,
                    tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaAndelMan',
                    contextRef: 'period0',
                    standardRubrik: 'Andel anställda män per land',
                    negateValue: false,
                    tupleRef: tupleRef(5, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(
                    ref(
                      sumAllowNull(
                        id(
                          `${rows.getBaseId()}.1.LanderfordelningMedelantaletAnstalldaKvinnor`
                        ),
                        id(
                          `${rows.getBaseId()}.1.LanderfordelningMedelantaletAnstalldaMan`
                        )
                      )
                    ),
                    {
                      type: 'decimalItemType',
                      unitRef: 'antal-anstallda',
                      format: 'ixt:numspacecomma',
                      name: 'se-gen-base:LanderfordelningMedelantaletAnstallda',
                      contextRef: 'period0',
                      standardRubrik: 'Medelantalet anställda per land',
                      negateValue: false,
                      tupleRef: tupleRef(6, `${rows.getBaseId()}.1.tuple`),
                      previousYearTuple: false,
                    }
                  )
                );
                return rows.build();
              });
            rows.newRowTemplateGenerator((_id, baseId) => ({
              id: _id,
              active: true,
              type: 'row',
              cells: {
                LanderfordelningMedelantaletAnstalldaLand: ixbrlCell(
                  value('Land'),
                  {
                    type: 'stringItemType',
                    name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaLand',
                    contextRef: 'period0',
                    standardRubrik:
                      'Land där anställda utför huvudsakliga arbetsuppgifter',
                    negateValue: false,
                    tuple: {
                      name: 'se-gaap-ext:LanderfordelningMedelantaletAnstalldaTuple',
                      id: `${baseId}.${_id}.tuple`,
                      tupleID: `${baseId}.${_id}.tuple`,
                    },
                    tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }
                ),
                LanderfordelningMedelantaletAnstalldaKvinnor: ixbrlCell(
                  value(),
                  {
                    type: 'decimalItemType',
                    unitRef: 'antal-anstallda',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaKvinnor',
                    contextRef: 'period0',
                    standardRubrik: 'Medelantalet anställda kvinnor per land',
                    negateValue: false,
                    tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }
                ),
                LanderfordelningMedelantaletAnstalldaAndelKvinnor: ixbrlCell(
                  value(),
                  {
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaAndelKvinnor',
                    contextRef: 'period0',
                    standardRubrik: 'Andel anställda kvinnor per land',
                    negateValue: false,
                    tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }
                ),
                LanderfordelningMedelantaletAnstalldaMan: ixbrlCell(value(), {
                  type: 'decimalItemType',
                  unitRef: 'antal-anstallda',
                  format: 'ixt:numspacecomma',
                  name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaMan',
                  contextRef: 'period0',
                  standardRubrik: 'Medelantalet anställda män per land',
                  negateValue: false,
                  tupleRef: tupleRef(4, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                LanderfordelningMedelantaletAnstalldaAndelMan: ixbrlCell(
                  value(),
                  {
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaAndelMan',
                    contextRef: 'period0',
                    standardRubrik: 'Andel anställda män per land',
                    negateValue: false,
                    tupleRef: tupleRef(5, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }
                ),
                LanderfordelningMedelantaletAnstallda: ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        `${baseId}.${_id}.LanderfordelningMedelantaletAnstalldaKvinnor`
                      ),
                      id(
                        `${baseId}.${_id}.LanderfordelningMedelantaletAnstalldaMan`
                      )
                    )
                  ),
                  {
                    type: 'decimalItemType',
                    unitRef: 'antal-anstallda',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:LanderfordelningMedelantaletAnstallda',
                    contextRef: 'period0',
                    standardRubrik: 'Medelantalet anställda per land',
                    negateValue: false,
                    tupleRef: tupleRef(6, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }
                ),
              },
            }));
            return rows.build();
          })
          .build(),
      },
      LanderfordelningAnstalldaKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id(
            'notes.note10.data.LanderfordelningMedelantaletAnstalldaAbstract.LanderfordelningMedelantaletAnstalldaTuple.active'
          )
        ),
        title: field('Kommentar till medelantalet anställda per land'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:LanderfordelningAnstalldaKommentar',
          standardRubrik: 'Kommentar till medelantalet anställda per land',
        }),
      },
    },
    FordelningStyrelseLedandeBefattningshavareAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Könsfördelning i styrelse och ledning'),
      FordelningStyrelseledamoterAbstract: {
        type: 'group',
        active: true,
        level: 1,
        title: field('Könsfördelning i styrelse'),
        table: table<IxbrlCell>(
          'notes.note10.data.FordelningStyrelseLedandeBefattningshavareAbstract.FordelningStyrelseledamoterAbstract.table',
          {
            id: 'FordelningStyrelseledamoterAbstract',
            label: 'Styrelseledamöter',
            dataType: 'text',
            active: true,
          },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) ?? '',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) ?? '',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'FordelningStyrelseledamoterAbstract',
                'hidden',
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  'FordelningStyrelseledamoterKvinnor',
                  'row',
                  noteLabel('Kvinnor'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans0',
                    type: 'decimalItemType',
                    unitRef: 'antal-anstallda',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:FordelningStyrelseledamoterKvinnor',
                    standardRubrik: 'Antal kvinnor som är styrelseledamöter',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans1',
                    type: 'decimalItemType',
                    unitRef: 'antal-anstallda',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:FordelningStyrelseledamoterKvinnor',
                    standardRubrik: 'Antal kvinnor som är styrelseledamöter',
                    negateValue: false,
                  })
                );
                rows.addRowWithType(
                  'FordelningStyrelseledamoterAndelKvinnor',
                  'row',
                  noteLabel('Kvinnor (%)'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans0',
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:FordelningStyrelseledamoterAndelKvinnor',
                    standardRubrik: 'Andel kvinnor som är i styrlesen',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans1',
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:FordelningStyrelseledamoterAndelKvinnor',
                    standardRubrik: 'Andel kvinnor som är i styrlesen',
                    negateValue: false,
                  })
                );
                rows.addRowWithType(
                  'FordelningStyrelseledamoterMan',
                  'row',
                  noteLabel('Män'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans0',
                    type: 'decimalItemType',
                    unitRef: 'antal-anstallda',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:FordelningStyrelseledamoterMan',
                    standardRubrik: 'Antal män som är styrelseledamöter',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans1',
                    type: 'decimalItemType',
                    unitRef: 'antal-anstallda',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:FordelningStyrelseledamoterMan',
                    standardRubrik: 'Antal män som är styrelseledamöter',
                    negateValue: false,
                  })
                );
                rows.addRowWithType(
                  'FordelningStyrelseledamoterAndelMan',
                  'row',
                  noteLabel('Män (%)'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans0',
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:FordelningStyrelseledamoterAndelMan',
                    standardRubrik: 'Andel män som är i styrelsen',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans1',
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:FordelningStyrelseledamoterAndelMan',
                    standardRubrik: 'Andel män som är i styrelsen',
                    negateValue: false,
                  })
                );
                return rows.build();
              });
            rows.addRowWithType(
              'AntalStyrelseledamoter',
              'sum',
              noteLabel('Antal styrelseledamöter'),
              ixbrlCell(
                ref(
                  sumAllowNull(
                    id(
                      'notes.note10.data.FordelningStyrelseLedandeBefattningshavareAbstract.FordelningStyrelseledamoterAbstract.table.FordelningStyrelseledamoterAbstract.FordelningStyrelseledamoterKvinnor.year'
                    ),
                    id(
                      'notes.note10.data.FordelningStyrelseLedandeBefattningshavareAbstract.FordelningStyrelseledamoterAbstract.table.FordelningStyrelseledamoterAbstract.FordelningStyrelseledamoterMan.year'
                    )
                  )
                ),
                {
                  contextRef: 'balans0',
                  type: 'decimalItemType',
                  unitRef: 'antal-anstallda',
                  format: 'ixt:numspacecomma',
                  name: 'se-gen-base:AntalStyrelseledamoter',
                  standardRubrik: 'Antal styrelseledamöter',
                  negateValue: false,
                }
              ),
              ixbrlCell(
                ref(
                  sumAllowNull(
                    id(
                      'notes.note10.data.FordelningStyrelseLedandeBefattningshavareAbstract.FordelningStyrelseledamoterAbstract.table.FordelningStyrelseledamoterAbstract.FordelningStyrelseledamoterKvinnor.previousYear'
                    ),
                    id(
                      'notes.note10.data.FordelningStyrelseLedandeBefattningshavareAbstract.FordelningStyrelseledamoterAbstract.table.FordelningStyrelseledamoterAbstract.FordelningStyrelseledamoterMan.previousYear'
                    )
                  )
                ),
                {
                  contextRef: 'balans1',
                  type: 'decimalItemType',
                  unitRef: 'antal-anstallda',
                  format: 'ixt:numspacecomma',
                  name: 'se-gen-base:AntalStyrelseledamoter',
                  standardRubrik: 'Antal styrelseledamöter',
                  negateValue: false,
                }
              )
            );
            return rows.build();
          })
          .build(),
      },
      FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAbstract: {
        type: 'group',
        active: true,
        level: 1,
        title: field(
          'Verkställande direktör och övriga ledande befattningshavare'
        ),
        table: table<IxbrlCell>(
          'notes.note10.data.FordelningStyrelseLedandeBefattningshavareAbstract.FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAbstract.table',
          {
            id: 'FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAbstract',
            label: 'VD och övriga ledande befattningshavare',
            dataType: 'text',
            active: true,
          },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) ?? '',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) ?? '',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAbstract',
                'hidden',
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  'FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareKvinnor',
                  'row',
                  noteLabel('Kvinnor'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans0',
                    type: 'decimalItemType',
                    unitRef: 'antal-anstallda',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareKvinnor',
                    standardRubrik:
                      'Antal kvinnor bland ledande befattningshavare',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans1',
                    type: 'decimalItemType',
                    unitRef: 'antal-anstallda',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareKvinnor',
                    standardRubrik:
                      'Antal kvinnor bland ledande befattningshavare',
                    negateValue: false,
                  })
                );
                rows.addRowWithType(
                  'FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAndelKvinnor',
                  'row',
                  noteLabel('Kvinnor (%)'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans0',
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAndelKvinnor',
                    standardRubrik:
                      'Andel kvinnor bland ledande befattningshavare',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans1',
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAndelKvinnor',
                    standardRubrik:
                      'Andel kvinnor bland ledande befattningshavare',
                    negateValue: false,
                  })
                );
                rows.addRowWithType(
                  'FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareMan',
                  'row',
                  noteLabel('Män'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans0',
                    type: 'decimalItemType',
                    unitRef: 'antal-anstallda',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareMan',
                    standardRubrik: 'Antal män bland ledande befattningshavare',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans1',
                    type: 'decimalItemType',
                    unitRef: 'antal-anstallda',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareMan',
                    standardRubrik: 'Antal män bland ledande befattningshavare',
                    negateValue: false,
                  })
                );
                rows.addRowWithType(
                  'FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAndelMan',
                  'row',
                  noteLabel('Män (%)'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans0',
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAndelMan',
                    standardRubrik: 'Andel män bland ledande befattningshavare',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans1',
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAndelMan',
                    standardRubrik: 'Andel män bland ledande befattningshavare',
                    negateValue: false,
                  })
                );
                return rows.build();
              });
            rows.addRowWithType(
              'AntalLedandeBefattningshavare',
              'sum',
              noteLabel('Antal ledande befattningshavare'),
              ixbrlCell(
                ref(
                  sumAllowNull(
                    id(
                      'notes.note10.data.FordelningStyrelseLedandeBefattningshavareAbstract.FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAbstract.table.FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAbstract.FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareKvinnor.year'
                    ),
                    id(
                      'notes.note10.data.FordelningStyrelseLedandeBefattningshavareAbstract.FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAbstract.table.FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAbstract.FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareMan.year'
                    )
                  )
                ),
                {
                  contextRef: 'balans0',
                  type: 'decimalItemType',
                  unitRef: 'antal-anstallda',
                  format: 'ixt:numspacecomma',
                  name: 'se-gen-base:AntalLedandeBefattningshavare',
                  standardRubrik: 'Antal ledande befattningshavare',
                  negateValue: false,
                }
              ),
              ixbrlCell(
                ref(
                  sumAllowNull(
                    id(
                      'notes.note10.data.FordelningStyrelseLedandeBefattningshavareAbstract.FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAbstract.table.FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAbstract.FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareKvinnor.previousYear'
                    ),
                    id(
                      'notes.note10.data.FordelningStyrelseLedandeBefattningshavareAbstract.FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAbstract.table.FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAbstract.FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareMan.previousYear'
                    )
                  )
                ),
                {
                  contextRef: 'balans1',
                  type: 'decimalItemType',
                  unitRef: 'antal-anstallda',
                  format: 'ixt:numspacecomma',
                  name: 'se-gen-base:AntalLedandeBefattningshavare',
                  standardRubrik: 'Antal ledande befattningshavare',
                  negateValue: false,
                }
              )
            );
            return rows.build();
          })
          .build(),
        FordelningStyrelseLedandeBefattningshavareKommentar: {
          type: 'group',
          active: true,
          level: 1,
          title: field('Kommentar till könsfördelning i styrelse och ledning'),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:FordelningStyrelseLedandeBefattningshavareKommentar',
            standardRubrik:
              'Kommentar till könsfördelning i styrelse och ledning',
          }),
        },
      },
    },
    LonerAndraErsattningarAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Löner och andra ersättningar'),
      table: table<IxbrlCell>(
        'notes.note10.data.LonerAndraErsattningarAbstract.table',
        {
          id: 'LonerAndraErsattningarAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'LonerAndraErsattningarAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'LonerAndraErsattningarStyrelseledamoterVerkstallandeDirektorMotsvarandeBefattningshavare',
                'row',
                noteLabel('Styrelsen och verkställande direktören'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:LonerAndraErsattningarStyrelseledamoterVerkstallandeDirektorMotsvarandeBefattningshavare',
                  standardRubrik:
                    'Löner och andra ersättningar till styrelseledamöter, verkställande direktör och motsvarande befattningshavare',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:LonerAndraErsattningarStyrelseledamoterVerkstallandeDirektorMotsvarandeBefattningshavare',
                  standardRubrik:
                    'Löner och andra ersättningar till styrelseledamöter, verkställande direktör och motsvarande befattningshavare',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'LonerAndraErsattningarStyrelseledamoterVerkstallandeDirektorMotsvarandeBefattningshavareVaravTantiemLiknande',
                'row',
                noteLabel('Varav tantiem och liknande'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:LonerAndraErsattningarStyrelseledamoterVerkstallandeDirektorMotsvarandeBefattningshavareVaravTantiemLiknande',
                  standardRubrik:
                    'Tantiem och liknande ersättningar, del av löner och andra ersättningar till styrelseledamöter, verkställande direktör och motsvarande befattningshavare',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:LonerAndraErsattningarStyrelseledamoterVerkstallandeDirektorMotsvarandeBefattningshavareVaravTantiemLiknande',
                  standardRubrik:
                    'Tantiem och liknande ersättningar, del av löner och andra ersättningar till styrelseledamöter, verkställande direktör och motsvarande befattningshavare',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'LonerAndraErsattningarOvrigaAnstallda',
                'row',
                noteLabel('Övriga anställda'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:LonerAndraErsattningarOvrigaAnstallda',
                  standardRubrik:
                    'Löner och andra ersättningar till övriga anställda',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:LonerAndraErsattningarOvrigaAnstallda',
                  standardRubrik:
                    'Löner och andra ersättningar till övriga anställda',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'LonerAndraErsattningar',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note10.data.LonerAndraErsattningarAbstract.table.LonerAndraErsattningarAbstract.LonerAndraErsattningarStyrelseledamoterVerkstallandeDirektorMotsvarandeBefattningshavare.year'
                  ),
                  id(
                    'notes.note10.data.LonerAndraErsattningarAbstract.table.LonerAndraErsattningarAbstract.LonerAndraErsattningarOvrigaAnstallda.year'
                  )
                )
              ),
              {
                contextRef: 'period0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:LonerAndraErsattningar',
                standardRubrik: 'Löner och andra ersättningar',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note10.data.LonerAndraErsattningarAbstract.table.LonerAndraErsattningarAbstract.LonerAndraErsattningarStyrelseledamoterVerkstallandeDirektorMotsvarandeBefattningshavare.previousYear'
                  ),
                  id(
                    'notes.note10.data.LonerAndraErsattningarAbstract.table.LonerAndraErsattningarAbstract.LonerAndraErsattningarOvrigaAnstallda.previousYear'
                  )
                )
              ),
              {
                contextRef: 'period1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:LonerAndraErsattningar',
                standardRubrik: 'Löner och andra ersättningar',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      LonerAndraErsattningarKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id('notes.note10.data.LonerAndraErsattningarAbstract.active')
        ),
        title: field('Kommentar till löner och andra ersättningar'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:LonerAndraErsattningarKommentar',
          standardRubrik: 'Kommentar till löner och andra ersättningar',
        }),
      },
    },
    SocialaKostnaderInklusivePensionskostnaderAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Sociala kostnader inklusive pensionskostnader'),
      table: table<IxbrlCell>(
        'notes.note10.data.SocialaKostnaderInklusivePensionskostnaderAbstract.table',
        {
          id: 'SocialaKostnaderInklusivePensionskostnaderAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'SocialaKostnaderInklusivePensionskostnaderAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows
                .addRowWithType(
                  'PensionskostnaderAbstract',
                  'header',
                  noteLabel('Pensionskostnader'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'PensionskostnaderStyrelsenVerkstallandeDirektorenMotsvarandeBefattningshavare',
                    'row',
                    noteLabel('Styrelsen och verkställande direktören'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PensionskostnaderStyrelsenVerkstallandeDirektorenMotsvarandeBefattningshavare',
                      standardRubrik:
                        'Pensionskostnader för styrelseledamöter, verkställande direktör och motsvarande befattningshavare',
                      saldo: 'debit',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PensionskostnaderStyrelsenVerkstallandeDirektorenMotsvarandeBefattningshavare',
                      standardRubrik:
                        'Pensionskostnader för styrelseledamöter, verkställande direktör och motsvarande befattningshavare',
                      saldo: 'debit',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'PensionskostnaderOvrigaAnstallda',
                    'row',
                    noteLabel('Övriga anställda'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PensionskostnaderOvrigaAnstallda',
                      standardRubrik: 'Pensionskostnader för övriga anställda',
                      saldo: 'debit',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PensionskostnaderOvrigaAnstallda',
                      standardRubrik: 'Pensionskostnader för övriga anställda',
                      saldo: 'debit',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'Pensionskostnader',
                'sum',
                noteLabel('Summa pensionskostnader'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note10.data.SocialaKostnaderInklusivePensionskostnaderAbstract.table.SocialaKostnaderInklusivePensionskostnaderAbstract.PensionskostnaderAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'period0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:Pensionskostnader',
                    standardRubrik: 'Pensionskostnader',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note10.data.SocialaKostnaderInklusivePensionskostnaderAbstract.table.SocialaKostnaderInklusivePensionskostnaderAbstract.PensionskostnaderAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'period1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:Pensionskostnader',
                    standardRubrik: 'Pensionskostnader',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'OvrigaSocialaAvgifterEnligtLagAvtal',
                'row',
                noteLabel('Övriga sociala avgifter enligt lag och avtal'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaSocialaAvgifterEnligtLagAvtal',
                  standardRubrik:
                    'Övriga sociala avgifter enligt lag och avtal',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaSocialaAvgifterEnligtLagAvtal',
                  standardRubrik:
                    'Övriga sociala avgifter enligt lag och avtal',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'SocialaKostnaderInklPensionskostnader',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note10.data.SocialaKostnaderInklusivePensionskostnaderAbstract.table.SocialaKostnaderInklusivePensionskostnaderAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'period0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:SocialaKostnaderInklPensionskostnader',
                standardRubrik: 'Sociala kostnader inklusive pensionskostnader',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note10.data.SocialaKostnaderInklusivePensionskostnaderAbstract.table.SocialaKostnaderInklusivePensionskostnaderAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'period1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:SocialaKostnaderInklPensionskostnader',
                standardRubrik: 'Sociala kostnader inklusive pensionskostnader',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      SocialaKostnaderKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id(
            'notes.note10.data.SocialaKostnaderInklusivePensionskostnaderAbstract.active'
          )
        ),
        title: field(
          'Kommentar till sociala kostnader inklusive pensionskostnader'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:SocialaKostnaderKommentar',
          standardRubrik:
            'Kommentar till sociala kostnader inklusive pensionskostnader',
        }),
      },
    },
    PensionsforpliktelserNuvarandeTidigareStyrelseVerkstallandeDirektorAbstract:
      {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Pensionsförpliktelser för nuvarande samt tidigare styrelse och verkställande direktör'
        ),
        table: table<IxbrlCell>(
          'notes.note10.data.PensionsforpliktelserNuvarandeTidigareStyrelseVerkstallandeDirektorAbstract.table',
          {
            id: 'PensionsforpliktelserNuvarandeTidigareStyrelseVerkstallandeDirektorAbstract',
            label: 'Pensionsförpliktelser',
            dataType: 'text',
            active: true,
          },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) ?? '',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) ?? '',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'PensionsforpliktelserNuvarandeTidigareStyrelseVerkstallandeDirektorAbstract',
                'hidden',
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  'PensionsforpliktelserNuvarandeTidigareStyrelseVerkstallandeDirektor',
                  'row',
                  noteLabel('Styrelse och verkställande direktör'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:PensionsforpliktelserNuvarandeTidigareStyrelseVerkstallandeDirektor',
                    standardRubrik:
                      'Pensionsförpliktelser för nuvarande samt tidigare styrelse och verkställande direktör',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:PensionsforpliktelserNuvarandeTidigareStyrelseVerkstallandeDirektor',
                    standardRubrik:
                      'Pensionsförpliktelser för nuvarande samt tidigare styrelse och verkställande direktör',
                    negateValue: false,
                  })
                );
                return rows.build();
              });
            return rows.build();
          })
          .build(),
        PensionsforpliktelserNuvarandeTidigareStyrelseVerkstallandeDirektorKommentar:
          {
            type: 'group',
            active: true,
            level: 0,
            visible: ref(
              id(
                'notes.note10.data.PensionsforpliktelserNuvarandeTidigareStyrelseVerkstallandeDirektorAbstract.active'
              )
            ),
            title: field(
              'Kommentar till pensionsförpliktelser för nuvarande samt tidigare styrelse och verkställande direktör'
            ),
            value: ixbrlField(field(''), {
              contextRef: 'period0',
              type: 'stringItemType',
              name: 'se-gen-base:PensionsforpliktelserNuvarandeTidigareStyrelseVerkstallandeDirektorKommentar',
              standardRubrik:
                'Kommentar till pensionsförpliktelser för nuvarande samt tidigare styrelse och verkställande direktör',
            }),
          },
      },
    LonerAndraErsattningarPubliktAktiebolagAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Löner och andra ersättningar, pensioner och liknande förmåner per befattningshavare för publikt aktiebolag'
      ),
      LonerAndraErsattningarPubliktAktiebolagTuple: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Löner och andra ersättningar, pensioner och liknande förmåner per befattningshavare för publikt aktiebolag'
        ),
        table: table<IxbrlCell>(
          'notes.note10.data.LonerAndraErsattningarPubliktAktiebolagAbstract.LonerAndraErsattningarPubliktAktiebolagTuple.table',
          {
            id: 'LonerAndraErsattningarPubliktAktiebolagBefattningshavareNamn',
            label: 'Befattningshavare',
            dataType: 'text',
            active: true,
          },
          {
            id: 'LonerAndraErsattningarPubliktAktiebolagBefattningshavareBefattning',
            label: 'Befattning',
            dataType: 'text',
            active: true,
          },
          {
            id: 'LonerAndraErsattningarPubliktAktiebolagBefattningshavareLonAnnanErsattning',
            label: 'Lön och andra ersättningar',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'LonerAndraErsattningarPubliktAktiebolagPensionskostnad',
            label: 'Pensionskostnad',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'LonerAndraErsattningarPubliktAktiebolagPensionsforpliktelse',
            label: 'Pensionsförpliktelse',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'LonerAndraErsattningarPubliktAktiebolagTuple',
                'hidden',
                undefined,
                undefined,
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  '1',
                  'row',
                  ixbrlCell(value('Befattningshavare'), {
                    type: 'stringItemType',
                    name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagBefattningshavareNamn',
                    contextRef: 'period0',
                    standardRubrik:
                      'Namn på befattningshavare i publikt aktiebolag',
                    negateValue: false,
                    tuple: {
                      name: 'se-gaap-ext:LonerAndraErsattningarPubliktAktiebolagTuple',
                      id: `${rows.getBaseId()}.1.tuple`,
                      tupleID: `${rows.getBaseId()}.1.tuple`,
                    },
                    tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value('Befattning'), {
                    type: 'stringItemType',
                    name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagBefattningshavareBefattning',
                    contextRef: 'period0',
                    standardRubrik:
                      'Benämning på befattning i publikt aktiebolag',
                    negateValue: false,
                    tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagBefattningshavareLonAnnanErsattning',
                    contextRef: 'period0',
                    standardRubrik:
                      'Lön och annan ersättning till befattningshavare i publikt aktiebolag',
                    saldo: 'debit',
                    negateValue: false,
                    tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagPensionskostnad',
                    contextRef: 'period0',
                    standardRubrik:
                      'Pensionskostnad för befattningshavare i publikt aktiebolag',
                    saldo: 'debit',
                    negateValue: false,
                    tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagPensionsforpliktelse',
                    contextRef: 'period0',
                    standardRubrik:
                      'Pensionsförpliktelse för befattningshavare i publikt aktiebolag',
                    negateValue: false,
                    tupleRef: tupleRef(5, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  })
                );
                return rows.build();
              });
            rows.newRowTemplateGenerator((_id, baseId) => ({
              id: _id,
              active: true,
              type: 'row',
              cells: {
                LonerAndraErsattningarPubliktAktiebolagBefattningshavareNamn:
                  ixbrlCell(value('Befattningshavare'), {
                    type: 'stringItemType',
                    name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagBefattningshavareNamn',
                    contextRef: 'period0',
                    standardRubrik:
                      'Namn på befattningshavare i publikt aktiebolag',
                    negateValue: false,
                    tuple: {
                      name: 'se-gaap-ext:LonerAndraErsattningarPubliktAktiebolagTuple',
                      id: `${baseId}.${_id}.tuple`,
                      tupleID: `${baseId}.${_id}.tuple`,
                    },
                    tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }),
                LonerAndraErsattningarPubliktAktiebolagBefattningshavareBefattning:
                  ixbrlCell(value('Befattning'), {
                    type: 'stringItemType',
                    name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagBefattningshavareBefattning',
                    contextRef: 'period0',
                    standardRubrik:
                      'Benämning på befattning i publikt aktiebolag',
                    negateValue: false,
                    tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }),
                LonerAndraErsattningarPubliktAktiebolagBefattningshavareLonAnnanErsattning:
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagBefattningshavareLonAnnanErsattning',
                    contextRef: 'period0',
                    standardRubrik:
                      'Lön och annan ersättning till befattningshavare i publikt aktiebolag',
                    saldo: 'debit',
                    negateValue: false,
                    tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }),
                LonerAndraErsattningarPubliktAktiebolagPensionskostnad:
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagPensionskostnad',
                    contextRef: 'period0',
                    standardRubrik:
                      'Pensionskostnad för befattningshavare i publikt aktiebolag',
                    saldo: 'debit',
                    negateValue: false,
                    tupleRef: tupleRef(4, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }),
                LonerAndraErsattningarPubliktAktiebolagPensionsforpliktelse:
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagPensionsforpliktelse',
                    contextRef: 'period0',
                    standardRubrik:
                      'Pensionsförpliktelse för befattningshavare i publikt aktiebolag',
                    negateValue: false,
                    tupleRef: tupleRef(5, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }),
              },
            }));
            return rows.build();
          })
          .build(),
      },
      LonerAndraErsattningarPubliktAktiebolagPersonerBolagetsLedning: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Antalet personer i bolagets ledning för publikt aktiebolag'
        ),
        table: table<IxbrlCell>(
          'notes.note10.data.LonerAndraErsattningarPubliktAktiebolagAbstract.LonerAndraErsattningarPubliktAktiebolagPersonerBolagetsLedning.table',
          {
            id: 'LonerAndraErsattningarPubliktAktiebolagPersonerBolagetsLedning',
            label: '',
            dataType: 'text',
            active: true,
          },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) ?? '',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) ?? '',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'LonerAndraErsattningarPubliktAktiebolagPersonerBolagetsLedning',
                'hidden',
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  'LonerAndraErsattningarPubliktAktiebolagPersonerBolagetsLedning',
                  'row',
                  noteLabel(
                    'Antalet personer i bolagets ledning för publikt aktiebolag'
                  ),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period0',
                    type: 'decimalItemType',
                    unitRef: 'antal-anstallda',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagPersonerBolagetsLedning',
                    standardRubrik:
                      'Antalet personer i bolagets ledning för publikt aktiebolag',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period1',
                    type: 'decimalItemType',
                    unitRef: 'antal-anstallda',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagPersonerBolagetsLedning',
                    standardRubrik:
                      'Antalet personer i bolagets ledning för publikt aktiebolag',
                    negateValue: false,
                  })
                );
                rows.addRowWithType(
                  'AntalPersonerOmfattasForpliktelserKostnaderAvseendePensionerPubliktBolag',
                  'row',
                  noteLabel(
                    'Antalet personer som omfattas av förpliktelser och kostnader avseende pensioner i publikt aktiebolag'
                  ),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans0',
                    type: 'decimalItemType',
                    unitRef: 'antal-anstallda',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:AntalPersonerOmfattasForpliktelserKostnaderAvseendePensionerPubliktBolag',
                    standardRubrik:
                      'Antalet personer som omfattas av förpliktelser och kostnader avseende pensioner i publikt aktiebolag',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans1',
                    type: 'decimalItemType',
                    unitRef: 'antal-anstallda',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:AntalPersonerOmfattasForpliktelserKostnaderAvseendePensionerPubliktBolag',
                    standardRubrik:
                      'Antalet personer som omfattas av förpliktelser och kostnader avseende pensioner i publikt aktiebolag',
                    negateValue: false,
                  })
                );
                return rows.build();
              });
            return rows.build();
          })
          .build(),
        LonerAndraErsattningarPubliktAktiebolagKommentar: {
          type: 'group',
          active: true,
          level: 0,
          title: field(
            'Kommentar till löner och andra ersättningar, pensioner och liknande förmåner per befattningshavare för publikt aktiebolag'
          ),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagKommentar',
            standardRubrik:
              'Kommentar till löner och andra ersättningar, pensioner och liknande förmåner per befattningshavare för publikt aktiebolag',
          }),
        },
      },
    },
    AvtalVasentligasteVillkorAvgangsvederlagPersonerForetagetsLedningsgrupp: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Avtal om avgångsvederlag och väsentligaste villkoren i avtalen'
      ),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:AvtalVasentligasteVillkorAvgangsvederlagPersonerForetagetsLedningsgrupp',
        standardRubrik:
          'Avtal om avgångsvederlag och väsentligaste villkoren i avtalen',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2010K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        MedelantaletAnstalldaAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            MedelantaletAnstalldaKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        LanderfordelningMedelantaletAnstalldaAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            LanderfordelningMedelantaletAnstalldaTuple: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                table: { type: 'table' as const },
              },
            },

            LanderfordelningAnstalldaKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        FordelningStyrelseLedandeBefattningshavareAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            FordelningStyrelseledamoterAbstract: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                table: { type: 'table' as const },
              },
            },

            FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAbstract:
              {
                type: 'part' as const,
                children: {
                  type: type,
                  level: number,
                  active: boolean,

                  title: cell,

                  table: { type: 'table' as const },

                  FordelningStyrelseLedandeBefattningshavareKommentar: {
                    type: 'part' as const,
                    children: {
                      type: type,
                      level: number,
                      active: boolean,

                      title: cell,

                      value: cell,
                    },
                  },
                },
              },
          },
        },

        LonerAndraErsattningarAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            LonerAndraErsattningarKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        SocialaKostnaderInklusivePensionskostnaderAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            SocialaKostnaderKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        PensionsforpliktelserNuvarandeTidigareStyrelseVerkstallandeDirektorAbstract:
          {
            type: 'part' as const,
            children: {
              type: type,
              level: number,
              active: boolean,

              title: cell,

              table: { type: 'table' as const },

              PensionsforpliktelserNuvarandeTidigareStyrelseVerkstallandeDirektorKommentar:
                {
                  type: 'part' as const,
                  children: {
                    type: type,
                    level: number,
                    active: boolean,
                    visible: cell,

                    title: cell,

                    value: cell,
                  },
                },
            },
          },

        LonerAndraErsattningarPubliktAktiebolagAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            LonerAndraErsattningarPubliktAktiebolagTuple: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                table: { type: 'table' as const },
              },
            },

            LonerAndraErsattningarPubliktAktiebolagPersonerBolagetsLedning: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                table: { type: 'table' as const },

                LonerAndraErsattningarPubliktAktiebolagKommentar: {
                  type: 'part' as const,
                  children: {
                    type: type,
                    level: number,
                    active: boolean,

                    title: cell,

                    value: cell,
                  },
                },
              },
            },
          },
        },

        AvtalVasentligasteVillkorAvgangsvederlagPersonerForetagetsLedningsgrupp:
          {
            type: 'part' as const,
            children: {
              type: type,
              level: number,
              active: boolean,

              title: cell,

              value: cell,
            },
          },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
