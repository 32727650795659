import {
  SpecificationRowType,
  SpecificationColumnType,
} from '_clients/types/types';

export const getColumnSum = (
  columnId: number,
  rows: SpecificationRowType[]
) => {
  let result: number | undefined;

  rows.forEach((row) => {
    const cell = row.cells.find((item) => item.columnId === columnId);

    if (!cell || typeof cell.value !== 'number') {
      return;
    }

    result = (result ?? 0) + cell.value;
  });

  return result;
};

export const getColumnsSum = (
  rows: SpecificationRowType[],
  columns: SpecificationColumnType[]
) => {
  const result: Record<number, number> = {};

  columns.forEach((column) => {
    if (column.contentType !== 'AMOUNT') {
      return;
    }
    const sum = getColumnSum(column.id, rows);

    if (sum !== undefined) {
      result[column.id] = sum;
    }
  });

  return result;
};
