import {
  AgoyTable,
  AgoyTableColumn,
  AgoyTableRow,
  AgoyTableRowGenerator,
  AgoyColumnTemplate,
  AccountValueType,
  Cell,
  ReferenceAccountInformation,
  Field,
  FieldProperties,
  ReferenceCell,
  isReferenceCell,
  createRow,
  TimePeriod,
  RowType,
} from '@agoy/document';
import { IncomeStatementType } from '../../document';
import { costDividedIncomeStatementK2v2Config } from '../../shares-company/income-statement/v2/k2/costdividedincomestatementk2v2';
import { costDividedIncomeStatementK3v2Config } from '../../shares-company/income-statement/v2/k3/costdividedincomestatementk3v2';
import { functionalIncomeStatementK3v2Config } from '../../shares-company/income-statement/v2/k3/functionalincomestatementk3v2';
import { NOTES_DIGITAL_K2 } from '../../../constants';

import {
  IxbrlField,
  IxbrlCell,
  IxbrlDataDefinition,
  order as tupleOrder,
} from '..';
import { AnnualReportType, AnnualReportVersion } from '../../document';

type ActiveReportType = Field | AgoyTable;
export const inactiveOnReportTypeK2 = <U extends ActiveReportType>(
  prop: U,
  reportType: AnnualReportType
): U => {
  return {
    ...prop,
    active: reportType !== 'k2',
  };
};

/**
 * Helper used to reduce ref cell to boolean value
 *
 * @param value bool, reference cell to convert to boolean
 * @returns undefined for refs not resolved to bool, otherwise passed in value
 */
export const isActive = (
  value: boolean | ReferenceCell | undefined
): boolean | undefined => {
  if (typeof value === 'object' && isReferenceCell(value)) {
    if (typeof value.value !== 'boolean') return undefined;
    return value.value;
  }

  return value;
};

export const activeReference = (field: Field, active: ReferenceCell): Field => {
  return {
    ...field,
    active,
  };
};

export const active = (field: Field): Field => ({ ...field, active: true });
export const inactive = (field: Field): Field => ({ ...field, active: false });

/**
 * Decorates a field with ixbrl data
 *
 * @param field Field to be decorated
 * @param ixbrl Ixbrl information for tagging of field
 * @returns decorated field
 */
export const ixbrlField = (
  field: Field,
  ixbrl: IxbrlDataDefinition
): IxbrlField => {
  return { ...field, ixbrl };
};

/**
 * Decorates a cell with ixbrl data
 *
 * @param cell Cell to be decorated
 * @param ixbrl Ixbrl information for tagging of cell
 * @returns decorated cell
 */
export const ixbrlCell = (
  cell: Cell,
  ixbrl: IxbrlDataDefinition | null
): IxbrlCell => {
  return ixbrl ? { ...cell, ixbrl } : cell;
};

export const field = (
  value?: string | number | undefined,
  props?: FieldProperties
): Field =>
  typeof value === 'string'
    ? { type: 'string', value, props }
    : { type: 'number', value, props };

export const row = (
  id: string,
  cells: AgoyTableRow['cells'],
  rows?: AgoyTableRow[],
  active?: boolean,
  type?: RowType
): AgoyTableRow => ({
  id,
  active: active === undefined ? true : active,
  cells,
  rows,
  type,
});

export interface TableBuilder<T extends Cell = Cell> {
  /**
   * Active toggle functionality on the whole table, defaults to true
   */
  setActive: (active: boolean | ReferenceCell) => TableBuilder<T>;
  /**
   * Returns an object (your table) containing your rows in one atribute and your columns in another
   * attribute. The columns are derived from the columns that you passed in to the table
   */
  build: () => AgoyTable<T>;
  /**
   * Used for building your rows. Pass a function to this funciton that you want to use to actually
   * build your rows. Your function will reviece the "RowsBuilder" as its first argument, check
   * the documentation of that method for understanding how to build rows.
   */
  addRows: (
    rows: (builder: RowsBuilder<T>) => AgoyTableRow<T>[]
  ) => TableBuilder<T>;
  newRowTemplate: (...cells: T[]) => TableBuilder<T>;
  newRowTemplateGenerator: (
    generator: AgoyTableRowGenerator<T>
  ) => TableBuilder<T>;
  newColumnTemplate: (
    column: (builder: ColumnBuilder<T>) => AgoyColumnTemplate<T>
  ) => TableBuilder<T>;
}

/**
 * Class for building a table
 *
 * TODO: Extend the table builder in @agoy/document
 *
 * @param baseId The ID you want to assign to the table
 * @param columnIds Column values
 */
export const table = <T extends Cell = Cell>(
  baseId: string,
  ...tableColumns: (string | AgoyTableColumn | undefined)[]
): TableBuilder<T> => {
  let tableRows: AgoyTableRow<T>[] = [];
  let newRowTemplate: AgoyTableRow<T> | AgoyTableRowGenerator<T> | undefined;
  let newColumnTemplate: AgoyColumnTemplate<T> | undefined;
  let tableActive: boolean | ReferenceCell | undefined;
  const rowActive = true;

  const columns: (AgoyTableColumn | undefined)[] = tableColumns.map((column) =>
    typeof column === 'string' ? { id: column } : column
  );

  const tableBuilder: TableBuilder<T> = {
    build: () => {
      const buildTable: AgoyTable<T> = {
        active: tableActive || true,
        columns: columns.filter(
          (col): col is AgoyTableColumn => col !== undefined
        ),
        rows: tableRows,
      };
      if (newRowTemplate) {
        buildTable.newRowTemplate = newRowTemplate;
      }
      if (newColumnTemplate) {
        buildTable.newColumnTemplate = newColumnTemplate;
      }
      if (tableActive) {
        buildTable.active = tableActive;
      }
      return buildTable;
    },
    addRows: (buildRows) => {
      tableRows = buildRows(rows(columns, baseId));
      return tableBuilder;
    },
    newRowTemplate: (...cells) => {
      newRowTemplate = createRow(columns, '', rowActive, cells);
      return tableBuilder;
    },
    newRowTemplateGenerator: (generator) => {
      newRowTemplate = generator;
      return tableBuilder;
    },
    newColumnTemplate: (columnTemplate) => {
      newColumnTemplate = columnTemplate(buildColumn());
      return tableBuilder;
    },
    setActive: (active: boolean | ReferenceCell) => {
      tableActive = active;
      return tableBuilder;
    },
  };

  return tableBuilder;
};

export interface RowsBuilder<T extends Cell = Cell> {
  /**
   * Returns the array containing the rows
   */
  build: () => AgoyTableRow<T>[];
  /**
   * Adds a row to your table. It will create an object representing a row and insert it into
   * the array that makes up the rows of the table.
   */
  addRow: (id: string, ...cells: (T | undefined)[]) => RowsBuilder<T>;
  /**
   * Adds a row with a type property to your table. It will create an object representing a row that has type and insert it into
   * the array that makes up the rows of the table.
   */
  addRowWithType: (
    id: string,
    type: RowType,
    ...cells: (T | undefined)[]
  ) => RowsBuilder<T>;

  /**
   * Adds a row to the management report table. It will create an object representing a row and insert it into
   * the array that makes up the rows of the table.
   */
  addRowManagementReport: (
    config:
      | {
          id: string;
          cells: (T | undefined)[];
          isRowActive: boolean | ReferenceCell;
        }
      | undefined
  ) => RowsBuilder<T>;

  /**
   * Adds rows inside the latest added row
   */
  addSubRows: (
    subRows: (b: RowsBuilder<T>) => AgoyTableRow<T>[]
  ) => RowsBuilder<T>;
  /**
   * setSortKey, set the sort key for the latest row.
   */
  setSortKey: (sortKey: number) => RowsBuilder<T>;
  /**
   * Sets a template for new rows in the latest added row
   */
  newRowTemplate: (...cells: (T | undefined)[]) => RowsBuilder<T>;
  /**
   * Sets a template with type property for new rows in the latest added row
   */
  newRowTemplateWithType: (
    type: RowType,
    ...cells: (T | undefined)[]
  ) => RowsBuilder<T>;

  /**
   * Sets a generator template for new rows in the last added row
   */
  newRowTemplateGenerator: (
    generator: AgoyTableRowGenerator<T>
  ) => RowsBuilder<T>;
  newRowTemplateBuilder: (
    subBuilder: (rows: RowsBuilder<T>) => RowsBuilder<T>
  ) => RowsBuilder<T>;
  /**
   * Sets active for added rows
   */
  addRowActive: (active: boolean | ReferenceCell) => RowsBuilder<T>;
  /**
   * Returns the id of the parent the rows are added to. Could be a table or another row.
   */
  getBaseId: () => string;
}

/**
 * Class for building table rows. When ran, returns "RowsBuilder". This object contains three
 * methods, "build", "addRow" and "addSubRow".
 */
export const rows = <T extends Cell = Cell>(
  columns: (AgoyTableColumn | undefined)[],
  baseId: string
): RowsBuilder<T> => {
  const result: AgoyTableRow<T>[] = [];
  let rowActive: boolean | ReferenceCell = true;

  const builder: RowsBuilder<T> = {
    build: () => result,
    addRow: (id, ...cells) => {
      result.push(createRow(columns, id, rowActive, cells));
      return builder;
    },
    addRowWithType: (id, type, ...cells) => {
      result.push(createRow(columns, id, rowActive, cells, type));
      return builder;
    },
    addRowManagementReport: (config) => {
      if (config) {
        const { id, cells, isRowActive } = config;
        result.push(createRow(columns, id, isRowActive, cells));
      }
      return builder;
    },
    addSubRows: (subRows) => {
      const lastRow = result[result.length - 1];
      result[result.length - 1] = {
        ...lastRow,
        rows: lastRow.rows
          ? [
              ...lastRow.rows,
              ...subRows(
                rows(columns, `${baseId}.${result[result.length - 1].id}`)
              ),
            ]
          : subRows(rows(columns, `${baseId}.${result[result.length - 1].id}`)),
      };
      return builder;
    },
    setSortKey: (sortKey) => {
      result[result.length - 1] = {
        ...result[result.length - 1],
        sortKey,
      };
      return builder;
    },
    newRowTemplate: (...cells) => {
      result[result.length - 1] = {
        ...result[result.length - 1],
        newRowTemplate: createRow(columns, '', rowActive, cells),
        rows: result[result.length - 1].rows || [],
      };
      return builder;
    },
    newRowTemplateWithType: (type, ...cells) => {
      result[result.length - 1] = {
        ...result[result.length - 1],
        newRowTemplate: createRow(columns, '', rowActive, cells, type),
        rows: result[result.length - 1].rows || [],
      };
      return builder;
    },
    newRowTemplateGenerator: (generator) => {
      result[result.length - 1] = {
        ...result[result.length - 1],
        newRowTemplate: generator,
        rows: result[result.length - 1].rows || [],
      };
      return builder;
    },
    newRowTemplateBuilder: (subBuilder) => {
      result[result.length - 1] = {
        ...result[result.length - 1],
        newRowTemplate: subBuilder(
          rows(columns, `${baseId}.${result[result.length - 1].id}`)
        ).build()[0],
        rows: result[result.length - 1].rows || [],
      };
      return builder;
    },
    getBaseId: () => baseId,
    addRowActive: (active) => {
      rowActive = active;
      return builder;
    },
  };
  return builder;
};

export interface ColumnBuilder<T extends Cell = Cell> {
  build: () => AgoyColumnTemplate<T>;
  setType: (type: 'string' | 'number') => ColumnBuilder<T>;
  addCell: (id: string, cell: T, columnId?: string) => ColumnBuilder<T>;
  cellGenerator: (
    generator: (columnId: string, rowId: string, fullRowId: string) => T
  ) => ColumnBuilder<T>;
}

export const buildColumn = <T extends Cell = Cell>(): ColumnBuilder<T> => {
  const result: AgoyColumnTemplate<T> = {
    type: 'number',
  };

  const builder: ColumnBuilder<T> = {
    build: () => result,
    setType: (type) => {
      result.type = type;
      return builder;
    },
    addCell: (id, cell, columnId) => {
      if (result.generator) {
        throw new Error('Cannot use addCell together with cellGenerator');
      }
      const cellTemplate: { id: string; cell: T; columnId?: string } = {
        id,
        cell,
      };
      // use template only for column with this id
      if (columnId) {
        cellTemplate.columnId = columnId;
      }
      if (!result.cells) {
        result.cells = [cellTemplate];
      } else {
        result.cells.push(cellTemplate);
      }
      return builder;
    },
    cellGenerator: (
      generator: (columnId: string, rowId: string, fullRowId: string) => T
    ): ColumnBuilder<T> => {
      if (result.cells) {
        throw new Error('Cannot use cellGenerator together with addCell');
      }
      result.generator = generator;
      return builder;
    },
  };

  return builder;
};

export const parentId = (id: string) => id.substring(0, id.lastIndexOf('.'));

/**
 * The purpose of this function is to look for a range of accounts
 * and if there is a value in one of those accounts,
 * the id of the specified note is returned.
 */
export const linkAccountsToNote = (
  accounts: Record<string, ReferenceAccountInformation>,
  first: number,
  last: number,
  noteNumber: number,
  isDigitalSubmission: boolean
): string => {
  if (isDigitalSubmission && !NOTES_DIGITAL_K2.includes(noteNumber)) {
    return '';
  }
  const result = range(first, last + 1).find((n) => {
    const accountNumber = n.toString();
    const accountInformation = accounts[accountNumber];
    if (accountInformation) {
      return true;
    }
  });
  return result ? `id(notes.note${noteNumber}.number)` : '';
};

export type AccountRange = [number, number];

/**
 * When modifier is not specified, default is 'ub' (outgoing balance)
 * noteAccountsOverride: To override the accounts that are used to link to a note
 */
export type SectionRow = {
  row: string;
  name: string;
  accounts: AccountRange[];
  noteNumber?: number;
  modifier?: AccountValueType;
  noteAccountsOverride?: AccountRange[];
};

export const getLinkToNote = (
  section: {
    accounts: AccountRange[];
    // TODO: when we need automatic referencing of K3 notes, we may need to pass a different argument here
    // In thise case we could maybe rename noteNumber to legacyK3orK2NoteNumber
    noteNumber?: number;
    // TODO: we may need a k3NoteNumber in the near future here as well
    noteAccountsOverride?: AccountRange[];
  },
  accounts: Record<string, ReferenceAccountInformation>,
  isDigitalSubmission = false,
  reportType?: AnnualReportType,
  documentTypeVersion?: AnnualReportVersion
): string | undefined => {
  let res: string | undefined = undefined;
  const accountRanges = section.noteAccountsOverride
    ? section.noteAccountsOverride
    : section.accounts;

  const AnnualReportVersion = documentTypeVersion;

  // To continue automatically referencing notes and supporting notes referenced in legacy manual K3 ARs
  if (reportType === 'k2' || AnnualReportVersion === '1') {
    if (section.noteNumber) {
      for (let i = 0; i < accountRanges.length; i++) {
        const [first, last] = accountRanges[i];

        const result = linkAccountsToNote(
          accounts,
          first,
          last,
          section.noteNumber,
          isDigitalSubmission
        );
        if (result !== '') {
          res = result;
          break;
        }
      }
    }
  }

  return res;
};

/**
 * Uses a range of data from start to end. Excludes end.
 */
export const range = (start: number, end: number): number[] => {
  return new Array(end - start).fill(0).map((_, i) => start + i);
};

export const tupleRef = (order: number, tupleRef: string) => ({
  order: tupleOrder(order),
  tupleRef,
});

export const getIncomeStatement = (
  incomeStatementType: IncomeStatementType,
  isK3Type: boolean,
  currentPeriod: TimePeriod,
  prevPeriod: TimePeriod | null,
  accountsData: Record<string, ReferenceAccountInformation>,
  version: AnnualReportVersion,
  reportType: AnnualReportType,
  accountsModifier: AccountValueType,
  isDigitalSubmission: boolean | null
) => {
  if (incomeStatementType === 'functional' && isK3Type) {
    return functionalIncomeStatementK3v2Config(
      accountsData,
      currentPeriod,
      prevPeriod,
      reportType,
      isDigitalSubmission,
      // always 1 here in sharesCompanyConfig
      version,
      accountsModifier
    );
  }

  if (incomeStatementType === 'cost' && isK3Type) {
    return costDividedIncomeStatementK3v2Config(
      accountsData,
      currentPeriod,
      prevPeriod,
      reportType,
      isDigitalSubmission,
      // always 1 here in sharesCompanyConfig
      version,
      accountsModifier
    );
  }

  return costDividedIncomeStatementK2v2Config(
    accountsData,
    currentPeriod,
    prevPeriod,
    reportType,
    isDigitalSubmission,
    // always 1 here in sharesCompanyConfig
    version,
    accountsModifier
  );
};
