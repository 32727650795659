import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Search } from '@material-ui/icons';

import Button from '_shared/components/Buttons/Button';
import { Input, InputContainerStyle } from '_shared/components/Inputs/v2/Input';
import {
  SpecificationColumnType,
  SpecificationRowType,
  SpecificationColumnContentType,
  SpecificationColumnsByCategory,
} from '_clients/types/types';
import Add from '@material-ui/icons/Add';
import { asResultClass, useApiSdk } from 'api-sdk';
import Drawer from '_shared/components/Drawer';

import Expandable from './Expandable';
import ColumnItem from './ColumnItem';
import AddColumnDialog from './AddColumnDialog';

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 50px;
  width: 100%;
  height: calc(100% - 48px);
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
`;

const ActionButtons = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(2)}px;
  width: 100%;
  border-top: 2px solid ${({ theme }) => theme.palette.border.light};
`;

const InputWrapper = styled.div`
  padding: 16px 8px 0 8px;
`;

const InputContainer = styled(InputContainerStyle)`
  position: relative;
  border: 1px solid ${({ theme }) => theme.palette.border.dark};
  padding-right: 32px;

  svg {
    position: absolute;
    right: 8px;
  }
`;

const HeaderButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const AddIcon = styled(Add)`
  width: 20px;
  height: 20px;
`;

interface ColumnSelectorProps {
  open: boolean;
  clientId: string;
  periodId: number;
  accountNumber: string;
  selectedColumns: SpecificationColumnType[];
  rows: SpecificationRowType[];
  onToggleColumn: (
    column: SpecificationColumnType,
    value: boolean
  ) => Promise<void>;
  onChangeColumn: (
    column: SpecificationColumnType,
    updatedField: Partial<SpecificationColumnType>
  ) => Promise<void>;
  onDeleteColumn: (id: number) => Promise<void>;
  onAddUserColumn: (
    name: string,
    contentType: SpecificationColumnContentType
  ) => Promise<void>;
  onClose: () => void;
}

const ColumnSelector = ({
  open,
  clientId,
  periodId,
  accountNumber,
  selectedColumns,
  rows,
  onToggleColumn,
  onChangeColumn,
  onDeleteColumn,
  onAddUserColumn,
  onClose,
}: ColumnSelectorProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const sdk = useApiSdk();

  const [columnsByCategory, setColumnsByCategory] = useState<
    SpecificationColumnsByCategory[]
  >([]);
  const [search, setSearch] = useState('');
  const [isAddColumnDialogOpen, setIsAddColumnDialogOpen] = useState(false);

  enum CategoryId {
    MANUAL = 4,
  }

  const getColumns = useCallback(async () => {
    const columns = await asResultClass(
      sdk.getGroupedSpecificationColumns({ clientid: clientId, periodId })
    );
    if (columns.ok) {
      setColumnsByCategory(columns.val.groupedByCategory);
    }
  }, [clientId, periodId, sdk]);

  useEffect(() => {
    getColumns();
  }, [getColumns]);

  const selectedColumnsId = useMemo(
    () => selectedColumns.map((col) => col.id),
    [selectedColumns]
  );

  const filteredColumns = useMemo(
    () =>
      columnsByCategory.map((item) => ({
        ...item,
        columns: item.columns.filter((col) =>
          col.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        ),
      })),
    [columnsByCategory, search]
  );

  const columnsWithData = useMemo(() => {
    const columns: number[] = [];

    rows.forEach((row) =>
      row.cells.forEach((cell) => {
        if (cell.value !== null && !columns.includes(cell.columnId)) {
          columns.push(cell.columnId);
        }
      })
    );

    return columns;
  }, [rows]);

  const handleChangeName = async (
    column: SpecificationColumnType,
    name: string
  ) => {
    await onChangeColumn(column, { name });
    getColumns();
  };

  const handleDeleteColumn = async (id: number) => {
    await onDeleteColumn(id);
    getColumns();
  };

  const handleAddColumnDialog = () => {
    setIsAddColumnDialogOpen(true);
  };

  const handleAddColumn = async (
    name: string,
    contentType: SpecificationColumnContentType
  ) => {
    await onAddUserColumn(name, contentType);
    setIsAddColumnDialogOpen(false);
    getColumns();
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      width="300px"
      headerTitle={`${formatMessage({ id: 'hidden.specification.editTable' })} - ${accountNumber}`}
    >
      <Container>
        <AddColumnDialog
          open={isAddColumnDialogOpen}
          onClose={() => setIsAddColumnDialogOpen(false)}
          onSubmit={handleAddColumn}
        />
        <Columns>
          <InputWrapper>
            <InputContainer>
              <Input
                value={search}
                placeholder={formatMessage({
                  id: 'hidden.specification.search.column',
                })}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Search />
            </InputContainer>
          </InputWrapper>

          {filteredColumns
            .filter((category) => category.id === CategoryId.MANUAL)
            .map((category) => {
              if (!category.columns.length && search.length > 0) {
                return null;
              }
              const showDescription =
                category.columns.length === 0 && search.length === 0;

              return (
                <Expandable
                  title={category.name}
                  desc={
                    showDescription
                      ? formatMessage({
                          id: 'hidden.specification.editColumn.manualDescription',
                        })
                      : ''
                  }
                  headerEndContent={
                    <HeaderButton onClick={handleAddColumnDialog}>
                      <AddIcon />
                    </HeaderButton>
                  }
                  key={category.id}
                  defaultExpanded
                >
                  {category.columns.map((column) => (
                    <ColumnItem
                      key={column.id}
                      column={column}
                      withData={columnsWithData.includes(column.id)}
                      checked={selectedColumnsId.includes(column.id)}
                      isManual={true}
                      onChangeName={handleChangeName}
                      onDelete={handleDeleteColumn}
                      onCheck={onToggleColumn}
                    />
                  ))}
                </Expandable>
              );
            })}

          {filteredColumns
            .filter((category) => category.id !== CategoryId.MANUAL)
            .map((category) => {
              if (!category.columns.length) {
                return null;
              }
              return (
                <Expandable
                  title={category.name}
                  key={category.id}
                  defaultExpanded
                >
                  {category.columns.map((column) => (
                    <ColumnItem
                      key={column.id}
                      column={column}
                      withData={columnsWithData.includes(column.id)}
                      checked={selectedColumnsId.includes(column.id)}
                      isManual={false}
                      onChangeName={handleChangeName}
                      onDelete={handleDeleteColumn}
                      onCheck={onToggleColumn}
                    />
                  ))}
                </Expandable>
              );
            })}
        </Columns>
        <ActionButtons>
          <Button
            label={formatMessage({ id: 'hidden.specification.close' })}
            size="medium"
            onClick={onClose}
          />
        </ActionButtons>
      </Container>
    </Drawer>
  );
};

export default ColumnSelector;
