/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-hb-kb-arsredovisning-2024-09-12_sv - Noter.csv
 * - Comments: nan
 */

import {
  value,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2001K2v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2001K2v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Redovisningsprinciper'),
  data: {
    active: true,
    RedovisningsVarderingsprinciper: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Immateriella anläggningstillgångar'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsVarderingsprinciper',
        standardRubrik: 'Redovisnings- och värderingsprinciper',
      }),
    },
    RedovisningsprinciperImmateriellaAnlaggningstillgangar: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Avskrivning'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperImmateriellaAnlaggningstillgangar',
        standardRubrik:
          'Redovisningsprinciper för immateriella anläggningstillgångar',
      }),
      RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar: {
        type: 'group',
        active: true,
        level: 1,
        title: field(
          'Koncessioner, patent, licenser, varumärken samt liknande rättigheter'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar',
          standardRubrik:
            'Redovisningsprinciper för avskrivningar immateriella anläggningstillgångar',
        }),
        RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar: {
          type: 'group',
          active: true,
          level: 1,
          title: field(
            'Koncessioner, patent, licenser, varumärken samt liknande rättigheter'
          ),
          table: table<IxbrlCell>(
            'notes.note1.data.RedovisningsprinciperImmateriellaAnlaggningstillgangar.RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar.RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar.table',
            {
              id: 'AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
              label: 'Procent',
              dataType: 'text',
              active: true,
              canToggle: true,
            },
            {
              id: 'AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
              label: 'År',
              dataType: 'numeric',
              active: true,
              canToggle: true,
            },
            {
              id: 'AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
              label: 'Hyresrätter och liknande rättigheter',
              dataType: 'text',
              active: true,
            },
            {
              id: 'AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
              label: 'Goodwill',
              dataType: 'text',
              active: true,
            },
            {
              id: 'AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
              label: 'Kommentar',
              dataType: 'text',
              active: true,
            },
            {
              id: 'AvskrivningarImmateriellaAnlaggningstillgangarKommentar',
              label: 'Materiella anläggningstillgångar',
              dataType: 'text',
              active: true,
            }
          )
            .addRows((rows) => {
              rows
                .addRowWithType(
                  'RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar',
                  'hidden',
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipImmateriellaAnlaggningstillgangarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterTuple',
                      'header',
                      noteLabel('Typ av tillgång'),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(
                          value('Hyresrätter och liknande rättigheter'),
                          {
                            type: 'stringItemType',
                            name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                            contextRef: 'period0',
                            standardRubrik:
                              'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                            negateValue: false,
                            tupleRef: tupleRef(
                              3,
                              `${rows.getBaseId()}.1.tuple`
                            ),
                            previousYearTuple: false,
                          }
                        )
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipImmateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(
                          value('Hyresrätter och liknande rättigheter'),
                          {
                            type: 'stringItemType',
                            name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                            contextRef: 'period0',
                            standardRubrik:
                              'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                            negateValue: false,
                            tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                            previousYearTuple: false,
                          }
                        ),
                    },
                  }));
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipImmateriellaAnlaggningstillgangarHyresratterLiknandeRattigheterTuple',
                      'header',
                      noteLabel('Typ av tillgång'),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarHyresratterLiknandeRattigheterTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Goodwill'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipImmateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarHyresratterLiknandeRattigheterTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('Goodwill'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipImmateriellaAnlaggningstillgangarGoodwillTuple',
                      'header',
                      noteLabel('Typ av tillgång'),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarGoodwillTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Kommentar'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Materiella anläggningstillgångar'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningarImmateriellaAnlaggningstillgangarKommentar',
                          contextRef: 'period0',
                          standardRubrik:
                            'Kommentar till avskrivningsprinciper för immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipImmateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarGoodwillTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('Kommentar'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningarImmateriellaAnlaggningstillgangarKommentar:
                        ixbrlCell(value('Materiella anläggningstillgångar'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningarImmateriellaAnlaggningstillgangarKommentar',
                          contextRef: 'period0',
                          standardRubrik:
                            'Kommentar till avskrivningsprinciper för immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(4, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  return rows.build();
                });
              return rows.build();
            })
            .build(),
        },
        RedovisningsprinciperMateriellaAnlaggningstillgangar: {
          type: 'group',
          active: true,
          level: 0,
          title: field('Avskrivning'),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:RedovisningsprinciperMateriellaAnlaggningstillgangar',
            standardRubrik:
              'Redovisningsprinciper materiella anläggningstillgångar',
          }),
          RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar: {
            type: 'group',
            active: true,
            level: 1,
            title: field('Byggnader'),
            value: ixbrlField(field(''), {
              contextRef: 'period0',
              type: 'stringItemType',
              name: 'se-gen-base:RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar',
              standardRubrik:
                'Redovisningsprinciper för avskrivningar materiella anläggningstillgångar',
            }),
            RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar: {
              type: 'group',
              active: true,
              level: 1,
              title: field('Byggnader'),
              table: table<IxbrlCell>(
                'notes.note1.data.RedovisningsprinciperImmateriellaAnlaggningstillgangar.RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar.RedovisningsprinciperMateriellaAnlaggningstillgangar.RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar.RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar.table',
                {
                  id: 'AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                  label: 'Procent',
                  dataType: 'text',
                  active: true,
                  canToggle: true,
                },
                {
                  id: 'AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                  label: 'År',
                  dataType: 'numeric',
                  active: true,
                  canToggle: true,
                },
                {
                  id: 'AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                  label: 'Maskiner och andra tekniska anläggningar',
                  dataType: 'text',
                  active: true,
                },
                {
                  id: 'AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                  label: 'Inventarier, verktyg och installationer',
                  dataType: 'text',
                  active: true,
                },
                {
                  id: 'AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                  label: 'Förbättringsutgifter på annans fastighet',
                  dataType: 'text',
                  active: true,
                },
                {
                  id: 'AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                  label: 'Övriga materiella anläggningstillgångar',
                  dataType: 'text',
                  active: true,
                },
                {
                  id: 'AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                  label: 'Kommentar',
                  dataType: 'text',
                  active: true,
                }
              )
                .addRows((rows) => {
                  rows
                    .addRowWithType(
                      'RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar',
                      'hidden',
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows
                        .addRowWithType(
                          'AvskrivningsprincipMateriellaAnlaggningstillgangarByggnaderTuple',
                          'header',
                          noteLabel('Typ av tillgång'),
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined
                        )
                        .addSubRows((rows) => {
                          rows.addRowWithType(
                            '1',
                            'row',
                            ixbrlCell(value('Procent'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                              contextRef: 'period0',
                              standardRubrik:
                                'Benämning på materiell anläggningstillgång',
                              negateValue: false,
                              tuple: {
                                name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarByggnaderTuple',
                                id: `${rows.getBaseId()}.1.tuple`,
                                tupleID: `${rows.getBaseId()}.1.tuple`,
                              },
                              tupleRef: tupleRef(
                                1,
                                `${rows.getBaseId()}.1.tuple`
                              ),
                              previousYearTuple: false,
                            }),
                            ixbrlCell(value('År'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                              contextRef: 'period0',
                              standardRubrik:
                                'Avskrivningsprocent avseende materiella anläggningstillgångar',
                              negateValue: false,
                              tupleRef: tupleRef(
                                2,
                                `${rows.getBaseId()}.1.tuple`
                              ),
                              previousYearTuple: false,
                            }),
                            ixbrlCell(
                              value('Maskiner och andra tekniska anläggningar'),
                              {
                                type: 'stringItemType',
                                name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                                contextRef: 'period0',
                                standardRubrik:
                                  'Nyttjandeperiod avseende materiella anläggningstillgångar',
                                negateValue: false,
                                tupleRef: tupleRef(
                                  3,
                                  `${rows.getBaseId()}.1.tuple`
                                ),
                                previousYearTuple: false,
                              }
                            )
                          );
                          return rows.build();
                        });
                      rows.newRowTemplateGenerator((_id, baseId) => ({
                        id: _id,
                        active: true,
                        type: 'row',
                        cells: {
                          AvskrivningsprincipMateriellAnlaggningstillgangBenamning:
                            ixbrlCell(value('Procent'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                              contextRef: 'period0',
                              standardRubrik:
                                'Benämning på materiell anläggningstillgång',
                              negateValue: false,
                              tuple: {
                                name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarByggnaderTuple',
                                id: `${baseId}.${_id}.tuple`,
                                tupleID: `${baseId}.${_id}.tuple`,
                              },
                              tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                              previousYearTuple: false,
                            }),
                          AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent:
                            ixbrlCell(value('År'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                              contextRef: 'period0',
                              standardRubrik:
                                'Avskrivningsprocent avseende materiella anläggningstillgångar',
                              negateValue: false,
                              tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                              previousYearTuple: false,
                            }),
                          AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod:
                            ixbrlCell(
                              value('Maskiner och andra tekniska anläggningar'),
                              {
                                type: 'stringItemType',
                                name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                                contextRef: 'period0',
                                standardRubrik:
                                  'Nyttjandeperiod avseende materiella anläggningstillgångar',
                                negateValue: false,
                                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                                previousYearTuple: false,
                              }
                            ),
                        },
                      }));
                      rows
                        .addRowWithType(
                          'AvskrivningsprincipMateriellaAnlaggningstillgangarMaskinerAndraTekniskaAnlaggningarTuple',
                          'header',
                          noteLabel('Typ av tillgång'),
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined
                        )
                        .addSubRows((rows) => {
                          rows.addRowWithType(
                            '1',
                            'row',
                            ixbrlCell(value('Procent'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                              contextRef: 'period0',
                              standardRubrik:
                                'Benämning på materiell anläggningstillgång',
                              negateValue: false,
                              tuple: {
                                name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarMaskinerAndraTekniskaAnlaggningarTuple',
                                id: `${rows.getBaseId()}.1.tuple`,
                                tupleID: `${rows.getBaseId()}.1.tuple`,
                              },
                              tupleRef: tupleRef(
                                1,
                                `${rows.getBaseId()}.1.tuple`
                              ),
                              previousYearTuple: false,
                            }),
                            ixbrlCell(value('År'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                              contextRef: 'period0',
                              standardRubrik:
                                'Avskrivningsprocent avseende materiella anläggningstillgångar',
                              negateValue: false,
                              tupleRef: tupleRef(
                                2,
                                `${rows.getBaseId()}.1.tuple`
                              ),
                              previousYearTuple: false,
                            }),
                            ixbrlCell(
                              value('Inventarier, verktyg och installationer'),
                              {
                                type: 'stringItemType',
                                name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                                contextRef: 'period0',
                                standardRubrik:
                                  'Nyttjandeperiod avseende materiella anläggningstillgångar',
                                negateValue: false,
                                tupleRef: tupleRef(
                                  3,
                                  `${rows.getBaseId()}.1.tuple`
                                ),
                                previousYearTuple: false,
                              }
                            )
                          );
                          return rows.build();
                        });
                      rows.newRowTemplateGenerator((_id, baseId) => ({
                        id: _id,
                        active: true,
                        type: 'row',
                        cells: {
                          AvskrivningsprincipMateriellAnlaggningstillgangBenamning:
                            ixbrlCell(value('Procent'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                              contextRef: 'period0',
                              standardRubrik:
                                'Benämning på materiell anläggningstillgång',
                              negateValue: false,
                              tuple: {
                                name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarMaskinerAndraTekniskaAnlaggningarTuple',
                                id: `${baseId}.${_id}.tuple`,
                                tupleID: `${baseId}.${_id}.tuple`,
                              },
                              tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                              previousYearTuple: false,
                            }),
                          AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent:
                            ixbrlCell(value('År'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                              contextRef: 'period0',
                              standardRubrik:
                                'Avskrivningsprocent avseende materiella anläggningstillgångar',
                              negateValue: false,
                              tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                              previousYearTuple: false,
                            }),
                          AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod:
                            ixbrlCell(
                              value('Inventarier, verktyg och installationer'),
                              {
                                type: 'stringItemType',
                                name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                                contextRef: 'period0',
                                standardRubrik:
                                  'Nyttjandeperiod avseende materiella anläggningstillgångar',
                                negateValue: false,
                                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                                previousYearTuple: false,
                              }
                            ),
                        },
                      }));
                      rows
                        .addRowWithType(
                          'AvskrivningsprincipMateriellaAnlaggningstillgangarInventarierVerktygInstallationerTuple',
                          'header',
                          noteLabel('Typ av tillgång'),
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined
                        )
                        .addSubRows((rows) => {
                          rows.addRowWithType(
                            '1',
                            'row',
                            ixbrlCell(value('Procent'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                              contextRef: 'period0',
                              standardRubrik:
                                'Benämning på materiell anläggningstillgång',
                              negateValue: false,
                              tuple: {
                                name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarInventarierVerktygInstallationerTuple',
                                id: `${rows.getBaseId()}.1.tuple`,
                                tupleID: `${rows.getBaseId()}.1.tuple`,
                              },
                              tupleRef: tupleRef(
                                1,
                                `${rows.getBaseId()}.1.tuple`
                              ),
                              previousYearTuple: false,
                            }),
                            ixbrlCell(value('År'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                              contextRef: 'period0',
                              standardRubrik:
                                'Avskrivningsprocent avseende materiella anläggningstillgångar',
                              negateValue: false,
                              tupleRef: tupleRef(
                                2,
                                `${rows.getBaseId()}.1.tuple`
                              ),
                              previousYearTuple: false,
                            }),
                            ixbrlCell(
                              value('Förbättringsutgifter på annans fastighet'),
                              {
                                type: 'stringItemType',
                                name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                                contextRef: 'period0',
                                standardRubrik:
                                  'Nyttjandeperiod avseende materiella anläggningstillgångar',
                                negateValue: false,
                                tupleRef: tupleRef(
                                  3,
                                  `${rows.getBaseId()}.1.tuple`
                                ),
                                previousYearTuple: false,
                              }
                            )
                          );
                          return rows.build();
                        });
                      rows.newRowTemplateGenerator((_id, baseId) => ({
                        id: _id,
                        active: true,
                        type: 'row',
                        cells: {
                          AvskrivningsprincipMateriellAnlaggningstillgangBenamning:
                            ixbrlCell(value('Procent'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                              contextRef: 'period0',
                              standardRubrik:
                                'Benämning på materiell anläggningstillgång',
                              negateValue: false,
                              tuple: {
                                name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarInventarierVerktygInstallationerTuple',
                                id: `${baseId}.${_id}.tuple`,
                                tupleID: `${baseId}.${_id}.tuple`,
                              },
                              tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                              previousYearTuple: false,
                            }),
                          AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent:
                            ixbrlCell(value('År'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                              contextRef: 'period0',
                              standardRubrik:
                                'Avskrivningsprocent avseende materiella anläggningstillgångar',
                              negateValue: false,
                              tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                              previousYearTuple: false,
                            }),
                          AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod:
                            ixbrlCell(
                              value('Förbättringsutgifter på annans fastighet'),
                              {
                                type: 'stringItemType',
                                name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                                contextRef: 'period0',
                                standardRubrik:
                                  'Nyttjandeperiod avseende materiella anläggningstillgångar',
                                negateValue: false,
                                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                                previousYearTuple: false,
                              }
                            ),
                        },
                      }));
                      rows
                        .addRowWithType(
                          'AvskrivningsprincipMateriellaAnlaggningstillgangarForbattringsutgifterAnnansFastighetTuple',
                          'header',
                          noteLabel('Typ av tillgång'),
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined
                        )
                        .addSubRows((rows) => {
                          rows.addRowWithType(
                            '1',
                            'row',
                            ixbrlCell(value('Procent'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                              contextRef: 'period0',
                              standardRubrik:
                                'Benämning på materiell anläggningstillgång',
                              negateValue: false,
                              tuple: {
                                name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarForbattringsutgifterAnnansFastighetTuple',
                                id: `${rows.getBaseId()}.1.tuple`,
                                tupleID: `${rows.getBaseId()}.1.tuple`,
                              },
                              tupleRef: tupleRef(
                                1,
                                `${rows.getBaseId()}.1.tuple`
                              ),
                              previousYearTuple: false,
                            }),
                            ixbrlCell(value('År'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                              contextRef: 'period0',
                              standardRubrik:
                                'Avskrivningsprocent avseende materiella anläggningstillgångar',
                              negateValue: false,
                              tupleRef: tupleRef(
                                2,
                                `${rows.getBaseId()}.1.tuple`
                              ),
                              previousYearTuple: false,
                            }),
                            ixbrlCell(
                              value('Övriga materiella anläggningstillgångar'),
                              {
                                type: 'stringItemType',
                                name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                                contextRef: 'period0',
                                standardRubrik:
                                  'Nyttjandeperiod avseende materiella anläggningstillgångar',
                                negateValue: false,
                                tupleRef: tupleRef(
                                  3,
                                  `${rows.getBaseId()}.1.tuple`
                                ),
                                previousYearTuple: false,
                              }
                            )
                          );
                          return rows.build();
                        });
                      rows.newRowTemplateGenerator((_id, baseId) => ({
                        id: _id,
                        active: true,
                        type: 'row',
                        cells: {
                          AvskrivningsprincipMateriellAnlaggningstillgangBenamning:
                            ixbrlCell(value('Procent'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                              contextRef: 'period0',
                              standardRubrik:
                                'Benämning på materiell anläggningstillgång',
                              negateValue: false,
                              tuple: {
                                name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarForbattringsutgifterAnnansFastighetTuple',
                                id: `${baseId}.${_id}.tuple`,
                                tupleID: `${baseId}.${_id}.tuple`,
                              },
                              tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                              previousYearTuple: false,
                            }),
                          AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent:
                            ixbrlCell(value('År'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                              contextRef: 'period0',
                              standardRubrik:
                                'Avskrivningsprocent avseende materiella anläggningstillgångar',
                              negateValue: false,
                              tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                              previousYearTuple: false,
                            }),
                          AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod:
                            ixbrlCell(
                              value('Övriga materiella anläggningstillgångar'),
                              {
                                type: 'stringItemType',
                                name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                                contextRef: 'period0',
                                standardRubrik:
                                  'Nyttjandeperiod avseende materiella anläggningstillgångar',
                                negateValue: false,
                                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                                previousYearTuple: false,
                              }
                            ),
                        },
                      }));
                      rows
                        .addRowWithType(
                          'AvskrivningsprincipMateriellaAnlaggningstillgangarOvrigaMateriellaAnlaggningstillgangarTuple',
                          'header',
                          noteLabel('Typ av tillgång'),
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined
                        )
                        .addSubRows((rows) => {
                          rows.addRowWithType(
                            '1',
                            'row',
                            ixbrlCell(value('Procent'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                              contextRef: 'period0',
                              standardRubrik:
                                'Benämning på materiell anläggningstillgång',
                              negateValue: false,
                              tuple: {
                                name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarOvrigaMateriellaAnlaggningstillgangarTuple',
                                id: `${rows.getBaseId()}.1.tuple`,
                                tupleID: `${rows.getBaseId()}.1.tuple`,
                              },
                              tupleRef: tupleRef(
                                1,
                                `${rows.getBaseId()}.1.tuple`
                              ),
                              previousYearTuple: false,
                            }),
                            ixbrlCell(value('År'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                              contextRef: 'period0',
                              standardRubrik:
                                'Avskrivningsprocent avseende materiella anläggningstillgångar',
                              negateValue: false,
                              tupleRef: tupleRef(
                                2,
                                `${rows.getBaseId()}.1.tuple`
                              ),
                              previousYearTuple: false,
                            }),
                            ixbrlCell(value('Kommentar'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                              contextRef: 'period0',
                              standardRubrik:
                                'Nyttjandeperiod avseende materiella anläggningstillgångar',
                              negateValue: false,
                              tupleRef: tupleRef(
                                3,
                                `${rows.getBaseId()}.1.tuple`
                              ),
                              previousYearTuple: false,
                            })
                          );
                          return rows.build();
                        });
                      rows.newRowTemplateGenerator((_id, baseId) => ({
                        id: _id,
                        active: true,
                        type: 'row',
                        cells: {
                          AvskrivningsprincipMateriellAnlaggningstillgangBenamning:
                            ixbrlCell(value('Procent'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                              contextRef: 'period0',
                              standardRubrik:
                                'Benämning på materiell anläggningstillgång',
                              negateValue: false,
                              tuple: {
                                name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarOvrigaMateriellaAnlaggningstillgangarTuple',
                                id: `${baseId}.${_id}.tuple`,
                                tupleID: `${baseId}.${_id}.tuple`,
                              },
                              tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                              previousYearTuple: false,
                            }),
                          AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent:
                            ixbrlCell(value('År'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                              contextRef: 'period0',
                              standardRubrik:
                                'Avskrivningsprocent avseende materiella anläggningstillgångar',
                              negateValue: false,
                              tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                              previousYearTuple: false,
                            }),
                          AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod:
                            ixbrlCell(value('Kommentar'), {
                              type: 'stringItemType',
                              name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                              contextRef: 'period0',
                              standardRubrik:
                                'Nyttjandeperiod avseende materiella anläggningstillgångar',
                              negateValue: false,
                              tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                              previousYearTuple: false,
                            }),
                        },
                      }));
                      return rows.build();
                    });
                  return rows.build();
                })
                .build(),
            },
          },
          AvskrivningarMateriellaAnlaggningstillgangarKommentar: {
            type: 'group',
            active: true,
            level: 1,
            title: field('Tjänste- och entreprenaduppdrag'),
            value: ixbrlField(field(''), {
              contextRef: 'period0',
              type: 'stringItemType',
              name: 'se-gen-base:AvskrivningarMateriellaAnlaggningstillgangarKommentar',
              standardRubrik:
                'Kommentar till avskrivningsprinciper för materiella anläggningstillgångar',
            }),
          },
        },
        RedovisningsprinciperTjansteOchEntreprenaduppdrag: {
          type: 'group',
          active: true,
          level: 0,
          title: field('Anskaffningsvärde för egentillverkade varor'),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:RedovisningsprinciperTjansteOchEntreprenaduppdrag',
            standardRubrik:
              'Redovisningsprinciper tjänste- och entreprenaduppdrag',
          }),
        },
        RedovisningsprinciperAnskaffningsvardeEgentillverkadevaror: {
          type: 'group',
          active: true,
          level: 0,
          title: field('Bristande jämförbarhet vid ändrade principer'),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:RedovisningsprinciperAnskaffningsvardeEgentillverkadevaror',
            standardRubrik:
              'Redovisningsprinciper anskaffningsvärde för egentillverkade varor',
          }),
        },
        RedovisningsprinciperBristandeJamforbarhetAndradePrinciper: {
          type: 'group',
          active: true,
          level: 0,
          title: field('Upplysningar till resultaträkningen'),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:RedovisningsprinciperBristandeJamforbarhetAndradePrinciper',
            standardRubrik:
              'Bristande jämförbarhet vid ändrade redovisningsprinciper',
          }),
        },
        UpplysningarResultatrakningenAbstract: {
          type: 'group',
          active: true,
          level: 0,
          title: field('Nettoomsättning i resultaträkning i förkortad form'),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:UpplysningarResultatrakningenAbstract',
            standardRubrik: 'Upplysningar till resultaträkningen',
          }),
        },
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2001K2v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        RedovisningsVarderingsprinciper: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperImmateriellaAnlaggningstillgangar: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            value: cell,

            RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,

                RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar:
                  {
                    type: 'part' as const,
                    children: {
                      type: type,
                      level: number,
                      active: boolean,

                      title: cell,

                      table: { type: 'table' as const },
                    },
                  },

                RedovisningsprinciperMateriellaAnlaggningstillgangar: {
                  type: 'part' as const,
                  children: {
                    type: type,
                    level: number,
                    active: boolean,

                    title: cell,

                    value: cell,

                    RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar:
                      {
                        type: 'part' as const,
                        children: {
                          type: type,
                          level: number,
                          active: boolean,

                          title: cell,

                          value: cell,

                          RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar:
                            {
                              type: 'part' as const,
                              children: {
                                type: type,
                                level: number,
                                active: boolean,

                                title: cell,

                                table: { type: 'table' as const },
                              },
                            },
                        },
                      },

                    AvskrivningarMateriellaAnlaggningstillgangarKommentar: {
                      type: 'part' as const,
                      children: {
                        type: type,
                        level: number,
                        active: boolean,

                        title: cell,

                        value: cell,
                      },
                    },
                  },
                },

                RedovisningsprinciperTjansteOchEntreprenaduppdrag: {
                  type: 'part' as const,
                  children: {
                    type: type,
                    level: number,
                    active: boolean,

                    title: cell,

                    value: cell,
                  },
                },

                RedovisningsprinciperAnskaffningsvardeEgentillverkadevaror: {
                  type: 'part' as const,
                  children: {
                    type: type,
                    level: number,
                    active: boolean,

                    title: cell,

                    value: cell,
                  },
                },

                RedovisningsprinciperBristandeJamforbarhetAndradePrinciper: {
                  type: 'part' as const,
                  children: {
                    type: type,
                    level: number,
                    active: boolean,

                    title: cell,

                    value: cell,
                  },
                },

                UpplysningarResultatrakningenAbstract: {
                  type: 'part' as const,
                  children: {
                    type: type,
                    level: number,
                    active: boolean,

                    title: cell,

                    value: cell,
                  },
                },
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
