import React, { useCallback, useContext, useEffect } from 'react';
import Modal from '_shared/components/Modal/v2/Modal';
import Typography from '_shared/components/Typography/Typography';
import { useIntl } from 'react-intl';
import Button from '_shared/components/Buttons/Button';
import styled from '@emotion/styled';
import AgoyAppClientContext from 'utils/AgoyAppClient/AgoyAppClientContext';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(3)}px;

  padding-left: ${(props) => props.theme.spacing(1)}px;
  padding-right: ${(props) => props.theme.spacing(1)}px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${(props) => props.theme.spacing(1)}px;
`;

const Footer = (closeDialog) => {
  const { formatMessage } = useIntl();

  return (
    <ButtonContainer>
      <Button
        label={formatMessage({
          id: 'api.error.dialog.button.understand',
        })}
        color="primary"
        variant="contained"
        size="large"
        onClick={(e) => closeDialog()}
      />
    </ButtonContainer>
  );
};

export type DialogContent = {
  title: string;
  message: string;
};

export const getDialogContent = (
  formatMessage,
  errorCode: string | undefined
): DialogContent | undefined => {
  const undefinedMessage = 'undefined';

  if (!errorCode) {
    return undefined;
  }

  const content = {
    title: formatMessage({
      id: `api.error.${errorCode}.title`,
      defaultMessage: undefinedMessage,
    }),
    message: formatMessage({
      id: `api.error.${errorCode}.message`,
      defaultMessage: undefinedMessage,
    }),
  };

  if (
    content.title !== undefinedMessage &&
    content.message !== undefinedMessage
  ) {
    return content;
  }
  return undefined;
};

export const ApiErrorDialog = () => {
  const { formatMessage } = useIntl();

  const [isOpen, setIsOpen] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [message, setMessage] = React.useState('');

  const { errorCode, setErrorCode } = useContext(AgoyAppClientContext);

  const openDialog = useCallback((content: DialogContent) => {
    setTitle(content.title);
    setMessage(content.message);
    setIsOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setIsOpen(false);
    if (setErrorCode) {
      setErrorCode('');
    }
  }, [setErrorCode]);

  useEffect(() => {
    const content = getDialogContent(formatMessage, errorCode);
    if (content) {
      openDialog(content);
    }
  }, [errorCode, openDialog, closeDialog, formatMessage]);

  return (
    <Modal
      open={isOpen}
      onClose={closeDialog}
      title={title}
      footer={Footer(closeDialog)}
      size="medium"
    >
      <Content>
        <Typography variant="body1" margin="none">
          {message}
        </Typography>
      </Content>
    </Modal>
  );
};

export default ApiErrorDialog;
