import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import range from 'lodash-es/range';
import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';

import { useSelector, RootState } from 'redux/reducers';
import { initState } from '_shared/redux/actions';
import { AnnualReportDataServicePrintProvider } from '_annual-report/service/AnnualReportDataServiceContext';
import FinancialReportPrint from '_financial-report/components/FinancialReportView/FinancialReportPrint';
import ReconciliationPrint from '_reconciliation/components/ReconciliationView/ReconciliationPrint';
import ReconciliationPrintProvider from '_reconciliation/components/ReconciliationView/ReconciliationPrintProvider';
import { CoverLetterPrint } from '_tax/components/TaxDeclarationView/EditForm/DynamicForms/CoverLetter';
import { TaxSummaryPrint } from '_tax/components/TaxDeclarationView/EditForm/DynamicForms/TaxSummary/Index';
import TaxesPrintProvider from '_tax/service/TaxesPrintRovider';
import TaxesPrint from '_tax/service/TaxesPrint';
import TaxesFormPrint from '_tax/service/TaxesFormPrint';
import AnnualReportPrint from '_annual-report/components/AnnualReportView/AnnualReportPrint';
import PeriodReportPrint from '_annual-report/components/AnnualReportView/PeriodReportPrint';
import ConfirmationCertificatePrint from '_annual-report/components/AnnualReportView/ConfirmationCertificatePrint';
import AnnualGeneralMeetingPrint from '_annual-report/components/AnnualReportView/AnnualGeneralMeetingPrint';
import Route from '_shared/components/Route';
import PrintStateContext from './PrintStateContext';
import VoucherPrint from '../VoucherView/VoucherPrint';
import Page from './Page';
import useHistory from '../../hooks/useHistory';

const Document = styled.div`
  background-color: white;
`;

const portraitStyles = css`
  @media print {
    @page {
      size: A4 portrait;
      margin: 2.7cm 2.5cm 2cm 2.5cm;
    }
  }
`;

const landscapeStyles = css`
  @media print {
    @page {
      size: A4 landscape;
      margin: 1cm 1cm 1cm 1cm;
    }
  }
`;

const zeroMarginStyles = css`
  @media print {
    @page {
      margin: 32px 0;
    }
  }
`;

const getEmptyPages = (search: string): number => {
  const result = /emptyPages=(\d+)/.exec(search);
  if (result && result[1]) {
    return parseInt(result[1], 10);
  }
  return 0;
};

const PrintedDocument = () => {
  const dispatch = useDispatch();
  const { setState } = useContext(PrintStateContext);

  const history = useHistory();
  const { location } = history;

  const [pageNumbers, setPageNumbers] = useState<Record<string, number>>({});

  const { loggedIn } = useSelector((state) => state.user);
  const emptyPages: number = getEmptyPages(location.search);

  useEffect(() => {
    // @ts-ignore
    window.isPrintReady = true;
  }, []);

  const isLandscape = useCallback(() => {
    return location.pathname.includes('/yearend/print/financialReport');
  }, [location]);

  const excludeMargins = useCallback(() => {
    return (
      location.pathname.includes('/yearend/print/coverLetter') ||
      location.pathname.includes('/yearend/print/taxSummary')
    );
  }, [location]);

  const content = (
    <Document>
      <Global styles={isLandscape() ? landscapeStyles : portraitStyles} />
      {excludeMargins() && <Global styles={zeroMarginStyles} />}
      <Global
        styles={{
          html: {
            fontSize: 14,
          },
        }}
      />
      {range(emptyPages).map((p) => (
        <Page key={p}>Empty page</Page>
      ))}
      <Switch>
        <Route path="/yearend/print/annualReportWithCertificate">
          <AnnualReportDataServicePrintProvider>
            <AnnualReportPrint pageNumbers={pageNumbers} withCertificate />
          </AnnualReportDataServicePrintProvider>
        </Route>
        <Route path="/yearend/print/annualReport">
          <AnnualReportDataServicePrintProvider>
            <AnnualReportPrint pageNumbers={pageNumbers} />
          </AnnualReportDataServicePrintProvider>
        </Route>
        <Route path="/yearend/print/periodReport">
          <AnnualReportDataServicePrintProvider>
            <PeriodReportPrint pageNumbers={pageNumbers} />
          </AnnualReportDataServicePrintProvider>
        </Route>
        <Route path="/yearend/print/confirmationCertificate">
          <AnnualReportDataServicePrintProvider>
            <ConfirmationCertificatePrint />
          </AnnualReportDataServicePrintProvider>
        </Route>
        <Route path="/yearend/print/annualGeneralMeeting">
          <AnnualReportDataServicePrintProvider>
            <AnnualGeneralMeetingPrint />
          </AnnualReportDataServicePrintProvider>
        </Route>
        <Route path="/yearend/print/financialReport">
          <AnnualReportDataServicePrintProvider>
            <FinancialReportPrint pageNumbers={pageNumbers} />
          </AnnualReportDataServicePrintProvider>
        </Route>
        <Route path="/yearend/print/accounting">
          <ReconciliationPrintProvider>
            <ReconciliationPrint pageNumbers={pageNumbers} />
          </ReconciliationPrintProvider>
        </Route>
        <Route path="/yearend/print/taxes">
          <TaxesPrintProvider>
            <TaxesPrint pageNumbers={pageNumbers} />
          </TaxesPrintProvider>
        </Route>
        <Route path="/yearend/print/coverLetter">
          <CoverLetterPrint />
        </Route>
        <Route path="/yearend/print/taxSummary">
          <TaxSummaryPrint />
        </Route>
        <Route path="/yearend/print/taxForm">
          <TaxesFormPrint pageNumbers={pageNumbers} />
        </Route>
        <Route path="/yearend/print/voucher">
          <VoucherPrint />
        </Route>
      </Switch>
    </Document>
  );

  const setContent = (state: Partial<RootState>) => {
    if (!loggedIn) {
      // The printing service is not logged in, so only set state then to protect logged in users
      dispatch(initState(state));
      setState(state);
    } else {
      console.error('setContent while logged in');
    }
  };

  /**
   * Hook for printing, called by puppeteer to
   * be able to navigate with React Router history.
   *
   * @param path
   */
  const navigateTo = (path: string) => {
    console.log('navigateTo', path);
    history.push(path);
  };
  // These functions are used by puppeteer during the printing process

  // @ts-ignore
  window.setContent = setContent;
  // @ts-ignore
  window.setPageNumbers = setPageNumbers;
  // @ts-ignore
  window.navigateTo = navigateTo;

  return content;
};

export default PrintedDocument;
