import {
  ref,
  value,
  or,
  id,
  sum,
  account,
  multiply,
  div,
  label,
  msg,
} from '@agoy/document';
import { IxbrlDataDefinition, SectionRow } from '../../..';
import { AnnualReportType, ConfiguredFinancialYear } from '../../document';
import { IxbrlCell } from '../../common/types/ixbrl';

import { companyTaxPerYear } from '../../common/taxPercentagePerYear';
import {
  active,
  inactive,
  field,
  table,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../common/utils/util';
import managementReportStandardRubriker from './managementReportStandardRubriker.json';
import managementReportNegateValue from './managementReportNegateValue.json';
import { SharesCompanyManagementReport } from './types';
import { getWarningCell } from '../settings/settings';
import { v1ChangesInEquityK2OrTransitionK3toK2DigitalSubmission } from './changes-in-equity-tables/v1ChangesInEquityK2OrTransitionK3toK2DigitalSubmission';
import { version2ChangesInEquityK3 } from './changes-in-equity-tables/v2ChangesInEquityK3';
import { sharesCompanyIncomeStatementAccounts } from '../income-statement/v1/incomeStatement';
import { v2ChangesInEquityK2OrTransitionK3toK2 } from './changes-in-equity-tables/v2ChangesInEquityK2';
import { v1ChangesInEquityManualSubmission } from './changes-in-equity-tables/v1ChangesInEquityManualSubmission';

export const changesInEquityIxbrl = (
  tagName: string,
  contextRef = 'period0',
  saldo?: 'debit' | 'credit',
  standardRubrik?: string,
  negateValue?: boolean
): IxbrlDataDefinition => {
  const base: IxbrlDataDefinition = {
    name: `se-gen-base:${tagName}`,
    type: 'monetaryItemType',
    contextRef,
  };

  let retObj = base;
  if (saldo) {
    retObj = { ...retObj, saldo };
  }

  if (standardRubrik) {
    retObj = { ...retObj, standardRubrik };
  } else if (managementReportStandardRubriker[tagName]) {
    // If there is no standardRubrik provided as parameter, use default from dict
    standardRubrik = managementReportStandardRubriker[tagName];
    retObj = { ...retObj, standardRubrik };
  }

  if (negateValue) {
    retObj = { ...retObj, negateValue };
  } else if (managementReportNegateValue[tagName]) {
    // If there is no negateValue provided as parameter, use default from dict
    negateValue = managementReportNegateValue[tagName];
    retObj = { ...retObj, negateValue };
  }

  return retObj;
};

const getChangesInEquityTable = (
  isDigitalSubmission: boolean | null,
  transitionK3toK2: boolean,
  reportType: AnnualReportType,
  documentTypeVersion = '1'
) => {
  if (isDigitalSubmission) {
    return generateChangesInEquityTableDigitalSubmission(
      reportType,
      transitionK3toK2,
      documentTypeVersion
    );
  }

  if (reportType === 'k3' && documentTypeVersion === '2') {
    return version2ChangesInEquityK3();
  }

  if (reportType === 'k2' && documentTypeVersion === '2') {
    return v2ChangesInEquityK2OrTransitionK3toK2(reportType, transitionK3toK2);
  }

  return v1ChangesInEquityManualSubmission(reportType, transitionK3toK2);
};

const getChangesInEquityAccountStructure = (
  reportType: AnnualReportType,
  documentTypeVersion = '1',
  isDigitalSubmission: boolean | null
): SectionRow[] => {
  if (documentTypeVersion !== '1' && reportType === 'k3') {
    return [
      {
        row: 'incomingAmount',
        name: 'Belopp vid årets ingång',
        accounts: [
          [2081, 2081],
          [2085, 2087],
          [2089, 2089],
          [2096, 2097],
          [8999, 8999],
        ],
      },
      {
        row: 'balanceInNew',
        name: 'Balanseras i ny räkning',
        accounts: [[8999, 8999]],
      },
      {
        row: 'changeOfReserveFund',
        name: 'Förändring av reservfond',
        accounts: [[2086, 2086]],
      },
      {
        row: 'newSplit',
        name: 'Nyemission',
        accounts: [[2081, 2081]],
      },
      {
        row: 'reductionOfShareCapital',
        name: 'Minskning av aktiekapital',
        accounts: [[2081, 2081]],
      },
      {
        row: 'reductionOfPremiumFund',
        name: 'Minskning av bunden överkursfond',
        accounts: [[2087, 2087]],
      },
      {
        row: 'yearResult',
        name: 'Årets resultat',
        accounts: [[8999, 8999]],
      },
    ];
  }
  // manual submission K2 and Legacy K3
  if (!isDigitalSubmission) {
    return [
      {
        row: '1',
        name: 'Villkorade aktieägartillskott uppgår till',
        accounts: [[2093, 2093]],
      },
      {
        row: 'incomingAmount',
        name: 'Belopp vid årets ingång',
        accounts: [
          [2081, 2089],
          [2090, 2099],
        ],
      },
      {
        row: 'newSplit',
        name: 'Nyemission',
        accounts: [[2087, 2087]],
      },
      {
        row: 'yearResult',
        name: 'Årets resultat',
        accounts: [[2099, 2099]],
      },
    ];
  }

  if (isDigitalSubmission) {
    return [
      // Digital K2 and transitionK3toK2: IBs of only 2081 and 2099
      {
        row: 'incomingAmount',
        name: 'Belopp vid årets ingång',
        accounts: [
          [2081, 2081],
          [2099, 2099],
        ],
      },
      {
        row: 'newSplit',
        name: 'Nyemission',
        accounts: [[2081, 2081]],
      },
      {
        row: 'yearResult',
        name: 'Årets resultat',
        accounts: [[8999, 8999]],
      },
    ];
  }

  return [];
};

type SharesCompanyManagementReference =
  | 'multiYearOverview'
  | 'changesInEquity'
  | 'resultsDisposition';

export type SharesCompanyManagementReportStructure = {
  [key in SharesCompanyManagementReference]: SectionRow[];
};

export const sharesCompanyManagementReportAccountRange = (
  reportType: AnnualReportType,
  documentTypeVersion = '1',
  isDigitalSubmission: boolean | null
): SharesCompanyManagementReportStructure => {
  const incomeStatementStructure =
    sharesCompanyIncomeStatementAccounts(reportType);

  return {
    multiYearOverview: [
      {
        row: 'netto',
        name: 'Nettoomsättning',
        accounts: incomeStatementStructure.incomes[0].accounts,
      },
    ],
    changesInEquity: getChangesInEquityAccountStructure(
      reportType,
      documentTypeVersion,
      isDigitalSubmission
    ),
    resultsDisposition: [
      {
        row: '3',
        name: 'Fri överkursfond',
        accounts: [[2097, 2097]],
      },
      {
        row: '1',
        name: 'Balanserat resultat',
        accounts: [
          [2090, 2096],
          [2098, 2098],
        ],
      },
      {
        row: '2',
        name: 'Årets resultat',
        accounts: [[2099, 2099]],
      },
    ],
  };
};

/**
 * The definition of the table columns change depending on the report type and the transition from K3 to K2
 * The mapping of the columns in each row is also affected by this
 */
const generateChangesInEquityTableDigitalSubmission = (
  reportType: AnnualReportType,
  transitionK3toK2: boolean,
  documentTypeVersion = '1'
) => {
  if (reportType === 'k3') {
    return version2ChangesInEquityK3();
  }

  if (reportType === 'k2' && documentTypeVersion === '2') {
    return v2ChangesInEquityK2OrTransitionK3toK2(reportType, transitionK3toK2);
  }

  return v1ChangesInEquityK2OrTransitionK3toK2DigitalSubmission(
    reportType,
    transitionK3toK2
  );
};

export const managementReportConfig = (
  reportType: AnnualReportType,
  transitionK3toK2: boolean,
  isDigitalSubmission: boolean | null,
  financialYears: ConfiguredFinancialYear[],
  documentTypeVersion = '1'
): SharesCompanyManagementReport => {
  const multiYearOverviewIxbrl = (
    row: string,
    contextRef: string
  ): IxbrlDataDefinition => {
    switch (row) {
      case 'solidity':
        return {
          type: 'pureItemType',
          name: 'se-gen-base:Soliditet',
          contextRef,
        };
      case 'netto':
        return {
          type: 'monetaryItemType',
          name: 'se-gen-base:Nettoomsattning',
          contextRef,
          saldo: 'credit',
        };
      case 'brutto':
        return {
          type: 'monetaryItemType',
          name: 'se-gen-base:BruttoresultatKostnadsslagsindelad',
          contextRef,
          saldo: 'credit',
        };
      case 'forkortadBrutto':
        return {
          type: 'monetaryItemType',
          name: 'se-gen-base:BruttoresultatForkortadFunktionsindelad',
          contextRef,
          saldo: 'credit',
        };
      // AGOY-3724: BruttoresultatForkortadFunktionsindelad not supported by Agoy yet
      case 'operativeResult':
        return {
          type: 'monetaryItemType',
          name: 'se-gen-base:Rorelseresultat',
          contextRef,
          saldo: 'credit',
        };
      case 'operativeMargin':
        return {
          type: 'pureItemType',
          name: 'se-gen-base:Rorelsemarginal',
          contextRef,
        };
      case 'avkastningTotaltKapital':
        return {
          type: 'pureItemType',
          name: 'se-gen-base:AvkastningTotaltKapital',
          contextRef,
        };
      case 'avkastningSysselsattKapital':
        return {
          type: 'pureItemType',
          name: 'se-gen-base:AvkastningSysselsattKapital',
          contextRef,
        };
      case 'avkastningEgetKapital':
        return {
          type: 'pureItemType',
          name: 'se-gen-base:AvkastningEgetKapital',
          contextRef,
        };
      case 'result':
        return {
          type: 'monetaryItemType',
          name: 'se-gen-base:ResultatEfterFinansiellaPoster',
          contextRef,
          saldo: 'credit',
        };
      case 'balanceSheetTotal':
        return {
          type: 'monetaryItemType',
          name: 'se-gen-base:Tillgangar',
          contextRef,
          saldo: 'debit',
        };
      case 'kassalikviditet':
        return {
          type: 'pureItemType',
          name: 'se-gen-base:Kassalikviditet',
          contextRef,
        };
      case 'avgNumberOfEmployees':
        return {
          type: 'decimalItemType',
          name: 'se-gen-base:MedelantaletAnstallda',
          unitRef: 'antal-anstallda',
          contextRef,
        };
      default:
        throw new Error(`ixbrl not defined for multiYearOverview ${row}`);
    }
  };

  return {
    // Verksamheten for K2
    active: undefined,
    activities: {
      boardText: field(''),
      boardTextWithCeo: field(''),
      boardWithoutCeo: active(field('')),
      liquidatorText: field(''),
      currencyText:
        reportType === 'k2'
          ? ixbrlField(field(''), {
              type: 'enumerationItemType',
              name: 'se-cd-base:BeloppsformatList',
              contextRef: 'period0',
            })
          : ixbrlField(field(''), {
              type: 'stringItemType',
              name: 'se-cd-base:MatEnhet',
              contextRef: 'period0',
            }),
      heading: field(''),
      active: reportType === 'k2',
      activityLabel: field('Verksamheten'),
      generallyLabel: field('Allmänt om verksamheten'),
      generally: ixbrlField(
        active(
          msg('{activityText}\n\nBolaget har sitt säte i {city}.', {
            activityText: id('managementReport.hidden.activityText'),
            city: id('managementReport.hidden.city'),
          })
        ),
        {
          type: 'stringItemType',
          name: 'se-gen-base:AllmantVerksamheten',
          contextRef: 'period0',
        }
      ),
      // TODO: this field is not tagged. Should it be omitted in digital submission mode?
      accountant: inactive(
        field(
          '[namn, byrå], som är auktoriserad redovisnings-/lönekonsult (FAR / SRF), har anlitats för biträde med följande tjänster: bokföring, lön, bokslut och årsredovisning.'
        )
      ),
      eventsLabel: field('Väsentliga händelser under räkenskapsåret'),
      events: ixbrlField(active(field('')), {
        type: 'stringItemType',
        name: 'se-gen-base:VasentligaHandelserRakenskapsaret',
        contextRef: 'period0',
      }),
      sharesLabel: field('Egna aktier'),
      shares: ixbrlField(active(field('')), {
        type: 'stringItemType',
        name: 'se-gen-base:EgnaAktier',
        contextRef: 'period0',
      }),
      otherLabel: field('Övrigt'),
      other: active(field('')),
    },
    // Verksamheten for K3
    business: {
      boardText: field(''),
      businessLabel: field('Verksamheten'),
      currencyText:
        reportType === 'k2'
          ? ixbrlField(field(''), {
              type: 'enumerationItemType',
              name: 'se-cd-base:BeloppsformatList',
              contextRef: 'period0',
            })
          : ixbrlField(field(''), {
              type: 'stringItemType',
              name: 'se-cd-base:MatEnhet',
              contextRef: 'period0',
            }),
      heading: field(''),
      active: reportType === 'k3',
      conditionsAndEventsLabel: field(
        'Viktiga förhållanden och väsentliga händelser'
      ),
      conditionsAndEvents: ixbrlField(active(field('')), {
        type: 'stringItemType',
        name: 'se-gen-base:ViktigaForhallandenVasentligaHandelser',
        contextRef: 'period0',
      }),
      businessNatureLabel: field('Verksamhetens art och inriktning'),
      businessNature: ixbrlField(
        active(
          msg('{activityText}\n\nBolaget har sitt säte i {city}.', {
            activityText: id('managementReport.hidden.activityText'),
            city: id('managementReport.hidden.city'),
          })
        ),
        {
          type: 'stringItemType',
          name: 'se-gen-base:VerksamhetenArtInriktning',
          contextRef: 'period0',
        }
      ),
      businessChangesLabel: field('Viktiga förändringar i verksamheten'),
      businessChanges: ixbrlField(active(field('')), {
        type: 'stringItemType',
        name: 'se-gen-base:ViktigaForandringarVerksamheten',
        contextRef: 'period0',
      }),
      externalFactorsLabel: field(
        'Viktiga externa faktorer som påverkat företagets ställning och resultat'
      ),
      externalFactors: ixbrlField(active(field('')), {
        type: 'stringItemType',
        name: 'se-gen-base:ViktigaExternaFaktorerPaverkatForetagetStallningResultat',
        contextRef: 'period0',
      }),
      specialCircumstancesLabel: field(
        'Speciella omständigheter som i särskild grad berör företaget'
      ),
      specialCircumstances: ixbrlField(active(field('')), {
        type: 'stringItemType',
        name: 'se-gen-base:SpeciellaOmstandigheterBerorForetaget',
        contextRef: 'period0',
      }),
      mainBusinessOwnersLabel: field(
        'Uppgift om ägare som har mer än tio procent av antalet andelar eller röster i företaget'
      ),
      mainBusinessOwners: ixbrlField(active(field('')), {
        type: 'stringItemType',
        name: 'se-gen-base:UppgiftAgareMerAnTioProcentAndelarRoster',
        contextRef: 'period0',
      }),
      limitedPartnershipsLabel: field('Komplementär i kommanditbolag'),
      limitedPartnerships: ixbrlField(active(field('')), {
        type: 'stringItemType',
        name: 'se-gen-base:KomplementarKommanditbolag',
        contextRef: 'period0',
      }),
      ownershipChangesLabel: field('Väsentliga förändringar i ägarstrukturen'),
      ownershipChanges: ixbrlField(active(field('')), {
        type: 'stringItemType',
        name: 'se-gen-base:ForetagetTillamparInteFortlevnadsprincipen',
        contextRef: 'period0',
      }),
      noSurvivalLabel: field(
        'Företaget tillämpar inte längre fortlevnadsprincipen'
      ),
      noSurvival: ixbrlField(active(field('')), {
        type: 'stringItemType',
        name: 'se-gen-base:ForetagetTillamparInteFortlevnadsprincipen',
        contextRef: 'period0',
      }),
      importantConditionsAndEventsLabel: field(
        'Övriga viktiga förhållanden och väsentliga händelser'
      ),
      importantConditionsAndEvents: ixbrlField(active(field('')), {
        type: 'stringItemType',
        name: 'se-gen-base:OvrigaViktigaForhallandenVasentligaHandelser',
        contextRef: 'period0',
      }),
      controlBalanceSheetLabel: field('Kontrollbalansräkning'),
      controlBalanceSheet: ixbrlField(active(field('')), {
        type: 'stringItemType',
        name: 'se-gen-base:Kontrollbalansrakning',
        contextRef: 'period0',
      }),
    },
    viability: {
      active: !isDigitalSubmission,
      title: field('Bedömning av företagets fortlevnadsförmåga'),
      text: field(''),
    },
    futureDevelopment: {
      active: reportType === 'k3',
      text: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-gen-base:FramtidaUtvecklingVasentligaRiskerOsakerhetsfaktorer',
        contextRef: 'period0',
      }),
      title: field(
        'Förväntad framtida utveckling samt väsentliga risker och osäkerhetsfaktorer'
      ),
    },
    rAndD: {
      active: reportType === 'k3',
      text: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-gen-base:ForskningUtveckling',
        contextRef: 'period0',
      }),
      title: field('Forskning och utveckling'),
    },
    subsidiariesAbroad: {
      active: reportType === 'k3',
      text: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-gen-base:FilialerUtlandet',
        contextRef: 'period0',
      }),
      title: field('Filialer i utlandet'),
    },
    sustainabilityInfo: {
      active: reportType === 'k3',
      text: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-gen-base:Hallbarhetsupplysningar',
        contextRef: 'period0',
      }),
      title: field('Hållbarhetsupplysningar'),
    },
    sustainabilityReport: {
      active: reportType === 'k3',
      text: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-gen-base:Hallbarhetsrapport',
        contextRef: 'period0',
      }),
      title: field('Hållbarhetsrapport'),
    },
    permitActivities: {
      active: reportType === 'k3',
      text: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-gen-base:TillstandAnmalningspliktigVerksamhetMiljobalken',
        contextRef: 'period0',
      }),
      title: field(
        'Tillstånds- eller anmälningspliktig verksamhet enligt miljöbalken'
      ),
    },
    ownShares: {
      active: reportType === 'k3',
      text: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-gen-base:EgnaAktier',
        contextRef: 'period0',
      }),
      title: field('Egna aktier'),
    },
    useOfFinancialInstruments: {
      active: reportType === 'k3',
      text: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-gen-base:AnvandningFinansiellaInstrument',
        contextRef: 'period0',
      }),
      title: field('Användning av finansiella instrument'),
    },
    multiYearOverview: {
      active: true,
      multiYearLabel: field('Flerårsöversikt'),
      multiYearTextLabel: field('Kommentar flerårsöversikt'),
      multiYearText: ixbrlField(active(field('')), {
        type: 'stringItemType',
        name: 'se-gen-base:KommentarFlerarsoversikt',
        contextRef: 'period0',
      }),
      table: table<IxbrlCell>(
        'managementReport.multiYearOverview.table',
        { id: 'label', label: '' },
        ...financialYears.map((year, index) => ({
          id: `period${index}`,
          label: year.label,
        })),
        { id: 'period0Warning' },
        { id: 'period1Warning' }
      )
        .addRows((rows) => {
          rows
            .addRow(
              'netto',
              label('Nettoomsättning'),
              ixbrlCell(
                ref(id('incomeStatement.Nettoomsattning.year')),
                multiYearOverviewIxbrl('netto', 'period0')
              ),
              ixbrlCell(
                ref(id('incomeStatement.Nettoomsattning.previousYear')),
                multiYearOverviewIxbrl('netto', 'period1')
              ),
              ...financialYears
                .slice(2)
                .map((year, index) =>
                  ixbrlCell(
                    value(undefined),
                    multiYearOverviewIxbrl('netto', `period${index + 2}`)
                  )
                )
            )
            .addRowActive(false)
            .addRow(
              'brutto',
              label(
                'Bruttoresultat i förkortad kostnadsslagsindelad resultaträkning'
              ),
              ixbrlCell(
                value(undefined),
                multiYearOverviewIxbrl('brutto', 'period0')
              ),
              ixbrlCell(
                value(undefined),
                multiYearOverviewIxbrl('brutto', 'period1')
              ),
              ...financialYears
                .slice(2)
                .map((year, index) =>
                  ixbrlCell(
                    value(undefined),
                    multiYearOverviewIxbrl('brutto', `period${index + 2}`)
                  )
                )
            );
          if (reportType === 'k3') {
            rows
              .addRowActive(false)
              .addRow(
                'forkortadBrutto',
                label(
                  'Bruttoresultat i förkortad funktionsindelad resultaträkning'
                ),
                ixbrlCell(
                  value(undefined),
                  multiYearOverviewIxbrl('forkortadBrutto', 'period0')
                ),
                ixbrlCell(
                  value(undefined),
                  multiYearOverviewIxbrl('forkortadBrutto', 'period1')
                ),
                ...financialYears
                  .slice(2)
                  .map((year, index) =>
                    ixbrlCell(
                      value(undefined),
                      multiYearOverviewIxbrl(
                        'forkortadBrutto',
                        `period${index + 2}`
                      )
                    )
                  )
              );
          }
          rows
            .addRow(
              'operativeResult',
              label('Rörelseresultat'),
              ixbrlCell(
                value(undefined),
                multiYearOverviewIxbrl('operativeResult', 'period0')
              ),
              ixbrlCell(
                value(undefined),
                multiYearOverviewIxbrl('operativeResult', 'period1')
              ),
              ...financialYears
                .slice(2)
                .map((year, index) =>
                  ixbrlCell(
                    value(undefined),
                    multiYearOverviewIxbrl(
                      'operativeResult',
                      `period${index + 2}`
                    )
                  )
                )
            )
            .addRowActive(true)
            .addRow(
              'result',
              label('Resultat efter finansiella poster'),
              ixbrlCell(
                ref(id('incomeStatement.ResultatEfterFinansiellaPoster.year')),
                multiYearOverviewIxbrl('result', 'period0')
              ),
              ixbrlCell(
                ref(
                  id(
                    'incomeStatement.ResultatEfterFinansiellaPoster.previousYear'
                  )
                ),
                multiYearOverviewIxbrl('result', 'period1')
              ),
              ...financialYears
                .slice(2)
                .map((year, index) =>
                  ixbrlCell(
                    value(undefined),
                    multiYearOverviewIxbrl('result', `period${index + 2}`)
                  )
                ),
              getWarningCell(`${rows.getBaseId()}.result.period0`),
              getWarningCell(`${rows.getBaseId()}.result.period1`)
            )
            .addRowActive(false)
            .addRow(
              'operativeMargin',
              label('Rörelsemarginal (%)'),
              ixbrlCell(
                value(undefined),
                multiYearOverviewIxbrl('operativeMargin', 'period0')
              ),
              ixbrlCell(
                value(undefined),
                multiYearOverviewIxbrl('operativeMargin', 'period1')
              ),
              ...financialYears
                .slice(2)
                .map((year, index) =>
                  ixbrlCell(
                    value(undefined),
                    multiYearOverviewIxbrl(
                      'operativeMargin',
                      `period${index + 2}`
                    )
                  )
                )
            )
            .addRow(
              'avkastningTotaltKapital',
              label('Avkastning totalt kapital (%)'),
              ixbrlCell(
                value(undefined),
                multiYearOverviewIxbrl('avkastningTotaltKapital', 'period0')
              ),
              ixbrlCell(
                value(undefined),
                multiYearOverviewIxbrl('avkastningTotaltKapital', 'period1')
              ),
              ...financialYears
                .slice(2)
                .map((year, index) =>
                  ixbrlCell(
                    value(undefined),
                    multiYearOverviewIxbrl(
                      'avkastningTotaltKapital',
                      `period${index + 2}`
                    )
                  )
                )
            )
            .addRow(
              'avkastningSysselsattKapital',
              label('Avkastning sysselsatt kapital (%)'),
              ixbrlCell(
                value(undefined),
                multiYearOverviewIxbrl('avkastningSysselsattKapital', 'period0')
              ),
              ixbrlCell(
                value(undefined),
                multiYearOverviewIxbrl('avkastningSysselsattKapital', 'period1')
              ),
              ...financialYears
                .slice(2)
                .map((year, index) =>
                  ixbrlCell(
                    value(undefined),
                    multiYearOverviewIxbrl(
                      'avkastningSysselsattKapital',
                      `period${index + 2}`
                    )
                  )
                )
            )
            .addRow(
              'avkastningEgetKapital',
              label('Avkastning eget kapital (%)'),
              ixbrlCell(
                value(undefined),
                multiYearOverviewIxbrl('avkastningEgetKapital', 'period0')
              ),
              ixbrlCell(
                value(undefined),
                multiYearOverviewIxbrl('avkastningEgetKapital', 'period1')
              ),
              ...financialYears
                .slice(2)
                .map((year, index) =>
                  ixbrlCell(
                    value(undefined),
                    multiYearOverviewIxbrl(
                      'avkastningEgetKapital',
                      `period${index + 2}`
                    )
                  )
                )
            )
            .addRowActive(true)
            .addRow(
              'balanceSheetTotal',
              label('Balansomslutning'),
              ixbrlCell(
                ref(id('balanceSheet.Tillgangar.year')),
                multiYearOverviewIxbrl('balanceSheetTotal', 'balans0')
              ),
              ixbrlCell(
                ref(id('balanceSheet.Tillgangar.previousYear')),
                multiYearOverviewIxbrl('balanceSheetTotal', 'balans1')
              ),
              ...financialYears
                .slice(2)
                .map((year, index) =>
                  ixbrlCell(
                    value(undefined),
                    multiYearOverviewIxbrl(
                      'balanceSheetTotal',
                      `balans${index + 2}`
                    )
                  )
                )
            )
            .addRowActive(false)
            .addRow(
              'kassalikviditet',
              label('Kassalikviditet (%)'),
              ixbrlCell(
                value(undefined),
                multiYearOverviewIxbrl('kassalikviditet', 'balans0')
              ),
              ixbrlCell(
                value(undefined),
                multiYearOverviewIxbrl('kassalikviditet', 'balans1')
              ),
              ...financialYears
                .slice(2)
                .map((year, index) =>
                  ixbrlCell(
                    value(undefined),
                    multiYearOverviewIxbrl(
                      'kassalikviditet',
                      `balans${index + 2}`
                    )
                  )
                )
            )
            .addRowActive(true)
            .addRow(
              'solidity',
              label('Soliditet (%)'),
              // ("Summa eget kapital" + (1 - (aktuell bolagsskatt)) * "summa obeskattade reserver")  / "Summa tillgångar"
              ixbrlCell(
                ref(
                  or(
                    div(
                      sum(
                        or(id('balanceSheet.EgetKapital.year'), 0),
                        multiply(
                          sum(
                            1,
                            multiply(
                              -1,
                              companyTaxPerYear[
                                financialYears[0].end.substring(0, 4)
                              ]
                            )
                          ),
                          or(id('balanceSheet.ObeskattadeReserver.year'), 0)
                        )
                      ),
                      id('balanceSheet.Tillgangar.year')
                    ),
                    0
                  )
                ),
                multiYearOverviewIxbrl('solidity', 'balans0')
              ),
              ixbrlCell(
                ref(
                  or(
                    div(
                      sum(
                        or(id('balanceSheet.EgetKapital.previousYear'), 0),
                        multiply(
                          sum(
                            1,
                            multiply(
                              -1,
                              companyTaxPerYear[
                                financialYears[1]?.end.substring(0, 4)
                              ]
                            )
                          ),
                          or(
                            id('balanceSheet.ObeskattadeReserver.previousYear'),
                            0
                          )
                        )
                      ),
                      id('balanceSheet.Tillgangar.previousYear')
                    ),
                    0
                  )
                ),
                multiYearOverviewIxbrl('solidity', 'balans1')
              ),
              ...financialYears
                .slice(2)
                .map((year, index) =>
                  ixbrlCell(
                    value(undefined),
                    multiYearOverviewIxbrl('solidity', `balans${index + 2}`)
                  )
                )
            )
            .addRowActive(false)
            .addRow(
              'avgNumberOfEmployees',
              label('Medelantalet anställda'),
              ixbrlCell(
                value(undefined),
                multiYearOverviewIxbrl('avgNumberOfEmployees', 'period0')
              ),
              ixbrlCell(
                value(undefined),
                multiYearOverviewIxbrl('avgNumberOfEmployees', 'period1')
              ),
              ...financialYears
                .slice(2)
                .map((year, index) =>
                  ixbrlCell(
                    value(undefined),
                    multiYearOverviewIxbrl(
                      'avgNumberOfEmployees',
                      `period${index + 2}`
                    )
                  )
                )
            );
          return rows.build();
        })
        .newRowTemplate(value(''), ...financialYears.map(() => value(0)))
        .build(),
    },
    definitionOfKey: {
      active: true,
      definitionOfKeyLabel: field('Nyckeltalsdefinitioner'),
      definitionOfKeyTable: table(
        'managementReport.definitionOfKey.definitionOfKeyTable',
        {
          id: 'label',
          label: '',
        }
      )
        .addRows((rows) =>
          rows
            .addRowActive(false)
            .addRow(
              'netSales',
              ixbrlCell(
                value(
                  'Nettoomsättning: Huvudintäkter, fakturerade kostnader, sidointäkter samt intäktskorrigeringar'
                ),
                {
                  type: 'stringItemType',
                  name: 'se-gen-base:Nyckeltalsdefinitioner',
                  contextRef: 'period0',
                }
              )
            )
            .addRowActive(false)
            .addRow(
              'operatingResult',
              ixbrlCell(
                value(
                  'Resultat efter finansiella poster: Resultat före bokslutsdispositioner och skatt'
                ),
                {
                  type: 'stringItemType',
                  name: 'se-gen-base:Nyckeltalsdefinitioner',
                  contextRef: 'period0',
                }
              )
            )
            .addRowActive(false)
            .addRow(
              'totalAssets',
              ixbrlCell(
                value(
                  'Balansomslutning: Bolagets tillgångar eller skulder inkluderat eget kapital'
                ),
                {
                  type: 'stringItemType',
                  name: 'se-gen-base:Nyckeltalsdefinitioner',
                  contextRef: 'period0',
                }
              )
            )
            .addRowActive(false)
            .addRow(
              '1',
              ixbrlCell(
                value(
                  'Soliditet: Justerat eget kapital i procent av balansomslutning'
                ),
                {
                  type: 'stringItemType',
                  name: 'se-gen-base:Nyckeltalsdefinitioner',
                  contextRef: 'period0',
                }
              )
            )
            .addRowActive(false)
            .addRow(
              '2',
              ixbrlCell(
                value(
                  'Avkastning på eget kapital: Resultat efter finansiella poster i procent av genomsnittligt justerat eget kapital'
                ),
                {
                  type: 'stringItemType',
                  name: 'se-gen-base:Nyckeltalsdefinitioner',
                  contextRef: 'period0',
                }
              )
            )
            .addRowActive(false)
            .addRow(
              '3',
              ixbrlCell(
                value(
                  'Avkastning på totalt kapital: Rörelseresultat med tillägg för finansiella intäkter, i procent av genomsnittlig balansomslutning'
                ),
                {
                  type: 'stringItemType',
                  name: 'se-gen-base:Nyckeltalsdefinitioner',
                  contextRef: 'period0',
                }
              )
            )
            .addRowActive(false)
            .addRow(
              '4',
              ixbrlCell(
                value(
                  'Kassalikviditet: Omsättningstillgångar exklusive varulager i procent av kortfristiga fordringar'
                ),
                {
                  type: 'stringItemType',
                  name: 'se-gen-base:Nyckeltalsdefinitioner',
                  contextRef: 'period0',
                }
              )
            )
            .build()
        )
        .newRowTemplateGenerator((id) => ({
          id,
          active: false,
          cells: {
            label: ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-gen-base:Nyckeltalsdefinitioner',
              contextRef: 'period0',
            }),
          },
        }))
        .build(),
    },
    changesInEquity: {
      title: field(''),
      active: true,
      commentLabel: field('Kommentar'),
      comment: ixbrlField(active(field('')), {
        type: 'stringItemType',
        name: 'se-gen-base:ForandringEgetKapitalKommentar',
        contextRef: 'period0',
      }),
      table: getChangesInEquityTable(
        isDigitalSubmission,
        transitionK3toK2,
        reportType,
        documentTypeVersion
      ),
      conditionalShareholderContributions: table(
        'managementReport.changesInEquity.conditionalShareholderContributions',
        { id: 'label', label: '' },
        ...financialYears.map((year, index) => ({
          id: `period${index}`,
          label: year.label,
        }))
      )
        .addRows((rows) =>
          rows
            .addRowManagementReport({
              id: '1',
              cells: [
                value('Villkorade aktieägartillskott uppgår till'),
                ref(or(multiply(-1, account('2093')), 0)),
                ref(or(multiply(-1, account('2093', 'ib')), 0)),
                ...financialYears.slice(2).map(() => value(0)),
              ],
              isRowActive: ref(
                sum(
                  ...financialYears.map((year, index) =>
                    or(
                      id(
                        `managementReport.changesInEquity.conditionalShareholderContributions.1.period${index}`
                      ),
                      0
                    )
                  )
                )
              ),
            })
            .build()
        )
        .newRowTemplate(value(''), ...financialYears.map(() => value(0)))
        .build(),
    },

    resultsDisposition: {
      title: field(''),
      active: true,
      proposeHeader: field(''),
      propose: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-gen-base:DispositionerVinstForlustKommentar',
        contextRef: 'balans0',
      }),
      toDisposeHeader: active(
        field('Till årsstämmans förfogande står följande medel:')
      ),
      toDispose: table(
        'managementReport.resultsDisposition.toDispose',
        'label',
        'value',
        'valueWarning'
      )
        .addRows((rows) => {
          rows
            // ids 3 and 4 come first here because it was added after 1 and 2 (chronologically)
            .addRowActive(false)
            .addRow(
              '3',
              value('Fri överkursfond'),
              ixbrlCell(ref(or(multiply(-1, account('2097')), 0)), {
                type: 'monetaryItemType',
                name: 'se-gen-base:Overkursfond',
                contextRef: 'balans0',
                saldo: 'credit',
              })
            );
          if (reportType === 'k3') {
            rows.addRowActive(false).addRow(
              '4',
              value('Fond för verkligt värde'),
              ixbrlCell(value(0), {
                type: 'monetaryItemType',
                name: 'se-gen-base:FondVerkligtVarde',
                contextRef: 'balans0',
                saldo: 'credit',
              })
            );
          }
          rows
            .addRowActive(true)
            .addRow(
              '1',
              value('Balanserat resultat'),
              ixbrlCell(ref(or(multiply(-1, account('2090:2096+2098')), 0)), {
                type: 'monetaryItemType',
                name: 'se-gen-base:BalanseratResultat',
                contextRef: 'balans0',
                saldo: 'credit',
              }),
              getWarningCell(`${rows.getBaseId()}.1.value`)
            )
            .addRow(
              '2',
              value('Årets resultat'),
              ixbrlCell(ref(or(multiply(-1, account('2099')), 0)), {
                type: 'monetaryItemType',
                name: 'se-gen-base:AretsResultatEgetKapital',
                contextRef: 'balans0',
                saldo: 'credit',
              }),
              getWarningCell(`${rows.getBaseId()}.2.value`)
            );
          return rows.build();
        })
        .newRowTemplate(value(''), value(0))
        .build(),
      customTotal: active(field('')),
      total: active(
        ixbrlField(
          ref(
            or(
              sum(id('managementReport.resultsDisposition.toDispose.*.value')),
              0
            )
          ),
          {
            type: 'monetaryItemType',
            name: 'se-gen-base:FrittEgetKapital',
            contextRef: 'balans0',
            saldo: 'credit',
          }
        )
      ),
      totalWarning:
        getWarningCell('managementReport.resultsDisposition.total') ||
        value(''),
      extraordinaryMeetingDividendHeader: field(''),
      extraordinaryMeetingDividend: table(
        'managementReport.resultsDisposition.extraordinaryMeetingDividend',
        'label',
        'value'
      )
        .addRows((rows) =>
          rows
            .addRowActive(false)
            .addRow(
              'extraordinaryMeetingDividend',
              value('Efterutdelning'),
              ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                name: 'se-gen-base:UtdelningBeslutadExtraBolagsstamma',
                contextRef: 'balans0',
                saldo: 'debit',
              })
            )
            .build()
        )
        .newRowTemplate(value(''), value(0))
        .build(),
      extraordinaryMeetingDividendTotal: ixbrlField(
        {
          ...active(
            ref(
              or(
                sum(
                  id('managementReport.resultsDisposition.total'),
                  multiply(
                    id(
                      'managementReport.resultsDisposition.extraordinaryMeetingDividend.*.value'
                    ),
                    -1
                  )
                ),
                0
              )
            )
          ),
          active: false,
        },
        {
          type: 'monetaryItemType',
          name: 'se-gen-base:FrittEgetKapitalEfterUtdelningBeslutadExtraBolagsstamma',
          contextRef: 'balans0',
          saldo: 'credit',
        }
      ),
      boardProposalHeader: active(field('')),
      proposal: table(
        'managementReport.resultsDisposition.proposal',
        'label',
        'value'
      )
        .addRows((rows) => {
          rows
            .addRowActive(false)
            .addRow(
              'repaymentOfConditionalShareholderContribution',
              value('Återbetalning av villkorat aktieägartillskott'),
              ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                name: 'se-gen-base:ForslagDispositionUtdelningAterbetalningVillkoratAktieagartillskott',
                contextRef: 'balans0',
                saldo: 'credit', // using credit (even though its not in taxonomi) to get sign attribute right if we got a negative number
              })
            )
            .addRow(
              'otherDividend',
              value('Annan utdelning'),
              ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                name: 'se-gen-base:ForslagDispositionUtdelningAnnanUtdelning',
                contextRef: 'balans0',
                saldo: 'credit', // using credit (even though its not in taxonomi) to get sign attribute right if we got a negative number
              })
            )
            .addRowActive(true)
            .addRow(
              '1',
              value('Utdelning'),
              ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                name: 'se-gen-base:ForslagDispositionUtdelning',
                contextRef: 'balans0',
                saldo: 'credit', // using credit (even though its not in taxonomi) to get sign attribute right if we got a negative number
              })
            )
            .addRowActive(false)
            .addRow(
              '2',
              value('Fondemission'),
              ixbrlCell(value(0), {
                type: 'monetaryItemType',
                name: 'se-gen-base:ForslagDispositionFondemission',
                contextRef: 'balans0',
                saldo: 'credit', // using credit (even though its not in taxonomi) to get sign attribute right if we got a negative number
              })
            )
            .addRow(
              '3',
              value('Avsättning till reservfond'),
              ixbrlCell(value(0), {
                type: 'monetaryItemType',
                name: 'se-gen-base:ForslagDispositionAvsattningReservfond',
                contextRef: 'balans0',
                saldo: 'credit', // using credit (even though its not in taxonomi) to get sign attribute right if we got a negative number
              })
            )
            .addRow(
              '4',
              value('Ianspråktagande av reservfond'),
              ixbrlCell(value(0), {
                type: 'monetaryItemType',
                name: 'se-gen-base:ForslagDispositionIanspraktagandeReservfond',
                contextRef: 'balans0',
                saldo: 'credit', // using credit (even though its not in taxonomi) to get sign attribute right if we got a negative number
              })
            )
            .addRow(
              '5',
              value('Ianspråktagande av bunden överkursfond'),
              ixbrlCell(value(0), {
                type: 'monetaryItemType',
                name: 'se-gen-base:ForslagDispositionIanspraktagandeBundenOverkursfond',
                contextRef: 'balans0',
                saldo: 'credit', // using credit (even though its not in taxonomi) to get sign attribute right if we got a negative number
              })
            )
            .addRow(
              '6',
              value('Ianspråktagande av uppskrivningsfond'),
              ixbrlCell(value(0), {
                type: 'monetaryItemType',
                name: 'se-gen-base:ForslagDispositionIanspraktagandeUppskrivningsfond',
                contextRef: 'balans0',
                saldo: 'credit', // using credit (even though its not in taxonomi) to get sign attribute right if we got a negative number
              })
            );
          if (reportType === 'k3') {
            rows.addRow(
              '7',
              value('Ianspråktagande av fond för utvecklingsutgifter'),
              ixbrlCell(value(0), {
                type: 'monetaryItemType',
                name: 'se-gen-base:ForslagDispositionIanspraktagandeFondUtvecklingsutgifter',
                contextRef: 'balans0',
                saldo: 'credit', // using credit (even though its not in taxonomi) to get sign attribute right if we got a negative number
              })
            );
          }
          return rows.build();
        })
        .newRowTemplate(value(''), value(0))
        .build(),
      customToTransfer: active(field('')),
      toTransfer: active(
        ixbrlField(
          ref(
            sum(
              multiply(
                -1,
                or(
                  sum(
                    id('managementReport.resultsDisposition.proposal.*.value')
                  ),
                  0
                )
              ),
              id('managementReport.resultsDisposition.total')
            )
          ),
          {
            type: 'monetaryItemType',
            name: 'se-gen-base:ForslagDispositionBalanserasINyRakning',
            contextRef: 'balans0',
            saldo: 'credit', // using credit (even though its not in taxonomi) to get sign attribute right if we got a negative number
          }
        )
      ),
      customInTotal: active(field('')),
      // No need for tagging because resolves the same value as 'total' above
      inTotal: ixbrlField(
        active(
          ref(
            or(
              sum(id('managementReport.resultsDisposition.toDispose.*.value')),
              0
            )
          )
        ),
        {
          type: 'monetaryItemType',
          name: 'se-gen-base:ForslagDisposition',
          contextRef: 'balans0',
          saldo: 'credit',
        }
      ),
      inTotalWarning:
        getWarningCell('managementReport.resultsDisposition.inTotal') ||
        value(''),
    },
    profitProposal: {
      title: field(''),
      active: false,
      dividendPerShare: ref(
        or(
          div(
            id('managementReport.profitProposal.dividend'),
            id('managementReport.profitProposal.shares')
          ),
          0
        )
      ),
      dividend: ref(
        or(id('managementReport.resultsDisposition.proposal.1.value'), 0)
      ),
      shares: field(1),
      toTransfer: ref(id('managementReport.resultsDisposition.toTransfer')),
      // the value (date) is added in ProfitProposal view component
      paymentDateType: field('generalMeeting'),
      paymentDate: field(''),
      statement: ixbrlField(
        field(
          'Styrelsen har gjort bedömningen att vinstutdelningen är försvarlig ' +
            'med hänsyn till dom krav som verksamhetens art, omfattning och ' +
            'risker ställer på storleken av det egna kapitalet, och bolagets ' +
            'konsolideringsbehov, likviditet och ställning i övrigt. Den ' +
            'förslagna vinstutdelningen är därför förenlig med vad som anges i ' +
            'aktiebolagslagen 17 kap. 3§ andra och tredje styckena.'
        ),
        {
          type: 'stringItemType',
          name: 'se-gen-base:StyrelsensYttrandeVinstutdelning',
          contextRef: 'period0',
        }
      ),
    },
    profitProposalK3: {
      title: ixbrlField(field('Specifikation av utdelning per aktieslag'), {
        type: 'stringItemType',
        name: 'se-gen-base:SpecifikationForslagUtdelningAktieAbstract',
        contextRef: 'period0',
      }),
      active: false,
      table: table<IxbrlCell>(
        'managementReport.profitProposal.table',
        { id: 'shareClass', label: 'Aktieslag' },
        { id: 'paymentPerShare', label: 'Utbetalningsbelopp per aktie' },
        { id: 'date', label: 'Avstämningsdag' },
        { id: 'paymentTime', label: 'Tidpunkt för betalning' },
        { id: 'dividendType', label: 'Typ av utdelning' }
      )
        .newRowTemplateGenerator((id, baseId) => ({
          id,
          active: true,
          cells: {
            shareClass: ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-gen-base:ForslagUtdelningAktieslag',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:SpecifikationForslagUtdelningAktieslagTuple',
                id: `${baseId}.${id}.tuple`,
                tupleID: `${baseId}.${id}.tuple`,
              },
              tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
            }),
            paymentPerShare: ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:ForslagUtdelningBeloppAktie',
              contextRef: 'period0',
              tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
            }),
            date: ixbrlCell(value(''), {
              type: 'dateItemType',
              name: 'se-gen-base:ForslagUtdelningAvstamningsdag',
              contextRef: 'period0',
              tupleRef: tupleRef(3, `${baseId}.${id}.tuple`),
            }),
            paymentTime: ixbrlCell(value(''), {
              type: 'dateItemType',
              name: 'se-gen-base:ForslagUtdelningTidpunktBetalning',
              contextRef: 'period0',
              tupleRef: tupleRef(4, `${baseId}.${id}.tuple`),
            }),
            dividendType: ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-gen-base:ForslagUtdelningTillgang',
              contextRef: 'period0',
              tupleRef: tupleRef(5, `${baseId}.${id}.tuple`),
            }),
          },
        }))
        .build(),
      comment: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-gen-base:DispositionerVinstForlustKommentar',
        contextRef: 'balans0',
      }),
      statement: ixbrlField(
        field(
          'Styrelsen har gjort bedömningen att vinstutdelningen är försvarlig med hänsyn ' +
            'till dom krav som verksamhetens art, omfattning och risker ställer på storleken ' +
            'av det egna kapitalet, och bolagets konsolideringsbehov, likviditet och ställning i övrigt. ' +
            'Den förslagna vinstutdelningen är därför förenlig med vad som anges ' +
            'i aktiebolagslagen 17 kap. 3§ andra och tredje styckena.'
        ),
        {
          type: 'stringItemType',
          name: 'se-gen-base:StyrelsensYttrandeVinstutdelning',
          contextRef: 'period0',
        }
      ),
    },
    hidden: {
      city: ref(id('settings.clientInformation.city')),
      activityText: ref(id('settings.clientInformation.activityText')),
    },
  };
};

export const managementReportReferences = (): Record<string, string> => ({
  solidity: sum(
    id('managementReport.multiYearOverview.table.nettoAndResult.*.value')
  ),
});
