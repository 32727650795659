import cashFlowStatement from '../cash-flow-statement/cashflowstatementk2v2.json';
import balanceStatement from '../balance-sheet/balancestatementk2v2.json';
import costDividedIncomeStatement from '../income-statement/costdividedincomestatementk2v2.json';

export type Documentation = {
  [key: string]: {
    tooltip?: string;
    standardrubrik?: string;
    accounts?: string;
  };
};

export const limitedCompanyDocumentation = (): Documentation => {
  return {
    ...cashFlowStatement,
    ...balanceStatement,
    ...costDividedIncomeStatement,
  };
};
