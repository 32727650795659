/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: nan
 */

import {
  value,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  previousYear,
  year,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2030K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2030K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Negativ goodwill'),
  data: {
    active: true,
    NegativGoodwillAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Negativ goodwill'),
      table: table<IxbrlCell>(
        'notes.note30.data.NegativGoodwillAbstract.table',
        {
          id: 'NegativGoodwillAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'NegativGoodwillAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'NegativGoodwill',
                'row',
                noteLabel('Belopp'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NegativGoodwill',
                  standardRubrik: 'Negativ goodwill',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NegativGoodwill',
                  standardRubrik: 'Negativ goodwill',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          return rows.build();
        })
        .build(),
      NotNegativGoodwillKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field('Kommentar till upplysning om negativ goodwill'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotNegativGoodwillKommentar',
          standardRubrik: 'Kommentar till upplysning om negativ goodwill',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2030K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        NegativGoodwillAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotNegativGoodwillKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
