import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableRowProps,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Alert from '_shared/components/AlertBanners/Alert';

import { sortByProp, isCreditAndDebitEqual } from 'utils/Voucher';
import { AccountPlanList, VoucherTransaction } from 'types/Voucher';

import Button from '_shared/components/Buttons/Button';
import TransactionRow from './TransactionRow';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-width: 360px;
  width: 100%;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
`;

const TableWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  width: 100%;
  overflow: auto;
  background: ${(props) => props.theme.palette.common.white};
`;

const VoucherTransactionsTable = styled(Table)`
  border-collapse: separate;

  .MuiTableCell-root {
    font-size: 0.9rem;
    color: ${(props) => props.theme.palette.common.black};
  }

  .MuiTableCell-head {
    border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
    font-weight: 700;
    font-size: 14px;
    color: ${(props) => props.theme.palette.common.black};
    padding: 8px 4px 12px 4px;
  }
`;

const ErrorMessageWrapper = styled.div`
  .MuiTypography-gutterBottom {
    margin-bottom: 0;
  }
  margin-bottom: 5px;
  margin-top: 15px;
`;

const AddTableRow = styled(TableRow)<TableRowProps>`
  .MuiTableCell-root {
    border: none;
    &:first-of-type {
      border: none;
      padding-left: 0;
    }
  }
`;

const emptyTransaction: VoucherTransaction = {
  id: 0,
  sourceInfo: ' ',
  kind: 'KS',
  typeAmount: 'debit',
  amount: 0,
  account: 0,
  debit: 0,
  credit: 0,
};

interface VoucherTransactionRows {
  records: VoucherTransaction[];
  accounts: AccountPlanList[];
  editMode?: boolean;
  disabled?: boolean;
  roundDown?: boolean;
  onAddTransaction?: (transaction: VoucherTransaction) => void;
  onUpdateTransaction?: (
    transaction: VoucherTransaction,
    field: string
  ) => void;
  onRemoveTransaction?: (removeId: number | string) => void;
  onError?: (error: string) => void;
}

const TransactionTable = ({
  records,
  accounts,
  editMode = true,
  disabled = false,
  roundDown = false,
  onAddTransaction = () => {},
  onUpdateTransaction = () => {},
  onRemoveTransaction = () => {},
  onError = () => {},
}: VoucherTransactionRows) => {
  const { formatMessage } = useIntl();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isCreditAndDebitEqual(records, roundDown)) {
      setErrorMessage('');
      onError('');
    } else {
      setErrorMessage(formatMessage({ id: 'ref.error.unbalanced' }));
      onError(formatMessage({ id: 'ref.error.unbalanced' }));
    }
  }, [records, onError, formatMessage]);

  const handleAddRow = () => {
    onAddTransaction({
      ...emptyTransaction,
      id:
        (sortByProp([...records], 'number', 'id')[records.length - 1]?.id ??
          99) + 1,
    });
  };
  return (
    <Container>
      <TableWrapper>
        <VoucherTransactionsTable>
          <TableHead>
            <TableRow>
              {!editMode && <TableCell align="left">#</TableCell>}
              <TableCell align="left">
                {`${editMode ? '# ' : ''}${formatMessage({ id: 'voucher.adjustments.account' })}`}
              </TableCell>
              <TableCell width="250" align="justify">
                {formatMessage({ id: 'voucher.adjustments.description' })}
              </TableCell>
              <TableCell width="150" align="right">
                {formatMessage({ id: 'voucher.adjustments.debit' })}
              </TableCell>
              <TableCell width="150" align="right">
                {formatMessage({ id: 'voucher.adjustments.credit' })}
              </TableCell>
              {editMode && <TableCell width="50" align="center" />}
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((row) => (
              <TransactionRow
                row={row}
                editMode={editMode}
                key={row.id}
                accounts={accounts}
                onUpdateTransaction={onUpdateTransaction}
                onRemoveTransaction={onRemoveTransaction}
                disabled={disabled}
                roundDown={roundDown}
              />
            ))}
            {editMode && (
              <AddTableRow>
                <TableCell>
                  <Button
                    label={formatMessage({ id: 'voucher.adjustments.add' })}
                    startIcon={<AddIcon fontSize="large" />}
                    onClick={handleAddRow}
                    disabled={disabled}
                    variant="outlined"
                    size="medium"
                  />
                </TableCell>
              </AddTableRow>
            )}
          </TableBody>
        </VoucherTransactionsTable>
      </TableWrapper>

      {errorMessage && (
        <ErrorMessageWrapper>
          <Alert type="error">{errorMessage}</Alert>
        </ErrorMessageWrapper>
      )}
    </Container>
  );
};

export default TransactionTable;
