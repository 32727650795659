/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-arsredovisning-2021-10-31-rev20230131_sv - Noter.csv
 * - Comments: nan
 */

import {
  value,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
  previousYear,
  year,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2085K2v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2085K2v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel(
    'Tillgångar, avsättningar och skulder som avser flera poster'
  ),
  data: {
    active: true,
    TillgangarAvsattningarSkulderTuple: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Tillgångar, avsättningar och skulder som avser flera poster'
      ),
      table: table<IxbrlCell>(
        'notes.note85.data.TillgangarAvsattningarSkulderTuple.table',
        {
          id: 'TillgangarAvsattningarSkulderPost',
          label:
            'Typ av tillgångar, avsättningar och skulder som avser flera poster',
          dataType: 'text',
          active: true,
        },
        {
          id: 'TillgangarAvsattningarSkulderBalanspost',
          label: 'Typ av balanspost',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'TillgangarAvsattningarSkulderTuple',
              'hidden',
              undefined,
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(
                  value(
                    'Typ av tillgångar, avsättningar och skulder som avser flera poster'
                  ),
                  {
                    type: 'stringItemType',
                    name: 'se-gen-base:TillgangarAvsattningarSkulderPost',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Typ av tillgång, avsättning eller skuld som avser flera poster',
                    negateValue: false,
                    tuple: {
                      name: 'se-gaap-ext:TillgangarAvsattningarSkulderTuple',
                      id: `${rows.getBaseId()}.1.tuple`,
                      tupleID: `${rows.getBaseId()}.1.tuple`,
                    },
                    tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: true,
                  }
                ),
                ixbrlCell(value('Typ av balanspost'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:TillgangarAvsattningarSkulderBalanspost',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Balanspost där tillgång, avsättning eller skuld som avser flera poster redovisas',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: true,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:TillgangarAvsattningarSkulderPerTyp',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Belopp per balanspost där tillgång, (avsättning/skuld) som avser flera poster redovisas',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple_balans0`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:TillgangarAvsattningarSkulderPerTyp',
                  contextRef: 'balans1',
                  standardRubrik:
                    'Belopp per balanspost där tillgång, (avsättning/skuld) som avser flera poster redovisas',
                  negateValue: false,
                  tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple_balans1`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              TillgangarAvsattningarSkulderPost: ixbrlCell(
                value(
                  'Typ av tillgångar, avsättningar och skulder som avser flera poster'
                ),
                {
                  type: 'stringItemType',
                  name: 'se-gen-base:TillgangarAvsattningarSkulderPost',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Typ av tillgång, avsättning eller skuld som avser flera poster',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:TillgangarAvsattningarSkulderTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: true,
                }
              ),
              TillgangarAvsattningarSkulderBalanspost: ixbrlCell(
                value('Typ av balanspost'),
                {
                  type: 'stringItemType',
                  name: 'se-gen-base:TillgangarAvsattningarSkulderBalanspost',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Balanspost där tillgång, avsättning eller skuld som avser flera poster redovisas',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                  previousYearTuple: true,
                }
              ),
              year: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:TillgangarAvsattningarSkulderPerTyp',
                contextRef: 'balans0',
                standardRubrik:
                  'Belopp per balanspost där tillgång, (avsättning/skuld) som avser flera poster redovisas',
                negateValue: false,
                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple_balans0`),
                previousYearTuple: false,
              }),
              previousYear: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:TillgangarAvsattningarSkulderPerTyp',
                contextRef: 'balans1',
                standardRubrik:
                  'Belopp per balanspost där tillgång, (avsättning/skuld) som avser flera poster redovisas',
                negateValue: false,
                tupleRef: tupleRef(4, `${baseId}.${_id}.tuple_balans1`),
                previousYearTuple: false,
              }),
            },
          }));
          return rows.build();
        })
        .build(),
      NotTillgangarAvsattningarSkuldeKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till specifikation av tillgångar, avsättningar och skulder som avser flera poster'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'balans0',
          type: 'stringItemType',
          name: 'se-gen-base:NotTillgangarAvsattningarSkuldeKommentar',
          standardRubrik:
            'Kommentar till specifikation av tillgångar, avsättningar och skulder som avser flera poster',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2085K2v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        TillgangarAvsattningarSkulderTuple: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotTillgangarAvsattningarSkuldeKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
