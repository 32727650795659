/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6853
 */

import {
  value,
  ref,
  id,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
  previousYear,
  year,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2003K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2003K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Nettoomsättningens fördelning'),
  data: {
    active: true,
    NettoomsattningVerksamhetsgrenTuple: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Nettoomsättningens fördelning på verksamhetsgrenar'),
      table: table<IxbrlCell>(
        'notes.note3.data.NettoomsattningVerksamhetsgrenTuple.table',
        {
          id: 'VerksamhetsgrenBenamning',
          label: 'Verksamhet',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'NettoomsattningVerksamhetsgrenTuple',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Verksamhet'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:VerksamhetsgrenBenamning',
                  contextRef: 'period0',
                  standardRubrik: 'Benämning på verksamhetsgren',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:NettoomsattningVerksamhetsgrenTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: true,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NettoomsattningVerksamhetsgren',
                  contextRef: 'period0',
                  standardRubrik: 'Nettoomsättning per verksamhetsgren',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple_period0`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NettoomsattningVerksamhetsgren',
                  contextRef: 'period1',
                  standardRubrik: 'Nettoomsättning per verksamhetsgren',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple_period1`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              VerksamhetsgrenBenamning: ixbrlCell(value('Verksamhet'), {
                type: 'stringItemType',
                name: 'se-gen-base:VerksamhetsgrenBenamning',
                contextRef: 'period0',
                standardRubrik: 'Benämning på verksamhetsgren',
                negateValue: false,
                tuple: {
                  name: 'se-gaap-ext:NettoomsattningVerksamhetsgrenTuple',
                  id: `${baseId}.${_id}.tuple`,
                  tupleID: `${baseId}.${_id}.tuple`,
                },
                tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                previousYearTuple: true,
              }),
              year: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:NettoomsattningVerksamhetsgren',
                contextRef: 'period0',
                standardRubrik: 'Nettoomsättning per verksamhetsgren',
                saldo: 'credit',
                negateValue: false,
                tupleRef: tupleRef(2, `${baseId}.${_id}.tuple_period0`),
                previousYearTuple: false,
              }),
              previousYear: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:NettoomsattningVerksamhetsgren',
                contextRef: 'period1',
                standardRubrik: 'Nettoomsättning per verksamhetsgren',
                saldo: 'credit',
                negateValue: false,
                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple_period1`),
                previousYearTuple: false,
              }),
            },
          }));
          return rows.build();
        })
        .build(),
      NettoomsattningVerksamhetsgrenKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id('notes.note3.data.NettoomsattningVerksamhetsgrenTuple.active')
        ),
        title: field(
          'Kommentar till nettoomsättningens fördelning på verksamhetsgrenar'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NettoomsattningVerksamhetsgrenKommentar',
          standardRubrik:
            'Kommentar till nettoomsättningens fördelning på verksamhetsgrenar',
        }),
      },
    },
    NettoomsattningGeografiskMarknadTuple: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Nettoomsättningens fördelning på geografiska marknader'),
      table: table<IxbrlCell>(
        'notes.note3.data.NettoomsattningGeografiskMarknadTuple.table',
        {
          id: 'GeografiskMarknadBenamning',
          label: 'Marknad',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'NettoomsattningGeografiskMarknadTuple',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Marknad'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:GeografiskMarknadBenamning',
                  contextRef: 'period0',
                  standardRubrik: 'Benämning på geografisk marknad',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:NettoomsattningGeografiskMarknadTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: true,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NettoomsattningGeografiskMarknad',
                  contextRef: 'period0',
                  standardRubrik: 'Nettoomsättning per geografisk marknad',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple_period0`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:NettoomsattningGeografiskMarknad',
                  contextRef: 'period1',
                  standardRubrik: 'Nettoomsättning per geografisk marknad',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple_period1`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              GeografiskMarknadBenamning: ixbrlCell(value('Marknad'), {
                type: 'stringItemType',
                name: 'se-gen-base:GeografiskMarknadBenamning',
                contextRef: 'period0',
                standardRubrik: 'Benämning på geografisk marknad',
                negateValue: false,
                tuple: {
                  name: 'se-gaap-ext:NettoomsattningGeografiskMarknadTuple',
                  id: `${baseId}.${_id}.tuple`,
                  tupleID: `${baseId}.${_id}.tuple`,
                },
                tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                previousYearTuple: true,
              }),
              year: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:NettoomsattningGeografiskMarknad',
                contextRef: 'period0',
                standardRubrik: 'Nettoomsättning per geografisk marknad',
                saldo: 'credit',
                negateValue: false,
                tupleRef: tupleRef(2, `${baseId}.${_id}.tuple_period0`),
                previousYearTuple: false,
              }),
              previousYear: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:NettoomsattningGeografiskMarknad',
                contextRef: 'period1',
                standardRubrik: 'Nettoomsättning per geografisk marknad',
                saldo: 'credit',
                negateValue: false,
                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple_period1`),
                previousYearTuple: false,
              }),
            },
          }));
          return rows.build();
        })
        .build(),
      NettoomsattningGeografiskMarknadKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id('notes.note3.data.NettoomsattningGeografiskMarknadTuple.active')
        ),
        title: field(
          'Kommentar till nettoomsättningens fördelning på geografiska marknader'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NettoomsattningGeografiskMarknadKommentar',
          standardRubrik:
            'Kommentar till nettoomsättningens fördelning på geografiska marknader',
        }),
      },
    },
    NotNettoomsattningFordelningKommentar: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Kommentar till nettoomsättningens fördelning'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:NotNettoomsattningFordelningKommentar',
        standardRubrik: 'Kommentar till nettoomsättningens fördelning',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2003K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        NettoomsattningVerksamhetsgrenTuple: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NettoomsattningVerksamhetsgrenKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        NettoomsattningGeografiskMarknadTuple: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NettoomsattningGeografiskMarknadKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        NotNettoomsattningFordelningKommentar: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            value: cell,
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
