/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-arsredovisning-2021-10-31-rev20230131_sv - Noter.csv
 * - Comments: nan
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  previousYear,
  year,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2082K2v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2082K2v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Ställda säkerheter'),
  data: {
    active: true,
    StalldaSakerheterAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Ställda säkerheter'),
      table: table<IxbrlCell>(
        'notes.note82.data.StalldaSakerheterAbstract.table',
        {
          id: 'StalldaSakerheterAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'StalldaSakerheterAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'StalldaSakerheterForetagsinteckningar',
                'row',
                noteLabel('Företagsinteckningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterForetagsinteckningar',
                  standardRubrik:
                    'Ställda säkerheter avseende företagsinteckningar',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterForetagsinteckningar',
                  standardRubrik:
                    'Ställda säkerheter avseende företagsinteckningar',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'StalldaSakerheterForetagsinteckningarKoncernforetag',
                'row',
                noteLabel(
                  '(Varav till koncernföretag, intresseföretag eller gemensamt styrt företag)'
                ),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterForetagsinteckningarKoncernforetag',
                  standardRubrik:
                    'Ställda säkerheter avseende företagsinteckningar för koncernföretag',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterForetagsinteckningarKoncernforetag',
                  standardRubrik:
                    'Ställda säkerheter avseende företagsinteckningar för koncernföretag',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'StalldaSakerheterFastighetsinteckningar',
                'row',
                noteLabel('Fastighetsinteckningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterFastighetsinteckningar',
                  standardRubrik:
                    'Ställda säkerheter avseende fastighetsinteckningar',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterFastighetsinteckningar',
                  standardRubrik:
                    'Ställda säkerheter avseende fastighetsinteckningar',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'StalldaSakerheterFastighetsinteckningarKoncernforetag',
                'row',
                noteLabel(
                  '(Varav till koncernföretag, intresseföretag eller gemensamt styrt företag)'
                ),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterFastighetsinteckningarKoncernforetag',
                  standardRubrik:
                    'Ställda säkerheter avseende fastighetsinteckningar för koncernföretag',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterFastighetsinteckningarKoncernforetag',
                  standardRubrik:
                    'Ställda säkerheter avseende fastighetsinteckningar för koncernföretag',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'StalldaSakerheterTillgangarAganderattsforbehall',
                'row',
                noteLabel('Tillgångar med äganderättsförbehåll'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterTillgangarAganderattsforbehall',
                  standardRubrik:
                    'Ställda säkerheter avseende tillgångar med äganderättsförbehåll',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterTillgangarAganderattsforbehall',
                  standardRubrik:
                    'Ställda säkerheter avseende tillgångar med äganderättsförbehåll',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'StalldaSakerheterTillgangaraganderattsforbehallKoncernforetag',
                'row',
                noteLabel(
                  '(Varav till koncernföretag, intresseföretag eller gemensamt styrt företag)'
                ),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterTillgangaraganderattsforbehallKoncernforetag',
                  standardRubrik:
                    'Ställda säkerheter avseende tillgångar med äganderättsförbehåll för koncernföretag',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterTillgangaraganderattsforbehallKoncernforetag',
                  standardRubrik:
                    'Ställda säkerheter avseende tillgångar med äganderättsförbehåll för koncernföretag',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'StalldaSakerheterBelanadeFordringar',
                'row',
                noteLabel('Belånade fordringar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterBelanadeFordringar',
                  standardRubrik:
                    'Ställda säkerheter avseende belånade fordringar',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterBelanadeFordringar',
                  standardRubrik:
                    'Ställda säkerheter avseende belånade fordringar',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'StalldaSakerheterBelanadeFordringarKoncernforetag',
                'row',
                noteLabel(
                  '(Varav till koncernföretag, intresseföretag eller gemensamt styrt företag)'
                ),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterBelanadeFordringarKoncernforetag',
                  standardRubrik:
                    'Ställda säkerheter avseende belånade fordringar för koncernföretag',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterBelanadeFordringarKoncernforetag',
                  standardRubrik:
                    'Ställda säkerheter avseende belånade fordringar för koncernföretag',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'StalldaSakerheterAndraStalldaSakerheter',
                'row',
                noteLabel('Andra ställda säkerheter'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterAndraStalldaSakerheter',
                  standardRubrik: 'Andra ställda säkerheter',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterAndraStalldaSakerheter',
                  standardRubrik: 'Andra ställda säkerheter',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'StalldaSakerheterAndraStalldaSakerheterKoncernforetag',
                'row',
                noteLabel(
                  '(Varav till koncernföretag, intresseföretag eller gemensamt styrt företag)'
                ),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterAndraStalldaSakerheterKoncernforetag',
                  standardRubrik: 'Andra ställda säkerheter för koncernföretag',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:StalldaSakerheterAndraStalldaSakerheterKoncernforetag',
                  standardRubrik: 'Andra ställda säkerheter för koncernföretag',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'StalldaSakerheter',
            'sum',
            noteLabel('Summa ställda säkerheter'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note82.data.StalldaSakerheterAbstract.table.StalldaSakerheterAbstract.StalldaSakerheterForetagsinteckningar.year'
                  ),
                  id(
                    'notes.note82.data.StalldaSakerheterAbstract.table.StalldaSakerheterAbstract.StalldaSakerheterFastighetsinteckningar.year'
                  ),
                  id(
                    'notes.note82.data.StalldaSakerheterAbstract.table.StalldaSakerheterAbstract.StalldaSakerheterTillgangarAganderattsforbehall.year'
                  ),
                  id(
                    'notes.note82.data.StalldaSakerheterAbstract.table.StalldaSakerheterAbstract.StalldaSakerheterBelanadeFordringar.year'
                  ),
                  id(
                    'notes.note82.data.StalldaSakerheterAbstract.table.StalldaSakerheterAbstract.StalldaSakerheterAndraStalldaSakerheter.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:StalldaSakerheter',
                standardRubrik: 'Ställda säkerheter',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note82.data.StalldaSakerheterAbstract.table.StalldaSakerheterAbstract.StalldaSakerheterForetagsinteckningar.previousYear'
                  ),
                  id(
                    'notes.note82.data.StalldaSakerheterAbstract.table.StalldaSakerheterAbstract.StalldaSakerheterFastighetsinteckningar.previousYear'
                  ),
                  id(
                    'notes.note82.data.StalldaSakerheterAbstract.table.StalldaSakerheterAbstract.StalldaSakerheterTillgangarAganderattsforbehall.previousYear'
                  ),
                  id(
                    'notes.note82.data.StalldaSakerheterAbstract.table.StalldaSakerheterAbstract.StalldaSakerheterBelanadeFordringar.previousYear'
                  ),
                  id(
                    'notes.note82.data.StalldaSakerheterAbstract.table.StalldaSakerheterAbstract.StalldaSakerheterAndraStalldaSakerheter.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:StalldaSakerheter',
                standardRubrik: 'Ställda säkerheter',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NotStalldaSakerheterKommentarer: {
        type: 'group',
        active: true,
        level: 0,
        title: field('Kommentar till specifikation av ställda säkerheter'),
        value: ixbrlField(field(''), {
          contextRef: 'balans0',
          type: 'stringItemType',
          name: 'se-gen-base:NotStalldaSakerheterKommentarer',
          standardRubrik: 'Kommentar till specifikation av ställda säkerheter',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2082K2v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        StalldaSakerheterAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotStalldaSakerheterKommentarer: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
