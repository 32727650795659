import { parseFormat } from '@agoy/dates';
import { SkatteverketTransaction } from '_clients/redux/customer-view/types';
import {
  SpecificationCellType,
  SpecificationColumnsByCategory,
} from '_clients/types/types';
import { IntlShape } from 'react-intl';
import { ColumnsIds, TransactionInfo } from './types';

const translations = {
  account: 'hidden.legacySpecification.account',
  amount: 'hidden.legacySpecification.amount',
  reference: 'hidden.legacySpecification.reference',
  description: 'hidden.legacySpecification.description',
  transactionInfo: 'hidden.legacySpecification.transactionInfo',
};

export const getColumnsIds = (
  groupedByCategory: SpecificationColumnsByCategory[],
  formatMessage: IntlShape['formatMessage']
) => {
  const columnsIds: ColumnsIds = {
    amount: 0,
    reference: 0,
    account: 0,
    description: 0,
    transactionInfo: 0,
  };

  groupedByCategory.forEach((category) =>
    category.columns.forEach((column) => {
      const key = Object.keys(translations).find(
        (item) => column.name === formatMessage({ id: translations[item] })
      );

      if (key) {
        columnsIds[key] = column.id;
      }
    })
  );

  return columnsIds;
};

export const getTransactionData = (
  cells: SpecificationCellType[],
  columnsIds: ColumnsIds
) => {
  return cells.reduce(
    (acc, cell) => {
      const result = { ...acc };

      Object.keys(columnsIds).forEach((key) => {
        if (cell.columnId === columnsIds[key]) {
          result[key] = cell.value;
        }
      });

      return result;
    },
    {
      account: '',
      amount: 0,
      reference: '',
    }
  );
};

export const getRowCells = (
  transactions: TransactionInfo[],
  columnsIds: ColumnsIds | null | undefined
) => {
  if (!columnsIds) {
    return [];
  }

  return transactions.map((transaction) => {
    const result = [
      {
        columnId: columnsIds.description,
        value: `${transaction.verificationText ?? ''} (${parseFormat(
          transaction.transactionDate,
          'yyyy-MM-dd'
        )})`.trim(),
      },
      {
        columnId: columnsIds.reference,
        value: `ver ${transaction.series}-${transaction.verificationNumber}`,
      },

      {
        columnId: columnsIds.account,
        value: transaction.account,
      },
      {
        columnId: columnsIds.amount,
        value: transaction.amount,
      },
    ];

    if (transaction.transactionText) {
      result.splice(2, 0, {
        columnId: columnsIds.transactionInfo,
        value: transaction.transactionText,
      });
    }

    return result;
  });
};

export const getSkatteverketRowCells = (
  transaction: SkatteverketTransaction,
  columnsIds: ColumnsIds | null | undefined,
  accountNumber: string
) => {
  if (!columnsIds) {
    return [];
  }

  return [
    {
      columnId: columnsIds.amount,
      value: transaction.amount,
    },
    {
      columnId: columnsIds.reference,
      value: `Skatteverket`,
    },
    {
      columnId: columnsIds.account,
      value: accountNumber,
    },
    {
      columnId: columnsIds.description,
      value: transaction.specification,
    },
  ];
};

export const formatTransactionToLegacySpecification = (
  transaction: TransactionInfo,
  id: number
) => {
  return {
    account: transaction.account,
    amount: transaction.amount.toFixed(2),
    description: `${transaction.verificationText ?? ''} (${parseFormat(
      transaction.transactionDate,
      'yyyy-MM-dd'
    )})`,
    id,
    reference: `ver ${transaction.series}-${transaction.verificationNumber}`,
  };
};
