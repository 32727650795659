import React, { useContext } from 'react';
import PrintStateContext from '_shared/components/PrintedDocument/PrintStateContext';
import PeriodDataContext from './HiddenRow/Rows/PeriodDataContext';

type ReconciliationPrintProviderProps = React.PropsWithChildren<{}>;

/**
 * This component provides a PeriodDataContext
 * for reconciliation print views. The content comes
 * from the printState.periodData
 */
const ReconciliationPrintProvider = ({
  children,
}: ReconciliationPrintProviderProps) => {
  const { state } = useContext(PrintStateContext);

  if (!state.periodData) {
    console.log('State is missing periodData');
    return null;
  }
  const {
    nextPeriodFinancialYear = null,
    nextPeriod = null,
    nextPeriodLocked = null,
    nextGroupedPeriods = null,
    previousPeriodFinancialYear = null,
    previousPeriod = null,
    previousPeriodLocked = null,
    previousYearEndLocked = null,
    previousGroupedPeriods = null,
    periodLocked = false,
    lastPeriodLocked = false,
    groupedPeriods = [],
    clientId = '',
    financialYear,
    period,
    lastPeriod,
    periodType,
    parentPeriodType,
    yearEndPeriod,
  } = state.periodData;

  const periodDataContext = {
    getPeriodUserInput: () => Promise.resolve(null),
    nextPeriodFinancialYear,
    nextPeriod,
    nextPeriodLocked,
    nextGroupedPeriods,
    previousPeriodFinancialYear,
    previousPeriod,
    previousPeriodLocked,
    previousYearEndLocked,
    previousGroupedPeriods,
    periodLocked,
    lastPeriodLocked,
    groupedPeriods,
    clientId,
    financialYear,
    period,
    lastPeriod,
    periodType,
    parentPeriodType,
    yearEndPeriod,
  };

  return (
    <PeriodDataContext.Provider value={periodDataContext}>
      {children}
    </PeriodDataContext.Provider>
  );
};

export default ReconciliationPrintProvider;
