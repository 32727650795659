import { useHistory as useReactHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { resolvePath } from '../../utils/routes';
import useFid from './useFid';

const useHistory = () => {
  const history = useReactHistory();
  const fid = useFid();

  const customHistory = useMemo(
    () => ({
      ...history,
      push: (path, state?) => history.push(resolvePath(fid, path), state),
      replace: (path, state?) => history.replace(resolvePath(fid, path), state),
    }),
    [fid, history, history.location]
  );

  return fid ? customHistory : history;
};

export default useHistory;
