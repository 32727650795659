import React, { useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { useSelector } from 'redux/reducers';
import { ClientCompany } from '@agoy/api-sdk-core';
import { parse, isSameOrAfter } from '@agoy/dates';
import { ReconciliationPeriod } from '@agoy/reconciliation';
import RowContext from '../../../RowContext';
import MoveAccountsContext from '../../../RowContext/MoveAccountsContext';
import { AccountNamesContext } from '../../../AccountNamesContext';
import { Column } from '../../../ScrollableTable';
import { HiddenGroupRow, HiddenRow } from '../../../HiddenRow';
import RenderColumnContent from '../../RenderColumnContent';
import AccountCell from './AccountCell';
import GroupCell from './GroupCell';
import GroupSumCell from './GroupSumCell';
import {
  AccountRow,
  GroupHeaderRow,
  GroupSumRow,
  HiddenAccountRow,
  HiddenGroupRow as HiddenGroupRowType,
  KeyFigureRow,
} from '../../../RowContext/types';

type HeaderColumnProps = {
  clientId: string;
  financialYear: ClientCompany['financialYears'][0];
  groupedPeriods: ReconciliationPeriod[];
  sticky: boolean;
  className?: string;
};

const HeaderColumn = ({
  className,
  clientId,
  financialYear,
  groupedPeriods,
}: HeaderColumnProps) => {
  const { formatMessage } = useIntl();

  const { rows } = useContext(RowContext);
  const accountNames = useContext(AccountNamesContext);
  const { movedAccounts, movedAccountsByPeriods } =
    useContext(MoveAccountsContext);

  const companyType = useSelector((state) => state.customers[clientId].type);

  const periodStatuses = useSelector(
    (state) => state.accountingView.clients[clientId]?.periodStatus
  );

  const firstNotLockedPeriod = useMemo(() => {
    if (!periodStatuses) {
      return undefined;
    }

    return financialYear.periods?.find(
      (period) =>
        period.type === 'month' &&
        periodStatuses[period.id]?.status !== 'LOCKED'
    );
  }, [financialYear.periods, periodStatuses]);

  const movedByPeriods = useMemo(() => {
    const newMovedByPeriods: Record<string, Set<string>> = {};

    Object.entries(movedAccounts).forEach(([accountNumber, movedPeriods]) => {
      Object.values(movedPeriods).forEach((moveData) => {
        if (newMovedByPeriods[accountNumber]) {
          newMovedByPeriods[accountNumber].add(moveData.toGroupId);
        } else {
          newMovedByPeriods[accountNumber] = new Set([moveData.toGroupId]);
        }
      });
    });

    return newMovedByPeriods;
  }, [movedAccounts]);

  const getMovingPeriods = useCallback(
    (account: string, group: string): string[] => {
      if (!firstNotLockedPeriod) {
        return [];
      }

      const periods = movedAccountsByPeriods[account] || {};
      const filtered = groupedPeriods
        .filter(
          (period) =>
            period.type !== 'yearEnd' &&
            isSameOrAfter(
              parse(period.start),
              parse(firstNotLockedPeriod.start)
            ) &&
            (!periods[period.start] || periods[period.start] === group)
        )
        .map((item) => item.start);

      const result = filtered.reduce((prev, periodStart) => {
        if (prev.length) {
          const index = groupedPeriods.findIndex(
            (period) => period.start === periodStart
          );
          const previousPeriod = groupedPeriods[index - 1];

          if (!filtered.includes(previousPeriod.start)) {
            return [...prev, periodStart];
          }

          return prev;
        }

        return [periodStart];
      }, [] as string[]);

      return result;
    },
    [firstNotLockedPeriod, groupedPeriods, movedAccountsByPeriods]
  );

  const accountRenderer = useCallback(
    (r: AccountRow, groupId: string): JSX.Element => {
      return (
        <AccountCell
          accountName={
            accountNames[financialYear.id]?.[r.accountNumber]?.name ?? ''
          }
          accountNumber={r.accountNumber}
          groupId={groupId}
          movedByPeriods={movedByPeriods[r.accountNumber]}
          companyType={companyType}
          movingPeriods={getMovingPeriods(r.accountNumber, groupId)}
        />
      );
    },
    [
      accountNames,
      financialYear.id,
      movedByPeriods,
      companyType,
      getMovingPeriods,
    ]
  );

  const groupHeaderRenderer = useCallback(
    (r: GroupHeaderRow): JSX.Element => (
      <GroupCell
        groupName={formatMessage({ id: `reconciliation.row.${r.id}` })}
      />
    ),
    [formatMessage]
  );

  const groupSumRenderer = useCallback(
    (r: KeyFigureRow | GroupSumRow) => (
      <GroupSumCell
        groupSumName={formatMessage({
          id: `reconciliation.row.${r.id}.sum`,
        })}
        groupId={r.id}
      />
    ),
    [formatMessage]
  );

  const keyFigureRenderer = useCallback(
    (r: KeyFigureRow | GroupSumRow) => (
      <GroupSumCell
        groupSumName={formatMessage({
          id: `reconciliation.row.${r.id}`,
        })}
        groupId={r.id}
      />
    ),
    [formatMessage]
  );

  const hiddenRowRenderer = useCallback(
    (r: HiddenAccountRow, groupId = '') => (
      <HiddenRow
        row={r}
        accountName={
          accountNames[financialYear.id]?.[r.accountNumber]?.name ?? ''
        }
        groupId={groupId}
      />
    ),
    [accountNames, financialYear.id]
  );

  const hiddenGroupRowRenderer = useCallback(
    (r: HiddenGroupRowType) => (
      <HiddenGroupRow
        row={r}
        clientId={clientId}
        financialYearId={financialYear.id}
      />
    ),
    [clientId, financialYear]
  );

  return (
    <Column className={className}>
      {rows.map((row) => (
        <RenderColumnContent
          key={`${row.type}_${row.id}`}
          clientId={clientId}
          periodStart={financialYear.start}
          row={row}
          account={accountRenderer}
          groupHeader={groupHeaderRenderer}
          groupSum={groupSumRenderer}
          keyFigure={keyFigureRenderer}
          hiddenRow={hiddenRowRenderer}
          hiddenGroupRow={hiddenGroupRowRenderer}
        />
      ))}
    </Column>
  );
};

export default styled(HeaderColumn)`
  width: 430px;

  & .account,
  & .keyFigure,
  & .groupHeader,
  & .groupSum {
    line-height: 35px;

    border-bottom: 2px solid #eeeeee;
    border-left: 2px solid #eeeeee;
    border-right: 2px solid #eeeeee;
  }

  & .keyFigure,
  & .groupHeader,
  & .groupSum {
    > span > div {
      padding: 2px 24px 2px 34px;
    }
  }

  & .hidden,
  & .hiddenGroup {
    position: relative;
  }
`;
