import React, {
  useState,
  useEffect,
  memo,
  useMemo,
  useCallback,
  useContext,
  useRef,
} from 'react';
import { matchPath, useLocation, useRouteMatch } from 'react-router-dom';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { Tooltip } from '@material-ui/core';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';

import { useApiSdk } from 'api-sdk';
import { Period } from '@agoy/api-sdk-core';
import { overviewTableWidth } from 'theme/measurements';

import { InputData } from '_reconciliation/types';
import { DocumentPasteWrapper } from '_shared/components/UploadDocument';
import { useAccountDocuments } from 'utils/usePeriodDocuments';
import { ClientsRouteParams } from 'routes/types';

import Tabs from './Tabs';

import { getActiveTabs } from './Tabs/config';
import CopyDialog from './CopyUserInputDialog/CopyDialog';
import PeriodDataContext from './PeriodDataContext';
import {
  onUserInputCallbackFunction,
  onUserInputRoutineCallbackFunction,
} from './types';
import { deepCompare } from 'utils/deepCompare';
import { trackCustomEvent } from '@fnox/web-analytics-script';
import { getAdditionalReconciliationPeriodData } from 'utils/piwik';

const StyledExpandLessIcon = styled.div`
  position: absolute;
  top: ${(props) => props.theme.spacing(1.25)}px;
  right: ${(props) => props.theme.spacing(2)}px;
  cursor: pointer;
`;

const CopyIconContainer = styled.div`
  position: absolute;
  top: ${(props) => props.theme.spacing(1.25)}px;
  right: ${(props) => props.theme.spacing(8)}px;
  cursor: pointer;
`;

const Root = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ totalNumberOfColumns; draggingImage? }>`
  grid-column: ${(props) => `1 / ${props.totalNumberOfColumns + 2}`};
  z-index: 2;
  margin-top: -2px;
  outline: ${(props) => (props.draggingImage ? '1px dashed #000' : 'none')};
`;

const StickyWrapper = styled.div`
  position: sticky;
  left: 0;
  border: 2px solid;
  max-width: calc(${overviewTableWidth} - 64px);
  border-color: ${(props) => props.theme.palette.primary.main};
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  overflow: hidden;
`;

interface HiddenRowRouteParams extends ClientsRouteParams {
  tab: string;
}

const useNavigation = (path): HiddenRowRouteParams | undefined => {
  const location = useLocation();
  return useMemo(() => {
    const match = matchPath<HiddenRowRouteParams>(
      location.pathname,
      `${path}/account/:account/:period/:tab`
    );

    return match?.params;
  }, [location, path]);
};

export interface HiddenRowProps {
  clientId: string;
  totalNumberOfColumns?: number;
  onUserInputCallback: onUserInputCallbackFunction;
  onUserInputRoutineCallback: onUserInputRoutineCallbackFunction;
  userData: InputData;
  accountNumber: string;
  accountName: string;
  period: Period;
  groupId: string;

  /**
   * New reconciliation have multiple periods in a cell, this is
   * a list of all periods that are included in for example the selected quarter.
   */
  periods?: Period[];

  onCloseCallback: (period: Period) => void;
  isNewSpecifications: boolean | null;
}

const HiddenRow = (props: HiddenRowProps) => {
  const { formatMessage } = useIntl();
  const sdk = useApiSdk();
  const prevUserData = useRef<InputData | null>(null);

  const {
    totalNumberOfColumns,
    userData,
    accountNumber,
    period,
    clientId,
    onUserInputRoutineCallback,
    onCloseCallback,
  } = props;
  const { visited } = userData || {};

  // HOOKS
  const { path } = useRouteMatch();
  const navigation = useNavigation(path);
  const { addDocument, refetchDocuments } = useAccountDocuments();

  const { periodLocked: isLocked, periodType } = useContext(PeriodDataContext);

  // STATE
  const activeTabs = getActiveTabs(parseInt(accountNumber, 10), periodType);

  const [activeTabId, setActiveTabId] = useState(activeTabs[0]?.id);
  const [copyDialogVisible, setCopyDialogVisible] = useState(false);

  // EFFECTS
  useEffect(() => {
    const updateVisited = async () => {
      await sdk.putUserInput({
        clientid: clientId,
        periodId: period.id,
        accountNumber: parseInt(accountNumber, 10),
        requestBody: {
          visited: true,
        },
      });
    };

    if (!visited && !isLocked) {
      updateVisited();
    }
  }, [accountNumber, period, visited, isLocked, sdk, clientId]);

  useEffect(() => {
    // auto navigate to active tabs, based on url tabId
    const tabId = navigation?.tab;

    if (!tabId) {
      return;
    }

    setActiveTabId(tabId);
  }, [navigation, activeTabs, setActiveTabId]);

  useEffect(() => {
    if (
      activeTabs.length > 0 &&
      !activeTabs.find((tab) => tab.id === activeTabId)
    ) {
      // Reset the active tab upon navigation to cell where the current
      // tab is not present.
      setActiveTabId(activeTabs[0].id);
    }
  }, [activeTabId, activeTabs]);

  useEffect(() => {
    if (prevUserData.current) {
      const changes = deepCompare(prevUserData.current, props.userData);
      Object.keys(changes).forEach((c) => {
        const tab = activeTabId.replace('-', '_');
        const change = c.toLocaleLowerCase();
        trackCustomEvent({
          eventCategory: 'reconciliations',
          eventAction: 'reconciliation_row:update',
          eventName: `${tab}:${change}`,
          data: getAdditionalReconciliationPeriodData(period),
        });
      });
    }

    // Update the ref to the current userData
    prevUserData.current = props.userData;
  }, [props.userData, activeTabId, period]);

  // CALLBACKS
  const handleTabChange = (_, newTab) => {
    if (newTab === 'event-view') {
      refetchDocuments();
    }
    setActiveTabId(newTab);
  };

  const handleCollapseClick = useCallback(() => {
    onCloseCallback(period);
  }, [onCloseCallback, period]);

  const handlePaste = (event: any) => {
    DocumentPasteWrapper(event, addDocument);
  };

  const toggleCopyDialogVisible = () => {
    const tab = activeTabs.find((t) => t.id === activeTabId);
    if (tab && !copyDialogVisible) {
      const tabTranslation = formatMessage({ id: tab.titleId });
      trackCustomEvent({
        eventCategory: 'reconciliations',
        eventAction: 'reconciliation_row:select',
        eventName: `${tabTranslation.toLocaleLowerCase()}:dialog`,
        data: getAdditionalReconciliationPeriodData(period),
      });
    }
    setCopyDialogVisible((currentValue) => !currentValue);
  };

  return (
    <Root totalNumberOfColumns={totalNumberOfColumns} onPaste={handlePaste}>
      {copyDialogVisible && (
        <CopyDialog
          period={period}
          accountNumber={accountNumber}
          open={copyDialogVisible}
          currentTabId={activeTabId}
          onUserInputRoutineCallback={onUserInputRoutineCallback}
          onClose={toggleCopyDialogVisible}
        />
      )}
      <StickyWrapper>
        <Tabs
          tabs={activeTabs}
          currentTabId={activeTabId}
          onTabChange={handleTabChange}
          tabContentProps={props}
        />

        <Tooltip title={formatMessage({ id: 'hidden.copy.tooltip' })}>
          <CopyIconContainer onClick={toggleCopyDialogVisible}>
            <FileCopyIcon />
          </CopyIconContainer>
        </Tooltip>
        <StyledExpandLessIcon onClick={handleCollapseClick}>
          <ExpandLessIcon />
        </StyledExpandLessIcon>
      </StickyWrapper>{' '}
    </Root>
  );
};

export default memo(HiddenRow);
