import { balanceStatementK2v2References } from '../balance-sheet/balancestatementk2v2';
import { costDividedIncomeStatementK2v2References } from '../income-statement/costdividedincomestatementk2v2';
import { cashFlowStatementK2v2References } from '../cash-flow-statement/cashflowstatementk2v2';

type References = {
  [key: string]: string;
};

export const limitedCompaniesReferences = (): References[] => [
  balanceStatementK2v2References(),
  costDividedIncomeStatementK2v2References(),
  cashFlowStatementK2v2References(),
];
