import { AgoyDocumentPart } from '@agoy/document';
import {
  CustomNotes,
  HeadingsSection,
  AnnualReportPart,
} from '@agoy/annual-report-document';
import { customNoteContentDefinition } from '../../shared/customNotes';
import { note2001K2v2ContentDefinition } from './notes/note2001k2v2';
import { note2004K2v2ContentDefinition } from './notes/note2004k2v2';
import { note2005K2v2ContentDefinition } from './notes/note2005k2v2';
export type GeneratedLimitedNotes = {
  headings: HeadingsSection;
  note1: AgoyDocumentPart<typeof note2001K2v2ContentDefinition>;
  note4: AgoyDocumentPart<typeof note2004K2v2ContentDefinition>;
  note: AgoyDocumentPart<typeof note2005K2v2ContentDefinition>;
  custom: CustomNotes;
} & AnnualReportPart;

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };

export const generatedLimitedNotesType = {
  type: 'part' as const,
  children: {
    active: boolean,
    headings: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        notesIncomeStatementTitle: cell,
        notesBalanceSheetTitle: cell,
        notesOtherTitle: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note1: note2001K2v2ContentDefinition,
    note4: note2004K2v2ContentDefinition,
    note: note2005K2v2ContentDefinition,
    custom: customNoteContentDefinition,
  },
};
