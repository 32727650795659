/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6899
 */

import {
  value,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2099K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2099K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Fusion'),
  data: {
    active: true,
    FusioneratBolagAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Företag som övertagits genom fusion'),
      FusionBolagTuple: {
        type: 'group',
        active: true,
        level: 1,
        title: field('Uppgift om företag som övertagits vid fusion'),
        table: table<IxbrlCell>(
          'notes.note99.data.FusioneratBolagAbstract.FusionBolagTuple.table',
          {
            id: 'ForetagetsNamn',
            label: 'Företagets namn',
            dataType: 'text',
            active: true,
          },
          {
            id: 'Organisationsnummer',
            label: 'Organisations\u00ADnummer',
            dataType: 'text',
            active: true,
          },
          {
            id: 'FusionBolagFusionsdag',
            label: 'Fusionsdag',
            dataType: 'text',
            active: true,
          },
          {
            id: 'FusionBolagOvertagetForetagNettoomsattningForeFusionenRegistrering',
            label: 'Nettoomsättning som ingår',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'FusionBolagOvertagetForetagRorelseresultatForeFusionenRegistrering',
            label: 'Rörelseresultat som ingår',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'FusionBolagOvertagetForetagNettoomsattningInteRedovisatOvertagandeForetage',
            label: 'Nettoomsättning som inte ingår',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'FusionBolagOvertagetForetagRorelseresultatInteRedovisatOvertagandeForetage',
            label: 'Rörelseresultat som inte ingår',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'FusionBolagTuple',
                'hidden',
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  '1',
                  'row',
                  ixbrlCell(value('Företagets namn'), {
                    type: 'stringItemType',
                    name: 'se-cd-base:ForetagetsNamn',
                    contextRef: 'period0',
                    standardRubrik: 'Företagets nuvarande namn',
                    negateValue: false,
                    tuple: {
                      name: 'se-gaap-ext:FusionBolagTuple',
                      id: `${rows.getBaseId()}.1.tuple`,
                      tupleID: `${rows.getBaseId()}.1.tuple`,
                    },
                    tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value('Organisations\u00ADnummer'), {
                    type: 'stringItemType',
                    name: 'se-cd-base:Organisationsnummer',
                    contextRef: 'period0',
                    standardRubrik: 'Organisationsnummer',
                    negateValue: false,
                    tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(''), {
                    type: 'dateItemType',
                    name: 'se-gen-base:FusionBolagFusionsdag',
                    contextRef: 'period0',
                    standardRubrik: 'Dag för överlåtande av företag vid fusion',
                    negateValue: false,
                    tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FusionBolagOvertagetForetagNettoomsattningForeFusionenRegistrering',
                    contextRef: 'period0',
                    standardRubrik:
                      'Övertaget företags nettoomsättning före fusionens registrering',
                    saldo: 'credit',
                    negateValue: false,
                    tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FusionBolagOvertagetForetagRorelseresultatForeFusionenRegistrering',
                    contextRef: 'period0',
                    standardRubrik:
                      'Övertaget företags rörelseresultat före fusionens registrering',
                    saldo: 'credit',
                    negateValue: false,
                    tupleRef: tupleRef(5, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FusionBolagOvertagetForetagNettoomsattningInteRedovisatOvertagandeForetage',
                    contextRef: 'period0',
                    standardRubrik:
                      'Övertaget företags nettoomsättning som inte redovisats i det övertagande företaget',
                    saldo: 'credit',
                    negateValue: false,
                    tupleRef: tupleRef(6, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FusionBolagOvertagetForetagRorelseresultatInteRedovisatOvertagandeForetage',
                    contextRef: 'period0',
                    standardRubrik:
                      'Övertaget företags rörelseresultat som inte redovisats i det övertagande företaget',
                    saldo: 'credit',
                    negateValue: false,
                    tupleRef: tupleRef(7, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  })
                );
                return rows.build();
              });
            rows.newRowTemplateGenerator((_id, baseId) => ({
              id: _id,
              active: true,
              type: 'row',
              cells: {
                ForetagetsNamn: ixbrlCell(value('Företagets namn'), {
                  type: 'stringItemType',
                  name: 'se-cd-base:ForetagetsNamn',
                  contextRef: 'period0',
                  standardRubrik: 'Företagets nuvarande namn',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:FusionBolagTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                Organisationsnummer: ixbrlCell(
                  value('Organisations\u00ADnummer'),
                  {
                    type: 'stringItemType',
                    name: 'se-cd-base:Organisationsnummer',
                    contextRef: 'period0',
                    standardRubrik: 'Organisationsnummer',
                    negateValue: false,
                    tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }
                ),
                FusionBolagFusionsdag: ixbrlCell(value(''), {
                  type: 'dateItemType',
                  name: 'se-gen-base:FusionBolagFusionsdag',
                  contextRef: 'period0',
                  standardRubrik: 'Dag för överlåtande av företag vid fusion',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                FusionBolagOvertagetForetagNettoomsattningForeFusionenRegistrering:
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FusionBolagOvertagetForetagNettoomsattningForeFusionenRegistrering',
                    contextRef: 'period0',
                    standardRubrik:
                      'Övertaget företags nettoomsättning före fusionens registrering',
                    saldo: 'credit',
                    negateValue: false,
                    tupleRef: tupleRef(4, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }),
                FusionBolagOvertagetForetagRorelseresultatForeFusionenRegistrering:
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FusionBolagOvertagetForetagRorelseresultatForeFusionenRegistrering',
                    contextRef: 'period0',
                    standardRubrik:
                      'Övertaget företags rörelseresultat före fusionens registrering',
                    saldo: 'credit',
                    negateValue: false,
                    tupleRef: tupleRef(5, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }),
                FusionBolagOvertagetForetagNettoomsattningInteRedovisatOvertagandeForetage:
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FusionBolagOvertagetForetagNettoomsattningInteRedovisatOvertagandeForetage',
                    contextRef: 'period0',
                    standardRubrik:
                      'Övertaget företags nettoomsättning som inte redovisats i det övertagande företaget',
                    saldo: 'credit',
                    negateValue: false,
                    tupleRef: tupleRef(6, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }),
                FusionBolagOvertagetForetagRorelseresultatInteRedovisatOvertagandeForetage:
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FusionBolagOvertagetForetagRorelseresultatInteRedovisatOvertagandeForetage',
                    contextRef: 'period0',
                    standardRubrik:
                      'Övertaget företags rörelseresultat som inte redovisats i det övertagande företaget',
                    saldo: 'credit',
                    negateValue: false,
                    tupleRef: tupleRef(7, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }),
              },
            }));
            return rows.build();
          })
          .build(),
      },
      FusionOverlatandeForetagBalansrakningTuple: {
        type: 'group',
        active: true,
        level: 1,
        title: field(
          'Uppgift om övertaget företags balansräkningar per fusionsdagen'
        ),
        table: table<IxbrlCell>(
          'notes.note99.data.FusioneratBolagAbstract.FusionOverlatandeForetagBalansrakningTuple.table',
          {
            id: 'ForetagetsNamn',
            label: 'Företagets namn',
            dataType: 'text',
            active: true,
          },
          {
            id: 'FusionOverlatandeForetagBalansrakningTypTillgangSkuld',
            label: 'Typ av tillgång eller skuld',
            dataType: 'text',
            active: true,
          },
          {
            id: 'FusionOverlatandeForetagBalansrakningBelopp',
            label: 'Belopp',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'FusionOverlatandeForetagBalansrakningTuple',
                'hidden',
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  '1',
                  'row',
                  ixbrlCell(value('Företagets namn'), {
                    type: 'stringItemType',
                    name: 'se-cd-base:ForetagetsNamn',
                    contextRef: 'period0',
                    standardRubrik: 'Företagets nuvarande namn',
                    negateValue: false,
                    tuple: {
                      name: 'se-gaap-ext:FusionOverlatandeForetagBalansrakningTuple',
                      id: `${rows.getBaseId()}.1.tuple`,
                      tupleID: `${rows.getBaseId()}.1.tuple`,
                    },
                    tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value('Typ av tillgång eller skuld'), {
                    type: 'stringItemType',
                    name: 'se-gen-base:FusionOverlatandeForetagBalansrakningTypTillgangSkuld',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Typ av tillgång, skuld, avsättning eller obeskattad reserv',
                    negateValue: false,
                    tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FusionOverlatandeForetagBalansrakningBelopp',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Belopp per tillgång, (skuld), (avsättning) eller (obeskattad reserv)',
                    negateValue: false,
                    tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  })
                );
                return rows.build();
              });
            rows.newRowTemplateGenerator((_id, baseId) => ({
              id: _id,
              active: true,
              type: 'row',
              cells: {
                ForetagetsNamn: ixbrlCell(value('Företagets namn'), {
                  type: 'stringItemType',
                  name: 'se-cd-base:ForetagetsNamn',
                  contextRef: 'period0',
                  standardRubrik: 'Företagets nuvarande namn',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:FusionOverlatandeForetagBalansrakningTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                FusionOverlatandeForetagBalansrakningTypTillgangSkuld:
                  ixbrlCell(value('Typ av tillgång eller skuld'), {
                    type: 'stringItemType',
                    name: 'se-gen-base:FusionOverlatandeForetagBalansrakningTypTillgangSkuld',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Typ av tillgång, skuld, avsättning eller obeskattad reserv',
                    negateValue: false,
                    tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }),
                FusionOverlatandeForetagBalansrakningBelopp: ixbrlCell(
                  value(),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FusionOverlatandeForetagBalansrakningBelopp',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Belopp per tillgång, (skuld), (avsättning) eller (obeskattad reserv)',
                    negateValue: false,
                    tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }
                ),
              },
            }));
            return rows.build();
          })
          .build(),
        NotFusionKommentar: {
          type: 'group',
          active: true,
          level: 0,
          title: field('Kommentar till specifikation av fusion'),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:NotFusionKommentar',
            standardRubrik: 'Kommentar till specifikation av fusion',
          }),
        },
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2099K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        FusioneratBolagAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            FusionBolagTuple: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                table: { type: 'table' as const },
              },
            },

            FusionOverlatandeForetagBalansrakningTuple: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                table: { type: 'table' as const },

                NotFusionKommentar: {
                  type: 'part' as const,
                  children: {
                    type: type,
                    level: number,
                    active: boolean,

                    title: cell,

                    value: cell,
                  },
                },
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
