/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-arsredovisning-2021-10-31-rev20230131_sv - Noter.csv
 * - Comments: nan
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
  previousYear,
  year,
  sumAccountsIBs,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2046K2v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2046K2v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Andelar i koncernföretag'),
  data: {
    active: true,
    AndelarKoncernforetagAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Andelar i koncernföretag'),
      table: table<IxbrlCell>(
        'notes.note46.data.AndelarKoncernforetagAbstract.table',
        {
          id: 'AndelarKoncernforetagAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'AndelarKoncernforetagAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'AndelarKoncernforetagAnskaffningsvardenIngaende',
                'sum_accounts',
                noteLabel('Ingående anskaffningsvärden'),
                ixbrlCell(ref(sumAccountsIBs('1300:1317', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AndelarKoncernforetagAnskaffningsvarden',
                  standardRubrik: 'Anskaffningsvärden andelar i koncernföretag',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsIBs('1300:1317', false, 'previousYear')),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndelarKoncernforetagAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden andelar i koncernföretag',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows
                .addRowWithType(
                  'AndelarKoncernforetagForandringAnskaffningsvardenAbstract',
                  'header',
                  noteLabel('Förändringar av anskaffningsvärden'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'AndelarKoncernforetagForandringAnskaffningsvardenInkop',
                    'row',
                    noteLabel('Inköp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenInkop',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med inköp av andelar i koncernföretag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenInkop',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med inköp av andelar i koncernföretag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarKoncernforetagForandringAnskaffningsvardenFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för andelar i koncernföretag genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för andelar i koncernföretag genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarKoncernforetagForandringAnskaffningsvardenForsaljningar',
                    'row',
                    noteLabel('Försäljningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenForsaljningar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar av andelar i koncernföretag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenForsaljningar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar av andelar i koncernföretag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarKoncernforetagForandringAnskaffningsvardenLamnadeAktieagartillskott',
                    'row',
                    noteLabel('Lämnade aktieägartillskott'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenLamnadeAktieagartillskott',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband lämnande aktieägartillskott till koncernföretag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenLamnadeAktieagartillskott',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband lämnande aktieägartillskott till koncernföretag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarKoncernforetagForandringAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
                    'row',
                    noteLabel('Återbetalning av lämnat aktieägartillskott'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband återbetalning av lämnat aktieägartillskott till koncernföretag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband återbetalning av lämnat aktieägartillskott till koncernföretag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarKoncernforetagForandringAnskaffningsvardenAretsResultatAndel',
                    'row',
                    noteLabel('Årets resultatandel'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenAretsResultatAndel',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband periodens resultatandel i handelsbolag som är koncernföretag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenAretsResultatAndel',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband periodens resultatandel i handelsbolag som är koncernföretag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarKoncernforetagForandringAnskaffningsvardenOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av andelar i koncernföretag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av andelar i koncernföretag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarKoncernforetagForandringAnskaffningsvardenOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av andelar i koncernföretag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av andelar i koncernföretag',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'AndelarKoncernforetagAnskaffningsvardenUtgaende',
                'sum',
                noteLabel('Utgående anskaffningsvärden'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note46.data.AndelarKoncernforetagAbstract.table.AndelarKoncernforetagAbstract.AndelarKoncernforetagAnskaffningsvardenIngaende.year'
                      ),
                      id(
                        'notes.note46.data.AndelarKoncernforetagAbstract.table.AndelarKoncernforetagAbstract.AndelarKoncernforetagForandringAnskaffningsvardenAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndelarKoncernforetagAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden andelar i koncernföretag',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note46.data.AndelarKoncernforetagAbstract.table.AndelarKoncernforetagAbstract.AndelarKoncernforetagAnskaffningsvardenIngaende.previousYear'
                      ),
                      id(
                        'notes.note46.data.AndelarKoncernforetagAbstract.table.AndelarKoncernforetagAbstract.AndelarKoncernforetagForandringAnskaffningsvardenAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndelarKoncernforetagAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden andelar i koncernföretag',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'AndelarKoncernforetagNedskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående nedskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1318', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AndelarKoncernforetagNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar andelar i koncernföretag',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(ref(sumAccountsIBs('1318', false, 'previousYear')), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AndelarKoncernforetagNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar andelar i koncernföretag',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows
                .addRowWithType(
                  'AndelarKoncernforetagForandringNedskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av nedskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'AndelarKoncernforetagForandringNedskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för andelar i koncernföretag genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för andelar i koncernföretag genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarKoncernforetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
                    'row',
                    noteLabel('Försäljningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar av andelar i koncernföretag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar av andelar i koncernföretag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarKoncernforetagForandringNedskrivningarAterfordaNedskrivningar',
                    'row',
                    noteLabel('Återförda nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av andelar i koncernföretag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av andelar i koncernföretag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarKoncernforetagForandringNedskrivningarAretsOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAretsOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av andelar i koncernföretag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAretsOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av andelar i koncernföretag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarKoncernforetagForandringNedskrivningarAretsNedskrivningar',
                    'row',
                    noteLabel('Årets nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av andelar i koncernföretag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av andelar i koncernföretag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarKoncernforetagForandringNedskrivningarAretsOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAretsOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av andelar i koncernföretag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAretsOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av andelar i koncernföretag',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'AndelarKoncernforetagNedskrivningarUtgaende',
                'sum',
                noteLabel('Utgående nedskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note46.data.AndelarKoncernforetagAbstract.table.AndelarKoncernforetagAbstract.AndelarKoncernforetagNedskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note46.data.AndelarKoncernforetagAbstract.table.AndelarKoncernforetagAbstract.AndelarKoncernforetagForandringNedskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndelarKoncernforetagNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar andelar i koncernföretag',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note46.data.AndelarKoncernforetagAbstract.table.AndelarKoncernforetagAbstract.AndelarKoncernforetagNedskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note46.data.AndelarKoncernforetagAbstract.table.AndelarKoncernforetagAbstract.AndelarKoncernforetagForandringNedskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndelarKoncernforetagNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar andelar i koncernföretag',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'AndelarKoncernforetag',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note46.data.AndelarKoncernforetagAbstract.table.AndelarKoncernforetagAbstract.AndelarKoncernforetagAnskaffningsvardenUtgaende.year'
                  ),
                  id(
                    'notes.note46.data.AndelarKoncernforetagAbstract.table.AndelarKoncernforetagAbstract.AndelarKoncernforetagNedskrivningarUtgaende.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AndelarKoncernforetag',
                standardRubrik: 'Andelar i koncernföretag',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note46.data.AndelarKoncernforetagAbstract.table.AndelarKoncernforetagAbstract.AndelarKoncernforetagAnskaffningsvardenUtgaende.previousYear'
                  ),
                  id(
                    'notes.note46.data.AndelarKoncernforetagAbstract.table.AndelarKoncernforetagAbstract.AndelarKoncernforetagNedskrivningarUtgaende.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AndelarKoncernforetag',
                standardRubrik: 'Andelar i koncernföretag',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      AndelarKoncernforetagKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id('notes.note46.data.AndelarKoncernforetagAbstract.active')
        ),
        title: field('Kommentar till andelar i koncernföretag'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:AndelarKoncernforetagKommentar',
          standardRubrik: 'Kommentar till andelar i koncernföretag',
        }),
      },
    },
    InnehavAndelarKoncernforetag: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Innehav av andelar i koncernföretag'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:InnehavAndelarKoncernforetag',
        standardRubrik: 'Innehav av andelar i koncernföretag',
      }),
      InnehavAndelarKoncernforetagInklusiveUppgiftForetagTuple: {
        type: 'group',
        active: true,
        level: 1,
        title: field(
          'Specifikation innehav av andelar i koncernföretag inklusive uppgifter om företag'
        ),
        table: table<IxbrlCell>(
          'notes.note46.data.InnehavAndelarKoncernforetag.InnehavAndelarKoncernforetagInklusiveUppgiftForetagTuple.table',
          {
            id: 'ForetagetsNamn',
            label: 'Företagets namn',
            dataType: 'text',
            active: true,
          },
          {
            id: 'Organisationsnummer',
            label: 'Organisations\u00ADnummer',
            dataType: 'text',
            active: true,
          },
          {
            id: 'ForetagetsSate',
            label: 'Säte',
            dataType: 'text',
            active: true,
          },
          {
            id: 'InnehavAndelarKoncernforetagAntalAndelar',
            label: 'Antal andelar',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'InnehavAndelarKoncernforetagEgetKapital',
            label: 'Eget kapital',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'InnehavAndelarKoncernforetagAretsResultat',
            label: 'Årets resultat',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'InnehavAndelarKoncernforetagKapitalandel',
            label: 'Kapitalandel',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'InnehavAndelarKoncernforetagRostrattsandel',
            label: 'Rösträttsandel',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'InnehavAndelarKoncernforetagRedovisatVarde',
            label: 'Redovisat värde',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'InnehavAndelarKoncernforetagInklusiveUppgiftForetagTuple',
                'hidden',
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  '1',
                  'row',
                  ixbrlCell(value('Företagets namn'), {
                    type: 'stringItemType',
                    name: 'se-cd-base:ForetagetsNamn',
                    contextRef: 'period0',
                    standardRubrik: 'Företagets nuvarande namn',
                    negateValue: false,
                    tuple: {
                      name: 'se-gaap-ext:InnehavAndelarKoncernforetagInklusiveUppgiftForetagTuple',
                      id: `${rows.getBaseId()}.1.tuple`,
                      tupleID: `${rows.getBaseId()}.1.tuple`,
                    },
                    tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value('Organisations\u00ADnummer'), {
                    type: 'stringItemType',
                    name: 'se-cd-base:Organisationsnummer',
                    contextRef: 'period0',
                    standardRubrik: 'Organisationsnummer',
                    negateValue: false,
                    tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value('Säte'), {
                    type: 'stringItemType',
                    name: 'se-cd-base:ForetagetsSate',
                    contextRef: 'period0',
                    standardRubrik: 'Företagets säte',
                    negateValue: false,
                    tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'sharesItemType',
                    unitRef: 'andelar',
                    name: 'se-gen-base:InnehavAndelarKoncernforetagAntalAndelar',
                    contextRef: 'balans0',
                    standardRubrik: 'Antal andelar per koncernföretag',
                    negateValue: false,
                    tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InnehavAndelarKoncernforetagEgetKapital',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Positivt (negativt) eget kapital per koncernföretag',
                    negateValue: false,
                    tupleRef: tupleRef(5, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InnehavAndelarKoncernforetagAretsResultat',
                    contextRef: 'period0',
                    standardRubrik:
                      'Periodens vinst (förlust) per koncernföretag',
                    negateValue: false,
                    tupleRef: tupleRef(6, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:InnehavAndelarKoncernforetagKapitalandel',
                    contextRef: 'balans0',
                    standardRubrik: 'Kapitalandel per koncernföretag',
                    negateValue: false,
                    tupleRef: tupleRef(7, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:InnehavAndelarKoncernforetagRostrattsandel',
                    contextRef: 'balans0',
                    standardRubrik: 'Rösträttsandel per koncernföretag',
                    negateValue: false,
                    tupleRef: tupleRef(8, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InnehavAndelarKoncernforetagRedovisatVarde',
                    contextRef: 'balans0',
                    standardRubrik: 'Redovisat värde per koncernföretag',
                    saldo: 'debit',
                    negateValue: false,
                    tupleRef: tupleRef(9, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  })
                );
                return rows.build();
              });
            rows.newRowTemplateGenerator((_id, baseId) => ({
              id: _id,
              active: true,
              type: 'row',
              cells: {
                ForetagetsNamn: ixbrlCell(value('Företagets namn'), {
                  type: 'stringItemType',
                  name: 'se-cd-base:ForetagetsNamn',
                  contextRef: 'period0',
                  standardRubrik: 'Företagets nuvarande namn',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:InnehavAndelarKoncernforetagInklusiveUppgiftForetagTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                Organisationsnummer: ixbrlCell(
                  value('Organisations\u00ADnummer'),
                  {
                    type: 'stringItemType',
                    name: 'se-cd-base:Organisationsnummer',
                    contextRef: 'period0',
                    standardRubrik: 'Organisationsnummer',
                    negateValue: false,
                    tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }
                ),
                ForetagetsSate: ixbrlCell(value('Säte'), {
                  type: 'stringItemType',
                  name: 'se-cd-base:ForetagetsSate',
                  contextRef: 'period0',
                  standardRubrik: 'Företagets säte',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                InnehavAndelarKoncernforetagAntalAndelar: ixbrlCell(value(), {
                  type: 'sharesItemType',
                  unitRef: 'andelar',
                  name: 'se-gen-base:InnehavAndelarKoncernforetagAntalAndelar',
                  contextRef: 'balans0',
                  standardRubrik: 'Antal andelar per koncernföretag',
                  negateValue: false,
                  tupleRef: tupleRef(4, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                InnehavAndelarKoncernforetagEgetKapital: ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:InnehavAndelarKoncernforetagEgetKapital',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Positivt (negativt) eget kapital per koncernföretag',
                  negateValue: false,
                  tupleRef: tupleRef(5, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                InnehavAndelarKoncernforetagAretsResultat: ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:InnehavAndelarKoncernforetagAretsResultat',
                  contextRef: 'period0',
                  standardRubrik:
                    'Periodens vinst (förlust) per koncernföretag',
                  negateValue: false,
                  tupleRef: tupleRef(6, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                InnehavAndelarKoncernforetagKapitalandel: ixbrlCell(value(), {
                  type: 'pureItemType',
                  unitRef: 'procent',
                  name: 'se-gen-base:InnehavAndelarKoncernforetagKapitalandel',
                  contextRef: 'balans0',
                  standardRubrik: 'Kapitalandel per koncernföretag',
                  negateValue: false,
                  tupleRef: tupleRef(7, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                InnehavAndelarKoncernforetagRostrattsandel: ixbrlCell(value(), {
                  type: 'pureItemType',
                  unitRef: 'procent',
                  name: 'se-gen-base:InnehavAndelarKoncernforetagRostrattsandel',
                  contextRef: 'balans0',
                  standardRubrik: 'Rösträttsandel per koncernföretag',
                  negateValue: false,
                  tupleRef: tupleRef(8, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                InnehavAndelarKoncernforetagRedovisatVarde: ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:InnehavAndelarKoncernforetagRedovisatVarde',
                  contextRef: 'balans0',
                  standardRubrik: 'Redovisat värde per koncernföretag',
                  saldo: 'debit',
                  negateValue: false,
                  tupleRef: tupleRef(9, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
              },
            }));
            return rows.build();
          })
          .build(),
      },
      InnehavAndelarKoncernforetagKommentar: {
        type: 'group',
        active: true,
        level: 1,
        visible: ref(
          id(
            'notes.note46.data.InnehavAndelarKoncernforetag.InnehavAndelarKoncernforetagInklusiveUppgiftForetagTuple.active'
          )
        ),
        title: field('Kommentar till innehav av andelar i koncernföretag'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:InnehavAndelarKoncernforetagKommentar',
          standardRubrik: 'Kommentar till innehav av andelar i koncernföretag',
        }),
      },
    },
    NotAndelarKoncernforetagKommentar: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Kommentar till specifikation av andelar i koncernföretag'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:NotAndelarKoncernforetagKommentar',
        standardRubrik:
          'Kommentar till specifikation av andelar i koncernföretag',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2046K2v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        AndelarKoncernforetagAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            AndelarKoncernforetagKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        InnehavAndelarKoncernforetag: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            value: cell,

            InnehavAndelarKoncernforetagInklusiveUppgiftForetagTuple: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                table: { type: 'table' as const },
              },
            },

            InnehavAndelarKoncernforetagKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        NotAndelarKoncernforetagKommentar: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            value: cell,
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
