import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useDragDropManager } from 'react-dnd';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as DragIcon } from 'assets/drag.svg';

import { SpecificationColumnContentType } from '_clients/types/types';
import { useApiSdk } from 'api-sdk';
import { addGlobalErrorMessage, addGlobalMessage } from 'redux/actions';
import LoadingLogo from '_shared/components/LoadingLogo';
import Button from '_shared/components/Buttons/Button';
import Typography from '_shared/components/Typography/Typography';
import { DraggableItemTypes } from 'contants';
import Alert from '_shared/components/AlertBanners/Alert';

import ColumnSelector from './ColumnSelector/ColumnSelector';
import PeriodDataContext from '../PeriodDataContext';
import DraggableTable from './DraggableTable';
import ResyncSpecificationDialog from './ResyncSpecificationDialog';
import OtherTasksButton from './OtherTasksButton';
import PrintSpecificationsModal from './PrintSpecification/PrintSpecificationsModal';
import { getColumnSum } from './utils';
import SpecificationMessage from './SpecificationMessage';
import SpecificationsContext from './Context/SpecificationsContext';

interface SpecificationTableProps {
  accountNumber: string;
  accountName: string;
  periodIB: number;
  periodUB: number;
  yearIB: number;
}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 8px;
  overflow: hidden;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const HoverContainer = styled.div<{ visible: boolean }>`
  display: flex;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  min-width: 24px;
  position: relative;
`;

const Hover = styled.div<{ position: number }>`
  position: absolute;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  height: 42px;
  width: 24px;
  top: ${({ position }) => position + 1}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
`;

const TableWrapper = styled.div`
  overflow: scroll;
  border-radius: 10px;
  border: 1px solid #ccc;
  max-width: fit-content;
  max-height: 60vh;
  position: relative;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 16px;
  padding-left: 24px;
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  height: 20px;
  padding: 2px;
`;

const AlertContainer = styled.div`
  padding-left: 24px;
  max-width: 600px;
`;

const SpecificationTable = ({
  accountNumber,
  accountName,
  periodIB,
  periodUB,
  yearIB,
}: SpecificationTableProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [hoverRowOffset, setHoverRowOffset] = useState<number | undefined>();
  const [isDragging, setIsDragging] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isResyncDialogOpen, setIsResyncDialogOpen] = useState(false);
  const [isSyncPreviousYearEndDialogOpen, setIsSyncPreviousYearEndDialogOpen] =
    useState(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  const {
    clientId,
    period,
    financialYear,
    periodLocked,
    periodType,
    previousYearEndLocked,
  } = useContext(PeriodDataContext);
  const {
    specification,
    specificationRows: rows,
    specificationColumns: columns,
    addRow,
    updateCell,
    deleteRows,
    sortByColumn,
    moveColumn,
    moveRow,
    toggleColumn,
    changeColumn,
    deleteColumn,
    selectSaldo,
    initSpecification,
  } = useContext(SpecificationsContext);

  const timerRef = useRef<NodeJS.Timer>();
  const contentRef = useRef<HTMLDivElement | null>(null);

  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();

  const isMissingSaldoColumn = useMemo(() => {
    return !columns.find(
      (column) => column.id === specification.actualBalanceColumnId
    );
  }, [columns, specification.actualBalanceColumnId]);

  const specificationAmount = useMemo(() => {
    if (!specification.actualBalanceColumnId) {
      return 0;
    }
    return getColumnSum(specification.actualBalanceColumnId, rows) ?? 0;
  }, [specification.actualBalanceColumnId, rows]);

  useEffect(() => {
    initSpecification();
  }, [initSpecification, periodLocked]);

  useEffect(() => {
    const unsubscribe = monitor.subscribeToStateChange(() => {
      setIsDragging(monitor.isDragging());
    });

    return () => {
      unsubscribe();
    };
  }, [monitor]);

  const toggleDrawer = () => {
    setDrawerOpen((currentValue) => !currentValue);
  };

  const openResyncDialog = () => {
    setIsResyncDialogOpen(true);
  };

  const openSyncPreviousYearEndDialog = () => {
    if (previousYearEndLocked) {
      setIsSyncPreviousYearEndDialogOpen(true);
    }
  };

  const resyncSpecification = useCallback(async () => {
    if (!specification.id) {
      return;
    }

    try {
      await sdk.resyncSpecification({
        clientid: clientId,
        specificationId: specification.id,
      });
      dispatch(
        addGlobalMessage('success', 'hidden.specification.resyncSuccess')
      );
    } catch {
      dispatch(addGlobalErrorMessage('error'));
    }
  }, [clientId, dispatch, sdk, specification.id]);

  const syncPreviousYearEndSpecification = useCallback(async () => {
    if (!specification.id) {
      return;
    }

    try {
      await sdk.syncPreviousYearEndSpecification({
        clientid: clientId,
        specificationId: specification.id,
      });
      dispatch(
        addGlobalMessage(
          'success',
          'hidden.specification.resyncSuccess.previousYearEnd'
        )
      );
    } catch {
      dispatch(addGlobalErrorMessage('error'));
    }
  }, [clientId, dispatch, sdk, specification.id]);

  const addUserColumn = useCallback(
    async (name: string, contentType: SpecificationColumnContentType) => {
      if (!specification.id) {
        return;
      }
      try {
        await sdk.addUserSpecificationColumn({
          clientid: clientId,
          requestBody: { name, contentType, periodId: period.id },
        });
        dispatch(
          addGlobalMessage('success', 'hidden.specification.addColumn.success')
        );
      } catch {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [clientId, dispatch, period.id, sdk, specification.id]
  );

  const handleDeleteRows = useCallback(
    async (rowsIds: number[]) => {
      await deleteRows(rowsIds);
      setSelectedRows((currentValue) =>
        currentValue.filter((id) => !rowsIds.includes(id))
      );
    },
    [deleteRows]
  );

  const startScrolling = (speed: number, container: HTMLElement) => {
    timerRef.current = setInterval(() => {
      container.scrollBy(0, speed);
    }, 1);
  };

  const cancelScroll = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  useEffect(() => {
    const unsubscribe = monitor.subscribeToOffsetChange(() => {
      const offset = monitor.getClientOffset();
      const type = monitor.getItemType();

      if (type !== DraggableItemTypes.SPECIFICATION_ROW) {
        return;
      }

      cancelScroll();

      if (!offset || !contentRef.current) {
        return;
      }

      const position = contentRef.current.getBoundingClientRect();
      const calculatedOffset = offset.y - position.y;

      if (calculatedOffset < 65) {
        startScrolling(-5, contentRef.current);
      } else if (position.height - calculatedOffset < 25) {
        startScrolling(5, contentRef.current);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [monitor]);

  const togglePrintModal = useCallback(() => {
    setIsPrintModalOpen((currentValue) => !currentValue);
  }, []);

  const rowsAreEmpty =
    rows.length === 0 ||
    rows.every((row) =>
      row.cells.every((cell) => cell.value === null || cell.value === '')
    );

  const showSpecificationMessage =
    rowsAreEmpty && (periodLocked || periodType === 'dead');

  if (showSpecificationMessage) {
    return (
      <Container>
        <SpecificationMessage
          periodType={periodType}
          periodLocked={periodLocked}
        />
      </Container>
    );
  }

  if (!specification.id) {
    return (
      <LoadingContainer>
        <LoadingLogo size="medium" />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <ResyncSpecificationDialog
        open={isResyncDialogOpen}
        onClose={() => setIsResyncDialogOpen(false)}
        onResync={resyncSpecification}
      />
      <ResyncSpecificationDialog
        open={isSyncPreviousYearEndDialogOpen}
        onClose={() => setIsSyncPreviousYearEndDialogOpen(false)}
        onResync={syncPreviousYearEndSpecification}
        previousYearEnd={true}
      />

      <PrintSpecificationsModal
        open={isPrintModalOpen}
        clientId={clientId}
        accountNumber={accountNumber}
        accountName={accountName}
        financialYear={financialYear}
        period={period}
        inputData={{
          ib: periodIB,
          ub: periodUB,
          yearIB,
          specificationAmount,
        }}
        columns={columns}
        rows={rows}
        onClose={togglePrintModal}
      />

      <ColumnSelector
        open={drawerOpen}
        clientId={clientId}
        periodId={period.id}
        accountNumber={accountNumber}
        selectedColumns={columns}
        rows={rows}
        onToggleColumn={toggleColumn}
        onChangeColumn={changeColumn}
        onDeleteColumn={deleteColumn}
        onAddUserColumn={addUserColumn}
        onClose={toggleDrawer}
      />

      <ActionButtons>
        {selectedRows.length ? (
          <>
            {selectedRows.length === rows.length ? (
              <Typography margin="none">
                {formatMessage({ id: 'table.draggable.allSelected' })}
              </Typography>
            ) : (
              <Typography margin="none">
                {selectedRows.length}{' '}
                {formatMessage({
                  id:
                    selectedRows.length > 1
                      ? 'table.draggable.selectedPlural'
                      : 'table.draggable.selected',
                })}
              </Typography>
            )}

            <Button
              size="small"
              variant="outlined"
              color="danger"
              label={formatMessage({ id: 'table.draggable.deleteRows' })}
              startIcon={<StyledDeleteIcon />}
              onClick={() => handleDeleteRows(selectedRows)}
            />
          </>
        ) : (
          <OtherTasksButton
            periodLocked={periodLocked}
            onClickResync={openResyncDialog}
            onClickPrint={togglePrintModal}
            onClickSyncPreviousYearEnd={openSyncPreviousYearEndDialog}
            isPreviousYearEndLocked={previousYearEndLocked ?? false}
          />
        )}
      </ActionButtons>

      {isMissingSaldoColumn && (
        <AlertContainer>
          <Alert type="warning">
            <Typography margin="none">
              {formatMessage({
                id: 'hidden.specification.noSelectedSaldoColumn',
              })}
            </Typography>
          </Alert>
        </AlertContainer>
      )}

      <TableContainer>
        <HoverContainer visible={!!hoverRowOffset && !isDragging}>
          <Hover position={hoverRowOffset || 0}>
            <DragIcon />
          </Hover>
        </HoverContainer>

        <TableWrapper ref={contentRef}>
          <DraggableTable
            columns={columns}
            rows={rows}
            selectedRows={selectedRows}
            sortColumnId={specification.sortColumnId}
            actualBalanceColumnId={specification.actualBalanceColumnId}
            periodLocked={periodLocked}
            onUpdateCell={updateCell}
            onMoveRow={moveRow}
            onMoveColumn={moveColumn}
            onSortColumn={sortByColumn}
            onNewRow={addRow}
            onSelectRow={setSelectedRows}
            onOpenDrawer={toggleDrawer}
            onDeleteRows={handleDeleteRows}
            onHover={setHoverRowOffset}
            onSelectSaldo={selectSaldo}
          />
        </TableWrapper>
      </TableContainer>
    </Container>
  );
};

export default SpecificationTable;
