import React, { useCallback, useContext, useState } from 'react';
import { Box, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import ResetIcon from '@material-ui/icons/Restore';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';

import usePrintState from '_tax/hooks/usePrintState';
import TaxesDataContext from '_tax/service/TaxesDataContext';
import ResetContentDialog from '_shared/components/ResetContent/ResetContentDialog';
import { printDocument } from '_shared/components/PrintedDocument/utils';
import EditingIconButton from '_shared/components/Buttons/EditingIconButton';
import { addGlobalErrorMessage } from 'redux/actions';

import LoadingLogo from '_shared/components/LoadingLogo';
import PageContent from '../PageContent';
import Header from '../../Header';
import Field from '../Field';
import TaxDocumentTable from '../../TaxDocumentTable';
import { numberValue, ReferenceCell } from '@agoy/document';

type ViewSummaryProps = {
  isPrint?: boolean;
};

const PrintButton = styled(IconButton)`
  &:hover {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const Container = styled.div`
  padding: 0 20px;
`;

const RowBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  flex: 1;
  h6 {
    font-weight: 700;
    color: #00313c;
  }
`;

const RowTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ViewSummary = ({ isPrint = false }: ViewSummaryProps) => {
  const { formatMessage } = useIntl();
  const printState = usePrintState();
  const dispatch = useDispatch();

  const [editing, setEditing] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { taxYearData, service, clientId, financialYear } =
    useContext(TaxesDataContext);

  const summarize = taxYearData.state?.document.particularSalaryTax.summarize;

  const toggleEditing = useCallback(() => {
    setEditing((value) => !value);
  }, [setEditing]);

  const handlePrint = useCallback(async () => {
    try {
      setPrintLoading(true);
      await printDocument(
        clientId,
        financialYear,
        'taxes',
        formatMessage({ id: 'tax.summary.header.label' }),
        ['summary'],
        printState
      );
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    } finally {
      setPrintLoading(false);
    }
  }, [clientId, dispatch, financialYear, formatMessage, printState]);

  const onHandleReset = useCallback(() => {
    service.resetDocumentPart('particularSalaryTax.summarize');
  }, [service]);

  const onConfirm = () => {
    onHandleReset();
    setOpen(false);
  };

  if (!summarize) {
    return null;
  }

  const baseId = 'particularSalaryTax.summarize';
  const taxToBeBookedValue = numberValue(summarize.taxToBeBooked);
  const multiplier = taxToBeBookedValue && taxToBeBookedValue < 0 ? -1 : 1;

  return (
    <PageContent>
      <Box maxWidth={650} marginTop={5}>
        <Paper>
          <Header
            mainTable
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
          >
            <Typography variant="h2">
              {formatMessage({ id: 'tax.summary.header.label' })}
            </Typography>
            {!isPrint && (
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <EditingIconButton value={editing} onClick={toggleEditing} />
                <Tooltip
                  title={formatMessage({ id: 'tax.table.reset.tooltip' })}
                >
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <ResetIcon />
                  </IconButton>
                </Tooltip>
                <ResetContentDialog
                  what={formatMessage({ id: `tax.particularSalaryTax.title` })}
                  open={open}
                  onClose={() => {
                    setOpen(false);
                  }}
                  onConfirm={onConfirm}
                />

                <PrintButton onClick={handlePrint} disabled={printLoading}>
                  {printLoading ? <LoadingLogo size="tiny" /> : <PrintIcon />}
                </PrintButton>
              </Box>
            )}
          </Header>
          <Container>
            <RowBox>
              <RowTitleContainer>
                <Typography variant="h6">
                  {formatMessage({ id: 'sum' })}
                </Typography>
                <Typography color="textSecondary">
                  {formatMessage({ id: 'tax.sum.texation.value' })}
                </Typography>
              </RowTitleContainer>
              <Typography variant="h6">
                <Field
                  id={`${baseId}.taxationBasis`}
                  cell={summarize.taxationBasis}
                  isField
                />
              </Typography>
            </RowBox>
            <RowBox>
              <RowTitleContainer>
                <Typography variant="h6">
                  {formatMessage({ id: 'sum' })}
                </Typography>
                <Typography color="textSecondary">
                  {formatMessage({ id: 'tax.particular.summary.label' })}
                </Typography>
              </RowTitleContainer>
              <Typography variant="h6">
                <Field
                  id={`${baseId}.particularSalaryTax`}
                  cell={summarize.particularSalaryTax}
                  isField
                />
              </Typography>
            </RowBox>
            <Typography
              style={{ marginTop: '5px' }}
              variant="h6"
              color="textPrimary"
            >
              {formatMessage({ id: 'tax.summary.recorded.payroll.label' })}
            </Typography>

            <TaxDocumentTable
              editing={editing}
              tableId={`${baseId}.recordedPayrollTax`}
              baseId="taxViewDocument"
              columns={summarize.recordedPayrollTax.columns}
              rows={summarize.recordedPayrollTax.rows}
              isPrint={isPrint}
            />
            <RowBox>
              <Typography variant="h6">
                {formatMessage({ id: 'tax.summary.recorded.tax.value.label' })}
              </Typography>
              <Typography variant="h6">
                <Field
                  id={`${baseId}.taxToBeBooked`}
                  cell={
                    {
                      ...summarize.taxToBeBooked,
                      value: taxToBeBookedValue
                        ? Math.floor(Math.abs(taxToBeBookedValue)) * multiplier
                        : taxToBeBookedValue,
                    } as ReferenceCell
                  }
                  isField
                />
              </Typography>
            </RowBox>
          </Container>
        </Paper>
      </Box>
    </PageContent>
  );
};

export default ViewSummary;
