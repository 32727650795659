import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import { asResultClass, getApiSdk } from 'api-sdk';
import { RootState } from 'redux/reducers';
import { getContext } from 'utils/AgoyAppClient/contextHolder';

import { SET_FEATURE_SWITCH } from './action-types';
import { FeatureSwitchType } from '../types';

export interface SetFeatureSwitchAction {
  type: typeof SET_FEATURE_SWITCH;
  featureSwitch: FeatureSwitchType;
}

export const setFeatureSwitch = (
  featureSwitch: FeatureSwitchType
): SetFeatureSwitchAction => ({
  type: SET_FEATURE_SWITCH,
  featureSwitch,
});

export const checkFeatureSwitch =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());

    const result = await asResultClass(sdk.getFortnoxFeatures());

    if (result.ok) {
      dispatch(setFeatureSwitch(result.val));
    }
  };
