import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Dialog, IconButton, TextField } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useIntl } from 'react-intl';

import Button from '_shared/components/Buttons/Button';
import Alert from '_shared/components/AlertBanners/Alert';
import Typography from '_shared/components/Typography/Typography';

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing(3)}px;
    padding: ${({ theme }) => theme.spacing(4)}px;
  }
`;

const CloseIcon = styled(Close)`
  font-size: 24px;
`;

type EditDialogProps = {
  columnName: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (name: string) => Promise<void>;
  onDelete: () => Promise<void>;
};

const EditDialog = ({
  columnName,
  open,
  onClose,
  onSubmit,
  onDelete,
}: EditDialogProps) => {
  const { formatMessage } = useIntl();

  const [nameValue, setNameValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    setNameValue(columnName);
  }, [columnName]);

  const handleDialogSubmit = async () => {
    setLoading(true);
    await onSubmit(nameValue);
    setLoading(false);
    onClose();
  };

  const handleOnClose = () => {
    setNameValue(columnName);
    onClose();
  };

  const handleDelete = async () => {
    setLoadingDelete(true);
    await onDelete();
    setLoadingDelete(false);
    onClose();
  };

  return (
    <StyledDialog open={open}>
      <Row>
        <Typography variant="h2" margin="none">
          {formatMessage({ id: 'hidden.specification.editColumn.title' })}
        </Typography>
        <IconButton disabled={loading} size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Row>

      <Typography margin="none">
        {formatMessage({ id: 'hidden.specification.editColumn.subTitle' })}
      </Typography>

      <TextField
        value={nameValue}
        onChange={(e) => setNameValue(e.target.value)}
        variant="outlined"
        label={formatMessage({ id: 'hidden.specification.editColumn.label' })}
      />

      <Alert type="warning">
        <Typography margin="none">
          {formatMessage({ id: 'hidden.specification.editColumn.warning' })}
        </Typography>
      </Alert>

      <Row>
        <Button
          label={formatMessage({
            id: 'hidden.specification.editColumn.delete',
          })}
          variant="outlined"
          color="danger"
          loading={loadingDelete}
          disabled={loading}
          onClick={handleDelete}
        />
        <Row>
          <Button
            label={formatMessage({ id: 'cancel' })}
            variant="text"
            disabled={loading}
            onClick={handleOnClose}
          />
          <Button
            label={formatMessage({ id: 'submit' })}
            loading={loading}
            onClick={handleDialogSubmit}
            disabled={loadingDelete}
          />
        </Row>
      </Row>
    </StyledDialog>
  );
};

export default EditDialog;
