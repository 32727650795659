import { AccountRow } from './types';

type AccountType = {
  number: number;
};

export const createEmptyAccount = (
  number: number,
  movedTo?: string
): AccountRow => ({
  type: 'account',
  number,
  hasComment: false,
  hasDocuments: false,
  hasInternalComments: false,
  hasLegacySpecifications: false,
  hasSpecifications: false,
  ib: 0,
  state: 'not_started',
  ub: 0,
  change: 0,
  movedTo,
});

export const getAccounts =
  <T extends AccountType>(
    allAccounts: T[],
    movedAccounts: {
      [accountNumber: string]: string;
    },
    emptyAccount?: (accountNumber: number) => T
  ) =>
  (start: number, end: number): T[] => {
    const rangeAccounts = allAccounts.filter(
      (account) => account.number >= start && account.number <= end
    );

    if (Object.keys(movedAccounts).length) {
      const updated = rangeAccounts.filter(
        (account) => !movedAccounts[account.number]
      );

      Object.entries(movedAccounts).forEach(([accountNumber, movedToGroup]) => {
        const [groupStart, groupEnd] = movedToGroup
          .split('_')
          .map((item) => parseInt(item));

        const account = allAccounts.find(
          (item) => `${item.number}` === accountNumber
        );

        if (groupStart >= start && groupEnd <= end) {
          const addAccount = account || emptyAccount?.(+accountNumber);

          if (addAccount) {
            updated.push({
              ...addAccount,
              movedTo: movedToGroup,
            });
          }
        }
      });

      return updated;
    }

    return rangeAccounts;
  };
