import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { RestoreOutlined, MoreVertOutlined } from '@material-ui/icons';
import { Popover } from '@material-ui/core';
import Typography from '_shared/components/Typography/Typography';
import { ReactComponent as ArrowIcon } from 'assets/arrow.svg';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
`;

const MenuItem = styled(Row)`
  column-gap: 8px;
  padding: 8px 16px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
`;

const IconButton = styled.button`
  all: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-radius: 12px;
  }
`;

const OptionsIcon = styled(MoreVertOutlined)`
  height: ${({ theme }) => theme.spacing(3)}px;
  width: ${({ theme }) => theme.spacing(3)}px;
`;

const UpIcon = styled(ArrowIcon)`
  fill: ${({ theme }) => theme.palette.common.black};
  width: ${({ theme }) => theme.spacing(2)}px;
  margin: 0 ${({ theme }) => theme.spacing(0.5)}px;
`;

const DownIcon = styled(UpIcon)`
  rotate: 180deg;
`;

const LeftIcon = styled(UpIcon)`
  rotate: -90deg;
`;

const RightIcon = styled(UpIcon)`
  rotate: 90deg;
`;

type ColumnOptionsProps = {
  contentType: string;
  disableLeft: boolean;
  disableRight: boolean;
  showResetButton: boolean;
  onMoveLeft: () => void;
  onMoveRight: () => void;
  onSort: (sortOrder: 'ASC' | 'DESC' | null, reset: boolean) => void;
};

function getSortTranslationKeys(contentType: string) {
  switch (contentType) {
    case 'TEXT':
      return ['sortColumnAToZ', 'sortColumnZToA'];
    case 'AMOUNT':
    case 'PERCENTAGE':
      return ['sortColumnLowToHigh', 'sortColumnHighToLow'];
    case 'DATE':
      return ['sortColumnFirstToLast', 'sortColumnLastToFirst'];
    default:
      return ['sortColumnAToZ', 'sortColumnZToA'];
  }
}

const ColumnOptions = ({
  contentType,
  disableLeft,
  disableRight,
  showResetButton,
  onMoveLeft,
  onMoveRight,
  onSort,
}: ColumnOptionsProps) => {
  const { formatMessage } = useIntl();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoveLeft = () => {
    onMoveLeft();
  };

  const handleMoveRight = () => {
    onMoveRight();
  };

  const handleSort = (sortOrder: 'ASC' | 'DESC' | null, reset = false) => {
    onSort(sortOrder, reset);
    setAnchorEl(null);
  };

  const sortTranslationKeys = getSortTranslationKeys(contentType);

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <PopoverContainer>
          <MenuItem onClick={() => handleSort('ASC')}>
            <UpIcon />
            <Typography margin="none">
              {formatMessage({
                id: `reconciliation.hidden.specifications.${sortTranslationKeys[0]}`,
              })}
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => handleSort('DESC')}>
            <DownIcon />
            <Typography margin="none">
              {formatMessage({
                id: `reconciliation.hidden.specifications.${sortTranslationKeys[1]}`,
              })}
            </Typography>
          </MenuItem>
          {showResetButton && (
            <MenuItem onClick={() => handleSort(null, true)}>
              <RestoreOutlined />
              <Typography margin="none">
                {formatMessage({
                  id: 'reconciliation.hidden.specifications.resetSort',
                })}
              </Typography>
            </MenuItem>
          )}
          {!disableLeft && (
            <MenuItem onClick={handleMoveLeft}>
              <LeftIcon />
              <Typography margin="none">
                {formatMessage({
                  id: 'reconciliation.hidden.specifications.moveColumnLeft',
                })}
              </Typography>
            </MenuItem>
          )}
          {!disableRight && (
            <MenuItem onClick={handleMoveRight}>
              <RightIcon />
              <Typography margin="none">
                {formatMessage({
                  id: 'reconciliation.hidden.specifications.moveColumnRight',
                })}
              </Typography>
            </MenuItem>
          )}
        </PopoverContainer>
      </Popover>

      <IconButton onClick={handleClick}>
        <OptionsIcon />
      </IconButton>
    </>
  );
};

export default ColumnOptions;
