import { ReferenceAccountInformation, label, value } from '@agoy/document';
import { active, field } from '@agoy/annual-report-document';
import { GeneratedSharesK2Notes } from './types';
import { getSharesCompanyCustomNote } from '../../shared/customNotes';
import { getNote2001K2v2 } from './notes/note2001k2v2';
import { getNote2004K2v2 } from './notes/note2004k2v2';
import { getNote2005K2v2 } from './notes/note2005k2v2';
import { getNote2009K2v2 } from './notes/note2009k2v2';
import { getNote2010K2v2 } from './notes/note2010k2v2';
import { getNote2014K2v2 } from './notes/note2014k2v2';
import { getNote2029K2v2 } from './notes/note2029k2v2';
import { getNote2021K2v2 } from './notes/note2021k2v2';
import { getNote2023K2v2 } from './notes/note2023k2v2';
import { getNote2026K2v2 } from './notes/note2026k2v2';
import { getNote2032K2v2 } from './notes/note2032k2v2';
import { getNote2034K2v2 } from './notes/note2034k2v2';
import { getNote2035K2v2 } from './notes/note2035k2v2';
import { getNote2036K2v2 } from './notes/note2036k2v2';
import { getNote2037K2v2 } from './notes/note2037k2v2';
import { getNote2038K2v2 } from './notes/note2038k2v2';
import { getNote2040K2v2 } from './notes/note2040k2v2';
import { getNote2041K2v2 } from './notes/note2041k2v2';
import { getNote2042K2v2 } from './notes/note2042k2v2';
import { getNote2044K2v2 } from './notes/note2044k2v2';
import { getNote2043K2v2 } from './notes/note2043k2v2';
import { getNote2046K2v2 } from './notes/note2046k2v2';
import { getNote2047K2v2 } from './notes/note2047k2v2';
import { getNote2048K2v2 } from './notes/note2048k2v2';
import { getNote2049K2v2 } from './notes/note2049k2v2';
import { getNote2050K2v2 } from './notes/note2050k2v2';
import { getNote2051K2v2 } from './notes/note2051k2v2';
import { getNote2053K2v2 } from './notes/note2053k2v2';
import { getNote2059K2v2 } from './notes/note2059k2v2';
import { getNote2061K2v2 } from './notes/note2061k2v2';
import { getNote2060K2v2 } from './notes/note2060k2v2';
import { getNote2063K2v2 } from './notes/note2063k2v2';
import { getNote2067K2v2 } from './notes/note2067k2v2';
import { getNote2075K2v2 } from './notes/note2075k2v2';
import { getNote2077K2v2 } from './notes/note2077k2v2';
import { getNote2081K2v2 } from './notes/note2081k2v2';
import { getNote2082K2v2 } from './notes/note2082k2v2';
import { getNote2083K2v2 } from './notes/note2083k2v2';
import { getNote2085K2v2 } from './notes/note2085k2v2';
import { getNote2087K2v2 } from './notes/note2087k2v2';
import { getNote2092K2v2 } from './notes/note2092k2v2';
import { getNote2095K2v2 } from './notes/note2095k2v2';
import { getNote2096K2v2 } from './notes/note2096k2v2';
import { getNote2100K2v2 } from './notes/note2100k2v2';

export const notesConfigSharesK2Config = (
  accounts: Record<string, ReferenceAccountInformation>,
  financialYears: string[],
  financialYear: string,
  period: string | null,
  isDigitalSubmission: boolean
): GeneratedSharesK2Notes => {
  const noteLabel = (name: string) =>
    isDigitalSubmission ? label(name) : value(name);

  return {
    active: undefined,
    headings: {
      active: undefined,
      number: value(),
      name: value(),
      notesIncomeStatementTitle: active(field('Noter till resultaträkning')),
      notesBalanceSheetTitle: active(field('Noter till balansräkning')),
      notesOtherTitle: active(field('Övriga noter')),
      group: value(),
      section: value(),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note1: getNote2001K2v2(noteLabel, financialYear, financialYears, period),
    note4: getNote2004K2v2(noteLabel, financialYear, financialYears, period),
    note5: getNote2005K2v2(noteLabel, financialYear, financialYears, period),
    note9: getNote2009K2v2(noteLabel, financialYear, financialYears, period),
    note10: getNote2010K2v2(noteLabel, financialYear, financialYears, period),
    note14: getNote2014K2v2(noteLabel, financialYear, financialYears, period),
    note29: getNote2029K2v2(noteLabel, financialYear, financialYears, period),
    note21: getNote2021K2v2(noteLabel, financialYear, financialYears, period),
    note23: getNote2023K2v2(noteLabel, financialYear, financialYears, period),
    note26: getNote2026K2v2(noteLabel, financialYear, financialYears, period),
    note32: getNote2032K2v2(noteLabel, financialYear, financialYears, period),
    note34: getNote2034K2v2(noteLabel, financialYear, financialYears, period),
    note35: getNote2035K2v2(noteLabel, financialYear, financialYears, period),
    note36: getNote2036K2v2(noteLabel, financialYear, financialYears, period),
    note37: getNote2037K2v2(noteLabel, financialYear, financialYears, period),
    note38: getNote2038K2v2(noteLabel, financialYear, financialYears, period),
    note40: getNote2040K2v2(noteLabel, financialYear, financialYears, period),
    note41: getNote2041K2v2(noteLabel, financialYear, financialYears, period),
    note42: getNote2042K2v2(noteLabel, financialYear, financialYears, period),
    note44: getNote2044K2v2(noteLabel, financialYear, financialYears, period),
    note43: getNote2043K2v2(noteLabel, financialYear, financialYears, period),
    note46: getNote2046K2v2(noteLabel, financialYear, financialYears, period),
    note47: getNote2047K2v2(noteLabel, financialYear, financialYears, period),
    note48: getNote2048K2v2(noteLabel, financialYear, financialYears, period),
    note49: getNote2049K2v2(noteLabel, financialYear, financialYears, period),
    note50: getNote2050K2v2(noteLabel, financialYear, financialYears, period),
    note51: getNote2051K2v2(noteLabel, financialYear, financialYears, period),
    note53: getNote2053K2v2(noteLabel, financialYear, financialYears, period),
    note59: getNote2059K2v2(noteLabel, financialYear, financialYears, period),
    note61: getNote2061K2v2(noteLabel, financialYear, financialYears, period),
    note60: getNote2060K2v2(noteLabel, financialYear, financialYears, period),
    note63: getNote2063K2v2(noteLabel, financialYear, financialYears, period),
    note67: getNote2067K2v2(noteLabel, financialYear, financialYears, period),
    note75: getNote2075K2v2(noteLabel, financialYear, financialYears, period),
    note77: getNote2077K2v2(noteLabel, financialYear, financialYears, period),
    note81: getNote2081K2v2(noteLabel, financialYear, financialYears, period),
    note82: getNote2082K2v2(noteLabel, financialYear, financialYears, period),
    note83: getNote2083K2v2(noteLabel, financialYear, financialYears, period),
    note85: getNote2085K2v2(noteLabel, financialYear, financialYears, period),
    note87: getNote2087K2v2(noteLabel, financialYear, financialYears, period),
    note92: getNote2092K2v2(noteLabel, financialYear, financialYears, period),
    note95: getNote2095K2v2(noteLabel, financialYear, financialYears, period),
    note96: getNote2096K2v2(noteLabel, financialYear, financialYears, period),
    note100: getNote2100K2v2(noteLabel, financialYear, financialYears, period),
    custom: getSharesCompanyCustomNote(financialYear, financialYears, period),
  };
};
