import React from 'react';
import styled from '@emotion/styled';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Cancel';

// What Icon goes with what type of message
const IconLookup = {
  info: <InfoIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
};

/**
 * The colors used are not from the theme on purpose, the ones from the theme
 * are too dark to use as background for the message type
 */
const MessageBar = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.warning.main};
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  box-shadow:
    inset 0 2px 4px rgb(0 0 0 / 8%),
    inset 0 4px 8px rgb(0 0 0 / 8%),
    inset 0 8px 16px rgb(0 0 0 / 8%);

  &[data-type='info'] {
    background-color: rgb(211, 236, 255);

    // Icon selector
    > div:first-of-type {
      color: rgb(77, 178, 255);
    }
  }

  &[data-type='warning'] {
    background-color: rgb(255, 236, 182);

    // Icon selector
    > div:first-of-type {
      color: rgb(250, 183, 0);
    }
  }

  &[data-type='error'] {
    background-color: rgb(255, 190, 190);

    // Icon selector
    > div:first-of-type {
      color: rgb(252, 54, 54);
    }
  }
`;

const Icon = styled.div`
  width: 48px;
  align-self: flex-start;
`;

const Text = styled.div`
  flex: 1;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
`;

const Title = styled(Typography)`
  font-weight: 600;
`;

// Use same fontsize as typography body in Mui
const Body = styled.div`
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  font-family: ${({ theme }) => theme.typography.body1.fontFamily};
  max-width: 160ch;
`;

type MessageProps = {
  id: number;
  type: string;
  title: string;
  body: string;
  onClose: (id: number) => void;
};

/**
 * Component that renders a system message
 *
 * @returns rendered message
 */
export const Message = ({
  id,
  type,
  title,
  body,
  onClose,
}: MessageProps): JSX.Element => {
  return (
    <MessageBar key={`system-message-${id}`} data-type={type}>
      <Icon>{IconLookup[type]}</Icon>
      <Text>
        <Title>{title}</Title>
        <Body>{body}</Body>
      </Text>
      <IconButton size="small" onClick={() => onClose(id)}>
        <Close />
      </IconButton>
    </MessageBar>
  );
};

export default Message;
