import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';

import { AgoyTable, ResolvedReference } from '@agoy/document';
import { useSelector } from 'redux/reducers';
import Typography from '_shared/components/Typography/Typography';
import { StyledTableCell } from '_shared/components/CommonTable/TableRow';

import Table from './Table';
import PrintHeader from './PrintHeader';

const PrintTable = styled(Table)`
  .MuiTableCell-root {
    border: 1px ${({ theme }) => theme.palette.grey[400]} solid;
  }
`;

type ImprovementsAndRenovationTablePrintProps = {
  table: AgoyTable;
  propertyCode: ResolvedReference;
  helperPartName: 'renovation' | 'improvements';
  isPerson?: boolean;
};

const ImprovementsAndRenovationTablePrint = ({
  table,
  propertyCode,
  helperPartName,
  isPerson,
}: ImprovementsAndRenovationTablePrintProps) => {
  const { formatMessage } = useIntl();

  const { currentCustomer = '', currentFinancialYear = '' } = useSelector(
    (state) => state.customerView
  );

  const person = useSelector((state) => state.persons[currentCustomer]);
  const company = useSelector((state) => state.customers[currentCustomer]);

  const headerInfo = useMemo(
    () =>
      isPerson
        ? {
            name: `${person?.firstName} ${person?.lastName}`,
            number: person?.personNumber,
          }
        : {
            name: company?.name,
            number: company?.orgNumber || '',
          },
    [person, company]
  );

  return (
    <>
      <PrintHeader
        financialYear={currentFinancialYear}
        name={headerInfo.name}
        number={headerInfo.number}
        propertyCode={propertyCode}
        isPerson
      />
      <Typography variant="h2">
        {formatMessage({
          id: `tax.declaration.form.helper.${helperPartName}.title`,
        })}
      </Typography>
      <PrintTable
        baseId="taxDeclaration"
        tableId={helperPartName}
        rows={table.rows}
        columns={table.columns}
        print
        renderCell={({ cell, column }) => {
          if (column.id === 'account') {
            const value = 'value' in cell && cell.value;
            return (
              <StyledTableCell className="left">
                <span>{value}</span>
              </StyledTableCell>
            );
          }
          return null;
        }}
      />
    </>
  );
};

export default ImprovementsAndRenovationTablePrint;
