import React, { useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { getClasses } from '@agoy/common';
import { useIntl } from 'react-intl';
import DoneIcon from '@material-ui/icons/Done';
import { ReactComponent as Expand } from 'assets/expand-bold.svg';
import { ClickAwayListener, Popper } from '@material-ui/core';

import When from '_shared/components/When/When';
import { Input } from '_shared/components/Inputs/v2/Input';
import Typography from '_shared/components/Typography/Typography';
import useEscapeKeyHandler from '_shared/hooks/useEscapeKeyHandler';
import useDropdownDimensions from '_shared/components/Select/useDropdownDimension';
import { InputContainer } from '../../Input/InputContainers';

export type GenericDropdownItem = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type DropDownWithSelectProps<T extends GenericDropdownItem> = {
  items: Array<T> | null;
  value: string | undefined;
  onValueChange: (item: T) => void;
  placeholder: string;
  label?: string;
};

const DropdownInput = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: ${({ theme }) =>
    theme.palette.taxDeclarationView.field.background};
  padding: ${({ theme }) => theme.spacing(0.5)}px;
  border-radius: 2px;
  border: 0;

  &:hover,
  &:focus-visible {
    background: rgba(0, 0, 0, 0.05);
  }

  svg {
    transition: transform 0.1s ease-in-out;
  }

  &.open svg {
    transform: rotate(-180deg);
  }

  @media print {
    svg {
      display: none;
    }

    background: none;
  }
`;

const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  font-family: 'Exo 2';
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
`;

const FilterInput = styled(Input)`
  padding: ${({ theme }) => theme.spacing(1)}px;
  border-radius: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(1)}px 0px 0px;
  border-bottom: 1px solid #8e9491;
`;

const InnerWrapper = styled.div`
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 0px 0px ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(1)}px;

  button:first-child {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const Item = styled.button`
  cursor: pointer;
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
  border: 0;
  background: none;
  height: 34px;

  span {
    width: ${({ theme }) => theme.spacing(3)}px;
  }

  &:hover,
  &:focus-visible {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const FILTER_INPUT_HEIGHT = 48;
const ITEM_HEIGHT = 34;

const DropdownWithSelect = <T extends GenericDropdownItem>({
  items,
  value,
  onValueChange,
  placeholder,
  label,
}: DropDownWithSelectProps<T>) => {
  const { formatMessage } = useIntl();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const filterInputRef = useRef<HTMLInputElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState<string>('');

  const { parentWidth, maxHeight } = useDropdownDimensions(
    anchorRef,
    ITEM_HEIGHT,
    isOpen
  );

  const classes = getClasses({ open: isOpen });

  // Close dropdown on escape key
  useEscapeKeyHandler(() => setIsOpen(false));

  const filteredItems = items?.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const dynamicHeight = useMemo(() => {
    const totalItemsHeight = (filteredItems?.length ?? 0) * ITEM_HEIGHT;
    const totalContentHeight = totalItemsHeight + FILTER_INPUT_HEIGHT;

    // Minimum: filter input + 1 item; maximum: whatever space is available
    const minHeight = FILTER_INPUT_HEIGHT + ITEM_HEIGHT;

    return Math.min(Math.max(totalContentHeight, minHeight), maxHeight);
  }, [filteredItems, maxHeight]);

  const onChange = (item: T) => {
    setIsOpen(false);
    onValueChange(item);
  };

  return (
    <>
      <InputContainer>
        <When isTrue={!!label}>
          <Typography variant="body2" as="label" margin="none">
            {label}
          </Typography>
        </When>
        <DropdownInput
          ref={anchorRef}
          className={classes}
          onClick={() => {
            setIsOpen((prev) => !prev);
            requestAnimationFrame(() => {
              filterInputRef.current?.focus();
            });
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              setIsOpen((prev) => !prev);
              requestAnimationFrame(() => {
                filterInputRef.current?.focus();
              });
            }
          }}
        >
          {value || placeholder}
          <Expand />
        </DropdownInput>
      </InputContainer>

      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <Popper open={isOpen} placement="bottom" anchorEl={anchorRef.current}>
            <PopupWrapper
              style={{
                width: parentWidth,
                maxHeight: `${dynamicHeight}px`,
              }}
            >
              <FilterInput
                type="text"
                placeholder={formatMessage({
                  id: 'dropdown.filter.placeholder',
                })}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (filteredItems && filteredItems.length > 0) {
                      onChange(filteredItems[0]);
                    }
                  }
                }}
                ref={filterInputRef}
              />
              <InnerWrapper>
                <When
                  isTrue={!!filteredItems && filteredItems.length > 0}
                  fallback={
                    <Item>
                      {formatMessage({ id: 'dropdown.filter.noresult' })}
                    </Item>
                  }
                >
                  {filteredItems?.map((item) => (
                    <Item
                      key={item.name}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          onChange(item);
                        }
                      }}
                      onClick={() => onChange(item)}
                    >
                      {value === item.name ? <DoneIcon /> : <span />}
                      {item.name}
                    </Item>
                  ))}
                </When>
              </InnerWrapper>
            </PopupWrapper>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default DropdownWithSelect;
