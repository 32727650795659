import React, { useEffect, useState } from 'react';
import Button from '_shared/components/Buttons/Button';
import { ButtonProps, Variant } from '_shared/components/Buttons/Button.types';

import ConfirmationDialog from '../Dialogs/ConfirmationDialog';
import { trackCustomEvent } from '@fnox/web-analytics-script';

interface Props extends ButtonProps {
  onClick: () => Promise<void>;
  label: string;
  confirmMessage: string;
  buttonVariant?: Variant;
  size?: 'large' | 'medium' | 'small';
  disabled?: boolean;
  annualReportFetchFromPreviousYear?: boolean;
}

const DeleteButtonWithDialog = ({
  onClick,
  label,
  confirmMessage,
  buttonVariant = 'contained',
  size = 'large',
  disabled = false,
  annualReportFetchFromPreviousYear = false,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open && annualReportFetchFromPreviousYear) {
      trackCustomEvent({
        eventCategory: 'annual_accounts_taxes',
        eventAction: 'annual_report_dialog:view',
        eventName: 'dialog:fetch_previous_financial_year',
      });
    }
  }, [open, annualReportFetchFromPreviousYear]);

  const handleSubmit = async () => {
    setLoading(true);
    setOpen(false);
    await onClick();
    setLoading(false);
  };

  return (
    <>
      <Button
        variant={buttonVariant}
        color="danger"
        onClick={() => setOpen(true)}
        label={label}
        size={size}
        disabled={disabled}
        loading={loading}
      />
      {open && (
        <ConfirmationDialog
          text={confirmMessage}
          onAccept={handleSubmit}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default DeleteButtonWithDialog;
