import React, {
  memo,
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import styled from '@emotion/styled';
import CommentIcon from '@material-ui/icons/Comment';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import { useDispatch, useSelector } from 'react-redux';
import { useDragDropManager } from 'react-dnd';
import { last } from 'lodash';

import {
  ReconciliationBalanceAccountGroup,
  ReconciliationBalanceAccountRow,
  ReconciliationBalanceKeyFigure,
  ReconciliationState,
} from '@agoy/api-sdk-core';
import { addGlobalErrorMessage } from 'redux/actions';
import { asResultClass, useApiSdk } from 'api-sdk';
import { RootState } from 'redux/reducers';
import { getClasses } from '@agoy/common';
import SaldoCheckbox from '_shared/components/SaldoCheckbox/SaldoCheckbox';
import { getPeriodStatusReconciliation } from '_reconciliation/redux/accounting-view/selectors';
import { ReconciliationPeriod } from '@agoy/reconciliation';

import HiddenRowsContext from '../../../RowContext/HiddenRowsContext';
import {
  isPercentageRow,
  formatValue,
  getAccountStateColor,
} from '../../../utils';
import { AccountBalance } from '../../../types';
import RowHoverContext from '../../../RowContext/RowHoverContext';
import { useIsNewSpecifications } from '_shared/HOC/withNewSpecificationsFeatureSwitchContext';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
  background-color: #fafafa;
  border-bottom: 2px solid #eeeeee;
  border-left: 2px solid #eeeeee;
  border-right: 2px solid #eeeeee;

  &.withHiddenRow {
    cursor: pointer;

    &.isHoverEnabled {
      :hover {
        :not(:has(.correctCheckbox:hover)) {
          text-decoration: underline;
        }

        .hoverWrapper {
          display: flex;
        }

        .correctCheckbox {
          display: flex;
        }
      }
    }
  }

  &.hiddenRowOpen {
    z-index: ${({ theme }) => theme.zIndex.accountingView.periodRow};
    border-bottom: 2px solid ${({ theme }) => theme.palette.background.paper};
    border-left: 2px solid ${({ theme }) => theme.palette.primary.main};
    border-right: 2px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

const BalanceCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 100%;
  padding: 5px;
`;

const Change = styled(BalanceCell)`
  border-right: 2px solid #eeeeee;
  min-width: 95px;
  width: 100%;
`;

const OutgoingBalance = styled(BalanceCell)<{
  state: ReconciliationState;
}>`
  min-width: 161px;
  width: 100%;
  justify-content: space-between;
  background-color: ${({ state }) => getAccountStateColor(state)};
  & > div {
    display: flex;
    align-items: center;
  }
`;
const Icons = styled.div`
  & svg {
    font-size: 1rem;
  }
`;

const HoverWrapper = styled.div`
  position: absolute;
  left: 3px;
  top: 3px;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  z-index: 1;
  border-radius: 4px;
  background-color: #f2f4f3;
  mix-blend-mode: multiply;
  display: none;
`;

type GroupSumCellProps = {
  groupId: string;
  period: ReconciliationPeriod;
  row: ReconciliationBalanceAccountGroup | undefined;
  balances?: AccountBalance;
  withHiddenRow?: boolean;
};

const getAccountRows = (
  rows: (
    | ReconciliationBalanceAccountRow
    | ReconciliationBalanceAccountGroup
    | ReconciliationBalanceKeyFigure
  )[]
): ReconciliationBalanceAccountRow[] =>
  rows.filter(
    (item): item is ReconciliationBalanceAccountRow => 'number' in item
  );

const GroupSumCell = ({
  groupId,
  period,
  row,
  balances,
  withHiddenRow = false,
}: GroupSumCellProps) => {
  const isNewSpecifications = useIsNewSpecifications();
  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const { addGroupHiddenRow, removeHiddenRow, hiddenRows } =
    useContext(HiddenRowsContext);
  const { setHoverRow } = useContext(RowHoverContext);

  const { periodChangeVisible, periodUBVisible, movingAccountsMode } =
    useSelector((state: RootState) => state.accountingView);

  const [showHiddenRow, setShowHiddenRow] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const unsubscribe = monitor.subscribeToStateChange(() => {
      setIsDragging(monitor.isDragging());
    });

    return () => {
      unsubscribe();
    };
  }, [monitor]);

  const sumGroupBalance = row?.sum;

  const accountRows = useMemo(() => getAccountRows(row?.rows || []), [row]);

  const actualBalancesCorrect =
    row?.state === 'done' || row?.state === 'checked';

  const actualBalancesError = row && row.state === 'error';

  const lastPeriod = last(period.periods);
  const isPeriodLocked = useSelector(
    getPeriodStatusReconciliation(
      lastPeriod || null,
      (state) => state.status === 'LOCKED'
    )
  );

  useEffect(() => {
    const resetQualityChecked = async () => {
      const isAccountRows =
        row && row.rows.length > 0 && row.rows[0].type === 'account';

      if (!lastPeriod || !row || !isAccountRows || isPeriodLocked) {
        return;
      }

      const accRows = row.rows as ReconciliationBalanceAccountRow[];
      const accountsToUpdate = accRows
        .filter((v) => v.state === 'error')
        .map((v) => v.number);
      if (accountsToUpdate.length > 0) {
        await asResultClass(
          sdk.putActualBalance({
            clientid: period.clientId,
            periodId: lastPeriod.id,
            account: accountsToUpdate,
            preliminary: lastPeriod.preliminary ?? false,
            requestBody: {
              checked: false,
            },
          })
        );
      }
    };
    if (actualBalancesError) {
      resetQualityChecked();
    }
  }, [actualBalancesError]);

  // Show hidden row if it is open for the current period
  useEffect(() => {
    setShowHiddenRow(hiddenRows[groupId]?.period === period);
  }, [hiddenRows, period, groupId]);

  const handleRowHoverOn = useCallback(() => {
    if (!isDragging) {
      setHoverRow({ groupId, accountId: groupId });
    }
  }, [groupId, isDragging, setHoverRow]);

  const handleRowHoverOut = useCallback(() => {
    setHoverRow(null);
  }, [setHoverRow]);

  const handleClick = useCallback(() => {
    if (withHiddenRow && !movingAccountsMode) {
      const newValue = !showHiddenRow;
      if (!newValue) {
        removeHiddenRow(groupId, period);
      }
      setShowHiddenRow(newValue);

      if (row && newValue) {
        addGroupHiddenRow(groupId, period, row, () => {
          setShowHiddenRow(false);
        });
      }
    }
  }, [
    withHiddenRow,
    movingAccountsMode,
    showHiddenRow,
    row,
    removeHiddenRow,
    groupId,
    period,
    addGroupHiddenRow,
  ]);

  const handleCheckboxChange = useCallback(
    async (event) => {
      event.stopPropagation();

      if (!lastPeriod || !row) {
        return;
      }

      const accountsToUpdate = accountRows
        .map((accountRow) => accountRow.number)
        .filter((account) => {
          const accountBalance = balances?.[
            `${row.id}.${account}`
          ] as ReconciliationBalanceAccountRow;

          return isNewSpecifications
            ? !accountBalance?.hasSpecifications
            : !accountBalance?.hasLegacySpecifications;
        });

      const shouldResetQualityChecked = row.state === 'done';

      const result = await asResultClass(
        sdk.putActualBalance({
          clientid: period.clientId,
          periodId: lastPeriod.id,
          account: accountsToUpdate,
          preliminary: lastPeriod.preliminary ?? false,
          requestBody: {
            balance: actualBalancesCorrect ? null : 'current',
            ...(shouldResetQualityChecked && { checked: false }),
          },
        })
      );

      if (result.err) {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [
      lastPeriod,
      period.clientId,
      row,
      accountRows,
      sdk,
      actualBalancesCorrect,
      balances,
      dispatch,
    ]
  );

  const classes = getClasses({
    withHiddenRow: withHiddenRow && !movingAccountsMode,
    hiddenRowOpen: showHiddenRow,
    isHoverEnabled: !isDragging,
  });

  if (!sumGroupBalance) {
    return (
      <Container className={classes}>
        {periodChangeVisible && <Change />}
        {periodUBVisible && <OutgoingBalance state="not_started" />}
      </Container>
    );
  }

  const isPercentage = isPercentageRow(sumGroupBalance.id);

  const state = accountRows.length && row.state ? row.state : 'not_started';

  const dataCy = `${groupId}_${period.type}_${period.start}`;

  return (
    <Container
      className={classes}
      data-cy={dataCy}
      onClick={handleClick}
      onMouseEnter={handleRowHoverOn}
      onMouseLeave={handleRowHoverOut}
    >
      {periodChangeVisible && (
        <Change className="change" data-cy={`${dataCy}_change`}>
          {sumGroupBalance && sumGroupBalance.change
            ? formatValue(sumGroupBalance.change, isPercentage)
            : ''}
        </Change>
      )}
      {periodUBVisible && (
        <OutgoingBalance state={state} className="outgoingBalance">
          <Icons>
            {period.type !== 'dead' && (
              <>
                {(row?.hasInternalComments || row?.hasComment) && (
                  <CommentIcon />
                )}

                {row?.hasDocuments && <AttachmentIcon />}
              </>
            )}
          </Icons>
          <div data-cy={`${dataCy}_outgoingBalance`}>
            {sumGroupBalance.ub !== null
              ? formatValue(sumGroupBalance.ub, isPercentage)
              : ''}

            {period.type !== 'dead' && (
              <SaldoCheckbox
                state={state}
                hasSpecifications={row?.hasSpecifications ?? false}
                visible={state === 'checked'}
                dataCy={`${dataCy}_saldoCheckbox`}
                onClick={handleCheckboxChange}
                isGroup
              />
            )}
          </div>
        </OutgoingBalance>
      )}
      <HoverWrapper className="hoverWrapper" />
    </Container>
  );
};

export default memo(GroupSumCell);
