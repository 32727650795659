/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6784
 */

import {
  value,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2054K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2054K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Finansiella instrument värderade till verkligt värde'),
  data: {
    active: true,
    FinansiellaInstrumentVarderadeVerkligtVardeTuple: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Finansiella instrument värderade till verkligt värde'),
      table: table<IxbrlCell>(
        'notes.note54.data.FinansiellaInstrumentVarderadeVerkligtVardeTuple.table',
        {
          id: 'FinansiellaInstrumentVarderadeVerkligtVardeFinansielltInstrumentTyp',
          label: 'Typ av finansiellt instrument',
          dataType: 'text',
          active: true,
        },
        {
          id: 'FinansiellaInstrumentVarderadeVerkligtVardeRedovisatVardeTillgang',
          label: 'Redovisat värde tillgång',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'FinansiellaInstrumentVarderadeVerkligtVardeRedovisatVardeSkuld',
          label: 'Redovisat värde skuld',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'FinansiellaInstrumentVarderadeVerkligtVardeVardeforandringRedovisadResultatrakning',
          label: 'Värdeförändring redovisad i resultaträkningen',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'FinansiellaInstrumentVarderadeVerkligtVardeVardeforandringRedovisadFondVerkligtVarde',
          label: 'Värdeförändring redovisad i fond för verkligt värde',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'FinansiellaInstrumentVarderadeVerkligtVardeTuple',
              'hidden',
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Typ av finansiellt instrument'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:FinansiellaInstrumentVarderadeVerkligtVardeFinansielltInstrumentTyp',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Typ av finansiellt instrument som värderas till verkligt värde',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:FinansiellaInstrumentVarderadeVerkligtVardeTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaInstrumentVarderadeVerkligtVardeRedovisatVardeTillgang',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Redovisat värde per finansiellt instrument som är tillgång',
                  saldo: 'debit',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaInstrumentVarderadeVerkligtVardeRedovisatVardeSkuld',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Redovisat värde per finansiellt instrument som är skuld',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaInstrumentVarderadeVerkligtVardeVardeforandringRedovisadResultatrakning',
                  contextRef: 'period0',
                  standardRubrik:
                    'Positiv (negativ) värdeförändring redovisad i resultaträkningen per finansiellt instrument',
                  negateValue: false,
                  tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaInstrumentVarderadeVerkligtVardeVardeforandringRedovisadFondVerkligtVarde',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av värdet per finansiellt instrument redovisat i fond verkligt värde',
                  negateValue: false,
                  tupleRef: tupleRef(5, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              FinansiellaInstrumentVarderadeVerkligtVardeFinansielltInstrumentTyp:
                ixbrlCell(value('Typ av finansiellt instrument'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:FinansiellaInstrumentVarderadeVerkligtVardeFinansielltInstrumentTyp',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Typ av finansiellt instrument som värderas till verkligt värde',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:FinansiellaInstrumentVarderadeVerkligtVardeTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
              FinansiellaInstrumentVarderadeVerkligtVardeRedovisatVardeTillgang:
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaInstrumentVarderadeVerkligtVardeRedovisatVardeTillgang',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Redovisat värde per finansiellt instrument som är tillgång',
                  saldo: 'debit',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
              FinansiellaInstrumentVarderadeVerkligtVardeRedovisatVardeSkuld:
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaInstrumentVarderadeVerkligtVardeRedovisatVardeSkuld',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Redovisat värde per finansiellt instrument som är skuld',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
              FinansiellaInstrumentVarderadeVerkligtVardeVardeforandringRedovisadResultatrakning:
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaInstrumentVarderadeVerkligtVardeVardeforandringRedovisadResultatrakning',
                  contextRef: 'period0',
                  standardRubrik:
                    'Positiv (negativ) värdeförändring redovisad i resultaträkningen per finansiellt instrument',
                  negateValue: false,
                  tupleRef: tupleRef(4, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
              FinansiellaInstrumentVarderadeVerkligtVardeVardeforandringRedovisadFondVerkligtVarde:
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaInstrumentVarderadeVerkligtVardeVardeforandringRedovisadFondVerkligtVarde',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av värdet per finansiellt instrument redovisat i fond verkligt värde',
                  negateValue: false,
                  tupleRef: tupleRef(5, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
            },
          }));
          return rows.build();
        })
        .build(),
      FinansiellaInstrumentVarderadeVerkligtVardeUpplysningarDerivat: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Upplysningar om derivat för finansiella instrument värderade till verkligt värde'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:FinansiellaInstrumentVarderadeVerkligtVardeUpplysningarDerivat',
          standardRubrik:
            'Upplysningar om derivat för finansiella instrument värderade till verkligt värde',
        }),
      },
    },
    NotFinansiellaInstrumentVarderadeVerkligtVardeKommentar: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Kommentar till specifikation av finansiella instrument värderade till verkligt värde'
      ),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:NotFinansiellaInstrumentVarderadeVerkligtVardeKommentar',
        standardRubrik:
          'Kommentar till specifikation av finansiella instrument värderade till verkligt värde',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2054K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        FinansiellaInstrumentVarderadeVerkligtVardeTuple: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            FinansiellaInstrumentVarderadeVerkligtVardeUpplysningarDerivat: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },

        NotFinansiellaInstrumentVarderadeVerkligtVardeKommentar: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            value: cell,
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
