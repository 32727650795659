import { useCallback, useState, useEffect, useMemo, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useSelector } from 'redux/reducers';
import { differenceInDays, isBefore } from 'date-fns';

import { parse, parseFormat, isSameOrAfter, isSameOrBefore } from '@agoy/dates';
import { useApiSdk, asResultClass, isApiErrorType } from 'api-sdk';
import { FinancialYear } from '@agoy/api-sdk-core';
import {
  ReferencesTypes,
  Voucher,
  VoucherStatusType,
  VoucherTransaction,
} from 'types/Voucher';
import { addGlobalErrorMessage } from 'redux/actions';
import { transformVouchers } from '_shared/components/VoucherView/utils';
import VoucherNumberContext from '_shared/components/VoucherView/VoucherNumberContext';
import AdjustmentsPrintContext from '_tax/components/Taxes/Adjustments/AdjustmentsPrintContext';
import { Document } from './Documents';
import { getDialogContent } from '../ApiErrorDialog/ApiErrorDialog';

type VoucherFormProps = {
  clientId: string;
  financialYear?: FinancialYear;
  defaultTransactions?: VoucherTransaction[];
  defaultDate?: string;
  defaultSeries?: string;
  defaultDescription?: string;
  defaultDocuments?: Document[];
  reference: ReferencesTypes;
  period: string;
  checkBookedVoucher?: (vouchers: Voucher[]) => Voucher | undefined;
};

export const DEFAULT_TRANSACTIONS: VoucherTransaction[] = [
  {
    id: 1,
    sourceInfo: ' ',
    kind: 'KS',
    typeAmount: 'credit',
    credit: 0,
    debit: 0,
    amount: 0,
    account: 0,
  },
  {
    id: 2,
    sourceInfo: ' ',
    kind: 'KS',
    typeAmount: 'debit',
    credit: 0,
    debit: 0,
    amount: 0,
    account: 0,
  },
];

const getFileUrl = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      if (e.target !== null) {
        resolve(e.target.result as string);
      } else {
        resolve('');
      }
    };

    reader.readAsDataURL(file);
  });
};

const getErrorMessage = (message, errorCode, formatMessage) => {
  if (message) {
    if (message === 'Verifikationsserien är inte manuell (I).') {
      return formatMessage({ id: 'tax.voucher.series.error' });
    }
    return `Fortnox: ${message}`;
  }

  if (errorCode === 'INVALID_VOUCHER_SERIES') {
    return formatMessage({ id: 'tax.voucher.series.notFound' });
  }
  return 'error';
};

const useVoucherForm = ({
  clientId,
  financialYear,
  period,
  defaultTransactions = [],
  defaultDate = '',
  defaultSeries = '',
  defaultDescription = '',
  reference,
  defaultDocuments = [],
  checkBookedVoucher,
}: VoucherFormProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const { getSeriesNextNumber, onChangeSeriesNextNumber } =
    useContext(VoucherNumberContext);

  const { updateVouchersData } = useContext(AdjustmentsPrintContext);

  const isFortnoxIntegration = useSelector(
    ({ customers }) => customers[clientId].integrations?.fortnox
  );

  const [createdVoucher, setCreatedVoucher] = useState<Voucher | undefined>();
  const [description, setDescription] = useState(defaultDescription);
  const [selectedSeries, setSelectedSeries] = useState(defaultSeries);
  const [transactions, setTransactions] =
    useState<VoucherTransaction[]>(defaultTransactions);
  const [actualTransactions, setActualTransactions] =
    useState<VoucherTransaction[]>(defaultTransactions);
  const [date, setDate] = useState(defaultDate);
  const [documents, setDocuments] = useState<Document[]>(defaultDocuments);
  const [voucherNumber, setVoucherNumber] = useState(1);
  const [nextVoucherNumber, setNextVoucherNumber] = useState(1);

  const periods = useSelector(
    ({ customers }) =>
      customers[clientId].rawFinancialYears.find(
        (year) => year.start === financialYear?.start
      )?.periods
  );

  const periodStatus = useSelector(
    ({ accountingView }) => accountingView.clients[clientId].periodStatus
  );

  const accountingPeriod = useMemo(() => {
    const parsedPeriod = parseFormat(period, 'yyyy-MM-dd');
    return periods?.find((item) => item.start === parsedPeriod);
  }, [period, periods]);

  const voucherStatus = useMemo(() => {
    if (!createdVoucher) {
      return VoucherStatusType.SUGGESTION;
    }
    return createdVoucher.preliminary
      ? VoucherStatusType.PRELIMINARY
      : VoucherStatusType.FINAL;
  }, [createdVoucher]);

  const firstNotLockedPeriodStart = useMemo(() => {
    if (!periodStatus || !periods) {
      return undefined;
    }
    const startDate = periods.find(
      (item) => periodStatus[item.id]?.status !== 'LOCKED'
    )?.start;

    return startDate ? parse(startDate) : undefined;
  }, [periodStatus, periods]);

  const [minPeriodDate, maxPeriodDate] = useMemo(
    () =>
      accountingPeriod
        ? [parse(accountingPeriod.start), parse(accountingPeriod.end)]
        : [],
    []
  );

  const getNextVoucherNumber = useCallback(async () => {
    if (!selectedSeries) {
      return;
    }
    const next = await getSeriesNextNumber(selectedSeries);

    setNextVoucherNumber(next);
  }, [getSeriesNextNumber, selectedSeries]);

  const getUploadedDocuments = useCallback(async () => {
    if (!accountingPeriod) {
      return;
    }

    const result = await asResultClass(
      sdk.getFilesByKey({
        clientid: clientId,
        periodId: accountingPeriod.id,
        key: reference,
      })
    );

    if (result.ok) {
      setDocuments(result.val.files);
    }
  }, [accountingPeriod, clientId, reference, sdk]);

  const getCreatedVoucher = useCallback(async () => {
    if (reference === ReferencesTypes.GLIDER) {
      return;
    }

    const result = await asResultClass(
      sdk.getVoucherList({
        clientid: clientId,
        period,
        reference,
      })
    );

    if (result.ok) {
      const vouchers = transformVouchers(result.val);

      const currentPeriodVoucher: Voucher | undefined = checkBookedVoucher
        ? checkBookedVoucher(vouchers)
        : vouchers.find(
            (item) =>
              isSameOrAfter(
                parse(item.transDate),
                parse(accountingPeriod?.start)
              ) &&
              isSameOrBefore(
                parse(item.transDate),
                parse(accountingPeriod?.end)
              )
          );

      setCreatedVoucher(currentPeriodVoucher);

      if (!currentPeriodVoucher) {
        getUploadedDocuments();
      }
    } else {
      if (isApiErrorType(result.val)) {
        if (result.val.handled) {
          return;
        }
        if (result.val.status === 403) {
          dispatch(addGlobalErrorMessage('error.client.not_authorized'));
          return;
        }
        dispatch(addGlobalErrorMessage(''));
        return;
      }
      dispatch(addGlobalErrorMessage('error'));
    }
  }, [
    reference,
    sdk,
    clientId,
    period,
    checkBookedVoucher,
    accountingPeriod?.start,
    accountingPeriod?.end,
    getUploadedDocuments,
    dispatch,
  ]);

  useEffect(() => {
    if (reference === ReferencesTypes.GLIDER || !createdVoucher) {
      return;
    }
    setDescription(createdVoucher.description || '');
    setSelectedSeries(createdVoucher.series);
    setVoucherNumber(createdVoucher.serNumber || 1);
    setTransactions(createdVoucher.transactions);
    setDate(createdVoucher.transDate || '');
    setDocuments(
      createdVoucher.attachments?.map((item) => ({
        id: item.id,
        name: item.name,
        url: item.path || '',
      })) || []
    );
  }, [createdVoucher, reference]);

  useEffect(() => {
    getNextVoucherNumber();
  }, [getNextVoucherNumber]);

  useEffect(() => {
    getCreatedVoucher();
  }, [getCreatedVoucher]);

  useEffect(() => {
    updateVouchersData(reference, {
      selectedDate: date,
      voucherDescription: description,
      status: voucherStatus,
      selectedSeries,
      voucherNumber,
      transactions,
      documents,
      reference,
    });
  }, [
    date,
    description,
    documents,
    getCreatedVoucher,
    reference,
    selectedSeries,
    transactions,
    updateVouchersData,
    voucherNumber,
    voucherStatus,
  ]);

  const onChangeSeries = useCallback(
    (value: string) => {
      if (createdVoucher) return;

      setSelectedSeries(value);
    },
    [createdVoucher]
  );

  const onChangeDescription = useCallback(
    (value: string) => {
      if (createdVoucher) return;

      setDescription(value);
    },
    [createdVoucher]
  );

  const onChangeDate = useCallback(
    (value: string) => {
      if (createdVoucher) return;

      setDate(value);
    },
    [createdVoucher]
  );

  const onAddTransaction = useCallback(
    (transaction: VoucherTransaction) => {
      if (createdVoucher) return;

      setTransactions((currentValue) => [...currentValue, transaction]);
    },
    [createdVoucher]
  );

  const onUpdateTransaction = useCallback(
    (updatedTransaction: VoucherTransaction) => {
      if (createdVoucher) return;

      setTransactions((currentValue) => {
        return currentValue.map((transaction) => {
          if (transaction.id === updatedTransaction.id) {
            return updatedTransaction;
          }
          return transaction;
        });
      });
    },
    [createdVoucher]
  );

  const onRemoveTransaction = useCallback(
    (removeId: string | number) => {
      if (createdVoucher) return;

      setTransactions((currentValue) => {
        if (currentValue.length < 3) {
          return currentValue.map((transaction) => {
            if (removeId === transaction.id) {
              return {
                ...transaction,
                account: 0,
                sourceInfo: '',
                debit: 0,
                amount: 0,
                credit: 0,
              };
            }
            return transaction;
          });
        }
        return currentValue.filter((item) => +item.id !== removeId);
      });
    },
    [createdVoucher]
  );

  const onCreateVoucher = useCallback(
    async (voucher) => {
      const result = createdVoucher?.id
        ? await asResultClass(
            sdk.updateVoucher({
              clientid: clientId,
              voucherid: createdVoucher.id,
              requestBody: {
                preliminary: false,
                series: isFortnoxIntegration ? 'I' : 'Agoy',
                source: voucher.source,
              },
            })
          )
        : await asResultClass(
            sdk.createVoucher({
              clientid: clientId,
              requestBody: voucher,
            })
          );

      if (result.ok) {
        onChangeSeriesNextNumber(voucher.series, nextVoucherNumber + 1);
        getCreatedVoucher();
      } else {
        const error = isApiErrorType(result.val)
          ? getErrorMessage(
              result.val.body?.details?.Message,
              result.val.body.code,
              formatMessage
            )
          : 'error';

        if (!isApiErrorType(result.val) || !result.val.handled) {
          dispatch(addGlobalErrorMessage(error));
        }
      }

      return result;
    },
    [
      createdVoucher,
      sdk,
      clientId,
      isFortnoxIntegration,
      onChangeSeriesNextNumber,
      nextVoucherNumber,
      getCreatedVoucher,
      dispatch,
      formatMessage,
    ]
  );

  // this function is only for glider yet
  const onUpdateVoucher = useCallback(
    async (voucher) => {
      const result = await asResultClass(
        sdk.updateVoucher({
          clientid: clientId,
          voucherid: voucher.id,
          requestBody: {
            preliminary: false,
            series: isFortnoxIntegration ? 'I' : 'Agoy',
            source: voucher.source,
          },
        })
      );

      if (result.ok) {
        onChangeSeriesNextNumber(voucher.series, nextVoucherNumber + 1);
        getCreatedVoucher();
      } else {
        const error = isApiErrorType(result.val)
          ? getErrorMessage(
              result.val.body?.message,
              result.val.body.code,
              formatMessage
            )
          : 'error';

        if (!isApiErrorType(result.val) || !result.val.handled) {
          dispatch(addGlobalErrorMessage(error));
        }
      }

      return result;
    },
    [
      clientId,
      dispatch,
      formatMessage,
      getCreatedVoucher,
      isFortnoxIntegration,
      nextVoucherNumber,
      onChangeSeriesNextNumber,
      sdk,
    ]
  );

  const onSetDocuments = useCallback((newDocument: Document) => {
    setDocuments((prevDocuments) => [...prevDocuments, newDocument]);
  }, []);

  const onChangeDocuments = useCallback(
    async (updatedDocuments: Document[]) => {
      setDocuments(updatedDocuments);

      const deletedDocument = documents.find(
        (doc) => updatedDocuments.indexOf(doc) === -1
      );

      if (deletedDocument) {
        const result = await asResultClass(
          sdk.deleteFile({
            clientid: clientId,
            fileid: deletedDocument.id,
          })
        );

        if (result.err) {
          dispatch(addGlobalErrorMessage('error'));
        }
      }
    },
    [clientId, dispatch, documents, sdk]
  );

  const onAddDocument = useCallback(
    async (file: File) => {
      if (!clientId || !accountingPeriod) {
        return;
      }

      const result = await asResultClass(
        sdk.putFile({
          clientid: clientId,
          periodId: accountingPeriod.id,
          key: reference,
          name: file.name,
          requestBody: file,
        })
      );

      if (result.ok) {
        const newDocument = {
          id: result.val.id,
          name: file.name,
          url: await getFileUrl(file),
        };

        onSetDocuments(newDocument);
      } else {
        if (
          isApiErrorType(result.val) &&
          getDialogContent(formatMessage, result.val.body.code)
        ) {
          // Do not display general error for specific error codes
          return;
        }
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [
      accountingPeriod,
      clientId,
      dispatch,
      onSetDocuments,
      reference,
      sdk,
      formatMessage,
    ]
  );

  const onSetTransactions = useCallback(
    (updatedTransactions: VoucherTransaction[]) => {
      if (createdVoucher) {
        // If the voucher has already been created, we set the actual transactions
        // in order to use for comparison with already booked transactions.
        setActualTransactions(updatedTransactions);
        return;
      }

      setTransactions(updatedTransactions);
    },
    [createdVoucher]
  );

  const onReset = useCallback(() => {
    setDescription(defaultDescription);
    setTransactions(defaultTransactions);
    setDate(defaultDate);
    setDocuments(defaultDocuments);
  }, [defaultDate, defaultDescription, defaultDocuments, defaultTransactions]);

  const onDeleteVoucher = useCallback(async () => {
    if (!createdVoucher?.id) return;

    const { id, series } = createdVoucher;

    const result = await asResultClass(
      sdk.deleteVoucher({
        clientid: clientId,
        voucherid: id,
      })
    );
    if (result.ok) {
      onChangeSeriesNextNumber(series, nextVoucherNumber - 1);
      getCreatedVoucher();
    }
  }, [
    createdVoucher,
    sdk,
    clientId,
    onChangeSeriesNextNumber,
    nextVoucherNumber,
    getCreatedVoucher,
  ]);

  return {
    voucherNumber,
    description,
    selectedSeries,
    transactions,
    date,
    nextVoucherNumber,
    documents,
    voucherStatus,
    maxPeriodDate,
    minPeriodDate,
    actualTransactions,
    disabledDate:
      !!firstNotLockedPeriodStart &&
      !!minPeriodDate &&
      !!maxPeriodDate &&
      (differenceInDays(minPeriodDate, maxPeriodDate) === 0
        ? differenceInDays(minPeriodDate, firstNotLockedPeriodStart) === 0
        : isBefore(minPeriodDate, firstNotLockedPeriodStart)),

    setTransactions: onSetTransactions,
    onAddTransaction,
    onUpdateVoucher,
    onUpdateTransaction,
    onRemoveTransaction,
    onChangeSeries,
    onChangeDescription,
    onCreateVoucher,
    onChangeDate,
    onChangeDocuments,
    onReset,
    onAddDocument,
    onDeleteVoucher,
  };
};

export default useVoucherForm;
