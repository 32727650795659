import { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { addGlobalErrorMessage, addGlobalMessage } from 'redux/actions';
import { asResultClass, isApiErrorType, useApiSdk } from 'api-sdk';
import {
  SpecificationCellType,
  SpecificationCellValueType,
  SpecificationColumnType,
  SpecificationRowType,
  SpecificationType,
} from '_clients/types/types';
import { NotificationContext } from '_shared/services/Notifications/NotificationsContext';
import { isColumnNumeric } from '_reconciliation/util/specifications';
import { useIsNewSpecifications } from '_shared/HOC/withNewSpecificationsFeatureSwitchContext';

import PeriodDataContext from '../../PeriodDataContext';
import SpecificationsContext from './SpecificationsContext';

type SpecificationsProviderProps = {
  accountNumber: string;
  children: React.ReactNode;
};

const getUpdatedCells = (
  cells: SpecificationCellType[],
  updatedCell: {
    rowId: number;
    columnId: number;
    value: SpecificationCellValueType;
  }
) => {
  const updatedCells = [...cells];
  const index = cells.findIndex((c) => c.columnId === updatedCell.columnId);

  if (index === -1) {
    updatedCells.push(updatedCell);
  } else {
    updatedCells[index] = updatedCell;
  }

  return updatedCells;
};

const SpecificationsProvider = ({
  accountNumber,
  children,
}: SpecificationsProviderProps) => {
  const sdk = useApiSdk();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const isNewSpecifications = useIsNewSpecifications();

  const { clientId, period, periodLocked, periodType } =
    useContext(PeriodDataContext);

  const notificationService = useContext(NotificationContext);

  const [specification, setSpecification] = useState<SpecificationType>({});
  const [specificationRows, setSpecificationRows] = useState<
    SpecificationRowType[]
  >([]);
  const [specificationColumns, setSpecificationColumns] = useState<
    SpecificationColumnType[]
  >([]);
  const [unableToCreateSpecification, setUnableToCreateSpecification] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const initSpecification = useCallback(async () => {
    // If a period is locked or dead, we can't create a specification
    if (periodLocked || periodType === 'dead') {
      setUnableToCreateSpecification(true);
      return null;
    }

    if (specification.id) {
      return null;
    }

    try {
      const response = await sdk.addSpecification({
        clientid: clientId,
        periodId: period.id,
        accountNumber: Number(accountNumber),
      });

      setSpecification(response);
      return response;
    } catch {
      dispatch(addGlobalErrorMessage('error'));
      return null;
    }
  }, [
    accountNumber,
    clientId,
    dispatch,
    period.id,
    periodLocked,
    periodType,
    sdk,
    specification.id,
  ]);

  const fetchSpecifications = useCallback(async () => {
    try {
      setLoading(true);
      const response = await sdk.getSpecifications({
        clientid: clientId,
        periodId: period.id,
        accountNumbers: [Number(accountNumber)],
      });

      if (Object.keys(response.accounts).length) {
        const data = response.accounts[accountNumber];
        setSpecification(data.specification);
        setSpecificationRows(data.rows);
        setSpecificationColumns(data.columns);
      }
    } catch {
      dispatch(addGlobalErrorMessage('error'));
    } finally {
      setLoading(false);
    }
  }, [sdk, clientId, period.id, accountNumber, dispatch]);

  useEffect(() => {
    if (isNewSpecifications) {
      fetchSpecifications();
    }
  }, [fetchSpecifications, isNewSpecifications]);

  const updateSpecification = useCallback(
    async ({
      columnId,
      sortOrder,
      actualBalanceColumnId,
      reset = false,
    }: {
      columnId?: number | null;
      sortOrder?: 'ASC' | 'DESC' | null;
      actualBalanceColumnId?: number | null;
      reset?: boolean;
    }) => {
      if (!specification.id) {
        return;
      }
      try {
        const data = await sdk.updateSpecification({
          clientid: clientId,
          specificationId: specification.id,
          requestBody: {
            sortColumnId: reset ? null : columnId,
            sortOrder: reset ? null : sortOrder,
            actualBalanceColumnId,
          },
        });
        setSpecification(data);
      } catch {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [clientId, dispatch, sdk, specification.id]
  );

  const addRow = useCallback(async () => {
    if (!specification.id) {
      return;
    }

    try {
      const newRow = await sdk.addSpecificationRow({
        clientid: clientId,
        specificationId: specification.id,
      });

      setSpecificationRows((prevRows) => [
        ...prevRows,
        { ...newRow, cells: [] },
      ]);
    } catch {
      dispatch(addGlobalErrorMessage('error'));
    }
  }, [specification.id, sdk, clientId, dispatch]);

  const updateCell = useCallback(
    async (
      value: SpecificationCellValueType,
      row: SpecificationRowType,
      columnId: number
    ) => {
      if (!specification.id) {
        return;
      }

      const column = specificationColumns.find((c) => c.id === columnId);

      if (!column) {
        return;
      }

      const formattedValue = (inputValue: string | number | null) => {
        let returnValue = inputValue;
        if (isColumnNumeric(column.contentType) && inputValue !== null) {
          returnValue = Number(
            typeof inputValue === 'string'
              ? inputValue.replace(/,/g, '.')
              : inputValue
          );
          if (Number.isNaN(returnValue)) {
            return inputValue;
          }
        }
        return returnValue;
      };

      const data = {
        clientid: clientId,
        specificationId: specification.id,
        rowId: row.id,
        requestBody: {
          columnId,
          value: formattedValue(value),
        },
      };

      const cellExists = !!row.cells.find((c) => c.columnId === columnId);

      const response = cellExists
        ? await asResultClass(sdk.updateSpecificationCell(data))
        : await asResultClass(sdk.addSpecificationCell(data));

      if (response.ok) {
        setSpecificationRows((prevRows) =>
          prevRows.map((prevRow) =>
            prevRow.id === row.id
              ? {
                  ...prevRow,
                  cells: getUpdatedCells(row.cells, response.val),
                }
              : prevRow
          )
        );
      }
      if (response.err && isApiErrorType(response.val)) {
        const error = response.val.body.message;
        if (error === 'Not valid date') {
          dispatch(
            addGlobalErrorMessage(
              formatMessage({ id: 'table.draggable.invalidDate' })
            )
          );
        } else {
          dispatch(addGlobalErrorMessage('error'));
        }
      }
    },
    [
      clientId,
      dispatch,
      formatMessage,
      sdk,
      specification.id,
      specificationColumns,
    ]
  );

  const deleteRows = useCallback(
    async (rowsIds: number[]) => {
      if (!specification.id) {
        return;
      }
      try {
        await sdk.deleteRows({
          clientid: clientId,
          specificationId: specification.id,
          rowIds: rowsIds,
        });
        setSpecificationRows((currentValue) =>
          currentValue.filter((row) => !rowsIds.includes(row.id))
        );

        dispatch(
          addGlobalMessage(
            'info',
            rowsIds.length > 1
              ? 'hidden.specification.multipleRowsRemoved'
              : 'hidden.specification.rowRemoved'
          )
        );
      } catch {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [clientId, dispatch, sdk, specification.id]
  );

  const sortByColumn = useCallback(
    async (
      columnId: number,
      sortOrder: 'ASC' | 'DESC' | null,
      reset = false
    ) => {
      await updateSpecification({ columnId, sortOrder, reset });
    },
    [updateSpecification]
  );

  const moveColumn = useCallback(
    async (currentIndex: number, moveToIndex: number) => {
      if (!specification.id) {
        return;
      }
      let updatedColumns = [...specificationColumns];
      const currentColumns = [...specificationColumns];
      const movedColumn = updatedColumns[currentIndex];

      updatedColumns.splice(currentIndex, 1);
      updatedColumns.splice(moveToIndex, 0, movedColumn);
      updatedColumns = updatedColumns.map((column, index) => ({
        ...column,
        order: index + 1,
      }));
      try {
        setSpecificationColumns(updatedColumns);
        await sdk.updateSelectedColumnOrder({
          clientid: clientId,
          specificationId: specification.id,
          selectedColumnId: movedColumn.id,
          requestBody: { order: moveToIndex + 1 },
        });
      } catch {
        setSpecificationColumns(currentColumns);
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [clientId, specificationColumns, dispatch, sdk, specification.id]
  );

  const moveRow = useCallback(
    async (currentIndex: number, moveToIndex: number) => {
      if (!specification.id) {
        return;
      }

      let updatedRows = [...specificationRows];
      const currentRows = [...specificationRows];

      const movedRow = updatedRows.splice(currentIndex, 1)[0];
      updatedRows.splice(moveToIndex, 0, movedRow);

      updatedRows = updatedRows.map((row, index) => ({
        ...row,
        order: index + 1,
      }));

      try {
        setSpecificationRows(updatedRows);

        await sdk.updateSpecificationRowOrder({
          clientid: clientId,
          specificationId: specification.id,
          rowId: movedRow.id,
          requestBody: {
            order: moveToIndex + 1,
          },
        });
      } catch {
        setSpecificationRows(currentRows);
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [clientId, dispatch, sdk, specificationRows, specification.id]
  );

  const deleteSelectedColumn = useCallback(
    async (column: SpecificationColumnType) => {
      if (!specification.id) {
        return;
      }

      try {
        await sdk.deleteSpecificationSelectedColumn({
          clientid: clientId,
          specificationId: specification.id,
          selectedColumnId: column.id,
        });
      } catch {
        dispatch(addGlobalErrorMessage('error'));
      }

      if (specification.actualBalanceColumnId === column.id) {
        setSpecification({ ...specification, actualBalanceColumnId: null });
      }

      setSpecificationColumns((currentValue) =>
        currentValue.filter((c) => c.id !== column.id)
      );

      setSpecificationRows((currentValue) =>
        currentValue.map((r) => ({
          ...r,
          cells: r.cells.filter((c) => c.columnId !== column.id),
        }))
      );
    },
    [clientId, dispatch, sdk, specification]
  );

  const addSelectedColumn = useCallback(
    async (column: SpecificationColumnType) => {
      if (!specification.id) {
        return;
      }

      try {
        await sdk.addSpecificationSelectedColumn({
          clientid: clientId,
          specificationId: specification.id,
          requestBody: { columnId: column.id },
        });
      } catch {
        dispatch(addGlobalErrorMessage('error'));
      }

      setSpecificationColumns((currentValue) => [...currentValue, column]);
    },
    [clientId, dispatch, sdk, specification]
  );

  const toggleColumn = useCallback(
    async (column: SpecificationColumnType, value: boolean) => {
      if (value) {
        addSelectedColumn(column);
      } else {
        deleteSelectedColumn(column);
      }
    },
    [addSelectedColumn, deleteSelectedColumn]
  );

  const changeColumn = useCallback(
    async (
      column: SpecificationColumnType,
      updatedField: Partial<SpecificationColumnType>
    ) => {
      const updatedName = updatedField.name;

      if (!updatedName) {
        return;
      }
      try {
        const result = await sdk.updateUserSpecificationColumn({
          clientid: clientId,
          periodId: period.id,
          columnId: column.id,
          requestBody: { name: updatedName },
        });

        setSpecificationColumns((currentValue) =>
          currentValue.map((c) =>
            c.id === column.id ? { ...c, name: updatedName, id: result.id } : c
          )
        );
      } catch {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [clientId, dispatch, period.id, sdk]
  );

  const deleteColumn = useCallback(
    async (columnId: number) => {
      if (!columnId) {
        return;
      }
      try {
        await sdk.deleteUserSpecificationColumn({
          clientid: clientId,
          periodId: period.id,
          columnId: columnId,
        });
      } catch {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [clientId, dispatch, period.id, sdk]
  );

  const selectSaldo = useCallback(
    async (columnId: number) => {
      try {
        await updateSpecification({ actualBalanceColumnId: columnId });
        dispatch(
          addGlobalMessage('success', 'table.draggable.actualBalance.success')
        );
      } catch {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [dispatch, updateSpecification]
  );

  useEffect(() => {
    const sub = notificationService?.subscribe(
      { clientId, topic: 'user-input-changed' },
      (msg) => {
        if (msg.err) {
          console.error('HiddenRow error in notification', msg.val);
          return;
        }

        if (msg.val.topic !== 'user-input-changed') {
          return;
        }

        if (!msg.val.accounts.includes(+accountNumber)) {
          return;
        }

        if (msg.val.information.includes('specification')) {
          if (isNewSpecifications) {
            fetchSpecifications();
          }
        }
      }
    );
    return () => {
      sub?.unsubscribe();
    };
  }, [
    notificationService,
    accountNumber,
    clientId,
    fetchSpecifications,
    isNewSpecifications,
  ]);

  const value = useMemo(
    () => ({
      specification,
      specificationRows,
      specificationColumns,
      unableToCreateSpecification,
      loading,
      addRow,
      updateCell,
      deleteRows,
      sortByColumn,
      moveColumn,
      moveRow,
      toggleColumn,
      changeColumn,
      deleteColumn,
      selectSaldo,
      initSpecification,
    }),
    [
      specification,
      specificationRows,
      specificationColumns,
      unableToCreateSpecification,
      loading,
      addRow,
      updateCell,
      deleteRows,
      sortByColumn,
      moveColumn,
      moveRow,
      toggleColumn,
      changeColumn,
      deleteColumn,
      selectSaldo,
      initSpecification,
    ]
  );

  return (
    <SpecificationsContext.Provider value={value}>
      {children}
    </SpecificationsContext.Provider>
  );
};

export default SpecificationsProvider;
