import styled from '@emotion/styled';
import React from 'react';
import Typography from '_shared/components/Typography/Typography';
import { useIntl } from 'react-intl';

const InfoContainer = styled.div`
  display: flex;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.border.light};
  padding: 16px;
  padding-right: 40px;
  height: auto;
  p span {
    font-size: 1rem;
    color: ${({ theme }) => theme.palette.grey[700]};
    padding-left: 60px;
  }
`;

type SpecificationMessageProps = {
  periodType: string;
  periodLocked: boolean;
};

const SpecificationMessage = ({
  periodType,
  periodLocked,
}: SpecificationMessageProps) => {
  const { formatMessage } = useIntl();

  return (
    <InfoContainer>
      <Typography variant="body1" textStyle="regular" margin="none">
        {(() => {
          if (periodType === 'dead') {
            return (
              <span>{formatMessage({ id: 'table.draggable.periodDead' })}</span>
            );
          }
          if (periodLocked) {
            return (
              <span>
                {formatMessage({ id: 'table.draggable.periodLocked' })}
              </span>
            );
          }
          return null;
        })()}
      </Typography>
    </InfoContainer>
  );
};

export default SpecificationMessage;
