import React from 'react';
import {
  SpecificationCellValueType,
  SpecificationColumnType,
  SpecificationRowType,
  SpecificationType,
} from '_clients/types/types';

type SpecificationsContextType = {
  specification: SpecificationType;
  specificationRows: SpecificationRowType[];
  specificationColumns: SpecificationColumnType[];
  unableToCreateSpecification: boolean;
  loading: boolean;
  initSpecification: () => Promise<SpecificationType | null>;
  addRow: () => Promise<void>;
  updateCell: (
    value: SpecificationCellValueType,
    row: SpecificationRowType,
    columnId: number
  ) => Promise<void>;
  deleteRows: (rowId: number[]) => Promise<void>;
  sortByColumn: (
    columnId: number,
    sortOrder: 'ASC' | 'DESC' | null,
    reset?: boolean
  ) => Promise<void>;
  moveColumn: (fromIndex: number, toIndex: number) => Promise<void>;
  moveRow: (fromIndex: number, toIndex: number) => Promise<void>;
  toggleColumn: (
    column: SpecificationColumnType,
    value: boolean
  ) => Promise<void>;
  changeColumn: (
    column: SpecificationColumnType,
    updatedField: Partial<SpecificationColumnType>
  ) => Promise<void>;
  deleteColumn: (columnId: number) => Promise<void>;
  selectSaldo: (columnId: number) => Promise<void>;
};

const defaultFunction = () => {
  throw new Error('No context provided');
};

const SpecificationsContext = React.createContext<SpecificationsContextType>({
  specification: {},
  specificationRows: [],
  specificationColumns: [],
  unableToCreateSpecification: false,
  loading: false,
  initSpecification: defaultFunction,
  addRow: defaultFunction,
  updateCell: defaultFunction,
  deleteRows: defaultFunction,
  sortByColumn: defaultFunction,
  moveColumn: defaultFunction,
  moveRow: defaultFunction,
  toggleColumn: defaultFunction,
  changeColumn: defaultFunction,
  deleteColumn: defaultFunction,
  selectSaldo: defaultFunction,
});

export default SpecificationsContext;
