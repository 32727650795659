import React, { useMemo } from 'react';
import {
  isTableType,
  stringValue,
  filterRows,
  numberValue,
  booleanValue,
  BooleanCell,
} from '@agoy/document';
import styled from '@emotion/styled';
import { getClasses } from '@agoy/common';
import {
  countActiveRows,
  getFieldType,
  isAnyGroupActive,
  isNoteContainer,
  removeDuplicatedIXBRLFromFirstColumn,
} from './helpers';
import HierarchicalPrintTable from '../../../UI/HierarchicalPrintTable/v2/HierarchicalPrintTable';
import { Note, NoteContainer, NoteContent, NoteProps } from './types';
import { IXElement } from '../../../ARKeyToIX';
import Typography from '../../../UI/Typography/Typography';
import Spacer from '../../../UI/helpers/Spacer';
import splitTableByColumns from '../../../utils/splitTableByColumns';
import { useIntl } from 'react-intl';

const TableWrapper = styled.div`
  &.auto-page-break {
    table {
      page-break-inside: auto;
    }
  }
`;

const NoteWrapper = styled.div`
  &.avoid-break {
    page-break-inside: avoid;

    table {
      page-break-inside: avoid;
    }
  }
`;

interface NoteContentProps {
  note: Note | NoteContainer | NoteContent;
  id: string;
  baseId: string;
  hasPreviousYear?: boolean;
}

const NoteContentRender = ({
  note,
  id,
  baseId,
  hasPreviousYear,
}: NoteContentProps): React.ReactElement | null => {
  const fields = Object.keys(note).filter(
    (key) =>
      ![
        'name',
        'section',
        'number',
        'group',
        'validForReportType',
        'type',
      ].includes(key)
  );
  const newBaseId = baseId ? `${baseId}.${id}` : id;

  const isGroupActive = booleanValue(note.active as BooleanCell);

  const isGroupVisible =
    note.visible !== undefined
      ? booleanValue(note.visible as BooleanCell)
      : isGroupActive;

  const groupIsCommentFor = useMemo(() => {
    /**
     * since note comment is located in wrong group, it might happen that
     * it will be in one group with table and commentForTableGroup = true.
     * It will be helpful to have more precise ways of determining what group contains and what comment relate to.
     */
    if (id.includes('Kommentar') && id.includes('Not')) {
      return 'note';
    }
    if ('visible' in note) {
      return 'table';
    }
    return undefined;
  }, [id, note]);

  const content = fields.map((fieldKey) => {
    const field = note[fieldKey];
    const type = getFieldType(field);

    if (!type) return null;

    const key = `${newBaseId}.${fieldKey}`;

    if (type === 'group') {
      return (
        <NoteContentRender
          key={key}
          note={note[fieldKey]}
          baseId={newBaseId}
          id={fieldKey}
          hasPreviousYear={hasPreviousYear}
        />
      );
    }

    if (!note.active) return null;

    if (isTableType(field)) {
      const columns = field.columns.filter(
        ({ id }) => id !== 'previousYear' || hasPreviousYear
      );

      const splitTables = splitTableByColumns(
        { ...field, columns },
        (_, index) => index === 0
      );

      // We need to remove ixbrl data from the first column of second and third table
      // otherwise it will be duplicated in the print and the note will not be correctly validated
      const filteredData = removeDuplicatedIXBRLFromFirstColumn(splitTables);

      const caption = isNoteContainer(note) ? stringValue(note.title) : '';

      return filteredData
        .filter((table) => booleanValue(table.active))
        .map((table, index) => {
          // If we have more than 20 rows we want to allow page break inside the table
          const filteredTable = filterRows(table, undefined, false);
          const numberOfActiveRowsToAvoidPageBreak = 20;

          return (
            <React.Fragment key={`${key}-${index}`}>
              <Spacer size={2} />
              <TableWrapper
                className={getClasses({
                  'auto-page-break':
                    countActiveRows(filteredTable) >
                    numberOfActiveRowsToAvoidPageBreak,
                })}
              >
                <HierarchicalPrintTable
                  table={filteredTable}
                  tableId={`notes.${newBaseId}.${fieldKey}`}
                  caption={index === 0 ? caption : ''}
                />
              </TableWrapper>
              <Spacer size={1} />
            </React.Fragment>
          );
        });
    }

    const value = stringValue(field);
    const isTitle = fieldKey === 'title';
    const isNote1 = newBaseId.startsWith('note1.data');

    if (isTitle) {
      // There is a special case for Note1 where we don't want to print the title for comments
      const hideCommentTitleInNote1 = isNote1 && value?.includes('Kommentar');

      // In Note1 we don't want to print the title for Avskrivningsprinciper (only table in Note1)
      const hideDeprecationTitle = isNote1 && value === 'Avskrivningsprinciper';

      // Only print titles for Note1
      if (!isNote1 || hideCommentTitleInNote1 || hideDeprecationTitle)
        return null;

      return (
        <Typography key={key} variant="h4">
          {value}
        </Typography>
      );
    }

    // add standardRubrik on comments
    const isComment = field.ixbrl?.standardRubrik.includes('Kommentar');

    if (!value?.trim()) return false;

    const valueByNewLine = value.split('\n');

    return (
      <React.Fragment key={key}>
        {isComment && groupIsCommentFor !== 'table' && (
          <Typography variant={id.includes('Not') ? 'h4' : 'h5'}>
            {field.ixbrl.standardRubrik}
          </Typography>
        )}
        <IXElement arPart={field}>
          <Typography variant="body1" as="div">
            {valueByNewLine.map((line, index) =>
              line === '' ? (
                <br key={`${key}-${index}`} />
              ) : (
                <Typography
                  key={`${key}-${index}`}
                  variant="body1"
                  margin="none"
                >
                  {line}
                </Typography>
              )
            )}
          </Typography>
        </IXElement>
      </React.Fragment>
    );
  });

  return <>{isGroupVisible && content}</>;
};

/**
 * This component renders notes that consist of name, fields and tables.
 *
 */
const NoteComponent = ({ noteId, note, hasPreviousYear }: NoteProps) => {
  const { formatMessage } = useIntl();
  const isCustom = noteId.includes('custom');

  const isGroupInactive = !isCustom && !isAnyGroupActive(note.data);
  const isCustomInactive = isCustom && !booleanValue(note.active);

  if (isGroupInactive || isCustomInactive) return null;

  return (
    <NoteWrapper
      // Make an exception for Note1, where we can't try to avoid page break
      // For the rest try to avoid page break inside a note and a table
      className={getClasses({ 'avoid-break': numberValue(note.number) !== 1 })}
    >
      <Typography variant="h3">
        {formatMessage(
          { id: 'annualReport.notes.title' },
          {
            number: numberValue(note.number),
            title: stringValue(note.name),
          }
        )}
      </Typography>

      <NoteContentRender
        note={isCustom ? note : note.data}
        id={isCustom ? noteId : 'data'}
        baseId={isCustom ? '' : noteId}
        hasPreviousYear={hasPreviousYear}
      />
    </NoteWrapper>
  );
};

export default NoteComponent;
