/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6818 (former Note 93)
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
  previousYear,
  year,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2028K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2028K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Skatt på årets resultat'),
  data: {
    active: true,
    SkattPeriodensResultatAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Skatt på periodens resultat'),
      table: table<IxbrlCell>(
        'notes.note28.data.SkattPeriodensResultatAbstract.table',
        {
          id: 'SkattPeriodensResultatAbstract',
          label: 'Skatt på årets resultat',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'SkattPeriodensResultatAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows
                .addRowWithType(
                  'AktuellSkattAbstract',
                  'header',
                  noteLabel('Aktuell skatt'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'AktuellSkattNuvarandePeriod',
                    'row',
                    noteLabel('Aktuell skatt'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AktuellSkattNuvarandePeriod',
                      standardRubrik: 'Aktuell skatt på periodens resultat',
                      saldo: 'debit',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AktuellSkattNuvarandePeriod',
                      standardRubrik: 'Aktuell skatt på periodens resultat',
                      saldo: 'debit',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AktuellSkattJusteringTidigarePeriod',
                    'row',
                    noteLabel('Justering avseende tidigare år'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AktuellSkattJusteringTidigarePeriod',
                      standardRubrik:
                        'Justering avseende tidigare perioders aktuella skatt',
                      saldo: 'debit',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AktuellSkattJusteringTidigarePeriod',
                      standardRubrik:
                        'Justering avseende tidigare perioders aktuella skatt',
                      saldo: 'debit',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'AktuellSkatt',
                'sum',
                noteLabel('Summa'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note28.data.SkattPeriodensResultatAbstract.table.SkattPeriodensResultatAbstract.AktuellSkattAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'period0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AktuellSkatt',
                    standardRubrik: 'Aktuell skattekostnad',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note28.data.SkattPeriodensResultatAbstract.table.SkattPeriodensResultatAbstract.AktuellSkattAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'period1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AktuellSkatt',
                    standardRubrik: 'Aktuell skattekostnad',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows
                .addRowWithType(
                  'UppskjutenSkattAbstract',
                  'header',
                  noteLabel('Uppskjuten skatt'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'ForandringUppskjutenSkattTemporaraSkillnader',
                    'row',
                    noteLabel(
                      'Förändring av uppskjuten skatt avseende temporära skillnader'
                    ),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringUppskjutenSkattTemporaraSkillnader',
                      standardRubrik:
                        'Förändring av uppskjuten skatt avseende temporära skillnader',
                      saldo: 'debit',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringUppskjutenSkattTemporaraSkillnader',
                      standardRubrik:
                        'Förändring av uppskjuten skatt avseende temporära skillnader',
                      saldo: 'debit',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForandringUppskjutenSkattefodranUnderskottsavdrag',
                    'row',
                    noteLabel(
                      'Förändring av uppskjuten skattefordran avseende underskottsavdrag'
                    ),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringUppskjutenSkattefodranUnderskottsavdrag',
                      standardRubrik:
                        'Förändring av uppskjuten skattefordran avseende underskottsavdrag',
                      saldo: 'debit',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringUppskjutenSkattefodranUnderskottsavdrag',
                      standardRubrik:
                        'Förändring av uppskjuten skattefordran avseende underskottsavdrag',
                      saldo: 'debit',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'UppskjutenSkatt',
                'sum',
                noteLabel('Summa'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note28.data.SkattPeriodensResultatAbstract.table.SkattPeriodensResultatAbstract.UppskjutenSkattAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'period0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:UppskjutenSkatt',
                    standardRubrik: 'Uppskjuten skattekostnad',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note28.data.SkattPeriodensResultatAbstract.table.SkattPeriodensResultatAbstract.UppskjutenSkattAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'period1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:UppskjutenSkatt',
                    standardRubrik: 'Uppskjuten skattekostnad',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'SkattAretsResultat',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note28.data.SkattPeriodensResultatAbstract.table.SkattPeriodensResultatAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'period0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:SkattAretsResultat',
                standardRubrik: 'Skatt på periodens resultat',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note28.data.SkattPeriodensResultatAbstract.table.SkattPeriodensResultatAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'period1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:SkattAretsResultat',
                standardRubrik: 'Skatt på periodens resultat',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      SkattPeriodensResultatKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id('notes.note28.data.SkattPeriodensResultatAbstract.active')
        ),
        title: field('Kommentar till skatt på periodens resultat'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:SkattPeriodensResultatKommentar',
          standardRubrik: 'Kommentar till skatt på periodens resultat',
        }),
      },
    },
    AvstamningEffktivSkattAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Avstämning av effektiv skatt'),
      table: table<IxbrlCell>(
        'notes.note28.data.AvstamningEffktivSkattAbstract.table',
        {
          id: 'AvstamningEffktivSkattAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'AvstamningEffktivSkattAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'ResultatForeSkatt',
                'row',
                noteLabel('Resultat före skatt'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatForeSkatt',
                  standardRubrik: 'Resultat före skatt',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatForeSkatt',
                  standardRubrik: 'Resultat före skatt',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'AvstamningEffktivSkattBeraknadSkattesats',
                'row',
                noteLabel('Gällande skattesats (%)'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'pureItemType',
                  unitRef: 'procent',
                  name: 'se-gen-base:AvstamningEffktivSkattBeraknadSkattesats',
                  standardRubrik: 'Gällande skattesats i procent',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'pureItemType',
                  unitRef: 'procent',
                  name: 'se-gen-base:AvstamningEffktivSkattBeraknadSkattesats',
                  standardRubrik: 'Gällande skattesats i procent',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'AvstamningEffktivSkattEnligtGallandeSkattesats',
                'row',
                noteLabel('Skatt enligt gällande skattesats'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvstamningEffktivSkattEnligtGallandeSkattesats',
                  standardRubrik: 'Skatt enligt gällande skattesats',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvstamningEffktivSkattEnligtGallandeSkattesats',
                  standardRubrik: 'Skatt enligt gällande skattesats',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          return rows.build();
        })
        .build(),
    },
    AvstamningEffktivSkattVasentligPostTuple: {
      type: 'group',
      active: true,
      level: 1,
      title: field(
        'Väsentliga poster som har påverkat sambandet mellan periodens skattekostnad eller skatteintäkt och redovisat resultat före skatt'
      ),
      table: table<IxbrlCell>(
        'notes.note28.data.AvstamningEffktivSkattVasentligPostTuple.table',
        {
          id: 'AvstamningEffktivSkattVasentligPostBenamning',
          label: 'Typ av väsentlig post',
          dataType: 'text',
          active: true,
        },
        {
          id: 'AvstamningEffktivSkattVasentligPost',
          label: 'Belopp',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'AvstamningEffktivSkattVasentligPostSkatteeffekt',
          label: 'Procent',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'AvstamningEffktivSkattVasentligPostTuple',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Typ av väsentlig post'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:AvstamningEffktivSkattVasentligPostBenamning',
                  contextRef: 'period0',
                  standardRubrik:
                    'Benämning på väsentlig post som har påverkat sambandet mellan periodens skattekostnad eller skatteintäkt och redovisat resultat före skatt',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:AvstamningEffktivSkattVasentligPostTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvstamningEffktivSkattVasentligPost',
                  contextRef: 'period0',
                  standardRubrik:
                    'Negativ (positiv) skatteeffekt per väsentlig post som har påverkat sambandet mellan periodens skattekostnad eller skatteintäkt och redovisat resultat före skatt',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'pureItemType',
                  unitRef: 'procent',
                  name: 'se-gen-base:AvstamningEffktivSkattVasentligPostSkatteeffekt',
                  contextRef: 'period0',
                  standardRubrik:
                    'Procentuell negativ (positiv) skatteeffekt per väsentlig post som har påverkat sambandet mellan periodens skattekostnad eller skatteintäkt och redovisat resultat före skatt',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              AvstamningEffktivSkattVasentligPostBenamning: ixbrlCell(
                value('Typ av väsentlig post'),
                {
                  type: 'stringItemType',
                  name: 'se-gen-base:AvstamningEffktivSkattVasentligPostBenamning',
                  contextRef: 'period0',
                  standardRubrik:
                    'Benämning på väsentlig post som har påverkat sambandet mellan periodens skattekostnad eller skatteintäkt och redovisat resultat före skatt',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:AvstamningEffktivSkattVasentligPostTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              AvstamningEffktivSkattVasentligPost: ixbrlCell(value(), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AvstamningEffktivSkattVasentligPost',
                contextRef: 'period0',
                standardRubrik:
                  'Negativ (positiv) skatteeffekt per väsentlig post som har påverkat sambandet mellan periodens skattekostnad eller skatteintäkt och redovisat resultat före skatt',
                negateValue: false,
                tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                previousYearTuple: false,
              }),
              AvstamningEffktivSkattVasentligPostSkatteeffekt: ixbrlCell(
                value(),
                {
                  type: 'pureItemType',
                  unitRef: 'procent',
                  name: 'se-gen-base:AvstamningEffktivSkattVasentligPostSkatteeffekt',
                  contextRef: 'period0',
                  standardRubrik:
                    'Procentuell negativ (positiv) skatteeffekt per väsentlig post som har påverkat sambandet mellan periodens skattekostnad eller skatteintäkt och redovisat resultat före skatt',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
            },
          }));
          return rows.build();
        })
        .build(),
    },
    RedovisadEffektivSkattBelopp: {
      type: 'group',
      active: true,
      level: 1,
      title: field('Redovisad effektiv skatt'),
      table: table<IxbrlCell>(
        'notes.note28.data.RedovisadEffektivSkattBelopp.table',
        {
          id: 'RedovisadEffektivSkattBelopp',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'RedovisadEffektivSkattBelopp',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'RedovisadEffektivSkattBelopp',
                'row',
                noteLabel('Redovisad effektiv skatt'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:RedovisadEffektivSkattBelopp',
                  standardRubrik: 'Redovisad effektiv skatt',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:RedovisadEffektivSkattBelopp',
                  standardRubrik: 'Redovisad effektiv skatt',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'RedovisadEffektivSkatt',
                'row',
                noteLabel('Redovisad effektiv skatt i procent'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'pureItemType',
                  unitRef: 'procent',
                  name: 'se-gen-base:RedovisadEffektivSkatt',
                  standardRubrik: 'Redovisad effektiv skatt i procent',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'pureItemType',
                  unitRef: 'procent',
                  name: 'se-gen-base:RedovisadEffektivSkatt',
                  standardRubrik: 'Redovisad effektiv skatt i procent',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          return rows.build();
        })
        .build(),
      AvstamningEffktivSkattKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id('notes.note28.data.RedovisadEffektivSkattBelopp.active')
        ),
        title: field('Kommentar till avstämning av effektiv skatt'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:AvstamningEffktivSkattKommentar',
          standardRubrik: 'Kommentar till avstämning av effektiv skatt',
        }),
      },
    },
    NotSkattPeriodensResultatKommentar: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Kommentar till upplysningar av skatt på periodens resultat'
      ),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:NotSkattPeriodensResultatKommentar',
        standardRubrik:
          'Kommentar till upplysningar av skatt på periodens resultat',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2028K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        SkattPeriodensResultatAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            SkattPeriodensResultatKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        AvstamningEffktivSkattAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        AvstamningEffktivSkattVasentligPostTuple: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        RedovisadEffektivSkattBelopp: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            AvstamningEffktivSkattKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        NotSkattPeriodensResultatKommentar: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            value: cell,
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
