import { id, ifOrElse, ref, value } from '@agoy/document';
import { getAnnualConfig, getIndividualTaxConfig } from '@agoy/common';
import { PersonMetaData, TaxDeclarationFormDefinition } from '../../types';
import { mapFormId, toInitial, toStructure } from '../forms-util';
import tables from './tables';

const configTaxSummary = (
  form: TaxDeclarationFormDefinition,
  customer: PersonMetaData,
  financialYearStart: string,
  financialYearEnd: string
) => {
  const year = parseInt(financialYearEnd.slice(0, 4));
  const annualConfig = getAnnualConfig(year);
  const individualTaxConfig = getIndividualTaxConfig(year);

  if (!annualConfig) {
    throw new Error('Failed to get annual configuration for tax summary');
  }

  const TaxSummary = mapFormId(form, 'TS');
  const initial = {
    TS: {
      partType: 'taxSummary' as const,
      id: TaxSummary,
      fields: {
        name: value(`${customer.firstName} ${customer.lastName}`),
        personalNumber: value(`${customer.personNumber}`),
        date: value(''),
        financialYear: value(year),
        taxYear: value(''),
        municipality: value(''),
        burialFeeRate: ref(id('TS.fields.burialFeeRateValue')),
        municipalTaxRate: ref(id('TS.fields.municipalTaxRateValue')),
        religiousCommunitiesFee: ref(
          id('TS.fields.religiousCommunitiesFeeValue')
        ),

        religiousCommunitiesFeeValue: value(),
        municipalTaxRateValue: value(),
        burialFeeRateValue: value(),

        taxFreeAllowance: value(),
        finalTax: {
          active: true,
          summaryType: ref(
            ifOrElse(
              id(
                'TS.fields.SammanstallningBerakningar.table.H.taxToPay.active'
              ),
              'taxToPay',
              'taxRefund'
            )
          ),
          text: ref(
            ifOrElse(
              id(
                'TS.fields.SammanstallningBerakningar.table.H.taxToPay.active'
              ),
              id('TS.fields.SammanstallningBerakningar.table.H.taxToPay.label'),
              id('TS.fields.SammanstallningBerakningar.table.H.taxRefund.label')
            )
          ),
          value: ref(
            ifOrElse(
              id(
                'TS.fields.SammanstallningBerakningar.table.H.taxToPay.active'
              ),
              id('TS.fields.SammanstallningBerakningar.table.H.taxToPay.value'),
              id('TS.fields.SammanstallningBerakningar.table.H.taxRefund.value')
            )
          ),
        },
        ...tables(annualConfig, individualTaxConfig),
        comment: {
          active: true,
          title: value('Slutlig kommentar'),
          text: value(''),
        },
        religiousCommunityHelp: {
          showReligionSection: value(false),
          showParish: value(false),
          showOtherReligion: value(false),

          selectedParish: value(''),
          religiousFee: value(),
          selectedNonTerritorialReligiousCommunity: value(''),
          nonTerritorialReligiousFee: value(),
        },
      },
      sru: {},
    },
  };

  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configTaxSummary;
