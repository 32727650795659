/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6812
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  previousYear,
  year,
  sumAccountsIBs,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2034K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2034K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel(
    'Koncessioner, patent, licenser, varumärken samt liknande rättigheter'
  ),
  data: {
    active: true,
    KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Koncessioner, patent, licenser, varumärken samt liknande rättigheter'
      ),
      table: table<IxbrlCell>(
        'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table',
        {
          id: 'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvardenIngaende',
                'sum_accounts',
                noteLabel('Ingående anskaffningsvärden'),
                ixbrlCell(ref(sumAccountsIBs('1020+1030+1040+1050', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvarden',
                  standardRubrik:
                    'Anskaffningsvärden koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(
                    sumAccountsIBs('1020+1030+1040+1050', false, 'previousYear')
                  ),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows
                .addRowWithType(
                  'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenAbstract',
                  'header',
                  noteLabel('Förändringar av anskaffningsvärden'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenInkop',
                    'row',
                    noteLabel('Inköp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenInkop',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med inköp av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenInkop',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med inköp av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenRorelseforvarv',
                    'row',
                    noteLabel('Rörelseförvärv'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom rörelseförvärv',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom rörelseförvärv',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenForsaljningarUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenForsaljningarUtrangeringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenForsaljningarUtrangeringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvardenUtgaende',
                'sum',
                noteLabel('Utgående anskaffningsvärden'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvardenIngaende.year'
                      ),
                      id(
                        'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvardenIngaende.previousYear'
                      ),
                      id(
                        'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående avskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1029+1039+1049+1059', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningar',
                  standardRubrik:
                    'Ackumulerade avskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(
                  ref(
                    sumAccountsIBs('1029+1039+1049+1059', false, 'previousYear')
                  ),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningar',
                    standardRubrik:
                      'Ackumulerade avskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              rows
                .addRowWithType(
                  'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av avskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av avskrivningar för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av avskrivningar för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda avskrivningar i samband med försäljningar eller utrangeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda avskrivningar i samband med försäljningar eller utrangeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarAretsAvskrivningar',
                    'row',
                    noteLabel('Årets avskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarAretsAvskrivningar',
                      standardRubrik:
                        'Periodens avskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarAretsAvskrivningar',
                      standardRubrik:
                        'Periodens avskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningarUtgaende',
                'sum',
                noteLabel('Utgående avskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningar',
                    standardRubrik:
                      'Ackumulerade avskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningar',
                    standardRubrik:
                      'Ackumulerade avskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              rows.addRowWithType(
                'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterUppskrivningarIngaende',
                'row',
                noteLabel('Ingående uppskrivningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterUppskrivningar',
                  standardRubrik:
                    'Ackumulerade uppskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterUppskrivningar',
                  standardRubrik:
                    'Ackumulerade uppskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows
                .addRowWithType(
                  'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av uppskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarForsaljningarUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarForsaljningarUtrangeringar',
                      standardRubrik:
                        'Återförda uppskrivningar i samband med försäljningar eller utrangeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarForsaljningarUtrangeringar',
                      standardRubrik:
                        'Återförda uppskrivningar i samband med försäljningar eller utrangeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarAretsUppskrivningar',
                    'row',
                    noteLabel('Årets uppskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarAretsUppskrivningar',
                      standardRubrik:
                        'Periodens uppskrivningar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarAretsUppskrivningar',
                      standardRubrik:
                        'Periodens uppskrivningar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp',
                    'row',
                    noteLabel('Årets nedskrivningar på uppskrivet belopp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Nedskrivningar av uppskrivet belopp på koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Nedskrivningar av uppskrivet belopp på koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp',
                    'row',
                    noteLabel('Årets avskrivningar på uppskrivet belopp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Avskrivningar av uppskrivet belopp på koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Avskrivningar av uppskrivet belopp på koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterUppskrivningarUtgaende',
                'sum',
                noteLabel('Utgående uppskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterUppskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterUppskrivningar',
                    standardRubrik:
                      'Ackumulerade uppskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterUppskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterUppskrivningar',
                    standardRubrik:
                      'Ackumulerade uppskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående nedskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1028+1038+1048+1058', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(
                  ref(
                    sumAccountsIBs('1028+1038+1048+1058', false, 'previousYear')
                  ),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              rows
                .addRowWithType(
                  'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av nedskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
                    'row',
                    noteLabel('Återförda nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAretsNedskrivningar',
                    'row',
                    noteLabel('Årets nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningarUtgaende',
                'sum',
                noteLabel('Utgående nedskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'KoncessionerPatentLicenserVarumarkenLiknandeRattigheter',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvardenUtgaende.year'
                  ),
                  id(
                    'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningarUtgaende.year'
                  ),
                  id(
                    'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterUppskrivningarUtgaende.year'
                  ),
                  id(
                    'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningarUtgaende.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheter',
                standardRubrik:
                  'Koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvardenUtgaende.previousYear'
                  ),
                  id(
                    'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningarUtgaende.previousYear'
                  ),
                  id(
                    'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterUppskrivningarUtgaende.previousYear'
                  ),
                  id(
                    'notes.note34.data.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.table.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningarUtgaende.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheter',
                standardRubrik:
                  'Koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          rows.addRowWithType(
            'KoncessionerPatentLicenserVarumarkenLiknandeRattigheterOffentligaBidrag',
            'row',
            noteLabel('Offentliga bidrag som reducerat anskaffningsvärdet'),
            ixbrlCell(value(undefined), {
              contextRef: 'balans0',
              type: 'monetaryItemType',
              unitRef: 'SEK',
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterOffentligaBidrag',
              standardRubrik:
                'Offentliga bidrag som reducerat anskaffningsvärdet på koncessioner, patent, licenser, varumärken samt liknande rättigheter',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              contextRef: 'balans1',
              type: 'monetaryItemType',
              unitRef: 'SEK',
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterOffentligaBidrag',
              standardRubrik:
                'Offentliga bidrag som reducerat anskaffningsvärdet på koncessioner, patent, licenser, varumärken samt liknande rättigheter',
              saldo: 'credit',
              negateValue: true,
            })
          );
          return rows.build();
        })
        .build(),
      NotKoncessionerPatentLicenserVarumarkenLiknandeRattigheterKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till specifikation av koncessioner, patent, licenser, varumärken samt liknande rättigheter'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotKoncessionerPatentLicenserVarumarkenLiknandeRattigheterKommentar',
          standardRubrik:
            'Kommentar till specifikation av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2034K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotKoncessionerPatentLicenserVarumarkenLiknandeRattigheterKommentar:
              {
                type: 'part' as const,
                children: {
                  type: type,
                  level: number,
                  active: boolean,

                  title: cell,

                  value: cell,
                },
              },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
