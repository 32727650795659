import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from '@emotion/styled';
import Dialog from '@material-ui/core/Dialog';
import { useIntl } from 'react-intl';
import { endOfMonth, startOfMonth } from 'date-fns';
import { isEqual } from 'lodash';

import { reformatDate } from '@agoy/common';
import { parse, format, parseFormat } from '@agoy/dates';
import { formatPeriodToMonthAndYear } from 'utils';
import { InputData } from '_reconciliation/types';
import PeriodSummary from '_shared/components/PeriodSummary';
import { SkatteverketTransaction } from '_clients/redux/customer-view/types';
import { useSelector } from 'redux/reducers';
import usePreviousValue from 'utils/hooks/usePreviousValue';

import InformationBanner from '../InformationBanner';
import FetchHeader from './FetchHeader';
import PeriodPicker from '../PeriodPicker';
import SkatteverketPrintView from './SkatteverketView';
import Table, { TableFilter } from './organisms/Table';
import PeriodDataContext from '../PeriodDataContext';

const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  display: flex;
  gap: ${(props) => props.theme.spacing(3)}px;
`;

const TableSection = styled.div`
  flex-grow: 1;
  margin-right: ${(props) => props.theme.spacing(2)}px;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(2)}px;
`;

const Link = styled.a`
  display: contents;
`;

/**
 * Select all transactions in periods. Get them as one page
 * const ELEMENTS_PER_PAGE = 10000;
 */
const ELEMENTS_PER_PAGE = 10000;
const CONTAINS_NAMES = {
  2710: 'Avdragen skatt',
  2730: 'Arbetsgivaravgift',
};

type ViewProps = {
  accountNumber: string;
  userData: InputData;
  transactions: SkatteverketTransaction[];
  totalResults: number;
  transactionsFetchDate: string | undefined;
  isLoading: boolean;
  handleFetchData: (
    startPeriods,
    endPeriods,
    offset,
    limit,
    contains,
    event?
  ) => void;
};

const View: React.FC<ViewProps> = ({
  accountNumber,
  userData,
  transactions,
  totalResults,
  transactionsFetchDate,
  isLoading,
  handleFetchData,
}: ViewProps) => {
  const { formatMessage } = useIntl();

  const { clientId, groupedPeriods, period } = useContext(PeriodDataContext);
  const accountingView = useSelector((state) => state.accountingView);
  const client = useSelector((state) => state.customers[clientId]);

  const {
    ub,
    ib,
    psaldo,
    saldo: userDataSaldo,
    legacySpecifications = [],
  } = userData || {};
  const saldo = userDataSaldo ? parseInt(userDataSaldo, 10) : 0;

  const [skatteverketTransactions, setSkatteverketTransactions] =
    useState(transactions);
  const [displayPreview, setDisplayPreview] = useState(false);

  const [filterBy, setFilterBy] = useState<TableFilter>({
    pageNumber: 1,
    periods: groupedPeriods.reduce(
      (result, p) => ({
        ...result,
        [reformatDate(p.start, 'yyyy-MM-dd', 'yyyyMM')]: true,
      }),
      {}
    ),
  });

  const previousFilterBy = usePreviousValue(filterBy);

  const isSibling = (firstDate, secondDate) => {
    if (Math.abs(firstDate.getYear() - secondDate.getYear()) > 1) {
      return false;
    }
    if (Math.abs(firstDate.getYear() - secondDate.getYear()) === 1) {
      return Math.abs(firstDate.getMonth() - secondDate.getMonth()) === 11;
    }
    if (Math.abs(firstDate.getYear() - secondDate.getYear()) === 0) {
      return Math.abs(firstDate.getMonth() - secondDate.getMonth()) === 1;
    }
    return false;
  };

  // CALLBACKS
  const handlePeriodSelect = useCallback((periods) => {
    const transformedPeriods = {};
    Object.keys(periods).forEach((p) => {
      const value = periods[p];
      transformedPeriods[parseFormat(p, 'yyyyMM')] = value;
    });

    setFilterBy((f) => ({
      ...f,
      periods: { ...transformedPeriods },
      pageNumber: 1,
    }));
  }, []);

  // calculating selected periods & getting transactions
  const handleFetchTransaction = useCallback(
    (isHardFetch = false) => {
      const { periods, pageNumber } = filterBy;
      if (Object.keys(periods).length === 0) {
        return;
      }

      const startPeriods: string[] = [];
      const endPeriods: string[] = [];
      let fromDate;
      let toDate;

      Object.keys(periods).forEach((el) => {
        const monthStarts = startOfMonth(parse(el));
        const monthEnds = endOfMonth(parse(el));

        if (!fromDate) {
          fromDate = monthStarts;
          toDate = monthEnds;
          startPeriods.push(format(fromDate, 'yyyy-MM-dd'));
        } else if (isSibling(toDate, monthEnds)) {
          toDate = monthEnds;
        } else {
          endPeriods.push(format(toDate, 'yyyy-MM-dd'));
          fromDate = monthStarts;
          toDate = monthEnds;
          startPeriods.push(format(fromDate, 'yyyy-MM-dd'));
        }
      });

      endPeriods.push(format(toDate, 'yyyy-MM-dd'));
      const pageOffset = pageNumber * ELEMENTS_PER_PAGE - ELEMENTS_PER_PAGE;
      const contains = CONTAINS_NAMES[accountNumber]
        ? CONTAINS_NAMES[accountNumber]
        : undefined;

      handleFetchData(
        startPeriods,
        endPeriods,
        pageOffset,
        ELEMENTS_PER_PAGE,
        contains,
        isHardFetch ? 'hard' : 'soft'
      );
    },
    [accountNumber, filterBy, handleFetchData]
  );

  // EFFECTS
  useEffect(() => {
    // update date when other period in same account is selected
    setFilterBy((value) => ({
      ...value,
      periods: { [reformatDate(period.start, 'yyyy-MM-dd', 'yyyyMM')]: true },
    }));
  }, [period]);

  // applying fetch on filters change
  useEffect(() => {
    const hasNoChanges = isEqual(filterBy, previousFilterBy);
    if (hasNoChanges) {
      return;
    }

    handleFetchTransaction();
  }, [filterBy, handleFetchTransaction, previousFilterBy]);

  useEffect(() => {
    // we add the ingoing and outgoing saldo to the transactions table
    // to be displayed with the transactions
    if (!client) {
      return;
    }

    setSkatteverketTransactions(transactions);
  }, [
    transactions,
    client,
    accountingView.clients,
    accountNumber,
    filterBy.periods,
    period,
  ]);

  // RENDERING
  return (
    <Wrapper>
      <PeriodSummary
        date={formatPeriodToMonthAndYear(period)}
        periodIb={ib}
        periodSaldo={psaldo}
        periodUb={ub}
        saldo={saldo}
      />

      <PeriodPicker
        clientId={client.id}
        periods={groupedPeriods}
        onPeriodChange={handlePeriodSelect}
      />

      <TableSection>
        <InformationBanner>
          {formatMessage({ id: 'hidden.skatteverket.alert.part1' })}
          <Link href="https://www.skatteverket.se/" target="_blank">
            skatteverket.se
          </Link>
          {formatMessage({ id: 'hidden.skatteverket.alert.part2' })}
        </InformationBanner>

        <FetchHeader
          date={transactionsFetchDate}
          period={period}
          account={accountNumber}
          transactions={skatteverketTransactions}
          hasSpecifications={!!legacySpecifications.length}
          isLoading={isLoading}
          onFetchClick={() => handleFetchTransaction(true)}
          onPreviewClick={() => setDisplayPreview(true)}
        />

        <Table
          isLoading={isLoading}
          transactions={skatteverketTransactions}
          filterBy={filterBy}
          accountNumber={accountNumber}
          totalResults={totalResults}
        />
      </TableSection>

      <Dialog open={displayPreview} fullWidth maxWidth="lg">
        <SkatteverketPrintView
          accountNumber={accountNumber}
          transactions={skatteverketTransactions}
          isPrint
          onClose={() => setDisplayPreview(false)}
        />
      </Dialog>
    </Wrapper>
  );
};

export default View;
