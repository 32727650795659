import {
  ResolveReferenceContext,
  ResolveReferenceError,
  ResolvedReference,
  isResolveReferenceError,
  ResolveError,
  isAccountValueType,
  AccountValueType,
} from './types';

import debounce from 'lodash/debounce';

type Value = string | number | boolean | undefined | ResolveReferenceError;

const debounceConsoleError = debounce(console.error, 1000);

const addErrorRef = (
  error: ResolveReferenceError,
  ref: string
): ResolveReferenceError => ({
  ...error,
  refChain: error.refChain ? [...error.refChain, ref] : [ref],
});

const resolveIdReference = <Context extends ResolveReferenceContext>(
  args: string | undefined,
  context: Context
): Value | Value[] => {
  if (!args) {
    return undefined;
  }

  if (args.includes('*')) {
    if (!context.expandIds) {
      return { error: 'expandNotSupported' };
    }
    return context
      .expandIds(args)
      .map((id) => {
        const value = context.resolveById(id, context);
        if (isResolveReferenceError(value)) {
          // Decorate the error here to include the specific id that caused it
          return {
            ...value,
            refChain: value.refChain
              ? [...value.refChain, `id(${id})`]
              : [`id(${id})`],
          };
        }
        return value;
      })
      .filter((value) => value !== undefined);
  }
  return context.resolveById(args, context);
};

const resolveConfigReference = (
  name: string | undefined,
  context: ResolveReferenceContext
): Value => {
  if (name === undefined) {
    return undefined;
  }
  return context.resolveConfig(name, context);
};

const isNumberOrRange = (value: string): boolean => {
  if (value.length === 4) {
    return /\d{4}/.test(value);
  }
  if (value.length >= 9) {
    // range?
    return /^\d{4}:\d{4}(:.*)?$/.test(value);
  }
  return false;
};

const isPeriodParam = (param: string): boolean => {
  return param.startsWith('period(') && param.endsWith(')');
};

const parsePeriodParam = (param: string): string => {
  return param.substring(0, param.length - 1).substring(7);
};

/**
 * Resolves a value from SIE data accounts. Supports summing a range of accounts.
 *
 * "account(2020)" gets the outgoing balance for account 2020.
 * "account(2020,ib)" gets the ingoing balance for account 2020.
 * "account(2000:2099)" sums the outgoing balances for accounts 2000 - 2099 inclusive.
 * "account(1000:1099+2000:2099)" sum account 1000 - 1099 and 2000 - 2099.
 * "account(id(accountNo))" gets the outgoing balance for the account number specified by the id-reference accountNo
 *
 * If no time period is specified, the default time period in the input will be used
 * "account(1000:1999,period(p1))" sums the outgoing balances for account 1000 - 1999 for the time period named "p1" in the input
 * @param args the argument part of account()
 * @param context
 */
const resolveAccountReference = (
  args: string | undefined,
  context: ResolveReferenceContext
): Value => {
  const { input } = context;
  if (!args) {
    return undefined;
  }

  const [accounts, ...params] = args.split(',');
  const { accountValueType, period } = params.reduce(
    (values, param) => {
      if (isAccountValueType(param)) {
        return { ...values, accountValueType: param };
      }
      if (isPeriodParam(param)) {
        return {
          ...values,
          period: input.periods[parsePeriodParam(param)],
        };
      }
      console.warn('Unhandled param to account', param);
      return values;
    },
    { accountValueType: 'ub' as AccountValueType, period: input.defaultPeriod }
  );

  if (!period) {
    if (process.env.NODE_ENV !== 'production') {
      // Debounce will only log the last error, but rather have one error than hiding them all.
      debounceConsoleError(`Missing period references by parameters ${params}`);
    }
    return { error: 'missingPeriod' };
  }

  const ranges = accounts.split('+').flatMap((range) => {
    let resolvedRange: string = range;
    if (!isNumberOrRange(range)) {
      const [ref, nextRef] = resolveReferenceInternal(range, context);
      if (typeof ref === 'string') {
        resolvedRange = ref;
      } else if (typeof ref === 'number') {
        resolvedRange = ref.toString();
      } else {
        return [];
      }
    }
    const [first, last, nameFilter] = resolvedRange.split(':');
    return [{ first, last: last ?? first, nameFilter }];
  });

  if (ranges.length === 0) {
    return { error: 'missingAccount' };
  }

  if (
    ranges.length === 1 &&
    ranges[0].first === ranges[0].last &&
    !ranges[0].nameFilter
  ) {
    return input.accountResolver.get(accountValueType, period, ranges[0].first);
  }

  return input.accountResolver.sum(accountValueType, period, ranges);
};

const resolveAccountNameReference = (
  args: string | undefined,
  context: ResolveReferenceContext
): Value => {
  const { input } = context;
  if (!args) {
    return undefined;
  }
  const [values] = resolveReferenceInternal(args, context);

  if (Array.isArray(values)) {
    return { error: 'tooManyValuesReturned' };
  }
  if (typeof values === 'number') {
    return input.accountResolver.getName(values) ?? '';
  }
  return { error: 'incompatibleType' };
};

const resolveFromMultipleReferences =
  (
    reducer: (res: Value, value: Value) => Value,
    initialValue?: number,
    ignoreMissing?: boolean
  ) =>
  (args: string | undefined, context: ResolveReferenceContext): Value => {
    if (!args) {
      return undefined;
    }

    const references = resolveMultipleReferences(args, context, ignoreMissing);

    if (references.length === 0) {
      return undefined;
    }

    if (initialValue === undefined) {
      return references.reduce(reducer);
    }
    return references.reduce(reducer, initialValue);
  };

const resolveMultiplyReference = resolveFromMultipleReferences((res, value) => {
  if (typeof res === 'object') {
    return res;
  }
  if (typeof value === 'object') {
    return value;
  }
  if (
    typeof res === 'boolean' ||
    typeof value === 'boolean' ||
    typeof res === 'string' ||
    typeof value === 'string'
  ) {
    return { error: 'incompatibleType' };
  }

  return res === undefined || value === undefined ? undefined : res * value;
}, 1);

const resolveSumReference = resolveFromMultipleReferences((res, value) => {
  if (typeof res === 'object') {
    return res;
  }
  if (typeof value === 'object') {
    return value;
  }
  if (
    typeof res === 'boolean' ||
    typeof value === 'boolean' ||
    typeof res === 'string' ||
    typeof value === 'string'
  ) {
    return { error: 'incompatibleType' };
  }

  return res === undefined || value === undefined ? undefined : res + value;
}, 0);

/**
 * Resolves a sum reference where null values are accepted, used from tax-declaration and connected forms
 */
const resolveSumAllowNullReference = resolveFromMultipleReferences(
  (res, value) => {
    if (typeof res === 'object') {
      return res;
    }
    if (typeof value === 'object') {
      return value ?? res;
    }
    if (
      typeof res === 'boolean' ||
      typeof value === 'boolean' ||
      typeof res === 'string' ||
      typeof value === 'string'
    ) {
      return { error: 'incompatibleType' };
    }

    // if value is absent return previous result (undefined or sum)
    if (value === undefined) {
      return res;
    }

    // if sum is undefined return current value (undefined or value)
    if (res === undefined) {
      return value;
    }

    // both values are present, sum them
    return res + value;
  },
  undefined,
  true
);

const resolveMaxReference = resolveFromMultipleReferences((res, value) => {
  if (typeof res === 'object') {
    return res;
  }
  if (typeof value === 'object') {
    return value;
  }
  if (
    typeof res === 'boolean' ||
    typeof value === 'boolean' ||
    typeof res === 'string' ||
    typeof value === 'string'
  ) {
    return { error: 'incompatibleType' };
  }

  if (res === undefined) {
    return value;
  }
  return value === undefined ? res : Math.max(res, value);
});

const resolveAbsReference = (
  args: string | undefined,
  context: ResolveReferenceContext
): Value => {
  if (args === undefined) {
    return undefined;
  }
  const [value] = resolveReferenceInternal(args, context);
  if (typeof value === 'number') {
    return Math.abs(value);
  }
  if (Array.isArray(value)) {
    return { error: 'tooManyValuesReturned' };
  }
  return value;
};

const resolveDivReference = resolveFromMultipleReferences((res, value) => {
  if (value === 0 || value === undefined) {
    // return ReferenceErrorType.DivisionByZero;
    return undefined;
  }

  if (typeof res === 'object') {
    return res;
  }
  if (typeof value === 'object') {
    return value;
  }
  if (
    typeof res === 'boolean' ||
    typeof value === 'boolean' ||
    typeof res === 'string' ||
    typeof value === 'string'
  ) {
    return { error: 'incompatibleType' };
  }

  return res === undefined ? 0 : res / value;
});

const resolveMinReference = resolveFromMultipleReferences((res, value) => {
  if (typeof res === 'object') {
    return res;
  }
  if (typeof value === 'object') {
    return value;
  }
  if (
    typeof res === 'boolean' ||
    typeof value === 'boolean' ||
    typeof res === 'string' ||
    typeof value === 'string'
  ) {
    return { error: 'incompatibleType' };
  }

  if (res === undefined) {
    return value;
  }
  return value === undefined ? res : Math.min(res, value);
});

const resolveOrReference = resolveFromMultipleReferences(
  (res, value) => {
    if (typeof res === 'object') {
      return res;
    }
    if (typeof value === 'object') {
      return value;
    }
    if (typeof res === 'boolean' || typeof value === 'boolean') {
      if (typeof res === 'boolean' && typeof value === 'boolean') {
        return res || value;
      }
      if (typeof res === 'undefined' && typeof value === 'boolean') {
        return value;
      }
      if (typeof res === 'boolean' && typeof value === 'undefined') {
        return res;
      }
      return { error: 'incompatibleType' };
    }

    return res === undefined ? value : res;
  },
  undefined,
  true
);

export const resolveMultipleReferences = (
  references: string,
  context: ResolveReferenceContext,
  ignoreMissing: boolean | undefined
): Value[] => {
  let referencesLeft: string | undefined = references;
  const result: Value[] = [];

  while (referencesLeft) {
    // eslint-disable-next-line prefer-const
    let [value, nextLeft]: [Value[] | Value, string | undefined] =
      resolveReferenceInternal(referencesLeft, context);
    if (Array.isArray(value)) {
      value.forEach((item) => {
        if (
          ignoreMissing &&
          isResolveReferenceError(item) &&
          (item.error === 'missingAccount' || item.error === 'missingId')
        ) {
          result.push(undefined);
        } else {
          result.push(item);
        }
      });
    } else if (
      ignoreMissing &&
      isResolveReferenceError(value) &&
      (value.error === 'missingAccount' || value.error === 'missingId')
    ) {
      result.push(undefined);
    } else {
      result.push(value);
    }
    nextLeft = nextLeft?.trim();
    if (nextLeft) {
      if (nextLeft.charAt(0) === ',') {
        referencesLeft = nextLeft.substring(1).trimLeft();
      } else {
        break;
      }
    } else {
      break;
    }
  }
  return result;
};

const floor = (
  args: string | undefined,
  context: ResolveReferenceContext
): Value => {
  if (args === undefined) {
    return undefined;
  }
  const [value] = resolveMultipleReferences(args, context, false);
  if (typeof value === 'number') {
    return Math.floor(value);
  }
  if (Array.isArray(value)) {
    return { error: 'tooManyValuesReturned' };
  }
  return value;
};

const round = (
  args: string | undefined,
  context: ResolveReferenceContext
): Value => {
  if (args === undefined) {
    return undefined;
  }
  const [value] = resolveReferenceInternal(args, context);
  if (typeof value === 'number') {
    if (value < 0) {
      return -Math.round(-value);
    }
    return Math.round(value);
  }
  if (Array.isArray(value)) {
    return { error: 'tooManyValuesReturned' };
  }
  return value;
};

const roundToBase = (
  args: string | undefined,
  context: ResolveReferenceContext
): Value => {
  if (!args) {
    return { error: 'internalError' as const };
  }

  const resolvedArgs = resolveMultipleReferences(args, context, false);

  if (resolvedArgs.length !== 2) {
    return { error: 'notEnoughArgumentsProvided' as const, refChain: [args] };
  }

  const [firstValue, secondValue] = resolvedArgs;

  if (typeof firstValue === 'number' && typeof secondValue === 'number') {
    return Math.trunc(firstValue / secondValue) * secondValue;
  }

  if (firstValue === undefined || secondValue === undefined) {
    return undefined;
  }

  return { error: 'invalidArgumentType' as const, refChain: [args] };
};

const not = (
  args: string | undefined,
  context: ResolveReferenceContext
): Value => {
  if (args === undefined) {
    return undefined;
  }
  const [value] = resolveReferenceInternal(args, context);

  if (typeof value === 'number') {
    return value === 0;
  }
  if (typeof value === 'string') {
    return value === '';
  }
  if (typeof value === 'boolean') {
    return !value;
  }
  if (Array.isArray(value)) {
    return { error: 'tooManyValuesReturned' };
  }
  return value;
};

const isTrue = (value: Value): boolean => {
  if (typeof value === 'number') {
    return value !== 0;
  }
  if (typeof value === 'string') {
    return value !== '';
  }
  if (typeof value === 'boolean') {
    return value;
  }
  return false;
};

const ifOrElse = (
  args: string | undefined,
  context: ResolveReferenceContext
): Value => {
  if (args) {
    const resolvedArgs = resolveMultipleReferences(args, context, false);
    if (resolvedArgs.length !== 3) {
      return { error: 'notEnoughArgumentsProvided' as const };
    }
    const [ifRef, thenRef, elseRef] = resolvedArgs;

    if (isTrue(ifRef)) {
      return thenRef;
    } else {
      return elseRef;
    }
  }
  return { error: 'internalError' };
};

const extractNumber = (str: string): string => {
  for (let i = 0; i < str.length; i += 1) {
    const ch = str.charAt(i);
    if ((ch < '0' || ch > '9') && ch !== '-' && ch !== '.') {
      return str.substring(0, i);
    }
  }
  return str;
};

export const parseReference = (
  reference: string
): [string, string | undefined, string | undefined] => {
  let i = 0;
  let typeEnd;
  let depth = 0;
  while (i < reference.length && typeEnd === undefined) {
    if (reference.charAt(i) === '(') {
      typeEnd = i;
      depth += 1;
    }
    i += 1;
  }

  if (reference.length > 0) {
    const number = extractNumber(reference);
    if (number) {
      return [number, undefined, reference.substring(number.length)];
    }
  }
  if (typeEnd === undefined) {
    return [reference, undefined, undefined];
  }
  while (i < reference.length && depth > 0) {
    if (reference.charAt(i) === '(') {
      depth += 1;
    } else if (reference.charAt(i) === ')') {
      depth -= 1;
    }
    i += 1;
  }

  return [
    reference.substring(0, typeEnd),
    reference.substring(typeEnd + 1, i - 1),
    i < reference.length ? reference.substring(i) : undefined,
  ];
};

type ResolveReferenceInternalFn = <Context extends ResolveReferenceContext>(
  reference: string,
  context: Context
) => [Value[] | Value, string | undefined];

const decorateError =
  (fn: ResolveReferenceInternalFn): ResolveReferenceInternalFn =>
  (
    ...args: Parameters<ResolveReferenceInternalFn>
  ): ReturnType<ResolveReferenceInternalFn> => {
    const result = fn(...args);
    const [value, rest] = result;

    if (Array.isArray(value)) {
      if (value.find(isResolveReferenceError)) {
        return [
          value.map((item) =>
            isResolveReferenceError(item) ? addErrorRef(item, args[0]) : item
          ),
          rest,
        ];
      }
    } else if (isResolveReferenceError(value)) {
      return [addErrorRef(value, args[0]), rest];
    }
    return result;
  };

/**
 * Resolves a sum reference where empty/undefined values are accepted
 */
const resolveSumAllowEmptyReferences = resolveFromMultipleReferences(
  (res, value) => {
    if (typeof res === 'object') {
      return res;
    }
    if (typeof value === 'object') {
      return value ?? res;
    }
    if (
      typeof res === 'boolean' ||
      typeof value === 'boolean' ||
      typeof res === 'string' ||
      typeof value === 'string'
    ) {
      return { error: 'incompatibleType' };
    }

    const returnedRes = res === undefined ? 0 : res;
    const returnedValue = value === undefined ? 0 : value;

    return returnedRes + returnedValue;
  },
  0,
  true
);

const resolveFormatDate = (
  args: string | undefined,
  context: ResolveReferenceContext
): Value => {
  if (args === undefined) {
    return undefined;
  }
  const [value] = resolveReferenceInternal(args, context);

  if (typeof value === 'string') {
    if (value.length === 8) {
      const year = value.substring(0, 4);
      const month = value.substring(4, 6);
      const day = value.substring(6, 8);

      return `${year}-${month}-${day}`;
    }

    return value;
  }

  return { error: 'incompatibleType' };
};

const isSmallerThan = (
  args: string | undefined,
  context: ResolveReferenceContext
): Value => {
  if (!args) {
    return { error: 'internalError' as const };
  }

  const resolvedArgs = resolveMultipleReferences(args, context, false);

  if (resolvedArgs.length !== 2) {
    return { error: 'notEnoughArgumentsProvided' as const, refChain: [args] };
  }

  const [firstValue, secondValue] = resolvedArgs;

  if (typeof firstValue === 'number' && typeof secondValue === 'number') {
    return firstValue <= secondValue;
  }

  return { error: 'invalidArgumentType' as const, refChain: [args] };
};

const isGreaterThan = (
  args: string | undefined,
  context: ResolveReferenceContext
): Value => {
  if (!args) {
    return { error: 'internalError' as const };
  }

  const resolvedArgs = resolveMultipleReferences(args, context, false);

  if (resolvedArgs.length !== 2) {
    return { error: 'notEnoughArgumentsProvided' as const, refChain: [args] };
  }

  const [firstValue, secondValue] = resolvedArgs;

  if (typeof firstValue === 'number' && typeof secondValue === 'number') {
    return firstValue > secondValue;
  }

  return { error: 'invalidArgumentType' as const, refChain: [args] };
};

const isGreaterThanOrEqualTo = (
  args: string | undefined,
  context: ResolveReferenceContext
): Value => {
  if (!args) {
    return { error: 'internalError' as const };
  }

  const resolvedArgs = resolveMultipleReferences(args, context, false);

  if (resolvedArgs.length !== 2) {
    return { error: 'notEnoughArgumentsProvided' as const, refChain: [args] };
  }

  const [firstValue, secondValue] = resolvedArgs;

  if (typeof firstValue === 'number' && typeof secondValue === 'number') {
    return firstValue >= secondValue;
  }

  return { error: 'invalidArgumentType' as const, refChain: [args] };
};

const limitMin = (
  args: string | undefined,
  context: ResolveReferenceContext
): Value => {
  if (!args) {
    return { error: 'internalError' as const };
  }

  const resolvedArgs = resolveMultipleReferences(args, context, false);

  if (resolvedArgs.length !== 2) {
    return { error: 'notEnoughArgumentsProvided' as const, refChain: [args] };
  }

  const [value, min] = resolvedArgs;

  return isSmallerThan(`${value},${min}`, context) ? min : value;
};

const limitMax = (
  args: string | undefined,
  context: ResolveReferenceContext
): Value => {
  if (!args) {
    return { error: 'internalError' as const };
  }

  const resolvedArgs = resolveMultipleReferences(args, context, false);

  if (resolvedArgs.length !== 2) {
    return { error: 'notEnoughArgumentsProvided' as const, refChain: [args] };
  }

  const [value, max] = resolvedArgs;

  return isGreaterThan(`${value},${max}`, context) ? max : value;
};

/**
 *
 * @param reference
 * @param context
 * @returns [resolvedValue, remaining string after reference]
 */
const resolveReferenceInternal: ResolveReferenceInternalFn = decorateError(
  <Context extends ResolveReferenceContext>(
    reference: string,
    context: Context
  ): [Value[] | Value, string | undefined] => {
    const [type, args, leftOver] = parseReference(reference);

    if (args !== undefined) {
      switch (type) {
        case 'account':
          return [resolveAccountReference(args, context), leftOver];
        case 'accountName':
          return [resolveAccountNameReference(args, context), leftOver];
        case 'abs':
          return [resolveAbsReference(args, context), leftOver];
        case 'id':
          return [resolveIdReference(args, context), leftOver];
        case 'config':
          return [resolveConfigReference(args, context), leftOver];
        case 'multiply':
          return [resolveMultiplyReference(args, context), leftOver];
        case 'div':
          return [resolveDivReference(args, context), leftOver];
        case 'sum':
          return [resolveSumReference(args, context), leftOver];
        case 'sumAllowNull':
          return [resolveSumAllowNullReference(args, context), leftOver];
        case 'max':
          return [resolveMaxReference(args, context), leftOver];
        case 'min':
          return [resolveMinReference(args, context), leftOver];
        case 'or':
          return [resolveOrReference(args, context), leftOver];
        case 'floor':
          return [floor(args, context), leftOver];
        case 'round':
          return [round(args, context), leftOver];
        case 'roundToBase':
          return [roundToBase(args, context), leftOver];
        case 'not':
          return [not(args, context), leftOver];
        case 'if':
          return [ifOrElse(args, context), leftOver];
        case 'sumAllowEmpty':
          return [resolveSumAllowEmptyReferences(args, context), leftOver];
        case 'formatDate':
          return [resolveFormatDate(args, context), leftOver];
        case 'isSmallerThan':
          return [isSmallerThan(args, context), leftOver];
        case 'isGreaterThan':
          return [isGreaterThan(args, context), leftOver];
        case 'isGreaterThanOrEqualTo':
          return [isGreaterThanOrEqualTo(args, context), leftOver];
        case 'limitMin':
          return [limitMin(args, context), leftOver];
        case 'limitMax':
          return [limitMax(args, context), leftOver];
        default:
          throw new ResolveError('unknownReferenceType');
      }
    }
    const numericValue = parseFloat(type);
    if (typeof numericValue === 'number' && !Number.isNaN(numericValue)) {
      return [numericValue, leftOver];
    }
    if (type === 'true') {
      return [true, leftOver];
    }
    if (type === 'false') {
      return [false, leftOver];
    }
    if (type.includes(',')) {
      return [type.split(','), leftOver];
    }
    return [undefined, leftOver];
  }
);
let recursionDepth = 0;
/**
 * resolveReference
 *
 * Functions:
 * - id(rowId), will get the value using another calculated row with id "rowId"
 * - config(configName), will get the value from config by name.
 * - multiply(value1,value2,...), will multiply all values that are defined by value1..n and that
 *      can be other references, like "multiply(account(8910),-1)" to multiply outgoing balance of
 *      account 8910 with the number -1.
 * - sum(value1,value2,...), like multiply but addition.
 * - max(value1,value2,...), returns the maximum value of the resolved value1, value2,...
 *
 * @param reference
 * @param context
 */
const resolveReference = <Context extends ResolveReferenceContext>(
  reference: string,
  context: Context
): ResolvedReference => {
  recursionDepth += 1;
  // If we have recursed too deep, we have a circular reference
  // Temporary fix to avoid infinite loop
  if (recursionDepth > 100) {
    recursionDepth = 0;
    throw new Error('Circular reference: ' + reference);
  }
  try {
    const [value] = resolveReferenceInternal(reference, context);
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return undefined;
      }
      if (value.length > 1) {
        return {
          error: 'tooManyValuesReturned',
          refChain: [reference],
        };
      }
      return value[0];
    }
    return value;
  } catch (error) {
    if (isResolveReferenceError(error)) {
      if (error.refChain) {
        error.refChain.push(reference);
      } else {
        error.refChain = [reference];
      }
      return error;
    }
    console.error('resolveReference', error);
    console.error(reference);
    return { error: 'internalError', refChain: [reference] };
  } finally {
    if (recursionDepth > 0) {
      recursionDepth -= 1;
    }
  }
};

export default resolveReference;
