import { EconomicAssociationNote1Section } from '@agoy/annual-report-document';
import { stringValue } from '@agoy/document';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import Paragraph from './Paragraph';

const Section = styled.div`
  margin: 0.25rem 0;
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
`;

const SectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

type Props = {
  note1: EconomicAssociationNote1Section;
};

const ServiceAndContractingAssignments = (props: Props): JSX.Element => {
  const { note1 } = props;
  const { formatMessage } = useIntl();
  const title = stringValue(note1.serviceAndContractingAssignmentsLabel);
  const defaultTitle = formatMessage({
    id: `annualReport.notes.note1.serviceAndContractingAssignments`,
  });

  const active =
    [
      'serviceAndContractingAssignmentsParagraph1',
      'serviceAndContractingAssignmentsParagraph2',
    ].find((paragraphId) => note1[paragraphId].active) !== undefined;

  return (
    <Section>
      <SectionTitle>
        {!active ? null : (
          <Typography variant="h4">{title || defaultTitle}</Typography>
        )}
      </SectionTitle>
      <Paragraph
        note1={note1}
        id="serviceAndContractingAssignmentsParagraph1"
      />
      <Paragraph
        note1={note1}
        id="serviceAndContractingAssignmentsParagraph2"
      />
    </Section>
  );
};

export default ServiceAndContractingAssignments;
