import { VoucherTransaction } from '../../types/Voucher';

// Check if amount of Voucher Transactions Debit/Credit is equal
const isCreditAndDebitEqual = (
  transactions: VoucherTransaction[],
  floorValue?: boolean
): boolean => {
  const transactionSum = {
    debit: 0,
    credit: 0,
  };

  const roundFunction = floorValue ? Math.floor : (value) => value;
  transactions.forEach((transaction) => {
    transactionSum.debit += roundFunction(+transaction.debit);
    transactionSum.credit += roundFunction(+transaction.credit);
  });
  return transactionSum.debit - transactionSum.credit === 0;
};

export default isCreditAndDebitEqual;
