import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { IconButton, Typography } from '@material-ui/core';
import { Print as PrintIcon, Close as CloseIcon } from '@material-ui/icons';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';

import { SkatteverketTransaction } from '_clients/redux/customer-view/types';
import { printDocument } from '_shared/components/PrintedDocument/utils';
import { useSelector } from 'redux/reducers';
import { reformatDate } from 'utils';
import { TimePeriod } from '@agoy/document';
import LoadingLogo from '_shared/components/LoadingLogo';
import Table, { TableFilter } from './organisms/Table';
import PeriodDataContext from '../PeriodDataContext';
import { usePrintState } from '../../utils';

const DocumentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 95%;
  margin: 3mm;
`;

const Text = styled(Typography)`
  font-weight: bold;
`;

const Margin = styled.div`
  margin: 40px;
`;

const TopbarActions = styled.div`
  display: flex;
  justify-content: space-between;
  @media print {
    display: none;
  }
`;

const PrintButton = styled(IconButton)`
  @media print {
    display: none;
  }
  background-color: white;
`;

const CloseButton = styled(IconButton)`
  @media print {
    display: none;
  }
  background-color: white;
`;

const SkatteverketLogo = styled.div`
  display: flex;
  flex: 1;
  margin-top: 40px;
  margin-bottom: 40px;
`;

interface Props {
  accountNumber: string;
  transactions: SkatteverketTransaction[];
  isLoading?: boolean;
  isPrint?: boolean;
  onClose?: () => void;
}

const SkatteverketPrintView = ({
  accountNumber,
  transactions,
  isLoading = false,
  isPrint = false,
  onClose,
}: Props) => {
  const { formatMessage } = useIntl();
  const printState = usePrintState();

  const [isPrintLoading, setIsPrintLoading] = useState(false);

  const timestamp = format(new Date(), 'yyyy-MM-dd, HH:mm');
  const totalResults = transactions?.length || 0;

  const { clientId, period, groupedPeriods, financialYear } =
    useContext(PeriodDataContext);
  const { name, orgNumber } = useSelector((state) => state.customers[clientId]);

  const filterBy: TableFilter = {
    pageNumber: 1,
    periods: groupedPeriods.reduce(
      (result, p) => ({
        ...result,
        [reformatDate(p.start, 'yyyy-MM-dd', 'yyyyMM')]: true,
      }),
      {}
    ),
  };

  const handlePrint = async () => {
    const docName = `${formatMessage({
      id: 'hidden.taxagencyintegration.view',
    })} ${name} ${period.start}.pdf`;

    const finYearInterval = TimePeriod.fromISODates(financialYear).value;

    try {
      setIsPrintLoading(true);

      await printDocument(
        clientId,
        finYearInterval,
        'accounting',
        docName,
        ['skatteverket'],
        {
          ...printState,
          accountingView: {
            ...printState.accountingView,
            clients: {
              [clientId]: {
                years: {
                  [finYearInterval]: {
                    skatteverketTransactions: transactions,
                  },
                },
              },
            },
          },
          parameters: {
            accountNumber,
          },
        }
      );
    } finally {
      setIsPrintLoading(false);
    }
  };

  return (
    <Content>
      <TopbarActions>
        <PrintButton
          aria-label="print"
          disabled={isPrintLoading}
          onClick={handlePrint}
        >
          {isPrintLoading ? <LoadingLogo size="tiny" /> : <PrintIcon />}
        </PrintButton>
        <CloseButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </TopbarActions>
      <DocumentHeader>
        <Text variant="body2">Skattekonto - Skatteverket</Text>
        <Text variant="body2">{timestamp}</Text>
      </DocumentHeader>
      <SkatteverketLogo>
        <img
          src="../../../skatteverket_logo.png"
          alt="Skateverket logo"
          width={100}
          height={100}
        />
        <Text variant="h1">Skattekonto</Text>
      </SkatteverketLogo>
      <DocumentHeader>
        <Text variant="body2" />
        <Text variant="body1">
          <b>{name}</b> {orgNumber}
        </Text>
      </DocumentHeader>
      <Text variant="h3">Bokförda transaktioner</Text>
      <Margin />

      <Table
        isPrintPreviewMode={isPrint}
        isLoading={isLoading}
        transactions={transactions}
        filterBy={filterBy}
        accountNumber={accountNumber}
        totalResults={totalResults}
      />

      <Margin />
    </Content>
  );
};

export default SkatteverketPrintView;
