/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6811
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  previousYear,
  year,
  sumAccountsIBs,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2035K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2035K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Hyresrätter och liknande rättigheter'),
  data: {
    active: true,
    HyresratterLiknandeRattigheterAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Hyresrätter och liknande rättigheter'),
      table: table<IxbrlCell>(
        'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table',
        {
          id: 'HyresratterLiknandeRattigheterAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'HyresratterLiknandeRattigheterAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'HyresratterLiknandeRattigheterAnskaffningsvardenIngaende',
                'sum_accounts',
                noteLabel('Ingående anskaffningsvärden'),
                ixbrlCell(ref(sumAccountsIBs('1060:1067', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:HyresratterLiknandeRattigheterAnskaffningsvarden',
                  standardRubrik:
                    'Anskaffningsvärden hyresrätter och liknande rättigheter',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsIBs('1060:1067', false, 'previousYear')),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:HyresratterLiknandeRattigheterAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden hyresrätter och liknande rättigheter',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows
                .addRowWithType(
                  'HyresratterLiknandeRattigheterForandringAnskaffningsvardenAbstract',
                  'header',
                  noteLabel('Förändringar av anskaffningsvärden'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringAnskaffningsvardenInkop',
                    'row',
                    noteLabel('Inköp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenInkop',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med inköp av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenInkop',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med inköp av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringAnskaffningsvardenRorelseforvarv',
                    'row',
                    noteLabel('Rörelseförvärv'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för hyresrätter och liknande rättigheter genom rörelseförvärv',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för hyresrätter och liknande rättigheter genom rörelseförvärv',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringAnskaffningsvardenFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för hyresrätter och liknande rättigheter genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för hyresrätter och liknande rättigheter genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringAnskaffningsvardenForsaljningarUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenForsaljningarUtrangeringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av hyresrätter och liknande rättigheter',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenForsaljningarUtrangeringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av hyresrätter och liknande rättigheter',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringAnskaffningsvardenOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringAnskaffningsvardenOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'HyresratterLiknandeRattigheterAnskaffningsvardenUtgaende',
                'sum',
                noteLabel('Utgående anskaffningsvärden'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterAnskaffningsvardenIngaende.year'
                      ),
                      id(
                        'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterForandringAnskaffningsvardenAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:HyresratterLiknandeRattigheterAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden hyresrätter och liknande rättigheter',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterAnskaffningsvardenIngaende.previousYear'
                      ),
                      id(
                        'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterForandringAnskaffningsvardenAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:HyresratterLiknandeRattigheterAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden hyresrätter och liknande rättigheter',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'HyresratterLiknandeRattigheterAvskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående avskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1069', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:HyresratterLiknandeRattigheterAvskrivningar',
                  standardRubrik:
                    'Ackumulerade avskrivningar hyresrätter och liknande rättigheter',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(ref(sumAccountsIBs('1069', false, 'previousYear')), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:HyresratterLiknandeRattigheterAvskrivningar',
                  standardRubrik:
                    'Ackumulerade avskrivningar hyresrätter och liknande rättigheter',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows
                .addRowWithType(
                  'HyresratterLiknandeRattigheterForandringAvskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av avskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringAvskrivningarAterfordaAvskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarAterfordaAvskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av avskrivningar för hyresrätter och liknande rättigheter genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarAterfordaAvskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av avskrivningar för hyresrätter och liknande rättigheter genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda avskrivningar i samband med försäljningar eller utrangeringar av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda avskrivningar i samband med försäljningar eller utrangeringar av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringAvskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omklassificeringar av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omklassificeringar av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringAvskrivningarAretsAvskrivningar',
                    'row',
                    noteLabel('Årets avskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarAretsAvskrivningar',
                      standardRubrik:
                        'Periodens avskrivningar hyresrätter och liknande rättigheter',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarAretsAvskrivningar',
                      standardRubrik:
                        'Periodens avskrivningar hyresrätter och liknande rättigheter',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringAvskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'HyresratterLiknandeRattigheterAvskrivningarUtgaende',
                'sum',
                noteLabel('Utgående avskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterAvskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterForandringAvskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:HyresratterLiknandeRattigheterAvskrivningar',
                    standardRubrik:
                      'Ackumulerade avskrivningar hyresrätter och liknande rättigheter',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterAvskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterForandringAvskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:HyresratterLiknandeRattigheterAvskrivningar',
                    standardRubrik:
                      'Ackumulerade avskrivningar hyresrätter och liknande rättigheter',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              rows.addRowWithType(
                'HyresratterLiknandeRattigheterUppskrivningarIngaende',
                'row',
                noteLabel('Ingående uppskrivningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:HyresratterLiknandeRattigheterUppskrivningar',
                  standardRubrik:
                    'Ackumulerade uppskrivningar hyresrätter och liknande rättigheter',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:HyresratterLiknandeRattigheterUppskrivningar',
                  standardRubrik:
                    'Ackumulerade uppskrivningar hyresrätter och liknande rättigheter',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows
                .addRowWithType(
                  'HyresratterLiknandeRattigheterForandringUppskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av uppskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringUppskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringUppskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar för hyresrätter och liknande rättigheter genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringUppskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar för hyresrätter och liknande rättigheter genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringUppskrivningarForsaljningarUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringUppskrivningarForsaljningarUtrangeringar',
                      standardRubrik:
                        'Återförda uppskrivningar i samband med försäljningar eller utrangeringar av hyresrätter och liknande rättigheter',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringUppskrivningarForsaljningarUtrangeringar',
                      standardRubrik:
                        'Återförda uppskrivningar i samband med försäljningar eller utrangeringar av hyresrätter och liknande rättigheter',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringUppskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringUppskrivningarOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringUppskrivningarOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringUppskrivningarAretsUppskrivningar',
                    'row',
                    noteLabel('Årets uppskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringUppskrivningarAretsUppskrivningar',
                      standardRubrik:
                        'Periodens uppskrivningar av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringUppskrivningarAretsUppskrivningar',
                      standardRubrik:
                        'Periodens uppskrivningar av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp',
                    'row',
                    noteLabel('Årets nedskrivningar på uppskrivet belopp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Nedskrivningar av uppskrivet belopp på hyresrätter och liknande rättigheter',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Nedskrivningar av uppskrivet belopp på hyresrätter och liknande rättigheter',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp',
                    'row',
                    noteLabel('Årets avskrivningar på uppskrivet belopp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Avskrivningar av uppskrivet belopp på hyresrätter och liknande rättigheter',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Avskrivningar av uppskrivet belopp på hyresrätter och liknande rättigheter',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringUppskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringUppskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringUppskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'HyresratterLiknandeRattigheterUppskrivningarUtgaende',
                'sum',
                noteLabel('Utgående uppskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterUppskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterForandringUppskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:HyresratterLiknandeRattigheterUppskrivningar',
                    standardRubrik:
                      'Ackumulerade uppskrivningar hyresrätter och liknande rättigheter',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterUppskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterForandringUppskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:HyresratterLiknandeRattigheterUppskrivningar',
                    standardRubrik:
                      'Ackumulerade uppskrivningar hyresrätter och liknande rättigheter',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'HyresratterLiknandeRattigheterNedskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående nedskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1068', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:HyresratterLiknandeRattigheterNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar hyresrätter och liknande rättigheter',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(ref(sumAccountsIBs('1068', false, 'previousYear')), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:HyresratterLiknandeRattigheterNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar hyresrätter och liknande rättigheter',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows
                .addRowWithType(
                  'HyresratterLiknandeRattigheterForandringNedskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av nedskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringNedskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för hyresrätter och liknande rättigheter genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för hyresrätter och liknande rättigheter genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
                    'row',
                    noteLabel('Återförda nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringNedskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringNedskrivningarAretsNedskrivningar',
                    'row',
                    noteLabel('Årets nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av hyresrätter och liknande rättigheter',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av hyresrätter och liknande rättigheter',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'HyresratterLiknandeRattigheterForandringNedskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av hyresrätter och liknande rättigheter',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'HyresratterLiknandeRattigheterNedskrivningarUtgaende',
                'sum',
                noteLabel('Utgående nedskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterNedskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterForandringNedskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:HyresratterLiknandeRattigheterNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar hyresrätter och liknande rättigheter',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterNedskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterForandringNedskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:HyresratterLiknandeRattigheterNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar hyresrätter och liknande rättigheter',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'HyresratterLiknandeRattigheter',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterAnskaffningsvardenUtgaende.year'
                  ),
                  id(
                    'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterAvskrivningarUtgaende.year'
                  ),
                  id(
                    'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterUppskrivningarUtgaende.year'
                  ),
                  id(
                    'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterNedskrivningarUtgaende.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:HyresratterLiknandeRattigheter',
                standardRubrik: 'Hyresrätter och liknande rättigheter',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterAnskaffningsvardenUtgaende.previousYear'
                  ),
                  id(
                    'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterAvskrivningarUtgaende.previousYear'
                  ),
                  id(
                    'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterUppskrivningarUtgaende.previousYear'
                  ),
                  id(
                    'notes.note35.data.HyresratterLiknandeRattigheterAbstract.table.HyresratterLiknandeRattigheterAbstract.HyresratterLiknandeRattigheterNedskrivningarUtgaende.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:HyresratterLiknandeRattigheter',
                standardRubrik: 'Hyresrätter och liknande rättigheter',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          rows.addRowWithType(
            'HyresratterLiknandeRattigheterOffentligaBidrag',
            'row',
            noteLabel('Offentliga bidrag som reducerat anskaffningsvärdet'),
            ixbrlCell(value(undefined), {
              contextRef: 'balans0',
              type: 'monetaryItemType',
              unitRef: 'SEK',
              name: 'se-gen-base:HyresratterLiknandeRattigheterOffentligaBidrag',
              standardRubrik:
                'Offentliga bidrag som reducerat anskaffningsvärdet på hyresrätter och liknande rättigheter',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              contextRef: 'balans1',
              type: 'monetaryItemType',
              unitRef: 'SEK',
              name: 'se-gen-base:HyresratterLiknandeRattigheterOffentligaBidrag',
              standardRubrik:
                'Offentliga bidrag som reducerat anskaffningsvärdet på hyresrätter och liknande rättigheter',
              saldo: 'credit',
              negateValue: true,
            })
          );
          return rows.build();
        })
        .build(),
      NotHyresratterLiknandeRattigheterKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till specifikation av hyresrätter och liknande rättigheter'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotHyresratterLiknandeRattigheterKommentar',
          standardRubrik:
            'Kommentar till specifikation av hyresrätter och liknande rättigheter',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2035K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        HyresratterLiknandeRattigheterAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotHyresratterLiknandeRattigheterKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
