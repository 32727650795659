import { trackCustomEvent } from '@fnox/web-analytics-script';

export function getAdditionalReconciliationPeriodData(period) {
  if (!period) return;
  return {
    object_id: 'reconciliation_period:' + period.id,
    object_date: period.start + ' to ' + period.end,
  };
}

export function trackInvalidTransitionError(
  rule: string,
  eventCategory: string,
  eventAction: string
) {
  let eventName;

  switch (rule) {
    case 'AN_REPORT.from.done.next.locked':
      eventName = 'type:annual_report_next_locked';
      break;
    case 'AN_REPORT.from.not.done.to.locked':
      eventName = 'type:annual_report_must_be_done_to_lock';
      break;
    case 'AN_REPORT.requires.financialStatement.done':
      eventName = 'type:annual_report_financial_statement_done';
      break;
    case 'FIN_STATEMENT.requires.reconciliation.done':
      eventName = 'type:financial_statement_reconciliation_done';
      break;
    default:
      eventName = 'type:no_key';
  }

  trackCustomEvent({
    eventCategory,
    eventAction,
    eventName,
  });
}
