import { isFieldType, isTableType } from '@agoy/document';
import { NoteContainer } from './types';

export const getFieldType = (
  field: any
): 'table' | 'field' | 'group' | null => {
  if (typeof field !== 'object') {
    return null;
  }

  // Group is an object used for hierarchy in notes
  if (field?.type === 'group') {
    return 'group';
  }

  if (isTableType(field)) {
    return 'table';
  }

  return isFieldType(field) ? 'field' : null;
};

export const isNoteContainer = (obj: any): obj is NoteContainer =>
  obj && typeof obj.title === 'object';

export const isAnyGroupActive = (obj): boolean => {
  let foundActiveGroup = false;

  const checkActive = (currentObject) => {
    if (foundActiveGroup) {
      return;
    }
    if (currentObject.type === 'group' && currentObject.active === true) {
      foundActiveGroup = true;
      return;
    }

    Object.values(currentObject).forEach((value) => {
      if (!foundActiveGroup && typeof value === 'object' && value !== null) {
        checkActive(value);
      }
    });
  };

  checkActive(obj);

  return foundActiveGroup;
};

export const removeDuplicatedIXBRLFromFirstColumn = (originalTables) => {
  // Deep clone the original data to prevent modifications to the original array
  const tables = JSON.parse(JSON.stringify(originalTables));

  // Iterate through the tables, starting from the second one (index = 1)
  for (let index = 1; index < tables.length; index++) {
    const table = tables[index];

    // Iterate over each row that contains nested rows
    table.rows?.forEach((row) => {
      row.rows?.forEach((innerRow) => {
        // Only target the first cell in the cells object
        const firstCellKey = Object.keys(innerRow.cells)[0];
        if (firstCellKey) {
          // Remove the 'ixbrl' property from the first cell
          delete innerRow.cells[firstCellKey].ixbrl;
        }
      });
    });
  }

  return tables;
};

export const countActiveRows = (table): number => {
  let count = 0;

  const traverseRows = (rows) => {
    for (const row of rows) {
      if (row.active) {
        count++;
      }
      if (row.rows) {
        traverseRows(row.rows);
      }
    }
  };

  traverseRows(table.rows);
  return count;
};
