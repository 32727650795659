/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-arsredovisning-2021-10-31-rev20230131_sv - Noter.csv
 * - Comments: nan
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  previousYear,
  year,
  sumAccountsIBs,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2040K2v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2040K2v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Maskiner och andra tekniska anläggningar'),
  data: {
    active: true,
    MaskinerAndraTekniskaAnlaggningarAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Maskiner och andra tekniska anläggningar'),
      table: table<IxbrlCell>(
        'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table',
        {
          id: 'MaskinerAndraTekniskaAnlaggningarAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'MaskinerAndraTekniskaAnlaggningarAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'MaskinerAndraTekniskaAnlaggningarAnskaffningsvardenIngaende',
                'sum_accounts',
                noteLabel('Ingående anskaffningsvärden'),
                ixbrlCell(ref(sumAccountsIBs('1200:1213', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarAnskaffningsvarden',
                  standardRubrik:
                    'Anskaffningsvärden maskiner och andra tekniska anläggningar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsIBs('1200:1213', false, 'previousYear')),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden maskiner och andra tekniska anläggningar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows
                .addRowWithType(
                  'MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenAbstract',
                  'header',
                  noteLabel('Förändringar av anskaffningsvärden'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenInkop',
                    'row',
                    noteLabel('Inköp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenInkop',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med inköp av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenInkop',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med inköp av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenRorelseforvarv',
                    'row',
                    noteLabel('Rörelseförvärv'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för maskiner och andra tekniska anläggningar genom rörelseförvärv',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för maskiner och andra tekniska anläggningar genom rörelseförvärv',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för maskiner och andra tekniska anläggningar genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för maskiner och andra tekniska anläggningar genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenForsaljningarUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenForsaljningarUtrangeringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av maskiner och andra tekniska anläggningar',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenForsaljningarUtrangeringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av maskiner och andra tekniska anläggningar',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'MaskinerAndraTekniskaAnlaggningarAnskaffningsvardenUtgaende',
                'sum',
                noteLabel('Utgående anskaffningsvärden'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table.MaskinerAndraTekniskaAnlaggningarAbstract.MaskinerAndraTekniskaAnlaggningarAnskaffningsvardenIngaende.year'
                      ),
                      id(
                        'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table.MaskinerAndraTekniskaAnlaggningarAbstract.MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden maskiner och andra tekniska anläggningar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table.MaskinerAndraTekniskaAnlaggningarAbstract.MaskinerAndraTekniskaAnlaggningarAnskaffningsvardenIngaende.previousYear'
                      ),
                      id(
                        'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table.MaskinerAndraTekniskaAnlaggningarAbstract.MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden maskiner och andra tekniska anläggningar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'MaskinerAndraTekniskaAnlaggningarAvskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående avskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1219', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarAvskrivningar',
                  standardRubrik:
                    'Ackumulerade avskrivningar maskiner och andra tekniska anläggningar',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(ref(sumAccountsIBs('1219', false, 'previousYear')), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarAvskrivningar',
                  standardRubrik:
                    'Ackumulerade avskrivningar maskiner och andra tekniska anläggningar',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows
                .addRowWithType(
                  'MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av avskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av avskrivningar för maskiner och andra tekniska anläggningar genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av avskrivningar för maskiner och andra tekniska anläggningar genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda avskrivningar i samband med försäljningar eller utrangeringar av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda avskrivningar i samband med försäljningar eller utrangeringar av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omklassificeringar av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omklassificeringar av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarAretsAvskrivningar',
                    'row',
                    noteLabel('Årets avskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarAretsAvskrivningar',
                      standardRubrik:
                        'Periodens avskrivningar maskiner och andra tekniska anläggningar',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarAretsAvskrivningar',
                      standardRubrik:
                        'Periodens avskrivningar maskiner och andra tekniska anläggningar',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'MaskinerAndraTekniskaAnlaggningarAvskrivningarUtgaende',
                'sum',
                noteLabel('Utgående avskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table.MaskinerAndraTekniskaAnlaggningarAbstract.MaskinerAndraTekniskaAnlaggningarAvskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table.MaskinerAndraTekniskaAnlaggningarAbstract.MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarAvskrivningar',
                    standardRubrik:
                      'Ackumulerade avskrivningar maskiner och andra tekniska anläggningar',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table.MaskinerAndraTekniskaAnlaggningarAbstract.MaskinerAndraTekniskaAnlaggningarAvskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table.MaskinerAndraTekniskaAnlaggningarAbstract.MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarAvskrivningar',
                    standardRubrik:
                      'Ackumulerade avskrivningar maskiner och andra tekniska anläggningar',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              rows.addRowWithType(
                'MaskinerAndraTekniskaAnlaggningarNedskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående nedskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1218', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar maskiner och andra tekniska anläggningar',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(ref(sumAccountsIBs('1218', false, 'previousYear')), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar maskiner och andra tekniska anläggningar',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows
                .addRowWithType(
                  'MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av nedskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för maskiner och andra tekniska anläggningar genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för maskiner och andra tekniska anläggningar genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAterfordaNedskrivningar',
                    'row',
                    noteLabel('Återförda nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAretsNedskrivningar',
                    'row',
                    noteLabel('Årets nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av maskiner och andra tekniska anläggningar',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av maskiner och andra tekniska anläggningar',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av maskiner och andra tekniska anläggningar',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'MaskinerAndraTekniskaAnlaggningarNedskrivningarUtgaende',
                'sum',
                noteLabel('Utgående nedskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table.MaskinerAndraTekniskaAnlaggningarAbstract.MaskinerAndraTekniskaAnlaggningarNedskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table.MaskinerAndraTekniskaAnlaggningarAbstract.MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar maskiner och andra tekniska anläggningar',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table.MaskinerAndraTekniskaAnlaggningarAbstract.MaskinerAndraTekniskaAnlaggningarNedskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table.MaskinerAndraTekniskaAnlaggningarAbstract.MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar maskiner och andra tekniska anläggningar',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'MaskinerAndraTekniskaAnlaggningar',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table.MaskinerAndraTekniskaAnlaggningarAbstract.MaskinerAndraTekniskaAnlaggningarAnskaffningsvardenUtgaende.year'
                  ),
                  id(
                    'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table.MaskinerAndraTekniskaAnlaggningarAbstract.MaskinerAndraTekniskaAnlaggningarAvskrivningarUtgaende.year'
                  ),
                  id(
                    'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table.MaskinerAndraTekniskaAnlaggningarAbstract.MaskinerAndraTekniskaAnlaggningarNedskrivningarUtgaende.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningar',
                standardRubrik: 'Maskiner och andra tekniska anläggningar',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table.MaskinerAndraTekniskaAnlaggningarAbstract.MaskinerAndraTekniskaAnlaggningarAnskaffningsvardenUtgaende.previousYear'
                  ),
                  id(
                    'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table.MaskinerAndraTekniskaAnlaggningarAbstract.MaskinerAndraTekniskaAnlaggningarAvskrivningarUtgaende.previousYear'
                  ),
                  id(
                    'notes.note40.data.MaskinerAndraTekniskaAnlaggningarAbstract.table.MaskinerAndraTekniskaAnlaggningarAbstract.MaskinerAndraTekniskaAnlaggningarNedskrivningarUtgaende.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningar',
                standardRubrik: 'Maskiner och andra tekniska anläggningar',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NotMaskinerAndraTekniskaAnlaggningarKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till specifikation av maskiner och andra tekniska anläggningar'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotMaskinerAndraTekniskaAnlaggningarKommentar',
          standardRubrik:
            'Kommentar till specifikation av maskiner och andra tekniska anläggningar',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2040K2v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        MaskinerAndraTekniskaAnlaggningarAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotMaskinerAndraTekniskaAnlaggningarKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
