import React from 'react';
import {
  ReconciliationBalanceAccountGroup,
  ReconciliationBalanceAccountRow,
} from '@agoy/api-sdk-core';
import { ReconciliationPeriod } from '@agoy/reconciliation';
import { HiddenRow, GroupRow } from './types';

/**
 * The hidden row context that manages which hidden row are
 * opened. Multiple accounts can be opened at the same time, but
 * only one period per account can be visible.
 *
 */
type HiddenRowsContextType = {
  hiddenRows: Record<string, HiddenRowType>;

  /**
   * addHiddenRow, adds a hidden row under a specific account
   *
   * @param accountNumber
   */
  addHiddenRow: (
    accountNumber: string,
    groupId: string,
    period: ReconciliationPeriod,
    accountBalance: ReconciliationBalanceAccountRow,
    /**
     * Callback when hidden row is closed for the given period,
     * called when another period is opened for the same account number.
     */
    onClose: () => void
  ) => void;

  /**
   * addHiddenRow, adds a hidden row under a specific account
   *
   * @param accountNumber
   */
  addGroupHiddenRow: (
    groupId: string,
    period: ReconciliationPeriod,
    groupRow: ReconciliationBalanceAccountGroup,
    /**
     * Callback when hidden row is closed for the given period,
     * called when another period is opened for the same account number.
     */
    onClose: () => void
  ) => void;

  /**
   * removeHiddenRow, removes/closes a hidden row
   */
  removeHiddenRow: (
    accountNumber: string,
    period: ReconciliationPeriod
  ) => void;

  removeAllHiddenRows: () => void;

  /**
   * removeAllHiddenRowsByPeriod, removes/closes all hidden rows for a specific period
   *
   * @param period
   */
  removeAllHiddenRowsByPeriod: (period: ReconciliationPeriod) => void;
};

export type HiddenRowType = {
  row: HiddenRow;
  group?: GroupRow;
  groupId?: string;
  period: ReconciliationPeriod;
  onClose: () => void;
};

const HiddenRowsContext = React.createContext<HiddenRowsContextType>({
  hiddenRows: {},
  addHiddenRow: () => {
    throw new Error('No context provided');
  },
  removeHiddenRow: () => {
    throw new Error('No context provided');
  },
  removeAllHiddenRows: () => {
    throw new Error('No context provided');
  },
  removeAllHiddenRowsByPeriod: () => {
    throw new Error('No context provided');
  },
  addGroupHiddenRow: () => {
    throw new Error('No context provided');
  },
});

export default HiddenRowsContext;
