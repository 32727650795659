/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6783 (former Note 100)
 */

import {
  value,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2055K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2055K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel(
    'Finansiella tillgångar som inte tagits bort från balansräkningen'
  ),
  data: {
    active: true,
    FinansiellaTillgangarInteTagitsBortBalansrakningenTuple: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Finansiella tillgångar som inte tagits bort från balansräkningen'
      ),
      table: table<IxbrlCell>(
        'notes.note55.data.FinansiellaTillgangarInteTagitsBortBalansrakningenTuple.table',
        {
          id: 'FinansiellaTillgangarInteTagitsBortBalansrakningenTillgangensTyp',
          label: 'Typ av finansiell tillgång',
          dataType: 'text',
          active: true,
        },
        {
          id: 'FinansiellaTillgangarInteTagitsBortBalansrakningenTillgangenRiskFordelarBeskrivning',
          label:
            'Karaktären på de risker och fördelar som är förknippade med innehavet och som företaget har kvar',
          dataType: 'text',
          active: true,
        },
        {
          id: 'FinansiellaTillgangarInteTagitsBortBalansrakningenTillgangenRedovisatVarde',
          label: 'Redovisat värde på tillgången',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'FinansiellaTillgangarInteTagitsBortBalansrakningenSkuldRedovisatVarde',
          label: 'Redovisat värde på skulden som är knuten till tillgången',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'FinansiellaTillgangarInteTagitsBortBalansrakningenTuple',
              'hidden',
              undefined,
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Typ av finansiell tillgång'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:FinansiellaTillgangarInteTagitsBortBalansrakningenTillgangensTyp',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Typ av finansiell tillgång som inte tagits bort från balansräkningen',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:FinansiellaTillgangarInteTagitsBortBalansrakningenTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(
                  value(
                    'Karaktären på de risker och fördelar som är förknippade med innehavet och som företaget har kvar'
                  ),
                  {
                    type: 'stringItemType',
                    name: 'se-gen-base:FinansiellaTillgangarInteTagitsBortBalansrakningenTillgangenRiskFordelarBeskrivning',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Karaktären på de risker och fördelar som är förknippade med innehavet och som företaget har kvar',
                    negateValue: false,
                    tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }
                ),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaTillgangarInteTagitsBortBalansrakningenTillgangenRedovisatVarde',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Redovisat värde per tillgång som inte tagits bort från balansräkningen',
                  saldo: 'debit',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaTillgangarInteTagitsBortBalansrakningenSkuldRedovisatVarde',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Redovisat värde på skuld som är knuten till tillgången som inte tagits bort från balansräkningen',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              FinansiellaTillgangarInteTagitsBortBalansrakningenTillgangensTyp:
                ixbrlCell(value('Typ av finansiell tillgång'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:FinansiellaTillgangarInteTagitsBortBalansrakningenTillgangensTyp',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Typ av finansiell tillgång som inte tagits bort från balansräkningen',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:FinansiellaTillgangarInteTagitsBortBalansrakningenTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
              FinansiellaTillgangarInteTagitsBortBalansrakningenTillgangenRiskFordelarBeskrivning:
                ixbrlCell(
                  value(
                    'Karaktären på de risker och fördelar som är förknippade med innehavet och som företaget har kvar'
                  ),
                  {
                    type: 'stringItemType',
                    name: 'se-gen-base:FinansiellaTillgangarInteTagitsBortBalansrakningenTillgangenRiskFordelarBeskrivning',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Karaktären på de risker och fördelar som är förknippade med innehavet och som företaget har kvar',
                    negateValue: false,
                    tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }
                ),
              FinansiellaTillgangarInteTagitsBortBalansrakningenTillgangenRedovisatVarde:
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaTillgangarInteTagitsBortBalansrakningenTillgangenRedovisatVarde',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Redovisat värde per tillgång som inte tagits bort från balansräkningen',
                  saldo: 'debit',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
              FinansiellaTillgangarInteTagitsBortBalansrakningenSkuldRedovisatVarde:
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaTillgangarInteTagitsBortBalansrakningenSkuldRedovisatVarde',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Redovisat värde på skuld som är knuten till tillgången som inte tagits bort från balansräkningen',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(4, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
            },
          }));
          return rows.build();
        })
        .build(),
      NotFinansiellaTillgangarInteTagitsBortBalansrakningenKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till specifikation av finansiella tillgångar som inte tagits bort från balansräkningen'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotFinansiellaTillgangarInteTagitsBortBalansrakningenKommentar',
          standardRubrik:
            'Kommentar till specifikation av finansiella tillgångar som inte tagits bort från balansräkningen',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2055K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        FinansiellaTillgangarInteTagitsBortBalansrakningenTuple: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotFinansiellaTillgangarInteTagitsBortBalansrakningenKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
