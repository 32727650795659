import React, { forwardRef } from 'react';
import { Route as ReactRoute, RouteProps, useParams } from 'react-router-dom';
import { resolvePath } from '../../utils/routes';
import useFid from '../hooks/useFid';
import { FORTNOX_ROUTE_PREFIX } from '../../contants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Route = forwardRef(({ path, ...rest }: RouteProps, ref) => {
  const params = useParams();
  const fid = useFid();
  let newPath;
  // Some route components are called with wildcards and some with values
  // filled (e.g. /agoy/:fid vs /agoy/013ca4f2b8ce4a8d9c48ca68f8995c56). We
  // need to support both when wildcard is used and the fid value is used.
  if (!fid || !path || path.includes(FORTNOX_ROUTE_PREFIX)) {
    newPath = path;
  } else if (Object.keys(params).filter((x) => x !== 'fid').length > 0) {
    newPath = resolvePath(fid, path as string);
  } else {
    newPath = `${FORTNOX_ROUTE_PREFIX}${path}`;
  }
  return <ReactRoute path={newPath} {...rest} />;
});
export default Route;
