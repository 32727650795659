import { value } from '@agoy/document';
import { CustomNotes } from '../common';
import { field, table } from '../common/utils/util';
import { previousYear, year } from '../common/utils/date-util';

export const getSharesCompanyCustomNote = (
  financialYear: string,
  financialYears: string[],
  period: string | null
): CustomNotes => {
  return {
    type: 'sections',
    sections: [],
    newSectionTemplate: {
      active: false,
      number: { type: 'number', value: 0 },
      name: { type: 'string', value: 'Ny not' },
      group: { type: 'string', value: 'other' },
      section: { type: 'string', value: '' },
      validForReportType: {
        k2: true,
        k3: true,
      },
      commentTitle: { type: 'string', value: 'Kommentar' },
      typeOne: field('Egen fritext'),
      typeTwo: table(
        'notes.custom.typeTwoTable',
        { id: 'label', label: '', dataType: 'text' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
          dataType: 'numeric',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
          dataType: 'numeric',
        }
      )
        .newColumnTemplate((column) => column.setType('string').build())
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      typeThree: table(
        'notes.custom.typeThreeTable',
        { id: 'label', label: '' },
        { id: 'val1', label: '' },
        { id: 'val2', label: '' },
        { id: 'val3', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
          dataType: 'numeric',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
          dataType: 'numeric',
        }
      )
        .newColumnTemplate((column) => column.setType('string').build())
        .newRowTemplate(
          value(''),
          value(''),
          value(''),
          value(''),
          value(undefined),
          value(undefined)
        )
        .build(),
    },
  };
};

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };

export const customNoteContentDefinition = {
  type: 'array' as const,
  children: {
    type: 'part' as const,
    children: {
      active: boolean,
      number: cell,
      name: cell,
      group: cell,
      section: cell,
      validForReportType: {
        type: 'part' as const,
        children: {
          k2: boolean,
          k3: boolean,
        },
      },
      commentTitle: cell,
      typeOne: cell,
      typeTwo: { type: 'table' as const },
      typeThree: { type: 'table' as const },
    },
  },
};
