import { combineReducers } from 'redux';
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux';
import user from '_users/redux';
import { USER_LOGOUT } from '_users/redux/action-types';
import customers from '_clients/redux/customers';
import persons from '_person/redux/persons';
import customersOverview from '_clients/redux/customers-overview';
import accountingView from '_reconciliation/redux/accounting-view';
import organisation from '_organization/redux';
import messages from '_messages/redux';
import accountListDrawer from '_shared/redux/account-list-drawer/reducer';
import checklist from '_shared/redux/checklist/reducer';
import customerView from '_clients/redux/customer-view';
import personsOverview from '_person/redux/personsOverview';
import document from '_tax/redux/document/reducer';
import taxView from '_tax/redux/tax-view/reducer';
import annualReport from '_annual-report/redux/annual-report/reducer';
import financialReport from '_financial-report/redux/reducer';
import taxDeclarationView from './TaxDeclarationView';
import ui from './UI';
import { featureSwitch } from '_access-rights/redux';

const reducer = combineReducers({
  accountListDrawer,
  user,
  /**
   * This reducer holds the state of `clients`.
   * Its called `customers` for legacy reasons (waiting for a refactor).
   */
  customers,
  persons,
  personsOverview,
  organisation,
  accountingView,
  taxView,
  taxDeclarationView,
  customersOverview,
  customerView,
  messages,
  annualReport,
  ui,
  document,
  financialReport,
  checklist,
  featureSwitch,
});

const rootReducer = (state, action) => {
  return reducer(action.type === USER_LOGOUT ? undefined : state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export default rootReducer;
