import React, { useEffect, useMemo, useState, useRef, memo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { orderBy } from 'lodash';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { areIntervalsOverlapping, format } from 'date-fns';
import { ErrorBoundary } from 'react-error-boundary';
import { useIntl } from 'react-intl';

import { ClientCompany } from '@agoy/api-sdk-core';
import { TimePeriod } from '@agoy/document';
import {
  addGlobalErrorMessage,
  getClient,
  setCurrentCustomer,
} from 'redux/actions';
import { useSelector } from 'redux/reducers';
import { ClientsRouteParams } from 'routes/types';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import { fetchProgramStatus } from '_shared/redux/actions';
import { ReconciliationIconMenuProvider } from '_shared/components/IconMenu';
import {
  setRoutinesData,
  setSinglePeriod,
} from '_reconciliation/redux/accounting-view/actions';
import { asResultClass, isApiErrorType, useApiSdk } from 'api-sdk';
import { mapRoutinesData } from '_clients/services/mappings';
import ReconciliationSpeedDial from '_shared/components/SpeedDial/ReconciliationSpeedDial';
import UserInputLoader from '_clients/components/Loaders/UserInputLoader';
import LoadProductStatuses from '_clients/components/LoadProductStatuses';
import UpdateSieFromFortnox from '_clients/components/UpdateSieFromFortnox';
import ConfirmationDialog from '_shared/components/Dialogs/ConfirmationDialog';
import FallbackComponent from '_shared/components/ErrorBoundary';
import { isSameOrBefore, parse } from '@agoy/dates';

import {
  getGroupedPeriods,
  getType,
  ReconciliationPeriod,
} from '@agoy/reconciliation';
import { trackCustomEvent } from '@fnox/web-analytics-script';
import useHistory from '_shared/hooks/useHistory';
import { AccountNamesContextProvider } from './AccountNamesContext';
import { RowContextProvider } from './RowContext';
import { RowWithoutClassName } from './RowContext/types';
import { AnnualReportContextProvider } from './RowContext/AnnualReportContext';
import {
  RowHoverContextProvider,
  RowHoverType,
} from './RowContext/RowHoverContext';
import { DndContextProvider, DragOverGroup } from './RowContext/DndContext';
import { MoveAccountsContextProvider } from './RowContext/MoveAccountsContext';
import ScrollableTable, { ScrollableTableRef } from './ScrollableTable';
import PeriodColumnContent from './Columns/PeriodColumn/PeriodColumnContent';
import HeaderColumn from './Columns/HeaderColumn/Cells/HeaderColumn';
import PeriodColumn from './Columns/PeriodColumn/PeriodColumn';
import ExpandRow from './Columns/PeriodColumn/ExpandRow';
import Header from './Columns/HeaderColumn/Cells/Header';
import TopHeader from './TopHeader';
import MovingAccountsToolbar from './MovingAccountsToolbar';
import { withNewSpecificationsFeatureSwitchContext } from '_shared/HOC/withNewSpecificationsFeatureSwitchContext';

const getClassName = (row: RowWithoutClassName) => {
  return `row ${row.type}`;
};

const ReconciliationTable = styled(ScrollableTable)`
  flex-direction: column;

  .row.account {
    height: 40px;
    background-color: #fff;
  }

  .row.header {
    height: fit-content;
    border: none;
    position: sticky;
    top: 45px;
    z-index: 4;
  }

  .row.group {
    height: max-content;
  }

  .row.keyFigure {
    background-color: #fff;
  }

  .row.groupSum {
    background-color: #fff;
  }

  .row.groupHeader {
    background-color: #fafafa;
  }

  .row.hidden {
    height: unset;
  }

  .row.hiddenGroup {
    height: unset;
  }

  .if-visible {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .row {
    height: 40px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: auto;
`;

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const defaultRows = [
  {
    type: 'header' as const,
    id: 'header',
  },
];

type ReconciliationContentProps = {
  clientId: string;
  financialYear: ClientCompany['financialYears'][0];
};

/**
 * New Reconciliation View, currently using the old APIs
 *
 */
const ReconciliationContent = memo(
  ({ clientId, financialYear }: ReconciliationContentProps) => {
    const history = useHistory();
    const { formatMessage } = useIntl();

    const [dragOverGroup, setDragOverGroup] = useState<DragOverGroup>(null);

    const tableRef = useRef<ScrollableTableRef>(null);

    const [dialogPeriodType, setDialogPeriodType] = useState(false);
    const [expandedColumns, setExpandedColumns] = useState<
      Record<string, boolean>
    >({});
    const [focusedPeriod, setFocusedPeriod] = useState<
      ReconciliationPeriod | undefined
    >();
    const [initialFocus, setInitialFocus] = useState(false);

    const rawFinancialYears = useSelector(
      (state) => state.customers[clientId]?.rawFinancialYears
    );
    const clientClosingPeriod = useSelector(
      (state) => state.customers[clientId]?.closingPeriod
    );

    const periodStatus = useSelector(
      (state) => state.accountingView.clients[clientId]?.periodStatus
    );

    const closingPeriod = clientClosingPeriod || 'month';

    const {
      periodChangeVisible,
      periodUBVisible,
      showSinglePeriod,
      showSinglePeriodType,
      periodLockedVisible,
    } = useSelector((state) => state.accountingView);

    const periodType = getType(closingPeriod);

    const columnSize = useMemo(() => {
      if (periodChangeVisible && periodUBVisible) {
        return 'full';
      }
      return 'half';
    }, [periodChangeVisible, periodUBVisible]);

    const financialYears = useMemo(() => {
      return rawFinancialYears ? orderBy(rawFinancialYears, 'start') : [];
    }, [rawFinancialYears]);

    // First we create all the periods memoized
    const allPeriods: ReconciliationPeriod[] = useMemo(() => {
      if (!financialYears || !periodType) {
        return [];
      }
      return getGroupedPeriods(
        clientId,
        periodType,
        financialYear,
        financialYears
      );
    }, [periodType, clientId, financialYear, financialYears]);

    // Create the year end period memoized to prevent rendering issues
    const yearEndPeriod = useMemo(
      () => ({
        type: 'yearEnd' as const,
        clientId,
        start: financialYear.start,
        end: financialYear.end,
        financialYears: [financialYear],
        periods:
          financialYear.periods
            ?.filter((p) => p.type === 'year_end')
            .map((p) => ({
              ...p,
              financialYear,
            })) ?? [],
      }),
      [clientId, financialYear]
    );

    // Then we filter the periods, this keeps the original period objects
    // unchanged.
    // We add the yearEnd period last
    const periods: ReconciliationPeriod[] = useMemo(() => {
      let resultPeriods = [...allPeriods];

      if (showSinglePeriod) {
        resultPeriods = resultPeriods.filter((period) =>
          showSinglePeriodType !== 'yearEnd'
            ? period.start === showSinglePeriod
            : period.start === resultPeriods[resultPeriods.length - 1].start
        );
      }

      if (!periodLockedVisible) {
        const lastLockedIndex = resultPeriods.reduce(
          (lastLocked, period, index) => {
            const subPeriods = period.periods.filter(
              (subPeriod) => subPeriod.type === 'month'
            );
            const lastPeriod = subPeriods[subPeriods.length - 1];
            const status =
              periodStatus?.[lastPeriod?.id]?.status || 'NOT_STARTED';

            if (status === 'LOCKED') {
              return index;
            }

            return lastLocked;
          },
          -1
        );

        if (lastLockedIndex !== -1) {
          resultPeriods = resultPeriods.slice(lastLockedIndex + 1);
        }
      }

      if (yearEndPeriod) {
        resultPeriods.push(yearEndPeriod);
      }
      return resultPeriods;
    }, [
      allPeriods,
      periodLockedVisible,
      periodStatus,
      showSinglePeriod,
      showSinglePeriodType,
      yearEndPeriod,
    ]);

    // All the collapsedPeriods by quarter/financialYear start.
    const collapsedPeriods = useMemo((): Record<
      string,
      ReconciliationPeriod[]
    > => {
      if (periodType === 'month') {
        return {};
      }
      return periods
        .filter((p) => p.type !== 'yearEnd')
        .reduce(
          (result, period) => ({
            ...result,
            [period.start]: period.periods
              .filter((p) => p.type === 'month')
              .map(
                (p): ReconciliationPeriod => ({
                  ...p,
                  type: 'dead',
                  clientId: period.clientId,
                  periods: [p],
                  financialYears: period.financialYears.filter(
                    (y) => y.start <= p.start && y.end >= p.end
                  ),
                  parent: period,
                  withinFinancialYear:
                    period.type === 'quarter'
                      ? areIntervalsOverlapping(
                          TimePeriod.fromISODates(p).toInterval(),
                          TimePeriod.fromISODates(financialYear).toInterval(),
                          { inclusive: true }
                        )
                      : true,
                })
              ),
          }),
          {}
        );
    }, [periods, financialYear, periodType]);

    useEffect(() => {
      if (showSinglePeriod) {
        setFocusedPeriod(undefined);
        return;
      }

      const newFocusPeriod = periods.find((period) => {
        return period.financialYears.find(
          (item) => item.start === financialYear.start
        );
      });

      setFocusedPeriod((currentPeriod) =>
        currentPeriod?.start === newFocusPeriod?.start &&
        currentPeriod?.end === newFocusPeriod?.end
          ? currentPeriod
          : newFocusPeriod
      );
    }, [financialYear, financialYears, periods, showSinglePeriod]);

    useEffect(() => {
      if (focusedPeriod) {
        tableRef.current?.scrollToFocus(initialFocus ? 'smooth' : 'auto');
        if (!initialFocus) {
          setInitialFocus(true);
        }
      }
    }, [focusedPeriod, initialFocus]);

    useEffect(() => {
      const newExpandedColumns = {};

      periods.forEach((period) => {
        newExpandedColumns[period.start] = false;
      });

      setExpandedColumns(newExpandedColumns);
    }, [periods]);

    const handleExpandColumn = (period: ReconciliationPeriod) => {
      const updatedExpandedColumns = { ...expandedColumns };

      updatedExpandedColumns[period.start] =
        !updatedExpandedColumns[period.start];

      setExpandedColumns(updatedExpandedColumns);
    };

    const handleClickScroll = () => {
      const currentDatePeriod = periods.find((item) => {
        const currentDate = format(new Date(), 'yyyy-MM-dd');

        return currentDate >= item.start && currentDate <= item.end;
      });

      const newFocusedPeriod = currentDatePeriod || periods[periods.length - 1];

      if (newFocusedPeriod === focusedPeriod) {
        tableRef.current?.scrollToFocus('smooth');
      } else {
        setFocusedPeriod(newFocusedPeriod);
      }
    };

    const onClosePeriodTypeDialog = () => {
      setDialogPeriodType(false);
    };

    const onClickPeriodTypeDialog = () => {
      history.push(`/clients/${clientId}`);
    };

    useEffect(() => {
      if (!clientClosingPeriod) {
        setDialogPeriodType(true);
        trackCustomEvent({
          eventCategory: 'reconciliations',
          eventAction: 'reconciliation:warning',
          eventName: 'modal:missing_period',
        });
      }
    }, [clientClosingPeriod]);

    return (
      <ContainerWrapper>
        <TopHeader clientId={clientId} onClickScroll={handleClickScroll} />

        {!clientClosingPeriod && dialogPeriodType && (
          <ConfirmationDialog
            title={formatMessage({
              id: 'reconciliation.periodType.missing.title',
            })}
            text={formatMessage({
              id: 'reconciliation.periodType.missing.text',
            })}
            onAccept={onClickPeriodTypeDialog}
            onClose={onClosePeriodTypeDialog}
            acceptButtonText={formatMessage({
              id: 'reconciliation.periodType.missing.button.open',
            })}
            closeButtonText={formatMessage({ id: 'CLOSE' })}
          />
        )}

        <DndContextProvider setDragOverGroup={setDragOverGroup}>
          <ReconciliationTable ref={tableRef}>
            <Header
              type={periodType}
              stickyHeader
              clientId={clientId}
              financialYear={financialYear}
            />

            <HeaderColumn
              clientId={clientId}
              financialYear={financialYear}
              groupedPeriods={periods}
              className={
                dragOverGroup?.isHeader
                  ? `dragOver_${dragOverGroup.groupId}`
                  : ''
              }
              sticky
            />

            {periods.map((period) => {
              const expanded =
                period.type !== 'yearEnd' && expandedColumns[period.start];
              const subPeriods = collapsedPeriods[period.start];

              const onlyOneAndEnd =
                showSinglePeriodType === 'yearEnd' && period.type !== 'yearEnd';
              const onlyOneAndPeriod =
                showSinglePeriodType !== 'yearEnd' && period.type === 'yearEnd';
              const columnShouldBeHidden =
                !!showSinglePeriod && (onlyOneAndPeriod || onlyOneAndEnd);

              const isDragGroupOver =
                dragOverGroup &&
                !dragOverGroup.isHeader &&
                isSameOrBefore(
                  parse(dragOverGroup?.periodStart),
                  parse(period.start)
                );

              return (
                <PeriodColumn
                  key={`${period.start}_${period.type}`}
                  focus={period === focusedPeriod}
                  sticky={false}
                  size={columnSize}
                  type={periodType}
                  expandedColumnsCount={expanded ? subPeriods.length : 0}
                  hidden={columnShouldBeHidden}
                >
                  <ExpandRow
                    period={period}
                    size={columnSize}
                    expanded={expanded}
                    onToggleExpand={handleExpandColumn}
                  />
                  <Row>
                    {expanded &&
                      subPeriods.map((subPeriod) => (
                        <PeriodColumnContent
                          key={subPeriod.start}
                          clientId={clientId}
                          period={subPeriod}
                          size={columnSize}
                          expanded={false}
                          notActive
                        />
                      ))}
                    <PeriodColumnContent
                      clientId={clientId}
                      period={period}
                      size={columnSize}
                      expanded={!!expandedColumns[period.start]}
                      className={
                        isDragGroupOver
                          ? `dragOver_${dragOverGroup.groupId}`
                          : ''
                      }
                    />
                  </Row>
                </PeriodColumn>
              );
            })}
          </ReconciliationTable>
        </DndContextProvider>
        <ReconciliationSpeedDial
          clientId={clientId}
          financialYear={financialYear}
          periodType={periodType}
          groupedPeriods={periods}
        />
        <MovingAccountsToolbar />
      </ContainerWrapper>
    );
  }
);

const ReconciliationView = () => {
  const dispatch = useDispatch();
  const route = useRouteMatch<ClientsRouteParams>();
  const sdk = useApiSdk();

  const [hoverRow, setHoverRow] = useState<RowHoverType>(null);

  const { clientId, financialYear } = route.params;

  const rawFinancialYears = useSelector(
    (state) => state.customers[clientId]?.rawFinancialYears
  );
  const clientLoaded = rawFinancialYears !== undefined;

  useEffect(() => {
    dispatch(setSinglePeriod());
  }, [financialYear, dispatch]);

  useEffect(() => {
    if (clientLoaded) {
      dispatch(setCurrentCustomer(clientId, financialYear));
    }
  }, [clientLoaded, clientId, financialYear, dispatch]);

  useEffect(() => {
    // Clear current customer when leaving
    return () => {
      dispatch(setCurrentCustomer(undefined, undefined));
    };
  }, [dispatch]);

  useEffect(() => {
    const fetchRoutines = async () => {
      const routinesResult = await asResultClass(
        sdk.getRoutines({ clientid: clientId })
      );

      if (routinesResult.err) {
        if (!isApiErrorType(routinesResult) || !routinesResult.handled) {
          dispatch(addGlobalErrorMessage('error'));
        }
        return;
      }

      const mapped = mapRoutinesData(routinesResult.val);
      await dispatch(setRoutinesData(clientId, mapped));
    };
    fetchRoutines();
  }, [clientId, dispatch, sdk]);

  const rawFinancialYear = useMemo(() => {
    return rawFinancialYears?.find(
      (year) =>
        financialYear &&
        TimePeriod.fromFinancialYear(financialYear).toISODates().start ===
          year.start
    );
  }, [financialYear, rawFinancialYears]);

  useEffect(() => {
    dispatch(getClient(clientId));
    dispatch(fetchProgramStatus(clientId, 'RECONCILIATION'));
  }, [clientId, dispatch]);

  if (!rawFinancialYear || !clientId) {
    return <LoadingPlaceholder />;
  }

  return (
    <Container
      className={
        hoverRow
          ? `hover_account_${hoverRow.accountId} hover_group_${hoverRow.groupId}`
          : ''
      }
    >
      <ReconciliationIconMenuProvider />

      <UserInputLoader
        clientId={clientId}
        financialYearId={rawFinancialYear.id}
      />
      <UpdateSieFromFortnox
        clientId={clientId}
        financialYear={financialYear}
        immediatelyUpdate
      />

      <LoadProductStatuses program="FIN_STATEMENT" />

      <RowContextProvider
        classNameProvider={getClassName}
        defaultRows={defaultRows}
      >
        <AccountNamesContextProvider
          clientId={clientId}
          financialYearId={rawFinancialYear.id}
        >
          <AnnualReportContextProvider
            clientId={clientId}
            financialYear={financialYear}
          >
            <RowHoverContextProvider setHoverRow={setHoverRow}>
              <MoveAccountsContextProvider
                clientId={clientId}
                financialYear={rawFinancialYear}
              >
                <ErrorBoundary fallback={<FallbackComponent />}>
                  <ReconciliationContent
                    clientId={clientId}
                    financialYear={rawFinancialYear}
                  />
                </ErrorBoundary>
              </MoveAccountsContextProvider>
            </RowHoverContextProvider>
          </AnnualReportContextProvider>
        </AccountNamesContextProvider>
      </RowContextProvider>
    </Container>
  );
};

export default ReconciliationView;
