import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';

import Button from '_shared/components/Buttons/Button';

const SaveContainer = styled.div`
  align-self: baseline;
`;
const ButtonWrapper = styled.div`
  display: flex;
`;
interface SaveChangesProps {
  saveChanges?: () => void;
  saveLabel: string;
  savingChanges?: boolean;
  discardLabel: string;
  discardChanges: () => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const SaveChanges = ({
  saveChanges,
  saveLabel,
  savingChanges = false,
  discardLabel,
  discardChanges,
  type,
}: SaveChangesProps) => {
  return (
    <SaveContainer>
      <ButtonWrapper>
        <Box mr={2}>
          <Button
            label={discardLabel}
            onClick={discardChanges}
            variant="text"
          />
        </Box>
        <Button
          label={saveLabel}
          type={type}
          loading={savingChanges}
          onClick={saveChanges}
          testId="save-btn"
        />
      </ButtonWrapper>
    </SaveContainer>
  );
};

export default SaveChanges;
