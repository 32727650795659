/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6857
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
  previousYear,
  year,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2071K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2071K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Avsättningar för pensioner och liknande förpliktelser'),
  data: {
    active: true,
    AvsattningarPensionerLiknandeForpliktelserIngaende: {
      type: 'group',
      active: true,
      level: 0,
      table: table<IxbrlCell>(
        'notes.note71.data.AvsattningarPensionerLiknandeForpliktelserIngaende.table',
        {
          id: 'AvsattningarPensionerLiknandeForpliktelser',
          label: 'Ingående balans',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'AvsattningarPensionerLiknandeForpliktelserIngaende',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'AvsattningarPensionerLiknandeForpliktelserIngaende',
                'row',
                noteLabel('Ingående balans'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelser',
                  standardRubrik:
                    'Avsättningar för pensioner och liknande förpliktelser',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelser',
                  standardRubrik:
                    'Avsättningar för pensioner och liknande förpliktelser',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows
                .addRowWithType(
                  'AvsattningarPensionerLiknandeForpliktelserForandringAbstract',
                  'header',
                  noteLabel(
                    'Förändringar av avsättningar för pensioner och liknande förpliktelser'
                  ),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'AvsattningarPensionerLiknandeForpliktelserForandringAvsattningar',
                    'row',
                    noteLabel('Årets avsättningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringAvsattningar',
                      standardRubrik:
                        'Periodens avsättningar för pensioner och liknande förpliktelser',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringAvsattningar',
                      standardRubrik:
                        'Periodens avsättningar för pensioner och liknande förpliktelser',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AvsattningarPensionerLiknandeForpliktelserForandringJusteringarForandringNuvarde',
                    'row',
                    noteLabel(
                      'Justeringar till följd av förändring av nuvärdet'
                    ),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringJusteringarForandringNuvarde',
                      standardRubrik:
                        'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser till följd av förändring av nuvärdet',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringJusteringarForandringNuvarde',
                      standardRubrik:
                        'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser till följd av förändring av nuvärdet',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AvsattningarPensionerLiknandeForpliktelserOvrigaVardeforandringar',
                    'row',
                    noteLabel('Övriga värdeförändringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserOvrigaVardeforandringar',
                      standardRubrik:
                        'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser till följd av övriga värdeförändringar än förändring av nuvärdet',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserOvrigaVardeforandringar',
                      standardRubrik:
                        'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser till följd av övriga värdeförändringar än förändring av nuvärdet',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AvsattningarPensionerLiknandeForpliktelserForandringRorelseforvarv',
                    'row',
                    noteLabel('Rörelseförvärv'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser i samband med rörelseförvärv',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser i samband med rörelseförvärv',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AvsattningarPensionerLiknandeForpliktelserForandringFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringFusion',
                      standardRubrik:
                        'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser vid fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringFusion',
                      standardRubrik:
                        'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser vid fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AvsattningarPensionerLiknandeForpliktelserForandringOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser i samband med omklassificeringar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser i samband med omklassificeringar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AvsattningarPensionerLiknandeForpliktelserForandringOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser i samband med omräkningsdifferenser',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser i samband med omräkningsdifferenser',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AvsattningarPensionerLiknandeForpliktelserForandringIanspraktagnaBelopp',
                    'row',
                    noteLabel('Under året ianspråktagna belopp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringIanspraktagnaBelopp',
                      standardRubrik:
                        'Under perioden ianspråktagna belopp från avsättningar för pensioner och liknande förpliktelser',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringIanspraktagnaBelopp',
                      standardRubrik:
                        'Under perioden ianspråktagna belopp från avsättningar för pensioner och liknande förpliktelser',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AvsattningarPensionerLiknandeForpliktelserForandringAterfordaBelopp',
                    'row',
                    noteLabel('Under året återförda belopp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringAterfordaBelopp',
                      standardRubrik:
                        'Under perioden återförda belopp från avsättningar för pensioner och liknande förpliktelser',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringAterfordaBelopp',
                      standardRubrik:
                        'Under perioden återförda belopp från avsättningar för pensioner och liknande förpliktelser',
                      negateValue: true,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'AvsattningarPensionerLiknandeForpliktelserUtgaende',
                'sum',
                noteLabel('Utgående balans'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note71.data.AvsattningarPensionerLiknandeForpliktelserIngaende.table.AvsattningarPensionerLiknandeForpliktelserIngaende.AvsattningarPensionerLiknandeForpliktelserIngaende.year'
                      ),
                      id(
                        'notes.note71.data.AvsattningarPensionerLiknandeForpliktelserIngaende.table.AvsattningarPensionerLiknandeForpliktelserIngaende.AvsattningarPensionerLiknandeForpliktelserForandringAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelser',
                    standardRubrik:
                      'Avsättningar för pensioner och liknande förpliktelser',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note71.data.AvsattningarPensionerLiknandeForpliktelserIngaende.table.AvsattningarPensionerLiknandeForpliktelserIngaende.AvsattningarPensionerLiknandeForpliktelserIngaende.previousYear'
                      ),
                      id(
                        'notes.note71.data.AvsattningarPensionerLiknandeForpliktelserIngaende.table.AvsattningarPensionerLiknandeForpliktelserIngaende.AvsattningarPensionerLiknandeForpliktelserForandringAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelser',
                    standardRubrik:
                      'Avsättningar för pensioner och liknande förpliktelser',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              );
              return rows.build();
            });
          return rows.build();
        })
        .build(),
      AvsattningarPensionerLiknandeForpliktelserForandringKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id(
            'notes.note71.data.AvsattningarPensionerLiknandeForpliktelserIngaende.active'
          )
        ),
        title: field(
          'Kommentar till specifikation av förändringar av avsättningar till pensioner och liknande förpliktelser'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringKommentar',
          standardRubrik:
            'Kommentar till specifikation av förändringar av avsättningar till pensioner och liknande förpliktelser',
        }),
      },
    },
    UpplysningPensionsavsattningarnaTryggandelagen: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Upplysning om pensionsavsättningar som omfattas av tryggandelagen'
      ),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:UpplysningPensionsavsattningarnaTryggandelagen',
        standardRubrik:
          'Upplysning om pensionsavsättningar som omfattas av tryggandelagen',
      }),
      PensionsavsattningarnaTryggandelagenAbstract: {
        type: 'group',
        active: true,
        level: 1,
        title: field('Pensionsavsättningar som omfattas av tryggandelagen'),
        table: table<IxbrlCell>(
          'notes.note71.data.UpplysningPensionsavsattningarnaTryggandelagen.PensionsavsattningarnaTryggandelagenAbstract.table',
          {
            id: 'PensionsavsattningarnaTryggandelagenAbstract',
            label: '',
            dataType: 'text',
            active: true,
          },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) ?? '',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) ?? '',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'PensionsavsattningarnaTryggandelagenAbstract',
                'hidden',
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  'AvsattningarPensionerLiknandeForpliktelserEnligtLag',
                  'row',
                  noteLabel('Belopp'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserEnligtLag',
                    standardRubrik:
                      'Avsättningar för pensioner och liknande förpliktelser enligt lagen om tryggande av pensionsutfästelse m.m.',
                    saldo: 'credit',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserEnligtLag',
                    standardRubrik:
                      'Avsättningar för pensioner och liknande förpliktelser enligt lagen om tryggande av pensionsutfästelse m.m.',
                    saldo: 'credit',
                    negateValue: false,
                  })
                );
                return rows.build();
              });
            return rows.build();
          })
          .build(),
      },
      UpplysningPensionsavsattningarnaTryggandelagenKommentar: {
        type: 'group',
        active: true,
        level: 1,
        visible: ref(
          id(
            'notes.note71.data.UpplysningPensionsavsattningarnaTryggandelagen.PensionsavsattningarnaTryggandelagenAbstract.active'
          )
        ),
        title: field(
          'Kommentar till upplysning om pensionsavsättningar som omfattas av tryggandelagen'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:UpplysningPensionsavsattningarnaTryggandelagenKommentar',
          standardRubrik:
            'Kommentar till upplysning om pensionsavsättningar som omfattas av tryggandelagen',
        }),
      },
    },
    SpecifikationFormansbestamdaPlanerRedovisadeEnligtIAS: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Specifikation av förmånsbestämda planer redovisade enligt IAS 19'
      ),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:SpecifikationFormansbestamdaPlanerRedovisadeEnligtIAS',
        standardRubrik:
          'Specifikation av förmånsbestämda planer redovisade enligt IAS 19',
      }),
      AktuariellaAntagandenPensionsplanerEnligtIASAbstract: {
        type: 'group',
        active: true,
        level: 1,
        title: field(
          'Aktuariella antaganden för pensionsplaner redovisade enligt IAS 19'
        ),
        table: table<IxbrlCell>(
          'notes.note71.data.SpecifikationFormansbestamdaPlanerRedovisadeEnligtIAS.AktuariellaAntagandenPensionsplanerEnligtIASAbstract.table',
          {
            id: 'AktuariellaAntagandenPensionsplanerEnligtIASAbstract',
            label: 'Aktuariella antaganden',
            dataType: 'text',
            active: true,
          },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) ?? '',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) ?? '',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'AktuariellaAntagandenPensionsplanerEnligtIASAbstract',
                'hidden',
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  'AktuariellaAntagandenPensionsplanerEnligtIASDiskonteringsranta',
                  'row',
                  noteLabel('Diskonteringsränta'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period0',
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:AktuariellaAntagandenPensionsplanerEnligtIASDiskonteringsranta',
                    standardRubrik:
                      'Diskonteringsränta för pensionsplaner redovisade enligt IAS 19',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period1',
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:AktuariellaAntagandenPensionsplanerEnligtIASDiskonteringsranta',
                    standardRubrik:
                      'Diskonteringsränta för pensionsplaner redovisade enligt IAS 19',
                    negateValue: false,
                  })
                );
                rows.addRowWithType(
                  'AktuariellaAntagandenPensionsplanerEnligtIASForvantadLoneokningstakt',
                  'row',
                  noteLabel('Förväntad löneökningstakt'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period0',
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:AktuariellaAntagandenPensionsplanerEnligtIASForvantadLoneokningstakt',
                    standardRubrik:
                      'Förväntad löneökningstakt för pensionsplaner redovisade enligt IAS 19',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period1',
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:AktuariellaAntagandenPensionsplanerEnligtIASForvantadLoneokningstakt',
                    standardRubrik:
                      'Förväntad löneökningstakt för pensionsplaner redovisade enligt IAS 19',
                    negateValue: false,
                  })
                );
                rows.addRowWithType(
                  'AktuariellaAntagandenPensionsplanerEnligtIASForvantadAvkastningForvaltningstillgangar',
                  'row',
                  noteLabel('Förväntad avkastning på förvaltningstillgångar'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period0',
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:AktuariellaAntagandenPensionsplanerEnligtIASForvantadAvkastningForvaltningstillgangar',
                    standardRubrik:
                      'Förväntad avkastning på förvaltningstillgångar för pensionsplaner redovisade enligt IAS 19',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period1',
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:AktuariellaAntagandenPensionsplanerEnligtIASForvantadAvkastningForvaltningstillgangar',
                    standardRubrik:
                      'Förväntad avkastning på förvaltningstillgångar för pensionsplaner redovisade enligt IAS 19',
                    negateValue: false,
                  })
                );
                rows.addRowWithType(
                  'AktuariellaAntagandenPensionsplanerEnligtIASOvrigaAktuariellaAntagande',
                  'row',
                  noteLabel('Övriga aktuariella antaganden'),
                  ixbrlCell(value(''), {
                    contextRef: 'period0',
                    type: 'stringItemType',
                    name: 'se-gen-base:AktuariellaAntagandenPensionsplanerEnligtIASOvrigaAktuariellaAntagande',
                    standardRubrik:
                      'Övriga aktuariella antaganden för pensionsplaner redovisade enligt IAS 19',
                    negateValue: false,
                  }),
                  ixbrlCell(value(''), {
                    contextRef: 'period1',
                    type: 'stringItemType',
                    name: 'se-gen-base:AktuariellaAntagandenPensionsplanerEnligtIASOvrigaAktuariellaAntagande',
                    standardRubrik:
                      'Övriga aktuariella antaganden för pensionsplaner redovisade enligt IAS 19',
                    negateValue: false,
                  })
                );
                return rows.build();
              });
            return rows.build();
          })
          .build(),
        AktuariellaAntagandenPensionsplanerEnligtIASKommentar: {
          type: 'group',
          active: true,
          level: 1,
          visible: ref(
            id(
              'notes.note71.data.SpecifikationFormansbestamdaPlanerRedovisadeEnligtIAS.AktuariellaAntagandenPensionsplanerEnligtIASAbstract.active'
            )
          ),
          title: field(
            'Kommentar till aktuariella antaganden för pensionsplaner redovisade enligt IAS 19'
          ),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:AktuariellaAntagandenPensionsplanerEnligtIASKommentar',
            standardRubrik:
              'Kommentar till aktuariella antaganden för pensionsplaner redovisade enligt IAS 19',
          }),
        },
      },
      RedovisadPensionskostnadEnligtIASTuple: {
        type: 'group',
        active: true,
        level: 1,
        title: field('Specifikation av pensionskostnader enligt IAS 19'),
        table: table<IxbrlCell>(
          'notes.note71.data.SpecifikationFormansbestamdaPlanerRedovisadeEnligtIAS.RedovisadPensionskostnadEnligtIASTuple.table',
          {
            id: 'RedovisadPensionskostnadEnligtIASPost',
            label: 'Post i resultaträkningen',
            dataType: 'text',
            active: true,
          },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) ?? '',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) ?? '',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'RedovisadPensionskostnadEnligtIASTuple',
                'hidden',
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  '1',
                  'row',
                  ixbrlCell(value('Post i resultaträkningen'), {
                    type: 'stringItemType',
                    name: 'se-gen-base:RedovisadPensionskostnadEnligtIASPost',
                    contextRef: 'period0',
                    standardRubrik:
                      'Post i resultaträkningen innehållande pensionskostnad eller räntekostnad för pensioner avseende planer redovisade enligt IAS 19',
                    negateValue: false,
                    tuple: {
                      name: 'se-gaap-ext:RedovisadPensionskostnadEnligtIASTuple',
                      id: `${rows.getBaseId()}.1.tuple`,
                      tupleID: `${rows.getBaseId()}.1.tuple`,
                    },
                    tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: true,
                  }),
                  ixbrlCell(value(undefined), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:RedovisadPensionskostnadEnligtIAS',
                    contextRef: 'period0',
                    standardRubrik:
                      'Belopp per post i resultaträkningen innehållande pensionskostnad eller räntekostnad för pensioner avseende planer redovisade enligt IAS 19',
                    saldo: 'debit',
                    negateValue: false,
                    tupleRef: tupleRef(
                      2,
                      `${rows.getBaseId()}.1.tuple_period0`
                    ),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(undefined), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:RedovisadPensionskostnadEnligtIAS',
                    contextRef: 'period1',
                    standardRubrik:
                      'Belopp per post i resultaträkningen innehållande pensionskostnad eller räntekostnad för pensioner avseende planer redovisade enligt IAS 19',
                    saldo: 'debit',
                    negateValue: false,
                    tupleRef: tupleRef(
                      3,
                      `${rows.getBaseId()}.1.tuple_period1`
                    ),
                    previousYearTuple: false,
                  })
                );
                return rows.build();
              });
            rows.newRowTemplateGenerator((_id, baseId) => ({
              id: _id,
              active: true,
              type: 'row',
              cells: {
                RedovisadPensionskostnadEnligtIASPost: ixbrlCell(
                  value('Post i resultaträkningen'),
                  {
                    type: 'stringItemType',
                    name: 'se-gen-base:RedovisadPensionskostnadEnligtIASPost',
                    contextRef: 'period0',
                    standardRubrik:
                      'Post i resultaträkningen innehållande pensionskostnad eller räntekostnad för pensioner avseende planer redovisade enligt IAS 19',
                    negateValue: false,
                    tuple: {
                      name: 'se-gaap-ext:RedovisadPensionskostnadEnligtIASTuple',
                      id: `${baseId}.${_id}.tuple`,
                      tupleID: `${baseId}.${_id}.tuple`,
                    },
                    tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                    previousYearTuple: true,
                  }
                ),
                year: ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:RedovisadPensionskostnadEnligtIAS',
                  contextRef: 'period0',
                  standardRubrik:
                    'Belopp per post i resultaträkningen innehållande pensionskostnad eller räntekostnad för pensioner avseende planer redovisade enligt IAS 19',
                  saldo: 'debit',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${baseId}.${_id}.tuple_period0`),
                  previousYearTuple: false,
                }),
                previousYear: ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:RedovisadPensionskostnadEnligtIAS',
                  contextRef: 'period1',
                  standardRubrik:
                    'Belopp per post i resultaträkningen innehållande pensionskostnad eller räntekostnad för pensioner avseende planer redovisade enligt IAS 19',
                  saldo: 'debit',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${baseId}.${_id}.tuple_period1`),
                  previousYearTuple: false,
                }),
              },
            }));
            rows.addRowWithType(
              'RedovisadPensionskostnadErsattningarAvslutadAnstallning',
              'sum',
              noteLabel('Summa'),
              ixbrlCell(
                ref(
                  sumAllowNull(
                    id(
                      'notes.note71.data.SpecifikationFormansbestamdaPlanerRedovisadeEnligtIAS.RedovisadPensionskostnadEnligtIASTuple.table.RedovisadPensionskostnadEnligtIASTuple.*.year'
                    )
                  )
                ),
                {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:RedovisadPensionskostnadErsattningarAvslutadAnstallning',
                  standardRubrik:
                    'Summa pensionskostnader och räntekostnader för pensioner avseende planer redovisade enligt IAS 19',
                  saldo: 'debit',
                  negateValue: false,
                }
              ),
              ixbrlCell(
                ref(
                  sumAllowNull(
                    id(
                      'notes.note71.data.SpecifikationFormansbestamdaPlanerRedovisadeEnligtIAS.RedovisadPensionskostnadEnligtIASTuple.table.RedovisadPensionskostnadEnligtIASTuple.*.previousYear'
                    )
                  )
                ),
                {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:RedovisadPensionskostnadErsattningarAvslutadAnstallning',
                  standardRubrik:
                    'Summa pensionskostnader och räntekostnader för pensioner avseende planer redovisade enligt IAS 19',
                  saldo: 'debit',
                  negateValue: false,
                }
              )
            );
            return rows.build();
          })
          .build(),
        RedovisadPensionskostnadEnligtIASKommentar: {
          type: 'group',
          active: true,
          level: 1,
          visible: ref(
            id(
              'notes.note71.data.SpecifikationFormansbestamdaPlanerRedovisadeEnligtIAS.RedovisadPensionskostnadEnligtIASTuple.active'
            )
          ),
          title: field(
            'Kommentar till specifikation av redovisad pensionskostnad enligt IAS 19'
          ),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:RedovisadPensionskostnadEnligtIASKommentar',
            standardRubrik:
              'Kommentar till specifikation av redovisad pensionskostnad enligt IAS 19',
          }),
        },
      },
      AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIASTuple:
        {
          type: 'group',
          active: true,
          level: 1,
          title: field(
            'Aktuariella intäkter och kostnader samt andra poster som redovisas mot eget kapital'
          ),
          table: table<IxbrlCell>(
            'notes.note71.data.SpecifikationFormansbestamdaPlanerRedovisadeEnligtIAS.AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIASTuple.table',
            {
              id: 'AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIASTyp',
              label: 'Post mot eget kapital',
              dataType: 'text',
              active: true,
            },
            {
              id: 'year',
              label: year(financialYear, financialYears, period) ?? '',
              dataType: 'numeric',
              active: true,
            },
            {
              id: 'previousYear',
              label: previousYear(financialYear, financialYears) ?? '',
              dataType: 'numeric',
              active: true,
            }
          )
            .addRows((rows) => {
              rows
                .addRowWithType(
                  'AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIASTuple',
                  'hidden',
                  undefined,
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    '1',
                    'row',
                    ixbrlCell(value('Post mot eget kapital'), {
                      type: 'stringItemType',
                      name: 'se-gen-base:AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIASTyp',
                      contextRef: 'period0',
                      standardRubrik:
                        'Typ av post som har redovisats mot eget kapital för pensionsplaner redovisade enligt IAS 19',
                      negateValue: false,
                      tuple: {
                        name: 'se-gaap-ext:AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIASTuple',
                        id: `${rows.getBaseId()}.1.tuple`,
                        tupleID: `${rows.getBaseId()}.1.tuple`,
                      },
                      tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                      previousYearTuple: true,
                    }),
                    ixbrlCell(value(undefined), {
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIAS',
                      contextRef: 'period0',
                      standardRubrik:
                        'Aktuariella intäkter eller kostnader per typ av post som har redovisats mot eget kapital för pensionsplaner redovisade enligt IAS 19',
                      saldo: 'credit',
                      negateValue: false,
                      tupleRef: tupleRef(
                        2,
                        `${rows.getBaseId()}.1.tuple_period0`
                      ),
                      previousYearTuple: false,
                    }),
                    ixbrlCell(value(undefined), {
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIAS',
                      contextRef: 'period1',
                      standardRubrik:
                        'Aktuariella intäkter eller kostnader per typ av post som har redovisats mot eget kapital för pensionsplaner redovisade enligt IAS 19',
                      saldo: 'credit',
                      negateValue: false,
                      tupleRef: tupleRef(
                        3,
                        `${rows.getBaseId()}.1.tuple_period1`
                      ),
                      previousYearTuple: false,
                    })
                  );
                  return rows.build();
                });
              rows.newRowTemplateGenerator((_id, baseId) => ({
                id: _id,
                active: true,
                type: 'row',
                cells: {
                  AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIASTyp:
                    ixbrlCell(value('Post mot eget kapital'), {
                      type: 'stringItemType',
                      name: 'se-gen-base:AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIASTyp',
                      contextRef: 'period0',
                      standardRubrik:
                        'Typ av post som har redovisats mot eget kapital för pensionsplaner redovisade enligt IAS 19',
                      negateValue: false,
                      tuple: {
                        name: 'se-gaap-ext:AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIASTuple',
                        id: `${baseId}.${_id}.tuple`,
                        tupleID: `${baseId}.${_id}.tuple`,
                      },
                      tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                      previousYearTuple: true,
                    }),
                  year: ixbrlCell(value(undefined), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIAS',
                    contextRef: 'period0',
                    standardRubrik:
                      'Aktuariella intäkter eller kostnader per typ av post som har redovisats mot eget kapital för pensionsplaner redovisade enligt IAS 19',
                    saldo: 'credit',
                    negateValue: false,
                    tupleRef: tupleRef(2, `${baseId}.${_id}.tuple_period0`),
                    previousYearTuple: false,
                  }),
                  previousYear: ixbrlCell(value(undefined), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIAS',
                    contextRef: 'period1',
                    standardRubrik:
                      'Aktuariella intäkter eller kostnader per typ av post som har redovisats mot eget kapital för pensionsplaner redovisade enligt IAS 19',
                    saldo: 'credit',
                    negateValue: false,
                    tupleRef: tupleRef(3, `${baseId}.${_id}.tuple_period1`),
                    previousYearTuple: false,
                  }),
                },
              }));
              return rows.build();
            })
            .build(),
          AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalKommentar:
            {
              type: 'group',
              active: true,
              level: 1,
              visible: ref(
                id(
                  'notes.note71.data.SpecifikationFormansbestamdaPlanerRedovisadeEnligtIAS.AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIASTuple.active'
                )
              ),
              title: field(
                'Kommentar till aktuariella intäkter och kostnader samt andra poster som redovisas mot eget kapital för pensionsplaner redovisade enligt IAS 19'
              ),
              value: ixbrlField(field(''), {
                contextRef: 'period0',
                type: 'stringItemType',
                name: 'se-gen-base:AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalKommentar',
                standardRubrik:
                  'Kommentar till aktuariella intäkter och kostnader samt andra poster som redovisas mot eget kapital för pensionsplaner redovisade enligt IAS 19',
              }),
            },
        },
      SpecifikationFormansbestamdaPlanerRedovisadeEnligtIASKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till specifikation av förmånsbestämda planer redovisade enligt IAS 19'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:SpecifikationFormansbestamdaPlanerRedovisadeEnligtIASKommentar',
          standardRubrik:
            'Kommentar till specifikation av förmånsbestämda planer redovisade enligt IAS 19',
        }),
      },
      NotAvsattningarPensionerLiknandeForpliktelserKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till specifikation av avsättningar för pensioner och liknande förpliktelser'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotAvsattningarPensionerLiknandeForpliktelserKommentar',
          standardRubrik:
            'Kommentar till specifikation av avsättningar för pensioner och liknande förpliktelser',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2071K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        AvsattningarPensionerLiknandeForpliktelserIngaende: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            table: { type: 'table' as const },

            AvsattningarPensionerLiknandeForpliktelserForandringKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        UpplysningPensionsavsattningarnaTryggandelagen: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            value: cell,

            PensionsavsattningarnaTryggandelagenAbstract: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                table: { type: 'table' as const },
              },
            },

            UpplysningPensionsavsattningarnaTryggandelagenKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        SpecifikationFormansbestamdaPlanerRedovisadeEnligtIAS: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            value: cell,

            AktuariellaAntagandenPensionsplanerEnligtIASAbstract: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                table: { type: 'table' as const },

                AktuariellaAntagandenPensionsplanerEnligtIASKommentar: {
                  type: 'part' as const,
                  children: {
                    type: type,
                    level: number,
                    active: boolean,
                    visible: cell,

                    title: cell,

                    value: cell,
                  },
                },
              },
            },

            RedovisadPensionskostnadEnligtIASTuple: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                table: { type: 'table' as const },

                RedovisadPensionskostnadEnligtIASKommentar: {
                  type: 'part' as const,
                  children: {
                    type: type,
                    level: number,
                    active: boolean,
                    visible: cell,

                    title: cell,

                    value: cell,
                  },
                },
              },
            },

            AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIASTuple:
              {
                type: 'part' as const,
                children: {
                  type: type,
                  level: number,
                  active: boolean,

                  title: cell,

                  table: { type: 'table' as const },

                  AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalKommentar:
                    {
                      type: 'part' as const,
                      children: {
                        type: type,
                        level: number,
                        active: boolean,
                        visible: cell,

                        title: cell,

                        value: cell,
                      },
                    },
                },
              },

            SpecifikationFormansbestamdaPlanerRedovisadeEnligtIASKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },

            NotAvsattningarPensionerLiknandeForpliktelserKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
