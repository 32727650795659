import { FeatureIdType } from '@agoy/common';
import { useSelector } from '../../redux/reducers';
import { FeatureSwitchType } from '../types';

/**
 * Verify if the provided feature(s) as `name` are included in the `featureSwitch`.
 */
export const hasFeature = (
  name: FeatureIdType | FeatureIdType[],
  featureSwitch: FeatureSwitchType = []
): boolean =>
  Array.isArray(name)
    ? name.every((n) => featureSwitch.includes(n))
    : featureSwitch.includes(name);

/**
 * Checks if the provided feature (`featureName`) is within the `state.featureSwitch` redux state.
 */
export const useHasFeature = (
  featureName: FeatureIdType | FeatureIdType[]
): boolean => {
  const featureSwitch = useSelector((state) => state.featureSwitch);

  return hasFeature(featureName, featureSwitch);
};
