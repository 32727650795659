const countries = [
  { code: 'AF', country: 'Afghanistan', countryEn: 'Afghanistan' },
  { code: 'AL', country: 'Albanien', countryEn: 'Albania' },
  { code: 'DZ', country: 'Algeriet', countryEn: 'Algeria' },
  {
    code: 'VI',
    country: 'Amerikanska Jungfruöarna',
    countryEn: 'Virgin Islands, U.S.',
  },
  { code: 'AS', country: 'Amerikanska Samoa', countryEn: 'American Samoa' },
  { code: 'AD', country: 'Andorra', countryEn: 'Andorra' },
  { code: 'AO', country: 'Angola', countryEn: 'Angola' },
  { code: 'AI', country: 'Anguilla', countryEn: 'Anguilla' },
  { code: 'AQ', country: 'Antarktis', countryEn: 'Antarctica' },
  {
    code: 'AG',
    country: 'Antigua och Barbuda',
    countryEn: 'Antigua and Barbuda',
  },
  { code: 'AR', country: 'Argentina', countryEn: 'Argentina' },
  { code: 'AM', country: 'Armenien', countryEn: 'Armenia' },
  { code: 'AW', country: 'Aruba', countryEn: 'Aruba' },
  { code: 'AU', country: 'Australien', countryEn: 'Australia' },
  { code: 'AZ', country: 'Azerbajdzjan', countryEn: 'Azerbaijan' },
  { code: 'BS', country: 'Bahamas', countryEn: 'Bahamas' },
  { code: 'BH', country: 'Bahrain', countryEn: 'Bahrain' },
  { code: 'BD', country: 'Bangladesh', countryEn: 'Bangladesh' },
  { code: 'BB', country: 'Barbados', countryEn: 'Barbados' },
  { code: 'BE', country: 'Belgien', countryEn: 'Belgium' },
  { code: 'BZ', country: 'Belize', countryEn: 'Belize' },
  { code: 'BJ', country: 'Benin', countryEn: 'Benin' },
  { code: 'BM', country: 'Bermuda', countryEn: 'Bermuda' },
  { code: 'BT', country: 'Bhutan', countryEn: 'Bhutan' },
  {
    code: 'BO',
    country: 'Bolivia',
    countryEn: 'Bolivia, Plurinational State of',
  },
  {
    code: 'BA',
    country: 'Bosnien och Hercegovina',
    countryEn: 'Bosnia and Herzegovina',
  },
  { code: 'BW', country: 'Botswana', countryEn: 'Botswana' },
  { code: 'BV', country: 'Bouvetön', countryEn: 'Bouvet Island' },
  { code: 'BR', country: 'Brasilien', countryEn: 'Brazil' },
  {
    code: 'VG',
    country: 'Brittiska Jungfruöarna',
    countryEn: 'Virgin Islands, British',
  },
  {
    code: 'IO',
    country: 'Brittiska territoriet i Indiska Oceanen',
    countryEn: 'British Indian Ocean Territory',
  },
  { code: 'BN', country: 'Brunei', countryEn: 'Brunei Darussalam' },
  { code: 'BG', country: 'Bulgarien', countryEn: 'Bulgaria' },
  { code: 'BF', country: 'Burkina Faso', countryEn: 'Burkina Faso' },
  { code: 'MM', country: 'Burma', countryEn: 'Myanmar' },
  { code: 'BI', country: 'Burundi', countryEn: 'Burundi' },
  { code: 'KY', country: 'Caymanöarna', countryEn: 'Cayman Islands' },
  {
    code: 'CF',
    country: 'Centralafrikanska republiken',
    countryEn: 'Central African Republic',
  },
  { code: 'CL', country: 'Chile', countryEn: 'Chile' },
  { code: 'CO', country: 'Colombia', countryEn: 'Colombia' },
  { code: 'CK', country: 'Cooköarna', countryEn: 'Cook Islands' },
  { code: 'CR', country: 'Costa Rica', countryEn: 'Costa Rica' },
  { code: 'CY', country: 'Cypern', countryEn: 'Cyprus' },
  { code: 'DK', country: 'Danmark', countryEn: 'Denmark' },
  {
    code: 'CD',
    country: 'Demokratiska republiken Kongo',
    countryEn: 'Congo, the Democratic Republic of the',
  },
  { code: 'DJ', country: 'Djibouti', countryEn: 'Djibouti' },
  { code: 'DM', country: 'Dominica', countryEn: 'Dominica' },
  {
    code: 'DO',
    country: 'Dominikanska republiken',
    countryEn: 'Dominican Republic',
  },
  { code: 'EC', country: 'Ecuador', countryEn: 'Ecuador' },
  { code: 'EG', country: 'Egypten', countryEn: 'Egypt' },
  { code: 'GQ', country: 'Ekvatorialguinea', countryEn: 'Equatorial Guinea' },
  { code: 'SV', country: 'El Salvador', countryEn: 'El Salvador' },
  { code: 'CI', country: 'Elfenbenskusten', countryEn: "Côte d'Ivoire" },
  { code: 'ER', country: 'Eritrea', countryEn: 'Eritrea' },
  { code: 'EE', country: 'Estland', countryEn: 'Estonia' },
  { code: 'ET', country: 'Etiopien', countryEn: 'Ethiopia' },
  {
    code: 'FK',
    country: 'Falklandsöarna',
    countryEn: 'Falkland Islands (Malvinas)',
  },
  { code: 'FJ', country: 'Fiji', countryEn: 'Fiji' },
  { code: 'PH', country: 'Filippinerna', countryEn: 'Philippines' },
  { code: 'FI', country: 'Finland', countryEn: 'Finland' },
  {
    code: 'FX',
    country: 'France métropolitaine (Frankrike, europeiska delen)',
    countryEn: 'France, Metropolitan',
  },
  { code: 'FR', country: 'Frankrike', countryEn: 'France' },
  { code: 'GF', country: 'Franska Guyana', countryEn: 'French Guiana' },
  {
    code: 'PF',
    country: 'Franska Polynesien',
    countryEn: 'French Polynesia',
  },
  {
    code: 'TF',
    country: 'Franska södra territorierna',
    countryEn: 'French Southern Territories',
  },
  { code: 'FO', country: 'Färöarna', countryEn: 'Faroe Islands' },
  {
    code: 'AE',
    country: 'Förenade Arabemiraten',
    countryEn: 'United Arab Emirates',
  },
  { code: 'GA', country: 'Gabon', countryEn: 'Gabon' },
  { code: 'GM', country: 'Gambia', countryEn: 'Gambia' },
  { code: 'GE', country: 'Georgien', countryEn: 'Georgia' },
  { code: 'GH', country: 'Ghana', countryEn: 'Ghana' },
  { code: 'GI', country: 'Gibraltar', countryEn: 'Gibraltar' },
  { code: 'GR', country: 'Grekland', countryEn: 'Greece' },
  { code: 'GD', country: 'Grenada', countryEn: 'Grenada' },
  { code: 'GL', country: 'Grönland', countryEn: 'Greenland' },
  { code: 'GP', country: 'Guadeloupe', countryEn: 'Guadeloupe' },
  { code: 'GU', country: 'Guam', countryEn: 'Guam' },
  { code: 'GT', country: 'Guatemala', countryEn: 'Guatemala' },
  { code: 'GG', country: 'Guernsey', countryEn: 'Guernsey' },
  { code: 'GN', country: 'Guinea', countryEn: 'Guinea' },
  { code: 'GW', country: 'Guinea Bissau', countryEn: 'Guinea-Bissau' },
  { code: 'GY', country: 'Guyana', countryEn: 'Guyana' },
  { code: 'HT', country: 'Haiti', countryEn: 'Haiti' },
  {
    code: 'HM',
    country: 'Heard- och McDonaldsöarna',
    countryEn: 'Heard Island and McDonald Islands',
  },
  { code: 'HN', country: 'Honduras', countryEn: 'Honduras' },
  { code: 'HK', country: 'Hongkong', countryEn: 'Hong Kong' },
  { code: 'IN', country: 'Indien', countryEn: 'India' },
  { code: 'ID', country: 'Indonesien', countryEn: 'Indonesia' },
  { code: 'IQ', country: 'Irak', countryEn: 'Iraq' },
  { code: 'IR', country: 'Iran', countryEn: 'Iran, Islamic Republic of' },
  { code: 'IE', country: 'Irland', countryEn: 'Ireland' },
  { code: 'IS', country: 'Island', countryEn: 'Iceland' },
  { code: 'IM', country: 'Isle of Man', countryEn: 'Isle of Man' },
  { code: 'IL', country: 'Israel', countryEn: 'Israel' },
  { code: 'IT', country: 'Italien', countryEn: 'Italy' },
  { code: 'JM', country: 'Jamaica', countryEn: 'Jamaica' },
  { code: 'JP', country: 'Japan', countryEn: 'Japan' },
  { code: 'YE', country: 'Jemen', countryEn: 'Yemen' },
  { code: 'JE', country: 'Jersey', countryEn: 'Jersey' },
  { code: 'JO', country: 'Jordanien', countryEn: 'Jordan' },
  { code: 'CX', country: 'Julön', countryEn: 'Christmas Island' },
  { code: 'KH', country: 'Kambodja', countryEn: 'Cambodia' },
  { code: 'CM', country: 'Kamerun', countryEn: 'Cameroon' },
  { code: 'CA', country: 'Kanada', countryEn: 'Canada' },
  { code: 'CV', country: 'Kap Verde', countryEn: 'Cape Verde' },
  { code: 'KZ', country: 'Kazakstan', countryEn: 'Kazakhstan' },
  { code: 'KE', country: 'Kenya', countryEn: 'Kenya' },
  { code: 'CN', country: 'Kina', countryEn: 'China' },
  { code: 'KG', country: 'Kirgizistan', countryEn: 'Kyrgyzstan' },
  { code: 'KI', country: 'Kiribati', countryEn: 'Kiribati' },
  { code: 'CC', country: 'Kokosöarna', countryEn: 'Cocos (Keeling) Islands' },
  { code: 'KM', country: 'Komorerna', countryEn: 'Comoros' },
  { code: 'CG', country: 'Kongo-Brazzaville', countryEn: 'Congo' },
  { code: 'HR', country: 'Kroatien', countryEn: 'Croatia' },
  { code: 'CU', country: 'Kuba', countryEn: 'Cuba' },
  { code: 'KW', country: 'Kuwait', countryEn: 'Kuwait' },
  {
    code: 'LA',
    country: 'Laos',
    countryEn: "Lao People's Democratic Republic",
  },
  { code: 'LS', country: 'Lesotho', countryEn: 'Lesotho' },
  { code: 'LV', country: 'Lettland', countryEn: 'Latvia' },
  { code: 'LB', country: 'Libanon', countryEn: 'Lebanon' },
  { code: 'LR', country: 'Liberia', countryEn: 'Liberia' },
  { code: 'LY', country: 'Libyen', countryEn: 'Libya' },
  { code: 'LI', country: 'Liechtenstein', countryEn: 'Liechtenstein' },
  { code: 'LT', country: 'Litauen', countryEn: 'Lithuania' },
  { code: 'LU', country: 'Luxemburg', countryEn: 'Luxembourg' },
  { code: 'MO', country: 'Macau', countryEn: 'Macao' },
  { code: 'MG', country: 'Madagaskar', countryEn: 'Madagascar' },
  {
    code: 'MK',
    country: 'Makedonien',
    countryEn: 'Macedonia, the former Yugoslav Republic of',
  },
  { code: 'MW', country: 'Malawi', countryEn: 'Malawi' },
  { code: 'MY', country: 'Malaysia', countryEn: 'Malaysia' },
  { code: 'MV', country: 'Maldiverna', countryEn: 'Maldives' },
  { code: 'ML', country: 'Mali', countryEn: 'Mali' },
  { code: 'MT', country: 'Malta', countryEn: 'Malta' },
  { code: 'MA', country: 'Marocko', countryEn: 'Morocco' },
  { code: 'MH', country: 'Marshallöarna', countryEn: 'Marshall Islands' },
  { code: 'MQ', country: 'Martinique', countryEn: 'Martinique' },
  { code: 'MR', country: 'Mauretanien', countryEn: 'Mauritania' },
  { code: 'MU', country: 'Mauritius', countryEn: 'Mauritius' },
  { code: 'YT', country: 'Mayotte', countryEn: 'Mayotte' },
  { code: 'MX', country: 'Mexiko', countryEn: 'Mexico' },
  {
    code: 'FM',
    country: 'Mikronesiska federationen',
    countryEn: 'Micronesia, Federated States of',
  },
  { code: 'MZ', country: 'Moçambique', countryEn: 'Mozambique' },
  { code: 'MD', country: 'Moldavien', countryEn: 'Moldova, Republic of' },
  { code: 'MC', country: 'Monaco', countryEn: 'Monaco' },
  { code: 'MN', country: 'Mongoliet', countryEn: 'Mongolia' },
  { code: 'ME', country: 'Montenegro', countryEn: 'Montenegro' },
  { code: 'MS', country: 'Montserrat', countryEn: 'Montserrat' },
  { code: 'NA', country: 'Namibia', countryEn: 'Namibia' },
  { code: 'NR', country: 'Nauru', countryEn: 'Nauru' },
  { code: 'NL', country: 'Nederländerna', countryEn: 'Netherlands' },
  {
    code: 'AN',
    country: 'Nederländska Antillerna',
    countryEn: 'Netherlands Antilles',
  },
  { code: 'NP', country: 'Nepal', countryEn: 'Nepal' },
  { code: 'NI', country: 'Nicaragua', countryEn: 'Nicaragua' },
  { code: 'NE', country: 'Niger', countryEn: 'Niger' },
  { code: 'NG', country: 'Nigeria', countryEn: 'Nigeria' },
  { code: 'NU', country: 'Niue', countryEn: 'Niue' },
  {
    code: 'KP',
    country: 'Nordkorea',
    countryEn: "Korea, Democratic People's Republic of",
  },
  {
    code: 'MP',
    country: 'Nordmarianerna',
    countryEn: 'Northern Mariana Islands',
  },
  { code: 'NF', country: 'Norfolkön', countryEn: 'Norfolk Island' },
  { code: 'NO', country: 'Norge', countryEn: 'Norway' },
  { code: 'NC', country: 'Nya Kaledonien', countryEn: 'New Caledonia' },
  { code: 'NZ', country: 'Nya Zeeland', countryEn: 'New Zealand' },
  { code: 'OM', country: 'Oman', countryEn: 'Oman' },
  { code: 'PK', country: 'Pakistan', countryEn: 'Pakistan' },
  { code: 'PW', country: 'Palau', countryEn: 'Palau' },
  { code: 'PA', country: 'Panama', countryEn: 'Panama' },
  { code: 'PG', country: 'Papua Nya Guinea', countryEn: 'Papua New Guinea' },
  { code: 'PY', country: 'Paraguay', countryEn: 'Paraguay' },
  { code: 'PE', country: 'Peru', countryEn: 'Peru' },
  { code: 'PN', country: 'Pitcairnöarna', countryEn: 'Pitcairn' },
  { code: 'PL', country: 'Polen', countryEn: 'Poland' },
  { code: 'PT', country: 'Portugal', countryEn: 'Portugal' },
  { code: 'PR', country: 'Puerto Rico', countryEn: 'Puerto Rico' },
  { code: 'QA', country: 'Qatar', countryEn: 'Qatar' },
  { code: 'RE', country: 'Réunion', countryEn: 'Réunion' },
  { code: 'RO', country: 'Rumänien', countryEn: 'Romania' },
  { code: 'RW', country: 'Rwanda', countryEn: 'Rwanda' },
  { code: 'RU', country: 'Ryssland', countryEn: 'Russian Federation' },
  {
    code: 'KN',
    country: 'Saint Kitts och Nevis',
    countryEn: 'Saint Kitts and Nevis',
  },
  { code: 'LC', country: 'Saint Lucia', countryEn: 'Saint Lucia' },
  {
    code: 'VC',
    country: 'Saint Vincent och Grenadinerna',
    countryEn: 'Saint Vincent and the Grenadines',
  },
  { code: 'BL', country: 'Saint-Barthélemy', countryEn: 'Saint Barthélemy' },
  {
    code: 'PM',
    country: 'Saint-Pierre och Miquelon',
    countryEn: 'Saint Pierre and Miquelon',
  },
  { code: 'SB', country: 'Salomonöarna', countryEn: 'Solomon Islands' },
  { code: 'WS', country: 'Samoa', countryEn: 'Samoa' },
  { code: 'SM', country: 'San Marino', countryEn: 'San Marino' },
  {
    code: 'SH',
    country: 'Sankta Helena',
    countryEn: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  {
    code: 'ST',
    country: 'São Tomé och Príncipe',
    countryEn: 'Sao Tome and Principe',
  },
  { code: 'SA', country: 'Saudiarabien', countryEn: 'Saudi Arabia' },
  { code: 'CH', country: 'Schweiz', countryEn: 'Switzerland' },
  { code: 'SN', country: 'Senegal', countryEn: 'Senegal' },
  { code: 'RS', country: 'Serbien', countryEn: 'Serbia' },
  { code: 'SC', country: 'Seychellerna', countryEn: 'Seychelles' },
  { code: 'SL', country: 'Sierra Leone', countryEn: 'Sierra Leone' },
  { code: 'SG', country: 'Singapore', countryEn: 'Singapore' },
  { code: 'SK', country: 'Slovakien', countryEn: 'Slovakia' },
  { code: 'SI', country: 'Slovenien', countryEn: 'Slovenia' },
  { code: 'SO', country: 'Somalia', countryEn: 'Somalia' },
  { code: 'ES', country: 'Spanien', countryEn: 'Spain' },
  { code: 'LK', country: 'Sri Lanka', countryEn: 'Sri Lanka' },
  { code: 'GB', country: 'Storbritannien', countryEn: 'United Kingdom' },
  { code: 'SD', country: 'Sudan', countryEn: 'Sudan' },
  { code: 'SR', country: 'Surinam', countryEn: 'Suriname' },
  {
    code: 'SJ',
    country: 'Svalbard och Jan Mayen',
    countryEn: 'Svalbard and Jan Mayen',
  },
  { code: 'SE', country: 'Sverige', countryEn: 'Sverige' },
  { code: 'SZ', country: 'Swaziland', countryEn: 'Swaziland' },
  { code: 'ZA', country: 'Sydafrika', countryEn: 'South Africa' },
  { code: 'KR', country: 'Sydkorea', countryEn: 'Korea, Republic of' },
  { code: 'SY', country: 'Syrien', countryEn: 'Syrian Arab Republic' },
  { code: 'TJ', country: 'Tadzjikistan', countryEn: 'Tajikistan' },
  { code: 'TW', country: 'Taiwan', countryEn: 'Taiwan, Republic of China' },
  {
    code: 'TZ',
    country: 'Tanzania',
    countryEn: 'Tanzania, United Republic of',
  },
  { code: 'TD', country: 'Tchad', countryEn: 'Chad' },
  { code: 'TH', country: 'Thailand', countryEn: 'Thailand' },
  { code: 'CZ', country: 'Tjeckien', countryEn: 'Czech Republic' },
  { code: 'TG', country: 'Togo', countryEn: 'Togo' },
  { code: 'TK', country: 'Tokelauöarna', countryEn: 'Tokelau' },
  { code: 'TO', country: 'Tonga', countryEn: 'Tonga' },
  {
    code: 'TT',
    country: 'Trinidad och Tobago',
    countryEn: 'Trinidad and Tobago',
  },
  { code: 'TN', country: 'Tunisien', countryEn: 'Tunisia' },
  { code: 'TR', country: 'Turkiet', countryEn: 'Turkey' },
  { code: 'TM', country: 'Turkmenistan', countryEn: 'Turkmenistan' },
  {
    code: 'TC',
    country: 'Turks- och Caicosöarna',
    countryEn: 'Turks and Caicos Islands',
  },
  { code: 'TV', country: 'Tuvalu', countryEn: 'Tuvalu' },
  { code: 'DE', country: 'Tyskland', countryEn: 'Germany' },
  { code: 'UG', country: 'Uganda', countryEn: 'Uganda' },
  { code: 'UA', country: 'Ukraina', countryEn: 'Ukraine' },
  { code: 'HU', country: 'Ungern', countryEn: 'Hungary' },
  { code: 'UY', country: 'Uruguay', countryEn: 'Uruguay' },
  { code: 'US', country: 'USA', countryEn: 'United States' },
  {
    code: 'UM',
    country: 'USA:s yttre öar',
    countryEn: 'United States Minor Outlying Islands',
  },
  { code: 'UZ', country: 'Uzbekistan', countryEn: 'Uzbekistan' },
  { code: 'VU', country: 'Vanuatu', countryEn: 'Vanuatu' },
  {
    code: 'VA',
    country: 'Vatikanstaten',
    countryEn: 'Holy See (Vatican City State)',
  },
  {
    code: 'VE',
    country: 'Venezuela',
    countryEn: 'Venezuela, Bolivarian Republic of',
  },
  { code: 'VN', country: 'Vietnam', countryEn: 'Viet Nam' },
  { code: 'BY', country: 'Vitryssland', countryEn: 'Belarus' },
  { code: 'EH', country: 'Västsahara', countryEn: 'Western Sahara' },
  {
    code: 'WF',
    country: 'Wallis- och Futunaöarna',
    countryEn: 'Wallis and Futuna',
  },
  { code: 'ZM', country: 'Zambia', countryEn: 'Zambia' },
  { code: 'ZW', country: 'Zimbabwe', countryEn: 'Zimbabwe' },
  { code: 'AX', country: 'Åland', countryEn: 'Åland Islands' },
  { code: 'AT', country: 'Österrike', countryEn: 'Austria' },
  { code: 'TL', country: 'Östtimor', countryEn: 'Timor-Leste' },
  { code: 'XK', country: 'Kosovo', countryEn: 'Kosovo' },
  { code: 'CW', country: 'Curaçao', countryEn: 'Curaçao' },
];

export default countries;
