import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
  CompanyMetaData,
} from '../../types';
import { ref, value, id } from '@agoy/document';
import { field, mapFormId, toInitial, toStructure } from '../forms-util';

const configORV = (
  form: TaxDeclarationFormDefinition,
  metadata: PersonMetaData | CompanyMetaData,
  financialYearStart: string,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const Fritextbrev = mapFormId(form, 'OVR');

  const orgOrpersNr = (metadata: PersonMetaData | CompanyMetaData): string => {
    if ('orgNumber' in metadata) return metadata.orgNumber;
    if ('personNumber' in metadata) return metadata.personNumber;
    return metadata;
  };

  const orgName = (metadata: PersonMetaData | CompanyMetaData) => {
    if ('orgNumber' in metadata) return metadata.name;
  };

  const initial = {
    OVR: {
      partType: 'form' as const,
      id: Fritextbrev,
      fields: {
        rakenskapsar_fr_o_med: value(financialYearStart || ''),
        rakenskapsar_t_o_med: value(financialYearEnd || ''),
        foretagets_namn: value(orgName(metadata) || ''),
        organisationsnummer: value(orgOrpersNr(metadata) || ''),
        meddelande: field('', { multiline: true }),
      },
      sru: {
        '99': ref(id('OVR.fields.meddelande')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configORV;
