import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Popover } from '@material-ui/core';
import { useIntl } from 'react-intl';

import {
  SpecificationColumnType,
  SpecificationRowType,
} from '_clients/types/types';
import { ccyFormat, getClasses } from '@agoy/common';
import Typography from '_shared/components/Typography/Typography';
import { purple } from '@agoy/theme/src/colors';
import { getColumnsSum } from '../utils';

type SumRowProps = {
  columns: SpecificationColumnType[];
  rows: SpecificationRowType[];
  actualBalanceColumnId: number | null | undefined;
  periodLocked: boolean;
  onSelectSaldo: (columnId: number) => void;
};

const Td = styled.td`
  position: relative;

  &.isOpen,
  &.isNumeral:hover {
    &:not(.periodLocked) {
      cursor: pointer;
      background-color: ${purple.LOW};
    }
  }
`;

const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

const Item = styled.div`
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: 8px 16px;
`;

const SumRow = ({
  columns,
  rows,
  actualBalanceColumnId,
  periodLocked,
  onSelectSaldo,
}: SumRowProps) => {
  const { formatMessage } = useIntl();

  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLTableCellElement | null>(null);

  const columnsSum = useMemo(
    () => getColumnsSum(rows, columns),
    [columns, rows]
  );

  useEffect(() => {
    if (!isOpen) {
      // set null with delay because of animation, to avoid flickering
      setTimeout(() => setAnchorEl(null), 250);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = (event: React.MouseEvent<HTMLTableCellElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleSelectSaldo = (id: number) => {
    onSelectSaldo(id);
    handleClose();
  };

  return (
    <tr>
      <td />
      {!periodLocked && <td />}
      {columns.map((col) => {
        const isNumeral = col.contentType === 'AMOUNT';

        return (
          <Td
            key={col.id}
            id={col.id.toString()}
            onClick={isNumeral && !periodLocked ? handleOpen : undefined}
            className={getClasses({
              isOpen: isOpen && anchorEl?.id === col.id.toString(),
              periodLocked,
              isNumeral,
            })}
          >
            <Typography
              margin="none"
              textStyle={actualBalanceColumnId === col.id ? 'bold' : 'regular'}
            >
              {typeof columnsSum[col.id] === 'number'
                ? `= ${ccyFormat(columnsSum[col.id], 2)}`
                : ''}
            </Typography>
          </Td>
        );
      })}
      {!periodLocked && <td />}

      <StyledPopover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { width: anchorEl?.clientWidth ?? 0 },
        }}
      >
        <Item onClick={() => handleSelectSaldo(Number(anchorEl?.id))}>
          <Typography margin="none">
            {formatMessage({
              id: 'hidden.specification.selectSaldoColumn',
            })}
          </Typography>
        </Item>
      </StyledPopover>
    </tr>
  );
};

export default SumRow;
