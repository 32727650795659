/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6898
 */

import {
  value,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2098K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2098K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Rättelse av fel'),
  data: {
    active: true,
    SpecifikationRattelseFelTuple: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Specifikation av rättelse av fel'),
      table: table<IxbrlCell>(
        'notes.note98.data.SpecifikationRattelseFelTuple.table',
        {
          id: 'RattelseFelKaraktar',
          label: 'Karaktären på felet',
          dataType: 'text',
          active: true,
        },
        {
          id: 'RattelseFelPostTyp',
          label: 'Typ av rättelsepost',
          dataType: 'text',
          active: true,
        },
        {
          id: 'RattelsebeloppResultatrakning',
          label: 'Rättelsebelopp resultaträkning',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'RattelsebeloppBalansrakning',
          label: 'Rättelsebelopp balansräkning',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'RattelsebeloppIngaendeBalans',
          label: 'Rättelsebelopp ingående balans',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'SpecifikationRattelseFelTuple',
              'hidden',
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Karaktären på felet'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:RattelseFelKaraktar',
                  contextRef: 'period0',
                  standardRubrik: 'Karaktären på felet som rättats',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:SpecifikationRattelseFelTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value('Typ av rättelsepost'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:RattelseFelPostTyp',
                  contextRef: 'period0',
                  standardRubrik: 'Typ av post vid rättelse av fel',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:RattelsebeloppResultatrakning',
                  contextRef: 'period0',
                  standardRubrik:
                    'Positivt (negativt) rättelsebelopp per rättad post i resultaträkningen',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:RattelsebeloppBalansrakning',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Positivt (negativt) rättelsebelopp per rättad post i balansräkningen',
                  negateValue: false,
                  tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:RattelsebeloppIngaendeBalans',
                  contextRef: 'period0',
                  standardRubrik:
                    'Positivt (negativt) rättelsebelopp per rättad post i ingående balans',
                  negateValue: false,
                  tupleRef: tupleRef(5, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              RattelseFelKaraktar: ixbrlCell(value('Karaktären på felet'), {
                type: 'stringItemType',
                name: 'se-gen-base:RattelseFelKaraktar',
                contextRef: 'period0',
                standardRubrik: 'Karaktären på felet som rättats',
                negateValue: false,
                tuple: {
                  name: 'se-gaap-ext:SpecifikationRattelseFelTuple',
                  id: `${baseId}.${_id}.tuple`,
                  tupleID: `${baseId}.${_id}.tuple`,
                },
                tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                previousYearTuple: false,
              }),
              RattelseFelPostTyp: ixbrlCell(value('Typ av rättelsepost'), {
                type: 'stringItemType',
                name: 'se-gen-base:RattelseFelPostTyp',
                contextRef: 'period0',
                standardRubrik: 'Typ av post vid rättelse av fel',
                negateValue: false,
                tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                previousYearTuple: false,
              }),
              RattelsebeloppResultatrakning: ixbrlCell(value(), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:RattelsebeloppResultatrakning',
                contextRef: 'period0',
                standardRubrik:
                  'Positivt (negativt) rättelsebelopp per rättad post i resultaträkningen',
                negateValue: false,
                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                previousYearTuple: false,
              }),
              RattelsebeloppBalansrakning: ixbrlCell(value(), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:RattelsebeloppBalansrakning',
                contextRef: 'balans0',
                standardRubrik:
                  'Positivt (negativt) rättelsebelopp per rättad post i balansräkningen',
                negateValue: false,
                tupleRef: tupleRef(4, `${baseId}.${_id}.tuple`),
                previousYearTuple: false,
              }),
              RattelsebeloppIngaendeBalans: ixbrlCell(value(), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:RattelsebeloppIngaendeBalans',
                contextRef: 'period0',
                standardRubrik:
                  'Positivt (negativt) rättelsebelopp per rättad post i ingående balans',
                negateValue: false,
                tupleRef: tupleRef(5, `${baseId}.${_id}.tuple`),
                previousYearTuple: false,
              }),
            },
          }));
          return rows.build();
        })
        .build(),
      NotRattelseFelKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field('Kommentar till specifikation av rättelse av fel'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotRattelseFelKommentar',
          standardRubrik: 'Kommentar till specifikation av rättelse av fel',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2098K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        SpecifikationRattelseFelTuple: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotRattelseFelKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
