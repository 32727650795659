import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { CreateOutlined } from '@material-ui/icons';

import {
  SpecificationColumnType,
  SpecificationRowType,
} from '_clients/types/types';
import Typography from '_shared/components/Typography/Typography';
import Checkbox from '_shared/components/Controls/Checkbox';
import Cell from './Cell';
import RowOptions from './RowOptions';
import DraggableRow from './DraggableRow';
import ColumnOptions from './ColumnOptions';
import AddNewRow from './AddNewRow';
import SumRow from './SumRow';
import { getClasses } from '@agoy/common';

const StyledTable = styled.table`
  // we can't use border: collapse; because of sticky columns :(
  // so have to add borders manually
  border-spacing: 0;
  table-layout: fixed;
  width: max-content;

  th,
  td {
    text-align: start;
    border-right: 1px solid #ccc;
    padding: 8px;
    height: 40px;
    background-color: white;
  }

  td {
    border-top: 1px solid #ccc;
  }

  th {
    p {
      white-space: nowrap;
    }
  }

  tr {
    th:first-of-type,
    td:first-of-type {
      width: 28px;
      position: sticky;
      left: 0;
      text-align: center;
      z-index: 2;
    }

    td:last-of-type,
    th:last-of-type {
      border-right: none;
    }
  }

  &:not(.periodLocked) {
    tr {
      th:nth-of-type(2),
      td:nth-of-type(2) {
        position: sticky;
        left: 28px;
        z-index: 2;
      }

      th:nth-last-of-type(2),
      td:nth-last-of-type(2) {
        border-right: none;
      }

      td:last-of-type,
      th:last-of-type {
        position: sticky;
        right: 0;
        border-left: 1px solid #ccc;
      }
    }
  }
`;

const StickyHeader = styled.thead`
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 3;
`;

const Button = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const CreateIcon = styled(CreateOutlined)`
  width: 20px;
  height: 20px;
`;

const ColumnHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
`;

type DraggableTableProps = {
  columns: SpecificationColumnType[];
  rows: SpecificationRowType[];
  selectedRows: number[];
  sortColumnId: number | null | undefined;
  actualBalanceColumnId: number | null | undefined;
  periodLocked: boolean;
  onUpdateCell: (
    value: string | number | null,
    row: SpecificationRowType,
    columnId: number
  ) => void;
  onNewRow: () => void;
  onSelectRow: (rowIds: number[]) => void;
  onMoveRow: (fromIndex: number, toIndex: number) => void;
  onSortColumn: (
    columnId: number,
    sortOrder: 'ASC' | 'DESC' | null,
    reset: boolean
  ) => void;
  onMoveColumn: (fromIndex: number, toIndex: number) => void;
  onDeleteRows: (rowId: number[]) => void;
  onHover: (offsetTop?: number) => void;
  onOpenDrawer: () => void;
  onSelectSaldo: (columnId: number) => void;
};

const DraggableTable = ({
  columns,
  rows,
  selectedRows,
  sortColumnId,
  actualBalanceColumnId,
  periodLocked,
  onUpdateCell,
  onNewRow,
  onSelectRow,
  onMoveRow,
  onSortColumn,
  onMoveColumn,
  onDeleteRows,
  onHover,
  onOpenDrawer,
  onSelectSaldo,
}: DraggableTableProps) => {
  const { formatMessage } = useIntl();

  const tableRef = useRef<HTMLTableElement>(null);

  const setFocus = () => {
    if (tableRef.current) {
      const selectedRow =
        tableRef.current.rows[tableRef.current.rows.length - 3];
      if (selectedRow) {
        const selectedCell = selectedRow.cells[2];
        if (selectedCell) {
          const el =
            selectedCell.firstElementChild?.getElementsByTagName('input');
          if (el?.[0]) {
            el[0].focus();
          }
        }
      }
    }
  };

  useEffect(() => {
    if (!periodLocked) {
      setFocus();
    }
  }, [periodLocked, rows.length]);

  const isAllRowsSelected =
    rows.length > 0 && selectedRows.length === rows.length;

  const moveRowUp = (rowId: number) => {
    const fromIndex = rows.findIndex((row) => row.id === rowId);
    onMoveRow(fromIndex, fromIndex - 1);
  };

  const moveRowDown = (rowId: number) => {
    const fromIndex = rows.findIndex((row) => row.id === rowId);
    onMoveRow(fromIndex, fromIndex + 1);
  };

  const moveColumnLeft = (columnId: number) => {
    const fromIndex = columns.findIndex((column) => column.id === columnId);
    onMoveColumn(fromIndex, fromIndex - 1);
  };

  const moveColumnRight = (columnId: number) => {
    const fromIndex = columns.findIndex((column) => column.id === columnId);
    onMoveColumn(fromIndex, fromIndex + 1);
  };

  const handleSelectAll = () => {
    if (isAllRowsSelected) {
      onSelectRow([]);
    } else {
      onSelectRow(rows.map((row) => row.id));
    }
  };

  const handleSelectRow = (id: number) => {
    if (selectedRows.includes(id)) {
      onSelectRow(selectedRows.filter((rowId) => rowId !== id));
    } else {
      onSelectRow([...selectedRows, id]);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onNewRow();
    }
  };

  return (
    <StyledTable ref={tableRef} className={getClasses({ periodLocked })}>
      <StickyHeader>
        <tr>
          <th>
            <Typography color="placeholder" margin="none">
              #
            </Typography>
          </th>
          {!periodLocked && (
            <th>
              <Checkbox
                checked={isAllRowsSelected}
                onChange={handleSelectAll}
              />
            </th>
          )}
          {columns.map((column, columnIndex) => (
            <th key={column.id}>
              <ColumnHeaderContent>
                <Typography textStyle="bold" margin="none">
                  {column.name}
                </Typography>
                {!periodLocked && (
                  <ColumnOptions
                    contentType={column.contentType}
                    disableLeft={columnIndex === 0}
                    disableRight={columnIndex === columns.length - 1}
                    showResetButton={column.id === sortColumnId}
                    onMoveLeft={() => moveColumnLeft(column.id)}
                    onMoveRight={() => moveColumnRight(column.id)}
                    onSort={(sortOrder, reset) =>
                      onSortColumn(column.id, sortOrder, reset)
                    }
                  />
                )}
              </ColumnHeaderContent>
            </th>
          ))}
          {/* Action column at the end */}
          {!periodLocked && (
            <th>
              <Button onClick={onOpenDrawer}>
                <CreateIcon />
              </Button>
            </th>
          )}
        </tr>
      </StickyHeader>

      <tbody>
        {rows.map((row, rowIndex) => (
          <DraggableRow
            row={row}
            disableDragging={periodLocked}
            key={row.id}
            onMoveRow={onMoveRow}
            onHover={onHover}
          >
            <td className="draggable">
              <Typography color="placeholder" margin="none">
                {rowIndex + 1}
              </Typography>
            </td>
            {!periodLocked && (
              <td>
                <Checkbox
                  checked={selectedRows.includes(row.id)}
                  onChange={() => handleSelectRow(row.id)}
                />
              </td>
            )}
            {columns.map((col) => (
              <Cell
                key={`${row.id}-${col.id}`}
                contentType={col.contentType}
                value={row.cells.find((c) => c.columnId === col.id)?.value}
                periodLocked={periodLocked}
                onUpdate={(value) => onUpdateCell(value, row, col.id)}
                onKeyDown={handleKeyDown}
              />
            ))}
            {/* Action row cell at the end */}
            {!periodLocked && (
              <td>
                <RowOptions
                  disableDown={rowIndex === rows.length - 1}
                  disableUp={rowIndex === 0}
                  onMoveDown={() => moveRowDown(row.id)}
                  onMoveUp={() => moveRowUp(row.id)}
                  onDelete={() => onDeleteRows([row.id])}
                />
              </td>
            )}
          </DraggableRow>
        ))}

        {/* Add new row */}
        <AddNewRow
          onDropRow={(fromIndex) => onMoveRow(fromIndex, rows.length - 1)}
        >
          <td />
          {!periodLocked && <td />}
          {columns.map((col, index) =>
            index === 0 && !periodLocked ? (
              <td key={col.id}>
                <Button onClick={onNewRow}>
                  <Typography margin="none">
                    {formatMessage({ id: 'add.row' })}
                  </Typography>
                </Button>
              </td>
            ) : (
              <td key={col.id} />
            )
          )}
          {!periodLocked && <td />}
        </AddNewRow>

        {/* Table footer */}
        <SumRow
          columns={columns}
          rows={rows}
          actualBalanceColumnId={actualBalanceColumnId}
          periodLocked={periodLocked}
          onSelectSaldo={onSelectSaldo}
        />
      </tbody>
    </StyledTable>
  );
};

export default DraggableTable;
