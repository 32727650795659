/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-arsredovisning-2021-10-31-rev20230131_sv - Noter.csv
 * - Comments: nan
 */

import {
  value,
  AgoyDocumentPart,
} from '@agoy/document';

import {
  IxbrlCell,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '@agoy/annual-report-document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2092K2v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2092K2v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Upplysning om moderföretag'),
  data: {
    active: true,
    UppgiftNarmasteModerforetagTuple: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Uppgift om närmaste moderföretag'),
      table: table<IxbrlCell>(
        'notes.note92.data.UppgiftNarmasteModerforetagTuple.table',
        {
          id: 'ForetagetsNamn',
          label: 'Nuvarande namn',
          dataType: 'text',
          active: true,
        },
        {
          id: 'Organisationsnummer',
          label: 'Organisations\u00ADnummer',
          dataType: 'text',
          active: true,
        },
        {
          id: 'ForetagetsSate',
          label: 'Säte',
          dataType: 'text',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'UppgiftNarmasteModerforetagTuple',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Nuvarande namn'), {
                  type: 'stringItemType',
                  name: 'se-cd-base:ForetagetsNamn',
                  contextRef: 'period0',
                  standardRubrik: 'Företagets nuvarande namn',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:UppgiftNarmasteModerforetagTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value('Organisations\u00ADnummer'), {
                  type: 'stringItemType',
                  name: 'se-cd-base:Organisationsnummer',
                  contextRef: 'period0',
                  standardRubrik: 'Organisationsnummer',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value('Säte'), {
                  type: 'stringItemType',
                  name: 'se-cd-base:ForetagetsSate',
                  contextRef: 'period0',
                  standardRubrik: 'Företagets säte',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              ForetagetsNamn: ixbrlCell(value('Nuvarande namn'), {
                type: 'stringItemType',
                name: 'se-cd-base:ForetagetsNamn',
                contextRef: 'period0',
                standardRubrik: 'Företagets nuvarande namn',
                negateValue: false,
                tuple: {
                  name: 'se-gaap-ext:UppgiftNarmasteModerforetagTuple',
                  id: `${baseId}.${_id}.tuple`,
                  tupleID: `${baseId}.${_id}.tuple`,
                },
                tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                previousYearTuple: false,
              }),
              Organisationsnummer: ixbrlCell(
                value('Organisations\u00ADnummer'),
                {
                  type: 'stringItemType',
                  name: 'se-cd-base:Organisationsnummer',
                  contextRef: 'period0',
                  standardRubrik: 'Organisationsnummer',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              ForetagetsSate: ixbrlCell(value('Säte'), {
                type: 'stringItemType',
                name: 'se-cd-base:ForetagetsSate',
                contextRef: 'period0',
                standardRubrik: 'Företagets säte',
                negateValue: false,
                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                previousYearTuple: false,
              }),
            },
          }));
          return rows.build();
        })
        .build(),
      NotUpplysningModerforetagKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field('Kommentar till upplysning om moderföretag'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotUpplysningModerforetagKommentar',
          standardRubrik: 'Kommentar till upplysning om moderföretag',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2092K2v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        UppgiftNarmasteModerforetagTuple: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotUpplysningModerforetagKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
