import React from 'react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import CloseIcon from '@material-ui/icons/Close';

type Variant = 'outlined' | 'filled';

const ChipContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  height: 28px;
  margin: 0;
  white-space: nowrap;

  font-size: 14px;

  border-radius: 28px;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  border: 1px solid ${({ theme }) => theme.palette.border.dark};

  &.filled {
    background-color: ${({ theme }) => theme.palette.chip.main};
  }

  &.clickable {
    cursor: pointer;

    &.filled:hover {
      box-shadow: ${({ theme }) => theme.shadows[1]};
    }

    &.outlined:hover {
      background-color: ${({ theme }) => theme.palette.grey[200]};
    }
  }
`;

const Label = styled.span`
  padding: ${({ theme }) => `${theme.spacing(0.5)}px ${theme.spacing(2)}px`};
  padding-right: 0;
  user-select: none;
`;

const CloseButton = styled(CloseIcon, { shouldForwardProp: isPropValid })<{
  visible: boolean;
}>`
  box-sizing: border-box;
  width: ${({ visible }) => (visible ? 18 : 0)}px;
  height: 18px;
  padding: ${({ visible }) => (visible ? 2 : 0)}px;
  border-radius: 50%;
  margin: 0 ${({ theme }) => theme.spacing(1)}px;
  /**
    material recommended transition 
    https://m3.material.io/styles/motion/easing-and-duration/tokens-specs
   */
  transition: width 300ms cubic-bezier(0.2, 0, 0, 1);
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.palette.grey[200]};
  }
`;

interface ChipProps {
  label: string;
  variant?: Variant;
  onClick?: () => void;
  onDelete?: () => void;
}

/**
 * Chip component.
 *
 * @param label    text to be displayed
 * @param variant  (optional, default = outlined) filled or outlined
 * @param onClick  (optional) click callback
 * @param onDelete (optional) delete callback, makes chip "deletable" and shows X button
 * @returns a single chip component
 */

const Chip = ({
  label,
  variant = 'outlined',
  onClick,
  onDelete,
}: ChipProps) => {
  const onChipClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onClick?.();
  };
  const onDeleteClick = (event) => {
    if (onDelete) {
      event.stopPropagation();
      event.preventDefault();
      onDelete();
    }
  };

  return (
    <ChipContainer
      onClick={onChipClick}
      className={`${variant} ${!!onClick && !onDelete ? 'clickable' : ''}`}
    >
      <Label>{label}</Label>
      <CloseButton
        onClick={onDeleteClick}
        fontSize="inherit"
        visible={!!onDelete}
      />
    </ChipContainer>
  );
};

export default Chip;
